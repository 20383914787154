import * as moment from 'moment';
import {ProjectStatusEnum} from '../../../core/business/service/project/project.dto';
import {IPeriodDto} from '../../../core/business/service/report/period/period.dto';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {IPeriodReportDto} from '../../../core/business/service/report/period-report/period-report.dto';
import {ICompanyDto} from '../../../core/business/service/company/company.dto';

export interface ISPHeader {
	months: ISPHeaderMonth[];
	totalPeriod: IPeriodReportDto;
	totalProjects: IPeriodReportDto;
}

export interface ISPHeaderMonth {
	mois: number;
	annee: number;
	date: Date;
	colspan: number;
	isMonthExpend: boolean;
	periods: ISPHeaderPeriod[];
	totalMonth: IPeriodReportDto;
}

export interface ISPHeaderPeriod {
	period: IPeriodDto;
	dateBegin: Date;
}

export class SuiviListFilter {
	periodStart: moment.Moment;
	periodEnd: moment.Moment;
	hideProjectWithNoBudget: boolean = false;
	withStatus: ProjectStatusEnum = null;
	showInactiveProjects: boolean = false;
	filterProjectName: string;
	client: IClientDto;
	companies: ICompanyDto[];
}

