import {IChiffrageDto} from '../chiffrage/chiffrage.dto';
import {ISousRepartitionDto} from '../sous-repartition/sous-repartition.dto';

export interface IRepartitionDto {
	id: number;
	nom: string;
	order: number;
	sommeCharge: number;
	sommeChargeMin: number;
	sommeChargeMax: number;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousRepartitions: ISousRepartitionDto[];
}

export class RepartitionDto implements IRepartitionDto {
	id: number;
	nom: string;
	order: number;
	sommeCharge: number;
	sommeChargeMin: number;
	sommeChargeMax: number;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousRepartitions: ISousRepartitionDto[];
}
