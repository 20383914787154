export enum ObfuscateProgressCatEnum {
	'LOT' = 'LOT',
	'PERIOD' = 'PERIOD',
	'STORY_REPORT' = 'STORY_REPORT'
}

export class ObfuscateProgressData {
	currentProject: {
		index: number,
		name: string
	};
	totalProjects: number;
	currentProgress?: DetailedProgessData;
}

export class DetailedProgessData {
	cat: ObfuscateProgressCatEnum;
	index: number;
	total: number;
	subProgress?: DetailedProgessData;
}
