import ExcelRow, {
	ExcelAlignement,
	ExcelCellBorder,
	ExcelCellColor,
	ExcelCellFont,
	ExcelCellFormat,
	ExcelProtection
} from './excel.dto';
import * as moment from 'moment';
import {UtilsService} from '../../../utils/service/utils.service';
import {Alignment, Fill, Row, Workbook, Worksheet} from 'exceljs';

const EXCEL_TYPE: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION: string = '.xlsx';

export default class ExcelService {

	public static saveAsExcelFile(workbook: Workbook, fragmentFilename: string): void {
		workbook.xlsx.writeBuffer().then((buffer) => {
			const blob: Blob = new Blob([buffer], {type: EXCEL_TYPE});

			let filename: string = '';
			filename += moment().format('YYYY-MM-DD_');
			if (fragmentFilename) {
				filename += fragmentFilename;
			}

			UtilsService.saveFile(blob, filename + EXCEL_EXTENSION);
		});
	}

	public setDataToWorkbook(excelRows: ExcelRow[], worksheetName: string): Workbook {
		const workbook: Workbook = new Workbook();

		const worksheet: Worksheet = workbook.addWorksheet(worksheetName);

		this.addDataRow(worksheet, excelRows);

		return workbook;
	}

	private addDataRow(worksheet: Worksheet, excelRows: ExcelRow[]): void {
		excelRows.forEach(d => {
				const row: Row = worksheet.addRow(d.cells.map(cell => cell.value));
				if (d.height) {
					row.height = d.height;
				}

				row.eachCell((cell, number) => {

					// les compteurs Excel commence à 1
					const cellFormat: ExcelCellFormat = d.cells[number - 1].cellFormat;
					if (cellFormat) {
						cell.numFmt = cellFormat.numFmt;
					}

					if (d.cells[number - 1].config) {
						const excelColor: ExcelCellColor = d.cells[number - 1].config.cellColor;
						if (excelColor) {
							cell.fill = {
								type: 'pattern',
								pattern: excelColor.fill.patternType,
								fgColor: {argb: excelColor.fill.fgColor.argb},
								bgColor: {argb: excelColor.fill.bgColor.argb}
							} as Fill;
						}

						const cellFont: ExcelCellFont = d.cells[number - 1].config.cellFont;
						if (cellFont) {
							cell.font = cellFont;
						}

						const cellBorder: ExcelCellBorder = d.cells[number - 1].config.cellBorder;
						if (cellBorder) {
							cell.border = cellBorder;
						}

						const cellAlignement: ExcelAlignement = d.cells[number - 1].config.cellAlignement;
						if (cellAlignement) {
							cell.alignment = cellAlignement as Alignment;
						}

						const cellProtection: ExcelProtection = d.cells[number - 1].protection;
						if (cellProtection) {
							cell.protection = cellProtection;
						}
					}
				});
			}
		);
	}
}
