import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class CustomHttpInterceptor extends KeycloakBearerInterceptor implements HttpInterceptor {
	constructor(protected keycloakService: KeycloakService,
				private router: Router,
				private translator: TranslateService,
				private snackbar: MatSnackBar) {
		super(keycloakService);
	}

	public static _onError(err: HttpErrorResponse, url: string, router: Router, translator: TranslateService, snackbar: MatSnackBar): void {
		let type: string;
		let errorMsg: string;
		if (err.error instanceof ErrorEvent) {
			console.debug('This is client side error');
			type = `Erreur client`;
			errorMsg = err.error.message;
		} else {
			console.debug('This is server side error');
			type = 'HTTP ' + err.status;
			if (err.error) {
				if (err.error.subErrors?.length && Array.isArray(err.error.subErrors)) {
					// Si des sub errors (pour la validation par exemple)
					errorMsg = '';
					(err.error.subErrors as any[]).forEach(subErr => {
						errorMsg += `${subErr.field} ${subErr.message} (${
							subErr.object
						})\n`;
					});
				} else {
					errorMsg = err.error.message;
				}
			} else {
				errorMsg = err.message;
			}
		}
		console.error(type, errorMsg, err);
		snackbar.open(translator.instant('ERROR.' + err.status), '', {
			panelClass: 'error',
		});

		if (err.status === 401) {
			// Unauthorized = token invalide
			if (!router.parseUrl(router.url).queryParamMap.has('returnUrl')) {
				router.navigate(['/gestion'], {queryParams: {err: 401, returnUrl: router.url}});
			}
		}

		if (err.status === 403) {
			// Forbidden = pas forcément les droits
			if (!router.parseUrl(router.url).queryParamMap.has('returnUrl')) {
				router.navigate(['/gestion'], {queryParams: {err: 403, returnUrl: router.url}});
			}
		}

		// On error 0 (no internet connection)
		if (err.status === 0) {
			return;
		}

		// On error 500
		if (err.status === 500) {
			// return;
		}
	}

	override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				CustomHttpInterceptor._onError(err, request.url, this.router, this.translator, this.snackbar);
				return throwError(() => err);
			})
		);
	}
}

export const customHttpInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: CustomHttpInterceptor,
	multi: true,
	deps: [KeycloakService, Router, TranslateService, MatSnackBar],
};
