import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';
import {ChiffrageService} from '../../core/business/service/avant-vente/chiffrage/chiffrage.service';
import {AvantVenteService} from '../../core/business/service/avant-vente/avant-vente.service';
import {
	AvantVenteEtatEnum,
	IAvantVenteDto
} from '../../core/business/service/avant-vente/avant-vente.dto';
import {ActivatedRoute, Router} from '@angular/router';
import {IChiffrageDto} from '../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {RepartitionService} from '../../core/business/service/avant-vente/repartition/repartition.service';
import {DialogRepartitionEditComponent} from './component/dialog-repartition-edit/dialog-repartition-edit.component';
import {
	IRepartitionDto,
	RepartitionDto
} from '../../core/business/service/avant-vente/repartition/repartition.dto';
import {ChiffrageComponent, TypeItemNode} from './component/chiffrage/chiffrage.component';
import {AvantVenteBusiness} from '../business/avant-vente.business';
import {ChiffrageGlobalComponent} from './component/chiffrage-global/chiffrage-global.component';
import {DialogChiffrageEditComponent} from './component/dialog-chiffrage-edit/dialog-chiffrage-edit.component';
import ExportExcelChiffrage from '../export-excel-chiffrage/export-excel-chiffrage';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder} from '@angular/forms';
import {DialogAvantVenteEditComponent} from '../component/dialog-avant-vente-edit/dialog-avant-vente-edit.component';
import {IClientDto} from '../../core/business/service/client/client.dto';
import {ClientService} from '../../core/business/service/client/client.service';
import {Title} from '@angular/platform-browser';
import {DialogChiffrageCreateComponent} from './component/dialog-chiffrage-create/dialog-chiffrage-create.component';
import {environment} from '../../../environments/environment';
import {debounceTime} from 'rxjs/operators';
import {ThemeEnum} from '../../theme/themes';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
	selector: 'app-avant-vente-detail',
	templateUrl: './avant-vente-detail.component.html',
	styleUrls: ['./avant-vente-detail.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class AvantVenteDetailComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

	@Output() eventAddNewRepartition: EventEmitter<IRepartitionDto> = new EventEmitter<IRepartitionDto>();
	@Output() eventDeleteRepartition: EventEmitter<IRepartitionDto> = new EventEmitter<IRepartitionDto>();
	@Output() eventAddNewCategorie: EventEmitter<TypeItemNode> = new EventEmitter<TypeItemNode>();

	exportExcelChiffrage: ExportExcelChiffrage;

	Math: Math = Math;
	paramAvantVenteId: string;
	avantVente$: Observable<IAvantVenteDto>;
	chiffrages$: Observable<IChiffrageDto[]>;
	updateOnClose: boolean = true;

	avantVente: IAvantVenteDto;
	selectedChiffrage: IChiffrageDto;
	lastOneChiffrage: IChiffrageDto;
	chiffrages: IChiffrageDto[];
	repartitions: IRepartitionDto[];
	avantVenteStatus: string[];
	clients: Observable<IClientDto[]>;

	@ViewChild('filterChiffrage') filterChiffrage: MatSelect;
	@ViewChild('chiffrageComponent') chiffrageComponent: ChiffrageComponent;
	@ViewChild('chiffrageGlobalComponent') chiffrageGlobalComponent: ChiffrageGlobalComponent;

	constructor(public dialog: MatDialog,
				private route: ActivatedRoute,
				private snackBar: MatSnackBar,
				private avantVenteService: AvantVenteService,
				private chiffrageService: ChiffrageService,
				private repartitionService: RepartitionService,
				private _translator: TranslateService,
				private _formBuilder: FormBuilder,
				private clientService: ClientService,
				private _titleService: Title,
				private _router: Router,
				private _cdr: ChangeDetectorRef) {
		this.exportExcelChiffrage = new ExportExcelChiffrage();
	}

	ngOnInit(): void {
		this.paramAvantVenteId = this.route.snapshot.paramMap.get('id');
		this.avantVenteStatus = Object.keys(AvantVenteEtatEnum);

		this.generateAvantVenteObservable(this.avantVenteService.findById(Number(this.paramAvantVenteId)));
		this.generateChiffrageObservable(this.chiffrageService.findByAvantVenteWithRepartition(Number(this.paramAvantVenteId)));

		this.clients = this.clientService.getAllClient();
	}

	ngAfterViewInit(): void {

		this.filterChiffrage.selectionChange.subscribe((selectionChange: MatOptionSelectionChange) => {
			if (selectionChange) {
				this.repartitions = this.selectedChiffrage.repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);
			}
		});

		this.chiffrageGlobalComponent.changedChiffrageValue.subscribe((chiffrageToUpdate: IChiffrageDto) => {
			const index: number = this.chiffrages.findIndex((ch) => ch.id === chiffrageToUpdate.id);
			this.chiffrages.splice(index, 1, chiffrageToUpdate);
		});

		this.chiffrageGlobalComponent.changedChiffrageValue.pipe(debounceTime(4000)).subscribe((chiffrageToUpdate: IChiffrageDto) => {
			if (chiffrageToUpdate) {
				const sumChargesAnnexes: number = chiffrageToUpdate.chargeAnnexes.reduce((acc, ca) => ca ? acc + ca.charge : 0, 0);
				const totalChargeDevelopment: number = chiffrageToUpdate.categories.reduce((acc, c) => acc + c.sommeCharge, 0);
				chiffrageToUpdate.chargeTotal = totalChargeDevelopment +
					Math.ceil(chiffrageToUpdate.pourcentageGestionProjet * totalChargeDevelopment / 25) / 4 +
					Math.ceil(chiffrageToUpdate.pourcentageITTest * totalChargeDevelopment / 25) / 4 + sumChargesAnnexes;

				this.chiffrageService.update(chiffrageToUpdate).subscribe();

				if (this.lastOneChiffrage === this.selectedChiffrage) {
					this.avantVente.chargeTotal = this.lastOneChiffrage.chargeTotal;
					this.avantVente.prixTotal = this.lastOneChiffrage.chargeTotal * this.lastOneChiffrage.tjm;
					this.avantVenteService.update(this.avantVente).subscribe();
				}
			}
		});

		this.chiffrageComponent.needToUpdateRepartitions.subscribe((chiffrage: IChiffrageDto) => {
			if (chiffrage) {
				this.repartitionService.findByChiffrage(chiffrage).subscribe((repartitions: RepartitionDto[]) => {
					this.repartitions = repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);
				});
			}
		});

		this.chiffrageGlobalComponent.needToUpdateRepartitions.subscribe((chiffrage: IChiffrageDto) => {
			if (chiffrage) {
				this.repartitionService.findByChiffrage(chiffrage).subscribe((repartitions: RepartitionDto[]) => {
					this.repartitions = repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);
				});
			}
		});
	}

	ngAfterViewChecked(): void {
		this._cdr.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.lastOneChiffrage === this.selectedChiffrage) {
			this.avantVente.chargeTotal = this.lastOneChiffrage.chargeTotal;
			this.avantVente.prixTotal = this.lastOneChiffrage.chargeTotal * this.lastOneChiffrage.tjm;
			if (this.updateOnClose) {
				this.avantVenteService.update(this.avantVente).subscribe();
			}
		}
	}

	openDialogCreateRepartition(): void {

		this.dialog.open(DialogRepartitionEditComponent, {
			data: {
				repartition: new RepartitionDto(),
			}
		}).afterClosed().subscribe((result: IRepartitionDto) => {
			if (result) {
				result.chiffrage = this.selectedChiffrage;
				this.repartitionService.create(result).subscribe((repartition: IRepartitionDto) => {
					if (repartition) {
						this.repartitions.push(repartition);
						this.selectedChiffrage.repartitions.push(repartition);
						this.repartitions = this.repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);

						this.eventAddNewRepartition.emit(repartition);
						this.snackBar.open(this._translator.instant('AVANT_VENTE.REPARTITION.NEW_SUCCESS'), '');
					}
				});
			}
		});
	}

	openDialogEditRepartition(repartition: IRepartitionDto): void {

		this.dialog.open(DialogRepartitionEditComponent, {
			data: {
				repartition: repartition
			}
		}).afterClosed().subscribe((result: IRepartitionDto) => {
			if (result) {
				this.repartitionService.update(result).subscribe((repartitionDto: IRepartitionDto) => {
					if (repartitionDto) {
						const index: number = this.repartitions.findIndex((r) => r.id === repartitionDto.id);
						this.repartitions.splice(index, 1, repartitionDto);
						this.snackBar.open(this._translator.instant('AVANT_VENTE.REPARTITION.UPDATE'), '');
					}
				});
			}
		});

	}

	openDialogEditAvantVent(): void {

		this.dialog.open(DialogAvantVenteEditComponent, {
			data: {
				avantVente: this.avantVente,
				clients: this.clients
			}
		}).afterClosed().subscribe((result: [IAvantVenteDto, string]) => {
			if (result?.length) {
				this.avantVenteService.update(result[0]).subscribe((avantVente: IAvantVenteDto) => {
					if (avantVente) {
						this.avantVente = avantVente;
						this.snackBar.open(this._translator.instant('AVANT_VENTE.EDIT.STATUS_UPDATED'), '');
					}
				});
			}
		});

	}

	openDialogEditChiffrage(): void {

		this.dialog.open(DialogChiffrageEditComponent, {
			data: {
				chiffrage: this.selectedChiffrage,
			}
		}).afterClosed().subscribe((result: IChiffrageDto) => {
			if (result) {
				this.chiffrageService.update(result).subscribe((updatedChiffrage: IChiffrageDto) => {
					if (updatedChiffrage) {
						this.selectedChiffrage = updatedChiffrage;
						this.snackBar.open('Création de la répartition : Offre' + updatedChiffrage.numero, '');
					}
				});
			}
		});
	}

	addNewCategorie(): void {
		this.eventAddNewCategorie.emit(TypeItemNode.CATEGORIE);
	}

	chiffrageEquals(objOne: IChiffrageDto, objTwo: IChiffrageDto): boolean {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	copyChiffragePlusRecent(): void {
		this.dialog.open(DialogChiffrageCreateComponent, {
			data: {
				oldType: this.lastOneChiffrage.type
			},
			width: '600px'
		}).afterClosed().subscribe((result) => {
			if (result) {
				this.chiffrageService.addNewChiffrageBasedOnLastOne(this.avantVente.id, result).subscribe((createdChiffrage: IChiffrageDto) => {
					if (createdChiffrage) {
						this.generateAvantVenteObservable(this.avantVenteService.findById(Number(this.paramAvantVenteId)));
						this.generateChiffrageObservable(this.chiffrageService.findByAvantVenteWithRepartition(Number(this.paramAvantVenteId)));
					}
				});
			}
		});
	}

	deleteCurrentChiffrage(): void {
		const confirmDelete: boolean = confirm(this._translator.instant('AVANT_VENTE.CHIFFRAGE.DELETE_CHIFFRAGE'));
		if (confirmDelete) {
			this.chiffrageService.remove(this.selectedChiffrage).subscribe((deletedChiffrage: IChiffrageDto) => {
				if (deletedChiffrage) {
					this.generateChiffrageObservable(this.chiffrageService.findByAvantVenteWithRepartition(Number(this.paramAvantVenteId)));
				}
			});
		}
	}

	deleteCurrentRepartition(repartition: IRepartitionDto): void {
		this.repartitionService.remove(repartition).subscribe((deletedRepartition: IRepartitionDto) => {
			if (deletedRepartition) {
				this.snackBar.open(this._translator.instant('AVANT_VENTE.REPARTITION.DELETE'), '');
				const index: number = this.repartitions.findIndex(r => +r.id === +deletedRepartition.id);
				this.repartitions.splice(index, 1);
				this.eventDeleteRepartition.emit(deletedRepartition);
			}
		});
	}

	private generateChiffrageObservable(obsChiffrage: Observable<IChiffrageDto[]>): void {
		this.chiffrages$ = obsChiffrage;
		this.chiffrages$.subscribe((chiffrages: IChiffrageDto[]) => {
			if (chiffrages) {
				this.chiffrages = chiffrages;
				this.lastOneChiffrage = chiffrages.reduce(AvantVenteBusiness.getChiffrageMaxNumero);
				this.selectedChiffrage = this.lastOneChiffrage;
				this.repartitions = this.selectedChiffrage.repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);
			}
		});
	}

	private generateAvantVenteObservable(obsAvantVente: Observable<IAvantVenteDto>): void {
		this.avantVente$ = obsAvantVente;
		this.avantVente$.subscribe((avantVente: IAvantVenteDto) => {
			if (avantVente) {
				this.avantVente = avantVente;
				this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Avant-vente > ' + this.avantVente.titre);
			}
		});
	}

	extractExcel(): void {
		this.chiffrageService.findAllDatasForExcelExport(this.selectedChiffrage).subscribe((chiffrageToExport: IChiffrageDto) => {
			this.exportExcelChiffrage.generateExcel(chiffrageToExport);
		});
	}
}
