<app-header [displayBreadCrumb]="'TEMPO_JIRA.TITLE' | translate" class="position-relative">
	<div class="ms-5">
		<app-searchbar [(value)]="dataSourcePaginate.username" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<div class="d-flex align-items-center">
		<button (click)="moveToWeek(-1)" class="week-arrow-btn"
				mat-icon-button>
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<mat-form-field class="select-week">
			<mat-label>{{'TEMPO_JIRA.FORM.CHOSE_WEEK' | translate}}</mat-label>
			<input [matDatepicker]="picker" [max]="maxDate.toDate()" [value]="selectedDate.toDate()"
				   (dateChange)="dateChange($event)"
				   matInput>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<button [disabled]="checkNextWeek()" (click)="moveToWeek(1)" class="week-arrow-btn"
				mat-icon-button>
			<mat-icon>keyboard_arrow_right</mat-icon>
		</button>
	</div>

	<mat-form-field>
		<mat-label>Entreprises</mat-label>
		<mat-select [(ngModel)]="filterCompanies" [hideSingleSelectionIndicator]="true" #selectCompanies>
			<mat-option [value]="companies">Toutes</mat-option>
			<mat-option [value]="[company]" *ngFor="let company of companies">{{ company.name }}</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-checkbox [checked]="dataSourcePaginate.incompleteImputationsOnly"
				  (change)="toggleIncompleteImputations($event)"
				  class="align-self-center">{{'TEMPO_JIRA.INCOMPLETE_ONLY' | translate}}</mat-checkbox>
	<mat-label class="align-self-center text-center">Total d'Heures : {{dataSourcePaginate.totalHours}} h</mat-label>

	<div class="actions">
		<button (click)="openConfigDialog()" class="its-button"
				mat-flat-button>{{'TEMPO_JIRA.CONFIG_FILTER' | translate}}</button>
	</div>
</app-header>

<div class="mat-elevation-z8 table-container">
	<mat-table [dataSource]="dataSourcePaginate" (matSortChange)="sortData($event)" matSort
			   matSortActive="email" matSortDirection="">

		<!-- Username Column -->
		<ng-container matColumnDef="displayName">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell [class.missing-time]="jiraUser.warning" *matCellDef="let jiraUser">
				<img [alt]="jiraUser.displayName" [src]="jiraUser.avatar | safizer" *ngIf="jiraUser.avatar"
					 class="avatar">
				<span>{{jiraUser.displayName}}</span>
			</mat-cell>
		</ng-container>

		<!-- Time Column -->
		<ng-container matColumnDef="timeSpent">
			<mat-header-cell *matHeaderCellDef
							 mat-sort-header>{{'TEMPO_JIRA.FORM.TIME_SPENT' | translate}}</mat-header-cell>
			<mat-cell [class.missing-time]="jiraUser.warning" *matCellDef="let jiraUser">
				{{jiraUser.timeSpent / 3600}} h
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator [length]="dataSourcePaginate.totalElem" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>

	<app-ogdp-paginator [length]="dataSourcePaginate.totalElem" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
</div>
