import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stickTopHeight'
})
export class StickTopHeightPipe implements PipeTransform {

  transform(header: HTMLElement, offset: number = 0): number {
	  return (header.offsetHeight + 30 + offset * 25);
  }

}
