<app-header [displayBreadCrumb]="'CLIENT.HEADER' | translate">
	<div class="ms-5">
		<app-searchbar [(value)]="filterClientName" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<mat-form-field>
		<mat-label>{{'JIRA_USER.STATE' | translate}}</mat-label>
		<mat-select #selectActif [(ngModel)]="filterActif" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option [value]="true">
				{{'USER.EDIT_FORM.ACTIF' | translate}}
			</mat-option>
			<mat-option [value]="false">
				{{'USER.EDIT_FORM.INACTIF' | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="actions">
		<button *ngIf="role | admin" (click)="syncWithMdm()" class="its-button" mat-flat-button>{{'APP.SYNC' | translate}}</button>
	</div>

</app-header>
<div class="mat-elevation-z8 table-container">
	<mat-table [dataSource]="dataSourcePaginate" (matSortChange)="sortData($event)" matSort matSortActive="nom"
			   matSortDirection="asc">

		<!-- Logo column -->
		<ng-container matColumnDef="logo">
			<mat-header-cell *matHeaderCellDef>Logo</mat-header-cell>
			<mat-cell *matCellDef="let client">
				<img [alt]="client.name" [src]="client.logo | safizer" *ngIf="client.logo" class="logo"/>
			</mat-cell>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Nom</mat-header-cell>
			<mat-cell *matCellDef="let client">{{client.name}}  </mat-cell>
		</ng-container>

		<!-- statut Column -->
		<ng-container matColumnDef="statut">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Statut</mat-header-cell>
			<mat-cell *matCellDef="let client">{{client.statut === true ? 'Actif' : 'Inactif'}}  </mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>
	<app-ogdp-paginator [length]="dataSourcePaginate.length()" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit"
						(pageClick)="goToPage($event)"></app-ogdp-paginator>
</div>
