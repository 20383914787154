import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../../core/pagination/dto/pagination-option.dto';
import {UserDataSource} from './datasource/user-datasource';
import {merge, Observable, throwError} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {SpinnerService} from '../../core/service/spinner.service';
import {IUserBaseDto, PermissionEnum} from '../../core/business/service/user-base/user-base.dto';
import {UserListFilter} from './dto/user-list.dto';
import {OgdpPaginatorComponent} from '../../utils/components/paginator/ogdp-paginator.component';
import {TranslateService} from '@ngx-translate/core';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {DialogUserEditComponent} from '../user-edit/dialog-user-edit.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {KeycloakService} from 'keycloak-angular';
import {UserInfo} from '../../security/util/user-info';

@Component({
	selector: 'app-userbase',
	templateUrl: './userbase.component.html',
	styleUrls: ['./userbase.component.scss']
})
export class UserbaseComponent extends UserInfo implements AfterViewInit, OnInit {

	dataSourcePaginate: UserDataSource;
	displayedColumns: string[] = ['avatar', 'trigramme', 'lastName', 'email', 'company', 'actif', 'ogdpAccess', 'role', 'jiraAccess', 'edit'];

	userFilter: UserListFilter;

	roles: string[] = Object.keys(PermissionEnum);
	companies: string[] = ['ITS Future', 'Cup of Tea', 'DDruid', 'Eglantine 3.0', 'La nuit porte conseil'];
	permissions: string[] = ['raf', 'avant-vente', 'v1-report'];

	paginationOption: PaginationOption;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchInputUsername: SearchbarComponent;
	@ViewChild('selectRole') selectRole: MatSelect;
	@ViewChild('selectCompany') selectCompany: MatSelect;
	@ViewChild('selectOGDPAccess') selectOGDPAccess: MatSelect;
	@ViewChild('selectActif') selectActif: MatSelect;
	@ViewChild('selectPermissions') selectPermissions: MatSelect;
	@ViewChild('selectJiraAccess') selectJiraAccess: MatSelect;


	constructor(private userBaseService: UserBaseService,
				private _translator: TranslateService,
				private spinnerService: SpinnerService,
				private _snackBar: MatSnackBar,
				public dialog: MatDialog,
				private _titleService: Title,
				public override keycloak: KeycloakService) {
		super(keycloak);

		this.paginationOption = new PaginationOption(20, 0);
		this.paginationOption.sortBy = new PaginationSortBy('email', PaginationSort.ASC);
		this.userFilter = new UserListFilter();
	}

	ngOnInit(): void {
		this.roles.pop();
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Utilisateurs ');
		this.dataSourcePaginate = new UserDataSource(this.userBaseService, this.spinnerService);
		this.loadUserBase();

		this.userFilter.actif = true;
		this.userFilter.jiraAccess = true;
	}

	ngAfterViewInit(): void {
		merge(this.selectRole.selectionChange, this.selectCompany.selectionChange, this.selectOGDPAccess.selectionChange,
			this.selectActif.selectionChange, this.selectPermissions.selectionChange, this.searchInputUsername.valueChange,
			this.selectJiraAccess.selectionChange, this.searchInputUsername.clearInput).pipe(
				debounceTime(500),
				distinctUntilChanged())
		.pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadUserBase();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadUserBase();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	syncWithMdm(): void {
		this.spinnerService.enableLoading();
		this.userBaseService.syncUsers().pipe(
			catchError(() => {
				this.spinnerService.disableLoading();
				return throwError(() => new Error('Erreur lors de la synchronisation des Utilisateurs'));
			})
		).subscribe(() => {
			this.loadUserBase();
			this.spinnerService.disableLoading();
			this._snackBar.open('Synchronisation réussie', '', {
				panelClass: 'success',
				duration: 2000,
			});
		});
	}

	sortData(event: any): void {
		if (event.direction) {
			this.paginationOption.sortBy = new PaginationSortBy(event.active, event.direction.toUpperCase());
		} else {
			this.paginationOption.sortBy = null;
		}
		this.loadUserBase();
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.loadUserBase();
	}

	private loadUserBase(): void {
		this.dataSourcePaginate.findFilterByUsernameActifPaging(this.userFilter, this.paginationOption);
	}

	async openDialogCreateUpdateUserBase(userBase?: IUserBaseDto, readOnly: boolean = false): Promise<void> {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '550px';

		if (userBase) {
			dialogConfig.data = {
				userBase: await this.userBaseService.getUser(userBase.id)
			};
		}
		if (readOnly) {
			dialogConfig.data.readOnly = true;
		}

		const dialogRef: MatDialogRef<DialogUserEditComponent> = this.dialog.open(DialogUserEditComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(result => {
			this._titleService.setTitle('ITS OGDP > Utilisateurs');
			if (result) {
				let obsUserUpdateOrCreate: Observable<IUserBaseDto>;
				if (userBase) { // update
					obsUserUpdateOrCreate = this.userBaseService.updateUser(result);
				}

				obsUserUpdateOrCreate.subscribe(value => {
					if (value) {
						this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
							panelClass: 'success'
						});
					} else {
						this._snackBar.open(this._translator.instant('APP.SNACKBAR.FAILURE'), '', {
							panelClass: 'error'
						});
					}

					this.ngOnInit();
				});
			}
		});
	}

	clearFilterField(): void {
		this.userFilter.email = '';
	}

}
