import {Component, OnInit} from '@angular/core';
import {UserBaseService} from '../../../core/business/service/user-base/user-base.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IUserBaseDto, UserBaseDto} from '../../../core/business/service/user-base/user-base.dto';
import {firstValueFrom} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {KeycloakService} from 'keycloak-angular';
import {UserInfo} from '../../../security/util/user-info';

@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends UserInfo implements OnInit {
	userForm: FormGroup;
	jiraTokenChange: boolean = false;

	constructor(private _userBaseService: UserBaseService,
				public override keycloak: KeycloakService,
				private _dialogRef: MatDialogRef<MyAccountComponent>,
				private _fb: FormBuilder,
				private _snackbar: MatSnackBar,
				private _translateService: TranslateService) {
		super(keycloak);

		this.userForm = this._fb.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			jiraToken: new FormControl(''),
			jiraPlaceholder: new FormControl({value: '', disabled: true})
		});
	}

	ngOnInit(): void {
		if (!this.currentUser) {
			this._snackbar.open(this._translateService.instant('PROFILE.ERROR.NOT_EXIST'), '', {
				panelClass: 'error'
			});
			this._dialogRef.close();
			return;
		}
		this.userForm.patchValue({
			firstName: this.currentUser.firstName,
			lastName: this.currentUser.lastName,
			jiraPlaceholder: this.currentUser.jiraToken
		});
	}

	async save(): Promise<void> {
		if (this.userForm.invalid) {
			return;
		}
		this.currentUser.firstName = this.userForm.get('firstName').value;
		this.currentUser.lastName = this.userForm.get('lastName').value;
		this.currentUser.jiraToken = this.userForm.get(this.jiraTokenChange ? 'jiraToken' : 'jiraPlaceholder').value;

		this.currentUser = await firstValueFrom(this._userBaseService.updateUser(this.currentUser));

		this._dialogRef.close();
	}
}
