import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {fromEvent, merge, Observable} from 'rxjs';
import {IClientDto} from '../../core/business/service/client/client.dto';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../../core/pagination/dto/pagination-option.dto';
import {OgdpPaginatorComponent} from '../../utils/components/paginator/ogdp-paginator.component';
import {ClientSelectComponent} from '../../client/component/client-select/client-select.component';
import {ClientService} from '../../core/business/service/client/client.service';
import {SpinnerService} from '../../core/service/spinner.service';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {
	AvantVenteDto,
	AvantVenteEtatEnum,
	AvantVenteFilterDto, IAvantVenteDto
} from '../../core/business/service/avant-vente/avant-vente.dto';
import {AvantVenteDataSource} from './datasource/avant-vente-list.datasource';
import {AvantVenteService} from '../../core/business/service/avant-vente/avant-vente.service';
import {DialogAvantVenteEditComponent} from '../component/dialog-avant-vente-edit/dialog-avant-vente-edit.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';

@Component({
	selector: 'app-avant-vente-list',
	templateUrl: './avant-vente-list.component.html',
	styleUrls: ['./avant-vente-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class AvantVenteListComponent implements AfterViewInit, OnInit, OnDestroy {

	displayedColumns: string[] = ['informations', 'dateCreation', 'nbOffre', 'budgetNbJ', 'budgetEuro', 'empty'];
	dataSourcePaginate: AvantVenteDataSource;

	avantVenteFilter: AvantVenteFilterDto;
	clients: Observable<IClientDto[]>;

	avantVenteEtatEnumKeys: string[] = Object.keys(AvantVenteEtatEnum);

	sortByOption: PaginationSortBy[];

	paginationOption: PaginationOption;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchbar: SearchbarComponent;
	@ViewChild('filterStatus') filterStatus: MatSelect;
	@ViewChild('filterClient') filterClient: ClientSelectComponent;
	@ViewChild('sortBy') sortBy: MatSelect;

	constructor(public dialog: MatDialog,
				private avantVenteService: AvantVenteService,
				private clientService: ClientService,
				private spinnerService: SpinnerService,
				private router: Router,
				private snackBar: MatSnackBar,
				private _titleService: Title) {

		this.paginationOption = new PaginationOption(20, 0);
		this.avantVenteFilter = new AvantVenteFilterDto();

		this.sortByOption = [];
		this.sortByOption['creationDateAsc'] = new PaginationSortBy('dateCreation', PaginationSort.ASC);
		this.sortByOption['creationDateDesc'] = new PaginationSortBy('dateCreation', PaginationSort.DESC);
		this.sortByOption['alphabetiqueOrderAsc'] = new PaginationSortBy('titre', PaginationSort.ASC);
		this.sortByOption['budgetDesc'] = new PaginationSortBy('chargeTotal', PaginationSort.DESC);
		this.paginationOption.sortBy = this.sortByOption['alphabetiqueOrderAsc'];


		this.dataSourcePaginate = new AvantVenteDataSource(this.avantVenteService, this.spinnerService);
	}

	ngOnInit(): void {
		this.loadClients();
		this.loadAvantVentes();

		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Avant-vente');
	}

	ngOnDestroy(): void {
	}

	ngAfterViewInit(): void {

		merge(this.filterStatus.selectionChange, this.filterClient.clientChange, this.sortBy.selectionChange,
			this.searchbar.valueChange.pipe(
				debounceTime(500),
				distinctUntilChanged())
		).pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadAvantVentes();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadAvantVentes();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	private loadAvantVentes(): void {
		this.dataSourcePaginate.findByFiltersPaging(this.avantVenteFilter, this.paginationOption);
	}

	private loadClients(): void {
		this.clients = this.clientService.getAllClient();
	}

	clearFilterField(): void {
		this.avantVenteFilter.titre = '';
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.paginationOption.limit = event.pageSize;
		this.loadAvantVentes();
	}

	sortData(event: any): void {
		if (event.direction) {
			this.paginationOption.sortBy = new PaginationSortBy(event.active, event.direction.toUpperCase());
		} else {
			this.paginationOption.sortBy = null;
		}
		this.loadAvantVentes();
	}

	deleteAvantVente(avantVente: IAvantVenteDto): void {
		const confirmDelete: boolean = confirm('Voulez-vous supprimer l\'avant-vente ?');
		if (confirmDelete) {
			this.avantVenteService.remove(avantVente.id).subscribe(() => {
				this.snackBar.open('Suppression de l\'avant-vente' + avantVente.titre, '');
				this.loadAvantVentes();
			});
		}
	}

	openDialogCreateAvantVente(): void {
		this.dialog.open(DialogAvantVenteEditComponent, {
			data: {
				avantVente: new AvantVenteDto(),
				clients: this.clientService.getAllClient()
			}
		}).afterClosed().subscribe((result) => {
			if (result) {
				this.avantVenteService.create(result[0], result[1]).subscribe(avantVente => {
					this.snackBar.open('Création de l\'avant vente : ' + avantVente.titre, '');
					this.loadAvantVentes();
				});
			}
		});
	}
}
