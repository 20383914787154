import {IProjectDto} from '../project/project.dto';

export interface IEpicDto {
	id: number;
	name: string;
	jiraIntId: string;
	order: number;
	reporter: string;
	updateDate: Date;
	deleted: boolean;
	projectId: number;
	project: IProjectDto;
	/**
	 * json of lots ids
	 */
	expandedInLots: string;
}

export class EpicDto implements IEpicDto {
	id: number;
	name: string;
	jiraIntId: string;
	order: number;
	reporter: string;
	updateDate: Date;
	deleted: boolean;
	projectId: number;
	project: IProjectDto;
	expandedInLots: string;
}
