import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ConfigurationService} from './core/business/service/configuration/configuration.service';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {filter, takeWhile} from 'rxjs';

export function init_app(appConfigService: ConfigurationService, keycloak: KeycloakService): () => any {
	return () => keycloak.keycloakEvents$.pipe(
		takeWhile(v => v.type !== KeycloakEventType.OnReady, true),
		filter(v => v.type === KeycloakEventType.OnReady)).subscribe(() => {
		appConfigService.loadConfigOnCache();
	});
}

@NgModule({
	imports: [HttpClientModule],
	providers: [
		ConfigurationService,
		{provide: APP_INITIALIZER, useFactory: init_app, deps: [ConfigurationService, KeycloakService], multi: true}
	]
})
export class AppConfigModule {
}
