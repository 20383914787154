import {Component, Input} from '@angular/core';
import {SpinnerService} from '../../core/service/spinner.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {HeaderService} from './header.service';

@Component({
	selector: 'app-header',
	animations: [
		trigger(
			'fadeInFadeOutAnim',
			[
				transition(
					':enter', [
						style({opacity: 0}),
						animate('200ms', style({'opacity': 1}))
					]
				),
				transition(
					':leave', [
						style({'opacity': 1}),
						animate('200ms', style({'opacity': 0}))

					]
				)]
		)
	],
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	@Input() displayBreadCrumb: string;

	constructor(public spinnerService: SpinnerService,
				public headerService: HeaderService) {
	}
}
