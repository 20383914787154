import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IChargeAnnexeDto} from './charge-annexe.dto';

@Injectable({
	providedIn: 'root'
})
export class ChargeAnnexeService extends ApiService {

	private route: string = '/api/av-charge-chargeAnnexe';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(chargeAnnexeDto: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {
		return this.post(this.route, chargeAnnexeDto);
	}

	update(chargeAnnexeDto: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {
		return this.put(this.route + '/' + chargeAnnexeDto.id, chargeAnnexeDto);
	}

	remove(chargeAnnexeDto: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {
		return this.delete(this.route + '/' + chargeAnnexeDto.id);
	}

	findByChiffrageWitSousRepartitionAndRepartition(chiffrageId: number): Observable<IChargeAnnexeDto[]> {
		return this.get(this.route + '/chiffrage/' + chiffrageId);
	}

	findByIdWithSousRepartitionAndRepartition(chargeAnnexeDto: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {
		return this.get(this.route + '/' + chargeAnnexeDto.id);
	}
}
