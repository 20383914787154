import {ILotDto} from '../lot/lot.dto';
import {IEpicDto} from '../epic/epic.dto';
import {ISubtaskDto} from '../subtask/subtask.dto';
import {ISprintDto} from '../sprint/sprint.dto';

export interface IStoryDto {
	id: number;
	name: string;
	jiraExtId: string;
	jiraIntId: string;
	budget: number;
	estimated: number;
	spent: number;
	remainingEXT: number;
	type: StoryTypeEnum;
	status: JiraStatusEnum;
	reporter: string;
	deleted: boolean;
	order: number;
	updateDate: Date;
	subtasks: ISubtaskDto[];
	epicId: number;
	epic: IEpicDto;
	lotId: number;
	lot: ILotDto;
	sprintId: number;
	sprint: ISprintDto;
	orphan: boolean;
	isExpanded: boolean;
}

export class StoryDto implements IStoryDto {
	id: number;
	name: string;
	jiraExtId: string;
	jiraIntId: string;
	budget: number;
	estimated: number;
	spent: number;
	remainingEXT: number;
	type: StoryTypeEnum;
	status: JiraStatusEnum;
	reporter: string;
	deleted: boolean;
	order: number;
	updateDate: Date;
	subtasks: ISubtaskDto[];
	epicId: number;
	epic: IEpicDto;
	lotId: number;
	lot: ILotDto;
	sprintId: number;
	sprint: ISprintDto;
	orphan: boolean;
	isExpanded: boolean = true;
}

export enum StoryTypeEnum {
	REGULAR = 'REGULAR',
	INT = 'INT',
	CONTINGENCY = 'CONTINGENCY'
}

export enum JiraStatusEnum {
	NEW = 'NEW',
	TO_EVALUATE = 'TO_EVALUATE',
	BACKLOG = 'BACKLOG',
	BLOCKED = 'BLOCKED',
	IN_PROGRESS = 'IN_PROGRESS',
	ANTICIPATED = 'ANTICIPATED',
	TO_REVIEW = 'TO_REVIEW',
	SUSPENDED = 'SUSPENDED',
	DONE_DEV = 'DONE_DEV',
	PACKAGED = 'PACKAGED',
	DELIVERED_RECETTE = 'DELIVERED_RECETTE',
	REOPENED = 'REOPENED',
	VALIDATED_RECETTE = 'VALIDATED_RECETTE',
	DELIVERED_PROD = 'DELIVERED_PROD',
	VALIDATED_PROD = 'VALIDATED_PROD',
	ACKNOWLEDGED = 'ACKNOWLEDGED',
	IN_STORY = 'IN_STORY',
	CLOSED = 'CLOSED',
}
