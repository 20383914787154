<app-header [displayBreadCrumb]="'ALERT.HEADER' | translate">
	<div class="ms-5">
		<app-searchbar [(value)]="projectAlertFilter.projectName" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<mat-form-field>
		<mat-label>{{'ALERT.TYPE.TITLE' | translate}}</mat-label>
		<mat-select [(ngModel)]="projectAlertFilter.type" [hideSingleSelectionIndicator]="true" #filterAlertType>
			<mat-option [value]="null">
				<span></span>
			</mat-option>
			<mat-option [value]="projectAlertType" *ngFor="let projectAlertType of projectAlertTypeEnumKeys">
				<span class="sp-iconmonstr-triangle-1 select-type-margin project-alert-{{projectAlertType}}"></span>
				<span>{{'ALERT.TYPE.' + projectAlertType | translate}}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field>
		<mat-label>{{'ALERT.CAUSE.FILTER' | translate}}</mat-label>
		<mat-select [(ngModel)]="projectAlertFilter.cause" [hideSingleSelectionIndicator]="true"
					#filterAlertMessageType>
			<mat-option [value]="null">
				<span></span>
			</mat-option>
			<mat-option [value]="messageAlertCause"
						*ngFor="let messageAlertCause of messageAlertCauseEnumKeys">
				<span
					class="sp-iconmonstr-triangle-1 select-type-margin project-alert-{{alertTypeMatrix[messageAlertCause]}}"></span>
				<span>{{'ALERT.CAUSE.' + messageAlertCause | translate}}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>
</app-header>

<div class="mat-elevation-z8 table-container">
	<mat-table [dataSource]="dataSourcePaginate" (matSortChange)="sortData($event)" matSort
			   matSortActive="creationDate" matSortDirection="asc">

		<!-- Panneau -->
		<ng-container matColumnDef="panneau">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let alert">
				<div>
					<span class="sp-iconmonstr-triangle-1 project-alert-{{alert.type}}"></span>
				</div>
			</mat-cell>
		</ng-container>

		<!-- Project name -->
		<ng-container matColumnDef="project">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERT.PROJECT_HEADER' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let alert">{{alert.project.name}}</mat-cell>
		</ng-container>

		<!-- Date -->
		<ng-container matColumnDef="creationDate">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERT.DATE_HEADER' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let alert">{{alert.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</mat-cell>
		</ng-container>

		<!-- Type -->
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERT.TYPE.TITLE' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let alert">{{'ALERT.TYPE.' + alert.type | translate}}</mat-cell>
		</ng-container>

		<!-- Message -->
		<ng-container matColumnDef="message">
			<mat-header-cell *matHeaderCellDef>{{'ALERT.CAUSE.TITLE' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let alert">{{alert.message}}</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row [ngClass]="row.type" (click)="goToProject(row.project.id)"
				 *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>
	<app-ogdp-paginator [length]="dataSourcePaginate.length()" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
</div>
