<app-header [displayBreadCrumb]="'BUG.HEADER' | translate">
	<mat-form-field class="ms-5">
		<mat-label>{{'BUG.FILTER.PROJECT' | translate}}</mat-label>
		<mat-select [(ngModel)]="project" [compareWith]="idEquals" [disabled]="projects.length === 1 || linkBugs"
					[hideSingleSelectionIndicator]="true"
					(openedChange)="myInput.focus(); myInput.value = ''; filteredProjects = projects">
			<input [placeholder]="'APP.SEARCH' | translate" (keyup)="filterProjects($any($event.target).value)"
				   #myInput autocomplete="off" class="p-2" matInput type="text">
			<mat-divider></mat-divider>
			<mat-option [value]="proj" *ngFor="let proj of filteredProjects">
				{{proj.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<div class="d-flex align-items-center">
		<mat-slide-toggle [(ngModel)]="ext" [disabled]="linkBugs" labelPosition="before">
			{{'BUG.FILTER.INT' | translate}}
		</mat-slide-toggle>
		<mat-label [class.label-disabled]="linkBugs" class="ps-2">{{'BUG.FILTER.EXT' | translate}}</mat-label>
	</div>

	<div class="actions">
		<button (click)="search()" *ngIf="!linkBugs" class="its-button" mat-flat-button>
			{{'APP.SEARCH' | translate}}
		</button>
	</div>
</app-header>

<div *ngIf="!project" class="d-flex flex-grow-1 align-items-center justify-content-center m-3 fw-bold">
	<span class="material-icons">arrow_upward</span>
	<span>{{'BUG.FILTER.FILL' | translate}}</span>
	<span class="material-icons">arrow_upward</span>
</div>

<div *ngIf="bugs && !bugs.length" class="fw-bold d-flex justify-content-center p-3">
	{{'APP.NO_DATA' | translate}}
</div>

<div *ngIf="bugs && bugs.length" class="mat-elevation-z8 table-container bg-white">
	<div class="step-container">
		<div *ngIf="!linkBugs" class="d-flex align-items-center justify-content-between">
			<span class="title">{{'BUG.STEP.STEP1' | translate}}</span>
			<button (click)="validateSelection()" *ngIf="selection.hasValue()" class="its-button me-3"
					mat-flat-button>
				{{'BUG.FILTER.VALIDATE_SELECTION' | translate}}
			</button>
		</div>
		<div *ngIf="linkBugs">
			<div class="d-flex align-items-center justify-content-between">
				<span class="title">{{'BUG.STEP.STEP2' | translate}}</span>
				<div>
					<button (click)="cancelSelection()" class="its-button me-3" mat-flat-button>
						{{'APP.CANCEL' | translate}}
					</button>
					<button (click)="save()" class="its-button me-3" mat-flat-button>
						{{'APP.VALIDATE' | translate}}
					</button>
				</div>
			</div>
			<div>
				<div>{{'BUG.STORY.TITLE' | translate}}</div>

				<mat-radio-group [(ngModel)]="chooseExistingStory">
					<mat-radio-button [value]="false">{{'BUG.STORY.CREATE' | translate}}</mat-radio-button>
					<mat-radio-button [disabled]="!stories.length"
									  [value]="true">{{'BUG.STORY.CHOOSE' | translate}}</mat-radio-button>
				</mat-radio-group>
			</div>
			<div *ngIf="chooseExistingStory">
				<mat-form-field>
					<mat-label>{{'BUG.STORY.SELECT' | translate}}</mat-label>
					<mat-select [(ngModel)]="story" [compareWith]="idEquals" [disabled]="stories.length === 1"
								[hideSingleSelectionIndicator]="true"
								(openedChange)="myInput.focus(); myInput.value = ''; filteredStories = stories">
						<input [placeholder]="'APP.SEARCH' | translate" (keydown)="$event.stopPropagation()"
							   (keyup)="filterStories($any($event.target).value)" #myInput autocomplete="off"
							   class="p-2" matInput type="text">
						<mat-divider></mat-divider>
						<mat-option [value]="sto" *ngFor="let sto of filteredStories">
							<div class="sp-STORY-{{sto.type}} d-inline-block align-middle pe-2">
								<i class="path1"></i>
								<i class="path2"></i>
							</div>
							<span class="align-middle">{{ext ? sto.jiraExtId : sto.jiraIntId}} - {{sto.name}}</span>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div *ngIf="!chooseExistingStory">
				<form [formGroup]="storyForm">
					<mat-form-field class="pe-3">
						<mat-label>{{'BUG.STORY.NAME' | translate}}</mat-label>
						<input formControlName="name" matInput required>
					</mat-form-field>
					<mat-form-field *ngIf="ext" class="pe-3">
						<mat-label>{{'BUG.STORY.BUDGET' | translate}}</mat-label>
						<input appNumberInput formControlName="budget" formatType="number" matInput required>
						<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
					</mat-form-field>
					<mat-form-field class="pe-3">
						<mat-label>{{'BUG.STORY.ESTIMATED' | translate}}</mat-label>
						<input appNumberInput formControlName="estimated" formatType="number" matInput required>
						<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
					</mat-form-field>
				</form>
			</div>
		</div>
	</div>

	<table [dataSource]="dataSource" class="w-100" mat-table>
		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th *matHeaderCellDef class="very-small-col" mat-header-cell>
				<mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
							  [disabled]="linkBugs"
							  [indeterminate]="selection.hasValue() && !isAllSelected()"
							  (change)="$event ? masterToggle() : null"
							  class="big-checkbox">
				</mat-checkbox>
			</th>
			<td *matCellDef="let row" mat-cell>
				<mat-checkbox [checked]="selection.isSelected(row)"
							  [disabled]="linkBugs"
							  (change)="$event ? selection.toggle(row) : null"
							  (click)="$event.stopPropagation()"
							  class="big-checkbox">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- Jira Column -->
		<ng-container matColumnDef="jira">
			<th *matHeaderCellDef class="very-small-col" mat-header-cell>{{'BUG.TABLE.JIRA' | translate}}</th>
			<td *matCellDef="let element" class="jira-browser-url" mat-cell>
				<a [href]="linkPrefix + element.jiraId" target="_blank">{{element.jiraId}}</a>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th *matHeaderCellDef mat-header-cell>{{'BUG.TABLE.NAME' | translate}}</th>
			<td *matCellDef="let element" mat-cell>{{element.name}}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="status">
			<th *matHeaderCellDef class="small-col text-center" mat-header-cell>{{'BUG.TABLE.STATUS' | translate}}</th>
			<td *matCellDef="let element" class="text-center" mat-cell>
				<span class="tag-{{element.statusName}}">{{element.statusName}}</span>
			</td>
		</ng-container>

		<!-- Creation Column -->
		<ng-container matColumnDef="creation">
			<th *matHeaderCellDef class="small-col" mat-header-cell>{{'BUG.TABLE.CREATION' | translate}}</th>
			<td *matCellDef="let element" mat-cell>{{element.creationDate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
		</ng-container>

		<!-- Priority Column -->
		<ng-container matColumnDef="priority">
			<th *matHeaderCellDef class="very-small-col" mat-header-cell>{{'BUG.TABLE.PRIORITY' | translate}}</th>
			<td *matCellDef="let element" mat-cell>
				<span [title]="element.priorityName"
					  *ngIf="element.priorityName === 'LOWEST' || element.priorityName === 'LOW'"
					  class="material-icons priority-{{element.priorityName}} align-middle">arrow_downward</span>
				<span [title]="element.priorityName"
					  *ngIf="!(element.priorityName === 'LOWEST' || element.priorityName === 'LOW')"
					  class="material-icons priority-{{element.priorityName}} align-middle">arrow_upward</span>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayColumns" mat-header-row></tr>
		<tr *matRowDef="let row; columns: displayColumns;" mat-row></tr>
	</table>
</div>


