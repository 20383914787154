<app-dialog-popup [popupTitle]="'Configuration des filtres'" (click)="lostFocus()">
	<div dialog-popup-content>
		<div class="main">
			<div class="sub-header">
				<span>Suivi</span>
				<app-searchbar [(value)]="search" (clearInput)="search = ''; updateList()"
							   (valueChange)="updateList()"></app-searchbar>
				<span>Ignoré</span>
			</div>
			<div *ngIf="ready" class="content">
				<div class="active scrollbar-hidden container">
					<div [class.focus]="user === focus"
						 (click)="setFocus(user); $event.stopPropagation(); source = 'ACTIVE'"
						 *ngFor="let user of active" class="item">
						<img [src]="user.avatar | safizer" *ngIf="user.avatar" alt="" class="avatar">
						<span>{{user.displayName}}</span>
					</div>
				</div>
				<div class="option">
					<div class="action">
						<button [disabled]="!focus || source === 'ACTIVE'"
								(click)="removeOfIgnoreList(); $event.stopPropagation()" mat-button>
							<span class="material-icons-outlined">keyboard_arrow_left</span>
						</button>
					</div>
					<div class="action">
						<button [disabled]="!focus || source === 'DISABLE'"
								(click)="addToIgnoreList(); $event.stopPropagation()" mat-button>
							<span class="material-icons-outlined">keyboard_arrow_right</span>
						</button>
					</div>
				</div>
				<div class="disable scrollbar-hidden container">
					<div [class.focus]="user === focus"
						 (click)="setFocus(user); $event.stopPropagation(); source = 'DISABLE'"
						 *ngFor="let user of disable" class="item">
						<img [src]="user.avatar | safizer" *ngIf="user.avatar" alt="" class="avatar">
						<span>{{user.displayName}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="close()" class="its-button" mat-flat-button>
			{{'APP.CANCEL' | translate}}
		</button>
		<button (click)="close(true)" class="its-button" mat-flat-button>
			{{'APP.SAVE' | translate}}
		</button>
	</div>
</app-dialog-popup>
