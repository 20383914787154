import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export abstract class ApiService {

	constructor(public http: HttpClient) {
	}

	private formatErrors(error: any): Observable<never> {
		return throwError(error.error);
	}

	get(path: string, params: HttpParams | {} = new HttpParams()): Observable<any> {
		return this.http.get(path, {params}).pipe(catchError(this.formatErrors));
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this.http.put(path, body).pipe(catchError(this.formatErrors));
	}

	post(path: string, body: Object = {}): Observable<any> {
		return this.http.post(path, body).pipe(catchError(this.formatErrors));
	}

	delete(path: string): Observable<any> {
		return this.http.delete(path).pipe(catchError(this.formatErrors));
	}
}
