<mat-sidenav-container class="main-layout">
	<mat-sidenav [class.side-menu-closed]="!headerService.menuOpen" class="side-menu"
				 mode="side" opened>
		<div *ngIf="!headerService.menuOpen" class="hide-menu"></div>
		<div class="version">v{{version}}</div>
		<button (click)="openClose()" class="logo-open-close">
			<img [alt]="'APP.LOGO' | translate" class="cot-logo"
				 src="../../../assets/img/{{ environment.theme }}/logo_reduced.png">
		</button>
		<div class="link-container" id="leftMenu">
			<p class="color-blue-navy-2 side-menu-title">
				<span>{{'MENU.TITLE' | translate}}</span>&nbsp;
				<button [class.color-orange-2]="theme === 'ITS'"
						[class.color-turquoise]="theme === 'Cup Of T'"
						[ngSwitch]="theme" class="fw-bold">
					<ng-container *ngSwitchCase="'ITS'">ITSF</ng-container>
					<ng-container *ngSwitchCase="'Cup Of T'">Cup Of T</ng-container>
				</button>
			</p>
			<a *ngIf="role | role: ['ADMIN', 'USER', 'CLIENT']" routerLink="/gestion"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.GESTION' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']" routerLink="/suivi" routerLinkActive="active">
				<span>{{'MENU.LINKS.SUIVI' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']:permissions:['v1-report', 'raf']"
			   routerLink="/v1-report" routerLinkActive="active">
				<span>{{'MENU.LINKS.V1_REPORT' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']" routerLink="/project-alert"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.ALERT' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>

			<hr class="side-menu-separator">

			<a *ngIf="role | role: ['ADMIN', 'USER']:permissions:['raf']"
			   routerLink="/demande-bdc" routerLinkActive="active">
				<span>{{'MENU.LINKS.DEMANDE_BDC' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']:permissions:['avant-vente']"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.AVANT_VENTE' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>

			<hr class="side-menu-separator">

			<a *ngIf="role | role: ['ADMIN']" routerLink="/tempo-jira"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.TEMPO_JIRA' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN']" routerLink="/export-tempo"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.EXPORT_TEMPO' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>

			<hr class="side-menu-separator">

			<a *ngIf="role | role: ['ADMIN']" routerLink="/user-base" routerLinkActive="active">
				<span>{{'MENU.LINKS.USER' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']: permissions: ['gestion-clients']"
			   routerLink="/gestion-client" routerLinkActive="active">
				<span>{{'MENU.LINKS.CLIENT' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN', 'USER']" routerLink="/company"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.COMPANY' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<a *ngIf="role | role: ['ADMIN']" routerLink="/configuration"
			   routerLinkActive="active">
				<span>{{'MENU.LINKS.CONFIGURATION' | translate}}</span>
				<div class="itsf-link-underline"></div>
			</a>
			<!--<a *ngIf="permission | role: ['ADMIN', 'USER']" routerLink="/bugs" routerLinkActive="active">
				{{'MENU.LINKS.BUGS' | translate}}
				<div class="itsf-link-underline"></div>
			</a>-->

		</div>

		<div class="bottom-menu">
			<button (click)="openProfileModal()">
				<span>{{'MENU.LINKS.PROFILE' | translate}}</span>
				<img [alt]="'MENU.PROFILE_PICTURE' | translate"
					 [matTooltip]="user.firstName + ' ' + user.lastName"
					 [src]="user.avatar | safizer" *ngIf="user?.avatar"
					 class="avatar">
			</button>
			<button (click)="logout()">
				{{'MENU.LINKS.LOGOUT' | translate}}
			</button>
		</div>
	</mat-sidenav>
	<mat-sidenav-content [class.side-menu-closed]="!headerService.menuOpen" class="main-content" id="main-content">
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
