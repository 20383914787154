import {IProjectDto} from '../project/project.dto';
import {ICompanyDto} from '../company/company.dto';
import {IConfigurationDto} from '../configuration/configuration.dto';

export interface IUserBaseDto {
	id: number;
	mdmId: number;
	jiraId: string;
	email: string;
	firstName: string;
	lastName: string;
	trigramme: string;
	avatar: string;
	ogdpAccess: boolean;
	jiraAccess: boolean;
	actif: boolean;
	lastYearOfActivity: number;
	companyId: number;
	company: ICompanyDto;
	role: PermissionEnum;
	permissions: string[];
	projects: IProjectDto[];
	jiraToken: string;
	userConfig: IConfigurationDto[];
}


export class UserBaseDto implements IUserBaseDto {
	id: number;
	mdmId: number;
	jiraId: string;
	email: string;
	firstName: string;
	lastName: string;
	trigramme: string;
	avatar: string;
	ogdpAccess: boolean;
	jiraAccess: boolean;
	actif: boolean;
	lastYearOfActivity: number;
	companyId: number;
	company: ICompanyDto;
	role: PermissionEnum;
	permissions: string[];
	projects: IProjectDto[];
	jiraToken: string;
	userConfig: IConfigurationDto[];
}

export enum PermissionEnum {
	ADMIN = 'ADMIN',
	USER = 'USER',
	CLIENT = 'CLIENT'
}
