<div (keyup.escape)="clearFormCategorie()" class="d-flex justify-content-between align-items-center categorie">

	<div *ngIf="stateItemNode == 'EDIT'; then edition else lecture"></div>
	<ng-template #edition> <!-- /!\ create and update -->
		<div>
			<button disabled mat-icon-button matTreeNodeToggle class="mat-icon-button-size">
				<mat-icon>
					{{isExpanded ? 'expand_more' : 'chevron_right'}}
				</mat-icon>
			</button>
		</div>

		<div class="d-flex align-items-center justify-content-between title-row categorie-title">
			<div class="d-flex align-items-center">
				<mat-form-field class="input-form-nom">
					<mat-label>Nom</mat-label>
					<input [(ngModel)]="categorie.nom" [focusOn]="true" (keydown.enter)="saveFormCategorie()" matInput>
				</mat-form-field>
			</div>
			<div class="d-flex align-items-center">
				<button (click)="saveFormCategorie()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">done</span>
				</button>
				<button (click)="clearFormCategorie()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">close</span>
				</button>
			</div>
		</div>

	</ng-template>

	<ng-template #lecture>
		<div class="d-flex align-items-center">
			<button (click)="toggleExpansion()" mat-icon-button matTreeNodeToggle class="mat-icon-button-size">
				<mat-icon>
					{{isExpanded ? 'expand_more' : 'chevron_right'}}
				</mat-icon>
			</button>
		</div>

		<div (dblclick)="editNode()"
			 class="d-flex align-items-center justify-content-between title-row categorie-title">
			<span class="d-flex">
				 {{(categorie.order + 1)}} - {{categorie.nom}}
			</span>

			<div *ngIf="chiffrage?.id === lastChiffrage?.id" class="d-flex align-items-center button-modification">
				<button [title]="'AVANT_VENTE.DETAIL.ADD_SUB_CATEGORY' | translate" (click)="addFormNewSousCategorie()"
						class="add-task me-2"
						color="primary" mat-mini-fab>
					<mat-icon>add</mat-icon>
				</button>

				<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
					<mat-icon>more_horiz</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="itsf-override">
					<button (click)="changeStateItemNode()" mat-menu-item>
						<span class="sp-edit gradient"></span>
						<span class="ms-2">{{'APP.EDIT' | translate}}</span>
					</button>
					<button (click)="deleteCategorie()" mat-menu-item>
						<span class="sp-delete gradient"></span>
						<span class="ms-2">{{'APP.DELETE' | translate}}</span>
					</button>
				</mat-menu>
			</div>
		</div>

		<div (dblclick)="editNode()" *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-categorie">
			<div class="charge-row categorie-charge">
				{{categorie.sommeCharge | number:'1.0-3'}}
			</div>
		</div>

		<div (dblclick)="editNode()" *ngFor="let totalRepartition of totalChargeByRepartition">
			<div class="d-flex align-items-center cell-categorie">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row repartition-charge">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{totalRepartition.sommeCharge| number:'1.0-3'}}
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div
							class="min d-flex align-items-center justify-content-center">{{totalRepartition.sommeChargeMin| number:'1.0-3'}}</div>
						<div
							class="d-flex align-items-center justify-content-center">{{totalRepartition.sommeChargeMax| number:'1.0-3'}}</div>
					</div>
				</div>
			</div>
		</div>

		<div (dblclick)="editNode()" class="d-flex justify-content-end align-items-center">
			<span *ngIf="node.isKeybordSelected"
				  class="keyboard-hints">{{'AVANT_VENTE.DETAIL.KEYBOARD_HINT.CATEGORIE' | translate}}</span>
		</div>
	</ng-template>
</div>
