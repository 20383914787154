import {
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {SPRow} from '../dto/suivi-projet.dto';
import {ILotDto} from '../../../core/business/service/lot/lot.dto';
import { MatTableDataSource } from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
	selector: 'app-dialog-lot-selection-suivi-projet',
	styleUrls: ['./dialog-lot-selection-suivi-projet.component.scss'],
	templateUrl: './dialog-lot-selection-suivi-projet.component.html'
})

export class DialogLotSelectionSuiviProjetComponent implements OnInit {

	public dataSource: MatTableDataSource<SPRow>;
	public displayedColumns: string[];
	public selection: SelectionModel<SPRow>;
	public canAddLot: ILotDto[] = [];
	public selectedLotToAdd: number;
	public displayedList: ILotDto[];
	public search: string = '';

	constructor(public dialogRef: MatDialogRef<DialogLotSelectionSuiviProjetComponent>,
				@Inject(MAT_DIALOG_DATA) public data: {
					allLots: ILotDto[],
					lots: SPRow[],
					defaultVisibleLots: SPRow[],
					selectionAutomatiqueLotsVisibles: boolean
				}) {
		if (!this.data.lots || !this.data.lots.length) {
			this.dataSource = new MatTableDataSource<SPRow>([]);
			this.selection = new SelectionModel<SPRow>(true, []);
		} else {
			this.dataSource = new MatTableDataSource<SPRow>(this.data.lots);
			this.selection = new SelectionModel<SPRow>(true, this.data.lots.filter(value => value.visible === true));
		}
		this.displayedList = this.data.allLots;
		for (const lot of this.data.lots) {
			this.displayedList = this.displayedList.filter((lotOfList: ILotDto) => lotOfList.name !== lot.rowDefinition.name);
		}
		this.data.allLots.forEach(lotBase => {
			let find: boolean = false;
			this.data.lots.forEach(lot => {
				if (lot.id === lotBase.id.toString()) {
					find = true;
				}
			});
			if (!find) {
				this.canAddLot.push(lotBase);
			}
		});
	}

	lotSelected = new FormControl('');
	filteredOptions: Observable<ILotDto[]>;

	@ViewChild('dialogPopupInput') dialogPopupInput: ElementRef;

	ngOnInit(): void {
		this.displayedColumns = ['id', 'name', 'statut'];
		this.filteredOptions = this.lotSelected.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);
	}

	private _filter(value: string): ILotDto[] {
		const filterValue: string = value.toString().toLowerCase();
		return this.displayedList.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	displayFn(id: number): string {
		if (!id) { return ''; }
		const index: number = this.displayedList.findIndex(option => option.id === id);
		return this.displayedList[index].name;
	}

	searchChange(): void {
		if (this.search && this.search !== '') {
			this.displayedList = this.data.allLots.filter(lot =>  lot.name.toLowerCase().includes(this.search.toLowerCase()));
		} else {
			this.displayedList = this.data.allLots;
		}
	}
	masterToggle(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.selection.select(...this.data.lots);
	}

	selectAuto(event: MatCheckboxChange): void {
		if (event.checked) {
			this.selection.clear();
			this.selection.select(...this.data.defaultVisibleLots);
		}
	}

	focusInputField(): void {
		(document.activeElement as HTMLElement).blur();
		this.dialogPopupInput.nativeElement.focus();
	}

	isAllSelected(): boolean {
		const numSelected: number = this.selection.selected.length;
		const numRows: number = this.data.lots.length;
		return numSelected === numRows;
	}

	validateSelection(): void {
		this.dialogRef.close({
			selection: this.selection.selected,
			selectionAutomatiqueLotsVisibles: this.data.selectionAutomatiqueLotsVisibles,
			toLoad: this.lotSelected.value ? [this.lotSelected.value] : null
		});
	}
}
