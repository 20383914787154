import {MessageAlertCauseEnum, ProjectAlertTypeEnum} from '../../core/business/service/project-alert/project-alert.dto';

export class ProjectAlertFilter {

	projectName: string;
	type: ProjectAlertTypeEnum;
	cause: MessageAlertCauseEnum;

	constructor() {
	}
}
