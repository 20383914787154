import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, filter, firstValueFrom, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

export interface SseObject {
	type: string;
	data: any;
}

@Injectable({
	providedIn: 'root',
})
export class SseService {

	private _eventSource: EventSource;
	private _message: BehaviorSubject<SseObject> = new BehaviorSubject<SseObject>(null);

	constructor(private _httpClient: HttpClient) { }

	connectToSse(type?: string): Observable<SseObject> {
		if (!this._eventSource) {
			this._eventSource = new EventSource('api/sse');
			this._eventSource.onmessage = (obj => {
				this._message.next(JSON.parse(obj.data));
			});
		}
		return this._message.pipe(
			filter(value => type ? value?.type === type : true), // Si un type est donnée, on filtre sur le type
		);
	}

	sendMessageForType(type: string, data: any): Promise<any> {
		return firstValueFrom(this._httpClient.post('/api/sse/update-sse', {type, data}));
	}

	closeAllSseConnection(): void {
		if (this._eventSource) {
			this._eventSource.close();
		}
	}
}
