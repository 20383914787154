<app-header
	[displayBreadCrumb]="('V1_REPORT.TITLE' | translate) + ' > ' + (project && project.jiraIntId?  project.jiraIntId : '')"
	class="position-relative">
	<div class="center-list">
		<a (click)="goToPage('/suivi/' + (project && project.jiraIntId?  project.jiraIntId : ''))"
		   class="nav-proj">{{'NAVIGATION.SUIVI' | translate}}</a>
		<a (click)="goToPage('/gestion/' + (project && project.id ? project.id : ''))"
		   class="nav-proj">{{'NAVIGATION.GESTION' | translate}}</a>
		<a [routerLink]="['/v1-report/' +  (project && project.id ? project.id : '')]"
		   class="nav-proj active">{{'NAVIGATION.RAPPORT' | translate}}</a>
	</div>
	<div class="ms-5">
		<button (click)="moveToWeek(-1)" class="week-arrow-btn" mat-icon-button>
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<mat-form-field class="select-week">
			<mat-label>{{'V1_REPORT.FORM.CHOSE_WEEK' | translate}}</mat-label>
			<input [matDatepicker]="picker" [max]="maxDate.toDate()" [value]="startDate.toDate()"
				   (dateChange)="dateChange($event)" matInput>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<button [disabled]="maxDate.format('DD-MM-YYYY') === startDate.format('DD-MM-YYYY')" (click)="moveToWeek(1)"
				class="week-arrow-btn" mat-icon-button>
			<mat-icon>keyboard_arrow_right</mat-icon>
		</button>
	</div>
	<div class="d-flex flex-column navigate-projects">
		<div class="navigate-label">{{ 'V1_REPORT.NAVIGATE_BETWEEN_PROJECTS' | translate }}</div>
		<div class="d-flex flex-row">
			<div class="navigate-project-item">
				<a [queryParams]="{selectedDate: startDate.format('YYYY-MM-DD'), v1: v1}"
				   [routerLink]="['/v1-report/', previousProject?.id]"
				   *ngIf="previousProject"
				   class="d-flex">
					<mat-icon>keyboard_arrow_left</mat-icon>
					<p (click)="pauseTimer(); askToSave()">{{previousProject?.name}}</p>
				</a>
				<a *ngIf="!previousProject" class="d-flex disabled">
					<mat-icon>keyboard_arrow_left</mat-icon>
					<p>{{ 'V1_REPORT.NO_PREVIOUS_PROJECT' | translate }}</p>
				</a>
			</div>
			<div class="navigate-project-item">
				<a [queryParams]="{selectedDate: startDate.format('YYYY-MM-DD'), v1: v1}"
				   [routerLink]="['/v1-report/', nextProject?.id]"
				   *ngIf="nextProject"
				   class="d-flex justify-content-end">
					<p (click)="pauseTimer(); askToSave()">{{nextProject?.name}}</p>
					<mat-icon>keyboard_arrow_right</mat-icon>
				</a>
				<a *ngIf="!nextProject" class="d-flex justify-content-end disabled" disabled>
					<p>{{ 'V1_REPORT.NO_NEXT_PROJECT' | translate }}</p>
					<mat-icon>keyboard_arrow_right</mat-icon>
				</a>
			</div>
		</div>
	</div>

	<div *ngIf="previousReport" class="comparing-reports">
		<mat-checkbox [(ngModel)]="comparingReports">{{'V1_REPORT.COMPARISON' | translate}}</mat-checkbox>
	</div>


	<div class="actions">
		<p [ngClass]="timerColor(false)" (click)="standBy()" *ngIf="v1 && !report?.isClosed" class="timer-v1">
			Temps restant:
		</p>
		<p [class.pause]="!run" [ngClass]="timerColor(true)" (click)="standBy()" *ngIf="v1 && !report?.isClosed"
		   class="timer-v1">{{formatTimer(limitTime)}}</p>
		<a [class.d-none]="!(role | admin)" (click)="openReport()" *ngIf="report?.isClosed"
           class="sp-refresh-icon" title="{{'V1_REPORT.REOPEN' | translate}}"></a>
		<button [disabled]="report?.isClosed" (click)="closeReport()" *ngIf="role | admin"
				class="its-button"
				mat-flat-button>{{ 'V1_REPORT.CLOSE' | translate }}{{v1 ? ' ( Ctrl + S )' : ''}}</button>
		<button [disabled]="report?.isClosed || report?.isPublished" (click)="publishReport()" class="its-button"
				mat-flat-button>{{(report?.isPublished ? 'V1_REPORT.ALREADY_PUBLISHED' : 'V1_REPORT.PUBLISH') | translate }}</button>
		<button [disabled]="report?.isClosed" (click)="updateReport()" class="its-button"
				mat-flat-button>{{ 'V1_REPORT.SAVE' | translate }}</button>
	</div>
</app-header>

<div *ngIf="report" class="content">
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-title-container">
			<div class="d-flex flex-row align-items-center report-title-row">
				<img [alt]="'APP.LOGO' | translate" [src]="project.client.logo | safizer" *ngIf="project?.client?.logo"
					 class="logo">
				<div class="d-flex flex-column flex-grow-1 ms-2">
					<span class="project-name">{{ project.name }}</span>
					<span class="project-jiraid">{{ project.jiraIntId }}</span>
				</div>
			</div>
			<div class="d-flex flex-row report-title-row project-info-container">
				<div class="project-info">
					<div class="info-label">Entreprise</div>
					<div class="info">{{project.company ? project.company.name : ''}}</div>
				</div>
				<div class="project-info">
					<div class="info-label">{{ 'PROJECT.BUDGET' | translate }}</div>
					<div class="info">{{project.budget}}</div>
				</div>
				<div class="project-info">
					<div class="info-label">{{ 'PROJECT.RAE' | translate }}</div>
					<div class="info">{{ project.remainingEXTSum }}</div>
				</div>
				<div class="project-info">
					<div class="info-label">{{ 'PROJECT.STATUS.TITLE' | translate }}</div>
					<div class="info">{{ 'PROJECT.STATUS.' + project.status | translate }}</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-row separate-header">
			<div class="report-header-cell-short">
				<div class="report-header-label">{{ 'V1_REPORT.CRITICALITY' | translate | uppercase }}</div>
				<div [class.bg-green-suivi]="report?.criticality === 3"
					 [class.bg-grey-light-2]="report?.criticality"
					 [class.bg-orange-2]="report?.criticality === 2"
					 [class.bg-red]="report?.criticality === 1"
					 [class.cursor-pointer]="!report.isClosed"
					 [class.missing]="fromCheck && (report?.criticality === null)"
					 [matTooltip]="'V1_REPORT.CRITICALITY_INFO' | translate"
					 (click)="changeCriticality(report)" class="report-header-info align-items-center"></div>
			</div>
			<div class="report-header-cell-short">
				<div class="report-header-label">{{ 'V1_REPORT.TREND' | translate | uppercase }}</div>
				<div [class.cursor-pointer]="!report.isClosed"
					 [matTooltip]="'V1_REPORT.TREND_INFO' | translate"
					 (click)="changeTrend()"
					 class="report-header-info trend-cell">
					<div [class.trend-high]="report.trend === 3"
						 [class.trend-low]="report.trend === 1"
						 [class.trend-medium]="report.trend === 2 || !report.trend"
						 class="sp-right-arrow"></div>
				</div>
			</div>
			<div *ngIf="!comparingReports" class="report-header-cell-medium">
				<div class="report-header-label">{{ 'V1_REPORT.PROJECT.SPENT' | translate | uppercase }}</div>
				<div class="report-header-info align-items-center">{{ project.periodSpentSum }}</div>
			</div>
			<div *ngIf="!comparingReports" class="report-header-cell-medium">
				<div class="report-header-label">{{ 'V1_REPORT.PROJECT.PRODUCT' | translate | uppercase }}</div>
				<div class="report-header-info align-items-center">{{ project.periodProdSum }}</div>
			</div>
			<div *ngIf="!comparingReports" class="report-header-cell-medium">
				<div class="report-header-label">{{ 'V1_REPORT.PROJECT.DIFF_SHORT' | translate | uppercase }}</div>
				<div [class.color-green-suivi]="project.periodProdSum - project.periodSpentSum > 0"
					 [class.color-red]="project.periodProdSum - project.periodSpentSum < 0"
					 class="report-header-info align-items-center">
					{{ project.periodProdSum - project.periodSpentSum }}
				</div>
			</div>
			<div class="report-header-cell-large">
				<div class="report-header-label">{{ 'V1_REPORT.SYNTHESIS' | translate | uppercase }}</div>
				<div
					[ngStyle]="{'background-color':  previousReport != undefined && report != undefined &&compareReport(report.summary, previousReport.summary) ? '#E9F7FF' : ''}"
					class="report-header-info flex-grow-1 align-items-center vertical-align-middle report-summary-container
					{{fromCheck && !report.summary? 'missing' : '' }}">
					<textarea [(ngModel)]="report.summary" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
							  [readOnly]="report.isClosed"
							  [required]="fromCheck && !report.summary"
							  (blur)="focus($event)"
							  (focus)="focus($event)"
							  cdkTextareaAutosize class="report-summary"
							  spellcheck="false"></textarea></div>
			</div>
		</div>
		<div *ngIf="previousReport && comparingReports" class="d-flex flex-row separate-header previous-report-data">
			<div class="report-header-cell-short">
				<div class="report-header-label">{{ 'V1_REPORT.CRITICALITY' | translate | uppercase }}</div>
				<div [class.bg-green-suivi]="previousReport?.criticality === 3"
					 [class.bg-grey-light-2]="previousReport?.criticality"
					 [class.bg-orange-2]="previousReport?.criticality === 2"
					 [class.bg-red]="previousReport?.criticality === 1"
					 [matTooltip]="'V1_REPORT.CRITICALITY_INFO' | translate"
					 class="report-header-info align-items-center"></div>
			</div>
			<div class="report-header-cell-short">
				<div class="report-header-label">{{ 'V1_REPORT.TREND' | translate | uppercase }}</div>
				<div [matTooltip]="'V1_REPORT.TREND_INFO' | translate"
					 class="report-header-info trend-cell">
					<div [class.trend-high]="previousReport.trend === 3"
						 [class.trend-low]="previousReport.trend === 1"
						 [class.trend-medium]="previousReport.trend === 2 || !previousReport.trend"
						 class="sp-right-arrow"></div>
				</div>
			</div>
			<div class="report-header-cell-large">
				<div class="report-header-label">{{ 'V1_REPORT.SYNTHESIS' | translate | uppercase }}</div>
				<div class="report-header-info report-axis-summary align-items-center">
					<p>{{ previousReport.summary }}</p></div>
			</div>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" *ngFor="let axis of matchingAxis" class="report-grid">
		<div class="report-axis-item">{{ axis.name }}</div>
		<div class="report-data-item">
			<div [class.bg-green-suivi]="axis.axisReport.criticality === 3"
				 [class.bg-grey-light-2]="axis.axisReport.criticality"
				 [class.bg-orange-2]="axis.axisReport.criticality === 2"
				 [class.bg-red]="axis.axisReport.criticality === 1"
				 [class.cursor-pointer]="!report.isClosed"
				 [class.missing]="fromCheck && (axis.axisReport.criticality === null)"
				 [matTooltip]="'V1_REPORT.CRITICALITY_INFO' | translate"
				 (click)="changeCriticality(axis.axisReport)"
				 class="report-data-cell">
			</div>
			<div [class.cursor-pointer]="!report.isClosed"
				 [matTooltip]="'V1_REPORT.TREND_INFO' | translate"
				 (click)="changeTrend(axis.axisReport)"
				 class="report-data-cell trend-cell">
				<div [class.trend-high]="axis.axisReport.trend === 3"
					 [class.trend-low]="axis.axisReport.trend === 1"
					 [class.trend-medium]="axis.axisReport.trend === 2 || !axis.axisReport.trend"
					 class="sp-right-arrow">
				</div>
			</div>
			<div
				[ngStyle]="{'background-color': axis.axisReport != undefined && axis.previousAxisReport != undefined && compareReport(axis.axisReport.description, axis.previousAxisReport.description) ? '#E9F7FF' : ''}"
				class="report-axis-summary {{fromCheck && !axis.axisReport.description? 'missing' : '' }}">
				<textarea [(ngModel)]="axis.axisReport.description" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
						  [readOnly]="report.isClosed"
						  [required]="fromCheck && !axis.axisReport.description"
						  (blur)="focus($event)"
						  (focus)="focus($event)"
						  cdkTextareaAutosize spellcheck="false"></textarea>
			</div>
		</div>
		<div *ngIf="comparingReports" class="report-data-item previous-report-data">
			<div [class.bg-green-suivi]="axis.previousAxisReport.criticality === 3"
				 [class.bg-grey-light-2]="axis.previousAxisReport.criticality"
				 [class.bg-orange-2]="axis.previousAxisReport.criticality === 2"
				 [class.bg-red]="axis.previousAxisReport.criticality === 1"
				 [matTooltip]="'V1_REPORT.CRITICALITY_INFO' | translate"
				 class="report-data-cell">
			</div>
			<div [matTooltip]="'V1_REPORT.TREND_INFO' | translate"
				 class="report-data-cell trend-cell">
				<div [class.trend-high]="axis.previousAxisReport.trend === 3"
					 [class.trend-low]="axis.previousAxisReport.trend === 1"
					 [class.trend-medium]="axis.previousAxisReport.trend === 2 || !axis.previousAxisReport.trend"
					 class="sp-right-arrow">
				</div>
			</div>
			<div class="report-axis-summary"><p>{{ axis.previousAxisReport.description }}</p></div>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.CUSTOMER_ISSUE' | translate }}</div>
		<div
			[ngStyle]="{'background-color':  previousReport != undefined && report != undefined && compareReport(report.customerIssue, previousReport.customerIssue) ? '#E9F7FF' : ''}"
			class="report-data-item flex-grow-1 report-axis-summary">
			<textarea [(ngModel)]="report.customerIssue" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
					  [readOnly]="report.isClosed"
					  (blur)="focus($event)"
					  (focus)="focus($event)"
					  cdkTextareaAutosize spellcheck="false"></textarea>
		</div>
		<div *ngIf="comparingReports" class="report-data-item flex-grow-1 previous-report-data report-axis-summary">
			<p>{{ previousReport.customerIssue }}</p>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.IMPROVEMENT_AXIS' | translate }}</div>
		<div
			[ngStyle]="{'background-color':  previousReport != undefined && report != undefined && compareReport(report.improvement, previousReport.improvement) ? '#E9F7FF' : ''}"
			class="report-data-item flex-grow-1 report-axis-summary">
			<textarea [(ngModel)]="report.improvement" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
					  [readOnly]="report.isClosed"
					  (blur)="focus($event)"
					  (focus)="focus($event)"
					  cdkTextareaAutosize spellcheck="false"></textarea>
		</div>
		<div *ngIf="comparingReports" class="report-data-item flex-grow-1 previous-report-data report-axis-summary">
			<p>{{ previousReport.improvement }}</p>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.COMMENT_DP' | translate }}</div>
		<div
			[ngStyle]="{'background-color':  previousReport != undefined && report != undefined && compareReport(report.commentDp, previousReport.commentDp) ? '#E9F7FF' : ''}"
			class="report-data-item flex-grow-1 report-axis-summary">
			<textarea [(ngModel)]="report.commentDp" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
					  [readOnly]="report.isClosed"
					  (blur)="focus($event)"
					  (focus)="focus($event)"
					  cdkTextareaAutosize spellcheck="false"></textarea>
		</div>
		<div *ngIf="comparingReports" class="report-data-item flex-grow-1 previous-report-data report-axis-summary">
			<p>{{ previousReport.commentDp }}</p>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports"
		 *ngIf="role | admin: permissions: ['raf']"
		 class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.EFFECTIVE_PREVISION' | translate }}</div>
		<div class="report-data-item">
			<div class="month-effective">
				<div>
					{{ 'MONTHS_SHORT.' + getMonth(0) | uppercase | translate }}
					<ng-container *ngIf="!comparingReports">{{startDate.format('YYYY')}}</ng-container>
				</div>
				<div>
					<input [(ngModel)]="report.m0" [disabled]="report.isClosed" max="99" min="0" step="0.25"
						   type="number">
					<span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
			<div class="month-effective">
				<div>
					{{ 'MONTHS_SHORT.' + getMonth(1) | uppercase | translate }}
					<ng-container *ngIf="!comparingReports">{{startDate.format('YYYY')}}</ng-container>
				</div>
				<div>
					<input [(ngModel)]="report.m1" [disabled]="report.isClosed" max="99" min="0" step="0.25"
						   type="number">
					<span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
			<div class="month-effective">
				<div>
					{{ 'MONTHS_SHORT.' + getMonth(2) | uppercase | translate }}
					<ng-container *ngIf="!comparingReports">{{startDate.format('YYYY')}}</ng-container>
				</div>
				<div>
					<input [(ngModel)]="report.m2" [disabled]="report.isClosed" max="99" min="0" step="0.25"
						   type="number">
					<span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
		</div>
		<div *ngIf="comparingReports" class="report-data-item previous-report-data">
			<div class="month-effective">
				<div>{{ 'MONTHS_SHORT.' + getMonthPreviousReport(0) | uppercase | translate }}</div>
				<div>{{ previousReport.m0 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
			<div class="month-effective">
				<div>{{ 'MONTHS_SHORT.' + getMonthPreviousReport(1) | uppercase | translate }}</div>
				<div>{{ previousReport.m1 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
			<div class="month-effective">
				<div>{{ 'MONTHS_SHORT.' + getMonthPreviousReport(2) | uppercase | translate }}</div>
				<div>{{ previousReport.m2 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.DEADLINE' | translate }}</div>
		<div class="report-data-item">
			<div class="deadline {{getDateStyle(report.deadlineContract)}}">
				<div *ngIf="!comparingReports">
					{{ 'V1_REPORT.DEADLINE_CONTRACT' | translate }}
				</div>
				<div>
					<div *ngIf="comparingReports">
						{{ 'V1_REPORT.DEADLINE_CONTRACT' | translate }}
					</div>
					<div>
						<input [(ngModel)]="report.deadlineContract" [matDatepicker]="pickerContract"
							   (focusout)="report.deadlineContract = regen($event)"
							   matInput>
					</div>
				</div>
				<div class="deadline-but">
					<div>
						<mat-datepicker-toggle [for]="pickerContract" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #pickerContract></mat-datepicker>
					</div>
					<span (click)="report.deadlineContract = null" *ngIf="report.deadlineContract" class="clear">
						<mat-icon>clear</mat-icon>
					</span>
				</div>
			</div>
			<div class="deadline {{getDateStyle(report.deadlineTMA)}}">
				<div *ngIf="!comparingReports">
					{{ 'V1_REPORT.DEADLINE_TMA' | translate }}
				</div>
				<div>
					<div *ngIf="comparingReports">
						{{ 'V1_REPORT.DEADLINE_TMA' | translate }}
					</div>
					<div>
						<input [(ngModel)]="report.deadlineTMA" [matDatepicker]="pickerTMA"
							   (focusout)="report.deadlineTMA = regen($event)"
							   matInput>
					</div>
				</div>
				<div class="deadline-but">
					<div>
						<mat-datepicker-toggle [for]="pickerTMA" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #pickerTMA></mat-datepicker>
					</div>
					<span (click)="report.deadlineTMA = null" *ngIf="report.deadlineTMA" class="clear">
						<mat-icon>clear</mat-icon>
					</span>
				</div>
			</div>
			<div class="deadline {{getDateStyle(report.deadlineHosting)}}">
				<div *ngIf="!comparingReports">
					{{ 'V1_REPORT.DEADLINE_HOSTING' | translate }}
				</div>
				<div>
					<div *ngIf="comparingReports">
						{{ 'V1_REPORT.DEADLINE_HOSTING' | translate }}
					</div>
					<div>
						<input [(ngModel)]="report.deadlineHosting" [matDatepicker]="pickerHosting"
							   (focusout)="report.deadlineHosting = regen($event)"
							   matInput>
					</div>
				</div>
				<div class="deadline-but">
					<div>
						<mat-datepicker-toggle [for]="pickerHosting" matSuffix></mat-datepicker-toggle>
						<mat-datepicker #pickerHosting></mat-datepicker>
					</div>
					<span (click)="report.deadlineHosting = null" *ngIf="report.deadlineHosting" class="clear">
						<mat-icon>clear</mat-icon>
					</span>
				</div>
			</div>
		</div>
		<div *ngIf="comparingReports" class="report-data-item previous-report-data">
			<div class="deadline {{getDateStyle(previousReport.deadlineContract)}}">
				<div>
					<div>
						{{ 'V1_REPORT.DEADLINE_CONTRACT' | translate }}
					</div>
					<div>
						{{previousReport.deadlineContract | date: 'dd/MM/yyy'}}
					</div>
				</div>
			</div>
			<div class="deadline {{getDateStyle(previousReport.deadlineTMA)}}">
				<div>
					<div>
						{{ 'V1_REPORT.DEADLINE_TMA' | translate }}
					</div>
					<div>
						{{previousReport.deadlineTMA | date: 'dd/MM/yyy'}}
					</div>
				</div>
			</div>
			<div class="deadline {{getDateStyle(previousReport.deadlineHosting)}}">
				<div>
					<div>
						{{ 'V1_REPORT.DEADLINE_HOSTING' | translate }}
					</div>
					<div>
						{{previousReport.deadlineHosting | date: 'dd/MM/yyy'}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div [class.report-grid-comparing]="comparingReports" class="report-grid">
		<div class="report-axis-item inverted">{{ 'V1_REPORT.COMMENT_CONTRACT' | translate }}</div>
		<div
			[ngStyle]="{'background-color':  previousReport != undefined && report != undefined && compareReport( report.commentContract, previousReport.commentContract) ? '#E9F7FF' : ''}"
			class="report-data-item flex-grow-1 report-axis-summary">
			<textarea [(ngModel)]="report.commentContract" [placeholder]=" 'V1_REPORT.COMMENT' | translate "
					  [readOnly]="report.isClosed"
					  (blur)="focus($event)"
					  (focus)="focus($event)"
					  cdkTextareaAutosize spellcheck="false"></textarea>
		</div>
		<div *ngIf="comparingReports" class="report-data-item flex-grow-1 previous-report-data report-axis-summary">
			<p>{{ previousReport.commentContract }}</p>
		</div>
	</div>
</div>
