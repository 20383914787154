import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberInputComponent} from './number-input.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NumberInputMinDirective} from './number-input-min.directive';
import {NumberInputDirective} from './number-input.directive';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	declarations: [NumberInputComponent, NumberInputMinDirective, NumberInputDirective],
	exports: [NumberInputComponent, NumberInputMinDirective, NumberInputDirective],
	imports: [
		CommonModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		TranslateModule
	]
})
export class NumberInputModule {
}
