import {
	ExcelAlignement,
	ExcelCellBorder,
	ExcelCellColor,
	ExcelCellConfig,
	ExcelCellFont
} from '../../core/service/excel/excel.dto';

export class ECCProjectTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFFFFF'},
				bgColor: {argb: 'FFFFFF'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 24,
			color: {argb: 'FF000000'}
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
	}
}

export class ECCBodyTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'C5D9F1'},
				bgColor: {argb: 'C5D9F1'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 10,
			color: {argb: '1F294E'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			top: {style: 'thin', color: {argb: 'FF1F294E'}},
			bottom: {style: 'thin', color: {argb: 'FF1F294E'}},
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCBodyRepartition extends ExcelCellConfig {

	constructor(hasRightBorder: boolean = true) {
		super();
		this.init(hasRightBorder);
	}

	init(hasRightBorder: boolean): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'C5D9F1'},
				bgColor: {argb: 'C5D9F1'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 10,
			color: {argb: '1F294E'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center'
		} as ExcelAlignement;
		if (hasRightBorder) {
			this.cellBorder = {
				top: {style: 'thin', color: {argb: 'FF1F294E'}},
				bottom: {style: 'thin', color: {argb: 'FF1F294E'}},
				right: {style: 'thin', color: {argb: 'FF1F294E'}}
			} as ExcelCellBorder;
		} else {
			this.cellBorder = {
				top: {style: 'thin', color: {argb: 'FF1F294E'}},
				bottom: {style: 'thin', color: {argb: 'FF1F294E'}}
			} as ExcelCellBorder;
		}
	}
}

export class ECCCategorieTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FF1F294E'},
				bgColor: {argb: '1F294E'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 11,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCCategorieSousRepartition extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FF1F294E'},
				bgColor: {argb: '1F294E'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 11,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCCategorieEmptyCell extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FF31859C'},
				bgColor: {argb: '31859C'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
	}
}

export class ECCSousCategorieTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFF3F7FA'},
				bgColor: {argb: 'FFF3F7FA'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 10,
			color: {argb: 'FF5A6381'}
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
			bottom: {style: 'thin', color: {argb: 'D9D9D9'}}
		} as ExcelCellBorder;
	}
}

export class ECCSousCategorieSousRepartition extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFF3F7FA'},
				bgColor: {argb: 'FFF3F7FA'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 10,
			color: {argb: 'FF1F294E'},
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
			bottom: {style: 'thin', color: {argb: 'D9D9D9'}}
		} as ExcelCellBorder;
	}
}

export class ECCSousCategorieSousRepartitionOptionnel extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFF3F7FA'},
				bgColor: {argb: 'FFF3F7FA'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 9,
			color: {argb: '65676E'},
			italic: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			bottom: {style: 'thin', color: {argb: 'D9D9D9'}}
		} as ExcelCellBorder;
	}
}

export class ECCTotalDeveloppementTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCTotalDeveloppementSousRepartition extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCTotalDeveloppementEmptyCell extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
	}
}

export class ECCTotalGlobalJourTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCTotalGlobalJourSousRepartition extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		} as ExcelAlignement;
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCTotalGlobalJourEmptyCell extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellColor = {
			fill: {
				patternType: 'solid',
				fgColor: {argb: 'FFE46C0A'},
				bgColor: {argb: 'FFE46C0A'}
			}
		} as ExcelCellColor;
		this.cellFont = {
			name: 'arial',
			size: 12,
			color: {argb: 'FFFFFFFF'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			horizontal: 'left',
			wrapText: true,
		} as ExcelAlignement;
	}
}


export class ECCBorderBottomOnly extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellBorder = {
			bottom: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCBorderRightOnly extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCBorderBottomRightOnly extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellBorder = {
			right: {style: 'thin', color: {argb: 'FF1F294E'}},
			bottom: {style: 'thin', color: {argb: 'FF1F294E'}},
		} as ExcelCellBorder;
	}
}

export class ECCCommentaire extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellFont = {
			name: 'arial',
			size: 9,
			color: {argb: '3333333'}
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
			wrapText: true,
		} as ExcelAlignement;
	}
}

export class ECCCommentaireTitle extends ExcelCellConfig {

	constructor() {
		super();
		this.init();
	}

	init(): void {
		this.cellFont = {
			name: 'arial',
			size: 10,
			color: {argb: '1F294E'},
			bold: true
		} as ExcelCellFont;
		this.cellAlignement = {
			vertical: 'middle',
		} as ExcelAlignement;
	}
}
