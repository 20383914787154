import {Component, OnDestroy, OnInit} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {ObfuscateProgressCatEnum, ObfuscateProgressData} from './dto/obfuscate-progress.objects';

@Component({
	selector: 'app-obfuscate-progress',
	templateUrl: './dialog-obfuscate-progress.component.html',
	styleUrls: ['./dialog-obfuscate-progress.component.scss'],
})
export class DialogObfuscateProgressComponent implements OnInit, OnDestroy {
	private socket: Socket;
	progress: ObfuscateProgressData = undefined;

	ngOnInit(): void {
		// the uri param (/progress) is base_url/namespace ('progress' here is the namespace)
		// but this isn't the actual endpoint that's going to be used
		// the actual url will be base_url/path
		this.socket = io('/progress', {
				path: '/api/export-bdd/obfuscate-every-project/progress'
		});

		this.socket.on('obfuscateProgressUpdate', (data: ObfuscateProgressData) => {
			this.progress = data;
		});
	}

	calculateProgress(total: number, current: number): string {
		if (total === 0) {
			return '0%';
		}
		const percentage: number = (current / total) * 100;
		return percentage + '%';
	}

	ngOnDestroy(): void {
		this.socket.disconnect();
	}
}
