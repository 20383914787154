import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HeaderModule} from '../template/header/header.module';
import {ClientComponent} from './client.component';
import {DialogClientEditModule} from './component/client-edit/dialog-client-edit.module';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatSortModule} from '@angular/material/sort';
import {UtilModule} from '../utils/util.module';
import {Title} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AdminRolePipe} from "../security/pipe/role.pipe";

const routes: Routes = [
	{path: '', component: ClientComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.CLIENT, 'gestion-clients']}},
];

@NgModule({
	declarations: [
		ClientComponent,
	],
	imports: [
		RouterModule.forChild(routes),
		MatSelectModule,
		MatIconModule,
		HeaderModule,
		MatButtonModule,
		MatInputModule,
		FormsModule,
		MatTableModule,
		CommonModule,
		MatPaginatorModule,
		DialogClientEditModule,
		OgdpPaginatorModule,
		TranslateModule,
		MatSortModule,
		UtilModule,
		AdminRolePipe,
	],
	providers: [
		Title,
	],
})

export class ClientModule {
}
