import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IChiffrageDto} from './chiffrage.dto';

@Injectable({
	providedIn: 'root'
})
export class ChiffrageService extends ApiService {

	private route: string = '/api/chiffrage';

	constructor(public http: HttpClient) {
		super(http);
	}

	update(chiffrageDto: IChiffrageDto): Observable<IChiffrageDto> {
		return this.put(this.route + '/' + chiffrageDto.id, chiffrageDto);
	}

	remove(chiffrageDto: IChiffrageDto): Observable<IChiffrageDto> {
		return this.delete(this.route + '/' + chiffrageDto.id);
	}

	addNewChiffrageBasedOnLastOne(avantVenteId: number, type: string): Observable<IChiffrageDto> {
		return this.post(this.route + '/addNewChiffrageBasedOnLastOne/' + avantVenteId, {type});
	}

	findById(chiffrageDto: IChiffrageDto): Observable<IChiffrageDto> {
		return this.get(this.route + '/' + chiffrageDto.id);
	}

	findByAvantVenteWithRepartition(avantVenteId: number): Observable<IChiffrageDto[]> {
		return this.get(this.route + '/findByAvantVenteWithRepartition/' + avantVenteId);
	}

	findAllDatasForExcelExport(chiffrageDto: IChiffrageDto): Observable<IChiffrageDto> {
		return this.get(this.route + '/findAllDatasForExcelExport/' + chiffrageDto.id);
	}
}
