import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportTempoComponent} from './export-tempo.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderModule} from '../../template/header/header.module';
import {Title} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {PermissionEnum} from '../../core/business/service/user-base/user-base.dto';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {UtilModule} from '../../utils/util.module';
import {DialogExportTempoProgressComponent} from './progress/dialog-export-tempo-progress.component';
import {DialogPopupModule} from "../../template/dialog/dialog-popup.module";

const routes: Routes = [
	{path: '', component: ExportTempoComponent, data: {roles: [PermissionEnum.ADMIN]}},
];

@NgModule({
	declarations: [ExportTempoComponent, DialogExportTempoProgressComponent],
	imports: [
		CommonModule,
		MatFormFieldModule,
		HeaderModule,
		MatInputModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		FormsModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		TranslateModule,
		UtilModule,
		DialogPopupModule
	],
	providers: [
		Title
	]
})

export class ExportTempoModule {
}
