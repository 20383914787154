<div class="week-report-content">
	<div *ngFor="let weekData of periods()" class="week-report">


		<div *ngIf="weekData.isUndefined">
			<div class="week-report-loading">
				<mat-icon>refresh</mat-icon>
			</div>
		</div>

		<div *ngIf="!weekData.isUndefined">
			<div class="week-report-title">
				<p>{{'SUIVI.TABLE.WEEK_LETTER' | translate}}{{weekData.period.week}}
					<span *ngIf="weekData.period.index !== 0">
					({{weekData.period.index}})
				</span>
					<span>
					{{weekData.period.dateBegin | date: 'dd'}}
				</span>
					<mat-icon class="arrow-title">arrow_right_alt</mat-icon>
					<span>
					{{weekData.period.dateEnd | date: 'dd'}}
				</span>
				</p>
			</div>

			<div class="week-report-no-data" *ngIf="weekData.globalBudget == undefined
				&& weekData.globalRAE == undefined
				&& weekData.globalSpentSum == undefined">
				<p>
					{{'APP.NO_DATA' | translate}}
				</p>
			</div>

			<div class="week-report-data-wrapper" *ngIf="weekData.globalBudget != undefined">
				<p class="week-report-data-label">
					{{'PROJECT.BUDGET' | translate}}
				</p>
				<p class="week-report-data">
					<span class="sp-iconmonstr-triangle-1 warning-color" *ngIf="weekData.current && budgetWarning"></span>
					{{weekData.globalBudget}}
					<span class="sp-iconmonstr-triangle-1 warning-color" *ngIf="weekData.current && budgetWarning"></span>
				</p>
			</div>

			<div class="week-report-data-wrapper" *ngIf="weekData.globalRAE != undefined">
				<p class="week-report-data-label">
					{{'PROJECT.RAE' | translate}}
				</p>
				<p class="week-report-data">
					{{weekData.globalRAE}}
				</p>
			</div>

			<div class="week-report-data-wrapper" *ngIf="weekData.globalSpentSum != undefined">
				<p class="week-report-data-label">
					{{'SUIVI.TABLE.CONSO' | translate}}
				</p>
				<p class="week-report-data">
					{{weekData.globalSpentSum}}
				</p>
			</div>
		</div>

	</div>
</div>
