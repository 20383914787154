import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {first, forkJoin, Observable} from 'rxjs';
import {MatChipInputEvent} from '@angular/material/chips';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ClientDto, IClientDto} from '../../../core/business/service/client/client.dto';
import {IProjectDto, ProjectDto, ProjectStatusEnum} from '../../../core/business/service/project/project.dto';
import {LotService} from '../../../core/business/service/lot/lot.service';
import {IUserBaseDto} from '../../../core/business/service/user-base/user-base.dto';
import {UtilsService} from '../../../utils/service/utils.service';

@Component({
	selector: 'app-dialog-projet',
	styleUrls: ['./dialog-projet-edit.component.scss'],
	templateUrl: './dialog-projet-edit.component.html'
})
export class DialogProjetEditComponent implements OnInit {

	project: IProjectDto;

	users: IUserBaseDto[] = [];
	filteredUsers: IUserBaseDto[] = [];
	clients: IClientDto[];

	commercialOptions: string[] = ['Commercial0', 'Commercial1', 'Commercial2'];
	facturationOptions: string[] = ['Facturation0', 'Facturation1', 'Facturation2'];

	projectStatusEnumKeys: string[] = Object.keys(ProjectStatusEnum);

	/**
	 * KEY CODE pour la validation dans le chips list
	 */
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];

	@ViewChild('projectStatus') projectStatus: MatSelect;

	constructor(public dialogRef: MatDialogRef<DialogProjetEditComponent>,
				private snackBar: MatSnackBar, private lotService: LotService,
				@Inject(MAT_DIALOG_DATA) public data: { project: IProjectDto, users: Observable<IUserBaseDto[]>, clients: Observable<IClientDto[]> }) {
		this.project = data.project;
		forkJoin([data.users.pipe(first()), data.clients.pipe(first())]).subscribe(([users, clients]) => {
			this.users = users.filter(u => u.actif).sort(UtilsService.dynamicSort(['firstName', 'lastName']));
			this.filteredUsers = this.users;
			this.clients = clients;
		});
	}

	ngOnInit(): void {
		if (!this.data.project?.id) {
			this.project = new ProjectDto();
			this.project.client = new ClientDto();
			this.project.v1Report = true;
			this.project.status = this.project.open ? ProjectStatusEnum.OPEN : ProjectStatusEnum.ARCHIVE;
		}

		if (!this.project.bitbucketRepositories) {
			this.project.bitbucketRepositories = [];
		}
	}

	userEquals(objOne: IUserBaseDto, objTwo: IUserBaseDto): boolean {
		return objOne?.id === objTwo?.id;
	}

	filterUsers(query: string): void {
		if (query) {
			this.filteredUsers = this.users.filter(u =>
				(u.firstName + ' ' + u.lastName).toUpperCase().includes(query.toUpperCase()));
		} else {
			this.filteredUsers = this.users;
		}
	}

	// region chip list
	addRepository(event: MatChipInputEvent): void {
		const input: HTMLInputElement = event.chipInput.inputElement;
		const value: string = event.value;

		// Add our type
		if ((value || '').trim()) {
			this.project.bitbucketRepositories.push(value.trim());
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}
	}

	removeRepository(slug: string): void {
		const index: number = this.project.bitbucketRepositories.indexOf(slug);

		if (index >= 0) {
			this.project.bitbucketRepositories.splice(index, 1);
		}
	}

	async onYesClick(): Promise<void> {
		if (this.project.name?.trim().length > 0) {
			// Protection contre des informations qu'on ne voudrait pas sauvegarder : Sinon erreur TypeOrm to heavy
			// Quand c'est undefined typeorm garde les informations présent en base de données, il n'écrase pas
			delete this.project.lots;
			delete this.project.epics;
			this.dialogRef.close(this.project);
		}
	}
}
