import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {IStoryDto} from '../story/story.dto';
import {IIssueFromJiraDto, ILinkBugsToStoryDto} from './bug-management.dto';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BugManagementService extends ApiService {

	private route: string = 'api/bug-management/';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	findAllBugs(project: string): Promise<IIssueFromJiraDto[]> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, project]));
		return firstValueFrom(this.http.get<IIssueFromJiraDto[]>(url));
	}

	async linkBugsToStory(project: string, bugs: IIssueFromJiraDto[], story: IStoryDto, int: boolean): Promise<boolean> {
		const link: ILinkBugsToStoryDto = {
			project,
			bugs,
			story,
			int
		};

		return firstValueFrom(this.http.post<boolean>(this.route, link));
	}

}
