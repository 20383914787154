import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientSelectComponent} from './client-select.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {UtilModule} from '../../../utils/util.module';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	declarations: [ClientSelectComponent],
	exports: [
		ClientSelectComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		TranslateModule,
		MatAutocompleteModule,
		MatInputModule,
		UtilModule,
	],
})

export class ClientSelectModule {
}
