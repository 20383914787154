import {IStoryDto} from '../story/story.dto';
import {IProjectDto} from '../project/project.dto';

export interface ILotDto {
	id: number;
	name: string;
	budget: number;
	estimated: number;
	order: number;
	contract: ContractStatusEnum;
	creationDate: Date;
	updateDate: Date;
	backlog: boolean;
	spentSum: number;
	remainingEXTSum: number;
	isExpend: boolean;
	finishDate: Date;
	stories: IStoryDto[];
	projectId: number;
	project: IProjectDto;
	storyLength: number;
}

export class Lot implements ILotDto {
	id: number;
	name: string;
	budget: number;
	estimated: number;
	order: number;
	contract: ContractStatusEnum;
	creationDate: Date;
	updateDate: Date;
	backlog: boolean;
	spentSum: number;
	remainingEXTSum: number;
	isExpend: boolean;
	finishDate: Date;
	stories: IStoryDto[];
	projectId: number;
	project: IProjectDto;
	storyLength: number;
}

export enum StatusEnum {
	DRAFT = 'DRAFT',
	INPROGRESS = 'INPROGRESS',
	DONE = 'DONE',
}

export enum ContractStatusEnum {
	DRAFT = 'DRAFT',
	OPEN = 'OPEN',
	SIGNED = 'SIGNED',
	FINISH = 'FINISH'
}
