import {IRepartitionDto} from '../../core/business/service/avant-vente/repartition/repartition.dto';
import {ChiffrageTypeEnum, IChiffrageDto} from '../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

export enum TotalTypeEnum {
	TOTAL_DEV = 'TOTAL_DEV',
	TOTAL_CHARGE = 'TOTAL_CHARGE',
	TOTAL_GLOBAL = 'TOTAL_GLOBAL'
}

export default class ExportExcelChiffrageTotaux {

	totalDeveloppements: Map<string, string[]>;
	totalCharges: Map<string, string[]>;
	totalGlobalJours: Map<string, string[]>;

	constructor() {
		this.totalDeveloppements = new Map<string, string[]>();
		this.totalCharges = new Map<string, string[]>();
		this.totalGlobalJours = new Map<string, string[]>();
	}

	initMapWithRepartition(chiffrage: IChiffrageDto): void {
		for (const repartition of chiffrage.repartitions) {
			if (chiffrage.type === ChiffrageTypeEnum.MINMAX) {
				this.totalDeveloppements.set(String(repartition.id + '-1'), []);
				this.totalCharges.set(String(repartition.id + '-1'), []);
				this.totalGlobalJours.set(String(repartition.id + '-1'), []);
				this.totalDeveloppements.set(String(repartition.id + '-2'), []);
				this.totalCharges.set(String(repartition.id + '-2'), []);
				this.totalGlobalJours.set(String(repartition.id + '-2'), []);
			} else {
				this.totalDeveloppements.set(String(repartition.id), []);
				this.totalCharges.set(String(repartition.id), []);
				this.totalGlobalJours.set(String(repartition.id), []);
			}

		}
		if (!chiffrage.repartitions.length) {
			this.totalDeveloppements.set('totalDevCharge', []);
			this.totalCharges.set('totalCharges', []);
			this.totalGlobalJours.set('totalGlobalJours', []);
		}

	}

	addCellToTotal(totalType: TotalTypeEnum, repartition: string, cell: string): void {
		switch (totalType) {
			case TotalTypeEnum.TOTAL_DEV:
				if (this.totalDeveloppements.has(repartition)) {
					this.totalDeveloppements.get(repartition).push(cell);
				}
				break;
			case TotalTypeEnum.TOTAL_CHARGE:
				if (this.totalCharges.has(repartition)) {
					this.totalCharges.get(repartition).push(cell);
				}
				break;
			case TotalTypeEnum.TOTAL_GLOBAL:
				if (this.totalGlobalJours.has(repartition)) {
					this.totalGlobalJours.get(repartition).push(cell);
				}
				break;
		}
	}

	getFormulaTotalDevelopment(repartition: string): any {
		return {
			formula: 'SUM(' + this.totalDeveloppements.get(repartition).join(',') + ')'
		};
	}

	getFormulaTotalGlobalJours(repartition: string, chiffrage: IChiffrageDto, beginAnnex: string, endAnnex: string): any {
		return {
			formula: 'SUM(' + this.totalDeveloppements.get(repartition).join(',') + ') + ' +
				'ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') + ') * ' +
				chiffrage.pourcentageGestionProjet + ' / 25 , 0) / 4 +' +
				' ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') +
				') * ' + chiffrage.pourcentageITTest + ' / 25 , 0) / 4 + SUM(' + beginAnnex + ':' + endAnnex + ')'
		};
	}

	getFormulaTotalGlobal(repartition: string, chiffrage: IChiffrageDto, beginAnnex: string, endAnnex: string): any {
		return {
			formula: '(SUM(' + this.totalDeveloppements.get(repartition).join(',') + ') + ' +
				'ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') + ') * ' +
				chiffrage.pourcentageGestionProjet + ' / 25 , 0) / 4 +' +
				' ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') +
				') * ' + chiffrage.pourcentageITTest + ' / 25 , 0) / 4 + SUM(' + beginAnnex + ':' + endAnnex + ')) * ' + chiffrage.tjm
		};
	}

	getFormulaPilotage(repartition: string, chiffrageDto: IChiffrageDto): any {
		return {
			formula: 'ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') +
				') * ' + chiffrageDto.pourcentageGestionProjet + ' / 25 , 0) / 4'
		};
	}

	getFormulaIntegrationTest(repartition: string, chiffrageDto: IChiffrageDto): any {
		return {
			formula: 'ROUNDUP(SUM(' + this.totalDeveloppements.get(repartition).join(',') +
				') * ' + chiffrageDto.pourcentageITTest + ' / 25 , 0) / 4'
		};
	}

	getKey(chiffrage: IChiffrageDto, repartition: IRepartitionDto, index?: number): string {
		let key: string;

		if (chiffrage.type === ChiffrageTypeEnum.MINMAX) {
			key = String(repartition.id) + '-' + String(index);
		} else {
			key = String(repartition.id);
		}

		return key;
	}
}
