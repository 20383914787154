import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import NumberFormatOptions = Intl.NumberFormatOptions;

@Component({
	selector: 'app-number-input',
	templateUrl: './number-input.component.html',
	styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, OnChanges {

	// Value
	@Input() value: number;
	@Output() valueChange: EventEmitter<number> = new EventEmitter();
	displayedValue: string;

	// Properties
	@Input() type: string = 'text';
	@Input() placeholder: string;
	@Input() required: boolean = false;
	@Input() readonly: boolean = false;
	@Input() formField: boolean = true;
	@Input() simpleInput: boolean = false;
	@Input() width: string;
	@Input() customMin: number = 0;
	@Input() formatType: 'currency' | 'number' = 'currency';
	@Input() currencyCode: string = 'EUR';
	@ViewChild('numberInput') numberInput: ElementRef<HTMLInputElement>;

	timeout: any;

	numberFormatOptions: NumberFormatOptions;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			this.value = changes.value.currentValue || 0;
			this.displayedValue = this.value.toLocaleString('fr', this.numberFormatOptions);
		}
	}

	ngOnInit(): void {
		switch (this.formatType) {
			case 'currency':
				this.numberFormatOptions = <NumberFormatOptions> {
					style: 'currency',
					currency: this.currencyCode,
					maximumFractionDigits: 2
				};
				break;
			case 'number':
				this.numberFormatOptions = <NumberFormatOptions> {
					style: 'decimal'
				};
				break;
		}

		if (!this.value) {
			this.value = 0;
		}
		this.displayedValue = this.value.toLocaleString('fr', this.numberFormatOptions);
	}

	formatValue(): void {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			// Retirer les espaces et le symbol euro
			this.value = parseFloat(('' + this.displayedValue).replace(',', '.').replace(/[^\d.,]/g, ''));
			if (!this.value || this.value < 0) {
				this.value = 0;
			}
			this.displayedValue = this.value.toLocaleString('fr', this.numberFormatOptions);
			this.valueChange.emit(this.value);
		}, 500);
	}
}
