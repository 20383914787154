export class Pagination<T> {

	public results: T[];
	public total: number;

	constructor(results: T[], total: number) {
		this.results = results;
		this.total = total;
	}
}
