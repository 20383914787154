<app-dialog-popup [popupTitle]="'SUIVI.FILTER.LOTS.SELECTION' | translate">
	<div class="dialog-content" dialog-popup-content>
		<span>{{'SUIVI.FILTER.LOTS.LOAD_LOT' | translate}}</span>
		<mat-form-field class="col-12">
			<mat-label>{{'APP.SEARCH' | translate}}</mat-label>
			<input type="text" matInput [formControl]="lotSelected" [matAutocomplete]="auto">
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
					{{option.name}} - {{'PROJECT.CONTRACT.STATUS.' + option.contract | translate}}
				</mat-option>
			</mat-autocomplete>
			<button (click)="selectedLotToAdd=undefined; $event.stopPropagation()" *ngIf="selectedLotToAdd"
					mat-icon-button matSuffix>
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<span *ngIf="selectedLotToAdd">{{'SUIVI.FILTER.LOTS.LOT_SELECTED' | translate}}</span>
		<br><br>
		<div>
			<mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
						  [indeterminate]="selection.hasValue() && !isAllSelected()"
						  (change)="data.selectionAutomatiqueLotsVisibles = false;$event ? masterToggle() : null"
						  class="checkbox-lots">
				{{'APP.SELECT_ALL' | translate}}
			</mat-checkbox>
			<mat-checkbox [(ngModel)]="data.selectionAutomatiqueLotsVisibles" (change)="selectAuto($event)"
						  class="checkbox-lots">
				{{'SUIVI.FILTER.LOTS.SELECTION_AUTO' | translate}}
			</mat-checkbox>
			<table [dataSource]="dataSource" class="w-100" mat-table>
				<ng-container matColumnDef="id">
					<td [class.table-selected-cell]="selection.isSelected(element)" *matCellDef="let element" mat-cell>
						<mat-checkbox [checked]="selection.isSelected(element)"
									  (change)="$event ? selection.toggle(element) : null"
									  (click)="data.selectionAutomatiqueLotsVisibles = false;$event.stopPropagation();">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<td [class.table-selected-cell]="selection.isSelected(element)" *matCellDef="let element"
						class="px-3 lots-listing" mat-cell>
						{{element.rowDefinition.name}}
					</td>
				</ng-container>

				<ng-container matColumnDef="statut">
					<td [class.table-selected-cell]="selection.isSelected(element)" *matCellDef="let element"
						class="lots-listing lots-listing-contract" mat-cell>
						{{'PROJECT.CONTRACT.STATUS.' + element.rowDefinition.contract | translate}}
					</td>
				</ng-container>

				<tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
			</table>
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="validateSelection()" class="its-button" mat-flat-button>
			{{'BUG.FILTER.VALIDATE_SELECTION' | translate}}
		</button>
	</div>
</app-dialog-popup>
