<!-- HEADER -->
<app-header [displayBreadCrumb]="('PROJECT.HEADER' | translate) + (projet.name ? ' → ' + projet.name : '')">
	<div class="center-list">
		<a [routerLink]="['/suivi/' +(projet && projet.jiraIntId?  projet.jiraIntId : '')]"
		   class="nav-proj">{{'NAVIGATION.SUIVI' | translate}}</a>
		<a [routerLink]="['/gestion/' + (projet && projet.id ? projet.id : '')]"
		   class="nav-proj active">{{'NAVIGATION.GESTION' | translate}}</a>
		<a [routerLink]="['/v1-report/' + (projet && projet.id ? projet.id : '')]"
		   *ngIf="role | role: ['ADMIN', 'USER']: permissions: ['v1-report', 'raf']"
		   class="nav-proj">{{'NAVIGATION.RAPPORT' | translate}}</a>
	</div>

	<!-- Recherche par nom -->
	<div class="ms-5">
		<app-searchbar [(value)]="filterSearchInput" (valueChange)="filter()" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<div *ngIf="gestionParameters">
		<mat-form-field *ngIf="role | role: ['ADMIN', 'USER']" class="large-select">
			<mat-label>{{'SUIVI.FILTER.DISPLAY' | translate}}</mat-label>
			<mat-select [value]="activatedParameters.displayParams"
						(selectionChange)="changeParametersSelection($event, 1)" multiple>
				<mat-option [title]="'PROJECT.DETAIL.FILTER.' + parameter.name.toUpperCase() | translate"
							[value]="parameter.name" *ngFor="let parameter of gestionParameters.displayParamsActivated">
					{{'PROJECT.DETAIL.FILTER.' + parameter.name.toUpperCase() | translate}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<mat-form-field>
		<mat-label>Statut lot</mat-label>
		<mat-select [value]="activatedParameters.statutLot" (selectionChange)="changeParametersSelection($event,2)"
					multiple>
			<mat-option [title]="'PROJECT.CONTRACT.STATUS.' + contractStatusKey | translate"
						[value]="contractStatusKey" *ngFor="let contractStatusKey of contractStatusEnumKeys">
				{{'PROJECT.CONTRACT.STATUS.' + contractStatusKey | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field *ngIf="projet.boardId">
		<mat-label>Sprint</mat-label>
		<mat-select [(ngModel)]="filterSprint" [hideSingleSelectionIndicator]="true" (selectionChange)="this.filter()">
			<mat-option [value]="null"></mat-option>
			<mat-option [value]="'noSprint'">--- {{ 'PROJECT.DETAIL.FILTER.STORIES_WITHOUT_SPRINTS' | translate }}
				---
			</mat-option>
			<mat-option [value]="sprint" *ngFor="let sprint of projet.sprints">
				<p [class.fst-italic]="sprint.status === 'future'" [class.fw-bold]="sprint.status === 'active'"
				   class="mb-0">{{sprint.name}}</p>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div *ngIf="selectedIssues.size" class="move-lot">
		<div class="font-size-12 text-center">
			<div>{{selectedIssues.size}} {{'PROJECT.DETAIL.SELECT.TOTAL' | translate}}</div>
			<div *ngIf="selectedIssues.values().next().value.lotId === backlogId">
				<a href="javascript:void(0)" (click)="selectAllBacklogIssues()">{{'APP.SELECT_ALL' | translate}}</a>
			</div>
		</div>
		<div>
			<button [matMenuTriggerFor]="moveToLot" class="its-button" mat-flat-button>
				{{'PROJECT.DETAIL.SELECT.MOVE' | translate}}
			</button>
			<mat-menu #moveToLot="matMenu" class="itsf-override">
				<ng-container *ngFor="let lot of getLotWithoutBacklogAndFinish()">
					<button (click)="moveSelectedIssuesToLot(lot)" mat-menu-item>
						<span class="ms-2 color-blueNavy-2">{{lot.name}}</span>
					</button>
				</ng-container>
			</mat-menu>
		</div>
	</div>

	<div class="actions">
		<div *ngIf="projet.lastJIRASyncDate" class="synchro-info">
			{{'PROJECT.SYNCHRO' | translate}} {{projet.lastJIRASyncDate | date: 'dd/MM/yyyy HH:mm:ss'}}
		</div>
		<button (click)="openDialogExportLot()" class="its-button" disabled
				mat-flat-button>{{'APP.EXPORT' | translate}}</button>
		<button [disabled]="synchronizationInProgress || !userIsAdminOrCP" (click)="synchronize()" class="its-button"
				mat-flat-button>
			{{'APP.SYNC' | translate}}
		</button>
	</div>
</app-header>

<!-- PAGE CONTENT -->
<div class="content">
	<!-- Titre projet et infos -->
	<div class="project-title d-flex">
		<div class="right-border background-lightblue" style="width: 700px">
			<div class="position-relative d-flex align-items-center w-100 h-100">
				<div class="project-logo">
					<img [alt]="'PROJECT.LOGO' | translate" [src]="projet.client.logo | safizer"
						 *ngIf="projet?.client?.logo"
						 class="logo">
				</div>

				<div class="d-flex flex-column ms-3">
					<div class="position-relative">
						<div class="table-blue d-flex">
							{{projet.name}}
							<div (click)="openDialogProjectAlert()" class="d-flex flex-row">
								<app-project-messages [numberAlerts]="projectAlert"></app-project-messages>
							</div>
						</div>
						<div>
							<div *ngIf="(role | client); then displayExt else displayBoth"></div>
							<ng-template #displayExt>
								<a [href]="configurationUrlJira?.value + projet?.jiraExtId" class="table-grey"
								   target="_blank">{{projet.jiraExtId}}</a>
							</ng-template>
							<ng-template #displayBoth>
								<a [href]="configurationUrlJira?.value + projet?.jiraIntId" class="table-grey"
								   target="_blank">{{projet.jiraIntId}}</a>
								<span class="table-grey"> | </span>
								<a [href]="configurationUrlJira?.value + projet?.jiraExtId" class="table-grey"
								   target="_blank">{{projet.jiraExtId}}</a>
							</ng-template>

						</div>
					</div>
				</div>

				<!-- Budget, RAE, status -->
				<div class="d-flex flex-column ms-3 project-info">
					<div class="d-flex align-items-end justify-content-start project-stats">
						<div *ngIf="projet.company">
							<div>Entreprise</div>
							<div class="color-grey">
								<span>{{projet.company.name}}</span>
							</div>
						</div>
						<div>
							<div>{{'PROJECT.BUDGET' | translate}}</div>
							<div class="color-grey">
								<span>{{projet.budget || 0 | number:'1.0-3'}}</span>
							</div>
						</div>
						<div>
							<div>{{'PROJECT.RAE' | translate}}</div>
							<div class="color-grey">{{projet.remainingEXTSum || 0 | number:'1.0-3'}}</div>
						</div>
						<div>
							<div>{{'PROJECT.STATUS.TITLE' | translate}}</div>
							<div class="color-grey">{{'PROJECT.STATUS.' + projet.status | translate}}</div>
						</div>
					</div>
				</div>

				<!-- Menu en hover -->
				<div *ngIf="userIsAdminOrCP" class="absolute-position align-items-center ps-2">
					<button [title]="'LOT.ADD' | translate" (click)="addLot()" class="add-task me-2" color="primary"
							mat-mini-fab>
						<mat-icon>add</mat-icon>
					</button>

					<button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();" class="more-button"
							color="warn" mat-icon-button>
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #menu="matMenu" class="itsf-override">
						<button (click)="editProject()" mat-menu-item>
							<span class="sp-edit gradient"></span>
							<span class="ms-2">{{'APP.EDIT' | translate}}</span>
						</button>
						<button (click)="deleteProject()" mat-menu-item>
							<span class="sp-delete gradient"></span>
							<span class="ms-2">{{'APP.DELETE' | translate}}</span>
						</button>
					</mat-menu>
				</div>
			</div>

		</div>
		<div class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.STATUS.TITLE' | translate}}
			</div>
		</div>
		<div class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.BUDGET' | translate}}
			</div>
		</div>
		<div class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.PROD' | translate}}
			</div>
		</div>
		<div class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.RAE' | translate}}
			</div>
		</div>
		<div class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.AVANC' | translate}}
			</div>
		</div>
		<div *ngIf="activatedParameters && activatedParameters.displayParams | includes: 'estimation'"
			 class="d-flex right-border">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 text-uppercase property">
				{{'PROJECT.ESTI' | translate}}
			</div>
		</div>
		<div *ngIf="activatedParameters && activatedParameters.displayParams | includes: 'jira_ext'"
			 class="d-flex right-border jira-browser-url-header">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12 property">
				{{'PROJECT.JIRA_ID_EXT' | translate}}
			</div>
		</div>
		<div *ngIf="activatedParameters && activatedParameters.displayParams | includes: 'sprint'"
			 class="d-flex justify-content-center right-border sprint-url">
			<div class="align-self-center text-center color-blue-navy-2 fw-bold font-size-12">
				{{'PROJECT.SPRINT' | translate | uppercase}}
			</div>
		</div>
	</div>
	<!-- Form création lot -->
	<div *ngIf="displayLotForm" class="background-lightblue" id="add-lot">
		<div class="h-100 d-flex align-items-center" style="width: 698px; background-color: white !important">
			<div class="new-lot color-blue-navy-2">{{'LOT.NEW' | translate}}</div>
			<mat-form-field class="lot-name">
				<mat-label>{{'LOT.NAME' | translate}}</mat-label>
				<input [(ngModel)]="selectedLot.name" (keydown.enter)="onLotFormValidation()"
					   (keyup.escape)="cancelAddLot()" itsfInputAutofocus matInput>
			</mat-form-field>
			<div class="ms-auto">
				<button (click)="onLotFormValidation()" class="material-icons gradient" mat-icon-button>done</button>
				<button (click)="cancelAddLot()" class="material-icons gradient" mat-icon-button>close</button>
			</div>
		</div>
	</div>

	<mat-tree [cdkDropListDisabled]="selectedIssue != null" [cdkDropListLockAxis]="'y'" [dataSource]="matTreeDataSource"
			  [treeControl]="treeControl" (cdkDropListDropped)="dragDrop($event)" #tree cdkDropList>
		<mat-tree-node [appTreeNodeClasses]="element" [cdkDragData]="element"
					   [cdkDragDisabled]="!!element.parentLot.backlog || element.parentLot.contract === 'FINISH' || !userIsAdminOrCP"
					   [hideEmptyEpic]="activatedParameters.displayParams | includes: 'hide_empty_epic'"
					   [isIssueForm]="isIssueForm"
					   [selectedIssue]="keyboardSelectedObject?.nodeIndex"
					   [shouldDisplayTreeNode]="shouldDisplayTreeNode"
					   (click)="setKeyboardSelectedObject(element)"
					   *matTreeNodeDef="let element"
					   cdkDrag>
			<ng-container *ngIf="isIssueForm(element); then editIssue else displayIssue"></ng-container>
			<ng-template #displayIssue>
				<!-- Informations -->
				<div class="col-informations">
					<ng-container *ngIf="element.isLot">
						<div *ngIf="!element.object.backlog && !element.object.editing"
							 class="d-flex position-relative align-items-center w-100">
							<div (click)="toggleExpandedState(element.object)"
								 class="d-flex position-relative align-items-center cursor-pointer" matTreeNodeToggle>
								<div [ngClass]="treeControl.isExpanded(element) ? 'rotate-180' : 'rotate-90'"
									 class="sp-up-arrow up-arrow">
								</div>
								<div [title]="element.object.id" class="d-flex flex-column">
									<div>
										<span>{{element.object.name}}</span>
										<span class="color-grey-light-2 fst-italic">
											- {{element.object.nombreDeStory}} {{'PROJECT.STORIES' | translate}}
										</span>
									</div>
								</div>
							</div>

							<div class="absolute-position align-items-center">
								<button [title]="'APP.FOLD_ALL' | translate"
										(click)="$event.stopPropagation();expandAll(element, false);"
										*ngIf="treeControl.isExpanded(element)" class="fold-button" color="primary"
										mat-mini-fab>
									<mat-icon>unfold_less</mat-icon>
								</button>
								<button [title]="'APP.UNFOLD_ALL' | translate"
										(click)="$event.stopPropagation();expandAll(element, true);" class="fold-button"
										color="primary"
										mat-mini-fab>
									<mat-icon>unfold_more</mat-icon>
								</button>
								<button [title]="'EPIC.ADD' | translate"
										(click)="$event.stopPropagation(); addIssueForm(null, null, element.parentLot);"
										*ngIf="element.object.contract != 'FINISH' && userIsAdminOrCP" class="add-task"
										color="primary" mat-mini-fab>
									<mat-icon>add</mat-icon>
								</button>
								<button [matMenuTriggerFor]="menu"
										(click)="$event.stopPropagation();"
										*ngIf="element.object.contract != 'FINISH' && userIsAdminOrCP"
										class="more-button ms-2"
										color="warn"
										mat-icon-button>
									<mat-icon>more_horiz</mat-icon>
									<mat-menu #menu="matMenu" class="itsf-override">
										<button (click)="editLot(element.object)" mat-menu-item>
											<span class="sp-edit gradient"></span>
											<span class="ms-2">{{'APP.EDIT' | translate}}</span>
										</button>
										<button (click)="removeLot(element.object)" mat-menu-item>
											<span class="sp-delete gradient"></span>
											<span class="ms-2">{{'APP.DELETE' | translate}}</span>
										</button>
									</mat-menu>
								</button>
							</div>
						</div>
						<div *ngIf="element.object.editing">
							<div class="d-flex align-items-center col-informations"
								 style="background-color: white !important">
								<div class="new-lot color-blue-navy-2">
									{{'LOT.EDIT' | translate}}
								</div>
								<mat-form-field class="lot-name">
									<mat-label>{{'LOT.NAME' | translate}}</mat-label>
									<input [(ngModel)]="element.object.name"
										   (keydown.enter)="confirmEditLot(element.object)"
										   (keyup.escape)="cancelEditLot(element.object)" itsfInputAutofocus matInput>
								</mat-form-field>
								<div class="ms-auto d-flex delete-button">
									<button (click)="$event.stopPropagation();confirmEditLot(element.object)"
											class="material-icons gradient" mat-icon-button>done
									</button>
									<button (click)="$event.stopPropagation();cancelEditLot(element.object)"
											class="material-icons gradient" mat-icon-button>close
									</button>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="!element.isLot">
						<div class="d-flex position-relative align-items-center w-100">
							<div (click)="toggleExpandedState(element.object)"
								 *ngIf="element.expandable && !element.parentLot.backlog" class="arrow-container"
								 matTreeNodeToggle>
								<div [ngClass]="treeControl.isExpanded(element) ? 'rotate-180' : 'rotate-90'"
									 class="sp-up-arrow up-arrow cursor-pointer"></div>
							</div>
							<div *ngIf="!element.expandable || element.parentLot.backlog" style="width: 36px"></div>
							<div style="width: 23px">
								<div
									[ngClass]="element.object | picto: element.object.type : !!element.parentLot.backlog"
									class="mx-1" style="max-width: 14px; cursor:move;">
									<i class="path1"></i>
									<i class="path2"></i>
								</div>
							</div>
							<div style="width: 18px">
								<mat-checkbox [(ngModel)]="element.checked"
											  (change)="onIssueCheck($event, element.object)"
											  *ngIf="element.object.type == 'STORY' && (element.parentLot.contract == 'DRAFT'
											  || element.parentLot.contract == 'OPEN' || element.parentLot.contract == 'SIGNED')"
											  class="table-checkbox">
								</mat-checkbox>
							</div>
							<!-- Numéro jira -->
							<div class="jira-browser-url">
								<div *ngIf="(role | client); then displayUrlExt else displayUrlInt"></div>
								<ng-template #displayUrlExt>
									<a [href]="element.object.urlJiraExtId" *ngIf="element.object.jiraExtId"
									   class="jira-id"
									   target="_blank">{{element.object.jiraExtId}}</a>
									<div *ngIf="!element.object.urlJiraExtId" class="jira-id">
										{{element.object.jiraExtId}}
									</div>
								</ng-template>
								<ng-template #displayUrlInt>
									<a [href]="element.object.urlJiraIntId" *ngIf="element.object.jiraIntId"
									   class="jira-id" target="_blank">{{element.object.jiraIntId}}</a>
									<div *ngIf="!element.object.urlJiraIntId" class="jira-id">
										{{element.object.jiraIntId}}
									</div>
								</ng-template>
							</div>
							<div class="text-overflow name d-flex align-items-center position-relative">
								<div class="text-overflow d-flex justify-content-start">
									<div [matTooltipDisabled]="divName.offsetWidth === divName.scrollWidth"
										 [matTooltip]="element.object.name" #divName class="text-overflow">
										<span
											*ngIf="!filterSearchInput || !element.object.matchesFilter || !element.object.highlightedName.length">
											{{element.object.name}}
										</span>
										<ng-container
											*ngIf="filterSearchInput && element.object.matchesFilter && element.object.highlightedName.length">
											<span>{{element.object.highlightedName[0]}}</span>
											<span class="highlighted">{{element.object.highlightedName[1]}}</span>
											<span>{{element.object.highlightedName[2]}}</span>
										</ng-container>
										<span
											*ngIf="element.object.type === 'EPIC' && divName.offsetWidth === divName.scrollWidth"
											class="nb-stories">
											({{element.nbChildren}})
										</span>
									</div>
									<span
										*ngIf="element.object.type === 'EPIC' && divName.offsetWidth !== divName.scrollWidth"
										class="nb-stories me-1">
										({{element.nbChildren}})
									</span>
								</div>

								<div *ngIf="(element.object.orphan && !element.parentLot.backlog)"
									 class="float-end me-2 color-orange-1 fw-bold">
									<u>EPIC ?</u>
								</div>
								<div *ngIf="element.parentLot.backlog" class="float-end me-2 color-red fw-bold">
									<u>LOT ?</u>
								</div>

								<div class="absolute-position align-items-center">
									<ng-container *ngIf="element.object.type === 'EPIC'">
										<button [title]="'APP.FOLD_ALL' | translate"
												(click)="$event.stopPropagation();expandAll(element, false);"
												*ngIf="treeControl.isExpanded(element)" class="fold-button"
												color="primary"
												mat-mini-fab>
											<mat-icon>unfold_less</mat-icon>
										</button>
										<button [title]="'APP.UNFOLD_ALL' | translate"
												(click)="$event.stopPropagation();expandAll(element, true);"
												class="fold-button"
												color="primary"
												mat-mini-fab>
											<mat-icon>unfold_more</mat-icon>
										</button>
									</ng-container>
									<ng-container
										*ngIf="element.parentLot.contract != 'FINISH' && !element.parentLot.backlog && userIsAdminOrCP">
										<button
											[title]="'PROJECT.DETAIL.ADD_STORY_SUBTASK' | translate"
											(click)="$event.stopPropagation();addIssueForm(element.object.id, element.object.type, element.parentLot);"
											*ngIf="element.object.type !== 'SUBTASK'"
											class="add-task me-2"
											color="primary"
											mat-mini-fab>
											<mat-icon>add</mat-icon>
										</button>
										<button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"
												class="more-button" color="warn" mat-icon-button>
											<mat-icon>more_horiz</mat-icon>
										</button>
										<mat-menu #menu="matMenu" class="itsf-override">
											<button (click)="editIssueForm(element.object)" mat-menu-item>
												<span class="sp-edit gradient"></span>
												<span class="ms-2">{{'APP.EDIT' | translate}}</span>
											</button>
											<button (click)="removeIssue(element.object)" *ngIf="element.contract != 'SIGNED' || (element.contract == 'SIGNED'
											 && element.object.budget == element.object.remainingEXT )" mat-menu-item>
												<span class="sp-delete gradient"></span>
												<span class="ms-2">{{'APP.DELETE' | translate}}</span>
											</button>
										</mat-menu>
									</ng-container>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<!-- Statut -->
				<div *ngIf="element.isLot" class="col-prop">
					<ng-container *ngIf="!element.object.backlog">
						<button [matMenuTriggerFor]="opt"
								class="button-override d-flex align-items-center pen-div-modification justify-content-center w-100"
								title="{{'PROJECT.CONTRACT.STATUS.' + element.object.contract | translate}}">
							<span [ngClass]="'sp-' + element.object.contract"></span>
							<div class="sp-up-arrow arrow-modification rotate-180 pe-2"></div>
						</button>
						<mat-menu #opt="matMenu" class="mat-menu-override-description">
							<div [class.mat-menu-with-desc-disabled]="element.object.contract === contractStatusEnumKey"
								 [disabled]="!userIsAdminOrCP"
								 (click)="changeLotState(element.object, contractStatusEnumKey)"
								 *ngFor="let contractStatusEnumKey of contractStatusEnumKeys" mat-menu-item>
								<div class="d-flex flex-row align-items-center">
									<span class="sp-{{contractStatusEnumKey}} gradient"></span>
									<div class="d-flex flex-column">
										<span
											class="ms-2 fw-bold">{{'PROJECT.CONTRACT.STATUS.' + contractStatusEnumKey | translate}}</span>
										<span
											class="ms-2 mb-2 description">{{ 'PROJECT.CONTRACT.DESCRIPTION.' + contractStatusEnumKey | translate }}</span>
									</div>
								</div>
							</div>
						</mat-menu>
					</ng-container>
				</div>
				<div #col *ngIf="!element.isLot" class="col-prop">
					<div (dblclick)="getIssuesNextStatus(element, col)"
						 *ngIf="element.object.status && element.object.storyType && element.object.storyType !== 'CONTINGENCY'">
						<ng-container *ngIf="!element.object.updatingStatus">
						<span
							class="tag-{{element.object.status.key}}">{{'JIRA.STATUS.' + element.object.status.key | translate}}</span>
						</ng-container>
						<ng-container *ngIf="element.object.updatingStatus">
							<button [matMenuTriggerFor]="statusMenu" class="status-update-btn" type="button">
							<span
								class="tag-{{element.object.status.key}}">{{'JIRA.STATUS.' + element.object.status.key | translate}}</span>
								<div class="sp-up-arrow arrow-modification d-inline-block rotate-180 pe-2"></div>
							</button>
							<mat-menu #statusMenu="matMenu" class="mat-menu-override-description">
								<div (click)="changeIssueStatus(element.object, nextStatus)"
									 *ngFor="let nextStatus of element.object.availableNextStatus"
									 class="menu-tag-container"
									 mat-menu-item>
								<span
									class="tag-{{nextStatus.key}}">{{'JIRA.STATUS.' + nextStatus.key | translate}}</span>
								</div>
							</mat-menu>
						</ng-container>
					</div>
				</div>
				<!-- Budget -->
				<div class="col-prop">
					<ng-container *ngIf="!element.object.backlog">
						{{element.object.budget | number:'1.0-3'}}
					</ng-container>
				</div>
				<!-- Prod -->
				<div class="col-prop">
					<ng-container *ngIf="!element.object.backlog">
						{{element.object.budget - (element.isLot ? element.object.RAE : element.object.remainingEXT) | number:'1.0-3'}}
					</ng-container>
				</div>
				<!-- RAE -->
				<div class="col-prop">
					<ng-container *ngIf="!element.object.backlog">
						{{element.isLot ? element.object.RAE : element.object.remainingEXT | number:'1.0-3'}}
					</ng-container>
				</div>
				<!-- Avancement -->
				<div class="col-prop">
					<ng-container *ngIf="!element.object.backlog">
						{{element.object.avancement}}
					</ng-container>
				</div>
				<!-- Estimated -->
				<ng-container
					*ngIf="!element.object.backlog && activatedParameters.displayParams | includes: 'estimation'">
					<div class="col-prop">
						{{element.object.estimated | number:'1.0-3'}}
					</div>
				</ng-container>
				<!-- JiraExtId -->
				<ng-container *ngIf="activatedParameters.displayParams | includes: 'jira_ext'">
					<div *ngIf="role | role: ['ADMIN', 'USER']"
						 class="col-prop jira-browser-url">
						<a [href]="element.object.urlJiraExtId" target="_blank">
							{{element.object.jiraExtId ? element.object.jiraExtId : ''}}
						</a>
					</div>
				</ng-container>
				<!-- Sprint -->
				<ng-container
					*ngIf="activatedParameters.displayParams | includes: 'sprint' && element?.object?.object?.sprint?.status ">
					<div [class.fst-italic]="element.object.object.sprint?.status === 'future'"
						 [class.fw-bold]="element.object.object.sprint?.status === 'active'"
						 *ngIf="role | role: ['ADMIN', 'USER']"
						 class="col-prop sprint-url">
						<a [href]="element.object?.object?.sprint?.jiraId | sprintUrl: projet.jiraIntId"
						   target="_blank">
							{{element.object.object.sprint?.name}}
						</a>
					</div>
				</ng-container>
				<div class="col-filler" style="overflow: hidden;">
					<div *ngIf="element.parentLot.backlog && element.object.object.epic" class="epic-infos">
						<a class="fw-bold"
						   href="https://itsfuture-jira.atlassian.net/browse/{{ element.object.object.epic.jiraIntId }}"
						   target="_blank">
							{{element.object.object.epic.jiraIntId}}
						</a> - {{element.object.object.epic.name}}
					</div>
					<div *ngIf="!element.parentLot.backlog" class="keyboard-hints">
						{{(element.isLot ? 'LOT' : element.object.type) + '.KEYBOARD_HINT' | translate}}
					</div>
				</div>
			</ng-template>
			<ng-template #editIssue>
				<div class="background-lightblue">
					<div [ngSwitch]="selectedIssue.type"
						 class="h-100 d-flex align-items-center"
						 style="width: 698px; background-color: white !important">

						<div *ngIf="selectedIssue.id || selectedIssue.type === 'EPIC'"
							 class="mx-1 sp-{{selectedIssue.type}} sp-{{selectedIssue.type}}-{{selectedIssue.storyType}} sp-{{selectedIssue.type}}-{{selectedIssue.subtaskType}}">
							<i class="path1"></i>
							<i class="path2"></i>
						</div>
						<mat-select [(ngModel)]="selectedIssue.storyType" [hideSingleSelectionIndicator]="true"
									*ngIf="!selectedIssue.id && selectedIssue.type === 'STORY'"
									class="story-type-select">
							<mat-option [value]="type" *ngFor="let type of storyTypes">
								<div [ngClass]="'sp-' + selectedIssue.type + '-' + type"
									 class="mx-1 d-inline-block">
									<i class="path1"></i>
									<i class="path2"></i>
								</div>
								<span>{{'PROJECT.DETAIL.ISSUE.STORY.TYPE.' + type | translate}}</span>
							</mat-option>
							<mat-select-trigger>
								<div [ngClass]="'sp-' + selectedIssue.type + '-' + selectedIssue.storyType"
									 class="mx-1 d-inline-block">
									<i class="path1"></i>
									<i class="path2"></i>
								</div>
							</mat-select-trigger>
						</mat-select>
						<mat-select [(ngModel)]="selectedIssue.subtaskType" [hideSingleSelectionIndicator]="true"
									*ngIf="!selectedIssue.id && selectedIssue.type === 'SUBTASK'"
									class="story-type-select">
							<mat-option [value]="type" *ngFor="let type of subtaskTypes">
								<div [ngClass]="'sp-' + selectedIssue.type + '-' + type"
									 class="mx-1 d-inline-block">
									<i class="path1"></i>
									<i class="path2"></i>
								</div>
								<span>{{'PROJECT.DETAIL.ISSUE.SUBTASK.TYPE.' + type | translate}}</span>
							</mat-option>
							<mat-select-trigger>
								<div
									[ngClass]="'sp-' + selectedIssue.type + '-' + selectedIssue.subtaskType"
									class="mx-1 d-inline-block">
									<i class="path1"></i>
									<i class="path2"></i>
								</div>
							</mat-select-trigger>
						</mat-select>

						<div class="new-lot text-capitalize color-jira-{{selectedIssue.type}}
									color-jira-{{selectedIssue.type}}-{{selectedIssue.storyType}}
									color-jira-{{selectedIssue.type}}-{{selectedIssue.subtaskType}}">

							<ng-container *ngIf="!selectedIssue.id">
								<span *ngIf="('' + selectedIssue.subtaskType).indexOf('SUBBUG') !== -1">
									{{'APP.NEW' | translate}} </span>
								<span *ngIf="selectedIssue.type === 'EPIC'">
									{{'APP.NEW_LIAISON' | translate}} </span>
								<span
									*ngIf="('' + selectedIssue.subtaskType).indexOf('SUBBUG') === -1 && selectedIssue.type !== 'EPIC'">
									{{'APP.NEW_FEMININE' | translate}} </span>
							</ng-container>
							<span *ngIf="selectedIssue.id">{{'APP.MODIFICATION' | translate}} </span>

							<span *ngIf="selectedIssue.type === 'EPIC'">
								{{'PROJECT.DETAIL.ISSUE.EPIC.TYPE.REGULAR' | translate}}</span>
							<span *ngIf="selectedIssue.type === 'STORY'">
								{{'PROJECT.DETAIL.ISSUE.STORY.TYPE.' + selectedIssue.storyType | translate}}</span>
							<span *ngIf="selectedIssue.type === 'SUBTASK'">
								{{'PROJECT.DETAIL.ISSUE.SUBTASK.TYPE.' + selectedIssue.subtaskType | translate}}</span>
						</div>

						<mat-form-field class="lot-name">
							<mat-label>{{'PROJECT.NAME' | translate}}</mat-label>
							<input [(ngModel)]="selectedIssue.name" (keydown.enter)="onIssueFormValidation()"
								   (keyup.escape)="cancelIssueForm()" itsfInputAutofocus matInput>
						</mat-form-field>

						<ng-container *ngSwitchCase="'STORY'">
							<mat-form-field *ngIf="selectedIssue.storyType !== 'INT'" class="lot-budget">
								<mat-label>{{'PROJECT.BUDGET' | translate}}</mat-label>
								<input [(ngModel)]="selectedIssue.budget" [bigStep]="1" [doBlurEvent]="false"
									   [smallStep]="0.125" (keydown.enter)="onIssueFormValidation()"
									   (keyup.escape)="cancelIssueForm()" appNumberInput autocomplete="off"
									   formatType="number" matInput type="text">
								<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
							</mat-form-field>
							<div *ngIf="!(role | client) && projet.jiraExtId">
								<mat-form-field class="lot-budget">
									<mat-label>{{'PROJECT.ESTI' | translate}}</mat-label>
									<input [(ngModel)]="selectedIssue.estimated" [bigStep]="1"
										   [disabled]="!projet.jiraExtId" [doBlurEvent]="false" [smallStep]="0.125"
										   (keydown.enter)="onIssueFormValidation()" (keyup.escape)="cancelIssueForm()"
										   appNumberInput autocomplete="off" formatType="number" matInput>
									<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
								</mat-form-field>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'SUBTASK'">
							<mat-form-field class="lot-budget">
								<mat-label>{{'PROJECT.ESTI' | translate}}</mat-label>
								<input [(ngModel)]="selectedIssue.estimated" [bigStep]="1" [doBlurEvent]="false"
									   [smallStep]="0.125" (keydown.enter)="onIssueFormValidation()"
									   (keyup.escape)="cancelIssueForm()" appNumberInput autocomplete="off"
									   formatType="number" matInput>
								<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
							</mat-form-field>
						</ng-container>
						<ng-container *ngSwitchCase="'EPIC'">
						</ng-container>
						<div class="ms-auto d-flex delete-button">
							<button (click)="onIssueFormValidation()" class="material-icons gradient" mat-icon-button>
								done
							</button>
							<button (click)="cancelIssueForm()" class="material-icons gradient" mat-icon-button>close
							</button>
						</div>
					</div>
				</div>
			</ng-template>
		</mat-tree-node>
	</mat-tree>

	<div class="d-flex footer">
		<div class="col-informations flex-end">{{'APP.TOTAL' | translate}}</div>
		<div class="col-prop"></div>
		<div class="col-prop">{{(projet.budget || 0) | number:'1.0-3'}}</div>
		<div class="col-prop">{{projet.budget - projet.remainingEXTSum | number:'1.0-3'}}</div>
		<div class="col-prop">{{projet.remainingEXTSum | number:'1.0-3'}}</div>
		<div class="col-prop">{{projet.budget | avancementProjet: projet.remainingEXTSum}}</div>
	</div>
	<div *ngIf="backlogList && backlogDisplay" class="container-missing-link">
		<div class="ml-title d-flex">
			<div style="width: 35px"></div>
			<div>Story sans lot ({{backlogList.content.length}})</div>
		</div>

		<ng-container *ngFor="let story of backlogList.content">
			<div *ngIf="story.type === 'STORY'" class="ml-story STORY backlog-issue d-flex">
				<div class="col-informations">
					<div class="d-flex position-relative align-items-center w-100">
						<div style="width: 51px"></div>
						<div style="width: 23px">
							<div
								class="mx-1 sp-iconmonstr-triangle-1 color-red font-size-12" style="max-width: 14px;">
								<i class="path1"></i>
								<i class="path2"></i>
							</div>
						</div>
						<div style="width: 27px">
							<mat-checkbox [checked]="selectedIssues.has(story.id)"
										  (change)="onIssueCheck($event, story)" class="table-checkbox"></mat-checkbox>
						</div>
						<!-- Numéro jira -->
						<div class="jira-browser-url">
							<div *ngIf="(role | client); then displayUrlExt else displayUrlInt"></div>
							<ng-template #displayUrlExt>
								<a [href]="story.urlJiraExtId" *ngIf="story.jiraExtId" class="jira-id"
								   target="_blank">{{story.jiraExtId}}</a>
							</ng-template>
							<ng-template #displayUrlInt>
								<a [href]="story.urlJiraIntId" *ngIf="story.jiraIntId" class="jira-id"
								   target="_blank">{{story.jiraIntId}}</a>
							</ng-template>
						</div>
						<div class="text-custom text-overflow name d-flex align-items-center position-relative">
							<div class="text-custom text-overflow d-flex justify-content-start">
								<div class="text-custom text-overflow">{{story.name}}</div>
								<div class="float-end me-2 color-red fw-bold text-decoration-underline">LOT ?</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-prop">
					<div>
						<span class="tag-{{story.object.status}}">
							{{'JIRA.STATUS.' + story.object.status | translate}}
						</span>
					</div>
				</div>
				<div class="col-prop">{{(story.budget || 0) | number:'1.0-3'}}</div>
				<div class="col-prop">{{(story.budget - story.remainingEXT) | number:'1.0-3'}}</div>
				<div class="col-prop">{{story.remainingEXT | number:'1.0-3'}}</div>
				<div class="col-prop"></div>
				<ng-container *ngIf="activatedParameters.displayParams | includes: 'estimation'">
					<div class="col-prop">{{story.estimated | number:'1.0-3'}}</div>
				</ng-container>
				<ng-container *ngIf="activatedParameters.displayParams | includes: 'jira_ext'">
					<div *ngIf="role | role: ['ADMIN', 'USER']" class="col-prop jira-browser-url">
						<a [href]="story.urlJiraExtId" target="_blank">{{story.jiraExtId ? story.jiraExtId : ''}}</a>
					</div>
				</ng-container>
				<ng-container *ngIf="activatedParameters.displayParams | includes: 'sprint'">
					<div [class.fst-italic]="story.object.sprint?.status === 'future'"
						 [class.fw-bold]="story.object.sprint?.status === 'active'"
						 *ngIf="role | role: ['ADMIN', 'USER']" class="col-prop sprint-url">
						<a [href]="story?.object.sprint?.jiraId | sprintUrl: projet.jiraIntId"
						   target="_blank">{{story.object.sprint?.name}}</a>
					</div>
				</ng-container>
				<div class="col-filler">
					<div *ngIf="story.object.epic" class="epic-infos">
						<a class="fw-bold"
						   href="https://itsfuture-jira.atlassian.net/browse/{{ story.object.epic.jiraIntId }}"
						   target="_blank">{{story.object.epic.jiraIntId}}</a> - {{story.object.epic.name}}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
