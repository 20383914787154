import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigurationService} from '../core/business/service/configuration/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {IConfigurationDto} from '../core/business/service/configuration/configuration.dto';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {ThemeEnum} from '../theme/themes';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-configuration',
	templateUrl: './configuration.component.html',
	styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

	configurationList: IConfigurationDto[] = [];

	constructor(private _router: Router,
				private _snackBar: MatSnackBar,
				private _translator: TranslateService,
				private _configurationService: ConfigurationService,
				private _titleService: Title) {
	}

	ngOnInit(): void {
		this._titleService.setTitle(
			ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Configuration'
		);
		this._configurationService.findAll().then(result => {
			result.forEach((c) => {
				c.inputType = (c.isSecret && c.value.length > 0) ? 'password' : 'text';
			});
			this.configurationList = result;
		});
	}


	async onKeydown(event: KeyboardEvent, elem: IConfigurationDto): Promise<void> {
		if (event.key === 'Enter' && elem.value !== 'Filled') {
			await this._configurationService.modifyConfiguration(elem, elem.id);

			if (elem.isSecret) {
				// @ts-ignore
				event.target.type = 'password';
			}

			this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
				panelClass: 'success'
			});
		}
		if (event.key === 'Backspace' && elem.isSecret) {
			elem.value = '';
			// @ts-ignore
			event.target.type = 'text';
		}
	}

	async saveButton(): Promise<void> {
		for (const config of this.configurationList) {
			if (config.value !== 'Filled') {
				await this._configurationService.modifyConfiguration(config, config.id);
			}
		}
		await this._configurationService.loadConfigOnCache();

		this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
			panelClass: 'success'
		});
	}
}

