<app-header [displayBreadCrumb]="'CONFIGURATION.HEADER' | translate">
	<div class="actions">
		<button mat-flat-button class="its-button" (click)="saveButton()"> {{'APP.SAVE' | translate}} </button>
	</div>
</app-header>

<div class="mat-elevation-z8 table-container p-3">
	<div *ngFor="let config of configurationList">
		<mat-form-field class="input-format">
			<mat-label>{{'CONFIGURATION.KEY.' + config.key | translate}}</mat-label>
			<input matInput [(ngModel)]="config.value"
				   [type]="config.inputType"
				   [title]="config.description"
				   (keydown)="onKeydown($event, config)">
		</mat-form-field>
	</div>
</div>
