<form>
	<mat-form-field>
		<input matInput [placeholder]="'Entreprise'"
			   [matAutocomplete]="auto"
			   [formControl]="selectCompanyController">
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedCompany" autoActiveFirstOption>
			<mat-option *ngFor="let company of companyFiltered" [value]="company">
				<img *ngIf="company.logo" [src]="company.logo | safizer" class="logo"/>
				<span>{{company.name}}</span>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<button class="d-flex justify-content-center align-items-center" (click)="clearFilter()" *ngIf="company"><span class="material-icons">clear</span></button>
</form>
