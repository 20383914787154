import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ChargeAnnexeService} from '../../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.service';
import {
	ChargeAnnexeDto,
	IChargeAnnexeDto
} from '../../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';
import {IChiffrageDto} from '../../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {AvantVenteBusiness} from '../../../../business/avant-vente.business';
import {IRepartitionDto} from '../../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {ISousRepartitionDto} from '../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {map, mergeMap} from 'rxjs/operators';
import {SousRepartitionService} from '../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.service';

export class DatasourceItemNode {
	displayName: string;
	children: DatasourceItemNode[];
	item: IChargeAnnexeDto;
	order: number;
}


@Injectable()
export class ChiffrageGlobalDatasource {

	dataChange: BehaviorSubject<DatasourceItemNode[]> = new BehaviorSubject<DatasourceItemNode[]>([]);

	constructor(private chargeAnnexeService: ChargeAnnexeService, private sousRepartitionService: SousRepartitionService) {
	}

	get data(): DatasourceItemNode[] {
		return this.dataChange.value;
	}


	findByChiffrage(chiffrageId: number): void {
		this.chargeAnnexeService.findByChiffrageWitSousRepartitionAndRepartition(chiffrageId).subscribe((chargeAnnexes: IChargeAnnexeDto[]) => {
			const data: DatasourceItemNode[] = this.buildFileTree(chargeAnnexes, 0);
			this.dataChange.next(data.sort(AvantVenteBusiness.sortItemNodeOrder));
		});
	}

	buildFileTree(chargeAnnexes: IChargeAnnexeDto[], level: number): DatasourceItemNode[] {
		return chargeAnnexes.reduce<DatasourceItemNode[]>((accumulator, obj) => {

			const node: DatasourceItemNode = new DatasourceItemNode();
			node.displayName = obj.titre;
			node.item = obj;
			node.order = obj.order;

			return accumulator.concat(node);
		}, []);
	}

	insertChargeAnnexeForm(name: string): void {
		this.data.push({displayName: name} as DatasourceItemNode);
		this.dataChange.next(this.data);
	}

	createChargeAnnexe(chiffrage: IChiffrageDto, node: DatasourceItemNode, partialChargeAnnexeDto: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {

		const nodeOrder: number = this.data.length;
		const newChargeAnnexe: IChargeAnnexeDto = new ChargeAnnexeDto(null, partialChargeAnnexeDto.titre, partialChargeAnnexeDto.charge, nodeOrder, chiffrage, partialChargeAnnexeDto.sousRepartitions);

		return this.chargeAnnexeService.create(newChargeAnnexe).pipe(mergeMap(createdChargeAnnexe => {
			if (createdChargeAnnexe) {
				return this.chargeAnnexeService.findByIdWithSousRepartitionAndRepartition(createdChargeAnnexe).pipe(map(chargeAnnexeWithSousRepartition => {
					if (chargeAnnexeWithSousRepartition) {
						node.displayName = chargeAnnexeWithSousRepartition.titre;
						node.order = chargeAnnexeWithSousRepartition.order;
						node.item = chargeAnnexeWithSousRepartition;
						node.children = [];

						this.dataChange.next(this.data);
					}

					return chargeAnnexeWithSousRepartition;
				}));
			}
		}));
	}

	updateChargeAnnexe(node: DatasourceItemNode, chargeAnnexe: IChargeAnnexeDto): Observable<IChargeAnnexeDto> {
		return this.chargeAnnexeService.update(chargeAnnexe).pipe(map(updatedChargeAnnexe => {
			if (updatedChargeAnnexe) {
				node.displayName = updatedChargeAnnexe.titre;
				node.item = updatedChargeAnnexe;

				this.dataChange.next(this.data);
			}

			return updatedChargeAnnexe;
		}));
	}

	deleteChargeAnnexe(node: DatasourceItemNode): void {
		this.chargeAnnexeService.remove(node.item).subscribe(deletedCategorie => {
			if (deletedCategorie) {
				this.data.splice(this.data.indexOf(node), 1);
				this.dataChange.next(this.data);
			}
		});
	}

	clearChargeAnnexe(node: DatasourceItemNode): void {
		this.data.splice(this.data.indexOf(node), 1);
		this.dataChange.next(this.data);
	}

	reloadSousRepartition(repartitionDto: IRepartitionDto): Observable<ISousRepartitionDto[]> {
		return this.sousRepartitionService.findByRepartitionWithRepartitionAndChargeAnnexe(repartitionDto).pipe(map((createdSousRepartitions: ISousRepartitionDto[]) => {
			if (createdSousRepartitions) {
				for (const datasourceItemNode of this.data) {
					if (datasourceItemNode.item?.sousRepartitions) { // on rattache à chaque charge annexe la nouvelle sous répartition
						const sousRepartitionForThisChargeAnnexe: ISousRepartitionDto = createdSousRepartitions.find(sousRepartition => sousRepartition.chargeAnnexeId === datasourceItemNode.item.id);
						if (sousRepartitionForThisChargeAnnexe) {
							datasourceItemNode.item.sousRepartitions.push(sousRepartitionForThisChargeAnnexe);
						}
					}
				}

				this.dataChange.next(this.data);
			}

			return createdSousRepartitions;
		}));
	}
}
