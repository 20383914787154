import {Component, Inject} from '@angular/core';
import {
	IssueToRedistribute,
	ILotRedistribution,
	SynchronizationService
} from '../../../../core/business/service/synchronization/synchronization.service';
import {MatTreeNode} from '../../projet-detail.component';
import {IssueTypeEnum} from '../../dto/projet-details.objects';
import {FormControl} from '@angular/forms';
import {LotDataSourceContent} from '../../datasource/projet-detail.datasource';
import {TranslateService} from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";

class IssueToRedistributeImpl implements IssueToRedistribute {
	name: string;
	issueId: number;
	jiraId: string;
	issue: MatTreeNode;
	lotId: number;
	oldBudget: number;
	newBudget: number;
	restored: boolean;
}

export class LotRedistribution {
	lot: MatTreeNode;
	deltaBudget: number;
	isForceModifBudget: boolean;
	issues: IssueToRedistributeImpl[];
	storyFormControl: FormControl;
	destStory: LotDataSourceContent;
	selectStoryFiltered: LotDataSourceContent[];
	errorMessage: string;
	isValid: boolean;
	lotId: number;
	destStoryId: number;
	sourceStories: IssueToRedistribute[];
}

@Component({
	selector: 'app-redistribution-budget-synchro',
	templateUrl: './dialog-redistribution-budget-synchro.component.html',
	styleUrls: ['./dialog-redistribution-budget-synchro.component.scss']
})
export class DialogRedistributionBudgetSynchroComponent {

	stories: LotDataSourceContent[];
	issuesByLots: LotRedistribution[];
	disableBtnValider: boolean = true;
	displayedColumns: string[] = ['issueId', 'oldBudget', 'newBudget', 'action'];

	constructor(public dialogRef: MatDialogRef<DialogRedistributionBudgetSynchroComponent>,
				@Inject(MAT_DIALOG_DATA) public data: {
					issues: IssueToRedistribute[],
					nodes: MatTreeNode[]
				},
				private _translator: TranslateService,
				private _synchronizationService: SynchronizationService,
				private _snackBar: MatSnackBar,) {

		this.issuesByLots = [];
		data.nodes.filter(node => node.isLot && data.issues.findIndex(issue => issue.lotId === node.lotId) !== -1)
			.forEach(lotNode => {
				this.issuesByLots.push({
					lot: lotNode,
					isForceModifBudget: false,
					storyFormControl: new FormControl(),
					destStory: null,
					deltaBudget: data.issues.filter(issue => issue.lotId === lotNode.object.id)
						.map(issue => issue.newBudget - issue.oldBudget).reduce((a, b) => a + b),
					issues: data.issues.filter(issue => issue.lotId === lotNode.object.id).map(issue => {
						const is: IssueToRedistributeImpl = Object.assign(new IssueToRedistributeImpl(), issue);
						is.issue = data.nodes.find(node => node.issueType === IssueTypeEnum.STORY && node.object.id === is.issueId);
						return is;
					}),
					selectStoryFiltered: [],
					errorMessage: '',
					isValid: false,
					lotId: lotNode.lotId,
					destStoryId: null,
					sourceStories: null
				});
			});
		this.stories = data.nodes.filter(node => node.issueType === IssueTypeEnum.STORY).map(node => node.object as LotDataSourceContent);
	}

	updateValidButton(): void {
		this.disableBtnValider = this.issuesByLots.some(issue => !issue.isValid);
	}

	valider(): void {
		this.dialogRef.close();
	}

	async restoreBudgetInJira(issue: IssueToRedistribute) : Promise<void>{
		await this._synchronizationService.pushStoryChangeToJira(issue.jiraId)
			.catch((err) => {
				this._snackBar.open('HTTP ' + err.status + ': ' + err.error.message, '', {
					panelClass: 'error',
					duration: 5000,
				});
			});
		this._snackBar.open('Budget restauré dans Jira pour ' + issue.jiraId);
		issue.restored = true;
		issue.newBudget = issue.oldBudget;
	}
}
