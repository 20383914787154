import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TempoJiraDatasource} from './pagination/tempo-jira.datasource';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../core/pagination/dto/pagination-option.dto';
import {Title} from '@angular/platform-browser';
import {SpinnerService} from '../core/service/spinner.service';
import {TempoJiraService} from '../core/business/service/tempo-jira/tempo-jira.service';
import {firstValueFrom, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {SearchbarComponent} from '../utils/components/searchbar/searchbar.component';
import {OgdpPaginatorComponent} from '../utils/components/paginator/ogdp-paginator.component';
import * as moment from 'moment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {ThemeEnum} from '../theme/themes';
import {UserConfigService} from '../core/business/service/user-config/user-config.service';
import {ConfigTempoJira, ConfigType} from '../core/business/service/user-config/user-config.dto';
import {ConfigDialogComponent} from './config-dialog/config-dialog/config-dialog.component';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ICompanyDto} from '../core/business/service/company/company.dto';
import {MatSelect} from '@angular/material/select';
import {CompanyService} from '../core/business/service/company/company.service';

@Component({
	selector: 'app-tempo-jira',
	templateUrl: './tempo-jira.component.html',
	styleUrls: ['./tempo-jira.component.scss']
})
export class TempoJiraComponent implements OnInit, AfterViewInit {

	displayedColumns: string[] = ['displayName', 'timeSpent'];
	dataSourcePaginate: TempoJiraDatasource;
	paginationOption: PaginationOption;
	maxDate: moment.Moment;
	selectedDate: moment.Moment;

	companies: ICompanyDto[] = [];
	companiesId: number[] = [];
	filterCompanies: ICompanyDto[];

	tempoConfig: ConfigTempoJira;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchInputUsername: SearchbarComponent;
	@ViewChild('selectCompanies') selectCompanies: MatSelect;

	constructor(private _titleService: Title,
				private _spinnerService: SpinnerService,
				private _tempoJiraService: TempoJiraService,
				private _companyService: CompanyService,
				private _activeRoute: ActivatedRoute,
				private _userConfig: UserConfigService,
				public dialog: MatDialog) {
		this.paginationOption = new PaginationOption(50, 0);
		this.paginationOption.sortBy = new PaginationSortBy('displayName', PaginationSort.ASC);
		this.tempoConfig = new ConfigTempoJira();
		this.tempoConfig.jiraID = [];
	}

	async ngOnInit(): Promise<void> {
		const queryParams: any = this._activeRoute.snapshot.queryParams;
		this.maxDate = moment().isoWeekday(1);
		this.selectedDate = queryParams['selectedDate'] ?
			moment(queryParams['selectedDate']).isoWeekday(1) >= this.maxDate ?
				this.initDate()
				: moment(queryParams['selectedDate']).isoWeekday(1)
			: this.initDate();
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Tempo JIRA');
		this.dataSourcePaginate = new TempoJiraDatasource(this._tempoJiraService, this._spinnerService);
		this.clearFilterField();
		this.companies = await firstValueFrom(this._companyService.getAllCompanies());
		this.filterCompanies = this.companies;
		this.setCompaniesId();
		this.dataSourcePaginate.setPaginationOption(this.paginationOption);
		this.loadJiraUsers();
		this.refreshURL();
	}

	ngAfterViewInit(): void {
		merge(this.searchInputUsername.valueChange, this.searchInputUsername.clearInput, this.selectCompanies.selectionChange).pipe(
			debounceTime(500),
			distinctUntilChanged(),
			tap(() => {
				this.setCompaniesId();
				this.paginator.pageIndex = 0;
				this.paginationOption.page = this.paginator.pageIndex;
				this.dataSourcePaginate.setPaginationOption(this.paginationOption);
				this.dataSourcePaginate.applyFilter();
			})
		).subscribe();

		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.dataSourcePaginate.setPaginationOption(this.paginationOption);
			this.dataSourcePaginate.applyFilter();
			this.paginator.length = this.dataSourcePaginate.totalElem;
		})).subscribe();
	}

	setCompaniesId(): void {
		this.companiesId = [];
		if (this.filterCompanies.length) {
			for (const company of this.filterCompanies) {
				this.companiesId.push(company.id);
			}
		} else {
			this.companiesId.push(this.filterCompanies[0].id);
		}
		this.dataSourcePaginate.companiesId = this.companiesId;
	}

	dateChange(e: MatDatepickerInputEvent<Date>): void {
		this.selectedDate = moment(e.value).isoWeekday(1);
		this.refreshURL();
		this.loadJiraUsers();
	}

	moveToWeek(amount: number): void {
		this.selectedDate.add(amount, 'week');
		this.refreshURL();
		this.loadJiraUsers();
	}

	initDate(): moment.Moment {
		if (moment().isoWeekday() === 1) {
			return moment().add(-1, 'week').isoWeekday(1);
		}
		return moment().isoWeekday(1);
	}

	checkNextWeek(): boolean {
		const d1: string = this.maxDate.toISOString().split('T')[0];
		const d2: string = moment(this.selectedDate).add(1, 'week').toISOString().split('T')[0];
		return moment(this.selectedDate).add(1, 'week') > moment(this.maxDate) && d1 !== d2;
	}

	clearFilterField(): void {
		this.dataSourcePaginate.username = '';
		this.dataSourcePaginate.companiesId = [];
	}

	sortData(event: any): void {
		this.dataSourcePaginate.setOrder(event.direction);
	}

	toggleIncompleteImputations(e: MatCheckboxChange): void {
		this.paginationOption.page = 0;
		this.dataSourcePaginate.incompleteImputationsOnly = e.checked;
		this.dataSourcePaginate.setPaginationOption(this.paginationOption);
		this.dataSourcePaginate.applyFilter();
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.dataSourcePaginate.setPaginationOption(this.paginationOption);
		this.dataSourcePaginate.updatePage();
	}

	getWeekInfos(): string {
		const endDate: moment.Moment = moment(this.selectedDate).add(4, 'day');
		return 'Semaine ' + this.selectedDate.format('W') + ' - du ' +
			this.selectedDate.format('DD-MM-YYYY') + ' au ' +
			endDate.format('DD-MM-YYYY');
	}

	openConfigDialog(): void {
		const dialogData: any = {users: this.dataSourcePaginate.save, config: this.tempoConfig};
		const dialog: MatDialogRef<ConfigDialogComponent> =
			this.dialog.open(ConfigDialogComponent, {
				data: dialogData,
				width: '1000px'
			});
		dialog.afterClosed().subscribe(async (ret: ConfigTempoJira) => {
			if (ret) {
				this.tempoConfig = ret;
				await firstValueFrom(this._userConfig.createOrUpdate(ConfigType.TEMPOJIRA, this.tempoConfig.jiraID))
					.then((res) => {
						this.dataSourcePaginate.notifyChanges(this.dataSourcePaginate.applyIgnoreFilter(res.config));
						this.dataSourcePaginate.totalData = this.dataSourcePaginate.datas();
						this.dataSourcePaginate.sortUsers();
						this.paginator.pageIndex = 0;
						this.paginationOption.page = this.paginator.pageIndex;
						this.paginationOption.limit = this.paginator.pageSize;
						this.dataSourcePaginate.setPaginationOption(this.paginationOption);
						this.dataSourcePaginate.applyFilter();
					});
			}
		});
	}

	private refreshURL(): void {
		const dateString: string = moment(this.selectedDate).startOf('week').add(2, 'day').toISOString().split('T')[0];
		history.pushState({}, '', window.location.origin + window.location.pathname + '?selectedDate=' + dateString);
	}

	private loadJiraUsers(): Promise<void> {
		return firstValueFrom(this._userConfig.getConfig(ConfigType.TEMPOJIRA))
			.then(res => {
				if (res) {
					this.tempoConfig.jiraID = res.config;
				} else {
					this._userConfig.createOrUpdate(ConfigType.TEMPOJIRA, []);
				}
				return this.dataSourcePaginate.getData(this.selectedDate, this.tempoConfig.jiraID);
			});
	}
}
