import {SPHeader, SPRow, SPTable} from '../dto/suivi-projet.dto';
import {CellBuilder, CellRowBuilder} from './dto/excel-cell-dto';
import {IStoryDto, StoryTypeEnum} from '../../../core/business/service/story/story.dto';
import {IProjectDto} from '../../../core/business/service/project/project.dto';

import {Cell, CellValue, Row, Workbook, Worksheet} from 'exceljs';

const BORDER_COLOR: string = 'FF1F294E';
const EPIC_BG_COLOR: string = 'FFD2D4DB';
const STORY_BG_COLOR: string = 'FFF3F7FA';
const LOT_FONT_COLOR: string = 'FFFFFFFF';
const EPIC_FONT_COLOR: string = 'FF1B1B1C';
const STORY_FONT_COLOR: string = 'FF5A6381';
const BORDER_BOTTOM_COLOR: string = 'FFDCDDDE';

export class SuiviProjetExport {

	public static generateExport(table: SPTable, startDate: Date, project: IProjectDto, displayProduitPeriod: boolean): Workbook {
		const workbook: Workbook = new Workbook();

		const worksheet: Worksheet = workbook.addWorksheet('Suivi');

		this.generateHeaders(worksheet, project.name, table.header, startDate, displayProduitPeriod);
		this.generateBody(worksheet, table.body, startDate, displayProduitPeriod, project);
		this.generateFooter(worksheet, table.footer, startDate, displayProduitPeriod);
		this.formatFile(workbook, project, displayProduitPeriod);
		return workbook;
	}

	public static generateHeaders(worksheet: Worksheet,
								  projectName: string,
								  header: SPHeader,
								  startDate: Date,
								  displayProduitPeriod: boolean): void {
		// Periodes (ex : 5/7 | 11/7)
		const periodCells: CellRowBuilder = new CellRowBuilder()
			.addEmptyCell(1)
			.addCell(this._generateProjectNameCell(projectName))
			.addEmptyCell(2);

		// Semaines (ex : S27)
		const weekCells: CellRowBuilder = new CellRowBuilder()
			.addEmptyCell(4);

		for (const period of header.periods) {
			const periodStart: Date = new Date(period.period.dateBegin);
			if (periodStart >= startDate) {
				// Génération période
				const periodEnd: Date = new Date(period.period.dateEnd);
				const formatDate: string = periodStart.getDate() + '/' + (periodStart.getMonth() + 1)
					+ ' | '
					+ periodEnd.getDate() + '/' + (periodEnd.getMonth() + 1);
				periodCells.addCell(this._generateDateCell(formatDate, 'FF6D748C'));

				// Génération semaine
				const formatWeek: string = 'S' + period.period.week;
				weekCells.addCell(this._generateDateCell(formatWeek, 'FF1F294E'));
			}
		}

		// Génération titre header
		const headerCells: CellRowBuilder = new CellRowBuilder()
			.addEmptyCell(2)
			.addCell(this._generateHeaderCell('Budget'))
			.addEmptyCell(header.periods.length + 1);


		if (displayProduitPeriod) {
			weekCells.addEmptyCell(1)
				.addCell(this._generateBasicCell('Période', true));

			headerCells.addEmptyCell(1)
				.addCell(this._generateBasicCell('Produit'));
		}

		weekCells.addEmptyCell(2)
			.addCell(this._generateBasicCell('Projet', true));

		headerCells.addEmptyCell(1)
			.addCell(this._generateBasicCell('Produit'))
			.addCell(this._generateBasicCell('RAE'))
			.addCell(this._generateBasicCell('Avanc.'));

		worksheet.addRows([null, null, null, null]);
		periodCells.addToWorksheet(worksheet);
		weekCells.addToWorksheet(worksheet);
		headerCells.addToWorksheet(worksheet);
	}

	public static generateBody(worksheet: Worksheet, rows: SPRow[], startDate: Date, displayProduitPeriod: boolean, project: IProjectDto): void {
		for (const spRow of rows) {
			// Si c’est une subtask ou une story de type INT
			if (spRow.type === 'subtask' || (spRow.type === 'story' && (<IStoryDto>spRow.rowDefinition).type === StoryTypeEnum.INT)) {
				continue;
			}
			// Si c’est pas un lot visible, on passe à la ligne suivante
			if ((spRow.type === 'lot' && !spRow.visible) || (spRow.lotParent && !spRow.lotParent.visible)) {
				continue;
			}

			// partie gauche du tableau : jiraId, nom, budget
			const headerConfig: CellBuilder = new CellBuilder().fontSize(10);
			let headerNameConfig: CellBuilder;
			let headerValueConfig: CellBuilder;

			const excelRow: CellRowBuilder = new CellRowBuilder();

			if (spRow.type === 'lot') {
				headerConfig.pattern(BORDER_COLOR)
					.fontFamily('arial')
					.fontColor(LOT_FONT_COLOR)
					.bold()
					.alignMiddle();
				headerNameConfig = headerConfig.clone().horizontalAlignment('left');
			} else if (spRow.type === 'epic') {
				headerConfig.pattern(EPIC_BG_COLOR)
					.fontFamily('arial')
					.fontColor(EPIC_FONT_COLOR)
					.bold()
					.alignMiddle();
				headerNameConfig = headerConfig.clone().horizontalAlignment('left');
			} else if (spRow.type === 'story') {
				headerConfig.pattern(STORY_BG_COLOR)
					.fontFamily('arial')
					.fontColor(STORY_FONT_COLOR)
					.alignMiddle()
					.borderBottom('thin', BORDER_BOTTOM_COLOR);
				headerNameConfig = headerConfig.clone().horizontalAlignment('left');
			} else {
				headerConfig.pattern(STORY_BG_COLOR)
					.fontFamily('arial')
					.fontColor(STORY_FONT_COLOR)
					.alignMiddle();
				headerNameConfig = headerConfig.clone();
			}
			headerValueConfig = headerConfig.clone().borderLeft('thin', BORDER_COLOR).borderRight('thin', BORDER_COLOR);

			excelRow.addCell(headerConfig.value(spRow.type === 'story' ? (project.jiraExtId ? spRow.rowDefinition['jiraExtId'] : spRow.rowDefinition['jiraIntId']) : '').build());
			excelRow.addCell(headerNameConfig.value(spRow.rowDefinition.name).build());
			excelRow.addCell(headerValueConfig.value(spRow.budget ? spRow.budget : 0).build());

			excelRow.addEmptyCell(1);

			// deuxième partie du tableau, valeur
			for (const cell of spRow.values) {
				if (cell.type === 'produit') {
					const cellStartDate: Date = new Date(cell.period.dateBegin);
					if (cellStartDate >= startDate) {
						excelRow.addCell(headerValueConfig.value(cell.value || '').build());
					}
				}
			}

			if (displayProduitPeriod) {
				excelRow.addEmptyCell(1);

				for (const cell of spRow.values) {
					if (cell.type === 'total-period-produit') {
						excelRow.addCell(headerValueConfig.value(cell.value || 0).build());
					}
				}
			}

			excelRow.addEmptyCell(1);

			// quatrième partie du tableau : totaux
			for (const cell of spRow.values) {
				if (cell.type === 'total-produit') {
					excelRow.addCell(headerValueConfig.value(cell.value || 0).build());
				}
			}

			for (const cell of spRow.values) {
				if (cell.type === 'total-raeext') {
					excelRow.addCell(headerValueConfig.value(cell.value || 0).build());
				}
			}

			for (const cell of spRow.values) {
				if (cell.type === 'total-avancement') {
					excelRow.addCell(headerValueConfig.value(cell.value ? Math.floor(cell.value) + '%' : '0%').build());
				}
			}

			const createdRow: Row = excelRow.addToWorksheet(worksheet);
			if (spRow.type === 'lot') {
				createdRow.height = 23.43;
			} else if (spRow.type === 'epic') {
				// Set le niveau de la ligne à 1 (groupement)
				createdRow.outlineLevel = 1;
			} else if (spRow.type === 'story') {
				// Set le niveau de la ligne à 2 (sous-groupement)
				createdRow.outlineLevel = 2;
				// Récupérer le lot
				const lot: SPRow = spRow.lotParent;
				// Filtrer les enfants pour garder que les story
				const storiesOfLot: SPRow[] = lot.children.filter(value => value.type === 'story');
				// Récupérer la dernière story
				const lastStory: SPRow = storiesOfLot[storiesOfLot.length - 1];
				// Vérifier si c’est le dernier élément du lot
				if (lastStory === spRow) {
					// Si c’est la dernière story du lot, ajouter une dernière ligne vide de niveau 1
					// Données vide mais même design que la ligne de story
					excelRow.getCells().forEach(value => value ? value.value = '' : '');
					const extraRow: Row = excelRow.addToWorksheet(worksheet);
					// Set le niveau 1 pour finaliser le groupe
					extraRow.outlineLevel = 1;
				}
			}
		}
	}

	public static generateFooter(worksheet: Worksheet, footer: SPRow, startDate: Date, displayProduitPeriod: boolean): void {
		const row: CellRowBuilder = new CellRowBuilder();
		// première partie : title
		row.addCell(this._generateFooterCell('Total'))
			.addCell(this._generateFooterCell(''))
			.addCell(this._generateFooterCell(footer.budget))
			.addEmptyCell(1);

		// seconde partie
		for (const cell of footer.values) {
			if (cell.type === 'produit') {
				const cellStartDate: Date = new Date(cell.period.dateBegin);
				if (cellStartDate >= startDate) {
					row.addCell(this._generateFooterCell(cell.value || 0));
				}
			}
		}

		if (displayProduitPeriod) {
			row.addEmptyCell(1);

			for (const cell of footer.values) {
				if (cell.type === 'total-period-produit') {
					row.addCell(this._generateFooterCell(cell.value || 0));
				}
			}
		}

		row.addEmptyCell(1);

		// quatrième partie : totaux
		for (const cell of footer.values) {
			if (cell.type === 'total-produit') {
				row.addCell(this._generateFooterCell(cell.value || 0));
			}
		}

		for (const cell of footer.values) {
			if (cell.type === 'total-raeext') {
				row.addCell(this._generateFooterCell(cell.value || 0));
			}
		}

		for (const cell of footer.values) {
			if (cell.type === 'total-avancement') {
				row.addCell(this._generateFooterCell(cell.value ? Math.floor(cell.value) + '%' : '0%'));
			}
		}

		const createdRow: Row = row.addToWorksheet(worksheet);
		createdRow.height = 23.43;
	}

	public static formatFile(workbook: Workbook, project: IProjectDto, displayProduitPeriod: boolean): void {
		const worksheet: Worksheet = workbook.getWorksheet(1);
		worksheet.getColumn(1).width = 21;
		worksheet.getColumn(2).width = 71;

		for (let i: number = 0; i < worksheet.columnCount; i++) {
			worksheet.getColumn(i + 3).width = 10.1;
		}

		// size column space after budget
		worksheet.getColumn(4).width = 5;

		if (displayProduitPeriod) {
			// size column before produit Période
			worksheet.getColumn(worksheet.columnCount - 5).width = 5;
		}

		// size column before Produit Project
		worksheet.getColumn(worksheet.columnCount - 3).width = 5;

		if (project) {
			if (project.client?.logo) {
				const logo: number = workbook.addImage({
					base64: project.client.logo,
					extension: 'png',
				});

				worksheet.addImage(logo, 'A1:A6');
			}
		}
	}

	private static _generateFooterCell(value: CellValue): Cell {
		return new CellBuilder(value)
			.pattern(STORY_BG_COLOR)
			.fontFamily('arial')
			.fontSize(10)
			.fontColor(STORY_FONT_COLOR)
			.bold()
			.alignMiddle()
			.borderTop('medium')
			.borderLeft('medium')
			.borderBottom('medium')
			.borderRight('medium')
			.build();
	}

	private static _generateBasicCell(value: CellValue, bold?: boolean): Cell {
		const cb: CellBuilder = new CellBuilder(value)
			.fontFamily('arial')
			.fontSize(10)
			.alignMiddle();

		if (bold) {
			cb.bold();
		}

		return cb.build();
	}

	private static _generateDateCell(value: CellValue, fontColor: string): Cell {
		return new CellBuilder(value)
			.fontFamily('arial')
			.fontSize(10)
			.fontColor(fontColor)
			.bold()
			.borderTop('medium')
			.borderBottom('medium')
			.borderLeft('medium')
			.borderRight('medium')
			.alignMiddle()
			.build();
	}

	private static _generateHeaderCell(value: CellValue): Cell {
		return new CellBuilder(value)
			.fontFamily('arial')
			.fontSize(10)
			.alignMiddle()
			.build();
	}

	private static _generateProjectNameCell(projectName: CellValue): Cell {
		return new CellBuilder(projectName)
			.fontFamily('arial')
			.fontSize(14)
			.bold()
			.alignMiddle()
			.build();
	}
}
