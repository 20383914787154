import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderModule} from '../template/header/header.module';
import {NumberInputModule} from '../utils/components/number-input/number-input.module';
import {RouterModule, Routes} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {BugManagementSearchComponent} from './bug-management-search/bug-management-search.component';
import {Title} from '@angular/platform-browser';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

const routes: Routes = [
	{path: '', component: BugManagementSearchComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.USER]}},
];

@NgModule({
	declarations: [BugManagementSearchComponent],
	imports: [
		CommonModule,
		HeaderModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		NumberInputModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatButtonModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatTableModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatDividerModule
	],
	providers: [
		Title
	]
})
export class BugManagementModule {
}
