import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../service/api.service';
import {firstValueFrom, Observable, of} from 'rxjs';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Pagination} from '../../../pagination/dto/pagination.dto';
import {IUserBaseDto, UserBaseDto} from './user-base.dto';
import {UserListFilter} from '../../../../users/user-list/dto/user-list.dto';
import {tap} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserBaseService extends ApiService {

	currentUser: UserBaseDto;
	private route: string = '/api/user';

	constructor(public http: HttpClient) {
		super(http);
	}

	getUserFromEmail(): Observable<IUserBaseDto> {
		if (this.currentUser) {
			return of(this.currentUser);
		}
		return this.http.get<IUserBaseDto>(this.route + '/get/email/').pipe(tap(user => this.currentUser = user));
	}

	getUser(id: number): Promise<IUserBaseDto> {
		return firstValueFrom(this.http.get<IUserBaseDto>(this.route + '/' + id));
	}

	createUser(user: IUserBaseDto): Observable<IUserBaseDto> {
		return this.post(this.route + '/createUser', user);
	}

	updateUser(user: IUserBaseDto): Observable<IUserBaseDto> {
		return this.post(this.route + '/updateUser', user);
	}

	getAllOGDPUsersWithJiraUserAndAvatar(): Observable<IUserBaseDto[]> {
		return this.get(this.route + '/allOGDPUsersWithJiraUserAndAvatar');
	}

	getAllPagingWithAvatar(paginationOption: PaginationOption): Observable<Pagination<IUserBaseDto>> {
		return this.post(this.route + '/paging/all', {paginationOption: paginationOption});
	}

	syncUsers(): Observable<void> {
		return this.get(this.route + '/sync');
	}

	findFilterByUsernameActifPaging(filter: UserListFilter, paginationOption: PaginationOption): Observable<Pagination<IUserBaseDto>> {
		return this.post(this.route + '/paging/findFilterByUsernamePaging', {
			filter: filter,
			paginationOption: paginationOption
		});
	}

	remove(userId: number): Promise<any> {
		return firstValueFrom(this.delete(this.route + '/' + userId));
	}

	async findActiveUsersForYear(year: number): Promise<IUserBaseDto[]> {
		return await firstValueFrom(this.http.get<IUserBaseDto[]>(this.route + '/findActiveUsersForYear' + '/' + year));
	}
}
