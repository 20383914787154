import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogPopupModule} from '../../../template/dialog/dialog-popup.module';
import {DialogProjectAlertDisplayComponent} from './dialog-project-alert-display.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
	declarations: [DialogProjectAlertDisplayComponent],
	imports: [
		CommonModule,
		MatInputModule,
		MatSelectModule,
		MatButtonModule,
		DialogPopupModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatTableModule,
		MatMenuModule,
		TranslateModule
	]
})
export class DialogProjectAlertDisplayModule {
}
