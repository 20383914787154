import {IStoryDto, JiraStatusEnum} from '../story/story.dto';

export interface ILinkBugsToStoryDto {
	project: string;
	bugs: IIssueFromJiraDto[];
	story: IStoryDto;
	int: boolean;
}

export interface IIssueFromJiraDto {
	name: string;
	type: JiraIssueTypeEnum;
	status: JiraStatusEnum;
	jiraId: string;
	linkedJiraId: string;
	estimated: number;
	remaining: number;
	reporter: string;
	creationDate: Date;
	updateDate: Date;
	epicId: string;
	parentKey?: string;
	projectKey: string;
	priority: JiraIssuePriorityEnum;
	priorityName: string;
	statusName: string;
}

export enum JiraIssueTypeEnum {
	EPIC = '10000',
	STORY = '10001',
	TASK = '10002',
	SUBTASK = '10003',
	BUG_INT = '10004',
	BUG = '10008',
	QUESTION = '10009',
	DEMANDE_EVOLUTION = '10010',
	TEST_SCENARIO = '10011',
	TEST_RUN = '10012',
	TEST_CASE = '10013',
	TACHE = '10014',
	SOUS_TACHE = '10015',
	BOGUE = '10016',
	SUBBUG = '10022',
	STORY_CONTINGENCY = '10023',
	MACRO_TASK = '10024',
	DOCUMENTATION = '10025',
	SUBBUG_INT = '10026',
	SUBBUG_CLIENT = '10027',
	AVANT_VENTE = '10028',
	STORY_INT = '10029',
	SUBTASK_INT = '10031'
}

export enum JiraIssuePriorityEnum {
	HIGHEST = '1',
	HIGH = '2',
	MEDIUM = '3',
	LOW = '4',
	LOWEST = '5',
}
