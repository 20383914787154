import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MatDividerModule} from '@angular/material/divider';
import {ProjetDetailComponent} from './projet-detail/projet-detail.component';
import {ProjectMessagesModule} from './component/project-messages/project-messages.module';
import {NumberInputModule} from '../utils/components/number-input/number-input.module';
import {HeaderModule} from '../template/header/header.module';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {ProjetComponent} from './projet/projet.component';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {ClientSelectModule} from '../client/component/client-select/client-select.module';
import {UtilModule} from '../utils/util.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DialogProjectAlertDisplayModule} from '../project-alert/component/dialog-project-alert-display/dialog-project-alert-display.module';
import {MatTreeModule} from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion';
import {DialogRedistributionBudgetComponent} from './projet-detail/component/redistribution-budget/dialog-redistribution-budget.component';
import {DialogRedistributionBudgetSignedComponent} from './projet-detail/component/redistribution-budget-signed/dialog-redistribution-budget-signed.component';
import {DialogExportLotComponent} from './projet-detail/component/export-lot/dialog-export-lot.component';
import {ItsfAutofocusDirective} from '../utils/components/autofocus/itsf-autofocus.directive';
import {DialogRedistributionBudgetSynchroComponent} from './projet-detail/component/redistribution-budget-synchro/dialog-redistribution-budget-synchro.component';
import {RedistributionBudgetLotComponent} from './projet-detail/component/redistribution-budget-synchro/redistribution-budget-lot/redistribution-budget-lot.component';
import {SprintUrlPipe} from './projet-detail/pipe/sprint-url.pipe';
import {PictoPipe} from './projet-detail/pipe/picto.pipe';
import {TreeNodeClassesDirective} from './projet-detail/directive/tree-node-classes.directive';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {DialogProjetEditComponent} from './component/projet-edit/dialog-projet-edit.component';
import {AvancementProjetPipe} from './projet-detail/pipe/avancement-projet.pipe';
import {CompanySelectModule} from '../company/component/company-select/company-select.module';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {AdminRolePipe, ClientRolePipe, RolePipe} from '../security/pipe/role.pipe';
import {DialogObfuscateProgressComponent} from "./component/obfuscate-progress/dialog-obfuscate-progress.component";

const routes: Routes = [
	{path: '', component: ProjetComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.USER, PermissionEnum.CLIENT]}},
	{path: ':id', component: ProjetDetailComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.USER, PermissionEnum.CLIENT]}},
];

@NgModule({
	declarations: [
		ProjetComponent,
		DialogProjetEditComponent,
		ProjetDetailComponent,
		DialogRedistributionBudgetComponent,
		DialogRedistributionBudgetSignedComponent,
		DialogExportLotComponent,
		ItsfAutofocusDirective,
		DialogRedistributionBudgetSynchroComponent,
		RedistributionBudgetLotComponent,
		SprintUrlPipe,
		PictoPipe,
		AvancementProjetPipe,
		TreeNodeClassesDirective,
		DialogObfuscateProgressComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatTableModule,
		HeaderModule,
		MatCheckboxModule,
		MatSelectModule,
		DialogPopupModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatPaginatorModule,
		MatChipsModule,
		MatAutocompleteModule,
		TranslateModule,
		NumberInputModule,
		ProjectMessagesModule,
		OgdpPaginatorModule,
		ClientSelectModule,
		CompanySelectModule,
		UtilModule,
		MatDividerModule,
		ReactiveFormsModule,
		DragDropModule,
		DialogProjectAlertDisplayModule,
		MatTooltipModule,
		MatTreeModule,
		MatExpansionModule,
		MatListModule,
		MatRadioModule,
		RolePipe,
		ClientRolePipe,
		AdminRolePipe,
	],
	providers: [
		Title,
	],
})
export class GestionModule {
}

