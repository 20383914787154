<app-dialog-popup [popupTitle]="'APP.OBFUSCATE.PROGRESS.TITLE' | translate" [footer]="false" [closeButton]="false">
	<div class="dialog-obfuscate-progress" dialog-popup-content>
		<div *ngIf="progress">
			<p class="project-name">{{ progress.currentProject.name }}</p>

			<div class="progress-bar-header">
				<p class="progress-bar-text">
					{{ 'APP.OBFUSCATE.PROGRESS.PROJECT' | translate }} {{ progress.currentProject.index }} / {{ progress.totalProjects }}
				</p>
			</div>
			<div class="progress">
				<div class="progress-bar" role="progressbar" [style.width]="calculateProgress(progress.totalProjects, progress.currentProject.index)" aria-valuenow="progress.currentProgress.index" aria-valuemin="0" [attr.aria-valuemax]="progress.currentProgress.total"></div>
			</div>

			<div *ngIf="progress.currentProgress">
				<div class="progress-bar-header">
					<p class="progress-bar-text">
						{{ ('APP.OBFUSCATE.PROGRESS.' + progress.currentProgress.cat) | translate }} {{ progress.currentProgress.index }} / {{ progress.currentProgress.total }}
					</p>
					<p *ngIf="progress.currentProgress.subProgress" class="progress-bar-subtext">
						{{ ('APP.OBFUSCATE.PROGRESS.' + progress.currentProgress.subProgress.cat) | translate }} {{ progress.currentProgress.subProgress.index }} / {{ progress.currentProgress.subProgress.total }}
					</p>
				</div>
				<div class="progress">
					<div class="progress-bar" role="progressbar" [style.width]="calculateProgress(progress.currentProgress.total, progress.currentProgress.index)" aria-valuenow="progress.currentProgress.index" aria-valuemin="0" [attr.aria-valuemax]="progress.currentProgress.total"></div>
				</div>
			</div>
		</div>
	</div>
</app-dialog-popup>
