import {PaginationOption} from '../../../core/pagination/dto/pagination-option.dto';
import {Pagination} from '../../../core/pagination/dto/pagination.dto';
import {AbstractPaginateDatasource} from '../../../core/pagination/abstract-paginate.datasource';
import GestionProjectFilter from './gestion-project-filter';
import {ProjectService} from '../../../core/business/service/project/project.service';
import {IProjectDto, ProjectStatusEnum} from '../../../core/business/service/project/project.dto';
import {ILotDto} from '../../../core/business/service/lot/lot.dto';
import {ProjectAlertService} from '../../../core/business/service/project-alert/project-alert.service';
import {INumberAlertsDto} from '../../../core/business/service/project-alert/project-alert.dto';
import {SpinnerService} from '../../../core/service/spinner.service';
import {UtilsService} from '../../../utils/service/utils.service';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {ICompanyDto} from '../../../core/business/service/company/company.dto';

export class DataSourceTable {
	id: number;
	name: string;
	jiraIntId: string;
	urlJiraIntId: string;
	jiraExtId: string;
	urlJiraExtId: string;
	budget: string;
	avancement: number;
	logo: string;
	client: IClientDto;
	company: ICompanyDto;
	status: ProjectStatusEnum;
	numberAlerts: INumberAlertsDto[];
	lots: ILotDto[];
}

export class ProjetDataSource extends AbstractPaginateDatasource<DataSourceTable> {

	constructor(private projectService: ProjectService, private projectAlertService: ProjectAlertService,
				private configJiraUrlBrowser: string, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	findByFiltersPaging(filter: GestionProjectFilter, paginationOption: PaginationOption): void {
		this.notifyPagingData(this.projectService.findByFiltersPaging(filter, paginationOption));
	}

	transformResults(results: Pagination<IProjectDto>): DataSourceTable[] {
		const dataSourceTable: DataSourceTable[] = [];
		for (const project of results.results) {
			const dataTableToAdd: DataSourceTable = new DataSourceTable();
			dataTableToAdd.id = project.id;
			dataTableToAdd.name = project.name;
			dataTableToAdd.jiraIntId = project.jiraIntId;
			dataTableToAdd.urlJiraIntId = this.configJiraUrlBrowser + project.jiraIntId;
			dataTableToAdd.jiraExtId = project.jiraExtId;
			dataTableToAdd.urlJiraExtId = this.configJiraUrlBrowser + project.jiraExtId;
			dataTableToAdd.client = project.client;
			dataTableToAdd.company = project.company;
			if (project.client) {
				dataTableToAdd.logo = project.client.logo;
			}
			dataTableToAdd.budget = project.budget == null ? '0 J' : project.budget.toString() + ' J';
			dataTableToAdd.status = project.status;
			dataTableToAdd.lots = project.lots.filter(lot => lot.backlog === false).sort(UtilsService.dynamicMultiSort('order'));
			dataSourceTable.push(dataTableToAdd);

			dataTableToAdd.avancement = project.budget === 0 ? 0 : ((1 - (project.remainingEXTSum / project.budget)) * 100);
		}

		// /!\ ATTENTION ici on fait un appel à la BDD
		dataSourceTable.forEach((value: DataSourceTable) => {
			this.projectAlertService.findNbAlertsByProject(value.id).subscribe((alerts: INumberAlertsDto[]) => value.numberAlerts = alerts);
		});

		return dataSourceTable;
	}
}
