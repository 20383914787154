import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWith'
})
export class StartsWithPipe implements PipeTransform {

  transform(value: string, startsWith: string): boolean {
    return value.startsWith(startsWith);
  }

}
