import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ISousCategorieDto} from './sous-categorie.dto';

@Injectable({
	providedIn: 'root'
})
export class SousCategorieService extends ApiService {

	private route: string = '/api/av-sous-categorie';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		return this.post(this.route, sousCategorieDto);
	}

	update(sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		return this.put(this.route + '/' + sousCategorieDto.id, sousCategorieDto);
	}

	remove(sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		return this.delete(this.route + '/' + sousCategorieDto.id);
	}

	findById(sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		return this.get(this.route + '/' + sousCategorieDto.id);
	}
}
