import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IProjectAlertDto} from '../../../core/business/service/project-alert/project-alert.dto';
import {ProjectAlertDisplayDatasource} from './datasource/dialog-project-alert-display.datasource';
import {SpinnerService} from '../../../core/service/spinner.service';
import {ProjectAlertService} from '../../../core/business/service/project-alert/project-alert.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-project-alert-display',
	templateUrl: './dialog-project-alert-display.component.html',
	styleUrls: ['./dialog-project-alert-display.component.scss']
})
export class DialogProjectAlertDisplayComponent implements AfterViewInit, OnInit {

	projectAlerts: Observable<IProjectAlertDto[]>;
	projectId: number;

	public dataSource: ProjectAlertDisplayDatasource;
	public displayedColumns: string[];

	hasDeleteEvent: boolean = false;

	constructor(public dialogRef: MatDialogRef<DialogProjectAlertDisplayComponent>,
				private spinnerService: SpinnerService,
				private projectAlertService: ProjectAlertService,
				@Inject(MAT_DIALOG_DATA) data: { projectAlerts: Observable<IProjectAlertDto[]>, projectId: number }) {

		this.projectAlerts = data.projectAlerts;
		this.projectId = data.projectId;

		this.dataSource = new ProjectAlertDisplayDatasource(this.spinnerService);
	}

	ngOnInit(): void {
		this.displayedColumns = ['delete', 'panneau', 'date', 'type', 'message'];
		this.loadProjectAlerts();
	}

	ngAfterViewInit(): void {

	}

	private loadProjectAlerts(): void {
		this.dataSource.loadProjectAlerts(this.projectAlerts);
	}

	closeDialog(): void {
		this.dialogRef.close(this.hasDeleteEvent);
	}

	deleteAlert(projectAlert: IProjectAlertDto): void {
		this.projectAlertService.deleteProjectAlert(projectAlert).subscribe(value => {
			if (value) {
				this.hasDeleteEvent = true;
				this.projectAlerts = this.projectAlertService.findByProject(this.projectId);
				this.dataSource.loadProjectAlerts(this.projectAlerts);
			} else {
				console.log('delete KO');
			}
		});
	}

	deleteWarningAlert(projectId: number): void {
		this.projectAlertService.deleteProjectAlertWithWarning(projectId).subscribe(() => {
			this.hasDeleteEvent = true;
			this.projectAlerts = this.projectAlertService.findByProject(this.projectId);
			this.dataSource.loadProjectAlerts(this.projectAlerts);
		});
	}
}
