import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {UtilsService} from '../../utils/service/utils.service';
import {
	IUserBaseDto,
	PermissionEnum,
	UserBaseDto
} from '../../core/business/service/user-base/user-base.dto';
import {IProjectDto} from '../../core/business/service/project/project.dto';
import {ProjectService} from '../../core/business/service/project/project.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';

export interface Perm {
	value: string;
	viewValue: string;
}

const PERMISSIONS: string[] = ['avant-vente', 'gestion-clients', 'v1-report', 'raf'];

@Component({
	selector: 'app-dialog-user-edit',
	templateUrl: './dialog-user-edit.component.html',
	styleUrls: ['./dialog-user-edit.component.scss']
})
export class DialogUserEditComponent implements AfterViewInit, OnInit {

	userBase: IUserBaseDto;

	userForm: FormGroup;

	projects: IProjectDto[] = [];
	filteredProjects: IProjectDto[] = [];
	permissions: string[] = PERMISSIONS;
	displayPermissions: boolean = false;

	@ViewChild('inputEmail') inputEmail: ElementRef;
	@ViewChild('autocomplete') autocomplete: MatAutocomplete;

	inputEmailIsReadOnly: boolean = false;

	constructor(private _fb: FormBuilder, public dialogRef: MatDialogRef<DialogUserEditComponent>,
				private _projectService: ProjectService,
				private _titleService: Title,
				@Inject(MAT_DIALOG_DATA) public data: {userBase: IUserBaseDto, readOnly: boolean}) {

		this.userForm = this._fb.group({
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.email
			])),
			permission: new FormControl(PermissionEnum.USER, Validators.required),
			permissions: new FormControl(),
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			jiraId: new FormControl(),
			actif: new FormControl('', Validators.required),
			projects: new FormControl([])
		});

		if (data.userBase) {
			this.userBase = data.userBase;
		} else {
			this.userBase = new UserBaseDto();
		}
	}

	ngOnInit(): void {
		if (this.userBase) {
			this._titleService.setTitle(
				ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Utilisateurs ' + this.userBase.firstName + ' ' + this.userBase.lastName
			);
		} else {
			this._titleService.setTitle(
				ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Utilisateurs > Nouvel utilisateur'
			);
		}
		firstValueFrom(this._projectService.findAll()).then(value => {
			if (value) {
				this.projects = value.sort(UtilsService.comparing(p => p.name));
			}
		});
		if (this.userBase.id) {
			this.userForm.patchValue({
				email: this.userBase.email,
				permission: this.userBase.role,
				permissions: this.userBase.permissions,
				firstName: this.userBase.firstName,
				lastName: this.userBase.lastName,
				actif: this.userBase.actif.toString(),
				jiraId: this.userBase.jiraId ? this.userBase.jiraId : '',
				projects: this.userBase.projects
			});
			this.inputEmailIsReadOnly = true;
			this.displayPermissions = this.userBase.role !== 'ADMIN';
		} else {
			// Desactive le FromControl, pas util en création
			this.userForm.get('actif').disable();
			this.inputEmailIsReadOnly = false;
		}
		if (this.data.readOnly) {
			this.userForm.disable();
		}
	}

	ngAfterViewInit(): void {

	}

	createUser(): void {
		if (this.data.readOnly) {
			this.data.readOnly = false;
			this.userForm.enable();
			return;
		}
		if (this.userForm.invalid) {
			return;
		}

		this.userBase.email = this.userForm.value.email;
		this.userBase.role = this.userForm.value.permission;
		this.userBase.permissions = this.userForm.value.permissions;
		this.userBase.firstName = this.userForm.value.firstName;
		this.userBase.lastName = this.userForm.value.lastName;
		this.userBase.projects = this.userForm.value.projects;
		if (this.userBase.role === 'ADMIN') {
			this.userBase.permissions = null;
		}
		if (this.userForm.value.jiraId) {
			this.userBase.jiraId = this.userForm.value.jiraId;
		} else {
			this.userBase.jiraId = null;
		}
		if (this.userBase.id) { // uniquement si on est en modification, sinon c'est la BDD qui set
			this.userBase.actif = this.userForm.value.actif === 'true';
		}

		this.dialogRef.close(this.userBase);
	}

	projectEquals(objOne: IProjectDto, objTwo: IProjectDto): boolean {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	filterProjects(query: string): void {
		if (query) {
			this.filteredProjects = this.projects.filter(p => p.name.toUpperCase().indexOf(query.toUpperCase()) !== -1);
		} else {
			this.filteredProjects = this.projects;
		}
	}

	handlePermissionsDisplay(event: MatSelectChange): void {
		this.displayPermissions = event.value !== 'ADMIN';
	}

}
