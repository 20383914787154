import {AbstractPaginateDatasource} from '../../core/pagination/abstract-paginate.datasource';
import {PaginationOption} from '../../core/pagination/dto/pagination-option.dto';
import {Pagination} from '../../core/pagination/dto/pagination.dto';
import {ClientService} from '../../core/business/service/client/client.service';
import {IClientDto} from '../../core/business/service/client/client.dto';
import {SpinnerService} from '../../core/service/spinner.service';


export class ClientDatasource extends AbstractPaginateDatasource<IClientDto> {

	constructor(private clientService: ClientService, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	findAllPaging(paginationOption: PaginationOption): void {
		this.notifyPagingData(this.clientService.findAllPaging(paginationOption));
	}

	findFilterByNamePaging(filter: string, actif: boolean, paginationOption: PaginationOption): void {
		this.notifyPagingData(this.clientService.findFilterByNamePaging(filter, actif, paginationOption));
	}

	transformResults(results: Pagination<IClientDto>): IClientDto[] {
		return results.results;
	}
}
