import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ICompanyDto} from './company.dto';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Pagination} from '../../../pagination/dto/pagination.dto';

@Injectable({
	providedIn: 'root'
})
export class CompanyService extends ApiService {

	private route: string = '/api/company';

	constructor(public http: HttpClient) {
		super(http);
	}

	getAllCompanies(): Observable<ICompanyDto[]> {
		return this.get(this.route);
	}

	getActiveCompanies(): Observable<ICompanyDto[]> {
		return this.get(this.route + '/active');
	}

	syncCompanies(): Observable<void> {
		return this.get(this.route + '/sync');
	}

	findAllPaging(paginationOption: PaginationOption): Observable<Pagination<ICompanyDto>> {
		return this.post(this.route + '/paging/all', {paginationOption: paginationOption});
	}
}
