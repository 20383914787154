import {
	AfterViewInit,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import {ICategorieDto} from '../../../../../../core/business/service/avant-vente/categorie/categorie.dto';
import {ChiffrageItemFlatNode, StateItemNodeEnum} from '../../chiffrage.component';
import {IRepartitionDto} from '../../../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {AvantVenteBusiness} from '../../../../../business/avant-vente.business';
import {ISousRepartitionDto} from '../../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {
	ChiffrageTypeEnum,
	IChiffrageDto
} from '../../../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

class TotalCharge {
	type: ChiffrageTypeEnum;
	sommeCharge: number;
	sommeChargeMin: number;
	sommeChargeMax: number;

	constructor(type: ChiffrageTypeEnum, sommeCharge: number, sommeChargeMin: number, sommeChargeMax: number) {
		this.type = type;
		this.sommeCharge = sommeCharge;
		this.sommeChargeMin = sommeChargeMin;
		this.sommeChargeMax = sommeChargeMax;
	}
}

@Component({
	selector: 'app-chiffrage-categorie',
	templateUrl: './chiffrage-categorie.component.html',
	styleUrls: ['./chiffrage-categorie.component.scss'],
})
export class ChiffrageCategorieComponent implements AfterViewInit, OnInit, OnChanges {

	@Input() isExpanded: boolean;
	@Input() categorie: ICategorieDto;
	@Input() node: ChiffrageItemFlatNode;
	@Input() stateItemNode: StateItemNodeEnum;
	@Input() repartitions: IRepartitionDto[];
	@Input() chiffrage: IChiffrageDto;
	@Input() lastChiffrage: IChiffrageDto;

	@Output() saveNewCategorieForm: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();
	@Output() clearNewCategorieForm: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();
	@Output() needNewSousCategorieForm: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();
	@Output() removeCategorie: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();

	totalChargeByRepartition: TotalCharge[];

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (this.node.isKeybordSelected && event.ctrlKey && event.key === 's') {
			event.preventDefault();
			this.changeStateItemNode();
		}

		if (this.node.isKeybordSelected && event.ctrlKey && event.key === 'x') {
			event.preventDefault();
			this.addFormNewSousCategorie();
		}
	}

	constructor() {
		this.totalChargeByRepartition = [];
	}

	ngOnInit(): void {
		if (!this.categorie) {
			this.node.isBeingEdited = true;
			this.categorie = {} as ICategorieDto;
		}
		this.updateAfterChange();
	}

	ngAfterViewInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.updateAfterChange();
	}

	saveFormCategorie(): void {
		if (this.categorie.nom.length === 0) {
			this.categorie.nom = 'Catégorie';
		}
		this.node.item = this.categorie;

		this.saveNewCategorieForm.emit(this.node);
		this.changeStateItemNode();
	}

	clearFormCategorie(): void {
		if (this.categorie.id) { // update
			this.changeStateItemNode();
		} else {
			this.clearNewCategorieForm.emit(this.node);
		}
	}

	addFormNewSousCategorie(): void {
		this.needNewSousCategorieForm.emit(this.node);
	}

	changeStateItemNode(): void {
		let newStateItemNode: StateItemNodeEnum = null;
		if (this.stateItemNode) {
			if (this.stateItemNode === StateItemNodeEnum.EDIT) {
				this.node.isBeingEdited = false;
				newStateItemNode = StateItemNodeEnum.READ;
			} else if (this.stateItemNode === StateItemNodeEnum.READ) {
				this.node.isBeingEdited = true;
				newStateItemNode = StateItemNodeEnum.EDIT;
			}
		} else {
			this.node.isBeingEdited = true;
			newStateItemNode = StateItemNodeEnum.EDIT;
		}
		// Lance l'update du component
		this.stateItemNode = newStateItemNode;
	}

	editNode(): void {
		if (this.stateItemNode !== StateItemNodeEnum.EDIT && this.lastChiffrage.id === this.chiffrage.id) {
			this.node.isBeingEdited = true;
			this.stateItemNode = StateItemNodeEnum.EDIT;
		}
	}

	toggleExpansion(): void {
		this.node.item.isExpanded = !this.categorie.isExpanded;
		this.saveNewCategorieForm.emit(this.node);
	}

	deleteCategorie(): void {
		this.removeCategorie.emit(this.node);
	}

	private updateAfterChange(): void {
		if (this.categorie) {
			if (this.categorie.sousCategories) {
				this.categorie.sousCategories.forEach(sousCat => sousCat.sousRepartitions.sort(AvantVenteBusiness.sortSousRepartitionOrder));
			}

			if (this.repartitions && this.categorie.sousCategories) {
				this.totalChargeByRepartition = [];
				for (const repartition of this.repartitions) {

					if (this.chiffrage.type !== ChiffrageTypeEnum.MINMAX) {
						const sumCategorieByRepartition: number = this.categorie.sousCategories.reduce((accSousCategorie, sousCategorie) => {
							const sousRepartition: ISousRepartitionDto = sousCategorie.sousRepartitions.find(value => value.repartitionId === repartition.id);
							if (sousRepartition) {
								return accSousCategorie + sousRepartition.charge;
							} else {
								return accSousCategorie;
							}
						}, 0);
						this.totalChargeByRepartition.push(new TotalCharge(this.chiffrage.type, sumCategorieByRepartition, null, null));
					} else if (this.chiffrage.type === ChiffrageTypeEnum.MINMAX) {

						const sumMinCategorieByRepartition: number = this.categorie.sousCategories.reduce((accSousCategorie, sousCategorie) => {
							const sousRepartition: ISousRepartitionDto = sousCategorie.sousRepartitions.find(value => value.repartitionId === repartition.id);
							if (sousRepartition) {
								return accSousCategorie + sousRepartition.chargeMin;
							} else {
								return accSousCategorie;
							}
						}, 0);

						const sumMaxCategorieByRepartition: number = this.categorie.sousCategories.reduce((accSousCategorie, sousCategorie) => {
							const sousRepartition: ISousRepartitionDto = sousCategorie.sousRepartitions.find(value => value.repartitionId === repartition.id);
							if (sousRepartition) {
								return accSousCategorie + sousRepartition.chargeMax;
							} else {
								return accSousCategorie + 0;
							}
						}, 0);

						this.totalChargeByRepartition.push(new TotalCharge(this.chiffrage.type, null, sumMinCategorieByRepartition, sumMaxCategorieByRepartition));
					}
				}
			}
		}
	}
}
