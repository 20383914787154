import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';
import {MY_FORMATS} from '../suivi-projet/suivi-projet.component';
import {ISPHeader, ISPHeaderMonth, ISPHeaderPeriod, SuiviListFilter} from './dto/suivi-list.dto';
import {firstValueFrom, merge, Observable} from 'rxjs';
import {DialogValidPeriodComponent} from './component/dialog-valid-period/dialog-valid-period.component';
import {DsPeriodReport, DsProject, SuiviDataSource} from './datasource/suivi-datasource';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../../core/pagination/dto/pagination-option.dto';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {SpinnerService} from '../../core/service/spinner.service';
import {ClientService} from '../../core/business/service/client/client.service';
import {IClientDto} from '../../core/business/service/client/client.dto';
import {ReportService} from '../../core/business/service/report/report.service';
import {ProjectService} from '../../core/business/service/project/project.service';
import {ProjectStatusEnum} from '../../core/business/service/project/project.dto';
import {OgdpPaginatorComponent} from '../../utils/components/paginator/ogdp-paginator.component';
import {ProjectAlertService} from '../../core/business/service/project-alert/project-alert.service';
import {DialogProjectAlertDisplayComponent} from '../../project-alert/component/dialog-project-alert-display/dialog-project-alert-display.component';
import {ConfigurationService} from '../../core/business/service/configuration/configuration.service';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';
import {ClientSelectComponent} from '../../client/component/client-select/client-select.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {AdminRolePipe} from '../../security/pipe/role.pipe';
import {ICompanyDto} from '../../core/business/service/company/company.dto';
import {CompanyService} from '../../core/business/service/company/company.service';

@Component({
	selector: 'app-suivi-list',
	templateUrl: './suivi-list.component.html',
	styleUrls: ['./suivi-list.component.scss'],
	providers: [
		// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
		// application's root module. We provide it at the component level here, due to limitations of
		// our example generation script.
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuiviListComponent extends UserInfo implements AfterViewInit, OnInit {

	dataSourcePaginate: SuiviDataSource;
	dataHeader: Observable<ISPHeader>;
	dataProject: Observable<DsProject[]>;

	limitStart: moment.Moment;
	limitEnd: moment.Moment;

	clients: IClientDto[] = [];
	companies: ICompanyDto[] = [];

	suiviListFilter: SuiviListFilter = new SuiviListFilter();
	sortByOption: PaginationSortBy[] = [];

	paginationOption: PaginationOption = new PaginationOption(20, 0);
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchBar: SearchbarComponent;
	@ViewChild('periodStartPicker') periodStartPicker: MatDatepicker<moment.Moment>;
	@ViewChild('periodEndPicker') periodEndPicker: MatDatepicker<moment.Moment>;
	@ViewChild('filterStatus') filterStatus: MatSelect;
	@ViewChild(ClientSelectComponent) filterClient: ClientSelectComponent;
	@ViewChild('selectCompanies') selectCompanies: MatSelect;
	@ViewChild('sortBy') sortBy: MatSelect;
	@ViewChild('displayTotalPeriod') displayTotalPeriod: MatCheckbox;
	@ViewChild('showInactiveProjects') showInactiveProjects: MatCheckbox;
	@ViewChild('hideProjectWithNoBudget') hideProjectWithNoBudget: MatCheckbox;

	constructor(private reportService: ReportService,
				private projectService: ProjectService,
				private projectAlertService: ProjectAlertService,
				private clientService: ClientService,
				private companyService: CompanyService,
				private configurationService: ConfigurationService,
				private spinnerService: SpinnerService,
				private snackBar: MatSnackBar,
				public override keycloak: KeycloakService,
				public dialog: MatDialog,
				private _titleService: Title) {
		super(keycloak);

		this.sortByOption['creationDateAsc'] = new PaginationSortBy('creationDate', PaginationSort.ASC);
		this.sortByOption['creationDateDesc'] = new PaginationSortBy('creationDate', PaginationSort.DESC);
		this.sortByOption['alphabetiqueOrderAsc'] = new PaginationSortBy('name', PaginationSort.ASC);
		this.paginationOption.sortBy = this.sortByOption['alphabetiqueOrderAsc'];

		this.dataSourcePaginate = new SuiviDataSource(this.projectService, this.reportService, this.projectAlertService,
			this.currentUser, this.configurationService.findOnCacheByKey('JIRA_URL_BROWSER').value, this.spinnerService);
	}

	async ngOnInit(): Promise<void> {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Suivi');

		this.dataHeader = this.dataSourcePaginate.dataHeader;
		this.dataProject = this.dataSourcePaginate.dataProject;

		this.limitEnd = moment().add(0, 'month');

		this.suiviListFilter.periodStart = moment().add(-1, 'month');
		this.suiviListFilter.periodEnd = moment().add(0, 'month');
		this.suiviListFilter.withStatus = ProjectStatusEnum.OPEN;

		firstValueFrom(this.clientService.getAllClient()).then(clients => {
			this.clients = clients;
		});

		this.companies = await firstValueFrom(this.companyService.getActiveCompanies());

		this.loadDataHeaderAndProject();
	}

	ngAfterViewInit(): void {
		merge(this.periodStartPicker.closedStream, this.periodEndPicker.closedStream, this.filterStatus.selectionChange,
			this.filterClient.clientChange, this.selectCompanies.selectionChange, this.showInactiveProjects.change,
			this.hideProjectWithNoBudget.change, this.searchBar.clearInput, this.searchBar.valueChange.pipe(
				debounceTime(500),
				distinctUntilChanged())
		).pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadDataHeaderAndProject();
		})).subscribe();

		merge(this.sortBy.selectionChange).pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadDataProject();
		})).subscribe();

		merge(this.displayTotalPeriod.change).pipe(tap(() => {
			this.dataSourcePaginate.rebuildHtmlTableWihtoutDbAccess();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap((event) => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadDataProject();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.loadDataProject();
	}

	clearSearch(): void {
		this.suiviListFilter.filterProjectName = '';
		this.paginator.pageIndex = 0;
		this.paginationOption.page = this.paginator.pageIndex;
		this.loadDataHeaderAndProject();
	}

	private loadDataProject(): void {
		this.dataSourcePaginate.loadDataProject(this.suiviListFilter.periodStart.startOf('month'),
			this.suiviListFilter.periodEnd.endOf('month'), this.suiviListFilter, this.paginationOption);
	}

	private loadDataHeaderAndProject(): void {
		this.dataSourcePaginate.loadDataHeader(this.suiviListFilter.periodStart.startOf('month'),
			this.suiviListFilter.periodEnd.endOf('month'), this.suiviListFilter);
		this.dataSourcePaginate.loadDataProject(this.suiviListFilter.periodStart.startOf('month'),
			this.suiviListFilter.periodEnd.endOf('month'), this.suiviListFilter, this.paginationOption);
	}

	expandOrShrinkMonth(period: ISPHeaderMonth): void {
		period.isMonthExpend = !period.isMonthExpend;
		this.dataSourcePaginate.rebuildHtmlTableWihtoutDbAccess();
	}

	onMonthSelected(event: moment.Moment, datePicker: MatDatepicker<any>, when: 'start' | 'end'): void {
		switch (when) {
			case 'start':
				this.suiviListFilter.periodStart = event;
				break;
			case 'end':
				this.suiviListFilter.periodEnd = event;
				break;
		}

		datePicker.close();
	}

	openDialogValidatePeriod(event: MouseEvent, sPHeaderPeriod: ISPHeaderPeriod): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();

		dialogConfig.position = {top: event.pageY + 'px', left: (event.pageX - 150) + 'px'};

		const dialogRef: MatDialogRef<DialogValidPeriodComponent> = this.dialog.open(DialogValidPeriodComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.spinnerService.enableLoading();
				this.snackBar.open('Validation de la période CP/Admin et soumission des périodes à Tempo en cours ...');
				this.reportService.validateAllPeriodReportForCpAndAdmin(sPHeaderPeriod.period.id).then(() => {
					this.loadDataHeaderAndProject();
					this.spinnerService.disableLoading();
					this.snackBar.open('Période validée CP/Admin et soumission des périodes à Tempo terminée');
				});
			}
		});
	}

	canValidateUnvalidateAdmin(project: DsProject, periodReport: DsPeriodReport): boolean {
		return project.status === ProjectStatusEnum.OPEN && new AdminRolePipe().transform(this.role) && periodReport.validatedCP;
	}

	validateUnvalidateAdmin(project: DsProject, periodReport: DsPeriodReport): void {
		if (project.status !== ProjectStatusEnum.OPEN || !new AdminRolePipe().transform(this.role)) {
			return;
		}
		if (periodReport.validatedCP && !periodReport.validatedAdmin) {
			this.spinnerService.enableLoading();
			this.snackBar.open('Validation de la période CP/Admin et soumission des périodes à Tempo en cours ...');
			this.reportService.validatePeriodForAdmin(periodReport.id).then(() => {
				this.loadDataHeaderAndProject();
				this.spinnerService.disableLoading();
				this.snackBar.open('Période validée CP/Admin et soumission des périodes à Tempo terminée');
			});
		} else if (periodReport.validatedCP && periodReport.validatedAdmin) {
			this.spinnerService.enableLoading();
			this.snackBar.open('Ré ouverture de la période et soumission de la ré ouverture à Tempo en cours ...');
			this.reportService.unvalidatePeriodForAdmin(periodReport.id).then(() => {
				this.loadDataHeaderAndProject();
				this.spinnerService.disableLoading();
				this.snackBar.open('Ré ouverture de la période et soumission de la ré ouverture à Tempo terminée');
			});
		} else {
			// do nothing
		}
	}

	openDialogProjectAlert(projectId: number): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '900px';
		dialogConfig.data = {
			projectAlerts: this.projectAlertService.findByProject(projectId),
			projectId: projectId
		};

		const dialogRef: MatDialogRef<DialogProjectAlertDisplayComponent> = this.dialog.open(DialogProjectAlertDisplayComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(deleteEvent => {
			if (deleteEvent) {
				this.loadDataHeaderAndProject();
			}
		});
	}
}
