<div class="d-flex justify-content-between annexe">

	<div *ngIf="stateItemNode == 'EDIT'; then edition else lecture"></div>
	<ng-template #edition> <!-- /!\ create and update -->

		<div class="d-flex align-items-center justify-content-between title-row">
			<div class="d-flex align-items-center">
				<mat-form-field class="input-form-nom">
					<mat-label>Nom</mat-label>
					<input [(ngModel)]="chargeAnnexe.titre" [focusOn]="true" (keydown.enter)="saveFormChargeAnnexe()"
						   matInput>
				</mat-form-field>
			</div>

			<div class="d-flex align-items-center">
				<button (click)="saveFormChargeAnnexe()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">done</span>
				</button>
				<button (click)="clearFormChargeAnnexe()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">close</span>
				</button>
			</div>
		</div>

		<div *ngIf="chiffrage.type !== 'REEL' && chiffrage.type !== 'MINMAX'"
			 class="d-flex align-items-center sous-categorie-charge">
			<mat-form-field class="input-form-charge">
				<mat-label>Charge</mat-label>
				<input [(ngModel)]="chargeAnnexe.charge" (keydown.enter)="saveFormChargeAnnexe()" class="text-center"
					   matInput>
			</mat-form-field>
		</div>
		<div
			*ngIf="chiffrage.type === 'REEL'"
			class="d-flex align-items-center justify-content-center cell-sous-categorie real-sum-container bg-grey-extralight-3">
			<div class="charge-row sous-categorie-charge">
				{{chargeAnnexe.charge | number:'1.0-3'}}
			</div>
		</div>

		<div [class.min-max-repartition-edit]="chiffrage.type === 'MINMAX'"
			 *ngFor="let editSousRepartition of chargeAnnexe.sousRepartitions"
			 class="repartition-row sous-categorie-repartition d-flex align-items-center justify-content-center">

			<div [ngSwitch]="chiffrage.type">
				<div *ngSwitchCase="'PERCENT'">
					<app-form-field-pourcentage [allSousRepartitions]="chargeAnnexe.sousRepartitions"
												[parent]="chargeAnnexe"
												[saveForm]="saveFormChargeAnnexe.bind(this)"
												[sousRepartition]="editSousRepartition">
					</app-form-field-pourcentage>
				</div>
				<div *ngSwitchCase="'MINMAX'">
					<app-form-field-minmax [allSousRepartitions]="chargeAnnexe.sousRepartitions" [parent]="chargeAnnexe"
										   [saveForm]="saveFormChargeAnnexe.bind(this)"
										   [sousRepartition]="editSousRepartition">
					</app-form-field-minmax>
				</div>
				<div *ngSwitchCase="'REEL'" class="real-container">
					<app-form-field-reel [saveForm]="saveFormChargeAnnexe.bind(this)"
										 [sousRepartition]="editSousRepartition"></app-form-field-reel>
				</div>
			</div>

		</div>
	</ng-template>

	<ng-template #lecture>
		<div class="d-flex align-items-center">
			<div class="d-flex justify-content-between align-items-center title-row cell-charge-annexe cell-charge-annexe-title">
				<span>{{chargeAnnexe.titre}}</span>
				<div *ngIf="chiffrage?.id === lastChiffrage?.id"
					 class="d-flex align-items-center button-action">

					<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #menu="matMenu" class="itsf-override">
						<button (click)="changeStateItemNode()" mat-menu-item>
							<span class="sp-edit gradient"></span>
							<span class="ms-2">{{'APP.EDIT' | translate}}</span>
						</button>
						<button (click)="deleteChargeAnnexe()" mat-menu-item>
							<span class="sp-delete gradient"></span>
							<span class="ms-2">{{'APP.DELETE' | translate}}</span>
						</button>
					</mat-menu>
				</div>
			</div>

			<div [class.bg-grey-extralight-3]="chiffrage?.type === 'REEL'" *ngIf="chiffrage?.type !== 'MINMAX'"
				 class="d-flex align-items-center cell-charge-annexe">
				<div class="charge-row cell-charge-annexe-value">
					{{chargeAnnexe.charge| number:'1.0-3'}}
				</div>
			</div>

			<div *ngFor="let repartition of chargeAnnexe.sousRepartitions">
				<div class="d-flex align-items-center cell-charge-annexe">
					<div [class.min-max-repartition]="chiffrage.type ==='MINMAX'" [ngSwitch]="chiffrage.type"
						 class="repartition-row cell-charge-annexe-value h-100 d-flex align-items-center justify-content-center">
						<div *ngSwitchCase="'PERCENT'">
							{{repartition.charge| number:'1.0-3'}}
						</div>
						<div *ngSwitchCase="'REEL'">
							{{repartition.charge| number:'1.0-3'}}
						</div>
						<div *ngSwitchCase="'MINMAX'" class="d-flex flex-row min-max-container">
							<div
								class="min d-flex align-items-center justify-content-center">{{repartition.chargeMin| number:'1.0-3'}}</div>
							<div
								class="d-flex align-items-center justify-content-center">{{repartition.chargeMax| number:'1.0-3'}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>
