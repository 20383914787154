import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit, Output,
	SimpleChanges
} from '@angular/core';
import {ChiffrageTypeEnum, IChiffrageDto} from '../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {
	IRepartitionDto, RepartitionDto
} from '../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {ChiffrageGlobalDatasource, DatasourceItemNode} from './datasource/chiffrage-global.datasource';
import {ChargeAnnexeService} from '../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.service';
import {AvantVenteBusiness} from '../../../business/avant-vente.business';
import {ISousRepartitionDto} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {Observable} from 'rxjs';
import {ICategorieDto} from '../../../../core/business/service/avant-vente/categorie/categorie.dto';
import {SousRepartitionService} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.service';
import {IChargeAnnexeDto} from '../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';

export class ChiffrageGlobalItemFlatNode {
	displayName: string;
	level: number;
	expandable: boolean;
	item: any;
	order: number;
}

class TotalCharge {
	type: ChiffrageTypeEnum;
	sommeCharge: number;
	sommeChargeMin: number;
	sommeChargeMax: number;

	constructor(type: ChiffrageTypeEnum, sommeCharge: number, sommeChargeMin: number, sommeChargeMax: number) {
		this.type = type;
		this.sommeCharge = sommeCharge;
		this.sommeChargeMin = sommeChargeMin;
		this.sommeChargeMax = sommeChargeMax;
	}
}

@Component({
	selector: 'app-chiffrage-global',
	templateUrl: './chiffrage-global.component.html',
	styleUrls: ['./chiffrage-global.component.scss'],
})
export class ChiffrageGlobalComponent implements OnInit, AfterViewInit, OnChanges {

	@Input() chiffrage: IChiffrageDto;
	@Input() lastChiffrage: IChiffrageDto;
	@Input() repartitions: IRepartitionDto[];
	@Input() eventNewRepartition: Observable<IRepartitionDto> = new Observable<IRepartitionDto>();
	@Input() eventNewCategorie: Observable<ICategorieDto[]> = new Observable<ICategorieDto[]>();

	@Output() changedChiffrageValue: EventEmitter<IChiffrageDto> = new EventEmitter<IChiffrageDto>();
	@Output() needToUpdateRepartitions: EventEmitter<IChiffrageDto> = new EventEmitter<IChiffrageDto>();

	treeControl: FlatTreeControl<ChiffrageGlobalItemFlatNode>;
	treeFlattener: MatTreeFlattener<DatasourceItemNode, ChiffrageGlobalItemFlatNode>;
	dataSource: MatTreeFlatDataSource<DatasourceItemNode, ChiffrageGlobalItemFlatNode>;
	businessDatasource: ChiffrageGlobalDatasource;

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap: Map<ChiffrageGlobalItemFlatNode, DatasourceItemNode> = new Map<ChiffrageGlobalItemFlatNode, DatasourceItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap: Map<DatasourceItemNode, ChiffrageGlobalItemFlatNode> = new Map<DatasourceItemNode, ChiffrageGlobalItemFlatNode>();

	haveAtLeastOnFormNotSave: boolean = false;

	totalChargeDeveloppement: number;
	totalChargeGlobalJour: number;
	totalGlobalJourByRepartitionEuro: TotalCharge[];
	Math: Math = Math;

	categories: ICategorieDto[];

	constructor(private chargeAnnexeService: ChargeAnnexeService, private sousRepartitionService: SousRepartitionService) {
		this.businessDatasource = new ChiffrageGlobalDatasource(this.chargeAnnexeService, this.sousRepartitionService);

		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<ChiffrageGlobalItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	}

	ngOnInit(): void {
		this.businessDatasource.dataChange.subscribe(data => {
			this.dataSource.data = data;
			this.updateAfterChange();
		});

		this.eventNewCategorie.subscribe((categories: ICategorieDto[]) => {
			if (categories) {
				this.categories = categories;
				this.updateAfterChange();
			}
		});

		this.eventNewRepartition.subscribe((repartitionDto: IRepartitionDto) => {
			this.reloadSousRepartition(repartitionDto);
		});
	}

	ngAfterViewInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.chiffrage?.currentValue) {
			this.businessDatasource.findByChiffrage(changes.chiffrage.currentValue.id);
		}

		this.updateAfterChange();
	}

	getLevel = (node: ChiffrageGlobalItemFlatNode) => node.level;
	isExpandable = (node: ChiffrageGlobalItemFlatNode) => node.expandable;
	getChildren = (node: DatasourceItemNode): DatasourceItemNode[] => node.children;
	hasChild = (_: number, _nodeData: ChiffrageGlobalItemFlatNode) => _nodeData.expandable;
	hasNoContent = (_: number, _nodeData: ChiffrageGlobalItemFlatNode) => _nodeData.item === null || _nodeData.item === undefined;

	transformer = (node: DatasourceItemNode, level: number) => {
		const existingNode: ChiffrageGlobalItemFlatNode = this.nestedNodeMap.get(node);
		const flatNode: ChiffrageGlobalItemFlatNode = existingNode && existingNode.displayName === node.displayName
			? existingNode
			: new ChiffrageGlobalItemFlatNode();

		flatNode.displayName = node.displayName;
		flatNode.level = level;
		flatNode.expandable = !!node.children;
		flatNode.item = node.item;
		flatNode.order = node.order;

		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	}

	addFormNewChargeAnnexe(): void {
		this.haveAtLeastOnFormNotSave = true;
		this.businessDatasource.insertChargeAnnexeForm('');
	}

	saveFormChargeAnnexe(node: ChiffrageGlobalItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);

		let updatedChargeAnnexe: Observable<IChargeAnnexeDto>;
		if (node.item.id) { // update
			updatedChargeAnnexe = this.businessDatasource.updateChargeAnnexe(nestedNode, node.item);
		} else {
			updatedChargeAnnexe = this.businessDatasource.createChargeAnnexe(this.chiffrage, nestedNode, node.item);
		}

		updatedChargeAnnexe.subscribe(() => {
			this.needToUpdateRepartitions.emit(this.chiffrage);
			this.haveAtLeastOnFormNotSave = false;
		});
	}

	getTotalGlobalJourRepartition(repartition: RepartitionDto, type: string): number {
		if (!repartition) {
			return 0;
		}

		let sommeCharge: number;
		if (type === 'REEL' || type === 'PERCENT') {
			sommeCharge = repartition.sommeCharge;
		} else if (type === 'MIN') {
			sommeCharge = repartition.sommeChargeMin;
		} else if (type === 'MAX') {
			sommeCharge = repartition.sommeChargeMax;
		}

		const sumChargeAnnexe: number = this.chiffrage.chargeAnnexes.map((chargeAnnexe) => {
			if (chargeAnnexe?.sousRepartitions?.length) {
				const filteredSousRepartition: ISousRepartitionDto[] =
					chargeAnnexe.sousRepartitions.filter((sr) => sr.repartitionId === repartition.id);

				if (filteredSousRepartition.length) {
					if (type === 'REEL' || type === 'PERCENT') {
						return filteredSousRepartition[0].charge;
					} else if (type === 'MIN') {
						return filteredSousRepartition[0].chargeMin;
					} else if (type === 'MAX') {
						return filteredSousRepartition[0].chargeMax;
					}
				} else {
					return 0;
				}
			}
		}).reduce((acc, current) => acc + current, 0);

		return sommeCharge +
			Math.ceil(this.chiffrage.pourcentageGestionProjet * sommeCharge / 25) / 4 +
			Math.ceil(this.chiffrage.pourcentageITTest * sommeCharge / 25) / 4 + sumChargeAnnexe;
	}

	getTotalChiffrage(): number {
		const sumChargeAnnexe: number = this.chiffrage.chargeAnnexes.map((chargeAnnexe) => {
			if (chargeAnnexe) {
				return chargeAnnexe.charge;
			}
		}).reduce((acc, current) => acc + current, 0);

		this.chiffrage.chargeTotal = this.totalChargeDeveloppement + sumChargeAnnexe +
			Math.ceil(this.chiffrage.pourcentageGestionProjet * this.totalChargeDeveloppement / 25) / 4 +
			Math.ceil(this.chiffrage.pourcentageITTest * this.totalChargeDeveloppement / 25) / 4;

		return this.chiffrage.chargeTotal;
	}

	clearFormChargeAnnexe(node: ChiffrageGlobalItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		this.businessDatasource.clearChargeAnnexe(nestedNode);
	}

	deleteChargeAnnexe(node: ChiffrageGlobalItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		this.businessDatasource.deleteChargeAnnexe(nestedNode);
	}

	reloadSousRepartition(repartitionDto: IRepartitionDto): void {
		this.businessDatasource.reloadSousRepartition(repartitionDto).subscribe(() => {
		});
	}

	private updateAfterChange(): void {
		if (this.repartitions && !this.haveAtLeastOnFormNotSave) {
			this.totalGlobalJourByRepartitionEuro = [];
			this.businessDatasource.data.map(chargeAnnexe => chargeAnnexe.item.sousRepartitions.sort(AvantVenteBusiness.sortSousRepartitionOrder));
			for (const repartition of this.repartitions) {
				const sousRepartitions: ISousRepartitionDto[] = [].concat(...this.businessDatasource.data.map(value =>
					value.item.sousRepartitions.filter(sousRepartition => sousRepartition.repartitionId === repartition.id)
				));

				if (this.chiffrage.type !== ChiffrageTypeEnum.MINMAX) {
					const sumCharge: number = sousRepartitions.reduce((acc, value) => acc + value.charge, 0) +
						repartition.sommeCharge + (repartition.sommeCharge * this.chiffrage.pourcentageGestionProjet / 100) +
						(repartition.sommeCharge * this.chiffrage.pourcentageITTest / 100);
					this.totalGlobalJourByRepartitionEuro.push(new TotalCharge(this.chiffrage.type, sumCharge, null, null));
				} else if (this.chiffrage.type === ChiffrageTypeEnum.MINMAX) {
					const sommeChargeMin: number = sousRepartitions.reduce((acc, value) => {
						return acc + value.charge;
					}, 0) + repartition.sommeChargeMin + (repartition.sommeChargeMin * this.chiffrage.pourcentageGestionProjet / 100) + (repartition.sommeChargeMin * this.chiffrage.pourcentageITTest / 100)
					const sommeChargeMax: number = sousRepartitions.reduce((acc, value) => {
						return acc + value.charge;
					}, 0) + repartition.sommeChargeMax + (repartition.sommeChargeMax * this.chiffrage.pourcentageGestionProjet / 100) + (repartition.sommeChargeMax * this.chiffrage.pourcentageITTest / 100)
					this.totalGlobalJourByRepartitionEuro.push(new TotalCharge(this.chiffrage.type, null, sommeChargeMin, sommeChargeMax));
				}
			}
		}

		if (this.categories) {
			this.totalChargeDeveloppement = this.categories.reduce((acc, value) => acc + value.sommeCharge, 0);
		} else {
			this.totalChargeDeveloppement = 0;
		}

		if (this.chiffrage) {
			this.chiffrage.categories = this.categories;
			this.chiffrage.chargeAnnexes = this.dataSource.data.map((d) => d.item);
			this.changedChiffrageValue.emit(this.chiffrage);
		}
	}
}
