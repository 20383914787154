import {IProjectDto} from '../project/project.dto';
import {IMatrix} from '../../common/common.dto';

export interface IProjectAlertDto {
	id: number;
	creationDate: Date;
	type: ProjectAlertTypeEnum;
	cause: MessageAlertCauseEnum;
	data: string;
	message: string;
	projectId: number;
	project: IProjectDto;
}

export interface INumberAlertsDto {
	type: ProjectAlertTypeEnum;
	count: string;
}

export interface IProjectNumberAlertsDto {
	projectId: number;
	nbAlerts: INumberAlertsDto[];
}

export enum ProjectAlertTypeEnum {
	ERROR = 'ERROR',
	WARNING = 'WARNING'
}

export enum MessageAlertCauseEnum {
	WITHOUT_LOT = 'WITHOUT_LOT',
	WITHOUT_BUDGET = 'WITHOUT_BUDGET',
	WITHOUT_EPIC = 'WITHOUT_EPIC',
	SPENT_AFTER_VALIDATION = 'SPENT_AFTER_VALIDATION',
	IMPUTATION_IN_LOT_DRAFT = 'IMPUTATION_IN_LOT_DRAFT',
	IMPUTATION_IN_LOT_FINISHED = 'IMPUTATION_IN_LOT_FINISHED',
	IMPUTATION_STORY_STATUS = 'IMPUTATION_STORY_STATUS'
}

export const AlertTypeByCauseMatrix: IMatrix = {
	[MessageAlertCauseEnum.WITHOUT_LOT]: ProjectAlertTypeEnum.ERROR,
	[MessageAlertCauseEnum.WITHOUT_BUDGET]: ProjectAlertTypeEnum.ERROR,
	[MessageAlertCauseEnum.WITHOUT_EPIC]: ProjectAlertTypeEnum.WARNING,
	[MessageAlertCauseEnum.SPENT_AFTER_VALIDATION]: ProjectAlertTypeEnum.ERROR,
	[MessageAlertCauseEnum.IMPUTATION_IN_LOT_DRAFT]: ProjectAlertTypeEnum.ERROR,
	[MessageAlertCauseEnum.IMPUTATION_IN_LOT_FINISHED]: ProjectAlertTypeEnum.ERROR,
	[MessageAlertCauseEnum.IMPUTATION_STORY_STATUS]: ProjectAlertTypeEnum.WARNING
};

export interface HasAlert {
	hasAlert: boolean;
}
