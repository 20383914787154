import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigType, UserConfigDto} from './user-config.dto';


@Injectable({
	providedIn: 'root'
})
export class UserConfigService extends ApiService {
	private route: string = 'api/user-config';

	constructor(public http: HttpClient) {
		super(http);
	}

	createOrUpdate(configType: ConfigType, data: any): Observable<UserConfigDto> {
		return this.post(this.route, {configType: configType, data: data});
	}

	getConfig(configType: ConfigType): Observable<UserConfigDto> {
		return this.get(this.route + '/' + configType);
	}
}
