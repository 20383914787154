export default class ExcelRow {
	cells: ExcelCell[];
	height: number;

	constructor(height?: number) {
		this.cells = [];
		this.height = height;
	}

	addEmptyCells(nb: number, config?: ExcelCellConfig): void {
		for (let i: number = 0; i < nb; ++i) {
			this.cells.push(new ExcelCell('', null, config ? config : null));
		}
	}
}

export class ExcelCell {

	value: number | string | Date | any;
	cellFormat: ExcelCellFormat;
	config: ExcelCellConfig;
	protection: ExcelProtection;

	constructor(value: number | string | Date | any, cellFormat?: ExcelCellFormat, config?: ExcelCellConfig, protection?: ExcelProtection) {
		this.value = value;
		this.cellFormat = cellFormat ? cellFormat : null;
		this.config = config ? config : null;
		this.protection = protection ? protection : null;
	}
}

// Only on version exceljs : 3.4.0
export class ExcelProtection {
	locked: boolean;
	hidden: boolean;

	constructor(locked: boolean, hidden: boolean) {
		this.locked = locked;
		this.hidden = hidden;

	}
}

export class ExcelCellConfig {

	cellColor: ExcelCellColor;
	cellFont: ExcelCellFont;
	cellBorder: ExcelCellBorder;
	cellAlignement: ExcelAlignement;

	constructor(cellColor?: ExcelCellColor, cellFont?: ExcelCellFont, cellBorder?: ExcelCellBorder, cellAlignement?: ExcelAlignement) {
		this.cellColor = cellColor;
		this.cellFont = cellFont;
		this.cellBorder = cellBorder;
		this.cellAlignement = cellAlignement;
	}
}

export class ExcelCellFormat {
	numFmt: string;

	constructor(numFmt: string) {
		this.numFmt = numFmt;
	}
}

export interface ExcelCellColor {
	fill: ExcelCellFill;
}

export interface ExcelCellFont {
	name: string;
	color: ExcelCellARGB;
	family: number;
	size: number;
	underline: boolean | 'none' | 'single' | 'double' | 'singleAccounting' | 'doubleAccounting';
	bold: boolean;
	italic: boolean;
}

export interface ExcelCellBorder {
	top: ExcelBorderStyle;
	left: ExcelBorderStyle;
	bottom: ExcelBorderStyle;
	right: ExcelBorderStyle;
}

export interface ExcelBorderStyle {
	style: 'thin' | 'dotted' | 'dashDot' | 'hair' | 'dashDotDot' | 'slantDashDot' | 'mediumDashed' | 'mediumDashDotDot' | 'mediumDashDot' | 'medium' | 'double' | 'thick';
	color: ExcelCellARGB;
}

export interface ExcelAlignement {
	vertical: 'top' | 'middle' | 'bottom' | 'distributed' | 'justify';
	horizontal: 'left' | 'center' | 'right' | 'fill' | 'justify' | 'centerContinuous' | 'distributeds';
	wrapText: boolean;
	indent: number;
	readingOrder: 'rtl' | 'ltr';
	textRotation: number | 'vertical'; /*0 to 90 ou -1 to -90*/
}

export interface ExcelCellFill {
	patternType: string;
	fgColor: ExcelCellARGB; // couleur de fond
	bgColor: ExcelCellARGB; // aucune idée de ce que ça fait ????
}

export interface ExcelCellARGB {
	argb: string;
}
