import {
	AfterViewInit,
	Component, ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {ISousCategorieDto} from '../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {IChargeAnnexeDto} from '../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';
import {ISousRepartitionDto} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-form-field-minmax',
	templateUrl: './form-field-minmax.component.html',
	styleUrls: ['./form-field-minmax.component.scss'],
})
export class FormFieldMinMaxComponent implements AfterViewInit, OnInit {

	@Input() parent: ISousCategorieDto | IChargeAnnexeDto;
	@Input() allSousRepartitions: ISousRepartitionDto[];
	@Input() sousRepartition: ISousRepartitionDto;
	@Input() saveForm: () => void;

	@Output() updatedSousRepartition: EventEmitter<ISousRepartitionDto> = new EventEmitter<ISousRepartitionDto>();

	@ViewChild('minInput') minInput: ElementRef;
	@ViewChild('maxInput') maxInput: ElementRef;

	tempMin: number;
	tempMax: number;

	constructor(private _snackbar: MatSnackBar, private _translator: TranslateService) {
	}

	ngOnInit(): void {
		if (this.sousRepartition) {
			if (!this.sousRepartition.chargeMin) {
				this.sousRepartition.chargeMin = 0;
			}

			if (!this.sousRepartition.chargeMax) {
				this.sousRepartition.chargeMax = 0;
			}

			this.tempMin = this.sousRepartition.chargeMin;
			this.tempMax = this.sousRepartition.chargeMax;
		}
	}

	ngAfterViewInit(): void {
		merge(fromEvent(this.minInput.nativeElement, 'keyup').pipe(
			debounceTime(1000),
			distinctUntilChanged()),
			fromEvent(this.maxInput.nativeElement, 'keyup').pipe(
				debounceTime(1000),
				distinctUntilChanged())).pipe(tap(() => {

			if (this.tempMin && this.tempMax && this.tempMin > this.tempMax) {
				this._snackbar.open(this._translator.instant('AVANT_VENTE.ERROR.MIN_GREATER_THAN_MAX'), '', {
					panelClass: 'error'
				});
			} else {
				if (this.tempMin) {
					this.sousRepartition.chargeMin = Number(this.tempMin);
				}
				if (this.tempMax) {
					this.sousRepartition.chargeMax = Number(this.tempMax);
				}
				this.updatedSousRepartitionChargeMinMax();
			}
		})).subscribe();
	}

	private updatedSousRepartitionChargeMinMax(): void {
		this.updatedSousRepartition.emit(this.sousRepartition);
	}
}
