import {AbstractPaginateDatasource} from '../../core/pagination/abstract-paginate.datasource';
import {Pagination} from '../../core/pagination/dto/pagination.dto';
import {PaginationOption} from '../../core/pagination/dto/pagination-option.dto';
import {SpinnerService} from '../../core/service/spinner.service';
import {TempoJiraService} from '../../core/business/service/tempo-jira/tempo-jira.service';
import {ITempoJiraDto} from '../../core/business/service/tempo-jira/tempo-jira.dto';
import * as moment from 'moment';
import {UserBaseDto} from '../../core/business/service/user-base/user-base.dto';

export class TempoJiraDatasource extends AbstractPaginateDatasource<ITempoJiraDto> {
	totalData: ITempoJiraDto[];
	page: Map<number, ITempoJiraDto[]>;
	paginationOption: PaginationOption = null;
	order: string = '';
	incompleteImputationsOnly: boolean = false;
	totalHours: number = 0;
	totalElem: number = 0;
	username: string = '';
	companiesId: number[];
	save: ITempoJiraDto[] = [];

	constructor(private tempoJiraService: TempoJiraService, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	setPaginationOption(po: PaginationOption): void {
		this.paginationOption = po;
	}

	updatePage(): void {
		this.notifyChanges(this.page.get(this.paginationOption.page));
	}

	setOrder(type: string): void {
		this.order = type;
		this.sortUsers();
	}
	sortUsers(): void {
		if (this.order === 'asc') {
			this.totalData.sort((a, b) => {
				if (a.timeSpent > b.timeSpent) {
					return 1;
				} else if (a.timeSpent < b.timeSpent) {
					return -1;
				} else {
					return 0;
				}
			});
		} else if (this.order === 'desc') {
			this.totalData.sort((a, b) => {
				if (a.timeSpent < b.timeSpent) {
					return 1;
				} else if (a.timeSpent > b.timeSpent) {
					return -1;
				} else {
					return 0;
				}
			});
		} else {
			this.totalData.sort((a, b) => a.displayName.localeCompare(b.displayName));
		}
		this.applyFilter();
	}

	userInCompany(user: ITempoJiraDto): boolean {
		for (const companyId of this.companiesId) {
			if (user.company.id === companyId) {
				return true;
			}
		}
		return false;
	}

	applyFilter(): void {
		this.totalElem = 0;
		this.page = new Map();
		let cnt: number = 0;
		let counter: number = 0;
		const pidx: number = !this.paginationOption ? 0 : this.paginationOption.page;
		const max: number = this.paginationOption.limit;
		let p: ITempoJiraDto[] = [];
		this.totalHours = 0;
		this.totalData.forEach(it => {
			if (it) {
				this.totalHours += it.timeSpent / 3600;
				if ((!this.incompleteImputationsOnly || (this.incompleteImputationsOnly && it.warning))) {
					if (this.userInCompany(it) && (this.username.length === 0 || it.displayName.toLowerCase().indexOf(this.username.toLowerCase()) !== -1)) {
						p.push(it);
						counter++;
						this.totalElem++;
					}
					if (counter === max) {
						counter = 0;
						this.page.set(cnt, p);
						p = [];
						cnt++;
					}
				}
			}
		});
		if (p.length > 0) {
			this.page.set(cnt, p);
		}
		this.notifyChanges(this.page.get(pidx));
	}

	async getData(date: moment.Moment, filter: string[] = []): Promise<void> {
		this.spinnerService.enableLoading();
		this.save = await this.tempoJiraService.getAllUsers(date, false);
		this.notifyChanges(this.applyIgnoreFilter(filter));
		this.totalData = this.datas();
		this.sortUsers();
		this.spinnerService.disableLoading();
	}
	applyIgnoreFilter(filter: string[]): ITempoJiraDto[] {
		if (!filter.length) {
			return this.save;
		}
		return this.save.filter(value => !filter.includes(value.id) );
	}
	notifyChanges(data: ITempoJiraDto[]): void {
		this.dataSubject.next(data);
	}

	transformResults(results: Pagination<ITempoJiraDto>): ITempoJiraDto[] {
		return results.results;
	}

}
