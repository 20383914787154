export class UtilsDatasource {

	static likeRegExp(expression: string, caseSensitive: boolean = false): RegExp {
		return new RegExp(`${
			expression.split(/(\[.+?])/g)
				.map((s, i) => i % 2 ?
					s.replace(/\\/g, '\\\\') :
					s.replace(/[-\/\\^$*+?.()|[\]{}%_]/g, m => {
						switch (m) {
							case '%':
								return '.*';
							case '_':
								return '.';
							default:
								return `\\${m}`;
						}
					})
				).join('')
		}`, caseSensitive ? '' : 'i');
	}

	static isMatchRegexWithRemoveDiacritics(likeExpression: string, value: string, caseSensitive: boolean = false): boolean {
		const regExp: RegExp = UtilsDatasource.likeRegExp(likeExpression, caseSensitive);
		const withoutDiacriticsValue: string = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return regExp.test(withoutDiacriticsValue);
	}

	static searchWithRemoveDiacritics(likeExpression: string, value: string, caseSensitive: boolean = false): number {
		const regExp: RegExp = UtilsDatasource.likeRegExp(likeExpression, caseSensitive);
		const withoutDiacriticsValue: string = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return withoutDiacriticsValue.search(regExp);
	}
}
