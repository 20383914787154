import { Pipe, PipeTransform } from '@angular/core';
import {LotDataSourceContent} from '../datasource/projet-detail.datasource';
import {IssueTypeEnum} from '../dto/projet-details.objects';

@Pipe({
  name: 'picto'
})
export class PictoPipe implements PipeTransform {

  transform(issue: LotDataSourceContent, issueType: IssueTypeEnum, backlogLot: boolean): unknown {
	  let pictoClass: string;
	  if (backlogLot && issueType === IssueTypeEnum.STORY) {
		  pictoClass = 'sp-iconmonstr-triangle-1 color-red font-size-12';
	  } else if (issue.orphan) {
		  pictoClass = 'sp-iconmonstr-triangle-1 warning-color font-size-12';
	  } else if (issueType === IssueTypeEnum.STORY) {
		  pictoClass = 'sp-' + issueType + '-' + issue.storyType;
	  } else if (issueType === IssueTypeEnum.SUBTASK) {
		  pictoClass = 'sp-' + issueType + '-' + issue.subtaskType;
	  } else {
		  pictoClass = 'sp-' + issueType;
	  }

	  return pictoClass;
  }

}
