<app-dialog-popup [popupTitle]="('APP.DELETE' | translate) + ' ' + clientToDelete.name">
	<div dialog-popup-content class="dialogContent">
		<div class="text-center w-100 color-red my-2 fw-bold">{{'APP.CONFIRM_DELETE' | translate}}</div>

		<div>
			{{'CLIENT.EDIT_FORM.NAME' | translate}} : {{clientToDelete.name}}
		</div>
		<div>
			{{'CLIENT.LOGO' | translate}} :
			<img *ngIf="clientToDelete.logo" [src]="clientToDelete.logo | safizer" class="avatar">
		</div>
		<div *ngIf="clientToDelete.projects">
			<div>
				{{'CLIENT.DELETE_PROJECTS' | translate}}
			</div>
			<ul >
				<li *ngFor="let projet of clientToDelete.projects">{{projet.name}}</li>
			</ul>
		</div>
	</div>
	<div dialog-popup-footer>
		<button mat-flat-button class="its-button" (click)="onYesClick()">{{'APP.DELETE' | translate}}</button>
	</div>
</app-dialog-popup>
