import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
	selector: '[appNumberInputMin][ngModel]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: NumberInputMinDirective,
			multi: true
		}
	]
})
export class NumberInputMinDirective implements Validator {

	@Input() customMin: number;

	validate(control: AbstractControl): { [key: string]: any } {
		const value: number = parseFloat(('' + control.value).replace(',', '.').replace(/[^\d.,]/g, ''));
		return (value < this.customMin) ? {'appNumberInputMin': true} : null;
	}

}
