import {IClientDto} from '../../../core/business/service/client/client.dto';
import {ICompanyDto} from '../../../core/business/service/company/company.dto';

export default class GestionProjectFilter {
	client: IClientDto;
	companies: ICompanyDto[];
	projectName: string;
	projectStatus: string[];
	cpId: number;

	constructor() {
		this.projectStatus = [];
	}
}
