import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogValidationComponent} from './dialog-validation.component';
import {DialogPopupModule} from '../../../template/dialog/dialog-popup.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
	declarations: [DialogValidationComponent],
	imports: [
		CommonModule,
		DialogPopupModule,
		MatDialogModule,
		MatButtonModule
	]
})
export class DialogValidationModule {
}
