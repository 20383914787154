import {AbstractPaginateDatasource} from '../../../core/pagination/abstract-paginate.datasource';
import {Pagination} from '../../../core/pagination/dto/pagination.dto';
import {PaginationOption} from '../../../core/pagination/dto/pagination-option.dto';
import {UserBaseService} from '../../../core/business/service/user-base/user-base.service';
import {IUserBaseDto} from '../../../core/business/service/user-base/user-base.dto';
import {UserListFilter} from '../dto/user-list.dto';
import {SpinnerService} from '../../../core/service/spinner.service';

export class UserDataSource extends AbstractPaginateDatasource<IUserBaseDto> {

	constructor(private userBaseService: UserBaseService, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	findAllPaging(paginationOption: PaginationOption): void {
		this.notifyPagingData(this.userBaseService.getAllPagingWithAvatar(paginationOption));
	}

	findFilterByUsernameActifPaging(filter: UserListFilter, paginationOption: PaginationOption): void {
		this.notifyPagingData(this.userBaseService.findFilterByUsernameActifPaging(filter, paginationOption));
	}

	transformResults(results: Pagination<IUserBaseDto>): IUserBaseDto[] {
		return results.results;
	}
}
