<app-dialog-popup [popupTitle]="'APP.EXPORT-TEMPO.PROGRESS.TITLE' | translate" [footer]="false" [closeButton]="false">
	<div class="dialog-export-tempo-progress" dialog-popup-content>
		<div *ngIf="progress">
			<div class="progress-bar-header">
				<p class="progress-bar-text">
					{{ 'APP.EXPORT-TEMPO.PROGRESS.USER' | translate }} {{ progress.currentUserIndex }} / {{ progress.totalUser }}
				</p>
			</div>
			<div class="progress">
				<div class="progress-bar" role="progressbar" [style.width]="calculateProgress(progress.totalUser, progress.currentUserIndex)" aria-valuenow="progress.currentProgress.index" aria-valuemin="0" [attr.aria-valuemax]="progress.totalUser"></div>
			</div>

			<div *ngIf="progress.totalProject > 1">
				<div class="progress-bar-header">
					<p class="progress-bar-text">
						{{ calculateProgress(progress.totalProject, progress.currentProjectIndex) }}
					</p>
				</div>
				<div class="progress">
					<div class="progress-bar" role="progressbar" [style.width]="calculateProgress(progress.totalProject, progress.currentProjectIndex)" aria-valuenow="progress.currentProgress.index" aria-valuemin="0" [attr.aria-valuemax]="progress.totalProject"></div>
				</div>
			</div>
		</div>
	</div>
</app-dialog-popup>
