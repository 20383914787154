import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-popup',
	styleUrls: ['./dialog-popup.component.scss'],
	templateUrl: './dialog-popup.component.html'
})
export class DialogPopupComponent {

	@Input()
	popupTitle: string;

	@Input()
	warn: boolean = false;

	@Input()
	footer: boolean = true;

	@Input()
	closeButton: boolean = true;

	constructor(public dialogRef: MatDialogRef<DialogPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
