import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IRepartitionDto} from './repartition.dto';
import {IChiffrageDto} from '../chiffrage/chiffrage.dto';

@Injectable({
	providedIn: 'root'
})
export class RepartitionService extends ApiService {

	private route: string = '/api/av-repartition';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(repartitionDto: IRepartitionDto): Observable<IRepartitionDto> {
		return this.post(this.route, repartitionDto);
	}

	update(repartitionDto: IRepartitionDto): Observable<IRepartitionDto> {
		return this.put(this.route + '/' + repartitionDto.id, repartitionDto);
	}

	remove(repartitionDto: IRepartitionDto): Observable<IRepartitionDto> {
		return this.delete(this.route + '/' + repartitionDto.id);
	}

	findByChiffrage(chiffrageDto: IChiffrageDto): Observable<IRepartitionDto[]> {
		return this.get(this.route + '/findByChiffrage/' + chiffrageDto.id);
	}
}
