export class PaginationOption {

	public limit: number;
	public page: number;
	public pageSizeOptions: number[];
	public sortBy: PaginationSortBy;

	constructor(limit: number, page: number, pageSizeOptions?: number[], sortColumnName?: string, sortDirection?: string) {
		this.limit = limit;
		this.page = page;

		if (pageSizeOptions) {
			this.pageSizeOptions = pageSizeOptions;
		} else {
			this.pageSizeOptions = [10, 20, 50];
		}
	}
}

export class PaginationSortBy {
	sortColumnName: string;
	sortDirection: PaginationSort;

	constructor(columName: string, direction: PaginationSort) {
		this.sortColumnName = columName;
		this.sortDirection = direction;
	}
}

export enum PaginationSort {
	ASC = 'ASC',
	DESC = 'DESC',
}
