<app-header [displayBreadCrumb]="('AVANT_VENTE.DETAIL.HEADER' | translate) + (avantVente$  | async)?.titre">

	<div *ngIf="avantVente$ | async" class="d-flex flex-row align-items-center avant-vente-container">
		<div class="av-title-container">
			<h4 class="av-title">{{ avantVente?.titre }}</h4>
		</div>
		<div class="av-status-container d-flex flex-column">
			<span>{{ 'AVANT_VENTE.ETAT.STATUS' | translate }}</span>
			<span class="av-status">{{'AVANT_VENTE.ETAT.' + avantVente?.etat | translate | uppercase }}</span>
		</div>
		<span (click)="openDialogEditAvantVent()" class="sp-edit gradient edit cursor-pointer"></span>
	</div>

	<mat-form-field class="av-select">
		<mat-label>Version</mat-label>
		<mat-select [(ngModel)]="selectedChiffrage" [compareWith]="chiffrageEquals"
					[hideSingleSelectionIndicator]="true" #filterChiffrage>
			<mat-option [value]="chiffrage" *ngFor="let chiffrage of chiffrages">
				<span>Offre {{chiffrage.numero}} - </span>
				<span>{{chiffrage.dateCreation | date: 'shortDate'}} - {{chiffrage.chargeTotal | number:'1.0-3'}}
					J - {{ 'AVANT_VENTE.CHIFFRAGE.TYPE.' + chiffrage.type | uppercase | translate }}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="actions">
		<button (click)="copyChiffragePlusRecent()" class="its-button" mat-flat-button>
			{{'AVANT_VENTE.DETAIL.COPY_VERSION' | translate}}
		</button>
		<button (click)="extractExcel()" class="its-button" mat-flat-button>{{'APP.EXPORT' | translate}}</button>
		<button (click)="deleteCurrentChiffrage()" *ngIf="(chiffrages$ | async)?.length > 1"
				class="sp-delete delete-button-style"></button>
	</div>
</app-header>

<div *ngIf="avantVente$ | async as avantVente" class="d-flex justify-content-start align-items-center">
	<div class="d-flex justify-content-between row-avant-vente-title">
		<div class="d-flex align-items-center">
			<img [alt]="'PROJECT.LOGO' | translate" [src]="avantVente.client.logo | safizer"
				 *ngIf="avantVente?.client?.logo" class="logo"/>

			<div class="d-flex flex-column title">Offre {{selectedChiffrage?.numero}}</div>
			<div class="offer-parameter">
				<div class="color-grey-light-3">{{'AVANT_VENTE.CHIFFRAGE_SHORT.TJM' | translate}}</div>
				<div class="color-grey">{{selectedChiffrage?.tjm || 0 | number:'1.0-3'}} €</div>
			</div>
			<div class="offer-parameter">
				<div
					class="color-grey-light-3">{{'AVANT_VENTE.CHIFFRAGE_SHORT.PERCENT_GEST_PROJ' | translate}}</div>
				<div class="color-grey">{{selectedChiffrage?.pourcentageGestionProjet || 0 | number:'1.0-3'}}</div>
			</div>
			<div class="offer-parameter">
				<div class="color-grey-light-3">{{'AVANT_VENTE.CHIFFRAGE_SHORT.PERCENT_IT_TEST' | translate}}</div>
				<div class="color-grey">{{selectedChiffrage?.pourcentageITTest || 0 | number:'1.0-3'}}</div>
			</div>
		</div>

		<div class="d-flex align-items-center button-action">
			<button [title]="'AVANT_VENTE.DETAIL.ADD_CATEGORY' | translate" (click)="addNewCategorie()"
					*ngIf="selectedChiffrage === lastOneChiffrage"
					class="add-task me-2" color="primary" mat-mini-fab>
				<mat-icon>add</mat-icon>
			</button>
			<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
				<mat-icon>more_horiz</mat-icon>
			</button>
			<mat-menu #menu="matMenu" class="itsf-override">
				<button (click)="openDialogEditChiffrage()" *ngIf="selectedChiffrage === lastOneChiffrage"
						mat-menu-item>
					<span class="sp-edit gradient"></span>
					<span class="ms-2">{{'APP.EDIT' | translate}}</span>
				</button>
				<ng-container *ngIf="chiffrages$ | async as chiffrages">
					<button (click)="deleteCurrentChiffrage()" *ngIf="chiffrages.length > 1" mat-menu-item>
						<span class="sp-delete gradient"></span>
						<span class="ms-2">{{'APP.DELETE' | translate}}</span>
					</button>
				</ng-container>
			</mat-menu>
		</div>
	</div>

	<div [class.bg-grey-extralight-3]="selectedChiffrage?.type === 'REEL'"
		 *ngIf="selectedChiffrage?.type !== 'MINMAX'"
		 class="d-flex align-items-center cell-header">
		<div class="charge-row charge-title cell-title-repartition">
			Charge<br/>(JOURS)
		</div>
	</div>

	<div *ngFor="let repartition of repartitions">
		<div class="d-flex align-items-center cell-header position-relative">
			<div [class.min-max-repartition]="selectedChiffrage?.type === 'MINMAX'"
				 class="repartition-row repartition-title cell-title-repartition">
				{{repartition.nom}}<br/>(JOURS)
			</div>
			<div class="absolution-options button-action">
				<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
					<mat-icon>more_horiz</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="itsf-override">
					<button (click)="openDialogEditRepartition(repartition)" mat-menu-item>
						<span class="sp-edit gradient"></span>
						<span class="ms-2">{{'APP.EDIT' | translate}}</span>
					</button>
					<button (click)="deleteCurrentRepartition(repartition)" mat-menu-item>
						<span class="sp-delete gradient"></span>
						<span class="ms-2">{{'APP.DELETE' | translate}}</span>
					</button>
				</mat-menu>
			</div>
		</div>
	</div>
	<div class="ps-3">
		<button (click)="openDialogCreateRepartition()"
				*ngIf="selectedChiffrage === lastOneChiffrage && selectedChiffrage?.type !== 'MINMAX'"
				class="its-button" mat-flat-button>
			{{'AVANT_VENTE.DETAIL.ADD_REPARTITION' | translate}}
		</button>
	</div>
</div>

<app-chiffrage [chiffrage]="selectedChiffrage" [eventDeleteRepartition]="eventDeleteRepartition"
			   [eventNewCategorie]="eventAddNewCategorie" [eventNewRepartition]="eventAddNewRepartition"
			   [lastChiffrage]="lastOneChiffrage" [repartitions]="repartitions" #chiffrageComponent>
</app-chiffrage>

<app-chiffrage-global [chiffrage]="selectedChiffrage" [eventNewCategorie]="chiffrageComponent.changedCategoriesValue"
					  [eventNewRepartition]="eventAddNewRepartition" [lastChiffrage]="lastOneChiffrage"
					  [repartitions]="repartitions" #chiffrageGlobalComponent>
</app-chiffrage-global>

