import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
	selector: '[appRowClass]',
	exportAs: 'appRowClass',
})
export class RowClassDirective implements OnChanges {

	@Input() appRowClass: string;
	@Input() hidden: boolean;

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this._setClass();
	}

	private _setClass(): void {
		if (this.appRowClass) {
			this.renderer.addClass(this.hostElement.nativeElement, this.appRowClass + '-row');
		}
		if (this.hidden) {
			this.renderer.addClass(this.hostElement.nativeElement, 'd-none');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'd-none');
		}
	}
}
