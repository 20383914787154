import {of} from 'rxjs';
import {AbstractPaginateDatasource} from '../../../../core/pagination/abstract-paginate.datasource';
import {Pagination} from '../../../../core/pagination/dto/pagination.dto';
import {ContractStatusEnum, ILotDto} from '../../../../core/business/service/lot/lot.dto';
import {IProjectDto} from '../../../../core/business/service/project/project.dto';
import {SpinnerService} from '../../../../core/service/spinner.service';

export class ExportLot {
	id: number;
	name: string;
	contract: ContractStatusEnum;
	position: number;
}

export class ExportLotDatasource extends AbstractPaginateDatasource<ExportLot> {

	constructor(public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	loadLots(project: IProjectDto, filter: string): void {
		if (filter) {
			this.notifyPagingData(of(new Pagination(project.lots, project.lots.length)), this.filterStatutFunction(filter));
		} else {
			this.notifyPagingData(of(new Pagination(project.lots, project.lots.length)));
		}
	}

	private filterStatutFunction(filter: string): any {
		return function (element: any): any {
			return element.statut === filter;
		};
	}

	transformResults(pagination: Pagination<ILotDto>): ExportLot[] {

		const datasource: ExportLot[] = [];

		for (let i: number = 0; i < pagination.total; i++) {

			const newExportLot: ExportLot = new ExportLot();
			newExportLot.id = pagination.results[i].id;
			newExportLot.name = pagination.results[i].name;
			newExportLot.contract = pagination.results[i].contract;
			newExportLot.position = i;

			datasource.push(newExportLot);
		}

		return datasource;
	}
}
