import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sprintUrl'
})
export class SprintUrlPipe implements PipeTransform {

  transform(sprintJiraId: string, projectJiraIntId: string): string | undefined {
	  if (sprintJiraId && projectJiraIntId) {
		  const baseString: string = 'https://itsfuture-jira.atlassian.net/issues/?jql=';
		  const jqlString: string = encodeURI(`project = ${projectJiraIntId} AND Sprint = ${sprintJiraId} ORDER BY created DESC`);
		  return baseString + jqlString;
	  } else {
	  	return undefined;
	  }
  }

}
