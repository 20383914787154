import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpParamsOptions} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from '../../../service/api.service';
import {firstValueFrom, Observable} from 'rxjs';
import {SuiviListFilter} from '../../../../suivi/suivi-list/dto/suivi-list.dto';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Pagination} from '../../../pagination/dto/pagination.dto';
import GestionProjectFilter from '../../../../gestion/projet/datasource/gestion-project-filter';
import {IProjectDto} from './project.dto';
import * as moment from 'moment';
import {ICompanyDto} from '../company/company.dto';

@Injectable({
	providedIn: 'root'
})
export class ProjectService extends ApiService {

	private route: string = '/api/project';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	removeProject(id: number): Promise<void> {
		return firstValueFrom(this.http.delete(this.route + '/' + id)).then();
	}

	modifyProject(id: number, project: IProjectDto): Promise<IProjectDto> {
		return firstValueFrom(this.http.put<IProjectDto>(this.route + '/' + id, project));
	}

	modifyDisplayParameters(id: number, parameters: string[]): Promise<IProjectDto> {
		return firstValueFrom(this.http.put<IProjectDto>(this.route + '/' + id + '/parameters', parameters));
	}

	modifyGestionParameters(id: number, parameters: string[], paramsToChange: number): Promise<IProjectDto> {
		return firstValueFrom(this.http.put<IProjectDto>(this.route + '/' + id + '/gestionParameters/' + paramsToChange, parameters));
	}

	syncProjects(): Observable<void> {
		return this.get(this.route + '/sync');
	}

	findAll(): Observable<IProjectDto[]> {
		return this.get(this.route);
	}

	findAllPaging(paginationOption: PaginationOption): Observable<Pagination<IProjectDto>> {
		return this.post(this.route + '/paging/all', {paginationOption: paginationOption});
	}

	findByFiltersPaging(filter: GestionProjectFilter, paginationOption: PaginationOption): Observable<Pagination<IProjectDto>> {
		const companiesId: number[] = [];
		if (filter.companies) {
			for (const company of filter.companies) {
				companiesId.push(company.id);
			}
		}
		return this.post(this.route + '/paging/filtered', {
			filter: {
				clientId: filter.client ? filter.client.id : null,
				companiesId: companiesId,
				projectName: filter.projectName,
				projectStatus: filter.projectStatus,
				cpId: filter.cpId ? filter.cpId : null
			},
			paginationOption: paginationOption
		});
	}

	findByClient(id: number): Observable<IProjectDto[]> {
		return this.get(this.route + '/client/' + id);
	}

	findOne(id: number): Observable<IProjectDto> {
		return this.get(this.route + '/' + id);
	}

	findAllByPeriodPaging(startDate: Date, endDate: Date, filter: SuiviListFilter,
						  paginationOption: PaginationOption): Observable<Pagination<IProjectDto>> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'paging', 'period']));
		const companiesId: number[] = [];
		if (filter.companies) {
			for (const company of filter.companies) {
				companiesId.push(company.id);
			}
		}

		return this.post(url, {
			start: startDate,
			end: endDate,
			filter: {
				hideProjectWithNoBudget: filter.hideProjectWithNoBudget,
				withStatus: filter.withStatus,
				showInactiveProjects: filter.showInactiveProjects,
				filterProjectName: filter.filterProjectName,
				clientId: filter.client ? filter.client.id : null,
				companiesId,
			},
			paginationOption: paginationOption
		});
	}

	findByPeriodProject(startDate: moment.Moment, projectId: number): Observable<IProjectDto> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'period-project', projectId]));
		return this.get(url, {start: startDate.format('YYYY-MM-DD')});
	}

	findByPeriod(startDate: moment.Moment, endDate: moment.Moment): Observable<IProjectDto[]> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'by-period']));
		return this.get(url, {start: startDate.format('YYYY-MM-DD'), end: endDate.format('YYYY-MM-DD')});
	}

	getProjectWithActiveLotOnly(jiraKey: string, startDate: moment.Moment, endDate: moment.Moment): Promise<any> {
		return firstValueFrom(
			this.get(this.route + '/with-active-lots/' + jiraKey, {
				start: startDate.toISOString(),
				end: endDate.toISOString()
			})
		).then(value => {
			return value;
		});
	}

	getWithReportsByWeekPaginated(startDate: moment.Moment,
								  endDate: moment.Moment,
								  filterName: string,
								  filterCompanies: ICompanyDto[],
								  paginationOption: PaginationOption): Observable<Pagination<IProjectDto>> {
		const startDateString: string = startDate.format('YYYY-MM-DD');
		const endDateString: string = endDate.format('YYYY-MM-DD');
		const companiesId: number[] = [];
		if (filterCompanies?.length !== 0) {
			for (const company of filterCompanies) {
				companiesId.push(company.id);
			}
		}
		return this.post(this.route + '/reports', {startDateString, endDateString, paginationOption, filterName, companiesId});
	}

	getPreviousAndNextProject(projectId: number, startDate: moment.Moment, companyId: number): Observable<IProjectDto[]> {
		return this.get(this.route + '/previous-and-next/' + projectId, {date: startDate.format('YYYY-MM-DD'), companyId});
	}

	checkUpdateDates(projectId: number): Observable<boolean> {
		return this.get(this.route + '/checkUpdateDates/' + projectId);
	}

	getV1NextProject(startDate: moment.Moment, endDate: moment.Moment, companyId: number): Observable<any> {
		return this.get(this.route + '/v1/oldest-next-project', {
			startDate: startDate.format('YYYY-MM-DD'),
			endDate: endDate.format('YYYY-MM-DD'),
			companyId
		});
	}
}
