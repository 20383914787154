import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';

@Component({
	selector: 'app-searchbar',
	templateUrl: './searchbar.component.html',
	styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {

	@Input() value: string;
	@Input() clear: boolean = true;
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() clearInput: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild(MatInput) matInput: MatInput;
	timeout: any;
	@Input() timeoutLength: number = 500;

	@HostListener('window:keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
			event.preventDefault();
			this.matInput?.focus();
		}
	}

	filter(value: string): void {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.value = value;
			this.valueChange.emit(value);
		}, this.timeoutLength);
	}
}
