<form>
	<mat-form-field class="w-100">
		<mat-label>{{'PROJECT.CLIENTS' | translate}}</mat-label>
		<input [formControl]="selectClientController" [matAutocomplete]="auto" matInput>
		<mat-autocomplete [displayWith]="displaySelectedClient" #auto="matAutocomplete"
						  [hideSingleSelectionIndicator]="true">
			<mat-option [value]="client" *ngFor="let client of clientfiltered" [title]="client.name">
				<img [alt]="client.name" [src]="client.logo | safizer" *ngIf="client.logo" class="logo"/>
				<span>{{client.name}}</span>
			</mat-option>
		</mat-autocomplete>
		<button (click)="clearFilter()" *ngIf="client" matSuffix class="clear material-icons">
			clear
		</button>
	</mat-form-field>
</form>
