import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';
import {AuthenticatedGuard} from './security/guard/authenticated.guard';

const routes: Routes = [
	{path: '', redirectTo: '/gestion', pathMatch: 'full'},
	{
		path: '',
		component: MainLayoutComponent,
		canActivateChild: [AuthenticatedGuard],
		children: [
			{
				path: 'configuration',
				loadChildren: () => import('./configuration/configuration.module').then(module => module.ConfigurationModule)
			},
			{
				path: 'gestion',
				loadChildren: () => import('./gestion/gestion.module').then(module => module.GestionModule)
			},
			{
				path: 'suivi',
				loadChildren: () => import('./suivi/suivi.module').then(module => module.SuiviModule)
			},
			{
				path: 'export-tempo',
				loadChildren: () => import('./administration/export-tempo/export-tempo.module').then(module => module.ExportTempoModule)
			},
			{
				path: 'tempo-jira',
				loadChildren: () => import('./tempo-jira/tempo-jira.module').then(module => module.TempoJiraModule)
			},
			{
				path: 'user-base',
				loadChildren: () => import('./users/users.module').then(module => module.UsersModule)
			},
			{
				path: 'gestion-client',
				loadChildren: () => import('./client/client.module').then(module => module.ClientModule)
			},
			{
				path: 'company',
				loadChildren: () => import('./company/company.module').then(module => module.CompanyModule)
			},
			{
				path: 'bugs',
				loadChildren: () => import('./bug-management/bug-management.module').then(module => module.BugManagementModule)
			},
			{
				path: 'project-alert',
				loadChildren: () => import('./project-alert/project-alert.module').then(module => module.ProjectAlertModule)
			},
			{
				path: 'avant-vente',
				loadChildren: () => import('./avant-vente/avant-vente.module').then(module => module.AvantVenteModule)
			},
			{
				path: 'v1-report',
				loadChildren: () => import('./v1-report/v1-report.module').then(module => module.V1ReportModule)
			},
			{
				path: 'demande-bdc',
				loadChildren: () => import('./demande-bdc/demande-bdc.module').then(module => module.DemandeBdcModule)
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
