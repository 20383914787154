import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {ISubtaskDto, SubtaskDto} from './subtask.dto';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubtaskService extends ApiService {

	private route: string = '/api/subtask';

	constructor(public http: HttpClient) {
		super(http);
	}

	createSubtask(subtask: ISubtaskDto): Promise<ISubtaskDto> {
		return firstValueFrom(this.http.post<ISubtaskDto>(this.route, subtask));
	}

	modifySubtask(id: number, subtask: ISubtaskDto): Promise<ISubtaskDto> {
		return firstValueFrom(this.http.put<ISubtaskDto>(this.route + '/' + id, subtask));
	}

	removeSubtask(id: number): Promise<void> {
		return firstValueFrom(this.http.delete(this.route + '/' + id)).then();
	}

	reorderSubtaskInStory(subtaskId: number, subtaskBeforeId: number, storyId: number): Promise<void> {
		const subtask: ISubtaskDto = new SubtaskDto();
		subtask.id = subtaskId;
		subtask.order = subtaskBeforeId;
		subtask.storyId = storyId;
		return firstValueFrom(this.http.post(this.route + '/reorder', subtask)).then();
	}
}
