import {IStoryDto} from '../story/story.dto';

export interface ISubtaskDto {
	id: number;
	name: string;
	jiraExtId: string;
	jiraIntId: string;
	estimated: number;
	type: SubtaskTypeEnum;
	spent: number;
	reporter: string;
	deleted: boolean;
	order: number;
	updateDate: Date;
	storyId: number;
	story: IStoryDto;
}

export class SubtaskDto implements ISubtaskDto {
	id: number;
	name: string;
	jiraExtId: string;
	jiraIntId: string;
	estimated: number;
	type: SubtaskTypeEnum;
	spent: number;
	reporter: string;
	deleted: boolean;
	order: number;
	updateDate: Date;
	storyId: number;
	story: IStoryDto;
}

export enum SubtaskTypeEnum {
	REGULAR = 'REGULAR',
	INT = 'INT',
	SUBBUG = 'SUBBUG',
	SUBBUG_INT = 'SUBBUG_INT'
}
