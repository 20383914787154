import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
	title: string;
	message: string;
	showYesButton: boolean;
	noText: string;
	yesText: string;
	warn?: boolean;
}

@Component({
	selector: 'app-dialog-validation',
	templateUrl: './dialog-validation.component.html',
	styleUrls: ['./dialog-validation.component.scss']
})
export class DialogValidationComponent implements OnInit {
	warn: boolean = false;
	constructor(
		public dialogRef: MatDialogRef<DialogValidationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
		if (!data.showYesButton) {
			data.showYesButton = false;
		}
		if (!data.noText) {
			data.noText = 'Non';
		}
		if (!data.yesText) {
			data.yesText = 'Oui';
		}
		if (data.warn) {
			this.warn = true;
		}
	}

	ngOnInit(): void {
	}

}
