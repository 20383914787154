import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ILotDto, Lot} from './lot.dto';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LotService extends ApiService {

	private route: string = '/api/lot';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	createLot(lot: ILotDto): Promise<ILotDto> {
		return firstValueFrom(this.http.post<ILotDto>(this.route, lot));
	}

	modifyLot(id: number, lot: ILotDto): Promise<ILotDto> {
		return firstValueFrom(this.http.put<ILotDto>(this.route + '/' + id, lot));
	}

	removeLot(id: number): Promise<void> {
		return firstValueFrom(this.http.delete(this.route + '/' + id)).then();
	}

	checkIfOneLotNotDraftOnProject(projectId: number): Observable<boolean> {
		return this.get(this.route + '/checkIfOneLotNotDraftOnProject/' + projectId);
	}

	toggleExpandedState(id: number): void {
		this.get(this.route + '/toggleExpandedState/' + id).subscribe();
	}

	setExpandedState(id: number, state: boolean): void {
		this.get(this.route + '/setExpandedState/' + id + '?state=' + state).subscribe();
	}
	getLotByIdsList(ids: number[]): Promise<ILotDto[]> {
		return firstValueFrom(
			this.http.get<ILotDto[]>(this.route + '/getByIds', {
				params: {
					ids
				}
			})
		);
	}
	getByProjectId(id: number): Promise<ILotDto[]> {
		return firstValueFrom(
			this.http.get<ILotDto[]>(this.route + '/getByProjectId', {
				params: {
					id
				}
			})
		);
	}
	reorderLot(lotId: number, lotBeforeId: number, projetId: number): Promise<void> {
		const lotDto: ILotDto = new Lot();
		lotDto.id = lotId;
		lotDto.order = lotBeforeId;
		lotDto.projectId = projetId;
		return firstValueFrom(this.http.post(this.route + '/reorder', lotDto)).then();
	}
}
