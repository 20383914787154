import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Pagination} from './dto/pagination.dto';
import {catchError, finalize} from 'rxjs/operators';
import {SpinnerService} from '../service/spinner.service';


export abstract class AbstractPaginateDatasource<T> implements DataSource<T> {

	protected dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	protected dataTotalLength: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	protected spinnerService: SpinnerService;

	protected constructor(spinnerService: SpinnerService) {
		this.spinnerService = spinnerService;
	}

	connect(collectionViewer: CollectionViewer): Observable<T[]> {
		return this.dataSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.dataSubject.complete();
	}

	length(): number {
		return this.dataTotalLength.value;
	}

	datas(): T[] {
		return this.dataSubject.value;
	}

	abstract transformResults(pagination: Pagination<any>): T[];

	protected notifyPagingData(results: Observable<Pagination<any>>, filterFunction?: any): void {

		this.spinnerService.enableLoading();

		results.pipe(
			catchError(() => of([])),
			finalize(() => {
				this.spinnerService.disableLoading();
			})
		)
			.subscribe((paginationDatas: Pagination<T>) => {

				let dataSourceTable: T[];
				if (filterFunction) {
					dataSourceTable = this.transformResults(paginationDatas).filter(filterFunction);
				} else {
					dataSourceTable = this.transformResults(paginationDatas);
				}

				this.dataTotalLength.next(paginationDatas.total);
				this.dataSubject.next(dataSourceTable);
			});
	}
}

