import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import {UserBaseDto} from '../../core/business/service/user-base/user-base.dto';

@Injectable({
	providedIn: 'root',
})
export class AuthenticatedGuard extends KeycloakAuthGuard implements CanActivate, CanActivateChild {
	constructor(
		protected override readonly router: Router,
		protected readonly keycloak: KeycloakService,
		private readonly snackbarService: MatSnackBar,
		private userService: UserBaseService,
	) {
		super(router, keycloak);
	}

	async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		// Si l’utilisateur n’est pas authentifié, on interdit l’affichage
		if (!this.authenticated) {
			await this.keycloak.login({
				redirectUri: window.location.origin + state.url
			});
		}

		if ((route.data?.roles?.indexOf(this.userService.currentUser?.role) === -1
			&& this.userService.currentUser?.permissions?.every((detail) => !route.data?.roles?.includes(detail)))) {
			this.router.navigate(['/gestion']);
			return false;
		}

		return this._checkRoles(route.data['roles']);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return true;
	}

	private _checkRoles(requiredRolesOfPage: string[]): boolean {
		// Check si la page est limité à certains rôles.
		// Allow the user to proceed if no additional roles are required to access the route.
		if (!Array.isArray(requiredRolesOfPage) || requiredRolesOfPage.length === 0) {
			return true;
		}

		const user: UserBaseDto = this.userService.currentUser;
		if (!user) {
			this.snackbarService.open('L’utilisateur n’a pas été récupéré');
			this.router.navigate(['/gestion']);
			return false;
		}
		const hasPermission: boolean = requiredRolesOfPage.includes(user.role);

		const hasPermissionDetail: boolean = user.permissions?.every(detail => !requiredRolesOfPage.includes(detail));
		// Est-ce que l’utilisateur a les autorisations de voir cette page ?
		if (!hasPermission || ! hasPermissionDetail) {
			this.snackbarService.open('Vous ne pouvez pas accéder à cette page');
			this.router.navigate(['/gestion']);
		}
		return hasPermission && hasPermissionDetail;
	}
}
