import {Component, Inject, OnInit} from '@angular/core';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {IProjectDto} from '../../../core/business/service/project/project.dto';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {ThemeEnum} from '../../../theme/themes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-client-delete',
	templateUrl: 'dialog-client-delete.component.html',
	styleUrls: ['dialog-client-delete.component.scss']
})
export class DialogClientDeleteComponent implements OnInit {

	clientToDelete: IClientDto;
	projects: IProjectDto[];

	constructor(private dialogRef: MatDialogRef<DialogClientDeleteComponent>,
				private _titleService: Title,
				@Inject(MAT_DIALOG_DATA) data: { clientToDelete: IClientDto }) {
		this.clientToDelete = data.clientToDelete;
	}

	ngOnInit(): void {
		if (this.clientToDelete.id) {
			this._titleService.setTitle(
				ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Gestion client > ' + this.clientToDelete.name
			);
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onYesClick(): void {
		this.dialogRef.close(this.clientToDelete);
	}
}
