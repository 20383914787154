import {saveAs} from 'file-saver';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DownloadFileService {

	constructor(public http: HttpClient) {
	}

	downloadFileFromServer(path: string): Observable<Blob> {
		return this.http.get(path, {responseType: 'blob'});
	}

	localDataToFile(dataArray: any, filename: String, fileType: string): void {
		const blob: Blob = new Blob([dataArray], {type: fileType})
		saveAs.saveAs(blob, filename);
	}

	localDataToCsvFile(dataArray: any[], filename: String, columnSeparator?: string): void {
		const seprator: string = columnSeparator ? columnSeparator : ',';
		const replacer: (key: string, value: string) => string = (key, value) => value === null ? '' : value;
		const header: string[] = Object.keys(dataArray[0]);
		const csv: string[] = dataArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(seprator));
		csv.unshift(header.join(seprator));
		const csvArray: string = csv.join('\r\n');

		this.localDataToFile(csvArray, filename, 'text/csv');
	}
}
