import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {PermissionEnum, UserBaseDto} from '../../core/business/service/user-base/user-base.dto';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import {inject} from '@angular/core';

export abstract class UserInfo {
	userService: UserBaseService = inject(UserBaseService);
	/**
	 * user id ( from db ) of the connected user
	 */
	currentUserId: string;
	/**
	 * Permissions
	 */
	role: PermissionEnum;
	/**
	 * Permission Details
	 */
	permissions: string[];
	/**
	 * keycloak username of the connected user
	 */
	email: string;
	/**
	 * keycloak roles of the connected user
	 */
	roles: string[];
	/**
	 * keycloak user of the connected user
	 */
	currentKeycloakUser: CustomKeycloakProfile;
	/**
	 * keycloak id of the connected user
	 */
	currentUserKeycloakId: string;

	protected constructor(protected keycloak: KeycloakService) {
		this.email = keycloak.getUsername();
		this.roles = keycloak.getUserRoles();
		this.currentKeycloakUser = keycloak.getKeycloakInstance().profile as CustomKeycloakProfile;
		this.currentUserKeycloakId = keycloak.getKeycloakInstance().subject;

		if (this.currentKeycloakUser.attributes['mdm-id']) {
			this.currentUserId = this.currentKeycloakUser.attributes['mdm-id'][0];
		}
		if (this.userService.currentUser) {
			this.currentUser = this.userService.currentUser;
		}
	}

	/**
	 * user object of the connected user
	 */
	private _currentUser: UserBaseDto;

	get currentUser(): UserBaseDto {
		return this._currentUser;
	}

	set currentUser(value: UserBaseDto) {
		this._currentUser = value;
		this.role = this._currentUser.role;
		this.permissions = this._currentUser.permissions;
	}
}

export interface CustomKeycloakProfile extends KeycloakProfile {
	attributes: AttributesKeycloak;
}

export interface AttributesKeycloak {
	'mdm-id': string[];
}
