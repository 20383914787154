import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {EpicDto, IEpicDto} from './epic.dto';
import {IProjectDto} from '../project/project.dto';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EpicService extends ApiService {

	private route: string = '/api/epic';

	constructor(public http: HttpClient) {
		super(http);
	}

	createEpic(epic: IEpicDto): Promise<IEpicDto> {
		return firstValueFrom(this.http.post<IEpicDto>(this.route, epic));
	}

	modifyEpic(id: number, epic: IEpicDto): Promise<IEpicDto> {
		return firstValueFrom(this.http.put<IEpicDto>(this.route + '/' + id, epic));
	}

	removeEpic(id: number): Promise<void> {
		return firstValueFrom(this.http.delete(this.route + '/' + id)).then();
	}

	reorderEpic(epicId: number, epicBeforeId: number, projetId: number): Promise<void> {
		const epicDto: IEpicDto = new EpicDto();
		epicDto.id = epicId;
		epicDto.order = epicBeforeId;
		epicDto.projectId = projetId;
		return firstValueFrom(this.http.post(this.route + '/reorder', epicDto)).then();
	}

	toggleExpandedState(id: number, lotId: number): void {
		this.get(`${this.route}/toggleExpandedState/${id}/${lotId}`).subscribe();
	}

	setExpandedState(id: number, lotId: number, state: boolean): void {
		this.get(`${this.route}/setExpandedState/${id}/${lotId}?state=${state}`).subscribe();
	}
}
