import {V1ReportService} from '../../core/business/service/v1-report/v1-report.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
	IAxisReportDto,
	IMatchingAxis,
	IReportDto,
} from '../../core/business/service/v1-report/v1-report.dto';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Observable} from 'rxjs';
import * as moment from 'moment';
import {ProjectService} from '../../core/business/service/project/project.service';
import {IProjectDto} from '../../core/business/service/project/project.dto';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {SpinnerService} from '../../core/service/spinner.service';
import { DateAdapter } from '@angular/material/core';
import {AxisReportService} from '../../core/business/service/v1-report/axis-report/axis-report.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';


@Component({
	selector: 'app-report-list',
	templateUrl: './report-detail.component.html',
	styleUrls: ['./report-detail.component.scss']
})
export class ReportDetailComponent extends UserInfo implements OnInit {

	projectId: number;
	project: IProjectDto;
	report: IReportDto;
	previousReport: IReportDto;
	matchingAxis: IMatchingAxis[];

	cpy: IReportDto;
	comparingReports: boolean = false;
	fromCheck: boolean = false;

	project$: Observable<IProjectDto>;
	previousProject: IProjectDto;
	nextProject: IProjectDto;

	run: boolean = false;
	v1: boolean = false;
	timer: number = 0;
	totalTime: number = 0;
	limitTime: number = 0;
	interval: any;

	startDate: moment.Moment;
	maxDate: moment.Moment;

	constructor(public override keycloak: KeycloakService,
				private _v1reportService: V1ReportService,
				private _projectService: ProjectService,
				private _axisReportService: AxisReportService,
				private _router: Router,
				private _route: ActivatedRoute,
				private _translator: TranslateService,
				private _spinnerService: SpinnerService,
				private _titleService: Title,
				private _dateAdapter: DateAdapter<Date>,
				private _snackBar: MatSnackBar) {
		super(keycloak);
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (this.v1) {
			if (event.key.toUpperCase() === 'S' && event.ctrlKey) {
				event.preventDefault();
				this.closeReport(true);
			}
		}
	}
	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Rapports V1');
		this._dateAdapter.setLocale('fr');
		this._dateAdapter.getFirstDayOfWeek = () => 1;
		const selectedDate: string = this._route.snapshot.queryParams['selectedDate'];
		this.v1 = this._route.snapshot.queryParams['v1'];
		this.maxDate = moment().isoWeekday(1).startOf('day');
		if (selectedDate && this.maxDate >= moment(selectedDate)) {
			this.startDate = moment(selectedDate).isoWeekday(1);
		} else {
			if (moment().isoWeekday() === 1) {
				const date: string = moment().add(-1, 'week').isoWeekday(1).toISOString().split('T')[0];
				this.startDate = moment(date).isoWeekday(1);
			} else {
				const date: string = moment().isoWeekday(1).toISOString().split('T')[0];
				this.startDate = moment(date).isoWeekday(1);
			}
		}
		this.refreshURL();

		this.projectId = +this._route.snapshot.paramMap.get('id');
		this._route.params.subscribe(params => {
			this._spinnerService.enableLoading();
			this.projectId = +params['id'];
			this.loadProject();
			this.project$.subscribe((project) => {
				this._projectService.getPreviousAndNextProject(
					this.projectId,
					this.startDate,
					project.companyId,
				).subscribe((result) => {
					this.previousProject = result[0];
					this.nextProject = result[1];
				});
			});
		});
	}

	loadProject(): void {
		this.project$ = this._projectService.findByPeriodProject(this.startDate, this.projectId);
		this.project$.subscribe((result) => {
			if (!result) {
				this._router.navigate(['/v1-report']);
				return;
			}
			this.project = result;
			this.project.periodSpentSum = this.getSpentSum();
			this.project.periodProdSum = this.getProductionSum();
			this.loadReport();
			this.fromCheck = false;
			this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Rapports V1 > ' + this.project.name);
		});
		this.v1 = this._route.snapshot.queryParams['v1'];
	}

	loadReport(): void {
		this._v1reportService.getReportByProjectAndWeek(this.startDate, this.projectId).subscribe(async (result) => {
			this.previousReport = result[1];
			if (result?.length && result[0]) {
				this.report = result[0];
			} else {
				await this.createReport();
			}

			this.matchingAxis = [];
			if (this.report) {
				this.report.axisReports.sort((a, b) => a.axis.id - b.axis.id);
				this.createMatchingAxis(this.report.axisReports);
				this.cpy = _.cloneDeep(this.report);
			}

			if (this.previousReport) {
				if (this.report) {
					this.previousReport.axisReports.forEach((axisReport) => {
						this.matchingAxis.find((axis) => axis.id === axisReport.axis.id).previousAxisReport = axisReport;
					});
				}
			}
			this._spinnerService.disableLoading();
			if (this.v1 && !this.report.isClosed) {
				this.initTimer();
				this.startTimer();
			}
		});
	}

	async createReport(): Promise<void> {
		this.report = await firstValueFrom(this._v1reportService.create(this.project.id, this.startDate.toDate()));
		this._snackBar.open(this._translator.instant('V1_REPORT.CREATED'), '', {
			panelClass: 'success'
		});
		this.report.axisReports.sort((a, b) => a.axis.id - b.axis.id);
		this.report.deadlineTMA = undefined;
		this.report.deadlineContract = undefined;
		this.report.deadlineHosting = undefined;
	}

	askToSave(): boolean {
		this.cpy.isPublished = this.report.isPublished;
		this.cpy.isClosed = this.report.isClosed;
		if (!_.isEqual(this.report, this.cpy) ) {
			const wantToSave: boolean = confirm(this._translator.instant('V1_REPORT.WANT_TO_SAVE'));
			if (wantToSave) {
				this.updateReport();
			}
		}
		return true;
	}
	createMatchingAxis(axisReports: IAxisReportDto[]): void {
		axisReports.forEach((axisReport) => {
			this.matchingAxis.push({
				id: axisReport.axis.id,
				name: axisReport.axis.name,
				description: axisReport.axis.description,
				axisReport: axisReport
			} as IMatchingAxis);
		});
	}

	dateChange(e: MatDatepickerInputEvent<Date>): void {
		this.askToSave();
		this.startDate = moment(e.value).isoWeekday(1);
		this.refreshURL();
		this.loadProject();
	}

	moveToWeek(amount: number): void {
		this.askToSave();
		if (moment(this.startDate).add(amount, 'week') <= this.maxDate) {
			this.startDate.add(amount, 'week');
			this.refreshURL();
			this.loadProject();
		}
	}

	refreshURL(): void {
		const dateString: string = moment(this.startDate).startOf('week').add(2, 'day').toISOString().split('T')[0];
		const str: string = this.v1 ? '&v1=true' : '';
		history.pushState({}, '', window.location.origin + window.location.pathname + '?selectedDate=' + dateString + str);
	}

	private wrongFormat(str: string): boolean {
		return (str === null || !str || str === '' || str.length === 0 || str === ' ' && str.length === 1);
	}

	checkForPublish(): boolean {
		if (this.wrongFormat(this.report.summary) || this.report.criticality === null) {
			return false;
		}
		for (let i: number = 0; i < this.report.axisReports.length ; i++) {
			if (this.wrongFormat(this.report.axisReports[i].description) || this.matchingAxis[i].axisReport.criticality === 0) {
				return false;
			}
		}
		return true;
	}
	publishReport(): void {
		const confirmPublish: boolean = confirm(this._translator.instant('V1_REPORT.CONFIRM_PUBLISH'));
		if (confirmPublish && this.checkForPublish()) {
			this.report.isPublished = true;
			this.cpy = _.cloneDeep(this.report);
			this._v1reportService.update(this.report).subscribe(() => {
				this._snackBar.open(this._translator.instant('V1_REPORT.PUBLISHED'), '', {
					panelClass: 'success'
				});
			});
		} else if (confirmPublish) {
			this._snackBar.open(this._translator.instant('V1_REPORT.ERR_PUBLISH'), '', {
				panelClass: 'error'
			});
			this.fromCheck = true;
		}
	}

	closeReport(skipConfirm: boolean = false): void {
		const confirmClose: boolean = skipConfirm ? true : confirm(this._translator.instant('V1_REPORT.CONFIRM_CLOSE'));
		if (confirmClose) {
			this.report.isClosed = true;
			this.report.isPublished = true;
			this.pauseTimer();
			if (this.v1) {
				this.report.timer += this.totalTime;
			}
			this.cpy = _.cloneDeep(this.report);
			this._v1reportService.update(this.report).subscribe(() => {
				this._snackBar.open(this._translator.instant('V1_REPORT.CLOSED'), '', {
					panelClass: 'success'
				});
			});
			if (this.v1) {
				const path: string = 'v1-report' + (this.nextProject ? '/' + this.nextProject.id : '');
				this._router.navigate([path], {queryParams: {selectedDate: this.startDate.format('YYYY-MM-DD'), v1: this.v1}}).catch(() => null);
			}
		}
	}

	openReport(): void {
		const confirmClose: boolean = confirm(this._translator.instant('V1_REPORT.CONFIRM_REOPEN'));
		if (confirmClose) {
			this.report.isClosed = false;
			this._v1reportService.update(this.report).subscribe(() => {
				this._snackBar.open(this._translator.instant('V1_REPORT.REOPENED'), '', {
					panelClass: 'success'
				});
				if (this.v1) {
					this.initTimer();
					this.startTimer();
				}
			});
		}
	}

	updateReport(): void {
		this._v1reportService.update(this.report).subscribe(() => {
			this.cpy = _.cloneDeep(this.report);
			this._snackBar.open(this._translator.instant('V1_REPORT.UPDATED'), '', {
				panelClass: 'success'
			});
		});
	}

	focus(e: FocusEvent): void {
		if (e.composedPath().length > 1) {
			if (e.type === 'focus') {
				(e.composedPath()[1] as HTMLDivElement).classList.add('textarea-focus');
			} else if (e.type === 'blur') {
				(e.composedPath()[1] as HTMLDivElement).classList.remove('textarea-focus');
			}
		}
	}

	changeCriticality(reportItem: IAxisReportDto | IReportDto): void {
		if (this.report.isClosed) {
			return;
		}
		if (!reportItem.criticality) {
			reportItem.criticality = 1;
		} else if (reportItem.criticality === 3) {
			reportItem.criticality = 1;
		} else {
			reportItem.criticality++;
		}
	}

	changeTrend(axisReport: IAxisReportDto = null): void {
		if (this.report.isClosed) {
			return;
		}
		if (axisReport) {
			if (!axisReport.trend) {
				axisReport.trend = 3;
			} else if (axisReport.trend === 3) {
				axisReport.trend = 1;
			} else {
				axisReport.trend++;
			}
		} else {
			if (!this.report.trend) {
				this.report.trend = 3;
			} else if (this.report.trend === 3) {
				this.report.trend = 1;
			} else {
				this.report.trend++;
			}
		}
	}

	getSpentSum(): number {
		let spentSum: number = 0;
		if (this.project?.period_report) {
			for(const periodReport of this.project.period_report){
				spentSum += periodReport.storyReports.reduce((acc, sr) => sr.spentSum + acc, 0);
			}
			return spentSum;
		} else {
			return 0;
		}
	}

	getProductionSum(): number {
		if (this.project?.period_report) {
			return this.project.period_report.reduce((acc, pr) => pr.productionSum + acc, 0);
		} else {
			return 0;
		}
	}

	getMonth(offset: number): string {
		return moment(this.startDate).add(offset, 'month').format('MMMM');
	}

	getMonthPreviousReport(offset: number): string {
		return moment(this.previousReport.beginDate).add(offset, 'month').format('MMMM');
	}

	formatTimer(nb: number): string {
		const sec: number = nb % 60;
		const min: number = (nb - sec) / 60;
		return (min < 10 ? 0 : '') + min.toString() + ':' + (sec < 10 ? 0 : '') + sec.toString();
	}

	timerColor(t: boolean): string {
		let color: string;
		if (this.limitTime > this.timer * 0.2 ) {
			color = 'green';
		} else if (this.limitTime > this.timer * 0.10 ) {
			color = 'orange';
		} else {
			color = t && this.run ? 'red blink' : 'red';
		}
		return color;
	}

	initTimer(): void {
		this.limitTime = 60 * this.project.time;
		this.timer = this.limitTime;
		this.totalTime = 0;
	}

	startTimer(): void {
		if (!this.run) {
			this.interval = setInterval(() => {
				if (this.limitTime > 0) {
					this.limitTime--;
				}
				this.totalTime++;
			}, 1000);
			this.run = true;
		}
	}

	standBy(): void {
		if (this.run) {
			this.pauseTimer();
		} else {
			this.startTimer();
		}
	}

	pauseTimer(): void {
		clearInterval(this.interval);
		this.run = false;
	}

	goToPage(url: string): void {
		this.askToSave();
		this._router.navigate([url]).catch(() => null);
	}

	getDateStyle(date: Date | moment.Moment): string {
		if (!date) {
			return '';
		}
		const monthDifference: number =  moment(new Date(moment(date).toDate())).diff(new Date(), 'months', true);
		if (monthDifference > 3) {
			return 'green';
		} else if (monthDifference > 1) {
			return 'orange';
		} else if (monthDifference > 0) {
			return 'red';
		} else {
			return 'warning';
		}
	}

	regen(event: any): Date {
		const str: string = event.target.value;
		const day: number = Number(str.split('/')[0]);
		const month: number = Number(str.split('/')[1]);
		const year: number = Number(str.split('/')[2]);
		return this._dateAdapter.createDate(year, month - 1, day);
	}

	compareReport(reportField: string, previousReportField: string): boolean {
		if (reportField !== null && reportField !== undefined && previousReportField !== null && previousReportField !== undefined) {
			return reportField.toLowerCase().trim() !== previousReportField.toLowerCase().trim();
		} else if (reportField !== null && reportField !== undefined && reportField.length > 0) {
			return true;
		} else if (previousReportField !== null && previousReportField !== undefined && previousReportField.length > 0) {
			return true;
		}
		return false;
	}
}
