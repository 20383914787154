import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {JiraTransition, JiraTransitionUpdateCall} from '../../../../gestion/projet-detail/dto/jira-transition.object';

export interface IssueToRedistribute {
	name: string;
	issueId: number;
	jiraId: string;
	lotId: number;
	oldBudget: number;
	newBudget: number;
	restored: boolean;
}

export interface ILotRedistribution {
	lotId: number;
	deltaBudget: number;
	isForceModifBudget: boolean;
	destStoryId: number;
	sourceStories: IssueToRedistribute[];
}

@Injectable({
	providedIn: 'root'
})
export class SynchronizationService extends ApiService {

	private route: string = '/api/synchronization';

	constructor(public http: HttpClient) {
		super(http);
	}

	synchroniseProject(projectJiraIntId: string): Promise<IssueToRedistribute[]> {
		return firstValueFrom(this.http.get<IssueToRedistribute[]>(this.route + '/synchronize/' + projectJiraIntId));
	}

	synchronizeLotState(lotId: number, state: string): Promise<boolean> {
		return firstValueFrom(this.http.post(this.route + '/synchronize-lot-state/' + lotId, { state }))
			.then(() => true).catch(() => false);
	}

	redistributeAfterSynchro(projectJiraIntId: string, items: ILotRedistribution[]): Promise<boolean> {
		return firstValueFrom(this.http.post(this.route + '/redistribute/' + projectJiraIntId, items)).then(() => true);
	}

	getJiraTransition(jiraId: string): Observable<JiraTransition[]> {
		return this.get(`${this.route}/jira-transition/${jiraId}`);
	}

	setJiraTransition(jiraId: string, storyId: number, transition: JiraTransition): Observable<JiraTransitionUpdateCall> {
		return this.post(`${this.route}/jira-transition/${jiraId}`, {id: storyId, transition});
	}

	pushStoryChangeToJira(jiraId: string): Promise<void> {
		return firstValueFrom(this.post(`${this.route}/push-story-jira/${jiraId}`));
	}
}
