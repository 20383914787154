<div class="container p-0">
	<!-- Header -->
	<div>
		<h1 class="text-center" mat-dialog-title>
			<span *ngIf="warn" class="sp-iconmonstr-triangle-1 warn-icon"></span>
			{{popupTitle}}
			<span *ngIf="warn" class="sp-iconmonstr-triangle-1 warn-icon"></span>
		</h1>
		<button *ngIf="closeButton"  [mat-dialog-close]="false" class="close-button material-icons" type="button">close</button>
	</div>
	<div class="colored-bar"></div>
	<!-- Body et Footer -->
	<form>
		<div mat-dialog-content class="p-0">
			<ng-content select="[dialog-popup-content]"></ng-content>
		</div>

		<div *ngIf="footer" mat-dialog-actions class="justify-content-center">
			<ng-content select="[dialog-popup-footer]"></ng-content>
		</div>
	</form>
</div>
