import { Pipe, PipeTransform } from '@angular/core';
import {LotDataSourceContent} from '../datasource/projet-detail.datasource';
import {IssueTypeEnum} from '../dto/projet-details.objects';

@Pipe({
  name: 'avancementProjet'
})
export class AvancementProjetPipe implements PipeTransform {

  transform(budget: number, remainingExt: number): number {
	  if (!budget) {
		  return 0;
	  }
	  return Math.round((budget - remainingExt) * 100 / budget);
  }

}
