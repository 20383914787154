import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberInputModule} from './components/number-input/number-input.module';
import {DialogValidationModule} from './components/dialog-validation/dialog-validation.module';
import {SearchbarComponent} from './components/searchbar/searchbar.component';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {SafizerPipe} from './pipe/safizer/safizer.pipe';
import { IncludesPipe } from './pipe/includes/includes.pipe';
import { FilterPipe } from './pipe/filter/filter.pipe';
import { NgVarDirective } from './directives/ngVar/ng-var.directive';
import { StartsWithPipe } from './pipe/starts-with/starts-with.pipe';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	imports: [
		CommonModule,
		NumberInputModule,
		DialogValidationModule,
		FormsModule,
		MatIconModule,
		TranslateModule,
		MatInputModule,
	],
	exports: [
		SearchbarComponent,
		SafizerPipe,
		IncludesPipe,
		FilterPipe,
		NgVarDirective,
		StartsWithPipe,
	],
	declarations: [SearchbarComponent, SafizerPipe, IncludesPipe, FilterPipe, NgVarDirective, StartsWithPipe],
})
export class UtilModule {
}
