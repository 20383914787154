import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DialogPopupComponent} from './dialog-popup.component';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
	declarations: [DialogPopupComponent],
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatDialogModule,
	],
	exports: [DialogPopupComponent]
})
export class DialogPopupModule {
}
