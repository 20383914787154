<app-header [displayBreadCrumb]="'AVANT_VENTE.HEADER' | translate" class="position-relative">
	<div class="ms-5">
		<app-searchbar [(value)]="avantVenteFilter.titre" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<mat-form-field>
		<mat-label>{{'PROJECT.STATUS.TITLE' | translate}}</mat-label>
		<mat-select [(ngModel)]="avantVenteFilter.etat" #filterStatus multiple>
			<mat-option [value]="avantVenteEtat" *ngFor="let avantVenteEtat of avantVenteEtatEnumKeys">
				{{'AVANT_VENTE.ETAT.' + avantVenteEtat | translate | uppercase }}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field>
		<mat-label>{{'PROJECT.SORT.TITLE' | translate}}</mat-label>
		<mat-select [(ngModel)]="paginationOption.sortBy" #sortBy [hideSingleSelectionIndicator]="true">
			<mat-option [title]="'PROJECT.SORT.CREATION_ASC' | translate"
						[value]="sortByOption['creationDateAsc']">{{'PROJECT.SORT.CREATION_ASC' | translate}}</mat-option>
			<mat-option [title]="'PROJECT.SORT.CREATION_DESC' | translate"
						[value]="sortByOption['creationDateDesc']">{{'PROJECT.SORT.CREATION_DESC' | translate}}</mat-option>
			<mat-option [title]="'PROJECT.SORT.ALPHA' | translate"
						[value]="sortByOption['alphabetiqueOrderAsc']">{{'PROJECT.SORT.ALPHA' | translate}}</mat-option>
			<mat-option [title]="'PROJECT.SORT.BUDGET' | translate"
						[value]="sortByOption['budgetDesc']">{{'PROJECT.SORT.BUDGET' | translate}}</mat-option>
		</mat-select>
	</mat-form-field>

	<app-client-select [(client)]="avantVenteFilter.client" [clients]="clients | async"
					   #filterClient></app-client-select>

	<div class="actions">
		<button (click)="openDialogCreateAvantVente()" class="round-button" color="primary" mat-mini-fab>
			<mat-icon>add</mat-icon>
		</button>
	</div>
</app-header>
<div class="mat-elevation-z8 table-container">

	<mat-table [dataSource]="dataSourcePaginate" (matSortChange)="sortData($event)" matSort
			   matSortActive="titre" matSortDirection="asc">

		<!-- Informations Column -->
		<ng-container matColumnDef="informations">
			<mat-header-cell *matHeaderCellDef class="project-header" mat-sort-header>Projet en avant-vente
			</mat-header-cell>
			<mat-cell *matCellDef="let avantVente">
				<div class="d-flex align-items-center justify-content-between w-100">
					<div>
						<img [alt]="'APP.LOGO' | translate" [src]="avantVente.client.logo | safizer"
							 *ngIf="avantVente.client?.logo" class="logo"/>
						<span class="my-auto">{{avantVente.titre}}</span>
					</div>

					<div class="d-flex justify-content-end align-items-center">
						<div class="tag-etat">
							<div class="color-grey-light-3">{{ 'AVANT_VENTE.ETAT.STATUS' | translate }}</div>
							<div>{{'AVANT_VENTE.ETAT.' + avantVente.etat | translate}}</div>
						</div>

						<div [routerLink]="['/avant-vente/', avantVente.id]" class="d-flex arrow-background">
							<div class="sp-right-arrow white-arrow align-self-center"></div>
						</div>
					</div>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="dateCreation">
			<mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header>
				<span [innerHTML]="'AVANT_VENTE.LIST.HEADER.DATE_CREATION' | translate"></span>
			</mat-header-cell>
			<mat-cell *matCellDef="let avantVente">{{avantVente.dateCreation | date:'dd/MM/yyyy'}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="nbOffre">
			<mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header>
				<span [innerHTML]="'AVANT_VENTE.LIST.HEADER.NB_OFFRE' | translate"></span>
			</mat-header-cell>
			<mat-cell *matCellDef="let avantVente">{{avantVente.nbOffre | number:'1.0-0'}}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="budgetNbJ">
			<mat-header-cell *matHeaderCellDef class="column-header" mat-sort-header>
				<span [innerHTML]="'AVANT_VENTE.LIST.HEADER.BUDGET_JOUR' | translate"></span>
			</mat-header-cell>
			<mat-cell *matCellDef="let avantVente">{{avantVente.chargeTotal | number:'1.0-0'}} J
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="budgetEuro">
			<mat-header-cell [innerHTML]="'AVANT_VENTE.LIST.HEADER.BUDGET_PRIX' | translate" *matHeaderCellDef class="column-header"
							 mat-sort-header>
			</mat-header-cell>
			<mat-cell *matCellDef="let avantVente">{{avantVente.prixTotal | number:'1.0-0'}} €</mat-cell>
		</ng-container>

		<ng-container matColumnDef="empty">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let avantVente">
				<button (click)="deleteAvantVente(avantVente)" class="sp-delete delete-button-style"></button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row [ngClass]="row.type" *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>

	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>
	<app-ogdp-paginator [length]="dataSourcePaginate.length()" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
</div>
