<app-dialog-popup [popupTitle]="('PROJECT.' + (project.id ? 'UPDATE' : 'NEW')) | translate">
	<div dialog-popup-content>
		<!--	Div contenant la première partie du pop-up-->
		<div class="d-flex w-100 project-main-info-wrapper">
			<!--Div contenant le nom du projet, le client et la description du projet-->
			<div class="width-90 text-center">
				<img [alt]="project.client.name" [src]="project.client.logo | safizer" *ngIf="project?.client?.logo"
					 class="project">
			</div>

			<!--Div contenant Le com du projet, le client et la description du projet-->
			<div class="px-4 w-100">
				<div class="row">
					<mat-form-field class="col-6">
						<mat-label>{{'PROJECT.PROJECT_NAME' | translate}}</mat-label>
						<input [(ngModel)]="project.name" matInput required disabled>
					</mat-form-field>
					<mat-form-field class="col-6">
						<mat-label>{{'PROJECT.CLIENT' | translate}}</mat-label>
						<input [(ngModel)]="project.client.name" matInput disabled>
					</mat-form-field>
				</div>
				<div class="row">
					<mat-form-field class="col-12">
						<mat-label>{{'APP.DESCRIPTION' | translate}}</mat-label>
						<textarea [(ngModel)]="project.description" matInput></textarea>
					</mat-form-field>
				</div>
			</div>
		</div>

		<!--Seconde partie du pop-up-->
		<div class="project-info-wrapper">
			<div class="row justify-content-between">
				<mat-form-field class="col-4">
					<mat-label>{{'PROJECT.JIRA_ID_INT' | translate}}</mat-label>
					<input [(ngModel)]="project.jiraIntId" matInput>
				</mat-form-field>
				<mat-form-field class="col-4">
					<mat-label>{{'PROJECT.JIRA_ID_EXT' | translate}}</mat-label>
					<input [(ngModel)]="project.jiraExtId" matInput>
				</mat-form-field>
				<mat-form-field class="col-4">
					<mat-label>{{'PROJECT.BOARD_ID' | translate}}</mat-label>
					<input [(ngModel)]="project.boardId" matInput>
				</mat-form-field>
			</div>
			<div *ngIf="!project.jiraExtId" class="row">
				<span class="color-grey fst-italic">{{'PROJECT.JIRA_INT_ONLY' | translate}}</span>
			</div>
			<div class="row">
				<mat-form-field class="col-12">
					<mat-label>{{'PROJECT.BITBUCKET_REPO' | translate}}</mat-label>
					<mat-chip-grid #chipGrid class="horizontal-scroll-chips">
						<mat-chip-row [removable]="true" (removed)="removeRepository(repo)"
									  *ngFor="let repo of project.bitbucketRepositories">
							{{repo}}
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip-row>
						<input [matChipInputAddOnBlur]="true"
							   [matChipInputFor]="chipGrid"
							   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							   (matChipInputTokenEnd)="addRepository($event)"/>
					</mat-chip-grid>
				</mat-form-field>
			</div>
			<div class="row">
				<mat-form-field class="col-12">
					<mat-label>{{'PROJECT.CP' | translate}}</mat-label>
					<mat-select [(ngModel)]="project.usersCp" [compareWith]="userEquals"
								(openedChange)="myInput.focus(); myInput.value = ''; filteredUsers = users" multiple>
						<input [placeholder]="'APP.SEARCH' | translate" (keydown)="$event.stopPropagation()"
							   (keyup)="filterUsers($any($event.target)?.value)" #myInput
							   autocomplete="off" class="p-2" matInput type="text">
						<mat-divider></mat-divider>
						<mat-option [class.d-none]="filteredUsers.indexOf(user) === -1" [value]="user"
									*ngFor="let user of users">
							<div *ngIf="user.avatar; then avatar else noAvatar"></div>
							<ng-template #avatar>
								<img [alt]="user.firstName" [src]="user.avatar | safizer" class="avatar">
							</ng-template>
							<ng-template #noAvatar>
								<span class="avatar"></span>
							</ng-template>
							<span>{{user.firstName}} {{user.lastName}}</span>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="row">
				<mat-form-field class="col-5">
					<mat-label>{{'PROJECT.OPEN' | translate}}</mat-label>
					<mat-select [(ngModel)]="project.status" [disabled]="true"
								[hideSingleSelectionIndicator]="true" #projectStatus>
						<mat-option [value]="projectStatus" *ngFor="let projectStatus of projectStatusEnumKeys">
							{{'PROJECT.STATUS.' + projectStatus | translate}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field class="col-7">
					<mat-label>{{'PROJECT.FACTURATION_TYPE' | translate}}</mat-label>
					<mat-select [disabled]="true" [hideSingleSelectionIndicator]="true">
						<mat-option [value]="facturationOption" *ngFor="let facturationOption of facturationOptions">
							{{facturationOption}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="row">
				<mat-checkbox [(ngModel)]="project.followUpReminder" class="table-checkbox" color="primary">
					{{ 'PROJECT.ACTIVATE_FOLLOW_UP_REMINDER' | translate}}
				</mat-checkbox>
			</div>
			<div class="d-flex align-items-center">
				<mat-checkbox [(ngModel)]="project.v1Report" class="table-checkbox" color="primary">
					{{'PROJECT.V1_REPORT' | translate}}
				</mat-checkbox>

				<mat-form-field class="px-3 col-5">
					<mat-label>{{'PROJECT.V1_TIMING' | translate}}</mat-label>
					<mat-select [(ngModel)]="project.time" [disabled]="!project.v1Report"
								[hideSingleSelectionIndicator]="true">
						<mat-option [value]="i" (click)="project.time = i" *ngFor="let i of [1,2,3,4,5]">
							{{i}} {{(i > 1 ? 'APP.MINUTE_PLUR' : 'APP.MINUTE') | translate}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>

	<div class="centered-actions" dialog-popup-footer>
		<button (click)="onYesClick()" class="its-button" mat-flat-button>
			{{'APP.' + (data.project?.id ? 'SAVE' : 'CREATE') | translate}}
		</button>
	</div>
</app-dialog-popup>

