import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {fromEvent, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {ThemeEnum} from '../../../theme/themes';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-client-edit',
	templateUrl: './dialog-client-edit.component.html',
	styleUrls: ['./dialog-client-edit.component.scss']
})
export class DialogClientEditComponent implements AfterViewInit, OnInit {

	client: IClientDto;
	clientForm: FormGroup;

	imageChangedEvent: any;
	base64CroppedImg: any;

	@ViewChild('selectFile') selectFile: ElementRef;
	@ViewChild('imgCropper') imgCropper: ImageCropperComponent;

	constructor(private fb: FormBuilder, private snackBar: MatSnackBar,
				public dialogRef: MatDialogRef<DialogClientEditComponent>,
				private _titleService: Title,
				@Inject(MAT_DIALOG_DATA) data: { client: IClientDto }) {
		this.client = data.client;
		this.clientForm = this.fb.group({
			name: new FormControl('', [
				Validators.required,
				Validators.min(1),
			])
		});
	}

	ngOnInit(): void {
		if (this.client.id) {
			this.clientForm.setValue({
				'name': this.client.name
			});

			this._titleService.setTitle(
				ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Gestion client > ' + this.client.name
			);

			this.base64CroppedImg = this.client.logo;
		}
	}

	ngAfterViewInit(): void {
		fromEvent(this.selectFile.nativeElement, 'change')
			.pipe(map((event) => {
					this.imageChangedEvent = event;
				})
			)
			.subscribe();

		merge(this.imgCropper.loadImageFailed).pipe(map(() => {
			this.snackBar.open('Impossible de charger le fichier.', '', {
				panelClass: 'error'
			});
		})).subscribe();

		merge(this.imgCropper.imageCropped).pipe(map((event: ImageCroppedEvent) => {
			this.base64CroppedImg = event.base64;
		})).subscribe();
	}

	saveClient(): void {
		if (this.clientForm.invalid) {
			return;
		} else {
			this.client.name = this.clientForm.value.name;
			this.client.logo = this.base64CroppedImg;
			this.dialogRef.close(this.client);
		}
	}

	isCreation(): boolean {
		return !this.client.id;
	}
}
