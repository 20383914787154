import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {IProjectTimeFinalDto} from './export-tempo.dto';
import {IUserBaseDto} from "../user-base/user-base.dto";

@Injectable({
	providedIn: 'root'
})
export class ExportTempoService extends ApiService {

	route: string = '/api/export-tempo/';

	constructor(public http: HttpClient) {
		super(http);
	}

	getSpentTimeByProjectAndUser(annee: number): Observable<IProjectTimeFinalDto[]> {
		return this.get(this.route + 'annee/' + annee);
	}
}
