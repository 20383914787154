import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export class PageIndex {
	value: string;
	pageIndex: number;
	isSelected: boolean;

	constructor(value: string, pageIndex: number, isSelected: boolean = false) {
		this.value = value;
		this.pageIndex = pageIndex;
		this.isSelected = isSelected;
	}
}

@Component({
	selector: 'app-ogdp-paginator',
	templateUrl: './ogdp-paginator.component.html',
	styleUrls: ['./ogdp-paginator.component.scss']
})
export class OgdpPaginatorComponent implements OnInit, OnChanges {

	@Input() linkMatPaginator: MatPaginator;
	@Input() length: number;
	@Input() pageSize: number;
	@Input() pageIndex: number;
	@Input() pageSizeOptions: number[];

	@Output() pageClick: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

	private DEFAULT_CENTRAL_NUMBERS: number = 3;

	public nbPages: number = 0;
	public centralNumbers: PageIndex[];
	public firstIndex: PageIndex;
	public lastIndex: PageIndex;
	public selectedIndex: PageIndex;

	constructor() {
		this.centralNumbers = [];
	}

	ngOnInit(): void {
		this.generatePageIndex(this.length, this.pageSize, this.pageIndex);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.generatePageIndex(this.length, this.pageSize, this.pageIndex);
	}

	nextPage(): void {
		this.linkMatPaginator?.nextPage();
	}

	previousPage(): void {
		this.linkMatPaginator?.previousPage();
	}

	firstPage(): void {
		this.linkMatPaginator?.firstPage();
	}

	goToPage(page: PageIndex): void {
		if (page.pageIndex === this.linkMatPaginator.pageIndex) {
			return;
		}
		const pageEvent: PageEvent = new PageEvent();
		pageEvent.pageIndex = page.pageIndex;
		pageEvent.previousPageIndex = this.linkMatPaginator.pageIndex;
		pageEvent.pageSize = this.linkMatPaginator.pageSize;
		pageEvent.length = this.linkMatPaginator.length;
		this.pageClick.emit(pageEvent);
	}

	lastPage(): void {
		this.linkMatPaginator?.lastPage();
	}

	pageSizeChanged(event: any): void {
		this.linkMatPaginator?._changePageSize(event.value);
	}

	private generatePageIndex(length: number, pageSize: number, pageIndex: number): void {

		this.nbPages = Math.ceil(length / pageSize);

		if (this.nbPages > 3) {
			this.centralNumbers = [];
			for (let i: number = pageIndex - 1 < 0 ? 0 : pageIndex - 1; i < ((pageIndex - 1 < 0 ? 0 : pageIndex - 1) + this.DEFAULT_CENTRAL_NUMBERS) && i < this.nbPages; ++i) {
				this.centralNumbers.push(new PageIndex(String(i + 1), i));
			}

			this.firstIndex = new PageIndex('1', 0);
			this.lastIndex = new PageIndex(String(this.nbPages), this.nbPages - 1);

		} else {
			this.firstIndex = null;
			this.lastIndex = null;
			this.centralNumbers = [];
			for (let i: number = 0; i < this.nbPages; ++i) {
				this.centralNumbers.push(new PageIndex(String(i + 1), i));
			}
		}

		const selectedPageIndex: PageIndex = this.centralNumbers.find(element => element.pageIndex === pageIndex);
		if (selectedPageIndex) {
			selectedPageIndex.isSelected = true;
			this.selectedIndex = selectedPageIndex;
		}
	}
}
