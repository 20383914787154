import {IChiffrageDto} from '../chiffrage/chiffrage.dto';
import {ISousCategorieDto} from '../sous-categorie/sous-categorie.dto';

export interface ICategorieDto {
	id: number;
	nom: string;
	order: number;
	sommeCharge: number;
	isExpanded: boolean;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousCategories: ISousCategorieDto[];
}

export class CategorieDto implements ICategorieDto {
	id: number;
	nom: string;
	order: number;
	sommeCharge: number;
	isExpanded: boolean;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousCategories: ISousCategorieDto[];

	constructor(id: number, nom: string, order: number, chiffrage: IChiffrageDto, sousCategories: ISousCategorieDto[]) {
		this.id = id;
		this.nom = nom;
		this.order = order;
		this.chiffrageId = chiffrage?.id;
		this.chiffrage = chiffrage;
		this.sousCategories = sousCategories;
		this.isExpanded = false;
	}
}
