import {Observable, of} from 'rxjs';
import {AbstractPaginateDatasource} from '../../../../core/pagination/abstract-paginate.datasource';
import {Pagination} from '../../../../core/pagination/dto/pagination.dto';
import {IProjectAlertDto} from '../../../../core/business/service/project-alert/project-alert.dto';
import {UtilsService} from '../../../../utils/service/utils.service';
import {SpinnerService} from '../../../../core/service/spinner.service';

export class ProjectAlertDisplayDatasource extends AbstractPaginateDatasource<IProjectAlertDto> {

	constructor(spinnerService: SpinnerService) {
		super(spinnerService);
	}

	loadProjectAlerts(projectAlerts: Observable<IProjectAlertDto[]>): void {
		projectAlerts.subscribe((projectAlertsData: IProjectAlertDto[]) => {
			this.notifyPagingData(of(new Pagination(projectAlertsData, projectAlertsData.length)));
		});
	}

	transformResults(pagination: Pagination<IProjectAlertDto>): IProjectAlertDto[] {
		return pagination.results.sort(UtilsService.dynamicMultiSort('type'));
	}
}
