import ExcelService from '../../core/service/excel/excel.service';
import {Workbook} from 'exceljs';
import {ChiffrageService} from '../../core/business/service/avant-vente/chiffrage/chiffrage.service';
import {ChiffrageTypeEnum, IChiffrageDto} from '../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import ExcelRow, {
	ExcelCell,
	ExcelCellFormat,
	ExcelProtection
} from '../../core/service/excel/excel.dto';
import {IAvantVenteDto} from '../../core/business/service/avant-vente/avant-vente.dto';
import {ICategorieDto} from '../../core/business/service/avant-vente/categorie/categorie.dto';
import {
	ECCBodyRepartition,
	ECCBodyTitle,
	ECCBorderBottomOnly,
	ECCBorderBottomRightOnly,
	ECCBorderRightOnly,
	ECCCategorieSousRepartition,
	ECCCategorieTitle, ECCCommentaire, ECCCommentaireTitle,
	ECCProjectTitle,
	ECCSousCategorieSousRepartition,
	ECCSousCategorieSousRepartitionOptionnel,
	ECCSousCategorieTitle,
	ECCTotalDeveloppementEmptyCell,
	ECCTotalDeveloppementSousRepartition,
	ECCTotalDeveloppementTitle,
	ECCTotalGlobalJourEmptyCell,
	ECCTotalGlobalJourSousRepartition,
	ECCTotalGlobalJourTitle
} from './export-excel-chiffrage.dto';
import {ISousRepartitionDto} from '../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import ExcelBusiness, {ExcelOrientationEnum, FormulaFctEnum} from '../../core/service/excel/excel.business';
import {IRepartitionDto} from '../../core/business/service/avant-vente/repartition/repartition.dto';
import ExportExcelChiffrageTotaux, {TotalTypeEnum} from './export-excel-chiffrage-totaux';
import ExportExcelChiffrageFormating from './export-excel-chiffrage-formating';
import {AvantVenteBusiness} from '../business/avant-vente.business';
import {ISousCategorieDto} from '../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';


export default class ExportExcelChiffrage {

	excelDatas: ExcelRow[];
	excelService: ExcelService;
	excelBusiness: ExcelBusiness;
	excelChiffrageTotaux: ExportExcelChiffrageTotaux;

	constructor() {
		this.excelService = new ExcelService();
		this.excelBusiness = new ExcelBusiness();
		this.excelChiffrageTotaux = new ExportExcelChiffrageTotaux();

		this.excelDatas = [];
	}


	generateExcel(chiffrage: IChiffrageDto): void {
		this.excelDatas = [];

		chiffrage.repartitions.sort(AvantVenteBusiness.sortRepartitionOrder);
		chiffrage.categories.forEach((categorie: ICategorieDto) => {
			categorie.sousCategories.forEach((sousCategorie: ISousCategorieDto) => {
				sousCategorie.sousRepartitions.sort(AvantVenteBusiness.sortSousRepartitionOrder);
			});
		});

		this.excelChiffrageTotaux.initMapWithRepartition(chiffrage);

		this.generateTitleHeader(chiffrage.avantVente);
		this.generateExcelCells(chiffrage);
		this.generateTotaux(chiffrage);

		const workBook: Workbook = this.excelService.setDataToWorkbook(this.excelDatas, 'chiffrage');
		ExportExcelChiffrageFormating.excelFormating(workBook, chiffrage);
		ExcelService.saveAsExcelFile(workBook, 'export_chiffrage');
	}

	generateExcelCells(chiffrageDto: IChiffrageDto): void {

		const rowTitleBody: ExcelRow = new ExcelRow(40);
		rowTitleBody.cells.push(new ExcelCell('', null, new ECCBodyRepartition(false)));
		rowTitleBody.cells.push(new ExcelCell('Elément de chiffrage', null, new ECCBodyTitle()));

		if (!chiffrageDto.repartitions.length) {
			rowTitleBody.cells.push(new ExcelCell('Charge (en jours)', null, new ECCBodyRepartition()));
		} else {
			for (const repartition of chiffrageDto.repartitions) {
				if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
					rowTitleBody.cells.push(new ExcelCell(repartition.nom + ' Min (en jours)', null, new ECCBodyRepartition()));
					rowTitleBody.cells.push(new ExcelCell(repartition.nom + ' Max (en jours)', null, new ECCBodyRepartition()));
				} else {
					rowTitleBody.cells.push(new ExcelCell(repartition.nom + ' (en jours)', null, new ECCBodyRepartition()));
				}
			}
		}
		rowTitleBody.addEmptyCells(1);
		rowTitleBody.cells.push(new ExcelCell('Commentaires', null, new ECCCommentaireTitle()));
		this.excelDatas.push(rowTitleBody);
		this.generateEmptyRow(chiffrageDto);

		chiffrageDto.categories = chiffrageDto.categories.sort((a, b) => a.order - b.order);
		for (const categorie of chiffrageDto.categories) {
			this.generateCategorieWithSousCategorieAndRepartition(categorie, chiffrageDto);
		}
	}

	generateTitleHeader(avantVente: IAvantVenteDto): void {

		const rowFirstTitle: ExcelRow = new ExcelRow(40);
		rowFirstTitle.cells.push(new ExcelCell(avantVente.titre, null, new ECCProjectTitle(), new ExcelProtection(true, true)));
		rowFirstTitle.addEmptyCells(3);
		this.excelDatas.push(rowFirstTitle);

		const rowSecondEmpty: ExcelRow = new ExcelRow();
		rowSecondEmpty.addEmptyCells(1);
		this.excelDatas.push(rowSecondEmpty);

		const rowThirdEmpty: ExcelRow = new ExcelRow();
		rowThirdEmpty.addEmptyCells(1);
		this.excelDatas.push(rowThirdEmpty);

		const rowFourthEmpty: ExcelRow = new ExcelRow();
		rowFourthEmpty.addEmptyCells(1);
		this.excelDatas.push(rowFourthEmpty);
	}

	generateCategorieWithSousCategorieAndRepartition(categorie: ICategorieDto, chiffrage: IChiffrageDto): void {
		const repartitions: IRepartitionDto[] = chiffrage.repartitions;
		const rowCategorie: ExcelRow = new ExcelRow(17.79);
		rowCategorie.cells.push(new ExcelCell('', null, new ECCCategorieTitle()));
		rowCategorie.cells.push(new ExcelCell((categorie.order + 1) + ' - ' + categorie.nom, null, new ECCCategorieTitle()));
		this.excelDatas.push(rowCategorie);

		let repartitionExcelIndex: string = this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL);
		for (const repartition of repartitions) {

			repartition.chiffrage = chiffrage;
			if (chiffrage.type === ChiffrageTypeEnum.MINMAX) {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_DEV,
					this.excelChiffrageTotaux.getKey(chiffrage, repartition, 1), repartitionExcelIndex
				);
				const newExcelCellMin: ExcelCell = new ExcelCell(
					this.generateFormula(categorie, repartitionExcelIndex),
					null,
					new ECCCategorieSousRepartition()
				);
				rowCategorie.cells.push(newExcelCellMin);
				repartitionExcelIndex = this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL);

				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_DEV,
					this.excelChiffrageTotaux.getKey(chiffrage, repartition, 2), repartitionExcelIndex
				);

				const newExcelCellMax: ExcelCell = new ExcelCell(
					this.generateFormula(categorie, repartitionExcelIndex),
					null,
					new ECCCategorieSousRepartition()
				);
				rowCategorie.cells.push(newExcelCellMax);
			} else {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_DEV, this.excelChiffrageTotaux.getKey(chiffrage, repartition), repartitionExcelIndex);
				const newExcelCell: ExcelCell = new ExcelCell(
					this.generateFormula(categorie, repartitionExcelIndex),
					null,
					new ECCCategorieSousRepartition()
				);
				rowCategorie.cells.push(newExcelCell);

			}
			repartitionExcelIndex = this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL);
		}
		if (!repartitions.length) {
			this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_DEV, 'totalDevCharge', repartitionExcelIndex);
			const newExcelCell: ExcelCell = new ExcelCell(
				this.generateFormula(categorie, repartitionExcelIndex),
				null,
				new ECCCategorieSousRepartition()
			);
			rowCategorie.cells.push(newExcelCell);
		}

		categorie.sousCategories = categorie.sousCategories.sort((a, b) => a.order - b.order);
		for (const sousCategorie of categorie.sousCategories) {
			const rowSousCategorie: ExcelRow = new ExcelRow();
			this.excelDatas.push(rowSousCategorie);

			if (sousCategorie.optionel) {
				rowSousCategorie.cells.push(new ExcelCell('Optionnel', null, new ECCSousCategorieSousRepartitionOptionnel()));
			} else {
				rowSousCategorie.cells.push(new ExcelCell('', null, new ECCSousCategorieSousRepartitionOptionnel()));
			}

			rowSousCategorie.cells.push(new ExcelCell(sousCategorie.nom, null, new ECCSousCategorieTitle()));
			for (const sousRepartition of sousCategorie.sousRepartitions) {
				if (chiffrage.type === ChiffrageTypeEnum.MINMAX) {
					rowSousCategorie.cells.push(new ExcelCell(sousRepartition.chargeMin, null, new ECCSousCategorieSousRepartition()));
					rowSousCategorie.cells.push(new ExcelCell(sousRepartition.chargeMax, null, new ECCSousCategorieSousRepartition()));
				} else {
					rowSousCategorie.cells.push(new ExcelCell(
						this.getFormValueFromSousRepartition(sousRepartition, chiffrage),
						null, new ECCSousCategorieSousRepartition()));
				}
			}
			if (!sousCategorie.sousRepartitions.length) {
				rowSousCategorie.cells.push(new ExcelCell(
					sousCategorie.charge,
					null, new ECCSousCategorieSousRepartition()));
			}
			if (sousCategorie.comment) {
				rowSousCategorie.addEmptyCells(1);
				rowSousCategorie.cells.push(new ExcelCell(sousCategorie.comment, null, new ECCCommentaire()));
			}
		}
	}

	generateFormula(category: ICategorieDto, repartitionExcelIndex: string): string {
		let formulaRepartition: string = '0';
		if (category.sousCategories.length) {
			formulaRepartition = this.excelBusiness.genFormulaSet(
				FormulaFctEnum.SUM,
				this.excelBusiness.getNNextExcelCellIndex(repartitionExcelIndex, ExcelOrientationEnum.ROW, 1),
				this.excelBusiness.getNNextExcelCellIndex(repartitionExcelIndex, ExcelOrientationEnum.ROW, category.sousCategories.length));
		}
		return formulaRepartition;
	}

	generateTotaux(chiffrageDto: IChiffrageDto): void {

		const devTotal: number = chiffrageDto.categories.reduce((acc, c) => acc + c.sommeCharge, 0);

		// Total -- Developpement
		this.generateEmptyRow(chiffrageDto);
		const rowTotalDeveloppement: ExcelRow = new ExcelRow(20.76);
		rowTotalDeveloppement.cells.push(new ExcelCell('', null, new ECCTotalDeveloppementEmptyCell()));
		rowTotalDeveloppement.cells.push(new ExcelCell('TOTAL DÉVELOPPEMENT', null, new ECCTotalDeveloppementTitle()));
		this.excelDatas.push(rowTotalDeveloppement);

		if (!chiffrageDto.repartitions.length) {
			this.excelChiffrageTotaux.addCellToTotal(
				TotalTypeEnum.TOTAL_GLOBAL, 'totalDevCharge',
				this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
			);
			rowTotalDeveloppement.cells.push(new ExcelCell(
				this.excelChiffrageTotaux.getFormulaTotalDevelopment('totalDevCharge'),
				null,
				new ECCTotalDeveloppementSousRepartition()));
		}
		for (const repartition of chiffrageDto.repartitions) {
			repartition.chiffrage = chiffrageDto;
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowTotalDeveloppement.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalDevelopment(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1)),
					null, new ECCTotalDeveloppementSousRepartition())
				);

				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowTotalDeveloppement.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalDevelopment(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2)),
					null, new ECCTotalDeveloppementSousRepartition())
				);
			} else {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowTotalDeveloppement.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalDevelopment(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition)),
					null, new ECCTotalDeveloppementSousRepartition())
				);
			}
		}
		this.generateEmptyRow(chiffrageDto);


		// Pilotage
		const rowPilotage: ExcelRow = new ExcelRow();
		rowPilotage.cells.push(new ExcelCell('', null, new ECCCategorieSousRepartition()));
		rowPilotage.cells.push(new ExcelCell('Pilotage', null, new ECCCategorieTitle()));
		this.excelDatas.push(rowPilotage);

		if (!chiffrageDto.repartitions.length) {
			this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, '0',
				this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
			);
			rowPilotage.cells.push(new ExcelCell(
				this.excelChiffrageTotaux.getFormulaPilotage('totalDevCharge', chiffrageDto), null, new ECCCategorieSousRepartition()));
		}
		for (const repartition of chiffrageDto.repartitions) {
			repartition.chiffrage = chiffrageDto;
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowPilotage.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaPilotage(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1), chiffrageDto),
					null,
					new ECCCategorieSousRepartition())
				);

				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowPilotage.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaPilotage(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2), chiffrageDto),
					null, new ECCCategorieSousRepartition())
				);
			} else {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowPilotage.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaPilotage(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition), chiffrageDto),
					null, new ECCCategorieSousRepartition())
				);
			}
		}

		// Intégartion & Tests
		const rowIntegrationTest: ExcelRow = new ExcelRow();
		rowIntegrationTest.cells.push(new ExcelCell('', null, new ECCCategorieSousRepartition()));
		rowIntegrationTest.cells.push(new ExcelCell('Intégration & Tests', null, new ECCCategorieTitle()));
		this.excelDatas.push(rowIntegrationTest);

		if (!chiffrageDto.repartitions.length) {
			this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, '0',
				this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
			);
			rowIntegrationTest.cells.push(new ExcelCell(
				this.excelChiffrageTotaux.getFormulaIntegrationTest('totalDevCharge', chiffrageDto),
				null,
				new ECCCategorieSousRepartition()));
		}
		for (const repartition of chiffrageDto.repartitions) {
			repartition.chiffrage = chiffrageDto;
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				this.excelChiffrageTotaux.addCellToTotal(
					TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowIntegrationTest.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaIntegrationTest(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1), chiffrageDto),
					null,
					new ECCCategorieSousRepartition())
				);

				this.excelChiffrageTotaux.addCellToTotal(
					TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowIntegrationTest.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaIntegrationTest(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2), chiffrageDto),
					null,
					new ECCCategorieSousRepartition())
				);
			} else {
				this.excelChiffrageTotaux.addCellToTotal(
					TotalTypeEnum.TOTAL_GLOBAL,
					this.excelChiffrageTotaux.getKey(chiffrageDto, repartition),
					this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL)
				);
				rowIntegrationTest.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaIntegrationTest(this.excelChiffrageTotaux.getKey(chiffrageDto, repartition), chiffrageDto),
					null,
					new ECCCategorieSousRepartition())
				);
			}
		}
		const beginAnnex: string = this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.ROW);
		const endAnnex: string = this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.ROW, chiffrageDto.chargeAnnexes.length);


		// Charges annexe
		for (const chargeAnnexe of chiffrageDto.chargeAnnexes) {
			const rowChargeAnnexe: ExcelRow = new ExcelRow();
			rowChargeAnnexe.cells.push(new ExcelCell('', null, new ECCCategorieSousRepartition()));
			rowChargeAnnexe.cells.push(new ExcelCell(chargeAnnexe.titre, null, new ECCCategorieTitle()));
			this.excelDatas.push(rowChargeAnnexe);

			if (!chargeAnnexe.sousRepartitions.length) {
				this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, '0', this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL));
				rowChargeAnnexe.cells.push(new ExcelCell(chargeAnnexe.charge, null, new ECCCategorieSousRepartition()));
			}
			for (const sousRepartition of chargeAnnexe.sousRepartitions) {
				const repartition: IRepartitionDto = chiffrageDto.repartitions.find(value => value.id === sousRepartition.repartitionId);

				if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
					this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1), this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL));
					rowChargeAnnexe.cells.push(new ExcelCell(this.getFormValueFromSousRepartition(sousRepartition, chiffrageDto), null, new ECCCategorieSousRepartition()));

					this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2), this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL));
					rowChargeAnnexe.cells.push(new ExcelCell(this.getFormValueFromSousRepartition(sousRepartition, chiffrageDto), null, new ECCCategorieSousRepartition()));
				} else {
					this.excelChiffrageTotaux.addCellToTotal(TotalTypeEnum.TOTAL_GLOBAL, this.excelChiffrageTotaux.getKey(chiffrageDto, repartition), this.excelBusiness.getNextCellIndex(this.excelDatas, ExcelOrientationEnum.COL));
					rowChargeAnnexe.cells.push(new ExcelCell(this.getFormValueFromSousRepartition(sousRepartition, chiffrageDto), null, new ECCCategorieSousRepartition()));
				}
			}
		}

		// Total -- Developpement & Formation & Documentation
		this.generateEmptyRow(chiffrageDto);
		const rowTotalGlobalJour: ExcelRow = new ExcelRow(20.76);
		rowTotalGlobalJour.cells.push(new ExcelCell('', null, new ECCTotalGlobalJourEmptyCell()));
		rowTotalGlobalJour.cells.push(new ExcelCell('TOTAL GLOBAL JOURS', null, new ECCTotalGlobalJourTitle()));
		this.excelDatas.push(rowTotalGlobalJour);

		if (!chiffrageDto.repartitions.length) {
			rowTotalGlobalJour.cells.push(new ExcelCell(
				this.excelChiffrageTotaux.getFormulaTotalGlobalJours('totalDevCharge', chiffrageDto, beginAnnex, endAnnex),
				null, new ECCTotalGlobalJourSousRepartition()));
		}
		for (const repartition of chiffrageDto.repartitions) {
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				rowTotalGlobalJour.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobalJours(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1),
						chiffrageDto, beginAnnex, endAnnex
					),
					null, new ECCTotalGlobalJourSousRepartition()));
				rowTotalGlobalJour.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobalJours(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2),
						chiffrageDto, beginAnnex, endAnnex
					),
					null, new ECCTotalGlobalJourSousRepartition()));
			} else {
				rowTotalGlobalJour.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobalJours(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition),
						chiffrageDto, beginAnnex, endAnnex
					),
					null, new ECCTotalGlobalJourSousRepartition()));
			}
		}

		// Total -- Global
		this.generateEmptyRow(chiffrageDto);
		const rowTotalGlobal: ExcelRow = new ExcelRow(20.76);
		rowTotalGlobal.cells.push(new ExcelCell('', null, new ECCTotalGlobalJourEmptyCell()));
		rowTotalGlobal.cells.push(new ExcelCell('TOTAL GLOBAL', null, new ECCTotalGlobalJourTitle()));
		this.excelDatas.push(rowTotalGlobal);

		if (!chiffrageDto.repartitions.length) {
			rowTotalGlobal.cells.push(new ExcelCell(
				this.excelChiffrageTotaux.getFormulaTotalGlobal('totalDevCharge', chiffrageDto, beginAnnex, endAnnex),
				new ExcelCellFormat('# ##0,00\\ €;-# ##0,00\\ €'),
				new ECCTotalGlobalJourSousRepartition()));
		}
		for (const repartition of chiffrageDto.repartitions) {
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				rowTotalGlobal.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobal(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 1),
						chiffrageDto, beginAnnex, endAnnex
					),
					new ExcelCellFormat('# ##0,00\\ €;-# ##0,00\\ €'),
					new ECCTotalGlobalJourSousRepartition()));
				rowTotalGlobal.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobal(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition, 2),
						chiffrageDto, beginAnnex, endAnnex
					),
					new ExcelCellFormat('# ##0,00\\ €;-# ##0,00\\ €'),
					new ECCTotalGlobalJourSousRepartition()));
			} else {
				rowTotalGlobal.cells.push(new ExcelCell(
					this.excelChiffrageTotaux.getFormulaTotalGlobal(
						this.excelChiffrageTotaux.getKey(chiffrageDto, repartition),
						chiffrageDto, beginAnnex, endAnnex
					),
					new ExcelCellFormat('# ##0,00\\ €;-# ##0,00\\ €'),
					new ECCTotalGlobalJourSousRepartition()));
			}
		}

		const rowEmpt3: ExcelRow = new ExcelRow();
		rowEmpt3.addEmptyCells(1, new ECCBorderBottomOnly());
		rowEmpt3.addEmptyCells(1, new ECCBorderBottomRightOnly());
		this.excelDatas.push(rowEmpt3);

		if (!chiffrageDto.repartitions.length) {
			rowEmpt3.addEmptyCells(1, new ECCBorderBottomRightOnly());
		}
		for (const repartition of chiffrageDto.repartitions) {
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				rowEmpt3.addEmptyCells(2, new ECCBorderBottomRightOnly());
			} else {
				rowEmpt3.addEmptyCells(1, new ECCBorderBottomRightOnly());
			}
		}
	}

	private getFormValueFromSousRepartition(sousRepartition: ISousRepartitionDto, chiffrage: IChiffrageDto): any {

		let formatValue: any;
		switch (chiffrage.type) {
			case ChiffrageTypeEnum.REEL:
				formatValue = sousRepartition.charge;
				break;
			case ChiffrageTypeEnum.PERCENT:
				formatValue = sousRepartition.charge;
				break;
			case ChiffrageTypeEnum.MINMAX:
				formatValue = sousRepartition.chargeMin + ' / ' + sousRepartition.chargeMin;
				break;
		}

		return formatValue;
	}

	private generateEmptyRow(chiffrageDto: IChiffrageDto): void {
		const rowEmpty: ExcelRow = new ExcelRow();
		rowEmpty.addEmptyCells(1);
		rowEmpty.addEmptyCells(1, new ECCBorderRightOnly());
		this.excelDatas.push(rowEmpty);

		if (!chiffrageDto.repartitions.length) {
			rowEmpty.addEmptyCells(1, new ECCBorderRightOnly());
		}
		for (const repartition of chiffrageDto.repartitions) {
			if (chiffrageDto.type === ChiffrageTypeEnum.MINMAX) {
				rowEmpty.addEmptyCells(2, new ECCBorderRightOnly());
			} else {
				rowEmpty.addEmptyCells(1, new ECCBorderRightOnly());
			}
		}
	}

}
