import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../utils/service/utils.service';
import {FormControl} from '@angular/forms';
import {fromEvent, merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {ContractStatusEnum} from '../../../../core/business/service/lot/lot.dto';
import {IStoryDto} from '../../../../core/business/service/story/story.dto';
import {TranslateService} from '@ngx-translate/core';
import {LotDataSource, LotDataSourceContent} from '../../datasource/projet-detail.datasource';
import {IssueTypeEnum} from '../../dto/projet-details.objects';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RedistributionBudgetSignedResult {
	storiesWithLotChange: IStoryDto[];
	storyToCredit: LotDataSourceContent;
	storyToDebit: LotDataSourceContent;
}

@Component({
	selector: 'app-dialog-redistribution-budget-signed',
	styleUrls: ['./dialog-redistribution-budget-signed.component.scss'],
	templateUrl: './dialog-redistribution-budget-signed.component.html'
})
export class DialogRedistributionBudgetSignedComponent implements AfterViewInit, OnInit {

	public srcLot: LotDataSource;
	public destLot: LotDataSource;
	public storiesWithLotChange: IStoryDto[];

	public selectStoryControllerToCredit: FormControl;
	public selectStoryFilteredToCredit: Observable<LotDataSourceContent[]>;
	public selectStoryControllerToDebit: FormControl;
	public selectStoryFilteredToDebit: Observable<LotDataSourceContent[]>;

	public totalBudgetStoriesWhithLotChange: number;

	public storyToCredit: LotDataSourceContent;
	public storyToDebit: LotDataSourceContent;

	public isBusinessValid: boolean = false;
	public errorMessage: string;

	@ViewChild('forceBudgetToCredit') forceBudgetToCredit: MatCheckbox;
	@ViewChild('forceBudgetToDebit') forceBudgetToDebit: MatCheckbox;
	@ViewChild('autocompleteToCredit') autocompleteToCredit: MatAutocomplete;
	@ViewChild('autocompleteToDebit') autocompleteToDebit: MatAutocomplete;
	@ViewChild('txtStoryToCredit') txtStoryToCredit: ElementRef;
	@ViewChild('txtStoryToDebit') txtStoryToDebit: ElementRef;

	constructor(public dialogRef: MatDialogRef<DialogRedistributionBudgetSignedComponent>,
				private translator: TranslateService,
				@Inject(MAT_DIALOG_DATA) public data: { storiesWithLotChange: IStoryDto[], srcLot: LotDataSource, destLot: LotDataSource }) {
		this.selectStoryControllerToCredit = new FormControl();
		this.selectStoryControllerToDebit = new FormControl();
		this.storiesWithLotChange = data.storiesWithLotChange;
		this.destLot = data.destLot;
		this.srcLot = data.srcLot;
	}

	ngOnInit(): void {
		this.totalBudgetStoriesWhithLotChange = this.storiesWithLotChange.reduce((sumBudget, story) => sumBudget + story.budget, 0);
	}

	ngAfterViewInit(): void {
		this.selectStoryFilteredToCredit = this.selectStoryControllerToCredit.valueChanges.pipe(
			startWith(''),
			debounceTime(250),
			distinctUntilChanged(),
			map(value => {
				if (typeof value === 'string') {
					return this.dataSelectStoryToCredit(this.getStories(this.srcLot), value);
				}
			}));

		this.selectStoryFilteredToDebit = this.selectStoryControllerToDebit.valueChanges.pipe(
			startWith(''),
			debounceTime(250),
			distinctUntilChanged(),
			map(value => {
				if (typeof value === 'string') {
					const matchStoriesToDebit: LotDataSourceContent[] = this.dataSelectStoryToDebit(this.getStories(this.destLot), value);
					if (matchStoriesToDebit.length === 0) {
						this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
					}
					return matchStoriesToDebit;
				}
			}));

		if (this.autocompleteToCredit) {
			merge(
				this.autocompleteToCredit.optionSelected,
				this.forceBudgetToCredit.change,
				fromEvent(this.txtStoryToCredit.nativeElement, 'keyup').pipe(
					debounceTime(250),
					distinctUntilChanged()))
				.pipe(tap(() => {
					if (this.forceBudgetToCredit.checked) {
						this.storyToCredit = null;
						this.selectStoryControllerToCredit.setValue('');
					} else {
						this.storyToCredit = this.selectStoryControllerToCredit.value;
					}
					this.isBusinessValid = this.isValidForm();
				})).subscribe();
		}

		if (this.autocompleteToDebit) {
			merge(
				this.autocompleteToDebit.optionSelected,
				this.forceBudgetToDebit.change,
				fromEvent(this.txtStoryToDebit.nativeElement, 'keyup').pipe(
					debounceTime(250),
					distinctUntilChanged()))
				.pipe(tap(() => {
					this.errorMessage = '';
					if (this.forceBudgetToDebit.checked) {
						this.storyToDebit = null;
						this.selectStoryControllerToDebit.setValue('');
					} else {
						this.storyToDebit = this.selectStoryControllerToDebit.value;
						if (this.storyToDebit) {
						} else {
							this.selectStoryFilteredToDebit.subscribe(value => {
								if (value?.length === 0) {
									this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
								}
							});
						}
					}
					this.isBusinessValid = this.isValidForm();
				})).subscribe();
		}
	}

	actionBtnValider(): void {
		if (this.srcLot.contract === ContractStatusEnum.SIGNED) {
			if (this.forceBudgetToCredit.checked) {
				// si on force on deplace juste sans compensation
			} else {
				this.storyToCredit.budget = this.storyToCredit.budget + this.totalBudgetStoriesWhithLotChange;
			}
		}

		if (this.destLot.contract === ContractStatusEnum.SIGNED) {
			if (this.forceBudgetToDebit.checked) {
				// si on force on deplace juste sans compensation
			} else {
				this.storyToDebit.budget = this.storyToDebit.budget - this.totalBudgetStoriesWhithLotChange;
			}
		}

		this.dialogRef.close({
			storiesWithLotChange: this.storiesWithLotChange,
			storyToCredit: this.storyToCredit,
			storyToDebit: this.storyToDebit
		});
	}

	getStories(lot: LotDataSource): LotDataSourceContent[] {
		let stories: LotDataSourceContent[] = lot.content.filter(child => child.type === IssueTypeEnum.STORY);
		lot.content.filter(child => child.type === IssueTypeEnum.EPIC).forEach(epic => {
			stories = stories.concat(epic.children.filter(child => child.type === IssueTypeEnum.STORY));
		});
		return stories;
	}

	dataSelectStoryToCredit(stories: LotDataSourceContent[], filter: string): LotDataSourceContent[] {
		const filterValue: string = filter.toLowerCase();
		const selectedStoriesId: number[] = this.storiesWithLotChange.map(value => value.id);
		return stories.filter(value => value.name.toLowerCase().includes(filterValue)
			&& !selectedStoriesId.includes(value.id)
		).sort(UtilsService.dynamicMultiSort('name'));
	}

	dataSelectStoryToDebit(stories: LotDataSourceContent[], filter: string): LotDataSourceContent[] {
		const filterValue: string = filter.toLowerCase();
		return stories.filter(value => value.name.toLowerCase().includes(filterValue)
			&& value.remainingEXT >= this.totalBudgetStoriesWhithLotChange
		).sort(UtilsService.dynamicMultiSort('name'));
	}

	displaySelectedStory(story: IStoryDto): string {
		return story ? [story.name, story.jiraIntId, story.remainingEXT].filter(value => value).join(' - ') : '';
	}

	private isValidForm(): boolean {

		let isBusinessValid: boolean = false;
		if (this.srcLot.contract === ContractStatusEnum.SIGNED && this.destLot.contract === ContractStatusEnum.SIGNED) {
			isBusinessValid = this.isValidDebitForm() && this.isValidCreditForm();
		} else if (this.srcLot.contract === ContractStatusEnum.SIGNED && this.destLot.contract !== ContractStatusEnum.SIGNED) {
			isBusinessValid = this.isValidCreditForm();
		} else if (this.srcLot.contract !== ContractStatusEnum.SIGNED && this.destLot.contract === ContractStatusEnum.SIGNED) {
			isBusinessValid = this.isValidDebitForm();
		}

		return isBusinessValid;
	}

	private isValidDebitForm(): boolean {
		let isValidDebitForm: boolean = false;

		if (this.storyToDebit) {
			if (this.storyToDebit.remainingEXT >= this.totalBudgetStoriesWhithLotChange) {
				isValidDebitForm = true;
			} else {
				this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NOT_ENOUGH_RAE');
			}
		} else if (this.forceBudgetToDebit.checked) {
			isValidDebitForm = true;
		}

		return isValidDebitForm;
	}

	private isValidCreditForm(): boolean {
		return this.storyToCredit != null || this.forceBudgetToCredit.checked;
	}
}
