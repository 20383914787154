import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {SPHeaderPeriod, SPHeaderUser, SPRowCell} from '../dto/suivi-projet.dto';

@Directive({
	selector: '[appCellClass]',
	exportAs: 'appCellClass',
})
export class CellClassDirective implements OnChanges {
	@Input() appCellClass: SPRowCell | SPHeaderUser | SPHeaderPeriod;
	@Input() colorGreen: boolean;
	@Input() colorRed: boolean;
	@Input() colorBlueNavy: boolean;
	@Input() borderLeft: boolean;
	@Input() borderRight: boolean;
	@Input() borderRightLight: boolean;
	@Input() hidden: boolean;
	@Input() jiraSubtask: boolean;
	@Input() bgWhite: boolean;
	@Input() bgGreen: boolean;
	@Input() bgRed: boolean;
	@Input() bgBlueLight: boolean;

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this._setClass();
	}

	private _setClass(): void {
		if (this.appCellClass?.index != null) {
			this.renderer.setAttribute(this.hostElement.nativeElement, 'cell-index', this.appCellClass.index.toString());
		}
		if (this.colorGreen) {
			this.renderer.addClass(this.hostElement.nativeElement, 'color-green-suivi');
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-red');
		} else if (this.colorRed) {
			this.renderer.addClass(this.hostElement.nativeElement, 'color-red');
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-green-suivi');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-green-suivi');
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-red');
		}
		if (this.colorBlueNavy) {
			this.renderer.addClass(this.hostElement.nativeElement, 'color-blue-navy-2');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-blue-navy-2');
		}
		if (this.bgGreen) {
			this.renderer.addClass(this.hostElement.nativeElement, 'bg-green-suivi');
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-red');
		} else if (this.bgRed) {
			this.renderer.addClass(this.hostElement.nativeElement, 'bg-red');
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-green-suivi');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-red');
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-green-suivi');
		}
		if (this.bgWhite) {
			this.renderer.addClass(this.hostElement.nativeElement, 'bg-white');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-white');
		}
		if (this.bgBlueLight) {
			this.renderer.addClass(this.hostElement.nativeElement, 'bg-blue-extralight');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'bg-blue-extralight');
		}
		if ((this.appCellClass && !this.appCellClass.displayed) || this.hidden) {
			this.renderer.addClass(this.hostElement.nativeElement, 'd-none');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'd-none');
		}
		if (this.borderLeft) {
			this.renderer.addClass(this.hostElement.nativeElement, 'border-l-blue-4');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'border-l-blue-4');
		}
		if (this.borderRight) {
			this.renderer.addClass(this.hostElement.nativeElement, 'border-r-blue-4');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'border-r-blue-4');
		}
		if (this.borderRightLight) {
			this.renderer.addClass(this.hostElement.nativeElement, 'border-r-blue-2');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'border-r-blue-2');
		}
		if (this.jiraSubtask) {
			this.renderer.addClass(this.hostElement.nativeElement, 'color-jira-SUBTASK');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'color-jira-SUBTASK');
		}
	}

}
