import {IChiffrageDto} from '../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {Workbook, Worksheet} from 'exceljs';

export default class ExportExcelChiffrageFormating {

	public static excelFormating(workBook: Workbook, chiffrage: IChiffrageDto): void {
		const worksheet: Worksheet = workBook.getWorksheet(1);

		worksheet.getColumn(1).width = 12;
		worksheet.getColumn(2).width = 75;

		let i: number = 2;
		if (!chiffrage.repartitions.length) {
			i++;
			worksheet.getColumn(i).width = 20;
		}
		chiffrage.repartitions.forEach(() => {
			if (chiffrage.type === 'MINMAX') {
				i++;
				worksheet.getColumn(i).width = 20;
			}
			i++;
			worksheet.getColumn(i).width = 20;
		});
		worksheet.getColumn(i + 1).width = 3;
		worksheet.getColumn(i + 2).width = 75;

		worksheet.mergeCells('A1:' + (worksheet.getColumn(i) as any).letter + '1');
		worksheet.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle'};
	}
}
