import ExcelRow from './excel.dto';

export enum FormulaFctEnum {
	SUM,
}

export enum ExcelOrientationEnum {
	COL = 'COL',
	ROW = 'ROW'
}


export default class ExcelBusiness {

	public getNextColumnLetter(columnName: string): string {
		let nextColumnLetter: string = '';

		if (columnName.length === 1) {
			const asciiNumber: number = columnName.charCodeAt(0);
			nextColumnLetter = String.fromCharCode(asciiNumber + 1);
		} else {
			console.log('Oh wait ...');
		}

		return nextColumnLetter;
	}

	public getNextExcelCellIndex(cellIndex: string, orientation: ExcelOrientationEnum): string {
		let nextColumnIndex: string = '';

		const letterIndex: string = cellIndex[0];
		const numberIndex: number = Number(cellIndex.slice(1, cellIndex.length));
		if (orientation === ExcelOrientationEnum.COL) {
			const nextLetterIndex: string = this.getNextColumnLetter(letterIndex);
			nextColumnIndex = nextLetterIndex + numberIndex;
		} else { // row
			nextColumnIndex = letterIndex + (numberIndex + 1);
		}

		return nextColumnIndex;
	}

	public getNNextExcelCellIndex(cellIndex: string, orientation: ExcelOrientationEnum, step: number): string {

		let nextColumnIndex: string = cellIndex;
		for (let i: number = 0; i < step; i++) {
			nextColumnIndex = this.getNextExcelCellIndex(nextColumnIndex, orientation);
		}

		return nextColumnIndex;
	}

	public genFormulaSet(type: FormulaFctEnum, excelStartIndex: string, excelEndIndex: string): any {
		const cellFormula: { formula: any; } = {} as { formula: any; };

		switch (type) {
			case FormulaFctEnum.SUM:
				cellFormula.formula = 'SUM(' + excelStartIndex + ':' + excelEndIndex + ')';
				break;
			default:
				console.log('Can not generate formula');
		}

		return cellFormula;
	}

	public genFormulaList(type: FormulaFctEnum, excelIndexs: string[]): any {
		const cellFormula: { formula: any; } = {} as { formula: any; };

		switch (type) {
			case FormulaFctEnum.SUM:
				cellFormula.formula = 'SUM(' + excelIndexs.join(',') + ')';
				break;
			default:
				console.log('Can not generate formula');
		}

		return cellFormula;
	}

	public getNextCellIndex(datas: ExcelRow[], orientation: ExcelOrientationEnum, rowDecalage: number = 0, colDecalage: number = 0): string {

		let lastDatasRow: number = datas.length;
		let lastDatasCol: number = datas[datas.length - 1].cells.length;

		lastDatasRow = lastDatasRow + rowDecalage;
		lastDatasCol = lastDatasCol + colDecalage;

		// 65 table ASCCI = A
		if (orientation === ExcelOrientationEnum.ROW) {
			return String.fromCharCode(64 + lastDatasCol) + (lastDatasRow + 1);
		} else {
			return String.fromCharCode(64 + lastDatasCol + 1) + lastDatasRow;
		}
	}

}
