import {ProjectService} from '../../../core/business/service/project/project.service';
import {ProjectAlertService} from '../../../core/business/service/project-alert/project-alert.service';
import {SpinnerService} from '../../../core/service/spinner.service';
import {ContractStatusEnum, ILotDto, Lot} from '../../../core/business/service/lot/lot.dto';
import {DetailedLot, EpicWithStory, IssueTypeEnum} from '../dto/projet-details.objects';
import {IStoryDto, JiraStatusEnum, StoryDto, StoryTypeEnum} from '../../../core/business/service/story/story.dto';
import {ISubtaskDto, SubtaskTypeEnum} from '../../../core/business/service/subtask/subtask.dto';
import {IProjectDto, ProjectDto} from '../../../core/business/service/project/project.dto';
import {IConfigurationDto} from '../../../core/business/service/configuration/configuration.dto';
import {AbstractDatasource, Filterable} from '../../../core/pagination/abstract.datasource';
import {BehaviorSubject, from} from 'rxjs';
import {INumberAlertsDto} from '../../../core/business/service/project-alert/project-alert.dto';
import {UtilsDatasource} from '../../../core/pagination/utils.datasource';
import {ISprintDto} from '../../../core/business/service/sprint/sprint.dto';
import {IEpicDto} from '../../../core/business/service/epic/epic.dto';
import {JiraTransition} from '../dto/jira-transition.object';
import {UtilsService} from '../../../utils/service/utils.service';
import {UserBaseDto} from '../../../core/business/service/user-base/user-base.dto';
import {AdminRolePipe, ClientRolePipe} from '../../../security/pipe/role.pipe';

export class LotDataSource implements Filterable {
	id: number;
	name: string;
	budget: number;
	estimated: number;
	contract: ContractStatusEnum;
	RAE: number;
	avancement: string;
	backlog: boolean;
	content: LotDataSourceContent[] = [];
	nombreDeStory: number;
	expanded: boolean = false;
	editing: boolean = false;
	object: DetailedLot;
	lotObject: ILotDto;
	isKeyboardSelected: boolean = false;
	matchesFilter: boolean = true;
	highlightedName: string[] = [];
	isLoaded: boolean = false;
}

export class LotDataSourceContent implements Filterable {
	parentType: IssueTypeEnum;
	type: IssueTypeEnum;
	subType: IssueTypeEnum;
	storyType: StoryTypeEnum;
	subtaskType: SubtaskTypeEnum;
	id: number;
	typeId: string;
	parentId: number;
	parentTypeId: string;
	name: string;
	jiraExtId: string;
	urlJiraExtId: string;
	jiraIntId: string;
	urlJiraIntId: string;
	status: { key: string, value: string };
	budget: number;
	remainingEXT: number;
	estimated: number;
	avancement: string;
	reporter: string;
	orphan: boolean = false;
	order: number;
	children: LotDataSourceContent[] = [];
	totalChildrenCount: number = 0;
	isLotContratSigned: boolean;
	lotId: number;
	object: EpicWithStory | IStoryDto | ISubtaskDto;
	visible: boolean;
	expanded: boolean = true;
	isKeyboardSelected: boolean = false;
	matchesFilter: boolean = true;
	highlightedName: string[] = [];
	contract: ContractStatusEnum;
	updatingStatus: boolean;
	availableNextStatus: JiraTransition[];
}

export class ProjetDetailDataSource extends AbstractDatasource<LotDataSource> {

	public dataDbProject: BehaviorSubject<IProjectDto>;
	public dataDbProjectAlert: BehaviorSubject<INumberAlertsDto[]>;
	public hideEmptyEpics: boolean = false;

	constructor(private projectService: ProjectService, private projectAlertService: ProjectAlertService,
				private user: UserBaseDto, public spinnerService: SpinnerService, public configurationUrlJira: IConfigurationDto) {
		super(spinnerService);

		this.dataDbProject = new BehaviorSubject<IProjectDto>(new ProjectDto());
		this.dataDbProjectAlert = new BehaviorSubject<INumberAlertsDto[]>([]);
	}

	findProject(projectId: number): void {
		this._filterFunction = undefined;
		this.notifyData(this.projectService.findOne(projectId));
		this.findProjectAlert(projectId);
	}

	findProjectAlert(projectId: number): void {
		this.projectAlertService.findNbAlertsByProject(projectId).subscribe(value => {
			this.dataDbProjectAlert.next(value);
		});
	}

	transformResults(result: IProjectDto): LotDataSource[] {
		if (result) {
			if (result.lots) {
				result.lots.sort((a: Lot, b: Lot) => (a.order - b.order));
				result.sprints.sort((a: ISprintDto, b: ISprintDto) => this.sortSprints(a, b));
			}
			this.dataDbProject.next(result);

			this.hideEmptyEpics = result.gestionParameters?.displayParams.includes('hide_empty_epic');

			const lotDataSource: LotDataSource[] = this.buildDataSource(result);
			lotDataSource.forEach(lot => {
				lot.isLoaded = lot.expanded;
			});

			return lotDataSource;
		} else {
			return [];
		}
	}

	populateLotWithStories(lot: LotDataSource, stories: IStoryDto[]): Promise<LotDataSource> {
		return new Promise(resolve => {
			if (lot.lotObject) {
				lot.lotObject.stories = [];
			}
			for (const story of stories) {
				lot.lotObject.stories.push(story);
				const content: LotDataSourceContent = this._createStoryForDatasource(story, null, lot, IssueTypeEnum.EPIC);
				let epic: LotDataSourceContent;
				if (story.epic) {
					epic = lot.content.find((e) => e.id === story.epicId);
					if (story.epic.expandedInLots) {
						epic.expanded = Array.from(JSON.parse(story.epic.expandedInLots)).some((lotId) => +lotId === lot.id);
					}
					epic.children.push(content);
					(epic.object as EpicWithStory).stories.push(story);
				}
			}
			lot.isLoaded = true;
			const index: number = this._data.findIndex((item) => lot.id === item.id);
			this._data.splice(index, 1, lot);
			this.dataSubject.next(this._data);

			resolve(lot);
		});
	}

	private sortSprints(a: ISprintDto, b: ISprintDto): number {
		if (a.status !== 'future' || b.status !== 'future') {
			if (a.status === 'future') {
				return -1;
			}
			if (b.status === 'future') {
				return 1;
			}
		}

		if (!a.startDate && !b.startDate) {
			return 0;
		}
		if (!a.startDate) {
			return -1;
		}
		if (!b.startDate) {
			return 1;
		}

		return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
	}

	filter(filter?: string, filterSprint?: ISprintDto | string, filterContract?: ContractStatusEnum, hideEpicWithoutStory?: boolean): void {
		if (filter || filterContract || filterSprint) {
			this.applyFilter(this.filterByName(filter, filterSprint, filterContract, hideEpicWithoutStory));
		} else {
			this.applyFilter(this.resetFilter());
		}
	}

	private filterByName(filter: string,
						 filterSprint: ISprintDto | string,
						 filterContract: ContractStatusEnum,
						 hideEpicWithoutStory?: boolean): any {
		function matchRegexAndHighlightName(element: Filterable, parents: Filterable[]): void {
			if (!filter) {
				element.matchesFilter = true;
				parents.forEach(p => p.matchesFilter = true);
			} else {
				let index: number = UtilsDatasource.searchWithRemoveDiacritics(filter, element.name, false);
				if (index !== -1) {
					element.matchesFilter = true;
					parents.forEach(p => p.matchesFilter = true);
					element.highlightedName = [
						element.name.substring(0, index),
						element.name.substring(index, index + filter.length),
						element.name.substring(index + filter.length)
					];
				} else if (index === -1 && element.jiraIntId) {
					index = UtilsDatasource.searchWithRemoveDiacritics(filter, element.jiraIntId, false);
					if (index !== -1) {
						element.matchesFilter = true;
						parents.forEach(p => p.matchesFilter = true);
					}
				}
			}
		}
		function recursiveApplyFilter(content: LotDataSourceContent, parents: Filterable[]): void {
			content.matchesFilter = false;
			content.highlightedName = [];
			if (hideEpicWithoutStory && content.type === IssueTypeEnum.EPIC && !content.children?.length) {
				return;
			}
			if (!filterSprint) {
				matchRegexAndHighlightName(content, parents);
			}
			if (content.type === IssueTypeEnum.STORY) {
				// @ts-ignore
				if (typeof filterSprint === 'string' && filterSprint === 'noSprint' && !content.object.sprint) {
					matchRegexAndHighlightName(content, parents);
				// @ts-ignore
				} else if (filterSprint as ISprintDto && content.object.sprint?.id === filterSprint.id) {
					matchRegexAndHighlightName(content, parents);
				}
			}
			content.children.forEach(c => {
				recursiveApplyFilter(c, parents.concat(content));
			});
		}
		return function (lot: LotDataSource): LotDataSource {
			lot.matchesFilter = false;
			lot.highlightedName = [];
			if (!filterContract || (lot.contract === filterContract || lot.backlog === true)) {
				matchRegexAndHighlightName(lot, []);
				lot.content.forEach(content => {
					recursiveApplyFilter(content, [lot]);
				});
			} else {
				const matchesFalse: any = (el: LotDataSourceContent) => {
					el.matchesFilter = false;
					el.children.forEach(matchesFalse);
				};
				lot.content.forEach(content => {
					content.matchesFilter = false;
					content.children.forEach(matchesFalse);
				});
			}
			return lot;
		};
	}

	private resetFilter(): any {
		function reset(filterable: LotDataSourceContent): void {
			filterable.matchesFilter = true;
			filterable.children.forEach(c => reset(c));
		}
		return function (element: LotDataSource): LotDataSource {
			element.matchesFilter = true;
			element.content.forEach(content => {
				content.matchesFilter = true;
				content.children.forEach(c => reset(c));
			});
			return element;
		};
	}

	private buildDataSource(project: ProjectDto): LotDataSource[] {
		const lotDataSource: LotDataSource[] = [];
		const result: DetailedLot[] = this.tableGroupByEpic(project);

		const clientMode: boolean = new ClientRolePipe().transform(this.user.role);
		for (const lot of result) {
			const lotToAdd: LotDataSource = new LotDataSource();
			lotToAdd.id = lot.id;
			lotToAdd.RAE = lot.RAE;
			if (lot.budget > 0) {
				lotToAdd.avancement = this.getAvancement(lot.budget, lot.RAE);
			} else {
				lotToAdd.avancement = '0 %';
			}
			lotToAdd.budget = lot.budget;
			lotToAdd.content = [];
			lotToAdd.name = lot.name;
			lotToAdd.contract = lot.contract;
			lotToAdd.backlog = lot.backlog;
			lotToAdd.RAE = lot.RAE;
			lotToAdd.expanded = lot.isExpend || lot.backlog; // toujours déplier le backlog
			lotToAdd.estimated = lot.estimated;
			lotToAdd.object = lot;
			lotToAdd.lotObject = lot.lotObject;
			if (isNaN(lot.storyLength)) {
				lotToAdd.nombreDeStory = 0;
			} else {
				lotToAdd.nombreDeStory = lot.storyLength;
			}
			for (const epic of lot.epics) {
				const epicToAdd: LotDataSourceContent = this._createEpicForDatasource(epic, lot.id);
				if (!lotToAdd.expanded || (this.hideEmptyEpics && epic.stories.length > 0) || !this.hideEmptyEpics) {
					lotToAdd.content.push(epicToAdd);
				}

				if (lotToAdd.backlog) {
					// expand epic so child stories will be visible, even if epic isn't.
					epicToAdd.expanded = true;
				}
				for (const story of epic.stories) {
					if (clientMode && story.type === StoryTypeEnum.INT) {
						// dans ce cas, on n’affiche pas les story INT, qui n'ont qu'un estimé
						continue;
					}
					const storyToAdd: LotDataSourceContent = this._createStoryForDatasource(story, epic, lot, 'EPIC');

					epicToAdd.children.push(storyToAdd);
					epicToAdd.totalChildrenCount++;

					lotToAdd.nombreDeStory++;
					// lotToAdd.content.push(storyToAdd);
					for (const subtask of story.subtasks) {
						if (subtask.deleted || (clientMode && (subtask.type === SubtaskTypeEnum.INT || subtask.type === SubtaskTypeEnum.SUBBUG_INT))) {
							continue;
						}
						const subtaskToAdd: LotDataSourceContent = this._createSubtaskForDatasource(subtask, story, lot.id);
						// lotToAdd.content.push(subtaskToAdd);

						storyToAdd.children.push(subtaskToAdd);
						epicToAdd.totalChildrenCount++;
					}
				}
			}
			for (const story of lot.orphanStories) {
				if (clientMode && (story.type === StoryTypeEnum.INT)) {
					// dans ce cas on affiche pas les sotry INT, qui n'ont qu'un estimé
					continue;
				}
				const storyToAdd: LotDataSourceContent = this._createStoryForDatasource(story, null, lot, 'LOT');

				lotToAdd.content.push(storyToAdd);
				lotToAdd.nombreDeStory++;
				for (const subtask of story.subtasks) {
					if (subtask.deleted) {
						continue;
					}
					const subtaskToAdd: LotDataSourceContent = this._createSubtaskForDatasource(subtask, story, lot.id);
					subtaskToAdd.visible = false;
					// lotToAdd.content.push(subtaskToAdd);

					storyToAdd.children.push(subtaskToAdd);
				}
			}

			lotDataSource.push(lotToAdd);
		}

		return lotDataSource;
	}

	private tableGroupByEpic(project: ProjectDto): DetailedLot[] {
		const detailedLots: DetailedLot[] = [];
		project.lots = project.lots || [];
		for (const lot of project.lots) {
			const lotToAdd: DetailedLot = new DetailedLot();
			lotToAdd.id = lot.id;
			lotToAdd.epics = [];
			lotToAdd.order = lot.order;
			lotToAdd.name = lot.name;
			lotToAdd.budget = lot.budget;
			lotToAdd.updateDate = lot.updateDate;
			lotToAdd.RAE = lot.remainingEXTSum;
			lotToAdd.contract = lot.contract;
			lotToAdd.backlog = lot.backlog;
			lotToAdd.isExpend = lot.isExpend;
			lotToAdd.estimated = lot.estimated;
			lotToAdd.lotObject = lot;
			lotToAdd.storyLength = lot.storyLength;
			if (lot.stories) {
				for (const story of lot.stories) {
					if (story.deleted === false) {
						if (story.epic != null) {
							const index: number = lotToAdd.epics.findIndex(epic => epic.id === story.epicId);
							// Si l'epic n'existe pas, il faut la créer
							if (index === -1) {
								const epicToAdd: EpicWithStory = new EpicWithStory();
								epicToAdd.id = story.epicId;
								epicToAdd.jiraIntId = story.epic.jiraIntId;
								epicToAdd.name = story.epic.name;
								epicToAdd.order = story.epic.order;
								epicToAdd.stories = [story];
								epicToAdd.updateDate = story.epic.updateDate;
								epicToAdd.RAE = story.remainingEXT;
								epicToAdd.budget = story.budget;
								epicToAdd.estimated = story.estimated;
								const expandedInLots: number[] = JSON.parse(story.epic.expandedInLots || '[]');
								epicToAdd.expanded = expandedInLots.indexOf(story.lotId) !== -1;

								// lotToAdd.remainingEXT += story.remainingEXT;
								lotToAdd.epics.push(epicToAdd);
							} else {
								// lotToAdd.remainingEXT += story.remainingEXT;
								lotToAdd.epics[index].budget += story.budget;
								lotToAdd.epics[index].estimated += story.estimated;
								lotToAdd.epics[index].RAE += story.remainingEXT;
								lotToAdd.epics[index].stories.push(story);
							}
						} else {
							story.orphan = true;
							story.order = 100000;
							lotToAdd.orphanStories.push(story);
						}
					}
				}
			}

			// Ajout des epic vides
			if (project.epics) {
				for (const epic of project.epics) {
					if (!epic.deleted && lotToAdd.epics.findIndex(epicA => epicA.id === epic.id) === -1) {
						const epicToAdd: EpicWithStory = new EpicWithStory();
						epicToAdd.id = epic.id;
						epicToAdd.jiraIntId = epic.jiraIntId;
						epicToAdd.name = epic.name;
						epicToAdd.order = epic.order;
						epicToAdd.stories = [];
						epicToAdd.updateDate = epic.updateDate;
						lotToAdd.epics.push(epicToAdd);
					}
				}
			}
			detailedLots.push(lotToAdd);
		}
		return this.sortTable(detailedLots);
	}

	private sortTable(table: DetailedLot[]): DetailedLot[] {
		table.sort((a: DetailedLot, b: DetailedLot) => (a.order - b.order));
		for (const lot of table) {
			lot.epics.sort((a: EpicWithStory, b: EpicWithStory) => (a.order - b.order));
			for (const epic of lot.epics) {
				epic.stories.sort((a: StoryDto, b: StoryDto) => (a.order - b.order));
				for (const story of epic.stories) {
					story.subtasks.sort((a: ISubtaskDto, b: ISubtaskDto) => (a.order - b.order));
				}
			}
		}

		return table;
	}

	private _createEpicForDatasource(epic: EpicWithStory, parentId: number): LotDataSourceContent {
		const epicToAdd: LotDataSourceContent = new LotDataSourceContent();
		epicToAdd.id = epic.id;
		epicToAdd.remainingEXT = epic.RAE;
		if (epic.budget > 0) {
			epicToAdd.avancement = this.getAvancement(epic.budget, epic.RAE);
		}
		epicToAdd.budget = epic.budget;
		epicToAdd.jiraIntId = epic.jiraIntId;
		epicToAdd.urlJiraIntId = this.configurationUrlJira.value + epic.jiraIntId;
		epicToAdd.name = epic.name;
		epicToAdd.order = epic.order;
		epicToAdd.type = IssueTypeEnum.EPIC;
		epicToAdd.typeId = 'EPIC-' + epicToAdd.id;
		epicToAdd.subType = IssueTypeEnum.STORY;
		epicToAdd.parentId = parentId;
		epicToAdd.parentTypeId = 'LOT-' + parentId;
		epicToAdd.lotId = parentId;
		epicToAdd.object = epic;
		epicToAdd.visible = true;
		epicToAdd.estimated = epic.estimated;
		epicToAdd.expanded = epic.expanded;
		return epicToAdd;
	}

	private _createStoryForDatasource(story: StoryDto,
									  epic: EpicWithStory | IEpicDto,
									  lot: DetailedLot | LotDataSource,
									  parentType: string): LotDataSourceContent {
		const storyToAdd: LotDataSourceContent = this._createBaseForDatasource(story, epic, lot.id);

		storyToAdd.isLotContratSigned = lot.contract === ContractStatusEnum.SIGNED;
		storyToAdd.budget = story.budget;
		storyToAdd.orphan = story.orphan;
		storyToAdd.expanded = story.isExpanded;
		storyToAdd.storyType = story.type;
		storyToAdd.type = IssueTypeEnum.STORY;
		storyToAdd.subType = IssueTypeEnum.SUBTASK;
		storyToAdd.remainingEXT = story.remainingEXT;
		storyToAdd.typeId = storyToAdd.type + '-' + storyToAdd.id;
		if (parentType === 'EPIC') {
			storyToAdd.parentType = IssueTypeEnum.EPIC;
		}
		storyToAdd.parentTypeId = parentType + '-' + storyToAdd.parentId;
		if (story.budget > 0) {
			storyToAdd.avancement = this.getAvancement(story.budget, story.remainingEXT);
		}
		const status: string = UtilsService.getEnumKeyByValue(JiraStatusEnum, story.status);
		if (status) {
			storyToAdd.status = {
				key: status,
				value: status.replace('_', ' ')
			};
		}
		return storyToAdd;
	}

	private _createSubtaskForDatasource(subtask: ISubtaskDto, story: IStoryDto, lotId: number): LotDataSourceContent {
		const subtaskToAdd: LotDataSourceContent = this._createBaseForDatasource(subtask, story, lotId);
		subtaskToAdd.avancement = '';
		subtaskToAdd.type = IssueTypeEnum.SUBTASK;
		subtaskToAdd.subtaskType = subtask.type;

		subtaskToAdd.typeId = 'SUBTASK-' + subtaskToAdd.id;
		subtaskToAdd.parentType = IssueTypeEnum.STORY;
		subtaskToAdd.parentTypeId = 'STORY-' + subtaskToAdd.parentId;
		return subtaskToAdd;
	}

	private _createBaseForDatasource(element: IStoryDto | ISubtaskDto,
									 parent: IStoryDto | EpicWithStory | IEpicDto,
									 lotId: number): LotDataSourceContent {
		const elementToAdd: LotDataSourceContent = new LotDataSourceContent();
		elementToAdd.id = element.id;
		elementToAdd.jiraExtId = element.jiraExtId;
		elementToAdd.urlJiraExtId = this.configurationUrlJira.value + element.jiraExtId;
		elementToAdd.jiraIntId = element.jiraIntId;
		elementToAdd.urlJiraIntId = this.configurationUrlJira.value + element.jiraIntId;
		elementToAdd.name = element.name;
		elementToAdd.lotId = lotId;
		elementToAdd.order = element.order;
		elementToAdd.estimated = element.estimated;
		elementToAdd.visible = true;
		if (parent) {
			elementToAdd.parentId = parent.id;
		}
		elementToAdd.object = element;
		return elementToAdd;
	}

	getAvancement(budget: number, remainingEXT: number): string {
		return budget <= 0 ? '0 %' : Math.round((1 - remainingEXT / budget) * 100).toString() + ' %';
	}
}
