import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {IRepartitionDto} from '../repartition/repartition.dto';
import {Observable} from 'rxjs';
import {ISousRepartitionDto} from './sous-repartition.dto';

@Injectable({
	providedIn: 'root'
})
export class SousRepartitionService extends ApiService {

	private route: string = '/api/av-sous-repartition';

	constructor(public http: HttpClient) {
		super(http);
	}

	findByRepartitionWithRepartitionAndSousCategorie(repartitionDto: IRepartitionDto): Observable<ISousRepartitionDto[]> {
		return this.get(this.route + '/findByRepartitionWithRepartitionAndSousCategorie/' + repartitionDto.id);
	}

	findByRepartitionWithRepartitionAndChargeAnnexe(repartitionDto: IRepartitionDto): Observable<ISousRepartitionDto[]> {
		return this.get(this.route + '/findByRepartitionWithRepartitionAndChargeAnnexe/' + repartitionDto.id);
	}

}
