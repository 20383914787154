<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" (cdkDropListDropped)="drop($event)" cdkDropList>

	<!-- Categorie -->
	<mat-tree-node [cdkDragData]="node" [cdkDragDisabled]="node.isBeingEdited"
				   [class.keybordSelectedLot]="node.isKeybordSelected"
				   (click)="selectedKeyboardSelectedNode(node)"
				   *matTreeNodeDef="let node; when: hasChild" cdkDrag
				   class="node-categorie">
		<!-- (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
				   (cdkDragReleased)="dragEnd()"-->
		<app-chiffrage-categorie [categorie]="node.item" [chiffrage]="chiffrage"
								 [isExpanded]="treeControl.isExpanded(node)"
								 [lastChiffrage]="lastChiffrage"
								 [node]="node"
								 [repartitions]="repartitions"
								 (clearNewCategorieForm)="clearFormCategorie(node)"
								 (needNewSousCategorieForm)="addFormNewSousCategorie(node)"
								 (removeCategorie)="deleteCategorie(node)"
								 (saveNewCategorieForm)="saveFormCategorie(node)">
		</app-chiffrage-categorie>
	</mat-tree-node>

	<!-- Sous categorie -->
	<mat-tree-node [cdkDragData]="node" [cdkDragDisabled]="node.isBeingEdited"
				   [class.bg-grey-extralight-2]="node.item.order%2==0" [class.keybordSelected]="node.isKeybordSelected"
				   (click)="selectedKeyboardSelectedNode(node)" *matTreeNodeDef="let node"
				   cdkDrag class="node-sous-categorie">
		<!-- (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
				   (cdkDragReleased)="dragEnd()" -->
		<app-chiffrage-sous-categorie [chiffrage]="chiffrage" [lastChiffrage]="lastChiffrage"
									  [node]="node"
									  [sousCategorie]="node.item"
									  (clearNewSousCategorieForm)="clearFormSousCategorie(node)"
									  (removeSousCategorie)="deleteSousCategorie(node)"
									  (saveNewSousCategorieForm)="saveFormSousCategorie(node)">
		</app-chiffrage-sous-categorie>
	</mat-tree-node>

	<!-- Création d'une nouvelle catégorie/sousCategorie -->
	<mat-tree-node [class.keybordSelectedLot]="node.isKeybordSelected"
				   [class.node-categorie]="getParentNode(node) == null"
				   [class.node-sous-categorie]="getParentNode(node) != null"
				   *matTreeNodeDef="let node; when: hasNoContent">

		<div *ngIf="getParentNode(node) == null; then categorie else souscategorie"></div>
		<ng-template #categorie>
			<div class="d-flex justify-content-start">
				<app-chiffrage-categorie [categorie]="node.item" [chiffrage]="chiffrage" [node]="node"
										 [repartitions]="repartitions"
										 [stateItemNode]="'EDIT'"
										 (clearNewCategorieForm)="clearFormCategorie(node)"
										 (needNewSousCategorieForm)="addFormNewSousCategorie(node)"
										 (saveNewCategorieForm)="saveFormCategorie(node)">
				</app-chiffrage-categorie>
			</div>
		</ng-template>
		<ng-template #souscategorie>
			<div class="d-flex justify-content-start">
				<app-chiffrage-sous-categorie [chiffrage]="chiffrage" [node]="node" [repartitions]="repartitions"
											  [sousCategorie]="node.item"
											  [stateItemNode]="'EDIT'"
											  (clearNewSousCategorieForm)="clearFormSousCategorie(node)"
											  (saveNewSousCategorieForm)="saveFormSousCategorie(node)">
				</app-chiffrage-sous-categorie>
			</div>
		</ng-template>
	</mat-tree-node>
</mat-tree>

