import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {ISousCategorieDto} from '../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';
import {ISousRepartitionDto} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {IChargeAnnexeDto} from '../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';
import {MatSelect, MatSelectChange} from '@angular/material/select';

@Component({
	selector: 'app-form-field-pourcentage',
	templateUrl: './form-field-pourcentage.component.html',
	styleUrls: ['./form-field-pourcentage.component.scss'],
})
export class FormFieldPourcentageComponent implements AfterViewInit, OnInit, OnChanges {

	@Input() parent: ISousCategorieDto | IChargeAnnexeDto;
	@Input() allSousRepartitions: ISousRepartitionDto[];
	@Input() sousRepartition: ISousRepartitionDto;
	@Input() saveForm: () => void;

	@Output() updatedSousRepartition: EventEmitter<ISousRepartitionDto> = new EventEmitter<ISousRepartitionDto>();

	@ViewChild('selectPourcentage') selectPourcentage: MatSelect;

	pourcentage: number;

	constructor() {
	}


	ngOnInit(): void {
		if (this.sousRepartition) {
			if (this.sousRepartition.charge === null || this.sousRepartition.charge === undefined) {
				this.pourcentage = 0;
			} else if (this.sousRepartition.charge === 0) {
				this.pourcentage = 0;
			} else if (this.sousRepartition.chargePercentage) {
				this.pourcentage = this.sousRepartition.chargePercentage;
			} else {
				this.pourcentage = this.sousRepartition.charge * 100 / +this.parent.charge;
			}
		}
	}

	ngAfterViewInit(): void {

		this.selectPourcentage.selectionChange.subscribe((matSelectChange: MatSelectChange) => {
			this.updatedSousRepartitionCharge(matSelectChange.value);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
	}

	private updatedSousRepartitionCharge(pourcentage: number): void {

		if (pourcentage === 0) {
			this.sousRepartition.charge = 0;
		} else {
			this.sousRepartition.charge = +this.parent.charge * pourcentage / 100;
		}
		this.sousRepartition.chargePercentage = pourcentage;
		this.updatedSousRepartition.emit(this.sousRepartition);
	}
}
