<div class="d-flex flex-row ogdp-paginator-container">
	<div style="flex: 1; padding-left: 10px;">
		<b>{{length}}</b> Résulats
	</div>
	<div class="d-flex">
		<div class="its-paginator d-flex justify-content-center">
			<button [class.cursor-pointer]="linkMatPaginator?.hasPreviousPage()"
					[disabled]="!linkMatPaginator?.hasPreviousPage()" class="reset-button">
				<mat-icon [class.previous-disabled]="!linkMatPaginator?.hasPreviousPage()" (click)="previousPage()"
						  class="previous">arrow_back</mat-icon>
			</button>

			<div class="range-label">
				<div (click)="firstPage()" *ngIf="firstIndex && selectedIndex.pageIndex > 2" class="firstIndex">
					<div class="cursor-pointer">{{firstIndex.value}}</div>
					<div>...</div>
				</div>

				<div class="centralIndex">
					<div [class.cursor-pointer]="!pageIndex.isSelected" [class.index-selected]="pageIndex.isSelected"
						 (click)="goToPage(pageIndex)" *ngFor="let pageIndex of centralNumbers">
						{{pageIndex.value}}
					</div>
				</div>

				<div (click)="lastPage()" *ngIf="lastIndex && selectedIndex.pageIndex < (nbPages-2)"
					 class="lastIndex">
					<div>...</div>
					<div class="cursor-pointer">{{lastIndex.value}}</div>
				</div>
			</div>

			<button [class.cursor-pointer]="linkMatPaginator?.hasNextPage()" [disabled]="!linkMatPaginator?.hasNextPage()"
					class="reset-button">
				<mat-icon [class.next-disabled]="!linkMatPaginator?.hasNextPage()" (click)="nextPage()" class="next">
					arrow_forward
				</mat-icon>
			</button>

		</div>

		<div class="filter-page-size">
			<mat-select [(ngModel)]="pageSize" [hideSingleSelectionIndicator]="true"
						(selectionChange)="pageSizeChanged($event)" class="select-page-size">
				<mat-option [value]="option" *ngFor="let option of pageSizeOptions" class="small-option">{{option}}</mat-option>
			</mat-select>
		</div>
	</div>
	<div style="flex: 1;">

	</div>
</div>
