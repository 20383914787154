<mat-form-field class="input-form-charge">
	<mat-label>Pourcentage</mat-label>
	<mat-select [(ngModel)]="pourcentage" [hideSingleSelectionIndicator]="true"
				(keydown.enter)="$event.preventDefault(); saveForm()" #selectPourcentage class="text-center">
		<mat-option [value]="100">100 %</mat-option>
		<mat-option [value]="0">0 %</mat-option>
		<mat-option [value]="item" *ngFor="let item of [90, 80, 70, 60, 50, 40, 30, 20, 10 ]">
			{{item}} %
		</mat-option>
	</mat-select>
</mat-form-field>
