import {AfterViewInit, Component, EventEmitter, Input, isDevMode, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../../core/pagination/dto/pagination-option.dto';
import {DataSourceTable, ProjetDataSource} from './datasource/projet-datasource';
import {catchError, debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {first, firstValueFrom, forkJoin, merge, throwError} from 'rxjs';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import GestionProjectFilter from './datasource/gestion-project-filter';
import {ClientService} from '../../core/business/service/client/client.service';
import {IClientDto} from '../../core/business/service/client/client.dto';
import {ProjectService} from '../../core/business/service/project/project.service';
import {IProjectDto, ProjectDto, ProjectStatusEnum} from '../../core/business/service/project/project.dto';
import {ProjectAlertService} from '../../core/business/service/project-alert/project-alert.service';
import {DialogProjectAlertDisplayComponent} from '../../project-alert/component/dialog-project-alert-display/dialog-project-alert-display.component';
import {OgdpPaginatorComponent} from '../../utils/components/paginator/ogdp-paginator.component';
import {SpinnerService} from '../../core/service/spinner.service';
import {ConfigurationService} from '../../core/business/service/configuration/configuration.service';
import {ClientSelectComponent} from '../../client/component/client-select/client-select.component';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {IUserBaseDto} from '../../core/business/service/user-base/user-base.dto';
import {FormControl} from '@angular/forms';
import {UtilsService} from '../../utils/service/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {ICompanyDto} from '../../core/business/service/company/company.dto';
import {CompanyService} from '../../core/business/service/company/company.service';
import {DialogValidationComponent} from '../../utils/components/dialog-validation/dialog-validation.component';
import {TranslateService} from '@ngx-translate/core';
import {DialogObfuscateProgressComponent} from '../component/obfuscate-progress/dialog-obfuscate-progress.component';
import {ExportBddService} from '../../core/business/service/export-bdd/export-bdd.service';

@Component({
	selector: 'app-projet',
	templateUrl: './projet.component.html',
	styleUrls: ['./projet.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class ProjetComponent extends UserInfo implements AfterViewInit, OnInit {

	displayedColumns: string[] = ['informations', 'estimated', 'percentage', 'void'];
	expandedElement: DataSourceTable[] | null = [];
	dataSourcePaginate: ProjetDataSource;

	gestionProjectFilter: GestionProjectFilter;
	project: IProjectDto;
	clients: IClientDto[];
	companies: ICompanyDto[];
	users: IUserBaseDto[] = [];
	cpId: number;
	isDevelopmentMode: boolean = isDevMode();

	public selectCpController: FormControl = new FormControl();
	public cpFiltered: IUserBaseDto[] = [];

	private _sortCps: IUserBaseDto[] = [];

	@Input() cp: IUserBaseDto;
	@Output() cpChange: EventEmitter<IUserBaseDto> = new EventEmitter<IUserBaseDto>();

	@Input() set cps(cps: IUserBaseDto[]) {
		if (cps) {
			this._sortCps = cps.sort(UtilsService.dynamicMultiSort('name'));
			this.cpFiltered = this._sortCps.slice();
			if (this.cp) {
				this.selectCpController.patchValue(this.cp);
			}
		}
	}

	projectStatusEnumKeys: string[] = Object.keys(ProjectStatusEnum);

	sortByOption: PaginationSortBy[];

	paginationOption: PaginationOption;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchInput: SearchbarComponent;
	@ViewChild('filterStatus') filterStatus: MatSelect;
	@ViewChild(ClientSelectComponent) filterClient: ClientSelectComponent;
	@ViewChild('selectCompanies') selectCompanies: MatSelect;
	@ViewChild('sortBy') sortBy: MatSelect;

	constructor(public dialog: MatDialog,
				public override keycloak: KeycloakService,
				private _projetService: ProjectService,
				private _userBaseService: UserBaseService,
				private _clientService: ClientService,
				private _companyService: CompanyService,
				private _projectAlertService: ProjectAlertService,
				private _configurationService: ConfigurationService,
				private _spinnerService: SpinnerService,
				private _router: Router,
				private _snackBar: MatSnackBar,
				private _titleService: Title,
				private _translator: TranslateService,
				private _exportBddService: ExportBddService) {
		super(keycloak);

		this.paginationOption = new PaginationOption(20, 0);
		this.gestionProjectFilter = new GestionProjectFilter();

		this.gestionProjectFilter.projectStatus.push(ProjectStatusEnum.OPEN);

		this.sortByOption = [];
		this.sortByOption['creationDateAsc'] = new PaginationSortBy('creationDate', PaginationSort.ASC);
		this.sortByOption['creationDateDesc'] = new PaginationSortBy('creationDate', PaginationSort.DESC);
		this.sortByOption['alphabetiqueOrderAsc'] = new PaginationSortBy('name', PaginationSort.ASC);
		this.sortByOption['budgetDesc'] = new PaginationSortBy('budget', PaginationSort.DESC);
		this.paginationOption.sortBy = this.sortByOption['alphabetiqueOrderAsc'];

		this.dataSourcePaginate = new ProjetDataSource(this._projetService, this._projectAlertService,
			this._configurationService.findOnCacheByKey('JIRA_URL_BROWSER').value, this._spinnerService);
	}

	async ngOnInit(): Promise<void> {
		forkJoin([
			this._userBaseService.getAllOGDPUsersWithJiraUserAndAvatar().pipe(first(), map((res) => this.users = res)),
			this._clientService.getAllClient().pipe(first(), map((res) => this.clients = res)),
			this._companyService.getActiveCompanies().pipe(first(), map((res) => this.companies = res)),
		]).subscribe(([users, clients, companies]) => {
			this.users.sort((a, b) => a.firstName.localeCompare(b.firstName));
			this.cpFiltered = this.users;
			this._sortCps = this.users;
		});
		this.loadProjects();
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Gestion');
	}

	ngAfterViewInit(): void {
		this.selectCpController.valueChanges
			.pipe(
				debounceTime(250),
				distinctUntilChanged())
			.subscribe((cp: any) => {
				if (typeof cp === 'string') { // on est en mode recherche
					if (cp) {
						this.cpFiltered = this.cpFilter(cp);
					} else {
						// le filtre de recherche est vide,
						this.cp = null;
						this.cpChange.emit(null);
						this.cpFiltered = this._sortCps.slice();
					}
				} else if (cp) { // on a sélectionné un client
					this.cp = cp ? cp : null;
					this.cpChange.emit(cp);
					this.cpFiltered = this._sortCps.slice();
					this.selectCP(cp ? cp.id : null);
				}
			});
		merge(this.filterStatus.selectionChange, this.filterClient.clientChange, this.selectCompanies.selectionChange,
			this.sortBy.selectionChange, this.searchInput.valueChange, this.searchInput.clearInput).pipe(
				debounceTime(500),
				distinctUntilChanged())
		.pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadProjects();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadProjects();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	syncWithMdm(): void {
		this._spinnerService.enableLoading();
		this._projetService.syncProjects().pipe(
			catchError(() => {
				this._spinnerService.disableLoading();
				return throwError(() => new Error('Erreur lors de la synchronisation des Projets'));
			})
		).subscribe(() => {
			this.loadProjects();
			this._spinnerService.disableLoading();
			this._snackBar.open('Synchronisation réussie', '', {
				panelClass: 'success',
				duration: 2000,
			});
		});
	}

	clearCpFilter(): void {
		this.selectCP(null);
		this.cp = null;
		this.cpChange.emit(null);
		this.selectCpController.patchValue(null);
	}

	cpFilter(value: string): IUserBaseDto[] {
		const filterValue: string = value.toLowerCase();
		return this._sortCps.filter(cp => (cp.firstName + ' ' + cp.lastName).toLowerCase().indexOf(filterValue) !== -1);
	}

	displaySelectedCp(cp: IUserBaseDto): string {
		return cp ? cp.firstName + ' ' + cp.lastName : '';
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.loadProjects();
	}

	addOrDelete(row: DataSourceTable): void {
		const index: number = this.expandedElement.indexOf(row);
		if (index === -1) {
			this.expandedElement.push(row);
		} else {
			this.expandedElement.splice(index, 1);
		}
	}

	async deleteProject(id: number): Promise<void> {
		if (confirm('Supprimer le projet ?')) {
			await this._projetService.removeProject(id);
		}

		return this.loadProjects();
	}

	clearFilterField(): void {
		this.gestionProjectFilter.projectName = '';
	}

	openDialogProjectAlert(projectId: number): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '900px';
		dialogConfig.data = {
			projectAlerts: this._projectAlertService.findByProject(projectId),
			projectId: projectId
		};

		const dialogRef: MatDialogRef<DialogProjectAlertDisplayComponent> = this.dialog.open(DialogProjectAlertDisplayComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(deleteEvent => {
			if (deleteEvent) {
				this.ngOnInit();
			}
		});
	}

	selectCP(id: number): void {
		this.setCp(id);
		this.paginationOption.page = 0;
		this.loadProjects();
	}

	setCp(val: number): void {
		this.cpId = val;
		this.gestionProjectFilter.cpId = val;
	}

	async obfuscateEveryProjectDialog(): Promise<void> {
		this._spinnerService.enableLoading();
		this.dialog.open(DialogValidationComponent, {
			data: {
				title: this._translator.instant('APP.CONFIRMATION'),
				message: this._translator.instant('APP.OBFUSCATE.WARNING'),
				showYesButton: true,
				noText: this._translator.instant('APP.NO'),
				yesText: this._translator.instant('APP.YES'),
				warn: true,
			},
		}).afterClosed().subscribe(async value => {
			if (value) {
				const progressDialog: MatDialogRef<DialogObfuscateProgressComponent, any> =
					this.dialog.open(DialogObfuscateProgressComponent, { disableClose: true, width: '500px', height: '300px' });
				await this._exportBddService.obfuscateEveryProject();
				progressDialog.close();
			}
			this._spinnerService.disableLoading();
		});
	}

	private loadProjects(): void {
		this.dataSourcePaginate.findByFiltersPaging(this.gestionProjectFilter, this.paginationOption);
	}
}
