import {AbstractPaginateDatasource} from '../../core/pagination/abstract-paginate.datasource';
import {PaginationOption} from '../../core/pagination/dto/pagination-option.dto';
import {Pagination} from '../../core/pagination/dto/pagination.dto';
import {IProjectAlertDto} from '../../core/business/service/project-alert/project-alert.dto';
import {ProjectAlertService} from '../../core/business/service/project-alert/project-alert.service';
import {ProjectAlertFilter} from '../dto/project-alert-filter.dto';
import {SpinnerService} from '../../core/service/spinner.service';

export class ProjectAlertDatasource extends AbstractPaginateDatasource<IProjectAlertDto> {

	constructor(private projectAlertService: ProjectAlertService, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	findAllPaging(paginationOption: PaginationOption): void {
		this.notifyPagingData(this.projectAlertService.findAllPaging(paginationOption));
	}

	findFilterPaging(projectAlertFilter: ProjectAlertFilter, paginationOption: PaginationOption): void {
		this.notifyPagingData(
			this.projectAlertService.findFilterPaging(projectAlertFilter, paginationOption));
	}

	transformResults(results: Pagination<IProjectAlertDto>): IProjectAlertDto[] {
		return results.results;
	}
}
