import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../service/api.service';
import {IConfigurationDto} from './configuration.dto';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService extends ApiService {

	private route: string = '/api/configuration';
	private cacheConfig: IConfigurationDto[];

	constructor(public http: HttpClient) {
		super(http);
	}

	create(configuration: IConfigurationDto): Promise<{ message: string }> {
		return firstValueFrom(this.http.post<{ message: string }>(this.route, configuration));
	}

	modifyConfiguration(configuration: IConfigurationDto, id: number): Promise<{ message: string }> {
		return firstValueFrom(this.http.put<{ message: string }>(this.route + '/' + id, configuration));
	}

	findOne(id: number): Promise<IConfigurationDto> {
		return firstValueFrom(this.http.get<IConfigurationDto>(this.route + '/' + id));
	}

	findAll(): Promise<IConfigurationDto[]> {
		return firstValueFrom(this.http.get<IConfigurationDto[]>(this.route));
	}

	findByKey(key: string): Promise<IConfigurationDto> {
		return firstValueFrom(this.http.get<IConfigurationDto>(this.route + '/' + key));
	}

	findOnCacheByKey(key: string): IConfigurationDto {
		return this.cacheConfig.find(value => value.key === key);
	}

	public loadConfigOnCache(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.get(this.route).subscribe((response: any) => {
				this.cacheConfig = response;
				resolve(true);
			});
		});
	}
}
