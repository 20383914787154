import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class SpinnerService implements OnDestroy {


	protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isLoading: Observable<boolean> = this.loadingSubject.asObservable();

	constructor() {
		this.loadingSubject.next(false);
	}

	ngOnDestroy(): void {
		this.loadingSubject.complete();
	}

	enableLoading(): void {
		this.loadingSubject.next(true);
	}

	disableLoading(): void {
		this.loadingSubject.next(false);
	}
}
