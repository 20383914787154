import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {MyAccountComponent} from './main-layout/my-account/my-account.component';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {UtilModule} from '../utils/util.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RolePipe} from '../security/pipe/role.pipe';

@NgModule({
	declarations: [MainLayoutComponent, MyAccountComponent],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		DialogPopupModule,
		MatSidenavModule,
		MatDialogModule,
		MatButtonModule,
		MatInputModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatTooltipModule,
		UtilModule,
		RolePipe,
	],
})
export class LayoutModule {
}
