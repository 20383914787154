import {Pagination} from '../../../core/pagination/dto/pagination.dto';
import {ISPHeader, ISPHeaderMonth, ISPHeaderPeriod, SuiviListFilter} from '../dto/suivi-list.dto';
import {PaginationOption} from '../../../core/pagination/dto/pagination-option.dto';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import * as moment from 'moment';
import {UtilsService} from '../../../utils/service/utils.service';
import {catchError, finalize, map} from 'rxjs/operators';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {ReportService} from '../../../core/business/service/report/report.service';
import {IPeriodDto} from '../../../core/business/service/report/period/period.dto';
import {
	IConsumedTimeDto,
	IPeriodReportDto
} from '../../../core/business/service/report/period-report/period-report.dto';
import {IProjectDto, ProjectStatusEnum} from '../../../core/business/service/project/project.dto';
import {ProjectService} from '../../../core/business/service/project/project.service';
import {SpinnerService} from '../../../core/service/spinner.service';
import {ProjectAlertService} from '../../../core/business/service/project-alert/project-alert.service';
import {
	INumberAlertsDto,
	IProjectNumberAlertsDto
} from '../../../core/business/service/project-alert/project-alert.dto';
import {UserBaseDto} from '../../../core/business/service/user-base/user-base.dto';
import {AdminRolePipe} from '../../../security/pipe/role.pipe';
import {ICompanyDto} from '../../../core/business/service/company/company.dto';

export class DsProject {
	id: number;
	name: string;
	jiraIntId: string;
	urlJiraIntId: string;
	jiraExtId: string;
	urlJiraExtId: string;
	budget: number;
	description: string;
	spentSum: number;
	remainingEXTSum: number;
	status: ProjectStatusEnum;
	client: IClientDto;
	company: ICompanyDto;
	periodReports: DsPeriodReport[];
	numberAlerts: INumberAlertsDto[];
}

export class DsPeriodReport {
	id: number;
	validatedCP: boolean = false;
	validatedAdmin: boolean = false;
	spentSum: number;
	difference: number;
	productionSum: number;
	totalMonth: boolean = false;
	totalPeriod: boolean = false;
	totalProject: boolean = false;
}

export class SuiviDataSource {

	private dataDbProject: BehaviorSubject<Pagination<IProjectDto>>;
	private dataDbConsumeTime: BehaviorSubject<IConsumedTimeDto[]>;
	private dataDbProjectAlerts: BehaviorSubject<IProjectNumberAlertsDto[]>;

	dataHeader: BehaviorSubject<ISPHeader>;
	dataProject: BehaviorSubject<DsProject[]>;
	defaultNbDisplayMonth: number;
	displayTotalPeriod: boolean;

	constructor(private projectService: ProjectService, private reportService: ReportService, private projectAlertService: ProjectAlertService,
				private user: UserBaseDto, private configJiraUrlBrowser: string, private spinnerService: SpinnerService) {

		this.dataDbProject = new BehaviorSubject<Pagination<IProjectDto>>(new Pagination([], 0));
		this.dataDbConsumeTime = new BehaviorSubject<IConsumedTimeDto[]>([]);
		this.dataDbProjectAlerts = new BehaviorSubject<IProjectNumberAlertsDto[]>([]);
		this.dataHeader = new BehaviorSubject<ISPHeader>(<ISPHeader>{
			months: [],
			totalPeriod: {},
			totalProjects: {}
		});
		this.dataProject = new BehaviorSubject<DsProject[]>([]);

		this.defaultNbDisplayMonth = 2;
		this.displayTotalPeriod = false;
	}

	loadDataHeader(periodStart: moment.Moment, periodEnd: moment.Moment, filter: SuiviListFilter): void {
		const dataDbHeader: Observable<IPeriodDto[]> = this.reportService.findByPeriodBetween(
			periodStart.startOf('month').toDate(),
			periodEnd.endOf('month').toDate(), filter);
		const obsConsumeTime: Observable<IConsumedTimeDto> = this.reportService.consumedTime(filter);

		combineLatest([dataDbHeader, obsConsumeTime]).pipe(
			catchError(() => of([])),
			map(([periods, consumeTime]) => {
				this.dataHeader.next(this.generateHeaderForHtmlTable(periods, consumeTime));
		})).subscribe();
	}

	loadDataProject(periodStart: moment.Moment, periodEnd: moment.Moment, filter: SuiviListFilter, paginationOption: PaginationOption): void {
		this.spinnerService.enableLoading();

		const dataDbProject: Observable<Pagination<IProjectDto>> =
			this.getProjectWithPeriod(periodStart, periodEnd, filter, paginationOption);

		dataDbProject.pipe(
			catchError(() => of([])),
			finalize(() => {
				this.spinnerService.disableLoading();
			})
		).subscribe((paginateProjects: Pagination<IProjectDto>) => {
			const projectIds: number[] = paginateProjects.results.map(value => value.id);
			const obsConsumeTime: Observable<IConsumedTimeDto[]> =
				this.reportService.consumedTimeByProject(projectIds);
			const obsProjectAlerts: Observable<IProjectNumberAlertsDto[]>
				= this.projectAlertService.findNbAlertsByProjects(projectIds);

			combineLatest([obsConsumeTime, obsProjectAlerts]).pipe(map(([consumeTimeByProject, projectAlerts]) => {
				this.dataDbProject.next(paginateProjects);
				this.dataDbConsumeTime.next(consumeTimeByProject);
				this.dataDbProjectAlerts.next(projectAlerts);
				this.dataProject.next(this.generateBodyForHtmlTable(paginateProjects, consumeTimeByProject, projectAlerts));
			})).subscribe();
		});
	}

	rebuildHtmlTableWihtoutDbAccess(): void {
		this.dataHeader.getValue().months.forEach(value => value.colspan = value.isMonthExpend ? value.periods.length + 1 : 1);
		this.defaultNbDisplayMonth = this.dataHeader.getValue().months.filter(value => value.colspan > 1).length;
		this.dataHeader.next(this.dataHeader.getValue());
		this.dataProject.next(this.generateBodyForHtmlTable(this.dataDbProject.getValue(),
			this.dataDbConsumeTime.getValue(), this.dataDbProjectAlerts.getValue()));
	}

	length(): number {
		return this.dataDbProject.getValue().total;
	}

	private getProjectWithPeriod(periodStart: moment.Moment, periodEnd: moment.Moment,
								 filter: SuiviListFilter, paginationOption: PaginationOption): Observable<Pagination<IProjectDto>> {
		return this.projectService.findAllByPeriodPaging(periodStart.startOf('month').toDate(),
			periodEnd.endOf('month').toDate(), filter, paginationOption);
	}

	private generateHeaderForHtmlTable(results: IPeriodDto[], consumeTime: IConsumedTimeDto): ISPHeader {
		const headerInfos: ISPHeader = <ISPHeader>{
			months: [],
		};
		for (const onePeriod of results) {
			// Génération du ISPHeaderMonth par rapport au mois et à l'année de la période
			const dateBeginMoment: moment.Moment = moment(onePeriod.dateBegin);
			const mois: number = dateBeginMoment.get('month') + 1;
			const annee: number = dateBeginMoment.get('year');
			// Cherche dans l'array en cache
			let headerMonth: ISPHeaderMonth = headerInfos.months.find(value => value.annee === annee && value.mois === mois);
			// Si on ne trouve rien, on créé une nouvelle entrée
			if (!headerMonth) {
				headerMonth = <ISPHeaderMonth>{
					mois,
					annee,
					date: dateBeginMoment.toDate(),
					periods: [],
					totalMonth: <IPeriodReportDto>{
						spentSum: 0,
						productionSum: 0
					},
					colspan: 0,
					isMonthExpend: false
				};
				headerInfos.months.push(headerMonth);
			}
			headerMonth.totalMonth.spentSum += onePeriod.periodReports[0].spentSum;
			headerMonth.totalMonth.productionSum += onePeriod.periodReports[0].productionSum;
			// Ajout de la période au mois
			const period: ISPHeaderPeriod = <ISPHeaderPeriod>{
				period: onePeriod,
				dateBegin: moment(onePeriod.dateBegin).toDate()
			};
			// Ajout de la période au mois
			headerMonth.periods.push(period);
		}

		// Ajout des totaux
		headerInfos.totalPeriod = <IPeriodReportDto>{
			spentSum: 0,
			productionSum: 0
		};
		headerInfos.totalProjects = <IPeriodReportDto>{
			spentSum: consumeTime.spentSum,
			productionSum: consumeTime.productionSum,
			difference: consumeTime.productionSum - consumeTime.spentSum
		};

		headerInfos.months.sort(UtilsService.dynamicMultiSort('annee', 'mois'));
		headerInfos.months.slice(headerInfos.months.length - this.defaultNbDisplayMonth, headerInfos.months.length)
			.map(value => value.isMonthExpend = true);
		headerInfos.months.forEach(month => {
			month.periods.sort(UtilsService.dynamicMultiSort('period.dateBegin', 'order'));
			month.colspan = month.isMonthExpend ? month.periods.length + 1 : 1;
			headerInfos.totalPeriod.spentSum += month.totalMonth.spentSum;
			headerInfos.totalPeriod.productionSum += month.totalMonth.productionSum;
		});

		return headerInfos;
	}

	private generateBodyForHtmlTable(projects: Pagination<IProjectDto>, consumeTime: IConsumedTimeDto[],
									 projectAlerts: IProjectNumberAlertsDto[]): DsProject[] {

		const rowDataArray: DsProject[] = [];
		for (const project of projects.results) {
			const dsProject: DsProject = new DsProject();
			dsProject.periodReports = [];
			dsProject.id = project.id;
			dsProject.name = project.name;
			dsProject.jiraExtId = project.jiraExtId;
			dsProject.urlJiraExtId = this.configJiraUrlBrowser + project.jiraExtId;
			dsProject.jiraIntId = project.jiraIntId;
			dsProject.urlJiraIntId = this.configJiraUrlBrowser + project.jiraIntId;
			dsProject.budget = project.budget;
			dsProject.remainingEXTSum = project.remainingEXTSum;
			dsProject.spentSum = project.spentSum;
			dsProject.status = project.status;
			dsProject.client = project.client;
			dsProject.company = project.company;

			const alerts: IProjectNumberAlertsDto = projectAlerts.find(value => value.projectId === project.id);
			if (alerts) {
				dsProject.numberAlerts = alerts.nbAlerts;
			}

			for (const month of this.dataHeader.getValue().months) {
				const idPeriodsForThisMonth: number[] = month.periods.map(period => period.period.id);
				const projectReportForThisMonth: IPeriodReportDto[] =
					(project.period_report || []).filter(projectReport => idPeriodsForThisMonth.includes(projectReport.periodId));

				if (month.isMonthExpend) {
					// Création d'une cellule du tableau par période
					for (const period of month.periods) {
						const projectReport: IPeriodReportDto = projectReportForThisMonth.find(value => value.periodId === period.period.id);
						if (projectReport) {
							const dsPeriodReport: DsPeriodReport = new DsPeriodReport();
							dsPeriodReport.id = projectReport.id;
							dsPeriodReport.validatedCP = projectReport.validatedCP;
							dsPeriodReport.validatedAdmin = projectReport.validatedAdmin;
							dsPeriodReport.spentSum = projectReport.spentSum;
							dsPeriodReport.difference = projectReport.productionSum - projectReport.spentSum;
							dsPeriodReport.productionSum = projectReport.productionSum;
							dsProject.periodReports.push(dsPeriodReport);
						} else {
							// période non contigue à l'interieur du mois, on crée une cellule vide
							const rowCells: DsPeriodReport = new DsPeriodReport();
							dsProject.periodReports.push(rowCells);
						}
					}
				}
				const dsPeriodReportSumMonth: DsPeriodReport = new DsPeriodReport();
				dsPeriodReportSumMonth.spentSum = projectReportForThisMonth.reduce(
					(accumulateur, valeurCourante) => accumulateur + valeurCourante.spentSum, 0);
				dsPeriodReportSumMonth.productionSum = projectReportForThisMonth.reduce(
					(accumulateur, valeurCourante) => accumulateur + valeurCourante.productionSum, 0);
				dsPeriodReportSumMonth.difference = dsPeriodReportSumMonth.productionSum - dsPeriodReportSumMonth.spentSum;
				dsPeriodReportSumMonth.totalMonth = true;
				dsProject.periodReports.push(dsPeriodReportSumMonth);
			}

			// calcul du total periode
			if (this.displayTotalPeriod) {
				const dsPeriodReportSumPeriod: DsPeriodReport = new DsPeriodReport();
				dsPeriodReportSumPeriod.spentSum = (project.period_report || []).reduce(
					(accumulateur, valeurCourante) => accumulateur + valeurCourante.spentSum, 0);
				dsPeriodReportSumPeriod.productionSum = (project.period_report || []).reduce(
					(accumulateur, valeurCourante) => accumulateur + valeurCourante.productionSum, 0);
				dsPeriodReportSumPeriod.difference = dsPeriodReportSumPeriod.productionSum - dsPeriodReportSumPeriod.spentSum;
				dsPeriodReportSumPeriod.totalPeriod = true;
				dsProject.periodReports.push(dsPeriodReportSumPeriod);
			}

			dsProject.periodReports.push(new DsPeriodReport()); // glitch pour l'affichage

			// calcul du total projet
			const consumedTimeProject: IConsumedTimeDto = consumeTime.find(value => value.id === project.id);
			if (consumedTimeProject) {
				const dsPeriodReportSumProject: DsPeriodReport = new DsPeriodReport();
				dsPeriodReportSumProject.spentSum = consumedTimeProject.spentSum;
				dsPeriodReportSumProject.difference = consumedTimeProject.productionSum - consumedTimeProject.spentSum;
				dsPeriodReportSumProject.productionSum = consumedTimeProject.productionSum;
				dsPeriodReportSumProject.totalProject = true;
				dsProject.periodReports.push(dsPeriodReportSumProject);
			}
			rowDataArray.push(dsProject);
		}

		return rowDataArray;
	}
}

