<div *ngIf="chiffrage">
	<!-- Total développement -->
	<div class="d-flex justify-content-start align-items-center row-total">
		<div class="d-flex justify-content-between align-items-center title-row cell-total cell-title fw-bolder">
			total développement
			<div class="align-items-center button-action">
				<button [title]="'LOT.ADD' | translate" (click)="addFormNewChargeAnnexe()"
						*ngIf="lastChiffrage?.id === chiffrage.id"
						class="add-task me-2" color="primary"
						mat-mini-fab>
					<mat-icon>add</mat-icon>
				</button>
			</div>
		</div>

		<div [class.real-row]="chiffrage.type === 'REEL'" *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-total">
			<div class="charge-row cell-total-developpement">
				{{totalChargeDeveloppement | number:'1.0-3'}}
			</div>
		</div>

		<div *ngFor="let repartition of repartitions">
			<div class="d-flex align-items-center cell-total">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row cell-total-developpement">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{repartition.sommeCharge| number:'1.0-3'}}
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div class="min d-flex align-items-center justify-content-center">
							{{(repartition.sommeChargeMin) ? repartition.sommeChargeMin : 0 | number:'1.0-3'}}
						</div>
						<div class="d-flex align-items-center justify-content-center">
							{{(repartition.sommeChargeMax) ? repartition.sommeChargeMax : 0 | number:'1.0-3'}}
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<!-- pilotage -->
	<div class="d-flex justify-content-start align-items-center node-annexe">
		<div
			class="d-flex justify-content-between align-items-center title-row cell-charge-annexe cell-charge-annexe-title">
			Pilotage
		</div>

		<div [class.real-row]="chiffrage.type === 'REEL'"
			 *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-charge-annexe">
			<div class="charge-row cell-charge-annexe-value">
				{{Math.ceil(chiffrage?.pourcentageGestionProjet * totalChargeDeveloppement / 25) / 4 | number:'1.0-3'}}
			</div>
		</div>

		<div *ngFor="let repartition of repartitions">
			<div class="d-flex align-items-center cell-charge-annexe">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row cell-charge-annexe-value">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{Math.ceil(chiffrage?.pourcentageGestionProjet * repartition.sommeCharge / 25) / 4 | number:'1.0-3'}}
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div class="min d-flex align-items-center justify-content-center">
							{{Math.ceil(chiffrage?.pourcentageGestionProjet * repartition.sommeChargeMin / 25) / 4 | number:'1.0-3'}}
						</div>
						<div class="d-flex align-items-center justify-content-center">
							{{Math.ceil(chiffrage?.pourcentageGestionProjet * repartition.sommeChargeMax / 25) / 4 | number:'1.0-3'}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Intégration & Tests -->
	<div class="d-flex justify-content-start align-items-center node-annexe">
		<div
			class="d-flex justify-content-between align-items-center title-row cell-charge-annexe cell-charge-annexe-title">
			Intégration & Tests
		</div>

		<div [class.real-row]="chiffrage.type === 'REEL'"
			 *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-charge-annexe">
			<div class="charge-row cell-charge-annexe-value">
				{{Math.ceil(chiffrage.pourcentageITTest * totalChargeDeveloppement / 25) / 4 | number:'1.0-3'}}
			</div>
		</div>

		<div *ngFor="let repartition of repartitions">
			<div class="d-flex align-items-center cell-charge-annexe">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row cell-charge-annexe-value">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{Math.ceil(chiffrage.pourcentageITTest * repartition.sommeCharge / 25) / 4 | number:'1.0-3'}}
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div class="min d-flex align-items-center justify-content-center">
							{{Math.ceil(chiffrage?.pourcentageITTest * repartition.sommeChargeMin / 25) / 4 | number:'1.0-3'}}
						</div>
						<div class="d-flex align-items-center justify-content-center">
							{{Math.ceil(chiffrage?.pourcentageITTest * repartition.sommeChargeMax / 25) / 4 | number:'1.0-3'}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

		<!-- Charge chargeAnnexe -->
		<mat-tree-node *matTreeNodeDef="let node" class="node-annexe">
			<app-chiffrage-global-annexe [chargeAnnexe]="node.item" [chiffrage]="chiffrage"
										 [lastChiffrage]="lastChiffrage" [node]="node" [repartitions]="repartitions"
										 (clearNewChargeAnnexeForm)="clearFormChargeAnnexe(node)"
										 (removeChargeAnnexe)="deleteChargeAnnexe(node)"
										 (saveNewChargeAnnexeForm)="saveFormChargeAnnexe(node)">
			</app-chiffrage-global-annexe>
		</mat-tree-node>

		<!-- Création d'une nouvelle charge chargeAnnexe -->
		<mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" class="node-annexe">
			<app-chiffrage-global-annexe [chargeAnnexe]="node.item" [chiffrage]="chiffrage" [node]="node"
										 [repartitions]="repartitions" [stateItemNode]="'EDIT'"
										 (clearNewChargeAnnexeForm)="clearFormChargeAnnexe(node)"
										 (saveNewChargeAnnexeForm)="saveFormChargeAnnexe(node)">
			</app-chiffrage-global-annexe>
		</mat-tree-node>
	</mat-tree>


	<!-- Total global jours -->
	<div class="d-flex justify-content-start align-items-center row-total">
		<div class="d-flex justify-content-between align-items-center title-row cell-total cell-title fw-bolder">
			total global jours
		</div>

		<div [class.real-row]="chiffrage.type === 'REEL'"
			 *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-total">
			<div class="charge-row cell-total-developpement">
				{{getTotalChiffrage() | number:'1.0-3'}}
			</div>
		</div>

		<div *ngFor="let repartition of repartitions">
			<div class="d-flex align-items-center cell-total">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row cell-total-global-jour">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{getTotalGlobalJourRepartition(repartition, 'REEL') | number:'1.0-3'}}
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div class="min d-flex align-items-center justify-content-center">
							{{getTotalGlobalJourRepartition(repartition, 'MIN')  | number:'1.0-3'}}
						</div>
						<div class="d-flex align-items-center justify-content-center">
							{{getTotalGlobalJourRepartition(repartition, 'MAX')  | number:'1.0-3'}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Total global euro -->
	<div class="d-flex justify-content-start align-items-center row-total total-global">
		<div class="d-flex justify-content-between align-items-center title-row cell-total cell-title fw-bolder">
			total global
		</div>

		<div [class.real-row]="chiffrage.type === 'REEL'"
			 *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-total">
			<div class="charge-row cell-total-global">
				{{getTotalChiffrage() * chiffrage.tjm | number:'1.0-3'}} €
			</div>
		</div>

		<div *ngFor="let repartition of repartitions">
			<div class="d-flex align-items-center cell-total">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
					 class="repartition-row cell-total-global">
					<div *ngIf="chiffrage.type !== 'MINMAX'">
						{{getTotalGlobalJourRepartition(repartition, 'REEL') * chiffrage.tjm | number:'1.0-3'}} €
					</div>
					<div *ngIf="chiffrage.type === 'MINMAX'" class="d-flex flex-row min-max-container">
						<div class="min d-flex align-items-center justify-content-center">
							{{getTotalGlobalJourRepartition(repartition, 'MIN') * chiffrage.tjm | number:'1.0-3'}} €
						</div>
						<div class="d-flex align-items-center justify-content-center">
							{{getTotalGlobalJourRepartition(repartition, 'MAX') * chiffrage.tjm | number:'1.0-3'}} €
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
