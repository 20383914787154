import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfigurationService} from '../../core/business/service/configuration/configuration.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SpinnerService} from '../../core/service/spinner.service';
import {IProjectDto} from '../../core/business/service/project/project.dto';
import {IStoryDto, JiraStatusEnum} from '../../core/business/service/story/story.dto';
import {ProjectService} from '../../core/business/service/project/project.service';
import {IIssueFromJiraDto, JiraIssuePriorityEnum} from '../../core/business/service/bug-management/bug-management.dto';
import {BugManagementService} from '../../core/business/service/bug-management/bug-management.service';
import {StoryService} from '../../core/business/service/story/story.service';
import {UtilsService} from '../../utils/service/utils.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {firstValueFrom} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-bug-management-search',
	templateUrl: './bug-management-search.component.html',
	styleUrls: ['./bug-management-search.component.scss']
})
export class BugManagementSearchComponent implements OnInit {

	// Filter
	projects: IProjectDto[] = [];
	filteredProjects: IProjectDto[] = [];
	project: IProjectDto;
	ext: boolean = false;

	// Bug datatable
	displayColumns: string[] = ['select', 'jira', 'name', 'status', 'creation', 'priority'];
	linkPrefix: string = '';
	bugs: IIssueFromJiraDto[];
	dataSource: MatTableDataSource<IIssueFromJiraDto> = new MatTableDataSource<IIssueFromJiraDto>();
	selection: SelectionModel<IIssueFromJiraDto> = new SelectionModel<IIssueFromJiraDto>(true, []);

	// Step 1 or 2
	linkBugs: boolean = false;

	// Story creation or choice
	stories: IStoryDto[];
	filteredStories: IStoryDto[];
	story: IStoryDto;
	storyForm: FormGroup;
	chooseExistingStory: boolean;

	constructor(private _bugManagementService: BugManagementService,
				private _storyService: StoryService,
				private _projectService: ProjectService,
				private _configurationService: ConfigurationService,
				private _fb: FormBuilder,
				private _snackbar: MatSnackBar,
				private _translator: TranslateService,
				private _spinnerService: SpinnerService,
				private _titleService: Title) {
		this.storyForm = this._fb.group({
			name: new FormControl('', Validators.required),
			budget: new FormControl(''),
			estimated: new FormControl('', Validators.required)
		});
	}

	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Bugs');

		this._spinnerService.enableLoading();
		// Récupération de la liste des projets
		firstValueFrom(this._projectService.findAll()).then(result => {
			this.projects = (result || []).sort(UtilsService.comparing(p => p.name));
			this.filteredProjects = this.projects;
			// Si que un résultat, on set le premier pour pas avoir a se faire chier
			if (this.projects.length === 1) {
				this.project = this.projects[0];
			}
			this._spinnerService.disableLoading();
		});
		this._configurationService.findByKey('JIRA_URL_BROWSER').then(result => {
			this.linkPrefix = result.value;
			this._spinnerService.disableLoading();
		});
	}

	// region filter
	idEquals(objOne: IProjectDto, objTwo: IProjectDto): boolean {
		if (objOne && objTwo) {
			return objOne.id === objTwo.id;
		}
	}

	search(): void {
		// Si pas de projet sélectionné, on affiche une erreur
		if (!this.project) {
			this._snackbar.open(this._translator.instant('BUG.FILTER.ERROR.FIELD_EMPTY'), '', {
				panelClass: 'error'
			});
			return;
		}
		// Si EXT choisi et pas de jira ext id ou inversement avec le int, on affiche une erreur
		if ((this.ext && !this.project.jiraExtId) || (!this.ext && !this.project.jiraIntId)) {
			this._snackbar.open(this._translator.instant('BUG.FILTER.ERROR.NO_JIRA_ID'), '', {
				panelClass: 'error'
			});
			return;
		}

		this._spinnerService.enableLoading();
		if (this.ext) {
			this.storyForm.get('budget').setValidators(Validators.required);
		} else {
			this.storyForm.get('budget').clearValidators();
		}

		this._findAllBugs();
		// Récup toutes les stories pour le projet
		this._storyService.findAllStory(this.project.id, !this.ext).then(result => {
			this.stories = result || [];
		});
	}

	validateSelection(): void {
		this.linkBugs = true;
		this.chooseExistingStory = true;
		this.dataSource = new MatTableDataSource<IIssueFromJiraDto>(this.selection.selected);
		this.displayColumns.splice(0, 1);
		this.story = null;
		this.filteredStories = this.stories;
		this.storyForm.reset();
		// Si que un résultat, on set le premier pour pas avoir a se faire chier
		if (!this.stories.length) {
			this.chooseExistingStory = false;
		} else if (this.stories.length === 1) {
			this.story = this.stories[0];
		}
	}

	cancelSelection(): void {
		this.linkBugs = false;
		this.dataSource = new MatTableDataSource<IIssueFromJiraDto>(this.bugs);
		this.displayColumns.unshift('select');
		this.selection.clear();
	}

	filterProjects(query: string): void {
		if (query) {
			this.filteredProjects = this.projects.filter(p => p.name.toUpperCase().indexOf(query.toUpperCase()) !== -1);
		} else {
			this.filteredProjects = this.projects;
		}
	}

	filterStories(query: string): void {
		query = query.toLowerCase();
		this.filteredStories = this.stories.filter(value =>
			value.name.toLowerCase().indexOf(query) !== -1
			|| (value.jiraIntId && value.jiraIntId.toLowerCase().indexOf(query) !== -1)
			|| (value.jiraExtId && value.jiraExtId.toLowerCase().indexOf(query) !== -1));
	}

	// endregion

	// region step container

	save(): void {
		if (this.chooseExistingStory) {
			if (!this.story) {
				return;
			}
		} else {
			if (this.storyForm.invalid) {
				return;
			}
			this.story = this.storyForm.value;
		}

		this._spinnerService.enableLoading();
		// Lie les bugs à la story
		const projectJiraId: string = this.ext ? this.project.jiraExtId : this.project.jiraIntId;
		this._bugManagementService.linkBugsToStory(projectJiraId, this.selection.selected, this.story, !this.ext).then(result => {
			if (result) {
				this._snackbar.open(this._translator.instant('BUG.VALIDATION.OK'));
				// Recherche tous les bugs
				this._findAllBugs().then(() => {
					// Reviens à l’étape 1
					this.cancelSelection();
				});
			} else {
				this._spinnerService.disableLoading();
				this._snackbar.open(this._translator.instant('BUG.VALIDATION.ERROR'), '', {
					panelClass: 'error'
				});
			}
		});
	}

	// region table
	isAllSelected(): boolean {
		const numSelected: number = this.selection.selected.length;
		const numRows: number = this.dataSource.data.length;
		return numSelected === numRows;
	}

	// endregion

	masterToggle(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.data.forEach(row => this.selection.select(row));
	}

	private _findAllBugs(): Promise<void> {
		// Récup le JIRA ID du projet
		const projectJiraId: string = this.ext ? this.project.jiraExtId : this.project.jiraIntId;
		// Récup tous les bugs pour le projet
		return this._bugManagementService.findAllBugs(projectJiraId).then(result => {
			this.bugs = result || [];
			if (this.bugs) {
				this.bugs.forEach(value => {
					value.priorityName = UtilsService.getEnumKeyByValue(JiraIssuePriorityEnum, value.priority);
					value.statusName = UtilsService.getEnumKeyByValue(JiraStatusEnum, value.status);
				});
			}
			this.selection.clear();
			this.dataSource = new MatTableDataSource<IIssueFromJiraDto>(this.bugs);
			this._spinnerService.disableLoading();
		});
	}

	// endregion
}
