import {Component, HostListener, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTree, MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DialogProjetEditComponent} from '../component/projet-edit/dialog-projet-edit.component';
import {SpinnerService} from '../../core/service/spinner.service';
import {ConfigurationService} from '../../core/business/service/configuration/configuration.service';
import {
	DialogRedistributionBudgetComponent,
	RedistributionBudgetResult,
} from './component/redistribution-budget/dialog-redistribution-budget.component';
import {DialogExportLotComponent} from './component/export-lot/dialog-export-lot.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TranslateService} from '@ngx-translate/core';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import {ClientService} from '../../core/business/service/client/client.service';
import {IConfigurationDto} from '../../core/business/service/configuration/configuration.dto';
import {ProjectService} from '../../core/business/service/project/project.service';
import {GestionDispParams, IProjectDto, ProjectDto} from '../../core/business/service/project/project.dto';
import {GestionParameter, IssueDragDropMessageEnum, IssueTypeEnum} from './dto/projet-details.objects';
import {IStoryDto, StoryDto, StoryTypeEnum} from '../../core/business/service/story/story.dto';
import {ContractStatusEnum, ILotDto, Lot} from '../../core/business/service/lot/lot.dto';
import {ISubtaskDto, SubtaskDto, SubtaskTypeEnum} from '../../core/business/service/subtask/subtask.dto';
import {LotService} from '../../core/business/service/lot/lot.service';
import {IPeriodReportDto} from '../../core/business/service/report/period-report/period-report.dto';
import {EpicDto, IEpicDto} from '../../core/business/service/epic/epic.dto';
import {EpicService} from '../../core/business/service/epic/epic.service';
import {StoryService} from '../../core/business/service/story/story.service';
import {SubtaskService} from '../../core/business/service/subtask/subtask.service';
import {
	IssueToRedistribute,
	SynchronizationService
} from '../../core/business/service/synchronization/synchronization.service';
import {ReportService} from '../../core/business/service/report/report.service';
import {
	DialogProjectAlertDisplayComponent
} from '../../project-alert/component/dialog-project-alert-display/dialog-project-alert-display.component';
import {ProjectAlertService} from '../../core/business/service/project-alert/project-alert.service';
import {INumberAlertsDto} from '../../core/business/service/project-alert/project-alert.dto';
import {LotDataSource, LotDataSourceContent, ProjetDetailDataSource} from './datasource/projet-detail.datasource';
import {first, firstValueFrom, Observable, takeWhile} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {
	DialogRedistributionBudgetSignedComponent,
	RedistributionBudgetSignedResult,
} from './component/redistribution-budget-signed/dialog-redistribution-budget-signed.component';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
	DialogRedistributionBudgetSynchroComponent
} from './component/redistribution-budget-synchro/dialog-redistribution-budget-synchro.component';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';
import {IStoryCreateUpdateDto} from '../../core/business/service/story/story.create.update.dto';
import {Title} from '@angular/platform-browser';
import {ISprintDto} from '../../core/business/service/sprint/sprint.dto';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {SseService} from '../../core/business/service/sse/sse.service';
import {JiraTransition} from './dto/jira-transition.object';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSelectChange} from '@angular/material/select';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {AdminRolePipe} from '../../security/pipe/role.pipe';

export interface MatTreeNode {
	lotId: number;
	isLot: boolean;
	checked: boolean;
	parentLot: LotDataSource;
	issueType: IssueTypeEnum;
	parent: LotDataSource | LotDataSourceContent;
	object: LotDataSource | LotDataSourceContent;
	children: MatTreeNode[];
}

export interface MatTreeFlatNode {
	lotId: number;
	isLot: boolean;
	checked: boolean;
	parentLot: LotDataSource;
	issueType: IssueTypeEnum;
	parent: LotDataSource | LotDataSourceContent;
	object: LotDataSource | LotDataSourceContent;
	expandable: boolean;
	level: number;
	nbChildren: number;
}

interface KeybordSelectedObject {
	node: MatTreeFlatNode;
	nodeIndex: number;
}

class GestionParamsActivated {
	displayParamsActivated: GestionParameter[];
	statutLotActivated: GestionParameter[];
}

const GESTION_PARAMETERS: GestionParamsActivated = {
	displayParamsActivated: [
		{
			name: 'estimation',
			value: false,
		},
		{
			name: 'jira_ext',
			value: false,
		},
		{
			name: 'hide_empty_epic',
			value: true,
		},
		{
			name: 'sprint',
			value: false,
		},
	],
	statutLotActivated: [
		{
			name: 'DRAFT',
			value: false,
		},
		{
			name: 'OPEN',
			value: false,
		},
		{
			name: 'SIGNED',
			value: false,
		},
		{
			name: 'FINISH',
			value: false,
		},
	]
};

@Component({
	selector: 'app-projet-detail',
	templateUrl: './projet-detail.component.html',
	styleUrls: ['./projet-detail.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
		trigger('openClose', [
			state('open', style({
				opacity: 1,
				background: 'linear-gradient(to right, #fabe2a, #f28a16, #e81743, #e81743, #f28A16, #fabe2a)',
				height: '25px',
				width: '100%',
				position: 'relative',
				overflow: 'hidden',

			})),
			state('close',
				style({
					height: '100px',
					opacity: '0.5',
					backgroundColor: 'green',
				})),
			transition('open=>close', [
				animate('1.5s ease-out'),
			]),
			transition('close=>open', [
				animate('0.5s'),
			]),
		]),
	],
})
export class ProjetDetailComponent extends UserInfo implements OnInit {

	@ViewChild(SearchbarComponent) searchInput: SearchbarComponent;
	@ViewChild('tree') tree: MatTree<any>;

	// Données serveur
	projectId: number;
	projetDetailDataSource: ProjetDetailDataSource;
	displayedColumns: string[] = ['informations', 'statut', 'budget', 'prod', 'rae', 'avanc', 'estimated', 'jiraExtId', 'void'];
	// Lot dont les détails sont affichés dans le formulaire de création/modification de lot
	selectedLot: LotDataSource = null;
	contractStatusEnumKeys: string[] = Object.keys(ContractStatusEnum);
	// Issue (story / subtask)
	selectedIssue: LotDataSourceContent;
	// Référence vers l'objet en cours d'édition
	selectedIssueOriginal: LotDataSourceContent;
	keyboardSelectedObject: KeybordSelectedObject;
	// Variables d'état
	displayLotForm: boolean = false;
	synchronizationInProgress: boolean = false;
	// Selected issues
	selectedIssues: Map<number, LotDataSourceContent> = new Map<number, LotDataSourceContent>();
	backlogId: number;

	configurationUrlJira: IConfigurationDto;

	storyTypes: string[] = Object.keys(StoryTypeEnum);
	subtaskTypes: string[] = Object.keys(SubtaskTypeEnum);

	filterSearchInput: string;
	filterContract: ContractStatusEnum;
	filterSprint: ISprintDto | string;

	// region tree

	treeControl: FlatTreeControl<MatTreeFlatNode>;
	treeFlattener: MatTreeFlattener<MatTreeNode, MatTreeFlatNode>;
	matTreeDataSource: MatTreeFlatDataSource<MatTreeNode, MatTreeFlatNode>;
	// Simple liste pour avoir toutes les nodes à plat (attention, pas d'ordre particulier)
	matTreeNodeList: MatTreeNode[];

	gestionParameters: GestionParamsActivated = GESTION_PARAMETERS;
	activatedParameters: GestionDispParams = { displayParams: [], statutLot: [] };

	// BACKLOG List
	backlogList: LotDataSource = null;
	backlogStories: MatTreeNode = null;
	backlogDisplay: boolean = false;

	userIsAdminOrCP: boolean = false;

	constructor(public dialog: MatDialog,
				public override keycloak: KeycloakService,
				private _spinnerService: SpinnerService,
				private _route: ActivatedRoute,
				private _projetService: ProjectService,
				private _lotService: LotService,
				private _epicService: EpicService,
				private _storyService: StoryService,
				private _subtaskService: SubtaskService,
				private _synchronizationService: SynchronizationService,
				private _sseService: SseService,
				private _reportService: ReportService,
				private _userBaseService: UserBaseService,
				private _clientService: ClientService,
				private _configurationService: ConfigurationService,
				private _projectAlertService: ProjectAlertService,
				private _snackBar: MatSnackBar,
				private _translator: TranslateService,
				private _router: Router,
				private _renderer: Renderer2,
				private _zone: NgZone,
				private _titleService: Title) {
		super(keycloak);

		this.projetDetailDataSource = new ProjetDetailDataSource(this._projetService, this._projectAlertService,
			this.currentUser, this._spinnerService, this._configurationService.findOnCacheByKey('JIRA_URL_BROWSER'));

		this.subtaskTypes.splice(this.subtaskTypes.indexOf('SUBBUG'), 1);
		this.subtaskTypes.splice(this.subtaskTypes.indexOf('INT'), 1);

		this.initMatTree();
	}

	/**
	 * NB: les sous-objets du projet (epics, stories...) ne sont pas maintenus à jour,
	 * n'utiliser this.projet que pour les infos au niveau du projet : id, name, budget, RAE...
	 * Pour les sous-éléments, se référer aux MatTreeNode et à leurs LotDataSource[Content]
	 */
	get projet(): IProjectDto {
		return this.projetDetailDataSource.dataDbProject.getValue();
	}

	// endregion

	get projectAlert(): INumberAlertsDto[] {
		return this.projetDetailDataSource.dataDbProjectAlert.getValue();
	}

	isIssueForm = (node: MatTreeFlatNode) => {
		if (node.isLot || !this.selectedIssue) {
			return false;
		}
		return node.object.id === this.selectedIssue.id
			&& node.lotId === this.selectedIssue.lotId
			&& node.issueType === this.selectedIssue.type;
	};

	transformer = (node: MatTreeNode, level: number): MatTreeFlatNode => {
		return {
			expandable: (node.issueType !== IssueTypeEnum.STORY || !(node.object as LotDataSourceContent).orphan)
				&& !!node.children && node.children.length > 0 && (node.children[0].object as LotDataSourceContent).visible,
			lotId: node.lotId,
			level: level,
			isLot: node.isLot,
			checked: node.checked,
			parentLot: node.parentLot,
			issueType: node.issueType,
			object: node.object,
			parent: node.parent,
			nbChildren: (node.children || []).length,
		};
	};

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (!this.keyboardSelectedObject) {
			this.keyboardSelectedObject = {node: this.treeControl.dataNodes[0], nodeIndex: 0};
			if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
				// First arrow press with no previous selection will select the top line.
				this.keyboardSelectedObject.node = null;
			}
		}

		if (event.ctrlKey && event.key === 's') {
			event.preventDefault();
			// édition de l'issue
			if (this.keyboardSelectedObject.node.object instanceof LotDataSource) {
				this.editLot(this.keyboardSelectedObject.node.parentLot);
			} else {
				this.editIssueForm(this.keyboardSelectedObject.node.object);
			}
		} else if (event.ctrlKey && event.key === 'x') {
			event.preventDefault();
			// création d'une issue pour le parent sélectionné
			if (this.keyboardSelectedObject.node.object instanceof LotDataSource) {
				this.addIssueForm(null, null, this.keyboardSelectedObject.node.object);
			} else {
				const issue: LotDataSourceContent = this.keyboardSelectedObject.node.object;
				switch (issue.type) {
					case IssueTypeEnum.EPIC:
					case IssueTypeEnum.STORY:
						this.addIssueForm(issue.id, issue.type, this.keyboardSelectedObject.node.parentLot);
						break;
					case IssueTypeEnum.SUBTASK:
						// do nothing
						break;
				}
			}
		} else if (event.ctrlKey && event.key === 'd') {
			event.preventDefault();
			// création d'une issue de même niveau (même parent) que la sélection
			if (this.keyboardSelectedObject.node.object instanceof LotDataSource) {
				this.addLot();
			} else {
				const parentLot: LotDataSource = this.keyboardSelectedObject.node.parentLot;
				const issue: LotDataSourceContent = this.keyboardSelectedObject.node.object;
				switch (issue.type) {
					case IssueTypeEnum.EPIC:
						this.addIssueForm(null, null, parentLot);
						break;
					case IssueTypeEnum.STORY:
						this.addIssueForm(issue.parentId, IssueTypeEnum.EPIC, parentLot);
						break;
					case IssueTypeEnum.SUBTASK:
						this.addIssueForm(issue.parentId, IssueTypeEnum.STORY, parentLot);
						break;
				}
			}
		} else if (event.key === 'ArrowUp') {
			if (!this.selectedIssue) {
				event.preventDefault();
				this.moveLineSelection(-1);
			}
		} else if (event.key === 'ArrowDown') {
			if (!this.selectedIssue) {
				event.preventDefault();
				this.moveLineSelection(1);
			}
		} else if (event.key === 'ArrowRight') {
			if (!this.selectedIssue) {
				event.preventDefault();
				const node: MatTreeFlatNode = this.keyboardSelectedObject.node;
				if (node.expandable) {
					if (node.isLot) {
						this.expandMatTreeNode(node.lotId);
					} else if (node.issueType === IssueTypeEnum.EPIC) {
						this.expandMatTreeNode(node.lotId, node.object.id);
					} else {
						this.expandMatTreeNode(node.lotId, null, node.object.id);
					}
				}
			}
		} else if (event.key === 'ArrowLeft') {
			if (!this.selectedIssue) {
				event.preventDefault();
				const node: MatTreeFlatNode = this.keyboardSelectedObject.node;
				if (node.expandable) {
					if (node.isLot) {
						this.expandMatTreeNode(node.lotId, null, null, false);
					} else if (node.issueType === IssueTypeEnum.EPIC) {
						this.expandMatTreeNode(node.lotId, node.object.id, null, false);
					} else {
						this.expandMatTreeNode(node.lotId, null, node.object.id, false);
					}
				}
			}
		}
	}

	async ngOnInit(): Promise<void> {
		this.projectId = +this._route.snapshot.paramMap.get('id');

		this.configurationUrlJira = await this._configurationService.findByKey('JIRA_URL_BROWSER');

		this.projetDetailDataSource.connect(null).subscribe(() => {
			this.toggleKeyboardSelectedObject(true);
			if (this.projet.name) {
				this._titleService.setTitle(
					ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Gestion > ' + this.projet.name,
				);
			}
			if (this.projet.gestionParameters) {
				this.activatedParameters = this.projet.gestionParameters;
			} else {
				this.activatedParameters.displayParams = this.gestionParameters.displayParamsActivated.filter((param) => param.value === true)
					.map((param) => param.name);
				this.activatedParameters.statutLot = this.gestionParameters.statutLotActivated.filter((param) => param.value === true)
					.map((param) => param.name);
			}
			if (this.projet.usersCp) {
				this.userIsAdminOrCP = new AdminRolePipe().transform(this.role)
					|| this.projet.usersCp.filter((user) => user.email === this.email).length > 0;
			}
		});
		this.refreshAll();
	}

	mapToMatTreeNode(lot: LotDataSource, parent: LotDataSource | LotDataSourceContent,
					 lotDataSourceContent: LotDataSourceContent): MatTreeNode {
		const newNode: MatTreeNode = {
			lotId: lot.id,
			parentLot: lot,
			object: lotDataSourceContent,
			parent: parent,
			isLot: false,
			checked: false,
			issueType: lotDataSourceContent.type,
			children: lotDataSourceContent.children.map(c => this.mapToMatTreeNode(lot, lotDataSourceContent, c)),
		};
		this.matTreeNodeList.push(newNode);
		return newNode;
	}

	/**
	 * Fold/unfold line & all its descendants
	 * @param parentNode
	 * @param expandState
	 */
	expandAll(parentNode: MatTreeFlatNode, expandState: boolean): void {
		if (parentNode.expandable) {
			if (expandState === true) {
				this.treeControl.expandDescendants(parentNode);
			} else {
				this.treeControl.collapseDescendants(parentNode);
			}
			if (parentNode.object.expanded !== expandState) {
				this.toggleExpandedState(parentNode.object, expandState);
			}
			const filter: any = parentNode.isLot ?
				node => !node.isLot && node.lotId === parentNode.object.id
				: node => !node.isLot && node.lotId === parentNode.lotId && node.parent.id === parentNode.object.id;
			this.matTreeNodeList.filter(filter).forEach(node => {
				if (node.object.expanded !== expandState) {
					// Save collapsed state
					this.toggleExpandedState(node.object, expandState);
				}
			});
		}
	}

	refreshAll(): void {
		this.selectedIssues.clear();
		this.projetDetailDataSource.findProject(this.projectId);
	}

	// region LOT: add/modification/suppression
	addLot(): void {
		this.selectedLot = new LotDataSource();
		this.selectedLot.budget = 0;
		this.selectedLot.RAE = 0;
		this.selectedLot.avancement = '0 %';
		this.selectedLot.nombreDeStory = 0;
		this.selectedLot.backlog = false;
		this.displayLotForm = true;
	}

	async onLotFormValidation(): Promise<void> {
		if (this.selectedLot.name?.trim().length) {
			const lot: ILotDto = new Lot();
			lot.name = this.selectedLot.name.trim();
			lot.projectId = this.projet.id;
			lot.contract = ContractStatusEnum.DRAFT;
			lot.budget = this.selectedLot.budget;
			// Créer coté serveur
			const createdLot: ILotDto = await this._lotService.createLot(lot);
			this.updateMatTreeWithNewLot(createdLot);
			this._snackBar.open(this._translator.instant('LOT.CREATED'));
			// Reset forms
			this.displayLotForm = false;
			this.selectedLot = null;
			this.refreshAll();
		}
	}

	async confirmEditLot(lotDS: LotDataSource): Promise<void> {
		if (lotDS.name?.trim().length) {
			const savedLot: ILotDto = await this._lotService.modifyLot(lotDS.id, <ILotDto>{
				id: lotDS.id,
				name: lotDS.name.trim(),
				budget: lotDS.budget,
			});
			lotDS.name = savedLot.name;
			lotDS.budget = savedLot.budget;
			lotDS.RAE = savedLot.remainingEXTSum;
			lotDS.avancement = this.projetDetailDataSource.getAvancement(lotDS.budget, lotDS.RAE);
			lotDS.editing = false;
			this.selectedLot = null;
			this._snackBar.open(this._translator.instant('LOT.UPDATED'));
		}
	}

	editLot(lot: LotDataSource): void {
		if (!this.selectedLot) {
			this.selectedLot = Object.assign(new LotDataSource(), lot);
			lot.editing = true;
		}
	}

	cancelEditLot(lot: LotDataSource): void {
		Object.assign(lot, this.selectedLot);
		this.selectedLot = null;
		lot.editing = false;
	}

	async changeLotState(lotDataSource: LotDataSource, contractStatusEnumKey: string): Promise<void> {
		const oldStatus: ContractStatusEnum = lotDataSource.contract;
		if (oldStatus === ContractStatusEnum[contractStatusEnumKey]) {
			return;
		}
		const lot: ILotDto = new Lot();
		lot.contract = ContractStatusEnum[contractStatusEnumKey];
		lot.finishDate = null;
		if (lot.contract === ContractStatusEnum.FINISH) {
			const spentSumNonValidate: {spentSum: number} = await
				this._reportService.spentSumNonValidatedForProjectAndLot(this.projet.id, lotDataSource.id);
			if (spentSumNonValidate.spentSum > 0) {
				this._snackBar.open(this._translator.instant('LOT.ERROR_UNVALIDATED_WEEKS')
					+ this._translator.instant('PROJECT.CONTRACT.STATUS.' + contractStatusEnumKey), '', {
					panelClass: 'error',
				});
				return;
			}
			const lastPeriodReport: IPeriodReportDto = await
				this._reportService.findLastPeriodReportValidatedForProjectAndLot(this.projet.id, lotDataSource.id);
			if (lastPeriodReport) {
				lot.finishDate = lastPeriodReport.period.dateEnd;
			} else {
				lot.finishDate = new Date();
			}
		}

		// Update mat-tree element
		lotDataSource.contract = lot.contract;
		// Update in DB
		await this._lotService.modifyLot(lotDataSource.id, lot);

		if (oldStatus === ContractStatusEnum.FINISH || contractStatusEnumKey === ContractStatusEnum.FINISH) {
			if (contractStatusEnumKey === ContractStatusEnum.FINISH) {
				await this.synchronize();
			}
			await this.synchronizeLotState(lotDataSource.id, contractStatusEnumKey);
		}
		// Display msg
		this._snackBar.open(this._translator.instant('LOT.IS')
			+ this._translator.instant('PROJECT.CONTRACT.STATUS.' + contractStatusEnumKey));

		// Update budget
		const statusToCount: ContractStatusEnum[] = [ContractStatusEnum.SIGNED, ContractStatusEnum.FINISH];
		if (statusToCount.indexOf(oldStatus) === -1 && statusToCount.indexOf(lot.contract) !== -1) {
			this.projet.budget += lotDataSource.budget;
			this.projet.remainingEXTSum += lotDataSource.RAE;
		} else if (statusToCount.indexOf(oldStatus) !== -1 && statusToCount.indexOf(lot.contract) === -1) {
			this.projet.budget -= lotDataSource.budget;
			this.projet.remainingEXTSum -= lotDataSource.RAE;
		}
	}

	async removeLot(lot: LotDataSource): Promise<void> {
		if (confirm(this._translator.instant('LOT.CONFIRM_DELETE', {lotName: lot.name}))) {
			await this._lotService.removeLot(lot.id);
			lot.id = null;
			this.deleteNewIssueFromMatTree();
			this._snackBar.open(this._translator.instant('LOT.DELETED'));
		}
	}

	cancelAddLot(): void {
		this.displayLotForm = false;
		this.selectedLot = null;
	}

	async addIssueForm(parentId: number, parentType: IssueTypeEnum, lot: LotDataSource): Promise<void> {
		if (parentId === null) {
			await this.expandMatTreeNode(lot.id);
		} else if (parentType === IssueTypeEnum.EPIC) {
			await this.expandMatTreeNode(lot.id, parentId);
		} else if (parentType === IssueTypeEnum.STORY) {
			await this.expandMatTreeNode(lot.id, null, parentId);
		}
		if (this.selectedIssue) {
			// Un seul form à la fois
			return;
		}
		this.selectedIssue = new LotDataSourceContent();
		this.selectedIssue.visible = true;
		if (parentId === null) {
			this.selectedIssue.type = IssueTypeEnum.EPIC;
			this.selectedIssue.lotId = lot.id;
		} else {
			if (parentType === IssueTypeEnum.EPIC) {
				this.selectedIssue.type = IssueTypeEnum.STORY;
				this.selectedIssue.storyType = StoryTypeEnum.REGULAR;
			} else if (parentType === IssueTypeEnum.STORY) {
				this.selectedIssue.type = IssueTypeEnum.SUBTASK;
				this.selectedIssue.subtaskType = SubtaskTypeEnum.REGULAR;
			}
			this.selectedIssue.lotId = lot.id;
			this.selectedIssue.parentId = parentId;
			this.selectedIssue.parentType = parentType;
			this.selectedIssue.parentTypeId = parentType + '-' + parentId;
			this.selectedIssue.reporter = this.currentUser.jiraId;
			this.selectedIssue.isLotContratSigned = lot.contract === ContractStatusEnum.SIGNED;
		}
		this.insertIntoMatTree(parentId, parentType, lot);
	}

	//endregion

	editIssueForm(issue: LotDataSourceContent): void {
		this.selectedIssue = Object.assign(new LotDataSourceContent(), issue);
		this.selectedIssueOriginal = issue;
	}

	async onIssueFormValidation(): Promise<void> {
		if (this.selectedIssue.name === undefined || this.selectedIssue.name.trim().length === 0) {
			return;
		}
		if (this.selectedIssue.type === IssueTypeEnum.STORY) {
			if (!this.selectedIssue.id) {
				await this.createStory(this.selectedIssue);
			} else {
				await this.editStory(this.selectedIssue);
				this.selectedIssueOriginal = undefined;
				this.selectedIssue = undefined;
			}
		} else if (this.selectedIssue.type === IssueTypeEnum.SUBTASK) {
			if (!this.selectedIssue.id) {
				await this.createSubtask(this.selectedIssue);
			} else {
				await this.editSubtask(this.selectedIssue);
			}
			this.selectedIssueOriginal = undefined;
			this.selectedIssue = undefined;
		} else if (this.selectedIssue.type === IssueTypeEnum.EPIC) {
			if (!this.selectedIssue.id) {
				await this.createEpic(this.selectedIssue);
			} else {
				await this.editEpic(this.selectedIssue);
			}
			this.selectedIssueOriginal = undefined;
			this.selectedIssue = undefined;
		}
	}

	cancelIssueForm(): void {
		if (!this.selectedIssue.id) {
			// Si on était en création, on supprime la ligne. Sinon, on sort juste du mode édition.
			this.deleteNewIssueFromMatTree();
		}
		this.selectedIssue = undefined;
	}

	//region Remove
	async removeIssue(issue: LotDataSourceContent): Promise<void> {
		if (issue.type === IssueTypeEnum.STORY) {
			if ((issue.object && (issue.object as IStoryDto).spent > 0) || issue.remainingEXT !== issue.budget) {
				this._snackBar.open(this._translator.instant('STORY.CANT_DELETE'), '', {
					panelClass: 'error',
				});
				return;
			}
		} else if (issue.type === IssueTypeEnum.SUBTASK) {
			if ((issue.object as ISubtaskDto).spent > 0) {
				this._snackBar.open(this._translator.instant('SUBTASK.CANT_DELETE'), '', {
					panelClass: 'error',
				});
				return;
			}
		}
		if (confirm(this._translator.instant('ISSUE.CONFIRM_DELETE'))) {
			if (issue.type === IssueTypeEnum.STORY) {
				if (this.isLotContratSigned(issue.lotId)) {
					const story: StoryDto = new StoryDto();
					story.name = issue.name;
					story.budget = issue.budget;
					story.estimated = issue.estimated;
					await this.openDialogRedistributionBudgetSuppression(issue);
				} else {
					await this.removeStory(issue);
					this.recalculateTotalsForEpicAndLot(issue.lotId, issue.parentId);
				}
			} else if (issue.type === IssueTypeEnum.SUBTASK) {
				await this.removeSubtask(issue);
			} else {
				await this.removeEpic(issue.id);
			}
		}
	}

	async removeStory(issue: LotDataSourceContent): Promise<void> {
		// On enlève l'issue du mat-tree en premier sinon effet bizarre visuellement
		const id: number = issue.id;
		issue.id = null;
		this.deleteNewIssueFromMatTree();
		await this._storyService.removeStory(id);
		this.projetDetailDataSource.findProjectAlert(this.projet.id);
		this._snackBar.open(this._translator.instant('STORY.DELETED'));
	}

	async removeSubtask(issue: LotDataSourceContent): Promise<void> {
		// On enlève l'issue du mat-tree en premier sinon effet bizarre visuellement
		const id: number = issue.id;
		issue.id = null;
		this.deleteNewIssueFromMatTree();
		await this._subtaskService.removeSubtask(id);
		this._snackBar.open(this._translator.instant('SUBTASK.DELETED'));
	}

	async removeEpic(id: number): Promise<void> {
		// On enlève l'issue du mat-tree en premier sinon effet bizarre visuellement
		// Il faut enlever l'epic de tous les lots
		this.matTreeNodeList
			.filter(node => node.issueType === IssueTypeEnum.EPIC && node.object.id === id)
			.forEach(epicNode => {
				// Stories deviennent orphelines : passent dans le lot
				const lotNode: MatTreeNode = this.matTreeNodeList.find(n => n.isLot && n.object.id === epicNode.lotId);
				epicNode.children.forEach(storyNode => {
					(storyNode.object as LotDataSourceContent).orphan = true;
					(storyNode.object as LotDataSourceContent).parentId = lotNode.object.id;
					storyNode.parent = lotNode.object;
				});
				if (lotNode) {
					lotNode.children = lotNode.children.concat(epicNode.children);
				}
				epicNode.object.id = null;
			});
		this.deleteNewIssueFromMatTree();
		await this._epicService.removeEpic(id);
		this._snackBar.open(this._translator.instant('EPIC.DELETED'));
	}

	editProject(): void {
		// Créer une nouvelle instance de dialog-popup en lui passant le projet selectionné
		this.dialog.open(DialogProjetEditComponent, {
			data: {
				project: this.projet,
				users: this._userBaseService.getAllOGDPUsersWithJiraUserAndAvatar(),
				clients: this._clientService.getAllClient(),
			},
		}).afterClosed().subscribe(async result => {
			if (result) {
				const savedProject: ProjectDto = await this._projetService.modifyProject(result.id, result);
				Object.assign(this.projetDetailDataSource.dataDbProject.value, savedProject);
				this._snackBar.open(this._translator.instant('PROJECT.SUCCESS_UPDATE',
					{name: this.projet.name}), '', {
					duration: 2500,
					panelClass: 'success'
				});
			}
		});
	}

	/**
	 * Rules to say if a node should be displayed or not
	 * @param node the node
	 * @param forceEmptyEpics true if we consider that empty epics should be displayed, regardless of the "hide_empty_epic" param
	 */
	shouldDisplayTreeNode = (node: MatTreeFlatNode, forceEmptyEpics: boolean): boolean => {
		if (node.isLot) {
			const lot: LotDataSource = node.object as LotDataSource;
			return lot.matchesFilter && (!lot.backlog || lot.nombreDeStory > 0);
		}
		const issue: LotDataSourceContent = node.object as LotDataSourceContent;
		if (!issue.id) {
			// Création d'issue : il faut afficher le form
			return true;
		}
		if (node.parentLot.backlog) {
			// Backlog
			return issue.matchesFilter && issue.type === IssueTypeEnum.STORY;
		} else if (!forceEmptyEpics
			&& this.activatedParameters.displayParams.includes('hide_empty_epic')
			&& node.issueType === IssueTypeEnum.EPIC && node.nbChildren < 1) {
			// Epic sans story
			return false;
		} else if (node.issueType === IssueTypeEnum.SUBTASK && (node.parent as LotDataSourceContent).orphan) {
			// Subtask dont la story est orphan
			return false;
		} else {
			// Default
			return issue.visible && issue.matchesFilter;
		}
	}

	// endregion

	//region Moving stories to a new Lot
	/**
	 * On vérifie que toutes les stories cochées appartiennent au même lot
	 * @param event
	 * @param issue
	 */
	onIssueCheck(event: MatCheckboxChange, issue: LotDataSourceContent): void {
		if (event.checked) {
			if (this.selectedIssues.size > 0) {
				const otherSelectedIssue: LotDataSourceContent = Array.from(this.selectedIssues.values())[0];
				if (otherSelectedIssue.lotId === issue.lotId) {
					this.selectedIssues.set(issue.id, issue);
				} else {
					event.source.checked = false;
					this._snackBar.open(this._translator.instant('PROJECT.DETAIL.MOVE_STORY.DIFFERENT_LOT'), '', {
						panelClass: 'error',
					});
				}
			} else {
				this.selectedIssues.set(issue.id, issue);
			}
		} else {
			this.selectedIssues.delete(issue.id);
		}
	}

	// region Issue form (all issues: epic / story / subtask)

	selectAllBacklogIssues(): void {
		this.backlogList.content
		.forEach(element => {
			if (element instanceof LotDataSourceContent) {
				this.selectedIssues.set(element.object.id, element);
			}
		});
	}

	async moveSelectedIssuesToLot(item: LotDataSource): Promise<void> {
		await this.toggleExpandedState(item, true);
		this._spinnerService.enableLoading();

		const allowMove: boolean = await firstValueFrom(this._projetService.checkUpdateDates(this.projet.id));
		if (allowMove) {
			// On peut le faire grâce à la fonction onIssueCheck(), qui s'assure que toutes les issues soient du même lot
			const srcLotId: number = Array.from(this.selectedIssues.values())[0].lotId;
			if (srcLotId === item.id) {
				this._snackBar.open(this._translator.instant('PROJECT.DETAIL.MOVE_STORY.SAME_LOT'), '', {
					panelClass: 'error',
				});
				this._spinnerService.disableLoading();
				return;
			}
			const srcLot: MatTreeNode = srcLotId === this.backlogStories.lotId ?
				this.backlogStories :
				this.matTreeDataSource.data.find(node => node.isLot && node.lotId === srcLotId);
			const destLot: MatTreeNode = this.matTreeDataSource.data.find(node => node.isLot && node.lotId === item.id);
			const srcContract: ContractStatusEnum = (srcLot.object as LotDataSource).contract;
			const destContract: ContractStatusEnum = (destLot.object as LotDataSource).contract;

			if ((srcContract === ContractStatusEnum.SIGNED && destContract !== ContractStatusEnum.FINISH)
				|| (destContract === ContractStatusEnum.SIGNED && srcContract !== ContractStatusEnum.FINISH)) {

				this.dialog.open(DialogRedistributionBudgetSignedComponent, {
					width: '750px',
					data: {
						storiesWithLotChange: Array.from(this.selectedIssues.values()),
						srcLot: srcLot.object,
						destLot: destLot.object,
					},
				}).afterClosed().subscribe(async (result: RedistributionBudgetSignedResult): Promise<void> => {
					this._spinnerService.enableLoading();
					if (result) {
						for (const story of [result.storyToCredit, result.storyToDebit]) {
							if (story) {
								await this.saveStory(story, false);
							}
						}
						await this.moveStories(item.id, Array.from(result.storiesWithLotChange));
					}
					this._spinnerService.disableLoading();
				});
			} else if (destContract === ContractStatusEnum.DRAFT || destContract === ContractStatusEnum.OPEN) {
				await this.moveStories(item.id, Array.from(this.selectedIssues.values()));
			}
		} else {
			this._snackBar.open(this._translator.instant('PROJECT.SYNCHRONIZE_BEFORE_MOVE'), '', {
				panelClass: 'warning',
				duration: 5000,
			});
		}
		this.backlogDisplay = !!this.backlogList.content.find(s => s.type === 'STORY');
		this._spinnerService.disableLoading();
	}

	snackbarMessageHandler(issueDragDrop: IssueDragDropMessageEnum): void {
		this._snackBar.open(this._translator.instant('PROJECT.DETAIL.DRAG_DROP.MESSAGE.' + issueDragDrop));
	}

	async synchronize(): Promise<void> {
		this._spinnerService.enableLoading();
		this.synchronizationInProgress = true;
		this._snackBar.open('Synchronisation en cours...');
		const issuesToRedistribute: IssueToRedistribute[] | void = await this._synchronizationService.synchroniseProject(this.projet.jiraIntId)
			.catch((err) => {
				this._snackBar.open('HTTP ' + err.status + ': ' + err.error.message, '', {
					panelClass: 'error',
					duration: 10000,
				});
				this.synchronizationInProgress = false;
			});

		this._spinnerService.disableLoading();
		if (issuesToRedistribute && issuesToRedistribute?.length) {
			// il y a des budgets modifiés coté jira sur des lots signés
			this.dialog.open(DialogRedistributionBudgetSynchroComponent, {
				width: '720px',
				maxHeight: '80vh',
				disableClose: true, // la synchro est longue, dommage de devoir la refaire si on clique en-dehors de la popup par accident
				data: {
					issues: issuesToRedistribute,
					nodes: this.matTreeNodeList,
				},
			}).afterClosed().subscribe(async res => {
				if (res) {
					this._spinnerService.enableLoading();
					this.projet.lastJIRASyncDate = new Date();
					const projectToModify: IProjectDto = this.projet;
					delete projectToModify.epics;
					delete projectToModify.lots;
					await this._projetService.modifyProject(this.projet.id, projectToModify);
					await this._synchronizationService.redistributeAfterSynchro(this.projet.jiraIntId, res);
					this._spinnerService.disableLoading();
				}
				this.synchronizationInProgress = false;
				await this.refreshAll();
			});
		} else if (issuesToRedistribute) {
			this.synchronizationInProgress = false;
			this._snackBar.open('Synchronisation terminée');
			return this.refreshAll();
		}
	}

	async synchronizeLotState(lotId: number, contractStatusEnumKey: string): Promise<void> {
		this._spinnerService.enableLoading();
		this.synchronizationInProgress = true;
		const type: string = 'lot-progress-' + lotId;
		this._sseService.connectToSse(type)
			.pipe(
				// Laisser la souscription ouverte tant qu’on n’a pas 1 (c’est-à-dire tant qu’on n’a pas fini)
				takeWhile(value => value.data !== 1, true),
				// Quand la souscription va se fermer, on envoie un message à null
				// pour que la connexion suivante ne récupère pas le dernier message envoyé sur ce type
				finalize(() => this._sseService.sendMessageForType(type, null)),
			)
			.subscribe(res => {
				// Reviens dans le giron Angular sinon le snackbar ne s’affiche pas bien
				this._zone.run(() => {
					if (res) {
						this._snackBar.open('Synchronisation avec Jira en cours... (' + (res.data * 100).toFixed(0) + '%)');
					} else {
						this._snackBar.open('Synchronisation avec Jira en cours... (0%)');
					}
				});
			});
		this._synchronizationService.synchronizeLotState(lotId, contractStatusEnumKey);

		this.synchronizationInProgress = false;
		this._spinnerService.disableLoading();
	}

	async changeParametersSelection(event: MatSelectChange, paramsToChange: number): Promise<void> {
		if (paramsToChange === 1) {
			this.activatedParameters.displayParams = event.value;
		} else if (paramsToChange === 2) {
			this.activatedParameters.statutLot = event.value;
		}
		await this._projetService.modifyGestionParameters(this.projet.id, event.value, paramsToChange);

		this.refreshAll();
	}

	filter(): void {
		if (this.filterSearchInput || this.filterContract || this.filterSprint) {
			console.log("TEST 2 - 3");
			this.projetDetailDataSource.filter(
				this.filterSearchInput, this.filterSprint, this.filterContract, this.activatedParameters.displayParams.includes('hide_empty_epic'),
			);
		} else {
			console.log("TEST 2 - 4");
			this.projetDetailDataSource.filter();
		}
		if (this.keyboardSelectedObject && !this.shouldDisplayTreeNode(this.keyboardSelectedObject.node, false)) {
			this.toggleKeyboardSelectedObject(false);
			this.keyboardSelectedObject = null;
		}
		this.matTreeDetectChanges();
	}

	//region Dialog
	openDialogExportLot(): void {
		this.dialog.open(DialogExportLotComponent, {
			width: '550px',
			data: {project: this.projet},
		}).afterClosed().subscribe(result => {
			if (result != null) {
				this.ngOnInit();
			}
		});
	}

	async openDialogRedistributionBudgetModification(issue: LotDataSourceContent): Promise<void> {
		return firstValueFrom(this.openDialogRedistributionBudget('EDIT', issue)).then(async result => {
			if (result?.srcStory) {
				if (result.destStory) {
					// Si on a modifié le budget dans la pop-in, on remet le bon budget de la story source
					if (issue.budget !== result.srcStory.budget) {
						issue.budget = result.srcStory.budget;
					}
					await this.saveStory(result.destStory);
				}
				await this.finishEditingStory(issue);
			}
		});
	}

	async openDialogRedistributionBudgetSuppression(selectedIssue: LotDataSourceContent): Promise<void> {
		return firstValueFrom(this.openDialogRedistributionBudget('DELETE', selectedIssue)).then(async result => {
			if (result?.srcStory) {
				if (result.destStory) {
					await this.saveStory(result.destStory);
				}
				this.removeStory(selectedIssue);
				this.recalculateTotalsForEpicAndLot(selectedIssue.lotId, selectedIssue.parentId);
			}
		});
	}

	openDialogProjectAlert(): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '900px';
		dialogConfig.data = {
			projectAlerts: this._projectAlertService.findByProject(this.projet.id),
			projectId: this.projet.id,
		};

		this.dialog.open(DialogProjectAlertDisplayComponent, dialogConfig).afterClosed().subscribe(deleteEvent => {
			if (deleteEvent) {
				this.refreshAll();
			}
		});
	}

	async deleteProject(): Promise<void> {
		if (confirm(this._translator.instant('PROJECT.CONFIRM_DELETE'))) {
			await this._projetService.removeProject(this.projectId);
		}

		await this._router.navigateByUrl('gestion');
	}

	//endregion

	clearFilterField(): void {
		this.filterSearchInput = '';
		console.log("TEST");
		this.filter();
	}

	// Retourne True si l'élément est affiché
	parentVisible(node: MatTreeFlatNode): boolean {
		if (node?.issueType === IssueTypeEnum.EPIC && node.nbChildren === 0 && this.activatedParameters.displayParams.find(s => s === 'hide_empty_epic')) {
			return false;
		}
		let parentNode: MatTreeFlatNode = this.treeControl.dataNodes.find(n => n.object.id === node?.parent?.object.id);
		if (!parentNode) {
			return this.treeControl.isExpandable(node) && !this.treeControl.isExpanded(node);
		}
		const parentList: MatTreeFlatNode[] = [];
		parentList.push(parentNode);
		// Récupération de la liste des parents
		while (parentNode.parent) {
			parentNode = this.treeControl.dataNodes.find(n => n.object.id === parentNode.parent.object.id);
			parentList.push(parentNode);
		}
		// Si un des parents est caché alors l'enfant l'est aussi
		for (let i: number = parentList.length - 1; i >= 0 ; i--) {
			if (parentList[i].issueType === IssueTypeEnum.EPIC) {
				const cast: LotDataSourceContent = parentList[i].object as LotDataSourceContent;
				return cast.visible;
			}
			if (this.treeControl.isExpandable(parentList[i]) && !this.treeControl.isExpanded(parentList[i])) {
				return false;
			}
		}
		return true;
	}


	dragDrop(event: CdkDragDrop<MatTreeFlatNode>): void {
		// ignore drops outside of the tree
		if (!event.isPointerOverContainer) {
			return;
		}

		const node: MatTreeFlatNode = event.item.data;

		if (event.previousIndex !== event.currentIndex) {
			let canReorder: boolean = false;

			if (node.isLot) {
				// Pas de contraintes sur le déplacement de lot
				canReorder = this._dragDropLot(node, event.previousIndex, event.currentIndex);
			} else {
				if (event.currentIndex === 0) {
					// On ne peut mettre qu'un lot en position 0
					canReorder = false;
				} else {
					switch (node.issueType) {
						case IssueTypeEnum.EPIC:
							canReorder = this._dragDropEpic(node, event.previousIndex, event.currentIndex);
							break;
						case IssueTypeEnum.STORY:
							canReorder = this._dragDropStory(node, event.previousIndex, event.currentIndex);
							break;
						case IssueTypeEnum.SUBTASK:
							canReorder = this._dragDropSubtask(node, event.previousIndex, event.currentIndex);
							break;
					}
				}
			}
			if (!canReorder) {
				this.snackbarMessageHandler(IssueDragDropMessageEnum.IMPOSSIBLE);
			}
		}
	}

	public getLotWithoutBacklogAndFinish(): LotDataSource[] {
		return this.matTreeDataSource.data.filter(node => node.isLot && node.object instanceof LotDataSource
			&& node.object.backlog === false
			&& (node.object.contract === ContractStatusEnum.DRAFT
				|| node.object.contract === ContractStatusEnum.OPEN
				|| node.object.contract === ContractStatusEnum.SIGNED))
			.map(node => node.object as LotDataSource);
	}

	setKeyboardSelectedObject(node: MatTreeFlatNode): void {
		const newkeybordSelectedObject: KeybordSelectedObject = {
			nodeIndex: this.treeControl.dataNodes.indexOf(node),
			node: node,
		};
		if (this.keyboardSelectedObject && (this.keyboardSelectedObject.nodeIndex === newkeybordSelectedObject.nodeIndex)) {
			this.toggleKeyboardSelectedObject(false);
			this.keyboardSelectedObject = null;
		} else {
			this.toggleKeyboardSelectedObject(false);
			this.keyboardSelectedObject = newkeybordSelectedObject;
			this.toggleKeyboardSelectedObject(true);
		}
	}

	//endregion

	// region status change
	async getIssuesNextStatus(elem: MatTreeFlatNode, col: HTMLDivElement): Promise<void> {
		col.style.cursor = 'wait';
		const obj: LotDataSourceContent = elem.object as LotDataSourceContent;
		if (!obj.id) {
			this._snackBar.open(this._translator.instant('PROJECT.SYNCHRONIZE_BEFORE_CHANGE_STATUS'), '', {
				panelClass: 'warning',
				duration: 5000,
			});
			col.style.cursor = 'default';
			return ;
		}

		const allowMove: boolean = await firstValueFrom(this._storyService.checkUpdateDates(obj.id)).catch(() => {
			col.style.cursor = 'default';
			return false;
		});
		if (!allowMove) {
			this._snackBar.open(this._translator.instant('PROJECT.SYNCHRONIZE_BEFORE_CHANGE_STATUS'), '', {
				panelClass: 'warning',
				duration: 5000,
			});
			col.style.cursor = 'default';
			return ;
		}

		const parent: LotDataSource = elem.parentLot as LotDataSource;
		if (!obj.jiraIntId || parent?.contract === ContractStatusEnum.FINISH) {
			col.style.cursor = 'default';
			return;
		}
		if (obj.updatingStatus) {
			obj.updatingStatus = false;
		} else {
			this._synchronizationService.getJiraTransition(obj.jiraIntId).pipe(first()).subscribe(res => {
				col.style.cursor = 'default';
				obj.updatingStatus = true;
				obj.availableNextStatus = res;
			});
		}
	}

	async changeIssueStatus(elem: LotDataSourceContent, status: JiraTransition): Promise<void> {
		// Vérifier que la story est bien dans le bon etat
		const allowMove: boolean = await firstValueFrom(this._storyService.checkUpdateDates(elem.object.id));

		if (!allowMove) {
			this._snackBar.open(this._translator.instant('PROJECT.SYNCHRONIZE_BEFORE_CHANGE_STATUS'), '', {
				panelClass: 'warning',
				duration: 5000,
			});
			return ;
		}

		this._synchronizationService.setJiraTransition(elem.jiraIntId, elem.id, status).pipe(first()).subscribe(res => {
			if (res.status === 400) {
				this._snackBar.open(this._translator.instant('PROJECT.JIRA_STATUS_NOT_GOOD'), '', {
					panelClass: 'error',
					duration: 5000,
				});
			}
			elem.status = {key: res.jiraStatus, value: res.jiraStatus};
			elem.availableNextStatus = res.transitions;
		});
	}
	// endregion

	async toggleExpandedState(item: LotDataSource | LotDataSourceContent, expandState?: boolean): Promise<void> {
		if (item instanceof LotDataSource) {
			const matTreeNode: MatTreeNode = this.matTreeNodeList.find(node =>
				node.isLot && node.object.id === item.id);
			matTreeNode.object.expanded = expandState !== undefined ? expandState : !matTreeNode.object.expanded;
			this._lotService.setExpandedState(item.id, matTreeNode.object.expanded);

			if (!item.isLoaded) {
				this._spinnerService.enableLoading();
				const result: IStoryDto[] = await firstValueFrom(this._storyService.findByLot(item.id));
				await this.projetDetailDataSource.populateLotWithStories(item, result);
				this._spinnerService.disableLoading();
			}
		} else {
			const matTreeNode: MatTreeNode = this.matTreeNodeList.find(node =>
				!node.isLot && node.lotId === item.lotId && node.issueType === item.type && node.object.id === item.id);
			matTreeNode.object.expanded = expandState !== undefined ? expandState : !matTreeNode.object.expanded;
			switch (item.type) {
				case IssueTypeEnum.EPIC:
					this._epicService.setExpandedState(item.id, item.lotId, matTreeNode.object.expanded);
					break;
				case IssueTypeEnum.STORY:
					if ((item.object as IStoryDto).subtasks.length) {
						this._storyService.setExpandedState(item.id, matTreeNode.object.expanded);
					}
					break;
			}
		}
	}

	//endregion

	//region MatTree
	private initMatTree(): void {
		this.treeControl = new FlatTreeControl<MatTreeFlatNode>(
			node => node.level, node => node.expandable);

		this.treeFlattener = new MatTreeFlattener<MatTreeNode, MatTreeFlatNode>(
			this.transformer, node => node.level, node => node.expandable, node => node.children);

		this.matTreeDataSource = new MatTreeFlatDataSource<MatTreeNode, MatTreeFlatNode>(this.treeControl, this.treeFlattener);
		this.projetDetailDataSource.connect(null).subscribe(data => {

			this.matTreeNodeList = [];
			this.backlogId = null;
			const matTreeNodes: MatTreeNode[] = [];
			for (const lotDataSource of data) {
				const newNode: MatTreeNode = {
					lotId: lotDataSource.id,
					object: lotDataSource,
					isLot: true,
					checked: false,
					parentLot: lotDataSource,
					parent: null,
					issueType: null,
					children: lotDataSource.content.map(c => this.mapToMatTreeNode(lotDataSource, lotDataSource, c)),
				};
				if (lotDataSource.backlog) {
					const newStories: LotDataSourceContent[] = [];

					lotDataSource.content.forEach(lotcontent => {
						if (lotcontent.type === 'EPIC') {
							newStories.push(...lotcontent.children);
						} else if (lotcontent.type === 'STORY') {
							newStories.push(lotcontent);
						}
					});
					lotDataSource.content = newStories;
					this.backlogId = lotDataSource.id;
					this.backlogList = lotDataSource;
					this.backlogDisplay = !!this.backlogList.content.find(s => s.type === 'STORY');
					this.backlogStories = newNode;
				} else {
					matTreeNodes.push(newNode);
					this.matTreeNodeList.push(newNode);
				}
			}
			this.matTreeDataSource.data = matTreeNodes;
			this.expandMatTreeNodes();
		});
	}

	private moveLineSelection(direction: number): void {
		this.toggleKeyboardSelectedObject(false);
		firstValueFrom(this.matTreeDataSource.connect(this.tree)).then(res => {
			const visibleNodes: MatTreeFlatNode[] = res.filter(node => this.shouldDisplayTreeNode(node, false));
			let currentIndex: number = visibleNodes.findIndex(node =>
				node.issueType === this.keyboardSelectedObject.node.issueType &&
				node.isLot === this.keyboardSelectedObject.node.isLot &&
				node.lotId === this.keyboardSelectedObject.node.lotId &&
				node.object.id === this.keyboardSelectedObject.node.object.id
			);
			if (currentIndex === -1) {
				this.keyboardSelectedObject.nodeIndex = 0;
				this.keyboardSelectedObject.node = this.treeControl.dataNodes[this.keyboardSelectedObject.nodeIndex];
			} else {
				currentIndex += direction;
				if (currentIndex >= 0 && currentIndex < visibleNodes.length) {
					this.keyboardSelectedObject.node = visibleNodes[currentIndex];
					this.keyboardSelectedObject.nodeIndex = this.treeControl.dataNodes.indexOf(this.keyboardSelectedObject.node);
				}
			}
			this.toggleKeyboardSelectedObject(true);
		});
	}

	/**
	 * Fonction à appeler après un ajout ou une suppression d'un node dans le MatTree.
	 * Les changements qui ne modifient pas la structure de la liste sont pris en compte sans appeler cette fonction.
	 */
	private matTreeDetectChanges(): void {
		const scrollContainer: HTMLElement = document.getElementById('main-content');
		const scrollPos: number = scrollContainer.scrollTop;
		this.matTreeDataSource.data = this.matTreeDataSource.data; // trigger change detection
		this.expandMatTreeNodes(); // need to re-expand nodes because they will be collapsed by default
		scrollContainer.scrollTop = scrollPos;
	}

	//endregion

	private expandMatTreeNodes(): void {
		const expand: any = (node: MatTreeNode) => {
			if (node.object.expanded) {
				this.treeControl.expand(this.treeControl.dataNodes.find(
					this.issueNode((node.object as LotDataSourceContent).type, node.object.id, node.lotId)));
			}
			node.children.forEach(expand);
		};
		for (const matTreeNode of this.matTreeDataSource.data) {
			if (matTreeNode.object instanceof LotDataSource) {
				if (matTreeNode.object.expanded) {
					// On expand le lot
					this.treeControl.expand(this.treeControl.dataNodes.find(this.lotNode(matTreeNode.object.id)));
				}
				// On expand les descendant s'ils sont à true
				(matTreeNode.children || []).forEach(expand);
			}
		}
	}

	private lotNode(id: number): any {
		return (node: MatTreeFlatNode | MatTreeNode) => (node.isLot && node.object.id === id);
	}

	private issueNode(issueType: IssueTypeEnum, id: number, lotId: number): any {
		return (node: MatTreeFlatNode | MatTreeNode) => (!node.isLot && node.lotId === lotId
			&& node.issueType === issueType && node.object.id === id);
	}

	private async expandMatTreeNode(lotId: number, epicId?: number, storyId?: number, expand: boolean = true): Promise<void> {
		let matTreeNode: MatTreeNode;
		let matTreeFlatNode: MatTreeFlatNode;
		if (storyId) {
			matTreeNode = this.matTreeNodeList.find(this.issueNode(IssueTypeEnum.STORY, storyId, lotId));
			matTreeFlatNode = this.treeControl.dataNodes.find(this.issueNode(IssueTypeEnum.STORY, storyId, lotId));
		} else if (epicId) {
			matTreeNode = this.matTreeNodeList.find(this.issueNode(IssueTypeEnum.EPIC, epicId, lotId));
			matTreeFlatNode = this.treeControl.dataNodes.find(this.issueNode(IssueTypeEnum.EPIC, epicId, lotId));
		} else {
			matTreeNode = this.matTreeNodeList.find(this.lotNode(lotId));
			matTreeFlatNode = this.treeControl.dataNodes.find(this.lotNode(lotId));
		}
		if (expand) {
			this.treeControl.expand(matTreeFlatNode);
		} else {
			this.treeControl.collapse(matTreeFlatNode);
		}
		await this.toggleExpandedState(matTreeNode.object, expand);
	}

	/**
	 * Insert new issue right under its parent
	 *
	 * @param parentId
	 * @param parentType
	 * @param lot
	 */
	private insertIntoMatTree(parentId: number, parentType: IssueTypeEnum, lot: LotDataSource): void {
		this.selectedIssueOriginal = Object.assign(new LotDataSourceContent(), this.selectedIssue);
		const newNode: MatTreeNode = {
			lotId: lot.id,
			isLot: false,
			parentLot: lot,
			checked: false,
			issueType: this.selectedIssue.type,
			parent: null,
			object: this.selectedIssueOriginal,
			children: [],
		};
		this.matTreeNodeList.push(newNode);
		if (parentId === null) {
			const matTreeLot: MatTreeNode = this.matTreeDataSource.data.find(node => node.object.id === lot.id);
			newNode.parent = matTreeLot.object;
			matTreeLot.children.splice(0, 0, newNode);
		} else {
			const parentNode: MatTreeNode = this.matTreeNodeList.find(node =>
				node.parentLot.id === lot.id && node.issueType === parentType && node.object.id === parentId);
			newNode.parent = parentNode.object;
			parentNode.children.splice(0, 0, newNode);
		}
		this.matTreeDetectChanges();
	}

	/**
	 * Delete issue without id
	 */
	private deleteNewIssueFromMatTree(): void {
		// Si le node est à la racine de matTreeDataSource, on l'enlève
		const index: number = this.matTreeDataSource.data.findIndex(n => !n.object.id);
		if (index !== -1) {
			this.matTreeDataSource.data.splice(index, 1);
		}
		// On l'enlève de la nodeList
		this.matTreeNodeList = this.matTreeNodeList.filter(n => !!n.object.id);
		// On l'enlève de son parent (comme c'est une référence, pas besoin de modifier matTreeDataSource pour ça)
		this.matTreeNodeList.forEach(n => n.children = n.children.filter(child => !!child.object.id));
		this.matTreeDetectChanges();
	}

	/**
	 * Ajouter un Node d'Epic dans un Lot, en dernière position par rapport aux autres epics,
	 * mais avant les stories orphelines, s'il y en a.
	 * @param lotNode
	 * @param epicNode
	 */
	private addEpicNodeToLot(lotNode: MatTreeNode, epicNode: MatTreeNode): void {
		const index: number = lotNode.children.findIndex(n => n.issueType !== IssueTypeEnum.EPIC);
		if (index !== -1) {
			lotNode.children.splice(index, 0, epicNode);
		} else {
			lotNode.children.push(epicNode);
		}
	}

	//endregion

	private updateMatTreeWithNewLot(createdLot: ILotDto): void {
		const lotDataSource: LotDataSource = Object.assign(new LotDataSource(), this.selectedLot);
		lotDataSource.id = createdLot.id;
		lotDataSource.contract = createdLot.contract;
		const newLotNode: MatTreeNode = {
			lotId: createdLot.id,
			isLot: true,
			parentLot: lotDataSource,
			checked: false,
			issueType: null,
			parent: null,
			object: lotDataSource,
			children: [],
		};
		// Si on a déjà au moins un lot (autre que backlog), reprendre ses epics
		if (this.matTreeDataSource.data.length > 1) {
			this.matTreeDataSource.data[0].children
				.filter(node => node.issueType === IssueTypeEnum.EPIC)
				.forEach(epicNode => {
					const epic: LotDataSourceContent = Object.assign(new LotDataSourceContent(), epicNode.object);
					epic.children = [];
					epic.totalChildrenCount = 0;
					epic.lotId = createdLot.id;
					epic.parentId = createdLot.id;
					epic.budget = 0;
					epic.remainingEXT = 0;
					epic.estimated = 0;
					epic.avancement = '0 %';
					const newEpicNode: MatTreeNode = {
						lotId: createdLot.id,
						isLot: false,
						checked: false,
						parentLot: lotDataSource,
						issueType: IssueTypeEnum.EPIC,
						parent: lotDataSource,
						object: epic,
						children: [],
					};
					newLotNode.children.push(newEpicNode);
					this.matTreeNodeList.push(newEpicNode);
				});
		}
		this.matTreeNodeList.push(newLotNode);
		// insérer avant la ligne de backlog
		this.matTreeDataSource.data.splice(this.matTreeDataSource.data.length - 1, 0, newLotNode);
		this.matTreeDetectChanges();
	}

	private isLotContratSigned(lotId: number): boolean {
		let isLotContratSigned: boolean = false;
		if (lotId) {
			const lot: MatTreeNode = this.matTreeNodeList.find(node => node.isLot && node.lotId === lotId);
			if (lot) {
				isLotContratSigned = (lot.object as LotDataSource).contract === ContractStatusEnum.SIGNED;
			}
		}
		return isLotContratSigned;
	}

	private async createStory(issue: LotDataSourceContent): Promise<void> {
		const story: IStoryDto = new StoryDto();
		story.name = issue.name.trim();
		story.type = issue.storyType;
		story.budget = issue.budget || 0;
		story.estimated = issue.estimated || 0;
		story.reporter = issue.reporter;
		story.lotId = issue.lotId;
		story.epicId = issue.parentId;

		if (this.isLotContratSigned(issue.lotId)) {
			this.openDialogRedistributionBudget('CREATION', issue, story).subscribe(async result => {
				if (result?.srcStory) {
					if (result.destStory) {
						await this.saveStory(result.destStory);
					}
					story.budget = result.srcStory.budget;
					await this.finishCreatingStory(story);
					this.recalculateTotalsForEpicAndLot(story.lotId, story.epicId);
					this.selectedIssueOriginal = undefined;
					this.selectedIssue = undefined;
				}
			});
		} else {
			await this.finishCreatingStory(story);
			this.recalculateTotalsForEpicAndLot(story.lotId, story.epicId);
			this.selectedIssueOriginal = undefined;
			this.selectedIssue = undefined;
		}
	}

	private async saveStory(story: LotDataSourceContent, recalculateTotals: boolean = true): Promise<void> {
		const savedStory: IStoryDto = await this._storyService.modifyStory(story.id,
			<IStoryCreateUpdateDto>{
				storyDto: <IStoryDto>{
					budget: story.budget,
				},
				jiraIntOnly: !this.projet.jiraExtId,
			});
		// modifier la story destination dans le mat tree
		const destNode: MatTreeNode = this.matTreeNodeList.find(node =>
			node.issueType === IssueTypeEnum.STORY && node.object.id === story.id);
		destNode.object.budget = savedStory.budget;
		(destNode.object as LotDataSourceContent).remainingEXT = savedStory.remainingEXT;
		(destNode.object as LotDataSourceContent).avancement = this.projetDetailDataSource.getAvancement(savedStory.budget,
			savedStory.remainingEXT);
		if (recalculateTotals) {
			this.recalculateTotalsForEpicAndLot(destNode.lotId, destNode.parent.id);
		}
	}

	private async finishCreatingStory(story: IStoryDto): Promise<void> {
		const createdIssue: IStoryDto = await this._storyService.createStory(<IStoryCreateUpdateDto>{
			storyDto: story,
			jiraIntOnly: !this.projet.jiraExtId,
		});

		Object.assign(this.selectedIssueOriginal, createdIssue);
		// Déplacer la nouvelle story à la fin de sa liste
		const epicNode: MatTreeNode = this.matTreeNodeList.find(node => node.issueType === IssueTypeEnum.EPIC
			&& node.object.id === this.selectedIssueOriginal.parentId && node.lotId === this.selectedIssueOriginal.lotId);
		epicNode.children.push(epicNode.children.splice(0, 1)[0]);
		this.matTreeDetectChanges();
		this.selectedIssueOriginal.type = IssueTypeEnum.STORY;
		this.selectedIssueOriginal.status = {
			key: createdIssue.status,
			value: createdIssue.status.replace('_', ' '),
		};
		this.selectedIssueOriginal.avancement = '0 %';
		this._snackBar.open(this._translator.instant('STORY.CREATED'));
	}

	private async editStory(issue: LotDataSourceContent): Promise<void> {
		if (this.selectedIssueOriginal.budget !== issue.budget
			&& this.isLotContratSigned(issue.lotId)) {
			await this.openDialogRedistributionBudgetModification(issue);
		} else {
			await this.finishEditingStory(issue);
		}
	}

	private async finishEditingStory(issue: LotDataSourceContent): Promise<void> {
		const savedIssue: IStoryDto = await this._storyService.modifyStory(issue.id,
			<IStoryCreateUpdateDto>{
				storyDto: <IStoryDto>{
					name: issue.name,
					budget: issue.budget,
					estimated: issue.estimated,
				},
				jiraIntOnly: !this.projet.jiraExtId,
			});
		this.projetDetailDataSource.findProjectAlert(this.projet.id);
		Object.assign(this.selectedIssueOriginal, issue);
		this.selectedIssueOriginal.name = savedIssue.name;
		this.selectedIssueOriginal.budget = savedIssue.budget;
		this.selectedIssueOriginal.remainingEXT = savedIssue.remainingEXT;
		this.selectedIssueOriginal.estimated = savedIssue.estimated;
		this.selectedIssueOriginal.avancement = this.projetDetailDataSource.getAvancement(this.selectedIssueOriginal.budget,
			this.selectedIssueOriginal.remainingEXT);
		this.recalculateTotalsForEpicAndLot(this.selectedIssueOriginal.lotId, this.selectedIssueOriginal.parentId);
		this._snackBar.open(this._translator.instant('STORY.MODIFIED'));
	}

	private async createSubtask(issue: LotDataSourceContent): Promise<void> {
		const subtask: ISubtaskDto = new SubtaskDto();
		subtask.name = issue.name.trim();
		subtask.type = issue.subtaskType;
		subtask.storyId = issue.parentId;
		subtask.estimated = issue.estimated;
		subtask.reporter = issue.reporter;
		const createdIssue: ISubtaskDto = await this._subtaskService.createSubtask(subtask);
		Object.assign(this.selectedIssueOriginal, createdIssue);
		// Déplacer la nouvelle subtask à la fin de sa liste
		const storyNode: MatTreeNode = this.matTreeNodeList.find(node =>
			node.issueType === IssueTypeEnum.STORY && node.object.id === this.selectedIssueOriginal.parentId);
		storyNode.children.push(storyNode.children.splice(0, 1)[0]);
		this.matTreeDetectChanges();
		this.selectedIssueOriginal.type = IssueTypeEnum.SUBTASK;
		this._snackBar.open(this._translator.instant('SUBTASK.CREATED'));
	}

	private async editSubtask(issue: LotDataSourceContent): Promise<void> {
		await this._subtaskService.modifySubtask(issue.id, <ISubtaskDto>{
			name: issue.name,
			estimated: issue.estimated,
		});
		Object.assign(this.selectedIssueOriginal, issue);
		this._snackBar.open(this._translator.instant('SUBTASK.MODIFIED'));
	}

	private async createEpic(issue: LotDataSourceContent): Promise<void> {
		const epic: IEpicDto = new EpicDto();
		epic.name = issue.name.trim();
		epic.reporter = issue.reporter;
		epic.projectId = this.projet.id;
		epic.expandedInLots = JSON.stringify(this.matTreeDataSource.data.filter(node => node.isLot
			&& !(node.object as LotDataSource).backlog).map(node => node.lotId));
		const createdIssue: IEpicDto = await this._epicService.createEpic(epic);
		Object.assign(this.selectedIssueOriginal, createdIssue);

		// Déplacer l'epic en bas du lot (coté serveur il aura déjà l'order le plus élevé)
		const parentLot: MatTreeNode = this.matTreeNodeList.find(node =>
			node.isLot && node.object.id === this.selectedIssueOriginal.lotId);
		// On avait ajouté l'epic en première position lorsqu'il était en édition, il suffit de splice.
		this.addEpicNodeToLot(parentLot, parentLot.children.splice(0, 1)[0]);

		// Ajouter le nouvel epic dans les autres lots
		this.matTreeDataSource.data.forEach(lotNode => {
			if (lotNode.isLot && !(lotNode.object as LotDataSource).backlog && lotNode.lotId !== this.selectedIssueOriginal.lotId) {
				const newEpicNode: MatTreeNode = {
					lotId: lotNode.object.id,
					isLot: false,
					checked: false,
					parentLot: lotNode.object as LotDataSource,
					issueType: IssueTypeEnum.EPIC,
					parent: lotNode.object,
					object: Object.assign(new LotDataSourceContent(), this.selectedIssueOriginal),
					children: [],
				};
				(newEpicNode.object as LotDataSourceContent).children = [];
				(newEpicNode.object as LotDataSourceContent).totalChildrenCount = 0;
				(newEpicNode.object as LotDataSourceContent).lotId = lotNode.object.id;
				(newEpicNode.object as LotDataSourceContent).parentId = lotNode.object.id;
				this.addEpicNodeToLot(lotNode, newEpicNode);
				this.matTreeNodeList.push(newEpicNode);
			}
		});
		this.matTreeDetectChanges();
		this._snackBar.open(this._translator.instant('EPIC.CREATED'));
	}

	private async editEpic(issue: LotDataSourceContent): Promise<void> {
		const savedEpic: IEpicDto = await this._epicService.modifyEpic(issue.id, <IEpicDto>{
			name: issue.name,
			reporter: issue.reporter,
		});
		// appliquer les changements sur l'epic dans tous les lots
		this.matTreeNodeList
			.filter(node => node.issueType === IssueTypeEnum.EPIC && node.object.id === issue.id)
			.forEach(node => {
				node.object.name = savedEpic.name;
				(node.object as LotDataSourceContent).reporter = savedEpic.reporter;
			});
		this._snackBar.open(this._translator.instant('EPIC.MODIFIED'));
	}

	//endregion

	//region Recalculate totals
	/**
	 * Re-calculate totals for given epic and lot
	 * @param lotId
	 * @param epicId
	 */
	private recalculateTotalsForEpicAndLot(lotId: number,
										   epicId?: number): void {
		const lotNode: MatTreeNode = this.matTreeDataSource.data.find(node =>
			node.isLot && node.object.id === lotId);
		if (!epicId && this.selectedIssue) {
			epicId = this.selectedIssue.parentId;
		}
		if (epicId) {
			const epicNode: MatTreeNode = lotNode.children.find(node =>
				node.issueType === IssueTypeEnum.EPIC && node.object.id === epicId);
			if (epicNode) {
				this.recalculateTotalEpic(epicNode);
			}
		}
		this.recalculateTotalLot(lotNode);
		this.recalculateTotalProjet();
	}

	private recalculateEverything(): void {
		this.projet.budget = 0;
		this.projet.remainingEXTSum = 0;
		this.matTreeDataSource.data.filter(node => node.isLot).forEach(lotNode => {
			lotNode.children.filter(lotChild => lotChild.issueType === IssueTypeEnum.EPIC).forEach(epicNode => {
				this.recalculateTotalEpic(epicNode);
			});
			this.recalculateTotalLot(lotNode);
			if ((lotNode.object as LotDataSource).contract === ContractStatusEnum.SIGNED
				|| (lotNode.object as LotDataSource).contract === ContractStatusEnum.FINISH) {
				this.projet.budget += lotNode.object.budget;
				this.projet.remainingEXTSum += (lotNode.object as LotDataSource).RAE;
			}
		});
	}

	//region Drag/Drop

	private recalculateTotalLot(lotNode: MatTreeNode): void {
		const lotObject: LotDataSource = lotNode.object as LotDataSource;
		lotObject.budget = 0;
		lotObject.estimated = 0;
		lotObject.RAE = 0;
		lotObject.nombreDeStory = 0;
		lotNode.children.forEach(lotChild => {
			lotObject.budget += lotChild.object.budget || 0;
			lotObject.estimated += lotChild.object.estimated || 0;
			lotObject.RAE += (lotChild.object as LotDataSourceContent).remainingEXT || 0;
			// Can be epic or orphan story
			if (lotChild.issueType === IssueTypeEnum.EPIC) {
				lotObject.nombreDeStory += (lotChild.children || []).length;
			} else if (lotChild.issueType === IssueTypeEnum.STORY) {
				lotObject.nombreDeStory++;
			}
		});
		lotObject.avancement = this.projetDetailDataSource.getAvancement(lotObject.budget, lotObject.RAE);
	}

	private recalculateTotalEpic(epicNode: MatTreeNode): void {
		const epicObject: LotDataSourceContent = (epicNode.object as LotDataSourceContent);
		epicObject.budget = 0;
		epicObject.remainingEXT = 0;
		epicObject.estimated = 0;
		epicNode.children.forEach(storyNode => {
			epicObject.budget += storyNode.object.budget || 0;
			epicObject.remainingEXT += (storyNode.object as LotDataSourceContent).remainingEXT || 0;
			epicObject.estimated += storyNode.object.estimated || 0;
		});
		epicObject.avancement = this.projetDetailDataSource.getAvancement(
			epicObject.budget, epicObject.remainingEXT);
	}

	private recalculateTotalProjet(): void {
		this.projet.budget = 0;
		this.projet.remainingEXTSum = 0;
		this.matTreeDataSource.data.filter(node => node.isLot).forEach(lotNode => {
			if ((lotNode.object as LotDataSource).contract === ContractStatusEnum.SIGNED
				|| (lotNode.object as LotDataSource).contract === ContractStatusEnum.FINISH) {
				this.projet.budget += lotNode.object.budget;
				this.projet.remainingEXTSum += (lotNode.object as LotDataSource).RAE;
			}
		});
	}

	private async moveStories(lotId: number, issues: (LotDataSourceContent | IStoryDto)[]): Promise<void> {
		const stories: StoryDto[] = [];
		for (const issue of issues) {
			if (issue.type === IssueTypeEnum.STORY) {
				const story: StoryDto = new StoryDto();
				story.id = issue.id;
				story.lotId = lotId;
				stories.push(story);
			}
		}
		await this._storyService.moveStoriesToLot(stories);
		this.projetDetailDataSource.findProjectAlert(this.projet.id);
		this._snackBar.open(this._translator.instant('PROJECT.DETAIL.MOVE_STORY.STORIES_MOVED',
			{nbStories: this.selectedIssues.size}), '', {
			panelClass: 'success',
		});
		this.selectedIssues.clear();
		const newLotNode: MatTreeNode = this.matTreeNodeList.find(node => node.isLot && node.object.id === lotId);
		const newLot: LotDataSource = newLotNode.object as LotDataSource;
		// Déplacer les stories dans le mat-tree
		for (const story of issues) {
			const storyNode: MatTreeNode = this.matTreeNodeList.find(node =>
				node.issueType === IssueTypeEnum.STORY && node.object.id === story.id);
			const storyObject: LotDataSourceContent = (storyNode.object as LotDataSourceContent);
			// Déplacer le node à son nouvel emplacement
			// orphan => se trouve dans un lot mais pas d'Epic pas ou dans le Backlog (pas de Lot)
			if ((storyObject.object as IStoryDto).lot.backlog) {
				// where  => test savoir si les issues sont dans le tree ou dans le backlog
				const where: MatTreeNode = this.matTreeNodeList.find(node => node.isLot && node.lotId === storyNode.lotId);
				const oldLotNode: MatTreeNode =  where ? where : this.backlogStories;
				const oldLot: LotDataSource = where ? oldLotNode.object as LotDataSource : this.backlogList;
				storyNode.parent = newLotNode.object;
				oldLotNode.children.splice(oldLotNode.children.indexOf(storyNode), 1);
				oldLot.content.splice(oldLot.content.indexOf(storyObject), 1);
				newLotNode.children.push(storyNode);
				newLot.content.push(storyObject);
			} else {
				const oldEpicNode: MatTreeNode = this.matTreeNodeList.find(node =>
					node.issueType === IssueTypeEnum.EPIC && node.lotId === storyNode.lotId && node.object.id === storyNode.parent.id);
				const oldEpic: LotDataSourceContent = oldEpicNode.object as LotDataSourceContent;
				const newEpicNode: MatTreeNode = this.matTreeNodeList.find(node =>
					node.issueType === IssueTypeEnum.EPIC && node.lotId === lotId && node.object.id === storyNode.parent.id);
				const newEpic: LotDataSourceContent = newEpicNode.object as LotDataSourceContent;
				storyNode.parent = newEpicNode.object;
				oldEpicNode.children.splice(oldEpicNode.children.indexOf(storyNode), 1);
				oldEpic.children.splice(oldEpic.children.indexOf(storyObject), 1);
				newEpicNode.children.push(storyNode);
				newEpic.children.push(storyObject);
			}
			storyNode.lotId = lotId;
			storyNode.parentLot = newLotNode.object as LotDataSource;
			(storyNode.children || []).forEach(subtask => {
				subtask.lotId = lotId;
				subtask.parentLot = newLotNode.object as LotDataSource;
				(subtask.object as LotDataSourceContent).lotId = lotId;
			});
			storyObject.lotId = lotId;
			storyObject.parentId = storyNode.parent.id;
		}
		this.recalculateEverything();
		this.matTreeDetectChanges();
	}

	private openDialogRedistributionBudget(action: string, issue: LotDataSourceContent,
										   story?: IStoryDto): Observable<RedistributionBudgetResult> {
		const dialogRef: MatDialogRef<DialogRedistributionBudgetComponent> = this.dialog.open(DialogRedistributionBudgetComponent, {
			width: '550px',
		});
		dialogRef.componentInstance.stories = this.matTreeNodeList.filter(node => !node.isLot
			&& node.issueType === IssueTypeEnum.STORY && node.lotId === issue.lotId).map(node => node.object as LotDataSourceContent);
		if (story) {
			dialogRef.componentInstance.srcStory = Object.assign(new LotDataSourceContent(), story);
		} else {
			dialogRef.componentInstance.srcStory = dialogRef.componentInstance.stories
				.find(value => value.id === issue.id);
		}

		dialogRef.componentInstance.action = action;
		if (action === 'EDIT') {
			dialogRef.componentInstance.newBudget = issue.budget;
		} else if (action === 'DELETE') {

			dialogRef.componentInstance.newBudget = 0;
		}

		return dialogRef.afterClosed();
	}

	private getNodeBefore(oldIndex: number, newIndex: number): MatTreeFlatNode {
		const indexNodeBefore: number = newIndex - 1;

		const flatVisible: MatTreeFlatNode[] = this.treeControl.dataNodes
			.filter(el =>
				(el.isLot && this.shouldDisplayTreeNode(el, true))
				|| (el.parent?.expanded && this.shouldDisplayTreeNode(el, true)));

		moveItemInArray(flatVisible, oldIndex, newIndex);

		return flatVisible[indexNodeBefore];
	}

	private _dragDropLot(node: MatTreeFlatNode, oldIndex: number, newIndex: number): boolean {
		const nodeBefore: MatTreeFlatNode = newIndex > 0 ? this.getNodeBefore(oldIndex, newIndex) : null;
		const lotBeforeId: number = nodeBefore ? nodeBefore.parentLot.id : null;
		if (oldIndex === newIndex) {
			return true;
		}

		const oldIndexInLot: number = this.matTreeDataSource.data.findIndex(n =>
			n.isLot && n.object.id === node.object.id);
		let newIndexInLot: number = !lotBeforeId ? 0 : this.matTreeDataSource.data.findIndex(n =>
			n.isLot && n.object.id === lotBeforeId);

		if (lotBeforeId) {
			newIndexInLot = this.correctIndex(newIndexInLot, oldIndex, newIndex);
		}

		moveItemInArray(this.matTreeDataSource.data, oldIndexInLot, newIndexInLot);
		// Refresh affichage
		this.matTreeDetectChanges();

		// Effectue les modifs côté serveur
		this._lotService.reorderLot(node.parentLot.id, lotBeforeId, this.projet.id);
		this.snackbarMessageHandler(IssueDragDropMessageEnum.ORDER_CHANGED);
		return true;
	}

	private _dragDropEpic(node: MatTreeFlatNode, oldIndex: number, newIndex: number): boolean {
		const lot: LotDataSource = node.parentLot;
		const lotNode: MatTreeNode = this.matTreeDataSource.data.find(n => n.isLot && n.object.id === lot.id);

		const currentEpic: LotDataSourceContent = node.object as LotDataSourceContent;
		const nodeBefore: MatTreeFlatNode = this.getNodeBefore(oldIndex, newIndex);

		let epicBefore: LotDataSourceContent;
		if (!nodeBefore.isLot) {
			switch (nodeBefore.issueType) {
				case IssueTypeEnum.EPIC:
					epicBefore = nodeBefore.object as LotDataSourceContent;
					break;
				case IssueTypeEnum.STORY:
					epicBefore = nodeBefore.parent as LotDataSourceContent;
					break;
				case IssueTypeEnum.SUBTASK:
					const storyParentOfSubtaskAbove: LotDataSourceContent = nodeBefore.parent as LotDataSourceContent;
					epicBefore = nodeBefore.parentLot.content.find(item => item.children.indexOf(storyParentOfSubtaskAbove) !== -1);
					break;
			}
			if (epicBefore.lotId !== lot.id) {
				// Ca n'a pas de sens de déplacer un epic dans un lot différent, car ils sont transverses.
				return true;
			}
		}
		// Déplacer les nodes du MatTree

		const oldIndexInLot: number = lotNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.EPIC && n.object.id === currentEpic.id);
		let newIndexInLot: number = !epicBefore ? 0 : lotNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.EPIC && n.object.id === epicBefore.id);
		if (epicBefore) {
			newIndexInLot = this.correctIndex(newIndexInLot, oldIndex, newIndex);
		}
		if (oldIndexInLot === newIndexInLot) {
			return true;
		}

		moveItemInArray(lotNode.children, oldIndexInLot, newIndexInLot);
		// Refresh affichage
		this.matTreeDetectChanges();

		// Effectue les modifs côté serveur
		const epicBeforeId: number = epicBefore ? epicBefore.id : null;
		this._epicService.reorderEpic(currentEpic.id, epicBeforeId, this.projet.id);
		this.snackbarMessageHandler(IssueDragDropMessageEnum.ORDER_CHANGED);
		return true;
	}

	private _dragDropStory(node: MatTreeFlatNode, oldIndex: number, newIndex: number): boolean {
		const currentStory: LotDataSourceContent = node.object as LotDataSourceContent;
		let newEpicParent: LotDataSourceContent;
		let storyBeforeId: number;

		const nodeBefore: MatTreeFlatNode = this.getNodeBefore(oldIndex, newIndex);
		if (nodeBefore.isLot) {
			// On ne peut pas mettre une story directement dans un lot, il faut la mettre dans un epic
			return false;
		}
		const issueBefore: LotDataSourceContent = nodeBefore.object as LotDataSourceContent;
		switch (nodeBefore.issueType) {
			case IssueTypeEnum.EPIC:
				// L'EPIC au dessus est l'issue juste au dessus
				newEpicParent = issueBefore;
				break;
			case IssueTypeEnum.STORY:
				if (issueBefore.orphan) {
					// On ne peut pas reorder des story orphelines
					return false;
				}
				// L'EPIC d'origine est le parent de la STORY juste au dessus
				newEpicParent = nodeBefore.parent as LotDataSourceContent;
				// Déplacer la STORY en dessous des enfants de la STORY au dessus
				storyBeforeId = issueBefore.id;
				break;
			case IssueTypeEnum.SUBTASK:
				// De la SUBTASK juste au dessus, on récup la STORY parent
				const storyParent: LotDataSourceContent = nodeBefore.parent as LotDataSourceContent;
				// De la STORY parent, on récup la EPIC parent
				newEpicParent = nodeBefore.parentLot.content.find(item => item.children.indexOf(storyParent) !== -1);
				// Déplacer la STORY en dessous des enfants de la STORY au dessus
				storyBeforeId = storyParent.id;
				break;
		}
		if (storyBeforeId === currentStory.id) {
			return true;
		}

		if (node.parentLot.id !== newEpicParent.lotId) {
			// Pour l'instant on ne permet pas le drag/drop inter-lot (à faire plus tard) todo
			return true;
		}

		const oldEpicParent: LotDataSourceContent = node.parent as LotDataSourceContent;
		if (oldEpicParent.type === IssueTypeEnum.EPIC && oldEpicParent.id === newEpicParent.id) {
			// On a déplacé la story au sein du même epic
			this.moveStoryWithinEpic(node, oldEpicParent, currentStory, storyBeforeId, oldIndex, newIndex);
		} else {
			// On a déplacé la story vers un nouvel epic
			this.moveStoryToNewEpic(node, oldEpicParent, currentStory, storyBeforeId, newEpicParent);
		}
		return true;
	}

	//endregion

	private moveStoryWithinEpic(node: MatTreeFlatNode, oldEpicParent: LotDataSourceContent,
								currentStory: LotDataSourceContent, storyBeforeId: number, oldIndex: number, newIndex: number): void {
		// Déplacer les nodes du MatTree
		const lotNode: MatTreeNode = this.matTreeDataSource.data.find(n => n.isLot && n.object.id === node.lotId);
		const epicNode: MatTreeNode = lotNode.children.find(n => n.issueType === IssueTypeEnum.EPIC && n.object.id === oldEpicParent.id);
		const oldIndexInEpic: number = epicNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.STORY && n.object.id === currentStory.id);
		let newIndexInEpic: number = !storyBeforeId ? 0 : epicNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.STORY && n.object.id === storyBeforeId);
		if (storyBeforeId) {
			newIndexInEpic = this.correctIndex(newIndexInEpic, oldIndex, newIndex);
		}
		if (oldIndexInEpic !== newIndexInEpic) {
			moveItemInArray(epicNode.children, oldIndexInEpic, newIndexInEpic);
			// Refresh affichage
			this.matTreeDetectChanges();

			// Effectue les modifs côté serveur
			this._storyService.reorderStoryInEpic(currentStory.id, storyBeforeId, oldEpicParent.id);
			this.snackbarMessageHandler(IssueDragDropMessageEnum.ORDER_CHANGED);
		}
	}

	private moveStoryToNewEpic(node: MatTreeFlatNode, oldEpicParent: LotDataSourceContent, currentStory: LotDataSourceContent,
							   storyBeforeId: number, newEpicParent: LotDataSourceContent): void {

		let storyNode: MatTreeNode;
		if (currentStory.orphan) {
			// S’il était orphelin (sans EPIC)
			// Plus orphelin
			currentStory.orphan = false;
			// Les subtasks sont visibles
			for (const child of currentStory.children) {
				child.visible = true;
			}
			// Récupérer le node dans le MatTree et l'enlever de la racine
			const parentLotNode: MatTreeNode = this.matTreeDataSource.data.find(n => n.isLot && n.object.id === currentStory.lotId);
			storyNode = parentLotNode.children.splice(parentLotNode.children.findIndex(n =>
				(n.object as LotDataSourceContent).typeId === currentStory.typeId), 1)[0];
		} else {
			// Enlever 1 enfant à l'EPIC précédent
			oldEpicParent.children.splice(oldEpicParent.children.findIndex(value => value.typeId === currentStory.typeId), 1);
			oldEpicParent.totalChildrenCount -= (1 + currentStory.children.length);
			oldEpicParent.budget -= currentStory.budget;
			oldEpicParent.remainingEXT -= currentStory.remainingEXT;
			oldEpicParent.estimated -= currentStory.estimated;
			oldEpicParent.avancement = this.projetDetailDataSource.getAvancement(oldEpicParent.budget, oldEpicParent.remainingEXT);
			// Enlever la story dans le MatTree
			const oldLotNode: MatTreeNode = this.matTreeDataSource.data.find(n => n.isLot && n.object.id === node.lotId);
			const oldEpicNode: MatTreeNode = oldLotNode.children.find(n =>
				n.issueType === IssueTypeEnum.EPIC && n.object.id === oldEpicParent.id);
			// Récupérer le node dans le MatTree et l'enlever de son ancien parent
			storyNode = oldEpicNode.children.splice(oldEpicNode.children.findIndex(n =>
				(n.object as LotDataSourceContent).typeId === currentStory.typeId), 1)[0];
		}

		// Ajouter 1 enfant au nouvel EPIC
		let index: number = 0;
		if (storyBeforeId) {
			index = 1 + newEpicParent.children.findIndex(s => s.type === IssueTypeEnum.STORY && s.id === storyBeforeId);
		}
		newEpicParent.children.splice(index, 0, currentStory);
		newEpicParent.totalChildrenCount += (1 + currentStory.children.length);
		newEpicParent.budget = (newEpicParent.budget || 0) + currentStory.budget;
		newEpicParent.remainingEXT = (newEpicParent.remainingEXT || 0) + currentStory.remainingEXT;
		newEpicParent.estimated = (newEpicParent.estimated || 0) + currentStory.estimated;
		newEpicParent.avancement = this.projetDetailDataSource.getAvancement(newEpicParent.budget, newEpicParent.remainingEXT);

		// Ajouter la story dans le MatTree
		const newLotNode: MatTreeNode = this.matTreeDataSource.data.find(n => n.isLot && n.object.id === newEpicParent.lotId);
		const newEpicNode: MatTreeNode = newLotNode.children.find(n =>
			n.issueType === IssueTypeEnum.EPIC && n.object.id === newEpicParent.id);
		index = 0;
		if (storyBeforeId) {
			index = 1 + newEpicNode.children.findIndex(n =>
				n.issueType === IssueTypeEnum.STORY && n.object.id === storyBeforeId);
		}
		newEpicNode.children.splice(index, 0, storyNode);
		storyNode.parent = newEpicParent;
		currentStory.parentId = newEpicParent.id;
		currentStory.parentTypeId = newEpicParent.typeId;
		currentStory.parentType = newEpicParent.type;

		// Refresh affichage
		this.matTreeDetectChanges();

		this._storyService.switchStoryEpic(currentStory.id, storyBeforeId, newEpicParent.id).finally(() => {
			this.projetDetailDataSource.findProjectAlert(this.projet.id);
		});
		this.snackbarMessageHandler(IssueDragDropMessageEnum.PARENT_CHANGED);
	}

	/**
	 * On ne peut drag une SUBTASK que si elle reste dans sa STORY
	 */
	private _dragDropSubtask(node: MatTreeFlatNode, oldIndex: number, newIndex: number): boolean {
		const storyParent: LotDataSourceContent = node.parent as LotDataSourceContent;
		const currentSubtask: LotDataSourceContent = node.object as LotDataSourceContent;

		const nodeBefore: MatTreeFlatNode = this.getNodeBefore(oldIndex, newIndex);
		if (nodeBefore.isLot) {
			// On doit rester dans une story, impossible d'aller directement sur un lot
			return false;
		}
		let idBefore: number;
		switch (nodeBefore.issueType) {
			case IssueTypeEnum.EPIC:
				// On doit rester dans une story, impossible d'aller directement sur un epic
				return false;
			case IssueTypeEnum.STORY:
				if (nodeBefore.object.id !== storyParent.id) {
					// On doit rester dans sa propre story
					return false;
				}
				break;
			case IssueTypeEnum.SUBTASK:
				if (nodeBefore.parent.id !== storyParent.id) {
					// On doit rester dans sa propre story
					return false;
				}
				idBefore = nodeBefore.object.id;
				break;
		}

		// Déplacer les nodes du MatTree
		const storyNode: MatTreeNode = this.matTreeNodeList.find(n =>
			n.issueType === IssueTypeEnum.STORY && n.object.id === storyParent.id);
		const oldIndexInStory: number = storyNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.SUBTASK && n.object.id === currentSubtask.id);
		let newIndexInStory: number = !idBefore ? 0 : storyNode.children.findIndex(n =>
			n.issueType === IssueTypeEnum.SUBTASK && n.object.id === idBefore);
		if (idBefore) {
			newIndexInStory = this.correctIndex(newIndexInStory, oldIndex, newIndex);
		}
		moveItemInArray(storyNode.children, oldIndexInStory, newIndexInStory);
		// Refresh affichage
		this.matTreeDetectChanges();

		// Effectue les modifs côté serveur
		this._subtaskService.reorderSubtaskInStory(currentSubtask.id, idBefore, storyParent.id);
		this.snackbarMessageHandler(IssueDragDropMessageEnum.ORDER_CHANGED);
		return true;
	}

	/**
	 * Correction de l'index en vue d'utiliser moveItemInArray()
	 * les index sont décalés quand on est dans le sens de la montée (visuellement),
	 * càd quand oldIndex est plus élevé que newIndex.
	 * L'élément déplacé n'est pas pris en compte dans les index.
	 * (event.currentIndex semble être déterminé en comptant à partir de la fin. Bizarre mais vrai)
	 * On ajoute 1 pour corriger et avoir un comportement stable quel que soit le sens de déplacement.
	 *
	 * @param indexToCorrect
	 * @param oldIndex
	 * @param newIndex
	 */
	private correctIndex(indexToCorrect: number, oldIndex: number, newIndex: number): number {
		if (oldIndex > newIndex) {
			return indexToCorrect + 1;
		}
		return indexToCorrect;
	}

	private toggleKeyboardSelectedObject(selected: boolean): void {
		if (this.keyboardSelectedObject?.node) {
			this.keyboardSelectedObject.node.object.isKeyboardSelected = selected;
		}
	}
}
