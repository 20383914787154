
<div class="header-container" [class.side-menu-closed]="!headerService.menuOpen">
	<div *ngIf="spinnerService.isLoading | async; then coloredBarAnimated else coloredBar"></div>
	<ng-template #coloredBarAnimated>
		<div class="colored-bar-animated"></div>
		<div class="loading-overlay" [@fadeInFadeOutAnim]></div>
	</ng-template>
	<ng-template #coloredBar>
		<div class="colored-bar"></div>
	</ng-template>
	<div class="path">
		<div>// {{displayBreadCrumb}}</div>
	</div>
	<div class="d-flex flex-row gap-4 pt-2">
		<ng-content>
		</ng-content>
	</div>
</div>
