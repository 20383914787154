import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {MatTreeFlatNode} from '../projet-detail.component';
import {LotDataSource, LotDataSourceContent} from '../datasource/projet-detail.datasource';

@Directive({
	selector: '[appTreeNodeClasses]',
})
export class TreeNodeClassesDirective implements OnChanges {

	@Input() appTreeNodeClasses: MatTreeFlatNode;
	@Input() shouldDisplayTreeNode: (node: MatTreeFlatNode, forceEmptyEpics: boolean) => boolean;
	@Input() isIssueForm: (node: MatTreeFlatNode) => boolean;
	// For changes detection
	@Input() selectedIssue: any;
	// For changes detection
	@Input() hideEmptyEpic: boolean;

	constructor(private renderer: Renderer2, private hostElement: ElementRef) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setClasses();
	}

	private setClasses(): void {
		const obj: LotDataSource | LotDataSourceContent = this.appTreeNodeClasses?.object;

		if (obj instanceof LotDataSourceContent) {
			this.renderer.addClass(this.hostElement.nativeElement, obj.type);

			if (obj.orphan) {
				this.renderer.addClass(this.hostElement.nativeElement, 'orphan-issue');
			} else {
				this.renderer.removeClass(this.hostElement.nativeElement, 'orphan-issue');
			}
		}
		if (obj instanceof LotDataSource) {
			if (obj.backlog) {
				this.renderer.addClass(this.hostElement.nativeElement, 'backlog');
			} else {
				this.renderer.removeClass(this.hostElement.nativeElement, 'backlog');
			}
		}

		if (this.shouldDisplayTreeNode && !this.shouldDisplayTreeNode(this.appTreeNodeClasses, false)) {
			this.renderer.addClass(this.hostElement.nativeElement, 'hidden');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'hidden');
		}
		if (this.appTreeNodeClasses.parent && this.appTreeNodeClasses.parentLot.backlog) {
			this.renderer.addClass(this.hostElement.nativeElement, 'backlog-issue');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'backlog-issue');
		}
		if (this.appTreeNodeClasses.isLot) {
			this.renderer.addClass(this.hostElement.nativeElement, 'lot-row');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'lot-row');
		}
		if (obj.isKeyboardSelected) {
			this.renderer.addClass(this.hostElement.nativeElement, 'keyboard-selected');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'keyboard-selected');
		}
		if (this.isIssueForm && this.isIssueForm(this.appTreeNodeClasses)) {
			this.renderer.addClass(this.hostElement.nativeElement, 'edit-issue');
		} else {
			this.renderer.removeClass(this.hostElement.nativeElement, 'edit-issue');
		}
	}
}
