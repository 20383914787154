import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Observable} from 'rxjs';
import {Pagination} from '../../../pagination/dto/pagination.dto';
import {INumberAlertsDto, IProjectAlertDto, IProjectNumberAlertsDto, ProjectAlertTypeEnum} from './project-alert.dto';
import {ProjectAlertFilter} from '../../../../project-alert/dto/project-alert-filter.dto';

@Injectable({
	providedIn: 'root'
})
export class ProjectAlertService extends ApiService {

	private route: string = '/api/project-alert';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	findAllPaging(paginationOption: PaginationOption): Observable<Pagination<IProjectAlertDto>> {
		return this.post(this.route + '/paging/all', {paginationOption});
	}

	findFilterPaging(projectAlertFilter: ProjectAlertFilter, paginationOption: PaginationOption): Observable<Pagination<IProjectAlertDto>> {
		return this.post(this.route + '/paging/filter', {projectAlertFilter, paginationOption});
	}

	findByProject(projectId: number): Observable<IProjectAlertDto[]> {
		return this.get(this.route + '/' + projectId);
	}

	findNbAlertsByProject(projectId: number): Observable<INumberAlertsDto[]> {
		return this.get(this.route + '/nbAlerts/' + projectId);
	}

	findNbAlertsByProjects(projectIds: number[]): Observable<IProjectNumberAlertsDto[]> {
		return this.post(this.route + '/nbAlerts/', {projectIds});
	}

	deleteProjectAlertWithWarning(projectId: number): Observable<void> {
		return this.delete(this.route + '/' + projectId + '/' + ProjectAlertTypeEnum.WARNING);
	}

	deleteProjectAlert(projectAlert: IProjectAlertDto): Observable<IProjectAlertDto> {
		return this.delete(this.route + '/' + projectAlert.id);
	}
}
