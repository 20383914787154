import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogValidPeriodComponent} from './suivi-list/component/dialog-valid-period/dialog-valid-period.component';
import {SuiviProjetComponent} from './suivi-projet/suivi-projet.component';
import {SuiviListComponent} from './suivi-list/suivi-list.component';
import {HeaderModule} from '../template/header/header.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {NumberInputModule} from '../utils/components/number-input/number-input.module';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {RouterModule, Routes} from '@angular/router';
import {
	DialogLotSelectionSuiviProjetComponent
} from './suivi-projet/lot-selection-suivi-projet/dialog-lot-selection-suivi-projet.component';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {ProjectMessagesModule} from '../gestion/component/project-messages/project-messages.module';
import {ClientSelectModule} from '../client/component/client-select/client-select.module';
import {UtilModule} from '../utils/util.module';
import {Title} from '@angular/platform-browser';
import {StickTopHeightPipe} from './suivi-projet/pipe/stick-top-height.pipe';
import {CellClassDirective} from './suivi-projet/directive/cell-class.directive';
import {RowClassDirective} from './suivi-projet/directive/row-class.directive';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AdminRolePipe, RolePipe} from '../security/pipe/role.pipe';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {WeekReportComponent} from './suivi-projet/component/week-report/week-report.component';

const routes: Routes = [
	{path: '', component: SuiviListComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.USER]}},
	{path: ':jirakey', component: SuiviProjetComponent, data: {roles: [PermissionEnum.ADMIN, PermissionEnum.USER, PermissionEnum.CLIENT]}},
];

@NgModule({
	declarations: [
		SuiviListComponent,
		DialogValidPeriodComponent,
		SuiviProjetComponent,
		DialogLotSelectionSuiviProjetComponent,
		StickTopHeightPipe,
		CellClassDirective,
		RowClassDirective,
		WeekReportComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		HeaderModule,
		FormsModule,
		MatDialogModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatSelectModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatSnackBarModule,
		MatMenuModule,
		MatTableModule,
		MatTooltipModule,
		TranslateModule,
		NumberInputModule,
		DialogPopupModule,
		MatPaginatorModule,
		OgdpPaginatorModule,
		ProjectMessagesModule,
		ClientSelectModule,
		UtilModule,
		RolePipe,
		AdminRolePipe,
		ReactiveFormsModule,
		MatAutocompleteModule
	],
	providers: [Title],
})
export class SuiviModule {
}
