<app-dialog-popup [popupTitle]="'AVANT_VENTE.CHIFFRAGE.CREATE' | translate">
	<div class="w-100 project-main-info-wrapper" dialog-popup-content>
		<mat-form-field>
			<mat-label>{{ 'AVANT_VENTE.CHIFFRAGE.TYPE.TITLE' | translate }}</mat-label>
			<mat-select [(ngModel)]="newChiffrageType" [hideSingleSelectionIndicator]="true">
				<mat-option [title]="'AVANT_VENTE.CHIFFRAGE.TYPE.' + type | translate" [value]="type"
							*ngFor="let type of chiffrageTypeEnum">
					{{'AVANT_VENTE.CHIFFRAGE.TYPE.' + type | translate}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<div *ngIf="newChiffrageType !== oldChiffrageType" class="warning">
			{{ 'AVANT_VENTE.CHIFFRAGE.TYPE.WARNING-CHANGE' | translate }}
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="onYesClick()" class="its-button" mat-flat-button>{{'APP.CREATE' | translate}}</button>
	</div>
</app-dialog-popup>
