import {Component, HostListener, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {environment} from '../environments/environment';
import {DebugService} from './utils/service/debug.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.scss'
	]
})
export class AppComponent implements OnInit {

	constructor(@Inject(DOCUMENT) private doc: any, private _debug: DebugService) { }

	ngOnInit(): void {
		const link: HTMLLinkElement = this.doc.createElement('link');
		link.setAttribute('rel', 'stylesheet');
		link.setAttribute('href', `assets/styles/themes/${environment.theme}.css`);
		this.doc.head.appendChild(link);

		this.doc.getElementById('appFavicon').setAttribute('href', `/assets/img/${environment.theme}/${environment.theme}.ico`);
	}

	@HostListener('window:keydown.control.d', ['$event'])
	onKeyDown(e: KeyboardEvent): void {
		// optionally use preventDefault() if your combination
		// triggers other events (moving focus in case of Shift+Tab)
		if (e.ctrlKey && e.key.toLowerCase() === 'd') {
			e.preventDefault();
			this._debug.debugMode = !this._debug.debugMode;
			if (this._debug.debugMode) {
				console.log('------ Starting Debug Mode ------');
			} else {
				console.log('------ Ending Debug Mode ------');
			}
		}
	}
}
