<div (dblclick)="editNode()"
	 (keyup.escape)="clearFormSousCategorie()" class="d-flex justify-content-center">

	<div *ngIf="stateItemNode == 'EDIT'; then edition else lecture"></div>
	<ng-template #edition>
		<div class="spacer"></div>
		<div class="d-flex align-items-center justify-content-between title-row sous-categorie-title">
			<div>
				<mat-checkbox [(ngModel)]="sousCategorie.optionel" class="rdg-optionel">Optionnel</mat-checkbox>

				<mat-form-field class="input-form-nom">
					<mat-label>Nom</mat-label>
					<input [(ngModel)]="sousCategorie.nom" [focusOn]="true" (keydown.enter)="saveFormSousCategorie()"
						   matInput required>
				</mat-form-field>
			</div>
		</div>

		<div *ngIf="chiffrage.type !== 'REEL' && chiffrage.type !== 'MINMAX'"
			 class="d-flex align-items-center sous-categorie-charge">
			<mat-form-field class="input-form-charge">
				<mat-label>Charge</mat-label>
				<input [(ngModel)]="sousCategorie.charge" (keydown.enter)="saveFormSousCategorie()" class="text-center"
					   matInput>
			</mat-form-field>
		</div>
		<div *ngIf="chiffrage.type === 'REEL'"
			 class="d-flex align-items-center cell-sous-categorie real-sum-container bg-grey-extralight-3">
			<div class="charge-row sous-categorie-charge">
				{{sousCategorie.charge | number:'1.0-3'}}
			</div>
		</div>

		<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'"
			 *ngFor="let editSousRepartition of sousCategorie.sousRepartitions"
			 class="repartition-row sous-categorie-repartition d-flex align-items-center justify-content-center">

			<div [ngSwitch]="chiffrage.type">
				<div *ngSwitchCase="'PERCENT'">
					<app-form-field-pourcentage [allSousRepartitions]="sousCategorie.sousRepartitions"
												[parent]="sousCategorie"
												[saveForm]="saveFormSousCategorie.bind(this)"
												[sousRepartition]="editSousRepartition">
					</app-form-field-pourcentage>
				</div>
				<div *ngSwitchCase="'MINMAX'">
					<app-form-field-minmax [allSousRepartitions]="sousCategorie.sousRepartitions"
										   [parent]="sousCategorie"
										   [saveForm]="saveFormSousCategorie.bind(this)"
										   [sousRepartition]="editSousRepartition">
					</app-form-field-minmax>
				</div>
				<div *ngSwitchCase="'REEL'" class="real-container">
					<app-form-field-reel [saveForm]="saveFormSousCategorie.bind(this)"
										 [sousRepartition]="editSousRepartition"></app-form-field-reel>
				</div>
			</div>

		</div>
		<div class="d-flex align-items-center input-comment">
			<mat-form-field class="input-form-charge">
				<mat-label>Commentaire</mat-label>
				<input [(ngModel)]="sousCategorie.comment" (keydown.enter)="saveFormSousCategorie()" matInput>
			</mat-form-field>
			<div class="d-flex align-items-center">
				<button (click)="saveFormSousCategorie()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">done</span>
				</button>
				<button (click)="clearFormSousCategorie()" class="btn-edit" mat-icon-button>
					<span class="material-icons gradient">close</span>
				</button>
			</div>
		</div>
	</ng-template>

	<ng-template #lecture>
		<div class="d-flex align-items-center sous-categorie-optionel">
			<div *ngIf="sousCategorie.optionel; then picoOptionel else pictoNotOptionel"></div>
			<ng-template #picoOptionel>
				<div class="sp-av-optionnel-oui pico-optionel" title="Optionnel"></div>
			</ng-template>
			<ng-template #pictoNotOptionel>
				<div class="sp-av-optionnel-non pico-optionel" title="Inclus"></div>
			</ng-template>
		</div>

		<div class="d-flex align-items-center justify-content-between title-row sous-categorie-title">

			<div class="d-flex align-items-center sous-categorie-name">
				<span [matTooltip]="isNameTruncated(refEl) ? sousCategorie.nom : ''" #refEl>{{sousCategorie.nom}}</span>
			</div>

			<div *ngIf="lastChiffrage?.id === chiffrage?.id" class="d-flex align-items-center button-modification">
				<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
					<mat-icon>more_horiz</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="itsf-override">
					<button (click)="changeStateItemNode()" mat-menu-item>
						<span class="sp-edit gradient"></span>
						<span class="ms-2">{{'APP.EDIT' | translate}}</span>
					</button>
					<button (click)="deleteSousCategorie()" mat-menu-item>
						<span class="sp-delete gradient"></span>
						<span class="ms-2">{{'APP.DELETE' | translate}}</span>
					</button>
				</mat-menu>
			</div>
		</div>

		<div [class.bg-grey-extralight-3]="chiffrage.type === 'REEL'" *ngIf="chiffrage?.type !== 'MINMAX'"
			 class="d-flex align-items-center cell-sous-categorie">
			<div class="charge-row sous-categorie-charge">
				{{sousCategorie.charge| number:'1.0-3'}}
			</div>
		</div>

		<div *ngFor="let repartition of sousCategorie.sousRepartitions">
			<div class="d-flex align-items-center cell-sous-categorie">
				<div [class.min-max-repartition]="chiffrage.type === 'MINMAX'" [ngSwitch]="chiffrage.type"
					 class="repartition-row sous-categorie-repartition">
					<div *ngSwitchCase="'PERCENT'">
						{{repartition.charge| number:'1.0-3'}}
					</div>
					<div *ngSwitchCase="'REEL'">
						{{repartition.charge| number:'1.0-3'}}
					</div>
					<div *ngSwitchCase="'MINMAX'" class="d-flex flex-row min-max-container">
						<div
							class="min d-flex align-items-center justify-content-center">{{repartition.chargeMin| number:'1.0-3'}}</div>
						<div
							class="d-flex align-items-center justify-content-center">{{repartition.chargeMax| number:'1.0-3'}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex justify-content-between align-items-center w100">
			<span class="categorie-comment">{{sousCategorie.comment}}</span>
			<span *ngIf="node.isKeybordSelected"
				  class="keyboard-hints">{{'AVANT_VENTE.DETAIL.KEYBOARD_HINT.SOUS_CATEGORIE' | translate}}</span>
		</div>
	</ng-template>
</div>
