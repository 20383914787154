import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {IStoryDto, StoryDto} from './story.dto';
import {Router} from '@angular/router';
import {IStoryCreateUpdateDto} from './story.create.update.dto';
import {first, firstValueFrom, Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StoryService extends ApiService {

	private route: string = '/api/story';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	createStory(story: IStoryCreateUpdateDto): Promise<IStoryDto> {
		return firstValueFrom(this.http.post<IStoryDto>(this.route, story));
	}

	modifyStory(id: number, story: IStoryCreateUpdateDto): Promise<IStoryDto> {
		return firstValueFrom(this.http.put<IStoryDto>(this.route + '/' + id, story));
	}

	moveStoriesToLot(stories: IStoryDto[]): Promise<void> {
		return firstValueFrom(this.http.post(this.route + '/move-to-lot', {stories})).then();
	}

	removeStory(id: number): Promise<void> {
		return firstValueFrom(this.http.delete(this.route + '/' + id)).then();
	}

	switchStoryEpic(storyId: number, storyBeforeId: number, epicId: number): Promise<void> {
		const story: IStoryDto = new StoryDto();
		story.id = storyId;
		story.order = storyBeforeId;
		story.epicId = epicId;
		return firstValueFrom(this.http.post(this.route + '/switch', story)).then();
	}

	reorderStoryInEpic(storyId: number, storyBeforeId: number, epicId: number): Promise<void> {
		const story: IStoryDto = new StoryDto();
		story.id = storyId;
		story.order = storyBeforeId;
		story.epicId = epicId;
		return firstValueFrom(this.http.post(this.route + '/reorder', story)).then();
	}

	findAllStory(projectId: number, int: boolean): Promise<IStoryDto[]> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, projectId], {
			queryParams: {
				int
			}
		}));
		return firstValueFrom(this.http.get<IStoryDto[]>(url));
	}

	findByLot(id: number): Observable<IStoryDto[]> {
		return this.get(this.route + '/lot/' + id);
	}

	toggleExpandedState(id: number): void {
		this.get(this.route + '/toggleExpandedState/' + id).pipe(first()).subscribe();
	}

	setExpandedState(id: number, state: boolean): void {
		this.get(`${this.route}/setExpandedState/${id}?state=${state}`).pipe(first()).subscribe();
	}

	checkUpdateDates(id: number): Observable<boolean> {
		return this.get(`${this.route}/checkUpdateDates/${id}`);
	}
}
