import {IProjectTimeFinalDto} from '../../../core/business/service/export-tempo/export-tempo.dto';

export class ExportTempoCsvExportFormat {

	Username: string;
	Projet: string;
	Janvier: string;
	Fevrier: string;
	Mars: string;
	Avril: string;
	Mai: string;
	Juin: string;
	Juillet: string;
	Aout: string;
	Septembre: string;
	Octobre: string;
	November: string;
	December: string;

	constructor() {
	}
}

export class CsvExportDatasource {

	public static transformForCsvExport(projectTimeFinals: IProjectTimeFinalDto[]): ExportTempoCsvExportFormat[] {
		const exportTempoCsvExportFormats: ExportTempoCsvExportFormat[] = [];

		for (const projectTimeFinal of projectTimeFinals) {
			const exportTempoCsvExportFormat: ExportTempoCsvExportFormat = new ExportTempoCsvExportFormat();

			exportTempoCsvExportFormat.Username = projectTimeFinal.trigramme;
			exportTempoCsvExportFormat.Projet = projectTimeFinal.project;
			exportTempoCsvExportFormat.Janvier = projectTimeFinal.timeJanuary;
			exportTempoCsvExportFormat.Fevrier = projectTimeFinal.timeFebruary;
			exportTempoCsvExportFormat.Mars = projectTimeFinal.timeMarch;
			exportTempoCsvExportFormat.Avril = projectTimeFinal.timeApril;
			exportTempoCsvExportFormat.Mai = projectTimeFinal.timeMay;
			exportTempoCsvExportFormat.Juin = projectTimeFinal.timeJune;
			exportTempoCsvExportFormat.Juillet = projectTimeFinal.timeJuly;
			exportTempoCsvExportFormat.Aout = projectTimeFinal.timeAugust;
			exportTempoCsvExportFormat.Septembre = projectTimeFinal.timeSeptember;
			exportTempoCsvExportFormat.Octobre = projectTimeFinal.timeOctober;
			exportTempoCsvExportFormat.November = projectTimeFinal.timeNovember;
			exportTempoCsvExportFormat.December = projectTimeFinal.timeDecember;

			exportTempoCsvExportFormats.push(exportTempoCsvExportFormat);
		}

		return exportTempoCsvExportFormats;
	}

	private static convertStringToNumber(value: string): number {
		if (value) {
			return Number(value.replace(',', '.'));
		} else {
			return Number(0);
		}
	}
}
