import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {INumberAlertsDto,} from '../../../core/business/service/project-alert/project-alert.dto';

@Component({
	selector: 'app-project-messages',
	template: `
        <div class="d-flex flex-row alerts">
            <div *ngFor="let numberAlert of numberAlerts">
                <div *ngIf="numberAlert.count > 0" [class]="numberAlert.type + ' ml-1'">
			<span class="sp-iconmonstr-triangle-1"
                  [matTooltip]="numberAlert.count + ' ' +  ('ALERT.TYPE.' + numberAlert.type | translate) + '(s)'"
                  [matTooltipClass]="'tolltipMultiLine'">
			</span>
                </div>
            </div>
        </div>
	`,
	styleUrls: ['./project-messages.component.scss']
})
export class ProjectMessagesComponent implements AfterViewInit, OnInit, OnChanges {

	@Input() numberAlerts: INumberAlertsDto[];

	constructor() {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {

	}

	ngOnChanges(changes: SimpleChanges): void {
	}
}
