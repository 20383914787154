import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-valid-period',
	styleUrls: ['./dialog-valid-period.component.scss'],
	templateUrl: './dialog-valid-period.component.html'
})

export class DialogValidPeriodComponent {

	constructor(public dialogRef: MatDialogRef<DialogValidPeriodComponent>) {
	}

	validatePeriod(): void {
		this.dialogRef.close(true);
	}
}
