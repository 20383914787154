import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {DialogUserEditComponent} from './user-edit/dialog-user-edit.component';
import {UserbaseComponent} from './user-list/userbase.component';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import {UtilModule} from '../utils/util.module';
import {MatSortModule} from '@angular/material/sort';
import {HeaderModule} from '../template/header/header.module';
import {MatIconModule} from '@angular/material/icon';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {RouterModule, Routes} from '@angular/router';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';

const routes: Routes = [
	{path: '', component: UserbaseComponent, data: {roles: [PermissionEnum.ADMIN]}},
];

@NgModule({
	declarations: [DialogUserEditComponent, UserbaseComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        DialogPopupModule,
        MatRadioModule,
        MatAutocompleteModule,
        TranslateModule,
        MatDividerModule,
        UtilModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatSortModule,
        HeaderModule,
        MatIconModule,
        OgdpPaginatorModule,
    ],
	providers: [
		Title
	]
})
export class UsersModule {
}
