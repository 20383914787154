import {Component, OnInit} from '@angular/core';
import {ExportTempoService} from '../../core/business/service/export-tempo/export-tempo.service';
import {DownloadFileService} from '../../core/service/download-file.service';
import {IProjectTimeFinalDto} from '../../core/business/service/export-tempo/export-tempo.dto';
import {CsvExportDatasource} from './business/csv-export.datasource';
import {SpinnerService} from '../../core/service/spinner.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {firstValueFrom, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserBaseService} from "../../core/business/service/user-base/user-base.service";
import {IUserBaseDto} from "../../core/business/service/user-base/user-base.dto";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogValidationComponent} from '../../utils/components/dialog-validation/dialog-validation.component';
import {DialogExportTempoProgressComponent} from './progress/dialog-export-tempo-progress.component';

@Component({
	selector: 'app-export-tempo',
	templateUrl: './export-tempo.component.html',
	styleUrls: ['./export-tempo.component.scss']
})
export class ExportTempoComponent implements OnInit {

	annee: string = new Date().getFullYear().toString();

	constructor(private exportTempoService: ExportTempoService, private userService: UserBaseService,
				private downloadFileService: DownloadFileService, private spinnerService: SpinnerService,
				private _translator: TranslateService, public snackBar: MatSnackBar,
				private _titleService: Title, public dialog: MatDialog) {
	}

	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Export Tempo');
	}

	async downloadCsv(): Promise<void> {
		if (this.annee.trim().length === 4) {
			this.snackBar.open(this._translator.instant('EXPORT_TEMPO.SNACKBAR.BEGIN'));
			this.spinnerService.enableLoading();
			const projectTimeFinal: IProjectTimeFinalDto[] = await this.exportTempoDataDialog(+this.annee);
			this.downloadFileService.localDataToCsvFile(CsvExportDatasource.transformForCsvExport(projectTimeFinal), 'export-tempo-' + this.annee + '.csv', ';');
			this.spinnerService.disableLoading();
		} else {
			this.snackBar.open(this._translator.instant('EXPORT_TEMPO.SNACKBAR.END'));
		}
	}

	async exportTempoDataDialog(year: number): Promise<IProjectTimeFinalDto[]> {
		return new Promise<IProjectTimeFinalDto[]>(async (resolve) => {
			const progressDialog: MatDialogRef<DialogExportTempoProgressComponent, any> =
				this.dialog.open(DialogExportTempoProgressComponent, {
					disableClose: true,
					width: '550px',
					height: '350px',
					data: {year: year}
				});
			const projectTimeFinal: IProjectTimeFinalDto[] = await firstValueFrom(this.exportTempoService.getSpentTimeByProjectAndUser(year));
			progressDialog.close();
			resolve(projectTimeFinal);
		});
	}
}
