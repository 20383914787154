<app-dialog-popup [popupTitle]="'STORY.BUDGET_REDISTRIBUTION_SIGNED.TITLE' | translate">
	<div dialog-popup-content>

		<div class="story-data">
			<div class="d-flex flex-column">
				<div class="p-2 info">
					<span class="dp-floating-label">{{'STORY.BUDGET_REDISTRIBUTION_SIGNED.INFO' | translate}}
					</span>
				</div>
				<div class="p-1 story-to-move">
					<span
						class="dp-floating-label">{{'STORY.BUDGET_REDISTRIBUTION_SIGNED.TOTAL_STORY' | translate}}</span>
					<span class="space">:</span>
					<span class="fw-bold">{{storiesWithLotChange.length}}</span>
				</div>
				<div class="p-1 story-to-move">
					<span
						class="dp-floating-label">{{'STORY.BUDGET_REDISTRIBUTION_SIGNED.TOTAL_BUDGET' | translate}}</span>
					<span class="space">:</span>
					<span class="fw-bold">{{totalBudgetStoriesWhithLotChange}}</span>
				</div>
			</div>
		</div>

		<div class="position-relative d-flex story-select">

			<div *ngIf="srcLot.contract == 'SIGNED'; then displayLotToCredit"></div>
			<ng-template #displayLotToCredit>
				<div class="d-flex flex-column">
					<div class="p-2 source">
						<div>
							<span class="lbl-lot-name">Lot source : </span>
							<span class="lot-name">{{srcLot.name}}</span>
						</div>
					</div>
					<div class="p-2">
						<mat-checkbox #forceBudgetToCredit class="lot-name">
							{{'STORY.BUDGET_REDISTRIBUTION.FORCE' | translate}}
						</mat-checkbox>
					</div>
					<div class="p-2">
						<form [hidden]="forceBudgetToCredit.checked">
							<mat-form-field class="autoCompleteInput">
								<mat-label>{{'STORY.BUDGET_REDISTRIBUTION_SIGNED.PLACEHOLDER_CREDIT' | translate}}</mat-label>
								<input [formControl]="selectStoryControllerToCredit"
									   [matAutocomplete]="autocompleteToCredit" matInput type="text" #txtStoryToCredit
									   class="lbl-lot-name">
								<mat-autocomplete #autocompleteToCredit="matAutocomplete"
												  [displayWith]="displaySelectedStory"
												  autoActiveFirstOption>
									<mat-option *ngFor="let story of selectStoryFilteredToCredit | async"
												[value]="story">
											<span>{{story.name}} - {{story.jiraIntId}} - ({{'PROJECT.RAE' | translate}}
												: {{story.remainingEXT}} {{'DATE.DAY_SMALL' | translate}})</span>
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</form>
					</div>
				</div>
			</ng-template>

			<div *ngIf="srcLot.contract == 'SIGNED' && destLot.contract == 'SIGNED'" class="separate-line"></div>

			<div *ngIf="destLot.contract == 'SIGNED'; then displayLotToDebit"></div>
			<ng-template #displayLotToDebit>
				<div class="d-flex flex-column">
					<div class="p-2">
						<div>
							<span class="lbl-lot-name">Lot destination : </span>
							<span class="lot-name">{{destLot.name}}</span>
						</div>
					</div>
					<div class="p-2">
						<mat-checkbox #forceBudgetToDebit class="lot-name">
							{{'STORY.BUDGET_REDISTRIBUTION.FORCE' | translate}}
						</mat-checkbox>
					</div>
					<div class="p-2">
						<form [hidden]="forceBudgetToDebit.checked" class="">
							<mat-form-field class="autoCompleteInput">
								<mat-label>{{'STORY.BUDGET_REDISTRIBUTION_SIGNED.PLACEHOLDER_DEBIT' | translate}}</mat-label>
								<input [formControl]="selectStoryControllerToDebit"
									   [matAutocomplete]="autocompleteToDebit"
									   matInput type="text" #txtStoryToDebit class="lbl-lot-name">
								<mat-autocomplete #autocompleteToDebit
												  [displayWith]="displaySelectedStory"
												  autoActiveFirstOption>
									<mat-option *ngFor="let story of selectStoryFilteredToDebit | async"
												[value]="story">
											<span>{{story.name}} {{story.jiraIntId ? ' - ' + story.jiraIntId : '' }}
												- ({{'PROJECT.RAE' | translate}}
												: {{story.remainingEXT}} {{'DATE.DAY_SMALL' | translate}})</span>
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
						</form>
					</div>
					<div class="p-2 d-flex justify-content-center">
						<div *ngIf="errorMessage"
							 class="alert alert-danger row justify-content-center align-self-center">{{errorMessage}}</div>
					</div>
				</div>
			</ng-template>
		</div>

	</div>

	<div dialog-popup-footer>
		<button (click)="actionBtnValider()" [disabled]="!isBusinessValid" mat-flat-button
				class="its-button mat-flat-button">
			{{'APP.VALIDATE' | translate}}
		</button>
	</div>
</app-dialog-popup>
