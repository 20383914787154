import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule} from '@angular/forms';
import {DatePipe, registerLocaleData} from '@angular/common';
import {ExportTempoModule} from './administration/export-tempo/export-tempo.module';
import {HttpClient} from '@angular/common/http';
import {HeaderModule} from './template/header/header.module';
import {ConfigurationModule} from './configuration/configuration.module';
import {SuiviModule} from './suivi/suivi.module';
import {UtilsService} from './utils/service/utils.service';
import localeFr from '@angular/common/locales/fr';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UtilModule} from './utils/util.module';
import {SpinnerService} from './core/service/spinner.service';
import {AppInitializer} from './utils/app-initializer';
import {LayoutModule} from './layout/layout.module';
import {AppConfigModule} from './appconfig.module';
import {TempoJiraModule} from './tempo-jira/tempo-jira.module';
import {GestionModule} from './gestion/gestion.module';
import {UsersModule} from './users/users.module';
import {ClientModule} from './client/client.module';
import {BugManagementModule} from './bug-management/bug-management.module';
import {ProjectAlertModule} from './project-alert/project-alert.module';
import {AvantVenteModule} from './avant-vente/avant-vente.module';
import {V1ReportModule} from './v1-report/v1-report.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {KeycloakModule} from './security/keycloak.module';
import {customHttpInterceptorProvider} from './security/custom-http.interceptor';
registerLocaleData(localeFr, 'fr');

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initialize(appInitializer: AppInitializer): () => Promise<any> {
	return () => appInitializer.init();
}

@NgModule({
	declarations: [
		AppComponent
	],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
		KeycloakModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HeaderModule,
        UtilModule,
        LayoutModule,
        AppConfigModule,
		ConfigurationModule,
		GestionModule,
		SuiviModule,
		ExportTempoModule,
		TempoJiraModule,
		UsersModule,
		ClientModule,
		BugManagementModule,
		ProjectAlertModule,
		AvantVenteModule,
		V1ReportModule
	],
	providers: [
		DatePipe,
		AppInitializer,
		{provide: LOCALE_ID, useValue: 'fr'},
		{provide: MAT_DATE_LOCALE, useValue: 'fr'},
		customHttpInterceptorProvider,
		{provide: APP_INITIALIZER, useFactory: initialize, deps: [AppInitializer], multi: true},
		{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
		UtilsService,
		SpinnerService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
