import {IProjectDto} from '../project/project.dto';

export interface IClientDto {
	id: number;
	name: string;
	logo: string;
	archived: boolean;
	projects: IProjectDto[];
}

export class ClientDto implements IClientDto {
	id: number;
	name: string;
	logo: string;
	archived: boolean;
	projects: IProjectDto[];
}
