<app-dialog-popup [popupTitle]="('AVANT_VENTE.REPARTITION.' + (repartition.id ? 'EDIT' : 'NEW')) | translate">
	<div class="d-flex w-100 project-main-info-wrapper" dialog-popup-content>
		<mat-form-field>
			<mat-label>{{'AVANT_VENTE.REPARTITION.NAME' | translate}}</mat-label>
			<input [(ngModel)]="repartition.nom" matInput required>
		</mat-form-field>
	</div>

	<div dialog-popup-footer>
		<button (click)="onYesClick()" class="its-button"
				mat-flat-button>{{'APP.' + (repartition?.id ? 'UPDATE' : 'CREATE') | translate}}</button>
	</div>
</app-dialog-popup>
