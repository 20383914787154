// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  	production: false,
  	theme: 'its_future', // 'its_future' || 'cup_of_t'
	keycloak: {
		// Réglage Keycloak
		realm: 'CreativeCodingPeople', // Nom du realm (Nom de domaine)
		clientId: 'ogdp_public', // Nom du client
		url: 'https://id-interne-preprod.its-future.com/', // Url de keycloak pour l'authentification, le slash final est important !
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
