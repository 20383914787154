import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, first, Observable} from 'rxjs';
import {CategorieDto, ICategorieDto} from '../../../../../core/business/service/avant-vente/categorie/categorie.dto';
import {CategorieService} from '../../../../../core/business/service/avant-vente/categorie/categorie.service';
import {ISousCategorieDto, SousCategorieDto} from '../../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';
import {SousCategorieService} from '../../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.service';
import {ChiffrageTypeEnum, IChiffrageDto} from '../../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {AvantVenteBusiness} from '../../../../business/avant-vente.business';
import {map, mergeMap} from 'rxjs/operators';
import {IRepartitionDto} from '../../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {SousRepartitionService} from '../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.service';
import {ISousRepartitionDto} from '../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {SpinnerService} from '../../../../../core/service/spinner.service';

export class DatasourceItemNode {
	displayName: string;
	children: DatasourceItemNode[];
	item: any;
	order: number;
}

@Injectable()
export class ChiffrageDatasource {

	dataChange: BehaviorSubject<DatasourceItemNode[]> = new BehaviorSubject<DatasourceItemNode[]>([]);
	categories: ICategorieDto[];

	constructor(private categorieService: CategorieService, private sousCategorieService: SousCategorieService,
				private sousRepartitionService: SousRepartitionService, public spinnerService: SpinnerService) {
	}

	get data(): DatasourceItemNode[] {
		return this.dataChange.value;
	}

	moveCategorie(nodeToMove: DatasourceItemNode, previousIndex: number, destIndex: number): void {
		const element: DatasourceItemNode = this.data[previousIndex];
		this.data.splice(previousIndex, 1);
		this.data.splice(destIndex, 0, element);

		const categoriesToSave: Observable<ICategorieDto>[] = [];
		this.data.forEach((node, i) => {
			node.order = i;
			node.item.order = i;
			// /!\ Attention il sauvegarde aussi toutes les sous-catégories
			categoriesToSave.push(this.categorieService.update(node.item).pipe(first()));
		});

		combineLatest(categoriesToSave).subscribe(value => {
			if (value) {
				this.dataChange.next(this.data);
			}
		});
	}

	moveSousCategorie(parentNode: DatasourceItemNode, nodeToMove: DatasourceItemNode, previousOrder: number, currentOrder: number): void {
		const categorie: DatasourceItemNode = this.data[this.data.indexOf(parentNode)];
		if (categorie) {
			categorie.children.splice(previousOrder, 1);
			categorie.children.splice(currentOrder, 0, nodeToMove);

			const sousCategoriesToSave: Observable<ISousCategorieDto>[] = [];
			categorie.children.forEach((node, i) => {
				node.order = i;
				node.item.order = i;
				// /!\ Attention il sauvegarde aussi toutes les sous catégories
				sousCategoriesToSave.push(this.sousCategorieService.update(node.item));
			});

			combineLatest(sousCategoriesToSave).subscribe(value => {
				if (value) {
					this.dataChange.next(this.data);
				}
			});
		}
	}

	findByChiffrageWithSousCategorieAndSousRepartition(chiffrageId: number): void {
		this.spinnerService.enableLoading();

		this.categorieService.findByChiffrageWithSousCategorieAndSousRepartitionAndRepartition(chiffrageId)
			.pipe(first())
			.subscribe((categories: ICategorieDto[]) => {
				if (categories) {
					this.categories = categories;
					const data: DatasourceItemNode[] = this.buildFileTree(categories, 0);
					this.dataChange.next(data.sort(AvantVenteBusiness.sortItemNodeOrder));
					this.spinnerService.disableLoading();
				}
			});
	}

	buildFileTree(categories: any[], level: number): DatasourceItemNode[] {
		return categories.reduce<DatasourceItemNode[]>((accumulator, obj) => {
			const node: DatasourceItemNode = new DatasourceItemNode();
			node.displayName = obj.nom;
			node.item = obj;
			node.order = obj.order;

			if (obj.sousCategories) {
				node.children = this.buildFileTree(obj.sousCategories, level + 1);
				node.children = node.children.sort(AvantVenteBusiness.sortItemNodeOrder);
			}

			return accumulator.concat(node);
		}, []);
	}

	insertSousCategorieForm(parent: DatasourceItemNode, name: string): void {
		if (parent.children) {
			parent.children.push({displayName: name} as DatasourceItemNode);
			this.dataChange.next(this.data);
		}
	}

	insertCategorieForm(name: string): void {
		this.data.push({displayName: name} as DatasourceItemNode);
		this.dataChange.next(this.data);
	}

	createSousCategorie(parent: DatasourceItemNode,
						node: DatasourceItemNode,
						partialSousCategorieDto: ISousCategorieDto,
						chiffrage: IChiffrageDto): Observable<ISousCategorieDto> {
		const nodeOrder: number = parent.item.sousCategories ? parent.item.sousCategories.length : 0;
		const newSousCategorie: ISousCategorieDto = new SousCategorieDto(
			null,
			partialSousCategorieDto.nom,
			+partialSousCategorieDto.charge,
			partialSousCategorieDto.optionel,
			nodeOrder,
			partialSousCategorieDto.comment,
			parent.item.id,
			partialSousCategorieDto.sousRepartitions
		);

		return this.sousCategorieService.create(newSousCategorie).pipe(
			first(),
			mergeMap(createdSousCategorie => {
				if (createdSousCategorie) {
					return this.sousCategorieService.findById(createdSousCategorie)
						.pipe(
							first(),
							map(sousCategorieWithSousRepartition => {
								if (sousCategorieWithSousRepartition) {
									node.displayName = sousCategorieWithSousRepartition.nom;
									node.order = sousCategorieWithSousRepartition.order;
									node.item = sousCategorieWithSousRepartition;

									this.data[this.data.indexOf(parent)].item.sousCategories.push(sousCategorieWithSousRepartition);
									parent.item.sommeCharge = parent.item.sousCategories.reduce((acc, value) => acc + value.charge, 0);

									this.dataChange.next(this.data);
								}
								sousCategorieWithSousRepartition.sousRepartitions.forEach((sousRepartition) => {
									if (chiffrage.type !== ChiffrageTypeEnum.MINMAX && !sousRepartition.charge) {
										sousRepartition.charge = 0;
									}
								});

								return sousCategorieWithSousRepartition;
							}));
				}
			}));
	}

	updateSousCategorie(parent: DatasourceItemNode, node: DatasourceItemNode, sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		sousCategorieDto.categorieId = parent.item.id;
		return this.sousCategorieService.update(sousCategorieDto).pipe(
			first(),
			map(updatedSousCategorie => {
				if (updatedSousCategorie) {
					parent.item.sommeCharge = parent.item.sousCategories.reduce((acc, value) => acc + value.charge, 0);
					this.dataChange.next(this.data);
				}

				return updatedSousCategorie;
			}));
	}

	clearSousCategorieForm(parent: DatasourceItemNode, node: DatasourceItemNode): void {
		if (parent.children) {
			// supprimer une sous-catégorie
			const indexNodeToRemove: number = parent.children.indexOf(node);
			this.data[this.data.indexOf(parent)].children.splice(indexNodeToRemove, 1);
		} else {
			// supprimer une categorie
		}
		this.dataChange.next(this.data);
	}

	createCategorie(chiffrage: IChiffrageDto, node: DatasourceItemNode, partialCategorieDto: ICategorieDto): Observable<ICategorieDto> {
		const newCategorie: ICategorieDto = new CategorieDto(null, partialCategorieDto.nom, null, chiffrage, null);

		return this.categorieService.create(newCategorie).pipe(
			first(),
			map(createdCategorie => {
				if (createdCategorie) {
					node.displayName = createdCategorie.nom;
					node.order = createdCategorie.order;
					createdCategorie.sousCategories = [];
					node.item = createdCategorie;
					node.children = [];

					this.categories.push(createdCategorie);

					this.dataChange.next(this.data);
				}

				return createdCategorie;
			}));
	}

	updateCategorie(node: DatasourceItemNode, categorieDto: ICategorieDto): Observable<ICategorieDto> {
		return this.categorieService.update(categorieDto).pipe(
			first(),
			map(updatedCategorie => {
				if (updatedCategorie) {
					this.dataChange.next(this.data);
				}

				return updatedCategorie;
			}));
	}

	clearCategorieForm(node: DatasourceItemNode): void {
		this.data.splice(this.data.indexOf(node), 1);
		this.dataChange.next(this.data);
	}

	deleteCategorie(node: DatasourceItemNode, categorieDto: ICategorieDto): Observable<ICategorieDto> {
		return this.categorieService.remove(categorieDto).pipe(
			first(),
			map(deletedCategorie => {
				if (deletedCategorie) {
					this.data.splice(this.data.indexOf(node), 1);
					this.dataChange.next(this.data);
					this.categories.splice(this.categories.indexOf(categorieDto), 1);
				}
				return deletedCategorie;
			}));
	}

	deleteSousCategorie(parent: DatasourceItemNode, node: DatasourceItemNode, sousCategorieDto: ISousCategorieDto): Observable<ISousCategorieDto> {
		return this.sousCategorieService.remove(sousCategorieDto).pipe(
			first(),
			map(deletedSousCategorie => {
				if (deletedSousCategorie) {
					const indexNodeToRemove: number = parent.children.indexOf(node);
					this.data[this.data.indexOf(parent)].children.splice(indexNodeToRemove, 1);

					const indexItemToRmove: number = parent.item.sousCategories.indexOf(sousCategorieDto);
					this.data[this.data.indexOf(parent)].item.sousCategories.splice(indexItemToRmove, 1);
					this.dataChange.next(this.data);


					parent.item.sommeCharge = parent.item.sousCategories.reduce((acc, value) => acc + value.charge, 0);
				}
				return deletedSousCategorie;
			}));
	}

	reloadSousRepartition(repartitionDto: IRepartitionDto): Observable<ISousRepartitionDto[]> {
		return this.sousRepartitionService.findByRepartitionWithRepartitionAndSousCategorie(repartitionDto).pipe(
			first(),
			map((createdSousRepartitions: ISousRepartitionDto[]) => {
				if (createdSousRepartitions?.length) {
					for (const sousRepartition of createdSousRepartitions) {
						for (const datasourceItemNode of this.data) {
							if (datasourceItemNode.item?.sousCategories) { // on est dans un noeud categorie
								// on doit trouver la sous categorie pour laquelle il faut rajouter une nouvelle sous répartition
								const sousCategorieToUpdated: ISousCategorieDto = datasourceItemNode.item.sousCategories.find(sousCategorie =>
									sousCategorie.id === sousRepartition.sousCategorieId
								);
								if (sousCategorieToUpdated) {
									sousCategorieToUpdated.sousRepartitions.push(sousRepartition);
								}
							}
						}
					}
				} else {
					for (const datasourceItemNode of this.data) {
						if (datasourceItemNode.item?.sousCategories) {
							datasourceItemNode.item.sousCategories.forEach((subCategory) => {
								const index: number = subCategory.sousRepartitions.findIndex((sr) => sr.repartitionId === +repartitionDto.id);
								subCategory.sousRepartitions.splice(index, 1);
							});
						}
					}
				}
				this.dataChange.next(this.data);
				return createdSousRepartitions;
			}));
	}
}
