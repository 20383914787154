import {IClientDto} from '../client/client.dto';

export interface IAvantVenteDto {
	id: number;
	titre: string;
	dateCreation: Date;
	dateModification: Date;
	chargeTotal: number;
	prixTotal: number;
	nbOffre: number;
	etat: AvantVenteEtatEnum;
	chiffrages: any[];
	chargeAnnexes: any[];
	clientId: number;
	client: IClientDto;
}

export class AvantVenteDto implements IAvantVenteDto {
	id: number;
	titre: string;
	dateCreation: Date;
	dateModification: Date;
	chargeTotal: number;
	prixTotal: number;
	nbOffre: number;
	etat: AvantVenteEtatEnum;
	chiffrages: any[];
	chargeAnnexes: any[];
	clientId: number;
	client: IClientDto;
}

export enum AvantVenteEtatEnum {
	EN_COURS = 'EN_COURS',
	GAGNE = 'GAGNE',
	PERDU = 'PERDU'
}

export class AvantVenteFilterDto {
	titre: string;
	etat: AvantVenteEtatEnum[];
	client: IClientDto;
}
