<div>
	<mat-form-field class="form-field-minmax">
		<mat-label>Min</mat-label>
		<input matInput type="text" #minInput [(ngModel)]="tempMin" class="text-center" (keydown.enter)="saveForm()">
	</mat-form-field>
	<span>/</span>
	<mat-form-field class="form-field-minmax">
		<mat-label>Max</mat-label>
		<input matInput type="text" #maxInput [(ngModel)]="tempMax" class="text-center" (keydown.enter)="saveForm()">
	</mat-form-field>
</div>
