<app-dialog-popup [popupTitle]="data.title" [warn]="warn">
	<div class="p-3" dialog-popup-content>
		<span>
			{{data.message}}
		</span>
	</div>
	<div class="d-flex justify-content-end p-3" dialog-popup-footer>
		<button [mat-dialog-close]="false" class="its-white-button" mat-button>{{data.noText}}</button>
		<button *ngIf="data.showYesButton" [mat-dialog-close]="true" cdkFocusInitial class="its-button"
				mat-button>{{data.yesText}}</button>
	</div>
</app-dialog-popup>
