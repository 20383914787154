import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../service/api.service';
import {firstValueFrom, Observable} from 'rxjs';
import * as moment from 'moment';
import {ITempoJiraDto} from './tempo-jira.dto';

@Injectable({
	providedIn: 'root'
})
export class TempoJiraService extends ApiService {

	private route: string = '/api/tempo-jira';

	constructor(public http: HttpClient) {
		super(http);
	}

	getAllUsers(date: moment.Moment, countToday: boolean = false): Promise<ITempoJiraDto[]> {
		 return firstValueFrom(this.post(this.route + '/allUsers/' + date.format('MM-DD-YYYY'), {countToday}));
	}
}
