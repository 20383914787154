<app-dialog-popup [popupTitle]="'Redistribution de budge'">
	<div class="dialog-content" dialog-popup-content>
		<div class="story-data">
			<div class="d-flex flex-column">
				<div class="p-2 info">
					{{'STORY.BUDGET_REDISTRIBUTION.' + action | translate}}
				</div>
				<div class="p-1 story-to-move">
					<span
						class="dp-floating-label">{{'PROJECT.NAME' | translate}}</span>
					<span class="space">:</span>
					<span class="fw-bold">{{srcStory.name}}</span>
				</div>
				<div class="p-1 story-to-move">
					<span
						class="dp-floating-label">{{'PROJECT.BUDGET' | translate}}</span>
					<span class="space">:</span>
					<span class="fw-bold">{{srcStory.budget}}</span>
				</div>
			</div>
		</div>

		<div class="position-relative d-flex flex-column story-select">
			<div class="d-flex">
				<div [hidden]="action == 'DELETE'">
					<mat-form-field class="input-budget">
						<mat-label>{{'STORY.BUDGET_REDISTRIBUTION.NEW' | translate}}</mat-label>
						<input [(ngModel)]="newBudget" #inputNewBudget appNumberInput autocomplete="off"
							   formatType="number" matInput type="text"/>
						<span matSuffix>{{'DATE.DAY_SMALL' | translate}}</span>
					</mat-form-field>
				</div>
				<div>
					<mat-checkbox [(ngModel)]="isForceModifBudget" #forceModifBudget class="lot-name">
						{{'STORY.BUDGET_REDISTRIBUTION.FORCE' | translate}}
					</mat-checkbox>
				</div>
			</div>

			<div class="d-flex">
				<form [hidden]="isForceModifBudget" class="w-100">
					<mat-form-field class="w-100">
						<mat-label>{{(keyPlaceholderSelectStory | translate) + ' ( ' + (srcStory.budget - newBudget) + ' )'}}</mat-label>
						<input [formControl]="selectStoryController" [matAutocomplete]="autocomplete" #txtSelecedStory
							   matInput type="text">
						<mat-autocomplete [displayWith]="displaySelectedStory" #autocomplete="matAutocomplete"
										  autoActiveFirstOption>
							<mat-option [value]="story" *ngFor="let story of selectStoryFiltered | async">
							<span [class.contingency]="story.storyType === 'CONTINGENCY'">{{story.jiraIntId}}
								- {{story.name}} - ({{'PROJECT.RAE' | translate}}
								: {{story.remainingEXT}} {{'DATE.DAY_SMALL' | translate}})</span>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</form>
			</div>

			<div class="error-message">
				<div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
			</div>
		</div>
	</div>

	<div dialog-popup-footer>
		<button [disabled]="disableBtnValider" (click)="actionBtnValider()" class="its-button mat-flat-button"
				mat-flat-button>
			{{'APP.VALIDATE' | translate}}
		</button>
	</div>
</app-dialog-popup>
