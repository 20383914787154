import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogClientEditComponent} from './dialog-client-edit.component';
import {DialogPopupModule} from '../../../template/dialog/dialog-popup.module';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DialogClientDeleteComponent} from '../client-delete/dialog-client-delete.component';
import {TranslateModule} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {UtilModule} from '../../../utils/util.module';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
	declarations: [DialogClientEditComponent, DialogClientDeleteComponent],
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatDialogModule,
		MatButtonModule,
		ReactiveFormsModule,
		DialogPopupModule,
		ImageCropperModule,
		TranslateModule,
		MatButtonModule,
		UtilModule,
	],
	providers: [
		Title,
	],
})
export class DialogClientEditModule {
}
