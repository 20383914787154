import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(array: any[] | string, value: any): boolean {
  	if (Array.isArray(array)) {
		return array?.includes(value);
	}
  	return array?.toString().includes(value);
  }
}
