import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IAxisReportDto} from '../v1-report.dto';

@Injectable({
	providedIn: 'root'
})
export class AxisReportService extends ApiService {

	private route: string = '/api/v1-report/axis-report';

	constructor(public http: HttpClient) {
		super(http);
	}

	find(id: number): Observable<IAxisReportDto> {
		return this.http.get<IAxisReportDto>(this.route + '/' + id);
	}

	update(axisReport: IAxisReportDto): Observable<IAxisReportDto> {
		return this.http.put<IAxisReportDto>(this.route + '/' + axisReport.id, {axisReport});
	}
}
