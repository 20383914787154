<app-header [displayBreadCrumb]="'USER.HEADER' | translate">
	<div class="ms-5">
		<app-searchbar [(value)]="userFilter.email" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<mat-form-field>
		<mat-label>Entreprise</mat-label>
		<mat-select #selectCompany [(ngModel)]="userFilter.company" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option *ngFor="let company of companies" [value]="company">
				{{ company }}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field style="max-width: 100px;">
		<mat-label>{{'USER.ACTIVE_STATE' | translate}}</mat-label>
		<mat-select #selectActif [(ngModel)]="userFilter.actif" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option [value]="true">
				{{'USER.EDIT_FORM.ACTIF' | translate}}
			</mat-option>
			<mat-option [value]="false">
				{{'USER.EDIT_FORM.INACTIF' | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field style="max-width: 130px;">
		<mat-label>Accès OGDP</mat-label>
		<mat-select #selectOGDPAccess [(ngModel)]="userFilter.ogdpAccess" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option [value]="'true'">
				Oui
			</mat-option>
			<mat-option [value]="'false'">
				Non
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field  style="max-width: 120px;">
		<mat-label>{{'USER.EDIT_FORM.ROLE.TITLE' | translate}}</mat-label>
		<mat-select #selectRole [(ngModel)]="userFilter.role" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option *ngFor="let role of roles" [value]="role">
				{{'USER.EDIT_FORM.ROLE.' + role | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field style="max-width: 150px;">
		<mat-label>Permissions</mat-label>
		<mat-select #selectPermissions [(ngModel)]="userFilter.permissions" multiple>
			<mat-option *ngFor="let permission of permissions" [value]="permission">
				{{ permission }}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field style="max-width: 130px;">
		<mat-label>Accès Jira</mat-label>
		<mat-select #selectJiraAccess [(ngModel)]="userFilter.jiraAccess" [hideSingleSelectionIndicator]="true">
			<mat-option [value]="null"></mat-option>
			<mat-option [value]="true">
				Oui
			</mat-option>
			<mat-option [value]="false">
				Non
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="actions">
		<button mat-flat-button (click)="syncWithMdm()" class="its-button">{{'APP.SYNC' | translate}}</button>
	</div>
</app-header>
<div class="mat-elevation-z8 table-container">
	<mat-table (matSortChange)="sortData($event)" [dataSource]="dataSourcePaginate" matSort
			   matSortActive="username" matSortDirection="asc">

		<!-- Avatar Column -->
		<ng-container matColumnDef="avatar">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let user">
				<img *ngIf="user.avatar" [src]="user.avatar | safizer" class="avatar">
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="trigramme">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Trigramme</mat-header-cell>
			<mat-cell *matCellDef="let user">{{user.trigramme}}</mat-cell>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="lastName">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER.NAME' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let user">{{user.firstName}} {{user.lastName}}</mat-cell>
		</ng-container>

		<!-- Email Column -->
		<ng-container matColumnDef="email">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER.EDIT_FORM.EMAIL' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let user">{{user.email}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="company">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Entreprise</mat-header-cell>
			<mat-cell *matCellDef="let user">{{user.company ? user.company.name : ''}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actif">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER.ACTIVE_STATE' | translate}}</mat-header-cell>
			<mat-cell
				*matCellDef="let user">{{(user.actif ? 'USER.EDIT_FORM.ACTIF' : 'USER.EDIT_FORM.INACTIF') | translate}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="ogdpAccess">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Accès OGDP</mat-header-cell>
			<mat-cell
				*matCellDef="let user">{{(user.ogdpAccess ? 'Oui' : 'Non') | translate}}</mat-cell>
		</ng-container>

		<!-- Role Column -->
		<ng-container matColumnDef="role">
			<mat-header-cell *matHeaderCellDef mat-sort-header>
				{{'USER.EDIT_FORM.ROLE.TITLE' | translate}}
			</mat-header-cell>
			<mat-cell *matCellDef="let user">{{(user.ogdpAccess ? ('USER.EDIT_FORM.ROLE.' + user.role | translate) : '-')}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="jiraAccess">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Accès Jira</mat-header-cell>
			<mat-cell
				*matCellDef="let user">{{(user.jiraAccess ? 'Oui' : 'Non') | translate}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="edit">
			<mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
			<mat-cell *matCellDef="let user">
				<span (click)="$event.stopPropagation(); openDialogCreateUpdateUserBase(user)"
					  class="sp-edit gradient cursor-pointer"></span>
			</mat-cell>
		</ng-container>


		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>
	<app-ogdp-paginator (pageClick)="goToPage($event)" [length]="dataSourcePaginate.length()"
						[linkMatPaginator]="paginator" [pageIndex]="paginationOption.page"
						[pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit"></app-ogdp-paginator>
</div>
