import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {IReportDto} from './v1-report.dto';

@Injectable({
	providedIn: 'root'
})
export class V1ReportService extends ApiService {

	private route: string = '/api/v1-report/report';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(projectId: number, startDate: Date): Observable<IReportDto> {
		return this.http.post<IReportDto>(this.route, {projectId, startDate});
	}

	getReportByProjectAndWeek(startDate: moment.Moment, projectId: number): Observable<IReportDto[]> {
		return this.http.get<IReportDto[]>(this.route + '/project/' + projectId + '?start=' + startDate.format('YYYY-MM-DD'));
	}

	closeWeekReports(startDate: moment.Moment): Observable<IReportDto> {
		return this.http.get<IReportDto>(this.route + '/close/' + startDate.format('YYYY-MM-DD'));
	}

	update(reportDto: IReportDto): Observable<IReportDto> {
		delete reportDto.project;
		return this.http.put<IReportDto>(this.route + '/' + reportDto.id, {reportDto});
	}

	find(id: number): Observable<IReportDto> {
		return this.http.get<IReportDto>(this.route + '/' + id);
	}

	getSumM(startDate: moment.Moment): Observable<any> {
		return this.http.get(this.route + '/sum-m/' + startDate.format('YYYY-MM-DD'));
	}
}
