import {IChiffrageDto} from '../chiffrage/chiffrage.dto';
import {ISousRepartitionDto} from '../sous-repartition/sous-repartition.dto';

export interface IChargeAnnexeDto {
	id: number;
	titre: string;
	charge: number;
	order: number;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousRepartitions: ISousRepartitionDto[];
}

export class ChargeAnnexeDto implements IChargeAnnexeDto {
	id: number;
	titre: string;
	charge: number;
	order: number;
	chiffrageId: number;
	chiffrage: IChiffrageDto;
	sousRepartitions: ISousRepartitionDto[];

	constructor(id: number, titre: string, charge: number, order: number, chiffrage: IChiffrageDto, sousRepartitions: ISousRepartitionDto[]) {
		this.id = id;
		this.titre = titre;
		this.charge = charge;
		this.order = order;
		this.chiffrageId = chiffrage?.id;
		this.chiffrage = chiffrage;
		this.sousRepartitions = sousRepartitions;
	}
}
