import {V1ReportService} from '../../core/business/service/v1-report/v1-report.service';
import {AxisService} from '../../core/business/service/v1-report/axis/axis.service';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';
import {ProjectService} from '../../core/business/service/project/project.service';
import {IReportDto} from '../../core/business/service/v1-report/v1-report.dto';
import {ActivatedRoute, Router} from '@angular/router';
import {IProjectDto} from '../../core/business/service/project/project.dto';
import {PaginationOption} from '../../core/pagination/dto/pagination-option.dto';
import {Pagination} from '../../core/pagination/dto/pagination.dto';
import {firstValueFrom, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {SpinnerService} from '../../core/service/spinner.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {SearchbarComponent} from '../../utils/components/searchbar/searchbar.component';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {ICompanyDto} from '../../core/business/service/company/company.dto';
import {CompanyService} from '../../core/business/service/company/company.service';

@Component({
	selector: 'app-report-list',
	templateUrl: './report-list.component.html',
	styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent extends UserInfo implements OnInit, AfterViewInit {

	projects: Pagination<IProjectDto>;
	periods: IProjectDto[];
	totalSum: { m0: number, m1: number, m2: number } = {m0: 0, m1: 0, m2: 0};

	companies: ICompanyDto[] = [];
	companiesId: number[] = [];

	filterSearchInput: string;
	filterCompanies: ICompanyDto[];
	paginationOption: PaginationOption;

	reportOrder: string;
	selectedDate: moment.Moment;
	startDate: moment.Moment;
	endDate: moment.Moment;
	maxDate: moment.Moment;

	older: number = 0;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(SearchbarComponent) searchBar: SearchbarComponent;
	@ViewChild('selectCompanies') selectCompanies: MatSelect;

	constructor(public override keycloak: KeycloakService,
				private _v1reportService: V1ReportService,
				private _axisService: AxisService,
				private _translator: TranslateService,
				private _projectService: ProjectService,
				private _companyService: CompanyService,
				private _dialog: MatDialog,
				private _snackBar: MatSnackBar,
				private _spinnerService: SpinnerService,
				private _router: Router,
				private _titleService: Title,
				private _dateAdapter: DateAdapter<Date>,
				private _route: ActivatedRoute) {
		super(keycloak);
	}

	async ngOnInit(): Promise<void> {
		this._dateAdapter.setLocale('fr');
		this._dateAdapter.getFirstDayOfWeek = () => 1;

		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Rapports V1');
		const selectedDate: string = this._route.snapshot.queryParams['selectedDate'];

		this.maxDate = moment().isoWeekday(1).startOf('day');
		if (selectedDate && this.maxDate >= moment(selectedDate)) {
			this.selectedDate = moment(selectedDate).isoWeekday(1);
			this.startDate = moment(selectedDate).isoWeekday(1);
			this.endDate = moment(selectedDate).isoWeekday(1).add(1, 'week');
		} else {
			// If we are on monday and the query param is not specified, we want to see the past week
			if (moment().isoWeekday() === 1) {
				this.selectedDate = moment().add(-1, 'week').isoWeekday(1);
				this.startDate = moment().add(-1, 'week').isoWeekday(1);
				this.endDate = moment().add(-1, 'week').isoWeekday(1).add(1, 'week');
			} else {
				this.selectedDate = moment().isoWeekday(1);
				this.startDate = moment().isoWeekday(1);
				this.endDate = moment().isoWeekday(1).add(1, 'week');
			}
		}

		this.companies = await firstValueFrom(this._companyService.getActiveCompanies());
		if (this.currentUser.company) {
			this.filterCompanies = [this.currentUser.company];
		} else {
			this.filterCompanies = this.companies;
		}

		this.paginationOption = new PaginationOption(20, 0);
		this.refreshURL();

		this.reportOrder = 'creation_date';

		this.loadReports();
		this.loadPeriods();
	}

	ngAfterViewInit(): void {
		merge(this.searchBar.clearInput, this.searchBar.valueChange, this.selectCompanies.selectionChange.pipe(
			debounceTime(500),
			distinctUntilChanged())
		).pipe(tap(() => {
			this.companiesId = [];
			if (this.selectCompanies.value.length) {
				for (const company of this.selectCompanies.value) {
					this.companiesId.push(company.id);
				}
			} else {
				this.companiesId.push(this.selectCompanies.value.id);
			}
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadReports();
		})).subscribe();

		merge(this.paginator.page, this.paginator.pageSize).pipe(tap(() => {
			this.paginationOption.limit = this.paginator.pageSize;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadReports();
			this.paginator.length = this.projects.total;
		})).subscribe();
	}

	loadReports(): void {
		this._spinnerService.enableLoading();
		this._v1reportService.getSumM(this.selectedDate).subscribe((result) => {
			this.totalSum = result;
		});
		this._projectService.getWithReportsByWeekPaginated(
			this.selectedDate,
			this.endDate,
			this.filterSearchInput,
			this.filterCompanies,
			this.paginationOption
		).subscribe((result) => {
				this.projects = result;
				this.addProjectDatas();
				this.orderReports();
				this._spinnerService.disableLoading();
				this.getFirstV1();
			}
		);
	}

	getFirstV1(): void {
		this._projectService.getV1NextProject(this.startDate, this.endDate, this.filterCompanies[0].id).subscribe((result) => {
			this.older = result;
		});
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.loadReports();
	}

	loadPeriods(): void {
		this._projectService.findByPeriod(this.startDate, this.endDate).subscribe((result) => {
			this.periods = result;
		});
	}

	closeReports(): void {
		const confirmClose: boolean = confirm(this._translator.instant('V1_REPORT.CONFIRM_CLOSE_MANY'));
		if (confirmClose) {
			this._v1reportService.closeWeekReports(this.selectedDate).subscribe(() => {
				this._snackBar.open(this._translator.instant('V1_REPORT.CLOSED_MANY'), '', {
					panelClass: 'success'
				});
			});
			this.loadReports();
			this.loadPeriods();
		}
	}

	orderReports(e: MatSelectChange = null): void {
		if (e) {
			this.reportOrder = e.value;
		}

		if (this.reportOrder === 'alphabetic') {
			this.projects.results.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
		}
		if (this.reportOrder === 'alerts') {
			this.projects.results.sort((a, b) => {
				if (this.getPublishedReportsLength(a) && this.getPublishedReportsLength(b)) {
					const criticalAlerts: number = this.getAlertByCriticality(b.reports[0], 1)
						- this.getAlertByCriticality(a.reports[0], 1);
					if (criticalAlerts === 0) {
						return this.getAlertByCriticality(b.reports[0], 2) - this.getAlertByCriticality(a.reports[0], 2);
					}
					return criticalAlerts;
				}
				return this.getPublishedReportsLength(b) - this.getPublishedReportsLength(a);
			});
		}
		if (this.reportOrder === 'creation_date') {
			this.projects.results.sort((a, b) => {
				return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
			});
		}
	}

	getPublishedReportsLength(project: IProjectDto): number {
		return project.reports.filter((r) => r.isPublished).length;
	}

	getAlertByCriticality(report: IReportDto, criticality: number): number {
		return report.axisReports.filter((axisReport) => axisReport.criticality === criticality).length;
	}

	getWeekInfos(): string {
		return this._translator.instant('V1_REPORT.WEEK', {week: this.selectedDate.format('W')});
	}

	clearFilterField(): void {
		this.filterSearchInput = '';
	}

	dateChange(e: MatDatepickerInputEvent<Date>): void {
		this.selectedDate = moment(e.value).isoWeekday(1);

		this.refreshURL();
		this.loadReports();
		this.loadPeriods();
		this.getFirstV1();
	}

	moveToWeek(amount: number): void {
		this.selectedDate.add(amount, 'week');
		this.startDate.add(amount, 'week');
		this.endDate.add(amount, 'week');

		this.refreshURL();
		this.loadReports();
		this.loadPeriods();
		this.getFirstV1();
	}

	refreshURL(): void {
		const dateString: string = moment(this.selectedDate).startOf('week').add(2, 'day').toISOString().split('T')[0];
		history.pushState(
			{},
			'',
			window.location.origin + window.location.pathname + '?selectedDate=' + dateString
		);
	}

	addProjectDatas(): void {
		for (const project of this.projects.results) {
			project.periodSpentSum = this.getSpentSum(project);
			project.periodProdSum = this.getProductionSum(project);
		}
	}

	getSpentSum(project: IProjectDto): number {
		if (this.periods) {
			const projectPeriod: IProjectDto = this.periods.find((period) => period.id === project.id);
			if (projectPeriod) {
				return projectPeriod.period_report.reduce((acc, pr) => pr.spentSum + acc, 0);
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	getProductionSum(project: IProjectDto): number {
		if (this.periods) {
			const projectPeriod: IProjectDto = this.periods.find((period) => period.id === project.id);
			if (projectPeriod) {
				return projectPeriod.period_report.reduce((acc, pr) => pr.productionSum + acc, 0);
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	getMonth(offset: number): string {
		return moment(this.selectedDate).add(offset, 'month').format('MMMM');
	}
}
