import {NgModule} from '@angular/core';
import {ReportListComponent} from './report-list/report-list.component';
import {HttpClientModule} from '@angular/common/http';
import {HeaderModule} from '../template/header/header.module';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {UtilModule} from '../utils/util.module';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {Title} from '@angular/platform-browser';
import {ReportDetailComponent} from './report-details/report-detail.component';
import {FocusOnInputModule} from '../utils/components/focus-on-input/focus-on-input.module';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AdminRolePipe, RolePipe} from '../security/pipe/role.pipe';

const routes: Routes = [
	{path: '', component: ReportListComponent, data: {roles: [PermissionEnum.ADMIN, 'v1-report', 'raf']}},
	{path: ':id', component: ReportDetailComponent, data: {roles: [PermissionEnum.ADMIN, 'v1-report', 'raf']}}
];

@NgModule({
	declarations: [ReportListComponent, ReportDetailComponent],
	imports: [
		RouterModule.forChild(routes),
		HttpClientModule,
		HeaderModule,
		MatIconModule,
		FormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		TranslateModule,
		CommonModule,
		MatSelectModule,
		UtilModule,
		MatPaginatorModule,
		OgdpPaginatorModule,
		MatTooltipModule,
		MatMenuModule,
		FocusOnInputModule,
		MatCheckboxModule,
		AdminRolePipe,
		RolePipe,
	],
	providers: [
		Title,
	],
})
export class V1ReportModule {
}
