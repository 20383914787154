import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AvantVenteFilterDto, IAvantVenteDto} from './avant-vente.dto';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Pagination} from '../../../pagination/dto/pagination.dto';

@Injectable({
	providedIn: 'root'
})
export class AvantVenteService extends ApiService {

	private route: string = '/api/avant-vente';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(avantVenteDto: IAvantVenteDto, chiffrageType: string): Observable<IAvantVenteDto> {
		return this.post(this.route, {
			avantVenteDto,
			chiffrageType
		});
	}

	update(avantVenteDto: IAvantVenteDto): Observable<IAvantVenteDto> {
		return this.put(this.route + '/' + avantVenteDto.id, avantVenteDto);
	}

	remove(id: number): Observable<void> {
		return this.delete(this.route + '/' + id);
	}

	findById(avantVenteId: number): Observable<IAvantVenteDto> {
		return this.get(this.route + '/' + avantVenteId);
	}

	findAllPaging(paginationOption: PaginationOption): Observable<Pagination<IAvantVenteDto>> {
		return this.post(this.route + '/paging/all', {paginationOption: paginationOption});
	}

	findByFiltersPaging(filter: AvantVenteFilterDto, paginationOption: PaginationOption): Observable<Pagination<IAvantVenteDto>> {
		return this.post(this.route + '/paging/filtered', {
			filter: {
				titre: filter.titre,
				etat: filter.etat,
				clientId: filter.client ? filter.client.id : null
			},
			paginationOption: paginationOption
		});
	}
}
