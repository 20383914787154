import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IChiffrageDto} from '../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

@Component({
	selector: 'app-dialog-chiffrage-edit',
	styleUrls: ['./dialog-chiffrage-edit.component.scss'],
	templateUrl: './dialog-chiffrage-edit.component.html'
})
export class DialogChiffrageEditComponent {

	chiffrage: IChiffrageDto;

	@ViewChild('txtTjm') txtTjm: ElementRef;
	@ViewChild('txtPercentGestProj') txtPercentGestProj: ElementRef;
	@ViewChild('txtPercentItTest') txtPercentItTest: ElementRef;

	constructor(public dialogRef: MatDialogRef<DialogChiffrageEditComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { chiffrage: IChiffrageDto }) {
		this.chiffrage = data.chiffrage;
	}

	onYesClick(): void {
		if (this.txtTjm.nativeElement?.value?.length
			&& this.txtPercentGestProj.nativeElement?.value?.length
			&& this.txtPercentItTest.nativeElement?.value?.length) {
			this.dialogRef.close(this.chiffrage);
		}
	}
}
