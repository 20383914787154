import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IStoryDto} from '../../../../../core/business/service/story/story.dto';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, startWith, tap} from 'rxjs/operators';
import {LotDataSourceContent} from '../../../datasource/projet-detail.datasource';
import {UtilsService} from '../../../../../utils/service/utils.service';
import {merge} from 'rxjs';
import {ILotRedistribution} from '../../../../../core/business/service/synchronization/synchronization.service';
import {LotRedistribution} from '../dialog-redistribution-budget-synchro.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
	selector: 'app-redistribution-budget-lot',
	templateUrl: './redistribution-budget-lot.component.html',
	styleUrls: ['./redistribution-budget-lot.component.scss']
})
export class RedistributionBudgetLotComponent implements AfterViewInit {

	@ViewChild('forceModifBudget') forceModifBudget: MatCheckbox;
	@ViewChild('autocomplete') autocomplete: MatAutocomplete;

	@Input() stories: LotDataSourceContent[];
	@Input() item: LotRedistribution;

	@Output() validChange: EventEmitter<void> = new EventEmitter<void>();

	constructor(private _translator: TranslateService) {
	}

	ngAfterViewInit(): void {
		this.item.storyFormControl.valueChanges.pipe(
			startWith(''),
			debounceTime(250),
			distinctUntilChanged())
			.subscribe(value => {
				this.item.selectStoryFiltered = [];
				if (typeof value === 'string') {
					const matchStories: LotDataSourceContent[] = this.dataSelectStory(value);
					if (matchStories.length === 0) {
						this.item.errorMessage = this._translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
					}
					this.item.selectStoryFiltered = matchStories;
				}
			});

		merge(
			this.autocomplete.optionSelected,
			this.forceModifBudget.change
		).pipe(tap(() => {
			this.item.errorMessage = '';
			if (this.item.isForceModifBudget) {
				this.item.destStory = null;
				this.item.storyFormControl.setValue('');
				this.item.isValid = true;
				this.validChange.emit();
			} else {
				this.item.destStory = this.item.storyFormControl.value;
				if (this.item.destStory) {
					if (this.item.destStory.budget >= this.item.deltaBudget) {
						this.item.isValid = true;
						this.validChange.emit();
					} else {
						this.item.isValid = false;
						this.validChange.emit();
						this.item.errorMessage = this._translator.instant('PROJECT.DETAIL.MOVE_STORY.NOT_ENOUGH_RAE');
					}
				} else {
					if (!this.item.selectStoryFiltered?.length) {
						this.item.errorMessage = this._translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
					}
					this.item.isValid = false;
					this.validChange.emit();
				}
			}
		})).subscribe();
	}

	private dataSelectStory(filter: string): LotDataSourceContent[] {
		const filterValue: string = filter.toLowerCase();
		return this.stories.filter(story =>
			story.lotId === this.item.lot.lotId
			&& !this.item.issues.some(issue => issue.issueId === story.id)
			&& (story.name.toLowerCase().indexOf(filterValue) === 0
			|| story.jiraIntId.toLowerCase().indexOf(filterValue) === 0)
			&& (story.budget >= this.item.deltaBudget)
		).sort(UtilsService.dynamicMultiSort('name'));
	}

	displaySelectedStory(story: IStoryDto): string {
		return story ? story.name + ' - ' + story.jiraIntId + ' - ' + story.remainingEXT : '';
	}

	getPlaceholder(item: ILotRedistribution): string {
		return this._translator.instant('STORY.BUDGET_REDISTRIBUTION.SELECTION_' + (item.deltaBudget > 0 ? 'DEBIT' : 'CREDIT'))
			+ ' ( ' + Math.abs(item.deltaBudget) + ' )';
	}
}
