import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ITempoJiraDto} from '../../../core/business/service/tempo-jira/tempo-jira.dto';
import {ConfigTempoJira} from '../../../core/business/service/user-config/user-config.dto';

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss']
})
export class ConfigDialogComponent implements OnInit {
	users: ITempoJiraDto[] = [];
	config: ConfigTempoJira;
	active: ITempoJiraDto[] = [];
	disable: ITempoJiraDto[] = [];

	focus: ITempoJiraDto = null;
	source: 'ACTIVE' | 'DISABLE' = 'ACTIVE';
	ready: boolean = false;
	search: string;
	constructor(
		public dialogRef: MatDialogRef<ConfigDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: { users: ITempoJiraDto[], config: ConfigTempoJira}) {
		this.users = data.users;
		this.config = data.config;
	}

	ngOnInit(): void {
		this.updateList();
		this.ready = true;
	}
	updateList(): void {
		this.active = [];
		this.disable = [];
		this.users.forEach(it => {
			if (!this.search || it.displayName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
				if (this.config.jiraID.includes(it.id)) {
					this.disable.push(it);
				} else {
					this.active.push(it);
				}
			}

		});
		this.sort(this.active);
		this.sort(this.disable);
	}
	sort(tab: ITempoJiraDto[]): void {
		tab.sort((l, r) => {
			const i: string = l.displayName.toLowerCase();
			const j: string = r.displayName.toLowerCase();
			return (i < j) ? -1 : (i > j) ? 1 : 0;
		});
	}
	setFocus(user: ITempoJiraDto): void {
		this.focus = user;
	}
	lostFocus(): void {
		this.focus = null;
	}
	addToIgnoreList(): void {
		if (!this.focus) {
			return;
		}
		this.config.jiraID.push(this.focus.id);
		this.updateList();
		this.lostFocus();
	}
	removeOfIgnoreList(): void {
		if (!this.focus) {
			return;
		}
		const index: number = this.config.jiraID.indexOf(this.focus.id);
		this.config.jiraID.splice(index , 1);
		this.updateList();
		this.lostFocus();
	}
	close(save: boolean = false): void {
		if (save) {
			this.dialogRef.close(this.config);
		} else {
			this.dialogRef.close();
		}
	}


}
