import {UserBaseDto} from '../user-base/user-base.dto';


export class UserConfigDto {
	id: number;
	userId: number;
	user: UserBaseDto;
	type: ConfigType;
	config: any;
}

export enum ConfigType {
	TEMPOJIRA = 'TEMPOJIRA',
}

export class ConfigTempoJira {
	jiraID: string[];
}
