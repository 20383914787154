import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectMessagesComponent} from './project-messages.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
	declarations: [ProjectMessagesComponent],
	exports: [ProjectMessagesComponent],
	imports: [CommonModule, MatTooltipModule, TranslateModule]
})
export class ProjectMessagesModule {
}
