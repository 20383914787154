<app-header [displayBreadCrumb]="'SUIVI.HEADER' | translate">
	<ng-container #headerElementsContainer>
		<!-- Recherche par nom -->
		<div class="ms-5">
			<app-searchbar [(value)]="suiviListFilter.filterProjectName" (clearInput)="clearSearch()"></app-searchbar>
		</div>

		<!-- Période d'affichage -->
		<div class="calendars">
			<mat-form-field>
				<mat-label>{{'SUIVI.FILTER.PERIOD' | translate}}</mat-label>
				<input [(ngModel)]="suiviListFilter.periodStart" [matDatepicker]="periodStartPicker"
					   [max]="suiviListFilter.periodEnd || limitEnd" [min]="limitStart" matInput>
				<mat-datepicker-toggle [for]="periodStartPicker" matSuffix></mat-datepicker-toggle>
				<mat-datepicker (monthSelected)="onMonthSelected($event, periodStartPicker, 'start')" #periodStartPicker
								startView="year"></mat-datepicker>
			</mat-form-field>
			<span class="calendar-sep">-</span>
			<mat-form-field>
				<mat-label></mat-label>
				<input [(ngModel)]="suiviListFilter.periodEnd" [matDatepicker]="periodEndPicker" [max]="limitEnd"
					   [min]="suiviListFilter.periodStart"
					   matInput>
				<mat-datepicker-toggle [for]="periodEndPicker" matSuffix></mat-datepicker-toggle>
				<mat-datepicker (monthSelected)="onMonthSelected($event, periodEndPicker, 'end')" #periodEndPicker
								startView="year"></mat-datepicker>
			</mat-form-field>
		</div>

		<!-- Statut projet -->
		<div>
			<mat-form-field class="status-filter">
				<mat-label>{{'PROJECT.STATUS.TITLE' | translate}} {{'PROJECT.TITLE' | translate}}</mat-label>
				<mat-select [(ngModel)]="suiviListFilter.withStatus" [hideSingleSelectionIndicator]="true"
							#filterStatus>
					<mat-option [value]="null">{{'PROJECT.STATUS.INDIFFERENT' | translate}}</mat-option>
					<mat-option [value]="'OPEN'">{{'PROJECT.STATUS.OPEN' | translate}}</mat-option>
					<mat-option [value]="'ARCHIVE'">{{'PROJECT.STATUS.ARCHIVE' | translate}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- Client -->
		<app-client-select [(client)]="suiviListFilter.client" [clients]="clients"></app-client-select>

		<!-- Company -->
		<div>
			<mat-form-field>
				<mat-label>Entreprises</mat-label>
				<mat-select [(ngModel)]="suiviListFilter.companies" #selectCompanies multiple>
					<mat-option [value]="company" *ngFor="let company of companies">{{ company.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- Coches -->
		<div class="checkbox-filter">
			<mat-checkbox [(ngModel)]="dataSourcePaginate.displayTotalPeriod" #displayTotalPeriod>
				{{'SUIVI.FILTER.TOTAL_PERIOD' | translate}}
			</mat-checkbox>
			<mat-checkbox [(ngModel)]="suiviListFilter.showInactiveProjects" #showInactiveProjects>
				{{'SUIVI.FILTER.SHOW_INACTIVE' | translate}}
			</mat-checkbox>
			<mat-checkbox [(ngModel)]="suiviListFilter.hideProjectWithNoBudget" #hideProjectWithNoBudget>
				{{'SUIVI.FILTER.HIDE_NO_BUDGET' | translate}}
			</mat-checkbox>
		</div>
	</ng-container>
</app-header>


<div [style.height]="'calc(100% - 31px - ' + headerElementsContainer.offsetHeight + 'px)'" id="table">
	<table>
		<thead *ngIf="dataHeader | async">
		<tr>
			<th class="sticky-first-line sticky-left-first-col sticky-over-other border-b-grey-1 table-title-th"
				rowspan="2">
				<div class="d-flex justify-content-between align-items-center">
					<span class="table-title">{{'PROJECT.PROJECTS' | translate}}</span>
					<!-- Tri -->
					<mat-form-field>
						<mat-label>{{'PROJECT.SORT.TITLE' | translate}}</mat-label>
						<mat-select [(ngModel)]="paginationOption.sortBy" [hideSingleSelectionIndicator]="true" #sortBy>
							<mat-option [value]="null"></mat-option>
							<mat-option
								[value]="sortByOption['creationDateAsc']">{{'PROJECT.SORT.CREATION_ASC' | translate}}</mat-option>
							<mat-option
								[value]="sortByOption['creationDateDesc']">{{'PROJECT.SORT.CREATION_DESC' | translate}}</mat-option>
							<mat-option
								[value]="sortByOption['alphabetiqueOrderAsc']">{{'PROJECT.SORT.ALPHA' | translate}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</th>
			<th class="sticky-first-line sticky-left-second-col sticky-over-other border-b-grey-1 border-r-blue-double header-type-conso"
				rowspan="2">
				<!--colonne conso, produit, difference--></th>
			<th [colSpan]="mois.colspan" *ngFor="let mois of (dataHeader | async).months"
				class="sticky-first-line bg-blue-navy-2 color-white text-uppercase border-r-blue-double table-grey header-display-month">
				<span *ngIf="!mois.isMonthExpend">{{mois.date | date: 'MMM yy'}}</span>
				<span *ngIf="mois.isMonthExpend">{{mois.date | date: 'MMMM yyyy'}}</span>
			</th>
			<th *ngIf="dataSourcePaginate.displayTotalPeriod"
				class="sticky-first-line bg-blue-navy-2 color-white text-uppercase border-r-blue-double table-grey total-period"
				rowspan="2">
				{{'SUIVI.TABLE.TOTAL_PERIOD' | translate}}
			</th>
			<th class="colonne-vide sticky-first-line border-b-grey-1 border-r-grey-1" rowspan="2"></th>
			<th class="sticky-first-line sticky-right-first-col sticky-over-other header-fix-col text-uppercase title-total-project fw-bolder"
				rowspan="2">
				{{'SUIVI.TABLE.TOTAL_PROJECT' | translate}}
			</th>
		</tr>
		<tr>
			<ng-container *ngFor="let month of (dataHeader | async).months">
				<ng-container *ngFor="let period of month.periods">
					<th [class.bg-green-suivi]="period.period.validated" *ngIf="month.isMonthExpend"
						class="sticky-second-line header-fix-col color-grey-light-3 border-b-grey-1 border-r-grey-1 display-week">
						<div>
							<span [class.color-white]="period.period.validated" class="color-blue-navy-2 fw-bold">
								{{'SUIVI.TABLE.WEEK_LETTER' | translate}}{{period.period.week}}
							</span>
							<span [class.color-white]="period.period.validated" *ngIf="period.period.index"
								  class="color-blue-navy-2">
								({{period.period.index}})
							</span>
							<span (click)="openDialogValidatePeriod($event, period)"
								  *ngIf="(role | admin) && !period.period.validated">
								<mat-icon class="header-arrow-icon color-blue-navy-2">arrow_drop_down</mat-icon>
							</span>
						</div>
						<div>
							<span>{{period.period.dateBegin | date: 'dd'}}</span>
							<mat-icon class="header-arrow-icon">arrow_right_alt</mat-icon>
							<span>{{period.period.dateEnd | date: 'dd'}}</span>
						</div>
					</th>
				</ng-container>
				<th class="sticky-second-line header-fix-col bg-blue-navy-2 color-white text-uppercase border-r-blue-double">
					<button (click)="expandOrShrinkMonth(month)" class="bg-blue-navy-2 color-white" mat-flat-button>
						<mat-icon class="header-arrow-icon me-0">
							{{month.isMonthExpend ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}
						</mat-icon>
					</button>
				</th>
			</ng-container>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngFor="let project of dataProject | async">
			<tr>
				<th class="sticky-left-first-col sticky-over-other project-info-wrapper" rowspan="3">
					<div class="d-flex flex-row justify-content-between">
						<div class="project-logo">
							<div>
								<img [alt]="'APP.LOGO' | translate" [src]="project.client.logo | safizer"
									 *ngIf="project.client?.logo" class="logo">
							</div>
						</div>

						<div class="project-info d-flex flex-column justify-content-between">
							<div class="position-relative">
								<div class="project-name fw-bold d-flex">
									<span>{{project.name}}</span>
									<div (click)="openDialogProjectAlert(project.id)" class="d-flex flex-row">
										<app-project-messages [numberAlerts]="project.numberAlerts">
										</app-project-messages>
									</div>
								</div>
								<div>
									<a [href]="project.urlJiraIntId" class="table-grey" target="_blank">
										{{project.jiraIntId}}
									</a>
									<span class="table-grey"> | </span>
									<a [href]="project.urlJiraExtId" class="table-grey" target="_blank">
										{{project.jiraExtId}}
									</a>
								</div>
							</div>
							<div class="d-flex align-items-end justify-content-between project-stats">
								<div>
									<div>Entreprise</div>
									<div class="color-grey">
										<span>
											{{project.company ? project.company.name : ''}}
										</span>
									</div>
								</div>
								<div>
									<div>{{'PROJECT.BUDGET' | translate}}</div>
									<div class="color-grey">
										<span>{{project.budget || 0 | number:'1.0-3'}}</span>
									</div>
								</div>
								<div>
									<div>{{'PROJECT.RAE' | translate}}</div>
									<div class="color-grey">{{project.remainingEXTSum || 0 | number:'1.0-3'}}</div>
								</div>
								<div>
									<div>{{'PROJECT.STATUS.TITLE' | translate}}</div>
									<div>
										<span class="color-grey">
											{{'PROJECT.STATUS.' + project.status | translate}}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div>
							<a [routerLink]="['/suivi/', project.jiraIntId]" class="project-title-detail">
								<mat-icon>arrow_right_alt</mat-icon>
							</a>
						</div>
					</div>
				</th>
				<th class="sticky-left-second-col header-row-name table-line-first-third border-b-grey-light">
					{{'SUIVI.TABLE.CONSO' | translate}}
				</th>
				<ng-container *ngFor="let projectPeriodReport of project.periodReports">
					<td [class.border-b-grey-light]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.border-r-blue-double]="!projectPeriodReport.validatedAdmin && projectPeriodReport.totalMonth"
						[class.cursor-pointer]="canValidateUnvalidateAdmin(project, projectPeriodReport)"
						[class.row-validatedAdmin]="projectPeriodReport.validatedAdmin"
						[class.row-validatedCP]="!projectPeriodReport.validatedAdmin && projectPeriodReport.validatedCP"
						[class.table-line-first-third]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.total-period-value]="projectPeriodReport.totalPeriod"
						[class.total-row]="projectPeriodReport.totalMonth"
						(click)="validateUnvalidateAdmin(project, projectPeriodReport)"
						*ngIf="!projectPeriodReport.totalProject">
						<span>{{projectPeriodReport.spentSum | number:'1.0-3'}}</span>
					</td>

					<th *ngIf="projectPeriodReport.totalProject"
						class="sticky-right-first-col total-project fw-bold">
						<span>{{projectPeriodReport.spentSum | number:'1.0-3'}}</span>
					</th>
				</ng-container>
			</tr>

			<tr>
				<th class="sticky-left-second-col header-row-name table-line-second border-b-grey-light">
					{{'SUIVI.TABLE.PRODUIT' | translate}}
				</th>
				<ng-container *ngFor="let projectPeriodReport of project.periodReports">
					<td [class.border-b-grey-light]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.border-r-blue-double]="projectPeriodReport.totalMonth"
						[class.cursor-pointer]="canValidateUnvalidateAdmin(project, projectPeriodReport)"
						[class.row-validatedAdmin]="projectPeriodReport.validatedAdmin"
						[class.row-validatedCP]="!projectPeriodReport.validatedAdmin && projectPeriodReport.validatedCP"
						[class.table-line-second]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.total-period-value]="projectPeriodReport.totalPeriod"
						[class.total-row]="projectPeriodReport.totalMonth"
						(click)="validateUnvalidateAdmin(project, projectPeriodReport)"
						*ngIf="!projectPeriodReport.totalProject">
						<span>{{projectPeriodReport.productionSum | number:'1.0-3'}}</span>
					</td>

					<th *ngIf="projectPeriodReport.totalProject"
						class="sticky-right-first-col total-project fw-bold">
						<span>{{projectPeriodReport.productionSum | number:'1.0-3'}}</span>
					</th>
				</ng-container>
			</tr>

			<tr>
				<th class="sticky-left-second-col header-row-name table-line-first-third border-b-grey-1">
					{{'SUIVI.TABLE.DIFFERENCE' | translate}}
				</th>
				<ng-container *ngFor="let projectPeriodReport of project.periodReports">
					<td [class.border-b-blue]="projectPeriodReport.totalMonth"
						[class.border-b-grey-1]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.border-r-blue-double]="projectPeriodReport.totalMonth"
						[class.cursor-pointer]="canValidateUnvalidateAdmin(project, projectPeriodReport)"
						[class.row-validatedAdmin]="projectPeriodReport.validatedAdmin"
						[class.row-validatedCP]="!projectPeriodReport.validatedAdmin && projectPeriodReport.validatedCP"
						[class.table-line-first-third]="!projectPeriodReport.totalPeriod && !projectPeriodReport.totalMonth"
						[class.total-period-value]="projectPeriodReport.totalPeriod"
						[class.total-row]="projectPeriodReport.totalMonth"
						(click)="validateUnvalidateAdmin(project, projectPeriodReport)"
						*ngIf="!projectPeriodReport.totalProject"
						class="table-line-first-third">
						<span>{{projectPeriodReport.difference | number:'1.0-3'}}</span>
					</td>

					<th *ngIf="projectPeriodReport.totalProject"
						class="sticky-right-first-col total-project border-b-grey-1 fw-bold">
						<span [class.total-negatif]="projectPeriodReport.difference < 0"
							  [class.total-positif]="projectPeriodReport.difference > 0">
							{{projectPeriodReport.difference | number:'1.0-3'}}
						</span>
					</th>
				</ng-container>
			</tr>
		</ng-container>
		</tbody>
		<tfoot [class.d-none]="!(role | admin)" *ngIf="dataHeader | async as data">
		<tr>
			<th class="sticky-left-first-col sticky-over-other project-info-wrapper bg-blue-navy-2 border-r-grey-1"
				rowspan="3">
				<div class="project-info d-flex justify-content-between">
					<div class="project-name fw-bold d-flex color-white text-uppercase">
						{{'APP.TOTAL' | translate}}
					</div>
				</div>
			</th>
			<th class="sticky-left-second-col header-row-name border-b-grey-light bg-blue-navy-2 color-white">
				{{'SUIVI.TABLE.CONSO' | translate}}
			</th>

			<ng-container *ngFor="let month of data.months">
				<ng-container *ngFor="let period of month.periods">
					<th *ngIf="month.isMonthExpend"
						class="total-project fw-bold border-b-grey-light border-r-grey-1 table-line-first-third">
						<span>{{period.period.periodReports[0].spentSum | number:'1.0-3'}}</span>
					</th>
				</ng-container>
				<th class="bg-blue-navy-2 total-project color-white fw-bold border-r-blue-double">
					<span>{{month.totalMonth.spentSum | number:'1.0-3'}}</span>
				</th>
			</ng-container>
			<th *ngIf="dataSourcePaginate.displayTotalPeriod"
				class="bg-blue-navy-2 color-white text-uppercase border-r-blue-double total-period-value">
				<span>{{data.totalPeriod.spentSum | number:'1.0-3'}}</span>
			</th>
			<th class="colonne-vide border-b-grey-light border-r-grey-1 table-line-first-third"></th>
			<th class="sticky-right-first-col sticky-over-other header-fix-col text-uppercase title-total-project fw-bolder">
				<span>{{data.totalProjects.spentSum | number:'1.0-3'}}</span>
			</th>
		</tr>

		<tr>
			<th class="sticky-left-second-col header-row-name border-b-grey-light bg-blue-navy-2 color-white">
				{{'SUIVI.TABLE.PRODUIT' | translate}}
			</th>
			<ng-container *ngFor="let month of data.months">
				<ng-container *ngFor="let period of month.periods">
					<th *ngIf="month.isMonthExpend" class="total-project fw-bold border-b-grey-light border-r-grey-1">
						<span>{{period.period.periodReports[0].productionSum | number:'1.0-3'}}</span>
					</th>
				</ng-container>
				<th class="bg-blue-navy-2 total-project color-white fw-bold border-r-blue-double">
					<span>{{month.totalMonth.productionSum | number:'1.0-3'}}</span>
				</th>
			</ng-container>
			<th *ngIf="dataSourcePaginate.displayTotalPeriod"
				class="bg-blue-navy-2 color-white text-uppercase border-r-blue-double total-period-value">
				<span>{{data.totalPeriod.productionSum | number:'1.0-3'}}</span>
			</th>
			<th class="colonne-vide border-b-grey-light border-r-grey-1"></th>
			<th class="sticky-right-first-col sticky-over-other header-fix-col text-uppercase title-total-project fw-bolder">
				<span>{{data.totalProjects.productionSum | number:'1.0-3'}}</span>
			</th>
		</tr>

		<tr>
			<th class="sticky-left-second-col header-row-name bg-blue-navy-2 color-white">
				{{'SUIVI.TABLE.DIFFERENCE' | translate}}
			</th>
			<ng-container *ngFor="let month of data.months">
				<ng-container *ngFor="let period of month.periods">
					<th *ngIf="month.isMonthExpend"
						class="total-project fw-bold border-b-grey-1 border-r-grey-1 table-line-first-third">
						<span>{{period.period.periodReports[0].productionSum - period.period.periodReports[0].spentSum
							| number:'1.0-3'}}</span>
					</th>
				</ng-container>
				<th class="bg-blue-navy-2 total-project color-white fw-bold border-r-blue-double">
					<span>{{month.totalMonth.productionSum - month.totalMonth.spentSum | number:'1.0-3'}}</span>
				</th>
			</ng-container>
			<th *ngIf="dataSourcePaginate.displayTotalPeriod"
				class="bg-blue-navy-2 color-white text-uppercase border-r-blue-double total-period-value">
				<span>{{data.totalPeriod.productionSum - data.totalPeriod.spentSum | number:'1.0-3'}}</span>
			</th>
			<th class="colonne-vide border-b-grey-1 border-r-grey-1 table-line-first-third"></th>
			<th class="sticky-right-first-col sticky-over-other header-fix-col text-uppercase title-total-project fw-bolder">
				<span [class.total-negatif]="data.totalProjects.difference < 0"
					  [class.total-positif]="data.totalProjects.difference > 0">
					{{data.totalProjects.difference | number:'1.0-3'}}
				</span>
			</th>
		</tr>
		</tfoot>
	</table>
	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>
	<app-ogdp-paginator [length]="dataSourcePaginate.length()" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
</div>

