import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {IClientDto} from '../../../core/business/service/client/client.dto';
import {ClientSelectComponent} from '../../../client/component/client-select/client-select.component';
import {AvantVenteEtatEnum, IAvantVenteDto} from '../../../core/business/service/avant-vente/avant-vente.dto';
import {ChiffrageTypeEnum} from '../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-avant-vente-edit',
	styleUrls: ['./dialog-avant-vente-edit.component.scss'],
	templateUrl: './dialog-avant-vente-edit.component.html'
})
export class DialogAvantVenteEditComponent implements AfterViewInit, OnInit {

	avantVente: IAvantVenteDto;
	chiffrageType: ChiffrageTypeEnum;
	chiffrageTypeEnum: string[] = Object.keys(ChiffrageTypeEnum);

	clients: Observable<IClientDto[]>;
	avantVenteEtatEnumKeys: string[] = Object.keys(AvantVenteEtatEnum);


	@ViewChild('selectedClient') selectedClient: ClientSelectComponent;

	constructor(public dialogRef: MatDialogRef<DialogAvantVenteEditComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { avantVente: IAvantVenteDto, clients: Observable<IClientDto[]> }) {

		this.avantVente = data.avantVente;
		this.clients = data.clients;
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {

	}

	onYesClick(): void {
		if (this.avantVente.titre?.trim().length > 0 && (this.avantVente.id || this.chiffrageType)) {
			// Protection contre des informations qu'on ne voudrais pas sauvegarder : Sinon erreur TypeOrm to eavy
			// Quand c'est unedined typeorm garde les informations présent en base de données, il n'ecrase pas
			this.avantVente.chiffrages = undefined;
			this.avantVente.chargeAnnexes = undefined;
			this.dialogRef.close([this.avantVente, this.chiffrageType]);
		}
	}
}
