import {Injectable} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom, Observable, of} from 'rxjs';
import {SPRAEExtUpdate} from '../../../../suivi/suivi-projet/dto/suivi-projet.dto';
import {Router} from '@angular/router';
import {IConsumedTimeDto, IPeriodReportDto, IPeriodReportDtos} from './period-report/period-report.dto';
import {IPeriodDto} from './period/period.dto';
import {SuiviListFilter} from '../../../../suivi/suivi-list/dto/suivi-list.dto';

@Injectable({
	providedIn: 'root'
})
export class ReportService extends ApiService {

	private route: string = '/api/report';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	spentSumNonValidatedForProjectAndLot(projetId: number, lotId: number): Promise<{ spentSum: number }> {
		return firstValueFrom(this.http.get(this.route + '/spentSumNonValidatedForProjectAndLot/' + projetId + '/' + lotId)).then();
	}

	findLastPeriodReportValidatedForProjectAndLot(projetId: number, lotId: number): Promise<IPeriodReportDto> {
		return firstValueFrom(this.http.get(this.route + '/findLastPeriodReportValidatedForProjectAndLot/' + projetId + '/' + lotId)).then();
	}

	findIfProjectHasAlerts(jiraIntId: string): Promise<boolean> {
		return firstValueFrom(this.http.get(this.route + '/has-alerts/' + jiraIntId)).then();
	}

	findPeriodForProject(jiraIntId: string, startDate?: string, endDate?: string): Promise<IPeriodReportDtos> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, jiraIntId], {
			queryParams: {
				start: startDate,
				end: endDate
			}
		}));

		return firstValueFrom(this.http.get<IPeriodReportDtos>(url));
	}

	findFirstPeriod(jiraIntId: string): Promise<IPeriodReportDto> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, jiraIntId, 'first-period']));

		return firstValueFrom(this.http.get<IPeriodReportDto>(url));
	}

	findByPeriodBetween(startDate: Date, endDate: Date, filter: SuiviListFilter): Observable<IPeriodDto[]> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'period', 'all'], {
			queryParams: {
				start: startDate.toISOString(),
				end: endDate.toISOString()
			}
		}));

		return this.post(url, {
			hideProjectWithNoBudget: filter.hideProjectWithNoBudget,
			withStatus: filter.withStatus,
			showInactiveProjects: filter.showInactiveProjects,
			filterProjectName: filter.filterProjectName,
			clientId: filter.client ? filter.client.id : null
		});
	}

	consumedTimeByProject(projectIds: number[]): Observable<IConsumedTimeDto[]> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'consumedTimeByProject']));

		if (projectIds.length > 0) {
			return this.post(url, {projectIds});
		} else {
			return of([]);
		}
	}

	consumedTime(filter: SuiviListFilter): Observable<IConsumedTimeDto> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'consumedTime']));
		return this.post(url, {
			hideProjectWithNoBudget: filter.hideProjectWithNoBudget,
			withStatus: filter.withStatus,
			showInactiveProjects: filter.showInactiveProjects,
			filterProjectName: filter.filterProjectName,
			clientId: filter.client ? filter.client.id : null
		});
	}

	saveRaeExts(raeExts: SPRAEExtUpdate[], jiraIntId?: string): Promise<boolean> {
		const url: string = this._router.serializeUrl(this._router.createUrlTree([this.route, 'rae-ext'], {
			queryParams: {
				projectKey: jiraIntId
			}
		}));

		return firstValueFrom(this.http.post(url, raeExts)).then(() => true).catch(() => false);
	}

	synchronizeProject(jiraIntId: string, smart: boolean = true, periodId?: number): Promise<boolean> {
		const periodIdParam: String = periodId ? ('&periodId=' + periodId) : '';
		// Sync le projet
		return firstValueFrom(this.http.get(`${this.route}/synchronize/${jiraIntId}?smart=${smart}${periodIdParam}`))
			.then(() => true);
	}

	validatePeriodForCP(periodId: number): Promise<IPeriodReportDto> {
		// Valide la période pour le CP
		return firstValueFrom(this.http.put<IPeriodReportDto>(this.route + '/validate/' + periodId + '/cp', {}));
	}

	validatePeriodForAdmin(periodId: number): Promise<IPeriodReportDto> {
		// Valide la période pour l'admin
		return firstValueFrom(this.http.put<IPeriodReportDto>(this.route + '/validate/' + periodId + '/admin', {}));
	}

	validateAllPeriodReportForCpAndAdmin(periodId: number): Promise<void> {
		// Valide la période cp et l'admin
		return firstValueFrom(this.http.post(this.route + '/validate/all/' + periodId + '/cpadmin', {})).then();
	}

	unvalidatePeriodForCP(periodId: number): Promise<IPeriodReportDto> {
		// Valide la période pour le CP
		return firstValueFrom(this.http.delete<IPeriodReportDto>(this.route + '/validate/' + periodId + '/cp'));
	}

	unvalidatePeriodForAdmin(periodId: number): Promise<IPeriodReportDto> {
		// Valide la période pour le CP
		return firstValueFrom(this.http.delete<IPeriodReportDto>(this.route + '/validate/' + periodId + '/admin'));
	}

	removeAlerts(periodReportId: number): Promise<void> {
		// Ignorer les erreurs pour la période
		return firstValueFrom(this.http.delete(this.route + '/alerts/' + periodReportId)).then();
	}
	findNextPeriodToValidate(jiraId: string, onlyCp: boolean ): Promise<IPeriodReportDto> {
		return firstValueFrom(this.http.get<IPeriodReportDto>(this.route + '/findNextPeriodToValidate/' + jiraId + '/' + onlyCp));
	}
	validateToPeriod(jiraId: string, onlyCp: boolean, target: number): Promise<number[]> {
		return firstValueFrom(this.http.post<number[]>(this.route + '/validateToPeriod', {
			jiraId,
			onlyCp,
			target
		}));
	}
}

