import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ICompanyDto} from '../../../core/business/service/company/company.dto';
import {UtilsService} from '../../../utils/service/utils.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
	selector: 'app-company-select',
	templateUrl: './company-select.component.html',
	styleUrls: ['./company-select.component.scss'],
})
export class CompanySelectComponent implements AfterViewInit {

	public selectCompanyController: FormControl = new FormControl();
	public companyFiltered: ICompanyDto[] = [];

	private _sortCompanies: ICompanyDto[] = [];

	@Input() company: ICompanyDto;
	@Output() companyChange: EventEmitter<ICompanyDto> = new EventEmitter<ICompanyDto>();

	@Input() set companies(companies: ICompanyDto[]) {
		if (companies) {
			this._sortCompanies = companies.sort(UtilsService.dynamicMultiSort('name'));
			this.companyFiltered = this._sortCompanies.slice();
			if (this.company) {
				this.selectCompanyController.patchValue(this.company);
			}
		}
	}

	constructor() {
	}

	ngAfterViewInit(): void {
		this.selectCompanyController.valueChanges
			.pipe(
				debounceTime(250),
				distinctUntilChanged())
			.subscribe((company: any) => {
				if (typeof company === 'string') {
					if (company) {
						this.companyFiltered = this.companyFilter(company);
					} else {
						this.company = null;
						this.companyChange.emit(null);
						this.companyFiltered = this._sortCompanies.slice();
					}
				} else {
					this.company = company;
					this.companyChange.emit(company);
					this.companyFiltered = this._sortCompanies.slice();
				}
			});
	}

	clearFilter(): void {
		this.company = null;
		this.companyChange.emit(null);
		this.selectCompanyController.patchValue(null);
	}

	companyFilter(value: string): ICompanyDto[] {
		const filterValue: string = value.toLowerCase();
		return this._sortCompanies.filter(company => company.name.toLowerCase().startsWith(filterValue));
	}

	displaySelectedCompany(company: ICompanyDto): string {
		return company ? company.name : '';
	}
}
