import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderModule} from '../template/header/header.module';
import {FormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {TranslateModule} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {TempoJiraComponent} from './tempo-jira.component';
import {UtilModule} from '../utils/util.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule, Routes} from '@angular/router';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import { ConfigDialogComponent } from './config-dialog/config-dialog/config-dialog.component';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';

const routes: Routes = [
	{path: '', component: TempoJiraComponent, data: {roles: [PermissionEnum.ADMIN]}},
];

@NgModule({
	declarations: [
		TempoJiraComponent,
  ConfigDialogComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		HeaderModule,
		MatTableModule,
		MatButtonModule,
		MatInputModule,
		FormsModule,
		MatPaginatorModule,
		MatSortModule,
		MatPaginatorModule,
		OgdpPaginatorModule,
		MatRadioModule,
		MatSelectModule,
		MatSortModule,
		TranslateModule,
		UtilModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		MatIconModule,
		DialogPopupModule,
	],
	providers: [
		Title,
	],
})
export class TempoJiraModule {
}
