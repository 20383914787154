import {IRepartitionDto} from '../../core/business/service/avant-vente/repartition/repartition.dto';
import {DatasourceItemNode} from '../avant-vente-detail/component/chiffrage/datasource/chiffrage.datasource';
import {ISousRepartitionDto} from '../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {IChiffrageDto} from '../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

export class AvantVenteBusiness {

	public static sortRepartitionOrder(n1: IRepartitionDto, n2: IRepartitionDto): number {
		return n1.order - n2.order;
	}

	public static sortItemNodeOrder(n1: DatasourceItemNode, n2: DatasourceItemNode): number {
		return n1.order - n2.order;
	}

	public static sortSousRepartitionOrder(n1: ISousRepartitionDto, n2: ISousRepartitionDto): number {
		return n1.repartition.order - n2.repartition.order;
	}

	public static getChiffrageMaxNumero(p: IChiffrageDto, v: IChiffrageDto): IChiffrageDto {
		if (p.numero > v.numero) {
			return p;
		} else {
			return v;
		}
	}
}
