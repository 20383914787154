<app-dialog-popup [popupTitle]="('USER.' + (userBase.id ? 'UPDATE' : 'NEW')) | translate">
	<div class="dialog-content" dialog-popup-content>
		<form [formGroup]="userForm">

			<div class="permission-row">
				<mat-form-field class="form-input-field">
					<mat-label>{{'USER.EDIT_FORM.ROLE.TITLE' | translate}}</mat-label>
					<mat-select [hideSingleSelectionIndicator]="true"
								(selectionChange)="handlePermissionsDisplay($event)" formControlName="permission">
						<mat-option [value]="'ADMIN'">
							{{'USER.EDIT_FORM.ROLE.ADMIN' | translate}}
						</mat-option>
						<mat-option [value]="'USER'">
							{{'USER.EDIT_FORM.ROLE.USER' | translate}}
						</mat-option>
					</mat-select>
					<mat-error
						*ngIf="userForm.get('permission').hasError('required')">{{'USER.ERROR_FORM.ROLE' | translate}}
						<strong>{{'USER.ERROR_FORM.REQUIRED_F' | translate}}</strong></mat-error>
				</mat-form-field>

				<mat-form-field *ngIf="displayPermissions" class="form-input-field">
					<mat-label>{{'USER.EDIT_FORM.ROLE.DETAILS.DETAILS' | translate}}</mat-label>
					<mat-select [value]="permissions" formControlName="permissions" multiple>
						<ng-container *ngFor="let parameter of permissions">
							<mat-option [title]="'USER.EDIT_FORM.ROLE.DETAILS.' + parameter.toUpperCase() | translate"
										[value]="parameter" *ngIf="parameter === 'hide_empty_epic'">
								{{'USER.EDIT_FORM.ROLE.DETAILS.' + parameter.toUpperCase() | translate}}
							</mat-option>
							<mat-option [title]="'PROJECT.DETAIL.FILTER.' + parameter.toUpperCase() | translate"
										[value]="parameter" *ngIf="parameter !== 'hide_empty_epic'">
								{{'USER.EDIT_FORM.ROLE.DETAILS.' + parameter.toUpperCase() | translate}}
							</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>

			<mat-form-field class="form-input-field">
				<mat-label>{{'USER.EDIT_FORM.PROJECTS' |translate}}</mat-label>
				<mat-select [compareWith]="projectEquals"
							(openedChange)="myInput.focus(); myInput.value = ''; filteredProjects = projects"
							formControlName="projects" multiple>
					<input [placeholder]="'APP.SEARCH' | translate" (keyup)="filterProjects($event.target.value)"
						   #myInput autocomplete="off"
						   class="p-2" matInput type="text">
					<mat-divider></mat-divider>
					<mat-option [class.d-none]="filteredProjects.indexOf(project) === -1" [value]="project"
								*ngFor="let project of projects">
						{{project.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>

	</div>

	<div dialog-popup-footer>
		<button (click)="createUser()" class="its-button" mat-flat-button>
			{{'APP.' + (userBase.id ? 'UPDATE' : 'CREATE') | translate}}
		</button>
	</div>
</app-dialog-popup>
