import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderModule} from '../template/header/header.module';
import {DialogPopupModule} from '../template/dialog/dialog-popup.module';
import {TranslateModule} from '@ngx-translate/core';
import {NumberInputModule} from '../utils/components/number-input/number-input.module';
import {RouterModule, Routes} from '@angular/router';
import {OgdpPaginatorModule} from '../utils/components/paginator/ogdp.paginator.module';
import {ClientSelectModule} from '../client/component/client-select/client-select.module';
import {AvantVenteListComponent} from './avant-vente-list/avant-vente-list.component';
import {AvantVenteDetailComponent} from './avant-vente-detail/avant-vente-detail.component';
import {Title} from '@angular/platform-browser';
import {MatSortModule} from '@angular/material/sort';
import {UtilModule} from '../utils/util.module';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {FormFieldPourcentageComponent} from './component/sous-repartition/pourcentage/form-field-pourcentage.component';
import {FormFieldMinMaxComponent} from './component/sous-repartition/min-max/form-field-minmax.component';
import {FormFieldReelComponent} from './component/sous-repartition/reel/form-field-reel.component';
import {DialogAvantVenteEditComponent} from './component/dialog-avant-vente-edit/dialog-avant-vente-edit.component';
import {ChiffrageComponent} from './avant-vente-detail/component/chiffrage/chiffrage.component';
import {ChiffrageCategorieComponent} from './avant-vente-detail/component/chiffrage/component/chiffrage-categorie/chiffrage-categorie.component';
import {ChiffrageSousCategorieComponent} from './avant-vente-detail/component/chiffrage/component/chiffrage-sous-categorie/chiffrage-sous-categorie.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FocusOnInputModule} from '../utils/components/focus-on-input/focus-on-input.module';
import {ChiffrageGlobalComponent} from './avant-vente-detail/component/chiffrage-global/chiffrage-global.component';
import {ChiffrageGlobalAnnexeComponent} from './avant-vente-detail/component/chiffrage-global/component/chiffrage-global-annexe/chiffrage-global-annexe.component';
import {DialogChiffrageCreateComponent} from './avant-vente-detail/component/dialog-chiffrage-create/dialog-chiffrage-create.component';
import {DialogRepartitionEditComponent} from './avant-vente-detail/component/dialog-repartition-edit/dialog-repartition-edit.component';
import {DialogChiffrageEditComponent} from './avant-vente-detail/component/dialog-chiffrage-edit/dialog-chiffrage-edit.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

const routes: Routes = [
	{path: '', component: AvantVenteListComponent, data: {roles: [PermissionEnum.ADMIN, 'avant-vente']}},
	{path: ':id', component: AvantVenteDetailComponent, data: {roles: [PermissionEnum.ADMIN]}}
];

@NgModule({
	declarations: [
		AvantVenteListComponent,
		AvantVenteDetailComponent,
		DialogAvantVenteEditComponent,
		FormFieldPourcentageComponent,
		FormFieldMinMaxComponent,
		FormFieldReelComponent,
		ChiffrageComponent,
		ChiffrageCategorieComponent,
		ChiffrageSousCategorieComponent,
		ChiffrageGlobalComponent,
		ChiffrageGlobalAnnexeComponent,
		DialogChiffrageCreateComponent,
		DialogRepartitionEditComponent,
		DialogChiffrageEditComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatTableModule,
		HeaderModule,
		MatCheckboxModule,
		MatSelectModule,
		DialogPopupModule,
		MatMenuModule,
		MatPaginatorModule,
		TranslateModule,
		NumberInputModule,
		OgdpPaginatorModule,
		ClientSelectModule,
		MatSortModule,
		UtilModule,
		ReactiveFormsModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatSlideToggleModule,
		MatTreeModule,
		MatProgressBarModule,
		DragDropModule,
		FocusOnInputModule,
		MatTooltipModule,
	],
	providers: [
		Title,
	],
})
export class AvantVenteModule {
}
