<app-dialog-popup [popupTitle]="('AVANT_VENTE.EDIT.' + (avantVente.id ? 'UPDATE' : 'NEW')) | translate">
	<div class="project-main-info-wrapper" dialog-popup-content>
		<img [alt]="avantVente.client?.name" [src]="avantVente.client.logo | safizer"
			 *ngIf="avantVente.client?.logo" class="av-project-logo"/>

		<div>
			<mat-form-field ngDefaultControl>
				<mat-label>{{'AVANT_VENTE.EDIT.AV_TITRE' | translate}}</mat-label>
				<input [(ngModel)]="avantVente.titre" matInput required>
			</mat-form-field>
			<app-client-select [(client)]="avantVente.client" [clients]="clients | async" #selectedClient
							   class="col"></app-client-select>

			<mat-form-field *ngIf="!avantVente.id">
				<mat-label>{{'AVANT_VENTE.CHIFFRAGE.TYPE.TITLE' | translate}}</mat-label>
				<mat-select [(ngModel)]="chiffrageType" required [hideSingleSelectionIndicator]="true">
					<mat-option [value]="type" *ngFor="let type of chiffrageTypeEnum">
						{{'AVANT_VENTE.CHIFFRAGE.TYPE.' + type | translate}}
					</mat-option>
				</mat-select>
			</mat-form-field>


			<mat-form-field *ngIf="avantVente.id">
				<mat-label>État</mat-label>
				<mat-select [(ngModel)]="avantVente.etat" [hideSingleSelectionIndicator]="true">
					<mat-option [value]="avantVenteEtat" *ngFor="let avantVenteEtat of avantVenteEtatEnumKeys">
						{{'AVANT_VENTE.ETAT.' + avantVenteEtat | translate}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="onYesClick()" class="its-button" mat-flat-button>
			{{'APP.' + (data.avantVente?.id ? 'UPDATE' : 'CREATE') | translate}}
		</button>
	</div>
</app-dialog-popup>

