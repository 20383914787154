<app-header [displayBreadCrumb]="'V1_REPORT.TITLE' | translate">
	<div class="ms-5">
		<app-searchbar [(value)]="filterSearchInput" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<div class="d-flex align-items-center">
		<button (click)="moveToWeek(-1)" class="week-arrow-btn" mat-icon-button>
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<mat-form-field class="select-week">
			<mat-label>{{'V1_REPORT.FORM.CHOSE_WEEK' | translate}}</mat-label>
			<input [matDatepicker]="picker" [max]="maxDate.toDate()" [value]="selectedDate.toDate()"
				   (dateChange)="dateChange($event)" matInput>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<button [disabled]="maxDate.toDate() <= startDate.clone().startOf('w').add('+1', 'week').toDate()"
				(click)="moveToWeek(1)" class="week-arrow-btn" mat-icon-button>
			<mat-icon>keyboard_arrow_right</mat-icon>
		</button>
	</div>

		<mat-form-field>
			<mat-label>{{'V1_REPORT.SORT.TITLE' | translate}}</mat-label>
			<mat-select [hideSingleSelectionIndicator]="true" [value]="reportOrder"
						(selectionChange)="orderReports($event)">
				<mat-option [title]="'V1_REPORT.SORT.ALPHABETIC_ORDER' | translate" value="alphabetic">
					{{ 'V1_REPORT.SORT.ALPHABETIC_ORDER' | translate}}
				</mat-option>
				<mat-option [title]="'V1_REPORT.SORT.ALERT_NUMBER' | translate" value="alerts">
					{{ 'V1_REPORT.SORT.ALERT_NUMBER' | translate}}
				</mat-option>
				<mat-option [title]="'V1_REPORT.SORT.CREATION_DATE' | translate" value="creation_date">
					{{ 'V1_REPORT.SORT.CREATION_DATE' | translate}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Company -->
		<mat-form-field>
			<mat-label>Entreprises</mat-label>
			<mat-select #selectCompanies [(ngModel)]="filterCompanies" [hideSingleSelectionIndicator]="true">
				<mat-option [value]="companies">Toutes</mat-option>
				<mat-option *ngFor="let company of companies" [value]="[company]">{{ company.name }}</mat-option>
			</mat-select>
		</mat-form-field>

	<div *ngIf="role | admin" class="actions header-buttons">
		<div style="height: 35%" matTooltip="Veuillez sélectionner une entreprise" [matTooltipDisabled]="filterCompanies && filterCompanies.length === 1">
			<button [disabled]="filterCompanies && filterCompanies.length !== 1"
					[queryParams]="{selectedDate: selectedDate.format('YYYY-MM-DD'), v1: true}"
					[routerLink]="'/v1-report/' + older" class="its-button v1" mat-flat-button>
				Lancer V1
			</button>
		</div>
		<button [disabled]="older === 0" (click)="closeReports()" class="its-button" mat-flat-button>
			{{ (older !== 0 ? 'V1_REPORT.CLOSE_WEEK' : 'V1_REPORT.WEEK_ALREADY_CLOSED')| translate }}
		</button>

	</div>
</app-header>
<div class="content">
	<div class="report-list-header">
		<div class="project-title header-cell">{{ getWeekInfos() }}</div>
		<div class="header-cell fire-cell">{{ 'V1_REPORT.CRITICALITY' | translate | uppercase }}</div>
		<div class="header-cell trend-cell">{{ 'V1_REPORT.TREND' | translate | uppercase }}</div>
		<div class="header-cell report-alert">{{ 'V1_REPORT.ALERT' | translate | uppercase}}</div>
		<div class="header-cell">{{ 'V1_REPORT.PROJECT.SPENT' | translate | uppercase}}</div>
		<div class="header-cell">{{ 'V1_REPORT.PROJECT.PRODUCT' | translate | uppercase}}</div>
		<div class="header-cell">{{ 'V1_REPORT.PROJECT.DIFF_SHORT' | translate | uppercase}}</div>
		<div *ngIf="role | role: ['ADMIN']: permissions: ['raf']"
			 class="header-cell affect-cell">{{ 'V1_REPORT.EFFECTIVE_PREVISION_SHORT' | translate | uppercase}}</div>
		<div class="header-cell report-synthesis">{{ 'V1_REPORT.SYNTHESIS' | translate | uppercase}}</div>
	</div>
	<div class="report-list-body">
		<div *ngFor="let project of projects?.results" class="report-row">
			<div class="body-cell project-title">
				<img [alt]="'APP.LOGO' | translate" [src]="project.client.logo | safizer" *ngIf="project?.client?.logo"
					 class="logo">
				<div class="d-flex flex-column flex-grow-1 ms-2">
					<span class="project-name">{{ project.name }}</span>
					<span class="project-jiraid">{{ project.jiraIntId }}</span>
				</div>
				<div class="ms-auto">
					<span class="project-company-name">{{ project.company ? project.company.name : '' }}</span>
				</div>
				<div [queryParams]="{selectedDate: selectedDate.format('YYYY-MM-DD'), companyId: project.companyId}"
					 [routerLink]="['/v1-report/', project.id]"
					 class="arrow-background d-flex">
					<div class="sp-right-arrow white-arrow align-self-center"></div>
				</div>
			</div>
			<ng-container *ngIf="project.reports && project.reports.length > 0 && project.reports[0].isPublished">
				<div [class.bg-green-suivi]="project.reports[0].criticality === 3"
					 [class.bg-grey-light-2]="!project.reports[0].criticality"
					 [class.bg-orange-2]="project.reports[0].criticality === 2"
					 [class.bg-red]="project.reports[0].criticality === 1"
					 class="body-cell align-items-center fire-cell"></div>
				<div class="body-cell trend-cell">
					<div *ngIf="project.reports[0].trend === 1" class="sp-right-arrow trend-low"></div>
					<div *ngIf="project.reports[0].trend === 2 || !project.reports[0].trend"
						 class="sp-right-arrow trend-medium"></div>
					<div *ngIf="project.reports[0].trend === 3" class="sp-right-arrow trend-high"></div>
				</div>
				<div class="body-cell report-alert">
					<ng-container
						*ngVar="(project.reports[0]?.axisReports | filter: 'criticality' : 1).length as criticalityLvl1Length">
						<div *ngIf="criticalityLvl1Length" class="alert-cell-item bg-red">
							{{criticalityLvl1Length}}
						</div>
						<div *ngIf="!criticalityLvl1Length" class="alert-cell-item"></div>
					</ng-container>
					<ng-container
						*ngVar="(project.reports[0]?.axisReports | filter: 'criticality' : 2).length as criticalityLvl2Length">
						<div *ngIf="criticalityLvl2Length" class="alert-cell-item bg-orange-2">
							{{criticalityLvl2Length}}
						</div>
						<div *ngIf="!criticalityLvl2Length" class="alert-cell-item"></div>
					</ng-container>
				</div>
				<div class="body-cell">{{ project.periodSpentSum }}</div>
				<div class="body-cell">{{ project.periodProdSum }}</div>
				<div [class.color-green-suivi]="project.periodProdSum - project.periodSpentSum > 0"
					 [class.color-red]="project.periodProdSum - project.periodSpentSum < 0"
					 class="body-cell">
					{{ project.periodProdSum - project.periodSpentSum }}
				</div>
				<div
					*ngIf="role | role: ['ADMIN']: permissions: ['raf']"
					class="body-cell affect-cell">
					{{ project.reports[0].m0 }} - {{ project.reports[0].m1 }} - {{ project.reports[0].m2 }}
				</div>
				<div class="body-cell report-synthesis"><p>{{ project.reports[0].summary }}</p></div>
			</ng-container>
			<ng-container *ngIf="!project.reports || project.reports.length === 0 || !project.reports[0].isPublished">
				<div class="no-report-cell d-flex align-items-center">{{ 'V1_REPORT.NOT_PUBLISHED' | translate }}</div>
			</ng-container>
		</div>
	</div>
	<mat-paginator [length]="projects?.total" [pageIndex]="paginationOption?.page"
				   [pageSizeOptions]="paginationOption?.pageSizeOptions"
				   [pageSize]="paginationOption?.limit"></mat-paginator>
	<app-ogdp-paginator [length]="projects?.total" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption?.page" [pageSizeOptions]="paginationOption?.pageSizeOptions"
						[pageSize]="paginationOption?.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
	<div *ngIf="(role | admin) && projects" class="d-flex flex-row effective-total">
		<div
			class="effective-total-title d-flex align-items-center">{{ 'V1_REPORT.EFFECTIVE_PREVISION' | translate }}</div>
		<div class="month-effective">
			<div>{{ 'MONTHS.' + getMonth(0) | uppercase | translate }} {{selectedDate.format('YYYY')}}</div>
			<div>{{ totalSum.m0 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span></div>
		</div>
		<div class="month-effective">
			<div>{{ 'MONTHS.' + getMonth(1) | uppercase | translate }} {{selectedDate.format('YYYY')}}</div>
			<div>{{ totalSum.m1 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span></div>
		</div>
		<div class="month-effective">
			<div>{{ 'MONTHS.' + getMonth(2) | uppercase | translate }} {{selectedDate.format('YYYY')}}</div>
			<div>{{ totalSum.m2 }} <span class="effective">{{ 'V1_REPORT.PEOPLE_SHORT' | translate }}</span></div>
		</div>
	</div>
</div>
