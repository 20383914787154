import {ICategorieDto} from '../categorie/categorie.dto';
import {ISousRepartitionDto} from '../sous-repartition/sous-repartition.dto';

export interface ISousCategorieDto {
	id: number;
	nom: string;
	charge: number | string;
	oldCharge?: number;
	optionel: boolean;
	order: number;
	comment: string;
	categorieId: number;
	categorie: ICategorieDto;
	sousRepartitions: ISousRepartitionDto[];
}

export class SousCategorieDto implements ISousCategorieDto {
	id: number;
	nom: string;
	charge: number;
	optionel: boolean;
	order: number;
	comment: string;
	categorieId: number;
	categorie: ICategorieDto;
	sousRepartitions: ISousRepartitionDto[];

	constructor(id: number, nom: string, charge: number, optionel: boolean, order: number, comment: string, categorieId: number, sousRepartitions: ISousRepartitionDto[]) {
		this.id = id;
		this.nom = nom;
		this.charge = charge;
		this.optionel = optionel;
		this.order = order;
		this.comment = comment;
		this.categorieId = categorieId;
		this.sousRepartitions = sousRepartitions;
	}
}
