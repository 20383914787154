export class UserListFilter {
	email: string;
	role: string;
	company: string;
	ogdpAccess: boolean;
	actif: boolean;
	permissions: string[];
	jiraAccess: boolean;

	constructor() {
	}
}
