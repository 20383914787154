import {Injectable} from '@angular/core';
import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ICategorieDto} from './categorie.dto';

@Injectable({
	providedIn: 'root'
})
export class CategorieService extends ApiService {

	private route: string = '/api/av-categorie';

	constructor(public http: HttpClient) {
		super(http);
	}

	create(categorieDto: ICategorieDto): Observable<ICategorieDto> {
		return this.post(this.route, categorieDto);
	}

	update(categorieDto: ICategorieDto): Observable<ICategorieDto> {
		return this.put(this.route + '/' + categorieDto.id, categorieDto);
	}

	remove(categorieDto: ICategorieDto): Observable<ICategorieDto> {
		return this.delete(this.route + '/' + categorieDto.id);
	}

	findByChiffrage(chiffrageId: number): Observable<ICategorieDto[]> {
		return this.get(this.route + '/chiffrage/' + chiffrageId);
	}

	findByChiffrageWithSousCategorie(chiffrageId: number): Observable<ICategorieDto[]> {
		return this.get(this.route + '/chiffrage-relation-sous-categorie/' + chiffrageId);
	}

	findByChiffrageWithSousCategorieAndSousRepartitionAndRepartition(chiffrageId: number): Observable<ICategorieDto[]> {
		return this.get(this.route + '/chiffrage-full-relation/' + chiffrageId);
	}
}
