<app-dialog-popup [popupTitle]="'ALERT.TITLE' | translate">
	<div dialog-popup-content class="dialog-content">
		<div class="actions">
			<button (click)="deleteWarningAlert(projectId)" class="its-button"
					mat-flat-button>{{'ALERT.DELETE_WARNING' | translate}}</button>
		</div>

		<mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="delete" class="mat-column-delete">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let alert">
					<div *ngIf="alert.type === 'WARNING'">
						<button class="sp-delete delete-button-style gradient"
								(click)="deleteAlert(alert)"></button>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="panneau" class="mat-column-panneau">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let alert">
					<div [ngSwitch]="alert.type">
						<ng-container *ngSwitchCase="'ERROR'">
							<span class="sp-iconmonstr-triangle-1 triangle-error"></span>
						</ng-container>
						<ng-container *ngSwitchCase="'WARNING'">
							<span class="sp-iconmonstr-triangle-1 triangle-warning"></span>
						</ng-container>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="date" class="mat-column-date">
				<mat-header-cell *matHeaderCellDef>{{'ALERT.DATE' | translate}}</mat-header-cell>
				<mat-cell *matCellDef="let alert">{{alert.creationDate | date:'dd/MM/yyyy HH:mm:ss'}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="type" class="mat-column-type">
				<mat-header-cell *matHeaderCellDef>{{'ALERT.TYPE.TITLE' | translate}}</mat-header-cell>
				<mat-cell *matCellDef="let alert">{{'ALERT.TYPE.' + alert.type | translate}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="message">
				<mat-header-cell *matHeaderCellDef>{{'ALERT.CAUSE.TITLE' | translate}}</mat-header-cell>
				<mat-cell *matCellDef="let alert">
					<div [title]="alert.message">{{alert.message}}</div>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.type"></mat-row>
		</mat-table>
	</div>

	<div dialog-popup-footer>
		<button (click)="closeDialog()" class="its-button" mat-flat-button>{{'APP.CLOSE' | translate}}</button>
	</div>
</app-dialog-popup>
