<app-dialog-popup [popupTitle]="'PROFILE.TITLE' | translate">
	<div dialog-popup-content class="overflow-hidden">
		<form [formGroup]="userForm">
			<!--	Div contenant la première partie du pop-up-->
			<div class="d-flex w-100 profile-main-info-wrapper">
				<!--Div contenant l'avatar du user en cours -->
				<div class="width-90 text-center">
					<img [alt]="currentUser.email" [src]="currentUser.avatar | safizer" class="icon-placeholder bg-dark"/>
				</div>

				<!-- nom, prénom, username -->
				<div class="px-4 w-100">
					<div class="row">
						<mat-form-field class="col-6">
							<mat-label>{{'PROFILE.USER.FIRSTNAME' | translate}}</mat-label>
							<input formControlName="firstName" matInput required>
						</mat-form-field>
						<mat-form-field class="col-6">
							<mat-label>{{'PROFILE.USER.LASTNAME' | translate}}</mat-label>
							<input formControlName="lastName" matInput required>
						</mat-form-field>
					</div>
					<div class="row">
						<mat-form-field class="col-12">
							<mat-label>{{'PROFILE.USER.EMAIL' | translate}}</mat-label>
							<input [value]="currentUser.email" disabled matInput>
						</mat-form-field>
					</div>
				</div>
			</div>
			<!--Fin de la première partie-->

			<!-- Token jira -->
			<div class="profile-info-wrapper">
				<mat-label class="jira-token-label w-100 justify-content-between">
					<span>{{'PROFILE.JIRA_TOKEN.TITLE' | translate}}
						<a href="https://itsfutur.atlassian.net/wiki/spaces/SDP/pages/667058459/Premi+re+connexion"
						   target="_blank">({{'APP.HELP' | translate}})</a>
					</span>
				</mat-label>
				<div>
					<mat-slide-toggle [(ngModel)]="jiraTokenChange" [ngModelOptions]="{standalone: true}">
						{{'PROFILE.JIRA_TOKEN.ACTIVATE' | translate}}
					</mat-slide-toggle>
					<mat-form-field class="jiraToken" *ngIf="jiraTokenChange">
						<input formControlName="jiraToken" matInput [type]="jiraTokenChange ? 'text' : 'password'">
					</mat-form-field>
				</div>
			</div>
		</form>
	</div>

	<div dialog-popup-footer>
		<button (click)="save()" [disabled]="userForm.invalid" class="its-button" mat-flat-button>
			{{'APP.SAVE' | translate}}
		</button>
	</div>
</app-dialog-popup>

