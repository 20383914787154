import {Injectable} from '@angular/core';

@Injectable()
export class UtilsService {

	constructor() {
	}

	/**
	 * Permet de sort sur un seul champs et de rentrer dans un objet pour trier par un champs d'un sous objet
	 * @param properties
	 */
	static dynamicSort = (properties: string[]) => {
		let sortOrder: number = 1;
		// determine sort order by checking sign of last element of array
		if (properties[properties.length - 1][0] === '-') {
			sortOrder = -1;
			// Chop off sign
			properties[properties.length - 1] = properties[properties.length - 1].substr(1);
		}
		return (a, b) => {
			const propertyOfA: any = UtilsService.recurseObjProp(a, properties);
			const propertyOfB: any = UtilsService.recurseObjProp(b, properties);
			const result: number = (propertyOfA < propertyOfB) ? -1 : (propertyOfA > propertyOfB) ? 1 : 0;
			return result * sortOrder;
		};
	}

	/**
	 * Takes an object and recurses down the tree to a target leaf and returns it value
	 * @param  {Object} root - Object to be traversed.
	 * @param  {Array} leafs - Array of downwards traversal. To access the value: {parent:{ child: 'value'}} -> ['parent','child']
	 * @param  {Number} index - Must not be set, since it is implicit.
	 * @return {String|Number}       The property, which is to be compared by sort.
	 */
	static recurseObjProp(root: Object, leafs: string[], index: number = 0): any {
		const upper: Object = root;
		// walk down one level
		const lower: Object = upper[leafs[index]];
		// Check if last leaf has been hit by having gone one step too far.
		// If so, return result from last step.
		if (!lower) {
			return upper;
		}
		// Else: recurse!
		index++;
		// HINT: Bug was here, for not explicitly returning function
		// https://stackoverflow.com/a/17528613/3580261
		return UtilsService.recurseObjProp(lower, leafs, index);
	}

	/**
	 * Permet de faire du tri d'objet facilement en choisissant les champs de l'objet.
	 * En mettant un - devant le nom du champs on peut lui dire que c'est en ordre descendant sinon c'est ascendant par défaut
	 *
	 * Multi-sort your array by a set of properties
	 * @param {...Array} Arrays to access values in the form of: {parent:{ child: 'value'}} -> ['parent','child']
	 * @return {Number} Number - number for sort algorithm
	 * @param args
	 */
	static dynamicMultiSort = (...args: string[]) => {
		return (a, b) => {
			let i: number = 0;
			let result: number = 0;
			const numberOfProperties: number = args.length;
			// REVIEW: slightly verbose; maybe no way around because of `.sort`-'s nature
			// Consider: `.forEach()`
			while (result === 0 && i < numberOfProperties) {
				result = UtilsService.dynamicSort(args[i].indexOf('.') === -1 ? [args[i]] : args[i].split('.'))(a, b);
				i++;
			}
			return result;
		};
	}

	/**
	 * Predicate de somme pour le calcul des totaux
	 */
	static sumValues = () => {
		return (a, b) => (a + b);
	}

	/**
	 * Compare two values
	 * @param a
	 * @param b
	 * @param isAsc
	 */
	static compare(a: number | string, b: number | string, isAsc: boolean = true): number {
		return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
	}

	/**
	 * Sort helper
	 * @param fct
	 * @param isAsc
	 */
	static comparing(fct: (a: any) => number | string, isAsc: boolean = true): any {
		return (a, b) => this.compare(fct(a), fct(b), isAsc);
	}

	static saveFile(blob: Blob, filename: string): void {
		const aElem: any = document.createElement('a');
		aElem.href = URL.createObjectURL(blob);
		aElem.download = filename;
		// start download
		aElem.click();
		// document.removeChild(aElem);
	}

	static getEnumKeyByValue(enumeration: any, value: any): string {
		if (enumeration && value) {
			return Object.keys(enumeration).find(key => enumeration[key] === value);
		} else {
			return '';
		}
	}
}
