import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClientSelectComponent} from '../../../../client/component/client-select/client-select.component';
import {IRepartitionDto} from '../../../../core/business/service/avant-vente/repartition/repartition.dto';

@Component({
	selector: 'app-dialog-repartition-edit',
	styleUrls: ['./dialog-repartition-edit.component.scss'],
	templateUrl: './dialog-repartition-edit.component.html'
})
export class DialogRepartitionEditComponent {

	repartition: IRepartitionDto;

	constructor(public dialogRef: MatDialogRef<DialogRepartitionEditComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { repartition: IRepartitionDto }) {
		this.repartition = data.repartition;
	}

	onYesClick(): void {
		if (this.repartition.nom?.trim().length > 0) {
			// Protection contre des informations qu'on ne voudrait pas sauvegarder : Sinon erreur TypeOrm to heavy
			// Quand c'est undefined typeorm garde les informations présent en base de données, il n'écrase pas
			this.dialogRef.close(this.repartition);
		}
	}
}
