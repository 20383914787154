import {
	AfterViewInit,
	Component, ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {IChargeAnnexeDto} from '../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';
import {ISousRepartitionDto} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {ISousCategorieDto} from '../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';

@Component({
	selector: 'app-form-field-reel',
	templateUrl: './form-field-reel.component.html',
	styleUrls: ['./form-field-reel.component.scss'],
})
export class FormFieldReelComponent implements AfterViewInit, OnInit {

	@Input() sousRepartition: ISousRepartitionDto;
	@Input() saveForm: () => void;

	@Output() updatedSousRepartition: EventEmitter<ISousRepartitionDto> = new EventEmitter<ISousRepartitionDto>();

	@ViewChild('input') input: ElementRef;
	temp: string;

	constructor() {
	}

	ngOnInit(): void {
		if (this.sousRepartition.charge) {
			this.temp = this.sousRepartition.charge.toString();
		} else {
			this.temp = '0';
		}
		this.updatedSousRepartitionCharge();
	}

	ngAfterViewInit(): void {
		merge(fromEvent(this.input.nativeElement, 'keyup').pipe(
			distinctUntilChanged())).pipe(tap(() => {
				this.updatedSousRepartitionCharge();
		})).subscribe();

	}

	private updatedSousRepartitionCharge(): void {
		this.sousRepartition.charge = +this.temp.replace(',', '.');
		this.updatedSousRepartition.emit(this.sousRepartition);
	}
}
