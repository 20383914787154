<app-dialog-popup [popupTitle]="'APP.EXPORT' | translate">
	<div dialog-popup-content class="dialog-content">

		<div class="position-relative">
			<div class="d-flex justify-content-center">
				<mat-checkbox [(ngModel)]="allLots" class="checkbox-lots" #chkAllLots>
					{{'LOT.SELECT.ALL' | translate}}
				</mat-checkbox>

				<mat-form-field>
					<mat-label>{{'PROJECT.STATUS.TITLE' | translate}}</mat-label>
					<mat-select [(ngModel)]="filter" #selectStatusLot [hideSingleSelectionIndicator]="true">
						<mat-option [value]="null"></mat-option>
						<mat-option *ngFor="let status of statusKeys" [value]="status">
							{{'PROJECT.CONTRACT.STATUS.' + status | translate}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<hr>

		<div [class.hide-table]="allLots">
			<mat-checkbox (change)="$event ? masterToggle() : null"
						  [checked]="selection.hasValue() && isAllSelected()"
						  [indeterminate]="selection.hasValue() && !isAllSelected()"
						  class="checkbox-lots">
				{{'APP.SELECT_ALL' | translate}}
			</mat-checkbox>
			<mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="id">
					<mat-cell *matCellDef="let element" [class.table-selected-cell]="selection.isSelected(element)">
						<mat-checkbox (click)="$event.stopPropagation()"
									  (change)="$event ? selection.toggle(element) : null"
									  [checked]="selection.isSelected(element)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="name">
					<mat-cell *matCellDef="let element"
							  [class.table-selected-cell]="selection.isSelected(element)">{{element.name}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="statut">
					<mat-cell *matCellDef="let element"
							  [class.table-selected-cell]="selection.isSelected(element)">{{element.contract}}</mat-cell>
				</ng-container>

				<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
			</mat-table>
		</div>
	</div>

	<div dialog-popup-footer>
		<button mat-flat-button class="its-button" (click)="actionBtnExporter()">{{'APP.EXPORT' | translate}}</button>
	</div>
</app-dialog-popup>
