import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from '../../../service/api.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ExportBddService extends ApiService {

	private route: string = '/api/export-bdd';

	constructor(public http: HttpClient, private _router: Router) {
		super(http);
	}

	obfuscateEveryProject(): Promise<void> {
		return firstValueFrom(this.get(this.route + '/obfuscate-every-project'));
	}
}
