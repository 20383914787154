import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../utils/service/utils.service';
import {FormControl} from '@angular/forms';
import {fromEvent, merge, Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {IStoryDto, StoryTypeEnum} from '../../../../core/business/service/story/story.dto';
import {TranslateService} from '@ngx-translate/core';
import {LotDataSourceContent} from '../../datasource/projet-detail.datasource';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';

export interface RedistributionBudgetResult {
	srcStory: LotDataSourceContent;
	destStory: LotDataSourceContent;
}

@Component({
	selector: 'app-dialog-redistribution-budget',
	styleUrls: ['./dialog-redistribution-budget.component.scss'],
	templateUrl: './dialog-redistribution-budget.component.html'
})
export class DialogRedistributionBudgetComponent implements AfterViewInit, OnInit {

	public stories: LotDataSourceContent[];
	public srcStory: LotDataSourceContent;

	public selectStoryController: FormControl;
	public selectStoryFiltered: Observable<LotDataSourceContent[]>;

	public destStory: LotDataSourceContent;
	public newBudget: number;
	public disableBtnValider: boolean = true;
	public errorMessage: string;
	public isForceModifBudget: false;

	public action: string;
	public keyPlaceholderSelectStory: string;

	@ViewChild('inputNewBudget') inputNewBudget: ElementRef;
	@ViewChild('forceModifBudget') forceModifBudget: MatCheckbox;
	@ViewChild('autocomplete') autocomplete: MatAutocomplete;
	@ViewChild('txtSelecedStory') txtSelecedStory: ElementRef;

	constructor(private translator: TranslateService, public dialogRef: MatDialogRef<DialogRedistributionBudgetComponent>) {
		this.selectStoryController = new FormControl();
	}

	ngOnInit(): void {
		if (!this.srcStory.id) { // En création, la popup ne s'ouvre que pour un lot signé
			this.newBudget = this.srcStory.budget;
			this.srcStory.budget = 0;
		}

		this.updateKeyPlaceholderSelectStory();
	}

	contingencyFirst(data: LotDataSourceContent[]): LotDataSourceContent[] {
		const contingencyStories: LotDataSourceContent[] = data.filter(lot => lot.storyType === StoryTypeEnum.CONTINGENCY);
		const otherStories: LotDataSourceContent[] = data.filter(lot => lot.storyType !== StoryTypeEnum.CONTINGENCY);
		const sortedStories: LotDataSourceContent[] = [];
		sortedStories.push(...contingencyStories, ...otherStories);
		return  sortedStories;
	}
	ngAfterViewInit(): void {
		this.selectStoryFiltered = this.selectStoryController.valueChanges.pipe(
			startWith(''),
			debounceTime(250),
			distinctUntilChanged(),
			map(value => {
				if (typeof value === 'string') {
					const matchStories: LotDataSourceContent[] = this.dataSelectStory(value);
					if (matchStories.length === 0) {
						this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
					}
					return  this.contingencyFirst(matchStories);
				}
			}));

		merge(
			this.autocomplete.optionSelected,
			this.forceModifBudget.change,
			fromEvent(this.inputNewBudget.nativeElement, 'keyup').pipe(
				debounceTime(250),
				distinctUntilChanged()),
			fromEvent(this.txtSelecedStory.nativeElement, 'keyup').pipe(
				debounceTime(250),
				distinctUntilChanged()))
			.pipe(tap(() => {
				this.errorMessage = '';
				if (this.isForceModifBudget) {
					this.destStory = null;
					this.selectStoryController.setValue('');
					if (this.newBudget != null) {
						this.disableBtnValider = false;
					}
				} else {
					this.destStory = this.selectStoryController.value;

					this.updateKeyPlaceholderSelectStory();

					if (this.destStory && this.newBudget != null) {
						if (this.hasEnoughRAE(this.destStory)) {
							this.disableBtnValider = false;
						} else {
							this.disableBtnValider = true;
							this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NOT_ENOUGH_RAE');
						}
					} else {
						this.selectStoryFiltered.subscribe(value => {
							if (value?.length === 0) {
								this.errorMessage = this.translator.instant('PROJECT.DETAIL.MOVE_STORY.NO_STORY_ENOUGH_RAE');
							}
						});
						this.disableBtnValider = true;
					}
				}
			})).subscribe();

		fromEvent(this.inputNewBudget.nativeElement, 'keyup').pipe(
			debounceTime(100),
			distinctUntilChanged(),
			tap(() => {
				this.selectStoryFiltered = of(this.contingencyFirst(this.dataSelectStory('')));
			})).subscribe();
	}

	actionBtnValider(): void {
		// Budget
		// /!\ ATTENTION : le deltaBudget est une valeur négative;
		const deltaBudget: number = this.srcStory.budget - this.newBudget;

		this.srcStory.budget = this.newBudget;
		if (!this.isForceModifBudget) {
			this.destStory.budget += deltaBudget;
			// this.destStory.remainingEXT += deltaBudget;
		}

		this.dialogRef.close({srcStory: this.srcStory, destStory: this.destStory});
	}

	private dataSelectStory(filter: string): LotDataSourceContent[] {
		const filterValue: string = filter.toLowerCase();
		return this.stories.filter(value =>
			value.id !== this.srcStory.id
			&& (value.name.toLowerCase().includes(filterValue)
				|| value.jiraIntId.toLowerCase().indexOf(filterValue) === 0)
			&& ((this.newBudget !== null && this.newBudget !== undefined) ? this.hasEnoughRAE(value) : true)
		).sort(UtilsService.dynamicMultiSort('name'));
	}

	private updateKeyPlaceholderSelectStory(): void {
		this.keyPlaceholderSelectStory = 'STORY.BUDGET_REDISTRIBUTION.SELECTION';
		if (this.newBudget != null) {
			if (this.srcStory.budget > this.newBudget) {
				// dest story sera créditée
				this.keyPlaceholderSelectStory = 'STORY.BUDGET_REDISTRIBUTION.SELECTION_CREDIT';
			} else if (this.srcStory.budget < this.newBudget) {
				// dest story sera débitée
				this.keyPlaceholderSelectStory = 'STORY.BUDGET_REDISTRIBUTION.SELECTION_DEBIT';
			}
		}
	}

	private hasEnoughRAE(storyToEdit: LotDataSourceContent): boolean {
		let hasEnoughDay: boolean = false;

		if (this.srcStory.budget > this.newBudget || storyToEdit.remainingEXT >= Math.abs(this.srcStory.budget - this.newBudget)) {
			// On vérifie qu'il y a assez de RAE dans la story à ponctionner
			// Si srcStory.budget > newBudget alors on ajoute des heures à la story,
			// la RAE ne rentre donc plus en compte
			hasEnoughDay = true;
		}

		return hasEnoughDay;
	}

	displaySelectedStory(story: IStoryDto): string {
		return story ? story.name + ' - ' + story.jiraIntId + ' - ' + story.remainingEXT : '';
	}
}
