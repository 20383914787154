import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChiffrageTypeEnum, IChiffrageDto} from '../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

@Component({
	selector: 'app-dialog-chiffrage-create',
	styleUrls: ['./dialog-chiffrage-create.component.scss'],
	templateUrl: './dialog-chiffrage-create.component.html'
})
export class DialogChiffrageCreateComponent {

	oldChiffrageType: ChiffrageTypeEnum;
	newChiffrageType: ChiffrageTypeEnum;
	chiffrageTypeEnum: string[] = Object.keys(ChiffrageTypeEnum);

	@ViewChild('txtTjm') txtTjm: ElementRef;

	constructor(public dialogRef: MatDialogRef<DialogChiffrageCreateComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { oldType: ChiffrageTypeEnum }) {
		this.oldChiffrageType = data.oldType;
		this.newChiffrageType = data.oldType;
	}

	onYesClick(): void {
		if ((this.newChiffrageType)) {
			this.dialogRef.close(this.newChiffrageType);
		} else {
			return;
		}
	}

}
