import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StateItemNodeEnum} from '../../../chiffrage/chiffrage.component';
import {IRepartitionDto} from '../../../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {IChargeAnnexeDto} from '../../../../../../core/business/service/avant-vente/charge-annexe/charge-annexe.dto';
import {ChiffrageGlobalItemFlatNode} from '../../chiffrage-global.component';
import {
	ISousRepartitionDto
} from '../../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {IChiffrageDto} from '../../../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';

@Component({
	selector: 'app-chiffrage-global-annexe',
	templateUrl: './chiffrage-global-annexe.component.html',
	styleUrls: ['./chiffrage-global-annexe.component.scss'],
})
export class ChiffrageGlobalAnnexeComponent implements OnInit {

	@Input() chargeAnnexe: IChargeAnnexeDto;
	@Input() chiffrage: IChiffrageDto;
	@Input() lastChiffrage: IChiffrageDto;
	@Input() node: ChiffrageGlobalItemFlatNode;
	@Input() stateItemNode: StateItemNodeEnum;
	@Input() repartitions: IRepartitionDto[];

	@Output() saveNewChargeAnnexeForm: EventEmitter<ChiffrageGlobalItemFlatNode> = new EventEmitter<ChiffrageGlobalItemFlatNode>();
	@Output() clearNewChargeAnnexeForm: EventEmitter<ChiffrageGlobalItemFlatNode> = new EventEmitter<ChiffrageGlobalItemFlatNode>();
	@Output() removeChargeAnnexe: EventEmitter<ChiffrageGlobalItemFlatNode> = new EventEmitter<ChiffrageGlobalItemFlatNode>();

	constructor() {
	}

	ngOnInit(): void {
		if (!this.chargeAnnexe) {
			this.chargeAnnexe = {} as IChargeAnnexeDto;

			if (this.stateItemNode === StateItemNodeEnum.EDIT) {
				this.chargeAnnexe.sousRepartitions = [];
				for (const repartition of this.repartitions) {
					this.chargeAnnexe.sousRepartitions.push({
						repartitionId: repartition.id
					} as ISousRepartitionDto);
				}
			}
		}
	}

	saveFormChargeAnnexe(): void {
		if (!this.chargeAnnexe.titre?.length) {
			return;
		}
		if (this.chargeAnnexe.charge) {
			this.chargeAnnexe.charge = Number(this.chargeAnnexe.charge);
			this.chargeAnnexe.sousRepartitions.forEach((sr) => {
				if (this.chiffrage.type !== 'MINMAX' && !sr.charge) {
					sr.charge = 0;
				} else if (this.chiffrage.type === 'MINMAX' && !sr.chargeMin) {
					sr.chargeMin = 0;
				} else if (this.chiffrage.type === 'MINMAX' && !sr.chargeMax) {
					sr.chargeMax = 0;
				}
				sr.charge = Math.ceil(sr.charge * 8) / 8;
				sr.chargeMin = Math.ceil(sr.chargeMin * 8) / 8;
				sr.chargeMax = Math.ceil(sr.chargeMax * 8) / 8;
			});
		}
		if (this.chiffrage.type === 'REEL') {
			this.chargeAnnexe.charge = this.chargeAnnexe.sousRepartitions.reduce((acc, sr) => acc + sr.charge, 0);
		}
		if (!this.chargeAnnexe.charge) {
			this.chargeAnnexe.charge = 0;
		}
		this.node.item = this.chargeAnnexe;
		this.saveNewChargeAnnexeForm.emit(this.node);
		this.changeStateItemNode();
	}

	clearFormChargeAnnexe(): void {
		if (this.chargeAnnexe.id) { // update
			this.changeStateItemNode();
		} else {
			this.clearNewChargeAnnexeForm.emit(this.node);
		}
	}

	deleteChargeAnnexe(): void {
		this.removeChargeAnnexe.emit(this.node);
	}

	changeStateItemNode(): void {
		let newStateItemNode: StateItemNodeEnum = null;
		if (this.stateItemNode) {
			if (this.stateItemNode === StateItemNodeEnum.EDIT) {
				newStateItemNode = StateItemNodeEnum.READ;
			} else if (this.stateItemNode === StateItemNodeEnum.READ) {
				newStateItemNode = StateItemNodeEnum.EDIT;
			}
		} else {
			newStateItemNode = StateItemNodeEnum.EDIT;
		}

		// Lance l'update du component
		this.stateItemNode = newStateItemNode;
	}
}
