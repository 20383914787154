import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription} from 'rxjs';
import {
	IDisplayMatrix,
	IReportDetail,
	IStoryReportDetail,
	ISubtaskReportDetail,
	SPHeader,
	SPHeaderMonth,
	SPHeaderPeriod,
	SPHeaderUser,
	SPRAEExtUpdate,
	SPRow,
	SPRowCell,
	SPRowObject,
	SPTable,
} from './dto/suivi-projet.dto';
import {UtilsService} from '../../utils/service/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogValidationComponent} from '../../utils/components/dialog-validation/dialog-validation.component';
import {SpinnerService} from '../../core/service/spinner.service';
import {environment} from '../../../environments/environment';
import {ConfigurationService} from '../../core/business/service/configuration/configuration.service';
import {DialogLotSelectionSuiviProjetComponent} from './lot-selection-suivi-projet/dialog-lot-selection-suivi-projet.component';
import {SuiviProjetExport} from './export-excel/suivi-projet-export';
import ExcelService from '../../core/service/excel/excel.service';
import {IConfigurationDto} from '../../core/business/service/configuration/configuration.dto';
import {ReportService} from '../../core/business/service/report/report.service';
import {ProjectService} from '../../core/business/service/project/project.service';
import {IProjectDto} from '../../core/business/service/project/project.dto';
import {IStoryDto, StoryTypeEnum} from '../../core/business/service/story/story.dto';
import {IPeriodReportDto, IPeriodReportDtos} from '../../core/business/service/report/period-report/period-report.dto';
import {ISubtaskDto} from '../../core/business/service/subtask/subtask.dto';
import {ContractStatusEnum, ILotDto} from '../../core/business/service/lot/lot.dto';
import {IStoryReportDto} from '../../core/business/service/report/story-report/story-report.dto';
import {ISubtaskReportDto} from '../../core/business/service/report/subtask-report/subtask-report.dto';
import {IEpicDto} from '../../core/business/service/epic/epic.dto';
import {NumberInputDirective} from '../../utils/components/number-input/number-input.directive';
import {INumberAlertsDto, ProjectAlertTypeEnum} from '../../core/business/service/project-alert/project-alert.dto';
import {ProjectAlertService} from '../../core/business/service/project-alert/project-alert.service';
import {
	DialogProjectAlertDisplayComponent
} from '../../project-alert/component/dialog-project-alert-display/dialog-project-alert-display.component';
import {LotService} from '../../core/business/service/lot/lot.service';
import {Title} from '@angular/platform-browser';
import {ThemeEnum} from '../../theme/themes';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DebugService} from '../../utils/service/debug.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {KeycloakService} from 'keycloak-angular';
import {UserInfo} from '../../security/util/user-info';
import {AdminRolePipe, ClientRolePipe} from '../../security/pipe/role.pipe';
import {IUserBaseDto} from "../../core/business/service/user-base/user-base.dto";
import {UserBaseService} from "../../core/business/service/user-base/user-base.service";

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS: object = {
	parse: {
		dateInput: 'MM/YYYY',
	},
	display: {
		dateInput: 'MMM YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'MMM YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

// Différents mode d’affichage des données
export const DISPLAY_MATRIX: IDisplayMatrix[] = [
	{
		name: 'client',
		displayValues: ['raeext', 'produit', 'emptyRows', 'validatedAdminCols'],
		floatingTable: false,
	},
	{
		name: 'semaine',
		displayValues: ['conso', 'individualImputations', 'raeext', 'produit', 'diff', 'diffTotal',
			'emptyRows', 'subtaskRows', 'keepOneValidatedWeek'],
		floatingTable: false,
	},
	{
		name: 'cp',
		displayValues: ['conso', 'individualImputations', 'raeext', 'produit', 'diff', 'diffTotal',
			'emptyRows', 'subtaskRows', 'validatedAdminCols'],
		floatingTable: false,
	},
	{
		name: 'prod',
		displayValues: ['produit', 'diff', 'diffTotal', 'emptyRows', 'total-period', 'validatedAdminCols'],
		floatingTable: false,
	},
];

@Component({
	selector: 'app-suivi-projet',
	templateUrl: './suivi-projet.component.html',
	styleUrls: ['./suivi-projet.component.scss'],
	providers: [
		// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
		// application's root module. We provide it at the component level here, due to limitations of
		// our example generation script.
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuiviProjetComponent extends UserInfo implements OnInit, OnDestroy {
	// Header view values
	periodStart: moment.Moment;
	periodEnd: moment.Moment;
	limitStart: moment.Moment;
	limitEnd: moment.Moment;
	arrowPeriodTimeout: any;
	datesBeforeCheck: moment.Moment[] = [];
	filterTimeout: any;
	displayMatrix: IDisplayMatrix[] = DISPLAY_MATRIX;
	displayMatrixValue: IDisplayMatrix | string;
	displayValues: Map<string, string[]> = new Map();
	displayValuesFlat: string[] = [];
	jiraKey: string;
	lotsSelector: SPRow[] = [];
	defaultVisibleLots: SPRow[] = [];
	/**
	 * Booléen pour savoir si l'utilisateur a défini manuellement une sélection de lots à afficher.
	 */
	selectionAutomatiqueLotsVisibles: boolean = true;
	periodReportToValidate: IPeriodReportDtos;
	// Warning section values
	warnings: string[] = [];
	// Table values
	tableDataSubscription: Subscription;
	tableData: SPTable;
	storiesMap: Map<number, IStoryDto> = new Map();
	subtaskMap: Map<number, ISubtaskDto> = new Map();
	// Floating table values
	floatingTableData: SPTable;
	showFloatingTable: boolean = false;
	@ViewChild('headerElementsContainer') headerElementsContainer: ElementRef<HTMLDivElement>;
	@ViewChild('floatingTableBG') floatingTableBGElem: ElementRef;
	@ViewChild('floatingTable') floatingTableElem: ElementRef;
	configurationUrlJira: IConfigurationDto;
	// Save values
	raeExtUpdated: SPRAEExtUpdate[] = [];
	// Input values
	productionMode: boolean = false;
	document: Document = document;
	projectAlert: INumberAlertsDto[];
	userIsAdminOrCP: boolean = false;
	allLots: ILotDto[] = [];

	constructor(private _router: Router,
				private _activeRoute: ActivatedRoute,
				private _reportService: ReportService,
				private _userService: UserBaseService,
				private _projectService: ProjectService,
				private _changeDetectorRef: ChangeDetectorRef,
				private _configurationService: ConfigurationService,
				private _translator: TranslateService,
				private _snackBar: MatSnackBar,
				private _dialog: MatDialog,
				private _renderer: Renderer2,
				private _spinnerService: SpinnerService,
				private _projectAlertService: ProjectAlertService,
				private _lotService: LotService,
				public dialog: MatDialog,
				public override keycloak: KeycloakService,
				private _debugService: DebugService,
				private _titleService: Title) {
		super(keycloak);

		this.displayValues.set('view', ['emptyRows', 'subtaskRows', 'zeros', 'individualImputations', 'diffTotal',
			'validatedAdminCols', 'keepOneValidatedWeek']);
		this.displayValues.set('col', ['conso', 'raeext', 'produit', 'diff', 'total-period']);

		if (new ClientRolePipe().transform(this.role)) {
			this.displayMatrixValue = this.displayMatrix.find(value => value.name === 'client');
		} else {
			// Par défaut => Vision CP (index 2 de la liste ou celui avec name = CP)
			this.displayMatrixValue = this.displayMatrix.find(value => value.name === 'cp');
		}

		this.displayValuesFlat = this.displayMatrixValue.displayValues;
		this.showFloatingTable = this.displayMatrixValue.floatingTable;
	}

	// Table values
	private _project: BehaviorSubject<IProjectDto> = new BehaviorSubject<IProjectDto>(null);

	get project(): IProjectDto {
		return this._project.getValue();
	}

	set project(value: IProjectDto) {
		this._project.next(value);
	}

	private _users: BehaviorSubject<IUserBaseDto[]> = new BehaviorSubject(null);

	get users(): IUserBaseDto[] {
		return this._users.getValue();
	}

	set users(value: IUserBaseDto[]) {
		this._users.next(value);
	}

	private _periodReports: BehaviorSubject<IPeriodReportDto[]> = new BehaviorSubject<IPeriodReportDto[]>(null);

	get periodReports(): IPeriodReportDto[] {
		return this._periodReports.getValue();
	}

	set periodReports(value: IPeriodReportDto[]) {
		this._periodReports.next(value);
	}

	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Suivi > Détails');

		this._spinnerService.enableLoading();
		this._configurationService.findByKey('JIRA_URL_BROWSER').then(value => this.configurationUrlJira = value);

		const queryParams: any = this._activeRoute.snapshot.queryParams;

		this.periodStart = queryParams['start'] ? moment(queryParams['start']) : moment().add(-1, 'month').startOf('month');
		this.periodEnd = queryParams['end'] ? moment(queryParams['end']) : moment().endOf('month');

		this.jiraKey = this._activeRoute.snapshot.paramMap.get('jirakey');

		// Récupération des périodes pour les infos dans la table
		this._reportService.findPeriodForProject(
			this.jiraKey,
			this.periodStart.toISOString(true),
			this.periodEnd.toISOString(true)).then(result => {
			this.periodReportToValidate = result;
			this.periodReports = result.reports;
		});

		// Récupération des users pour le header de la table
		this._userService.findActiveUsersForYear(this.periodStart.year()).then(result => {
			if (result) {
				this.users = result;
			}
		});

		this._reportService.findFirstPeriod(this.jiraKey).then(result => {
			this.limitStart = moment(result.period.dateBegin).startOf('month');
		});

		this._getProject().then(() => {
			this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Suivi > ' + this.project.name);
			this.tableDataSubscription = combineLatest([this._periodReports, this._users, this._project])
				.pipe().subscribe(([periodReports, users, project]) => {
					if (!periodReports || !users || !users.length || !project) {
						return;
					}
					if (this._activeRoute.snapshot.queryParams['displayParameters'] || this.project.displayParameters) {
						this.displayValuesFlat = (this._activeRoute.snapshot.queryParams['displayParameters'])
							? this._activeRoute.snapshot.queryParams['displayParameters']
							: this.project.displayParameters;
						this._checkIfFilterLookLikeADisplayMatrix();
						this.applyFilter(0);
					} else {
						this._initTable();
					}
					setTimeout(() => {
						this._changeDetectorRef.detectChanges();
						this._spinnerService.disableLoading();
					});
				});
		});
		this.limitEnd = moment().endOf('month');
	}

	ngOnDestroy(): void {
		this._spinnerService.disableLoading();
		this.tableDataSubscription?.unsubscribe();
	}

	updateURLParams(displayParameters: string[]): void {
		let queryParams: Params;

		if (displayParameters) {
			queryParams = {
				start: this.periodStart.startOf('month').format('YYYY-MM-DD'),
				end: this.periodEnd.format('YYYY-MM-DD'),
				displayParameters,
			};
		} else {
			queryParams = {
				start: this.periodStart.startOf('month').format('YYYY-MM-DD'),
				end: this.periodEnd.format('YYYY-MM-DD'),
			};
		}

		const queryParamString: string = this._router.serializeUrl(this._router.createUrlTree([], {queryParams}));
		history.pushState({}, '', window.location.origin + queryParamString);
	}

	getDisplayParametersInURL(): string[] {
		const searchQuery: string = window.location.search;
		const parameters: string[] = searchQuery.match(new RegExp(/displayParameters=[a-zA-Z0-9]*/, 'gm'));
		if (!parameters) {
			return;
		}
		return parameters.map((p) => p.split('=')[1]);
	}

	// region filters / header bar
	onMonthSelected(event: moment.Moment, datePicker: MatDatepicker<any>, when: 'start' | 'end'): void {
		datePicker.close();
		const oldStartYear: number = this.periodStart.year();
		switch (when) {
			case 'start':
				this.periodStart = event;
				break;
			case 'end':
				this.periodEnd = event;
				break;
		}
		const newStartYear: number = this.periodStart.year();
		this.applyDateChange(newStartYear != oldStartYear);
		this.updateURLParams(this._activeRoute.snapshot.queryParams['displayParameters']);
	}

	onMonthButtonClick(backward: boolean, toLimit: boolean): void {
		const diffMonth: number = this.periodEnd.diff(this.periodStart, 'month');
		const oldStartYear: number = this.periodStart.year();
		if (backward) {
			if (toLimit) {
				this.periodStart = moment(this.limitStart);
			} else {
				this.periodStart = moment(this.periodStart.add(-diffMonth, 'month'));
			}
			if (this.periodStart.isBefore(this.limitStart)) {
				this.periodStart = moment(this.limitStart);
			}
			if (diffMonth === 0 && !toLimit) {
				this.periodStart = moment(this.periodStart.add(-1, 'month'));
			}
			this.periodEnd = moment(this.periodStart).add(diffMonth, 'month');
		} else {
			if (toLimit) {
				this.periodEnd = moment(this.limitEnd);
			} else {
				this.periodEnd = moment(this.periodEnd.add(diffMonth, 'month'));
			}
			if (this.periodEnd.isAfter(this.limitEnd)) {
				this.periodEnd = moment(this.limitEnd);
			}
			if (diffMonth === 0 && !toLimit) {
				this.periodEnd = moment(this.periodEnd.add(1, 'month'));
			}
			this.periodStart = moment(this.periodEnd).add(-diffMonth, 'month');
		}
		this.periodStart = this.periodStart.startOf('month');
		this.periodEnd = this.periodEnd.endOf('month');

		const newStartYear: number = this.periodStart.year();

		// On charge pas de suite au cas ou on veut faire plusieurs clique
		clearTimeout(this.arrowPeriodTimeout);
		this.arrowPeriodTimeout = setTimeout(() => {
			this.applyDateChange(newStartYear != oldStartYear);
			this.updateURLParams(this.getDisplayParametersInURL());
		}, 500);
	}

	async changeViewMatrix(event: MatSelectChange): Promise<void> {
		// Si on a choisi un display matrix, sinon on fait rien
		this.displayMatrixValue = event.value;
		if (event.value !== 'custom') {
			const values: string[] = (event.value as IDisplayMatrix).displayValues;
			this._projectService.modifyDisplayParameters(this.project.id, values);
			this.displayValuesFlat = values;
			await this.applyFilter();
			this.showHideFloatingTable((event.value as IDisplayMatrix).floatingTable);
			this.updateURLParams(values);
		}
	}

	async changeViewSelection(event: MatSelectChange): Promise<void> {
		let viewOnly: boolean = false;
		if ((this.displayValuesFlat.indexOf('zeros') !== -1 && event.value.indexOf('zeros') === -1)
			|| (this.displayValuesFlat.indexOf('zeros') === -1 && event.value.indexOf('zeros') !== -1)) {
			viewOnly = true;
		}
		this._projectService.modifyDisplayParameters(this.project.id, event.value);
		this.displayValuesFlat = event.value;

		// Check dans la liste pour mettre peronnalisé ou la valeur correspondante dans la liste avec les infos mises
		this._checkIfFilterLookLikeADisplayMatrix();
		if (!viewOnly) {
			await this.applyFilter();
		}
		this.updateURLParams(event.value);
	}

	showHideFloatingTable(checked: boolean): void {
		this.showFloatingTable = checked;
		this._checkIfFilterLookLikeADisplayMatrix();
		if (this.showFloatingTable) {
			const budgetHeader: HTMLElement = document.getElementById('budget-col');

			let left: number = (budgetHeader.offsetLeft + budgetHeader.offsetWidth) - this.floatingTableElem.nativeElement.offsetWidth;
			const lastValidatedCP: SPHeaderPeriod = this.tableData.header.periods.find(value => value.lastValidatedCP);
			if (lastValidatedCP) {
				const periodHeader: HTMLElement = document.getElementById('period-' + lastValidatedCP.index);
				left = (periodHeader.offsetLeft + periodHeader.offsetWidth) - this.floatingTableElem.nativeElement.offsetWidth;
			}

			this._renderer.setStyle(this.floatingTableBGElem.nativeElement, 'left', left + 'px');
			this._renderer.setStyle(this.floatingTableElem.nativeElement, 'left', left + 'px');
		} else {
			this._renderer.removeStyle(this.floatingTableBGElem.nativeElement, 'left');
			this._renderer.removeStyle(this.floatingTableElem.nativeElement, 'left');
		}
		this._changeDetectorRef.detectChanges();
	}

	applyFilter(timeout: number = 500): Promise<void> {
		return new Promise<void>(resolve => {
			clearTimeout(this.filterTimeout);
			this.filterTimeout = setTimeout(() => {
				this._spinnerService.enableLoading();
				this._initTable();
				this._changeDetectorRef.detectChanges();
				this._spinnerService.disableLoading();
				resolve();
			}, timeout);
		});
	}

	applyDateChange(yearChange: boolean): void {
		if (!this.periodStart || !this.periodEnd) {
			return;
		}

		this._spinnerService.enableLoading();
		this._reportService.findPeriodForProject(
			this.jiraKey,
			this.periodStart.startOf('month').toISOString(true),
			this.periodEnd.endOf('month').toISOString(true)).then(async result => {
			const lots: number[] = [];
			for (const rep of result.reports) {
				for (const storyRep of rep.storyReports) {
					if ((storyRep.productionCalc !== 0 || storyRep.spentSum !== 0)) {
						if (!lots.find(l => l === storyRep.lotId)) {
							lots.push(storyRep.lotId);
							break;
						}
					}
				}
			}
			const lotsToGet: number[] = [];
			for (const lot of lots) {
				if (!this.project.lots.find(l => l.id === lot)) {
					lotsToGet.push(lot);
				}
			}
			if (lotsToGet && lotsToGet.length !== 0) {
				await this.loadMoreToProject(lotsToGet);
			}
			// Le fait de mettre à jour period reports va trigger le table data subscription
			// parce que period reports change et le combine latest le voit et donc agit
			this.periodReportToValidate = result;
			this.periodReports = result.reports;
		});
		if(yearChange){
			// Récupération des users pour le header de la table
			this._userService.findActiveUsersForYear(this.periodStart.year()).then(result => {
				if (result) {
					this.users = result;
				}
			});
		}
	}

	async loadMoreToProject(ids: number[], refresh: boolean = false): Promise<void> {
		if (ids && ids.length > 0) {
			await this._lotService.getLotByIdsList(ids).then(async res => {
				const lotsToAdd: ILotDto[] = [];
				res.forEach(newLot => {
					if (!this.project.lots.find(l => l.id === newLot.id)) {
						lotsToAdd.push(newLot);
					}
				});
				this.project.lots = [...this.project.lots, ...lotsToAdd];
				await this._updateProject();
				if (refresh) {
					this._initTable();
				}
			});
		}
	}

	async synchronizeProject(smart: boolean = true, showSnackBar: boolean, periodId?: number): Promise<void> {
		await this._reportService.synchronizeProject(this.jiraKey, smart, periodId).then(async () => {
			this.project = null;
			this.users = await this._userService.findActiveUsersForYear(this.periodStart.year());
			this.periodReportToValidate = await this._reportService.findPeriodForProject(
				this.jiraKey,
				this.periodStart.toISOString(true),
				this.periodEnd.toISOString(true));
			this.periodReports = this.periodReportToValidate.reports;
			await this._getProject();
			if (showSnackBar) {
				this._snackBar.open(this._translator.instant('SUIVI.SYNCED'));
			}
		});
	}

	async sync(showSnackbar: boolean = true, smart: boolean = true, periodId?: number): Promise<void> {
		this._spinnerService.enableLoading();
		if (showSnackbar) {
			await this.synchronizeProject(smart, showSnackbar, periodId).catch((err) => {
				this._snackBar.open('HTTP 500 : ' + err.error.message, '', {
					panelClass: 'error',
					duration: 15000,
				});
				this._spinnerService.disableLoading();
			});
		} else {
			await this.synchronizeProject(smart, showSnackbar, periodId);
		}
	}

	openLotSelectorDialog(): void {
		this.dialog.open(DialogLotSelectionSuiviProjetComponent, {
			autoFocus: false,
			width: '500px',
			data: {
				allLots: this.allLots,
				lots: this.lotsSelector,
				defaultVisibleLots: this.defaultVisibleLots,
				selectionAutomatiqueLotsVisibles: this.selectionAutomatiqueLotsVisibles,
			},
		}).afterClosed().subscribe(result => {
			if (!result) {
				return;
			}
			this._spinnerService.enableLoading();
			this.selectionAutomatiqueLotsVisibles = result.selectionAutomatiqueLotsVisibles;
			if (result.toLoad && result.toLoad.length > 0) {
				this.selectionAutomatiqueLotsVisibles = false;
				this.loadMoreToProject(result.toLoad, true).then(() => {
					const idsLots: string[] = [];
					result.toLoad.forEach(id => {
						idsLots.push('lot-' + id);
						const idx: number = this.allLots.findIndex(l => l.id === id);
						this.allLots.splice(idx, 1);
					});
					const added: SPRow[] = this.lotsSelector.filter(l => idsLots.includes(l.id));
					added.forEach(it => it.visible = true);
					this._applyLotVisibilityChange([...result.selection, ...added]);
					this._spinnerService.disableLoading();
				});
			} else {
				this._applyLotVisibilityChange(result.selection);
				this._spinnerService.disableLoading();
			}
		});
	}

	async exportSuivi(): Promise<void> {
		this._spinnerService.enableLoading();

		const displayProduitPeriod: boolean = this.displayValuesFlat.includes('total-period');
		const beginDate: Date = this._findFirstPeriodWithProd(this.tableData.body);

		const workbook: any = SuiviProjetExport.generateExport(this.tableData, beginDate, this.project, displayProduitPeriod);
		ExcelService.saveAsExcelFile(workbook, this.project.client ? this.project.client.name : this.project.name);

		this.project.lastExportDate = new Date();
		const projectToUpdate: IProjectDto = Object.assign({}, this.project);
		delete projectToUpdate.lots;
		delete projectToUpdate.epics;
		await this._projectService.modifyProject(this.project.id, projectToUpdate);

		this._spinnerService.disableLoading();
	}

	async save(showSnackbar: boolean = true): Promise<void> {
		if (!this.raeExtUpdated.length) {
			return;
		}
		return await this._reportService.saveRaeExts(this.raeExtUpdated, this.jiraKey).then(() => {
			this.raeExtUpdated = [];
			// this._spinnerService.disableLoading();
			if (showSnackbar) {
				this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
					panelClass: 'success',
				});
			}
		});
	}

	// endregion

	// region suivi
	hasValue(value: string): boolean {
		return this.displayValuesFlat.includes(value);
	}

	getCellInfo(cell: any): void {
		if (this._debugService.debugMode) {
			console.log(cell);
		}
	}

	reinitRAE(period: SPHeaderPeriod): void {
		// Trouver l'index de la col de RAE
		const indexOfRAEExt: number = period.users.find(value => value.type === 'raeext').index;
		let lastRaeExtValue: number;

		for (const row of this.tableData.body) {
			if (period.headerPeriodBefore) {
				const indexOfLastRAEExt: number = period.headerPeriodBefore.users.find(value => value.type === 'raeext').index;
				lastRaeExtValue = row.values[indexOfLastRAEExt].value;
			} else if (this.periodReportToValidate.lastWeekPeriod) {
				const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports
					.find(sr => sr.storyId === row.rowDefinition.id);
				if (storyReport) {
					lastRaeExtValue = storyReport.remainingEXT;
				} else {
					continue;
				}
			}

			const actualRaeExt: SPRowCell = row.values[indexOfRAEExt];
			// Si row = story
			if (row.type === 'story') {
				// Valeur = valeur original
				actualRaeExt.value = actualRaeExt.originalValue;
				this.onInputChange(actualRaeExt.value, actualRaeExt);
			}
		}
	}

	async validateAllPeriodBefore(period: SPHeaderPeriod, onlyCP: boolean = true): Promise<void> {
		this._spinnerService.enableLoading();
		this._dialog.open(DialogValidationComponent, {
			data: {
				title: this._translator.instant('APP.CONFIRMATION'),
				message: this._translator.instant('SUIVI.TABLE.BUTTON.VALIDATION_BEFORE', {valType: onlyCP ? 'CP' : 'CP + ADMIN'}),
				showYesButton: true,
				noText: this._translator.instant('APP.NO'),
				yesText: this._translator.instant('APP.YES'),
				warn: true,
			},
		}).afterClosed().subscribe(async value => {
			if (value) {
				await this._reportService.validateToPeriod(
					this._project.getValue().jiraIntId,
					onlyCP,
					period.periodReport.id
				).then(async res => {
					for (let i: number = 0; i < res.length; i++) {
						const update: SPHeaderPeriod = this.tableData.header.periods.find(
							a => a.periodReport.id === res[i]
						);
						if (update) {
							const last: boolean = i === res.length - 1;
							update.lastValidatedCP = last;
							update.periodReport.validatedCP = true;
							if (!onlyCP) {
								update.lastValidatedAdmin = last;
								update.periodReport.validatedAdmin = true;
							}
							if (last) {
								await this._reportService.findNextPeriodToValidate(this._project.getValue().jiraIntId, onlyCP).then(async ret => {
									if (res) {
										this.periodReportToValidate.toValidateCP = ret.id;
										this.periodReportToValidate.toDevalidateCP = res[i];
										if (!onlyCP) {
											this.periodReportToValidate.toValidateAdmin = ret.id;
											this.periodReportToValidate.toDevalidateAdmin = res[i];
										}
									}
								});
							}
						}
					}
					this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
						panelClass: 'success',
					});
				});
			}
			this._spinnerService.disableLoading();
		});
	}

	async validatePeriodCp(period: SPHeaderPeriod, adminAlso: boolean = false): Promise<void> {
		this.raeExtUpdated = [];
		// Vérification que tous les RAE soit mis
		// Trouver l'index de la col de RAE
		const indexOfRAEExt: number = period.users.find(value => value.type === 'raeext').index;
		let hasNull: boolean = false;
		let hasBadImputation: boolean = false;
		const badValues: number[] = [];
		// Itérer sur chaque ligne pour vérifier si la valeur n'est pas null
		for (let i: number = 0; i < this.tableData.body.length; i++) {
			const row: SPRow = this.tableData.body[i];
			// Il faut que ce soit une story pour que ce soit intéressant ET que le lot ne soit pas en DRAFT ou FINISH
			if (row.type !== 'story'
				|| (row.lotParent && (row.lotParent.rowDefinition as ILotDto).contract === ContractStatusEnum.DRAFT)
				|| (row.lotParent && (row.lotParent.rowDefinition as ILotDto).contract === ContractStatusEnum.FINISH)) {
				continue;
			}
			// On ignore la vérif pour les story contingence et INT
			if (row.type === 'story' && ((row.rowDefinition as IStoryDto).type === StoryTypeEnum.CONTINGENCY
				|| (row.rowDefinition as IStoryDto).type === StoryTypeEnum.INT)) {
				continue;
			}

			const element: HTMLElement = document.getElementById('raeext-' + i + '-' + indexOfRAEExt);
			if (element) {
				this._renderer.removeClass(element, 'error');
			}
			// Si valeur null, on met hasNull à vrai
			if (row.values[indexOfRAEExt].value == null) { // == null va check null et undefined
				hasNull = true;
				badValues.push(i);
			}
			if (row.values[indexOfRAEExt].value % 0.125 !== 0) {
				hasBadImputation = true;
				badValues.push(i);
			}
			// On ajoute à la liste des rae à update
			this.raeExtUpdated.push({
				periodReportId: row.values[indexOfRAEExt].period.periodReport.id,
				storyId: row.rowDefinition.id,
				value: row.values[indexOfRAEExt].value,
			});
		}
		// Si une ligne contient null ou undefined ou n'est pas un multiple de 0.125, on affiche une dialogue
		if (hasNull || hasBadImputation) {
			for (const rowIndex of badValues) {
				const element: HTMLElement = document.getElementById('raeext-' + rowIndex + '-' + indexOfRAEExt);
				if (element) {
					this._renderer.addClass(element, 'error');

				}
			}
			this._dialog.open(DialogValidationComponent, {
				data: {
					title: this._translator.instant('APP.WARNING'),
					message: this._translator.instant('SUIVI.TABLE.BUTTON.ERROR'),
					showYesButton: false,
					noText: this._translator.instant('APP.CLOSE'),
				},
			});
		} else {
			// Sinon on affiche une modal pour valider la période
			this._spinnerService.enableLoading();
			this._dialog.open(DialogValidationComponent, {
				data: {
					title: this._translator.instant('APP.CONFIRMATION'),
					message: this._translator.instant('SUIVI.TABLE.BUTTON.VALIDATION'),
					showYesButton: true,
					noText: this._translator.instant('APP.NO'),
					yesText: this._translator.instant('APP.YES'),
				},
			}).afterClosed().subscribe(async value => {
				if (value) {
					period.periodReport.validatedCP = true;
					// Elle devient la dernière validé CP et la précédente ne l’est plus
					period.lastValidatedCP = true;
					if (period.headerPeriodBefore) {
						period.headerPeriodBefore.lastValidatedCP = false;
					}
					if (period.headerPeriodAfter) {
						// La période à valider est la suivante
						this.periodReportToValidate.toValidateCP = period.headerPeriodAfter.periodReport.id;
					} else {
						this.periodReportToValidate.toValidateCP = null;
					}
					// La période à dé-valider est l'actuel
					this.periodReportToValidate.toDevalidateCP = period.periodReport.id;

					this.raeExtUpdated = [];
					for (const row of this.tableData.body) {
						if (row.type !== 'story' || (row.rowDefinition as IStoryDto).lot.contract === ContractStatusEnum.FINISH) {
							continue;
						}
						if ((row.rowDefinition as IStoryDto).type !== 'CONTINGENCY') {
							this.raeExtUpdated.push({
								periodReportId: period.periodReport.id,
								storyId: row.rowDefinition.id,
								value: row.values[row.values.findIndex(value1 =>
									value1.type === 'raeext'
									&& value1.period.periodReport.id === period.periodReport.id)
									].value,
							});
						} else {
							this.raeExtUpdated.push({
								periodReportId: period.periodReport.id,
								storyId: row.rowDefinition.id,
								value: row.budget,
							});
						}
					}
					await this.save(false);

					period.periodReport = await this._reportService.validatePeriodForCP(period.periodReport.id);

					// Calculer les rae ext pour la période suivante
					this._fillRaeExtNextPeriod(period);

					let adminOk: boolean = true;

					// Si la dernière synchro date de plus 3 minutes, on re-synchronise
					if (this._getDateDifferenceInSeconds(new Date(this.project.lastTempoSyncDate), new Date()) > 180) {
						await this.sync(false, true, period.period.id).catch((err) => {
							this._snackBar.open('HTTP 500 : ' + err.error.message, '', {
								panelClass: 'error',
								duration: 15000,
							});
						});
					} else {
						this.periodReportToValidate = await this._reportService.findPeriodForProject(
							this.jiraKey,
							this.periodStart.toISOString(true),
							this.periodEnd.toISOString(true));
						this.periodReports = this.periodReportToValidate.reports;
					}
					if (adminAlso) {
						adminOk = await this._executeAdminValidation(period);
					}
					if (adminOk) {
						this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
							panelClass: 'success',
						});
						this._spinnerService.disableLoading();
					}

					if (this.showFloatingTable) {
						// il faut recalculer la position, on vient de valider une semaine.
						this.showHideFloatingTable(true);
					}
				} else {
					this._spinnerService.disableLoading();
				}
				this._changeDetectorRef.detectChanges();
			});
		}
	}

	unvalidatePeriodCP(period: SPHeaderPeriod, adminAlso: boolean = false): void {
		this._spinnerService.enableLoading();
		this._dialog.open(DialogValidationComponent, {
			data: {
				title: this._translator.instant('APP.CONFIRMATION'),
				message: this._translator.instant('SUIVI.TABLE.BUTTON.UNVALIDATION'),
				showYesButton: true,
				noText: this._translator.instant('APP.NO'),
				yesText: this._translator.instant('APP.YES'),
			},
		}).afterClosed().subscribe(async value => {
			if (value) {
				period.periodReport.validatedCP = false;
				// Elle n’est plus la dernière validé CP et la précédente le devient
				period.lastValidatedCP = false;
				if (period.headerPeriodBefore) {
					period.headerPeriodBefore.lastValidatedCP = true;
				}
				// La période à valider est l'actuel
				this.periodReportToValidate.toValidateCP = period.periodReport.id;
				// La période à dé-valider est la précédente
				if (period.headerPeriodBefore) {
					this.periodReportToValidate.toDevalidateCP = period.headerPeriodBefore.periodReport.id;
				}
				await this._reportService.unvalidatePeriodForCP(period.periodReport.id);

				if (adminAlso) {
					await this._executeAdminUnvalidation(period);
				}

				// Enlever l’état "alert" des cellules
				period.periodReport.hasAlert = false;
				for (const row of this.tableData.body) {
					const cellsOfPeriod: SPRowCell[] = row.values.filter(cell =>
						cell.period
						&& cell.period.periodReport
						&& cell.period.periodReport.id === period.periodReport.id
						&& cell.type === 'user'
						&& cell.object
						&& cell.object.hasAlert);
					cellsOfPeriod.forEach(cell => cell.object.hasAlert = false);
				}

				this.periodReportToValidate.hasAlert = await this._reportService.findIfProjectHasAlerts(this.project.jiraIntId);

				await this._getProject();

				this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
					panelClass: 'success',
				});
			}
			this._spinnerService.disableLoading();
			this._changeDetectorRef.detectChanges();
		});
	}

	validatePeriodAdmin(period: SPHeaderPeriod): void {
		this._spinnerService.enableLoading();
		this._dialog.open(DialogValidationComponent, {
			data: {
				title: this._translator.instant('APP.CONFIRMATION'),
				message: this._translator.instant('SUIVI.TABLE.BUTTON.VALIDATION'),
				showYesButton: true,
				noText: this._translator.instant('APP.NO'),
				yesText: this._translator.instant('APP.YES'),
			},
		}).afterClosed().subscribe(async value => {
			if (value) {
				// Si la dernière synchro date de plus 3 minutes, on re-synchronise
				if (this._getDateDifferenceInSeconds(new Date(this.project.lastTempoSyncDate), new Date()) > 180) {
					await this.sync(false, true, period.period.id).catch((err) => {
						this._snackBar.open('HTTP 500 : ' + err.error.message, '', {
							panelClass: 'error',
							duration: 15000,
						});
					});
				} else {
					this.periodReportToValidate = await this._reportService.findPeriodForProject(
						this.jiraKey,
						this.periodStart.toISOString(true),
						this.periodEnd.toISOString(true));
					this.periodReports = this.periodReportToValidate.reports;
				}

				const adminOk: boolean = await this._executeAdminValidation(period);
				if (adminOk) {
					this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
						panelClass: 'success',
					});
				}
			}
			this._spinnerService.disableLoading();
			this._changeDetectorRef.detectChanges();
		});
	}

	unvalidatePeriodAdmin(period: SPHeaderPeriod): void {
		this._spinnerService.enableLoading();
		this._dialog.open(DialogValidationComponent, {
			data: {
				title: this._translator.instant('APP.CONFIRMATION'),
				message: this._translator.instant('SUIVI.TABLE.BUTTON.UNVALIDATION'),
				showYesButton: true,
				noText: this._translator.instant('APP.NO'),
				yesText: this._translator.instant('APP.YES'),
			},
		}).afterClosed().subscribe(async value => {
			if (value) {
				await this._executeAdminUnvalidation(period);

				this._snackBar.open(this._translator.instant('APP.SNACKBAR.SAVE'), '', {
					panelClass: 'success',
				});
			}
			this._spinnerService.disableLoading();
			this._changeDetectorRef.detectChanges();
		});
	}

	async ignorePeriodErrors(period: SPHeaderPeriod): Promise<void> {
		this._spinnerService.enableLoading();

		await this._reportService.removeAlerts(period.periodReport.id);
		await this.sync(false);

		this._spinnerService.disableLoading();
		this._changeDetectorRef.detectChanges();
	}

	// endregion

	reportConsoToRaeExtForAll(period: SPHeaderPeriod): void {
		const indexOfConso: number = period.users.find(value => value.type === 'conso').index;
		for (const row of this.tableData.body) {
			this.reportConsoToRaeExt(row, indexOfConso);
		}
	}

	reportConsoToRaeExt(row: SPRow, index: number): void {
		const cell: SPRowCell = row.values[index];

		if (row.type === 'story' && cell.period.periodReport.id === this.periodReportToValidate.toValidateCP) {
			let lastCumuleValue: number = 0;
			if (cell.period.headerPeriodBefore) {
				const lastRAECell: SPHeaderUser = cell.period.headerPeriodBefore.users.find(value => value.type === 'raeext');
				if (cell.row.values[lastRAECell.index].report) {
					lastCumuleValue = (cell.row.values[lastRAECell.index].report as IStoryReportDto).productionCumule;
				}
			} else if (this.periodReportToValidate.lastWeekPeriod) {
				const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports
					.find(sr => sr.storyId === row.rowDefinition.id);
				if (!storyReport) {
					return;
				}
				lastCumuleValue = storyReport.productionCumule;
			} else {
				lastCumuleValue = 0;
			}
			// RAE Ext = RAE EXT à T-1 - conso (conso = produit)
			this.onInputChange(cell.row.budget - lastCumuleValue - cell.value, row.values[index + 1]);
		}
	}

	calcOldBudget(cell: SPRowCell): number {
		let lastRAEValue: number = cell.row.budget;
		let lastCumuleValue: number = 0;
		if (cell.period.headerPeriodBefore) {
			const lastRAECell: SPHeaderUser = cell.period.headerPeriodBefore.users.find(value => value.type === 'raeext');
			lastRAEValue = cell.row.values[lastRAECell.index].value;
			if (cell.row.values[lastRAECell.index].report) {
				lastCumuleValue = (cell.row.values[lastRAECell.index].report as IStoryReportDto).productionCumule;
			}
		} else if (this.periodReportToValidate.lastWeekPeriod) {
			const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports
				.find(sr => sr.storyId === cell.row.rowDefinition.id);
			if (storyReport) {
				lastRAEValue = storyReport.remainingEXT;
				lastCumuleValue = storyReport.productionCumule;
			}
		} else {
			lastCumuleValue = 0;
		}
		return lastRAEValue + lastCumuleValue;
	}

	// region input change

	onInputKeyDown(event: KeyboardEvent, numberInputDirective: NumberInputDirective): void {
		if (event.key.toLowerCase() === 'p') {
			this.productionMode = true;
		}
		if (event.key.toLowerCase() === 'h') {
			numberInputDirective.elementRef.nativeElement.value = numberInputDirective.value / 8;
		}
	}

	onInputChange(inputValue: number, cell: SPRowCell): void {
		if (inputValue < 0) {
			inputValue = 0;
		}

		// Set la value de la cell
		cell.value = inputValue;

		if (cell.value === 0) {
			cell.fillRAEEXT = true;
		}

		// Pour save la valeur côté serveur après
		// Cherche le raeextupdate dans l'array
		const index: number = this.raeExtUpdated.findIndex(value =>
			value.periodReportId === cell.period.periodReport.id
			&& value.storyId === cell.row.rowDefinition.id);
		// Créer ou enlever un RAEExtUpdated en fonction de sa valeur / rapport à l'original
		if (cell.value === cell.originalValue) {
			// enlève de l'array si présent
			if (index !== -1) {
				this.raeExtUpdated.splice(index, 1);
			}
		} else {
			if (index === -1) {
				// Si rien trouvé, on ajoute
				this.raeExtUpdated.push(<SPRAEExtUpdate>{
					storyId: cell.row.rowDefinition.id,
					periodReportId: cell.period.periodReport.id,
					value: cell.value,
				});
			} else {
				// Sinon on update
				this.raeExtUpdated[index].value = cell.value;
			}
		}

		// Update la valeur de l'epic (somme des story enfants)
		const lotRow: SPRow = cell.row.lotParent;
		const epicRow: SPRow = cell.row.epicParent;

		// Update parent et ligne total
		this._calcForParentAndTotalRow(epicRow, lotRow, cell.index);

		// Update du produit
		let cumuleBeforeCellValue: number = 0;
		if (cell.lastOfSameTypeIndex) {
			if (cell.row.values[cell.lastOfSameTypeIndex].report) {
				cumuleBeforeCellValue = (cell.row.values[cell.lastOfSameTypeIndex].report as IStoryReportDto).productionCumule;
			}
		} else if (this.periodReportToValidate.lastWeekPeriod) {
			const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports.find(
				sr => sr.storyId === cell.row.rowDefinition.id);
			if (storyReport) {
				cumuleBeforeCellValue = storyReport.productionCumule;
			}
		}
		// Produit à l'instant = budget - raeext - produit semaine précédente || raeext semaine precedente - raeext courant
		const produit: number = inputValue !== null ? cell.row.budget - inputValue - cumuleBeforeCellValue : 0;
		// Si on affiche la colonne de produit seulement
		if (this.displayValuesFlat.indexOf('produit') !== -1) {
			const produitCell: SPRowCell = cell.row.values[cell.index + 1];
			// Produit = budget - raeext
			produitCell.value = produit;

			// Update parent et ligne total
			this._calcForParentAndTotalRow(epicRow, lotRow, cell.index + 1);
		}

		// Update la valeur du diff
		// Si on affiche la colonne de diff
		if (this.displayValuesFlat.indexOf('diff') !== -1) {
			const diffCellIndex: number = cell.index + 2;
			const diffCell: SPRowCell = cell.row.values[diffCellIndex];
			if (produit != null) {
				if (cell.report) {
					// Diff = produit - conso
					diffCell.value = produit - cell.report.spentSum;
				} else {
					diffCell.value = produit;
				}
			} else {
				diffCell.value = null;
			}

			// Update parent et ligne total
			this._calcForParentAndTotalRow(epicRow, lotRow, diffCellIndex);
		}

		this._calcForTotal(cell.row, 'total-period', inputValue, epicRow, lotRow);
		this._calcForTotal(cell.row, 'total', inputValue, epicRow, lotRow);
	}

	onInputBlur(cell: SPRowCell, numberInputDirective: NumberInputDirective): void {
		if (!numberInputDirective.value) {
			return;
		}
		if (this.productionMode) {
			// Récup RAEExt semaine précédente et faire RAEExt T-1 - valeur (produit)
			// Si pas de semaine précédente, utiliser le budget
			if (cell.period.headerPeriodBefore) {
				const lastRAECell: SPHeaderUser = cell.period.headerPeriodBefore.users.find(value => value.type === 'raeext');
				if (lastRAECell) {
					numberInputDirective.value = cell.row.values[lastRAECell.index].value - numberInputDirective.value;
					if (numberInputDirective.value < 0) {
						numberInputDirective.value = 0;
					}
					if (numberInputDirective.value === 0) {
						cell.fillRAEEXT = true;
					}
				}
			} else if (this.periodReportToValidate.lastWeekPeriod) {
				const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports
					.find(sr => sr.storyId === cell.row.rowDefinition.id);
				if (storyReport) {
					numberInputDirective.value = storyReport.remainingEXT - numberInputDirective.value;
					if (numberInputDirective.value < 0) {
						numberInputDirective.value = 0;
					}
					if (numberInputDirective.value === 0) {
						cell.fillRAEEXT = true;
					}
				}
			} else {
				numberInputDirective.value = cell.row.budget - numberInputDirective.value;
			}
		}
		numberInputDirective.displayedValue = numberInputDirective.value.toString();
		numberInputDirective.formatValue(true);

		// Remettre les valeurs par défaut
		this.productionMode = false;
	}

	checkIfProjectHasErrors(): boolean {
		return this.project.projectAlert && this.project.projectAlert.filter(value => value.type === ProjectAlertTypeEnum.ERROR).length > 0;
	}

	// endregion

	openDialogProjectAlert(): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '900px';
		dialogConfig.data = {
			projectAlerts: this._projectAlertService.findByProject(this._project.getValue().id),
			projectId: this._project.getValue().id,
		};

		const dialogRef: MatDialogRef<DialogProjectAlertDisplayComponent> = this.dialog.open(DialogProjectAlertDisplayComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(deleteEvent => {
			if (deleteEvent) {
				this._getProject();
			}
		});
	}

	changeIsExpendState(row: SPRow): void {
		this._lotService.toggleExpandedState(row.rowDefinition.id);
		row.expanded = !row.expanded;
		this.project.lots.find(lot => row.id === 'lot-' + lot.id).isExpend = row.expanded;
	}

	private _checkIfFilterLookLikeADisplayMatrix(): void {
		this.displayMatrixValue = 'custom';
		for (const displayMatrix of this.displayMatrix) {
			const hasSameFloating: boolean = displayMatrix.floatingTable === this.showFloatingTable;
			const containsAll: (arr1: any[], arr2: any[]) => boolean = (arr1, arr2) =>
				arr2.every(arr2Item => arr1.includes(arr2Item));
			const sameMembers: (arr1: any[], arr2: any[]) => boolean = (arr1, arr2) =>
				containsAll(arr1, arr2) && containsAll(arr2, arr1);
			const hasSameDisplayValues: boolean = sameMembers(displayMatrix.displayValues, this.displayValuesFlat);
			if (hasSameFloating && hasSameDisplayValues) {
				this.displayMatrixValue = displayMatrix;
				break;
			}
		}
	}

	private async _updateProject(): Promise<void> {
		const storiesList: IStoryDto[] = [].concat(...this.project.lots.map(value => value.stories));
		this.storiesMap = new Map(storiesList.map(value => [value.id, value]));
		if (this.hasValue('subtaskRows')) {
			const subtaskList: ISubtaskDto[] = [].concat(...storiesList.map(value => value.subtasks));
			this.subtaskMap = new Map(subtaskList.map(value => [value.id, value]));
		}
		this._changeDetectorRef.detectChanges();
	}

	private async _getProject(): Promise<void> {
		const project: IProjectDto = await this._projectService.getProjectWithActiveLotOnly(this.jiraKey, this.periodStart, this.periodEnd);
		if (project) {
			const storiesList: IStoryDto[] = [].concat(...project.lots.map(value => value.stories));
			this.storiesMap = new Map(storiesList.map(value => [value.id, value]));
			if (this.hasValue('subtaskRows')) {
				const subtaskList: ISubtaskDto[] = [].concat(...storiesList.map(value => value.subtasks));
				this.subtaskMap = new Map(subtaskList.map(value => [value.id, value]));
			}
			this.project = project;
			this.projectAlert = await firstValueFrom(this._projectAlertService.findNbAlertsByProject(project.id));
			this._changeDetectorRef.detectChanges();

			this.project.projectAlert = await firstValueFrom(this._projectAlertService.findByProject(project.id));
			this._changeDetectorRef.detectChanges();

			if (this.project.usersCp) {
				this.userIsAdminOrCP = new AdminRolePipe().transform(this.role)
					|| this.project.usersCp.filter((user) => user.email === this.email).length > 0;
			}
			this._lotService.getByProjectId(this.project.id).then(lots => {
				this.allLots = lots.filter(l => l.backlog === false && l.contract !== ContractStatusEnum.DRAFT);
				const parseDate: any = (input): Date => {
					const parts: any[] = input.match(/(\d+)/g);
					return new Date(parts[0], parts[1] - 1, parts[2]);
				};
				this.allLots.sort((a, b) => parseDate(b.creationDate).getTime() - parseDate(a.creationDate).getTime());
			});
		}
	}

	private _applyLotVisibilityChange(result: SPRow[]): void {
		this.lotsSelector.forEach(value => value.visible = result.findIndex(value1 => value1.id === value.id) !== -1);
		if (this.tableData === undefined) {
			return;
		}
		this.tableData.footer = this._generateFooter(this.tableData.header, this.tableData.body);
		this.tableData.projFooter = this._generateProjFooter(this.tableData.footer);
		this.floatingTableData = this._generateFloatingTable();


		this._changeDetectorRef.detectChanges();

		setTimeout(() => {
			this._changeDetectorRef.detectChanges();
		});
	}

	private _findFirstPeriodWithProd(spRows: SPRow[]): Date {
		let firstDateWithProd: Date = new Date();
		for (const spRow of spRows) {
			for (const cell of spRow.values) {
				if (cell.type === 'produit') {
					const cellDateBeginWithProd: Date = new Date(cell.period.dateBegin);
					if (cellDateBeginWithProd < firstDateWithProd) {
						firstDateWithProd = cellDateBeginWithProd;
					}
				}
			}
		}
		return firstDateWithProd;
	}

	private async _executeAdminValidation(period: SPHeaderPeriod): Promise<boolean> {
		if (this.project.projectAlert.filter(value => value.type === ProjectAlertTypeEnum.ERROR).length === 0) {
			// Si pas d’erreur
			period.periodReport.validatedAdmin = true;
			if (period.headerPeriodAfter) {
				// La période à valider est la suivante
				this.periodReportToValidate.toValidateAdmin = period.headerPeriodAfter.periodReport.id;
			} else {
				this.periodReportToValidate.toValidateAdmin = null;
			}
			period.lastValidatedAdmin = true;
			if (period.headerPeriodBefore) {
				period.headerPeriodBefore.lastValidatedAdmin = false;
			}
			// La période à dé-valider est l'actuel
			this.periodReportToValidate.toDevalidateAdmin = period.periodReport.id;
			period.periodReport = await this._reportService.validatePeriodForAdmin(period.periodReport.id);
			this.periodReportToValidate = await this._reportService.findPeriodForProject(
				this.jiraKey,
				this.periodStart.toISOString(true),
				this.periodEnd.toISOString(true));
			this.periodReports = this.periodReportToValidate.reports;

			return true;
		} else {
			// Si erreur, on affiche un message
			this._dialog.open(DialogValidationComponent, {
				data: {
					title: this._translator.instant('APP.WARNING'),
					message: this._translator.instant('SUIVI.TABLE.BUTTON.VALIDATION_ADMIN_ERROR'),
					showYesButton: false,
					noText: this._translator.instant('APP.CLOSE'),
				},
			});
			return false;
		}
	}

	private async _executeAdminUnvalidation(period: SPHeaderPeriod): Promise<void> {
		period.periodReport.validatedAdmin = false;
		// La période à valider est l'actuel
		this.periodReportToValidate.toValidateAdmin = period.periodReport.id;
		period.lastValidatedAdmin = false;
		// La période à dé-valider est la précédente
		if (period.headerPeriodBefore) {
			this.periodReportToValidate.toDevalidateAdmin = period.headerPeriodBefore.periodReport.id;
			period.headerPeriodBefore.lastValidatedAdmin = true;
		}

		this._spinnerService.enableLoading();
		this._snackBar.open('Ré ouverture de la période et soumission de la ré ouverture à Tempo en cours ...');
		this._reportService.unvalidatePeriodForAdmin(period.periodReport.id).then(() => {
			this._spinnerService.disableLoading();
			this._snackBar.open('Ré ouverture de la période et soumission de la ré ouverture à Tempo terminé');
		});
	}

	// region Calc
	/**
	 * Calcul des informations dans la colonne total (periode/projet)
	 * @param row
	 * @param prefix
	 * @param inputValue
	 * @param epicRow
	 * @param lotRow
	 */
	private _calcForTotal(row: SPRow, prefix: string, inputValue: number, epicRow: SPRow, lotRow: SPRow): void {
		// Update du total à droite
		// Update RAEEXT
		const totalRaeExtIndex: number = row.values.findIndex(value => value.type === prefix + '-raeext');
		row.values[totalRaeExtIndex].value = inputValue;
		// Update parent et ligne total
		this._calcForParentAndTotalRow(epicRow, lotRow, totalRaeExtIndex);

		// Update du total à droite
		const totProduitColIndex: number = this._calcTotalProduitForRow(row, prefix);
		// Update parent et ligne total
		this._calcForParentAndTotalRow(epicRow, lotRow, totProduitColIndex);

		// Update du total de diff à droite (prod - conso)
		const totProduitCell: SPRowCell = row.values.find(value => value.type === prefix + '-produit');
		const totConsoCell: SPRowCell = row.values.find(value => value.type === prefix + '-conso');
		const totDiffColIndex: number = row.values.findIndex(value => value.type === prefix + '-diff');
		row.values[totDiffColIndex].value = totProduitCell.value - totConsoCell.value;
		// Update parent et ligne total
		this._calcForParentAndTotalRow(epicRow, lotRow, totDiffColIndex);

		// Update la valeur de l'avancement à droite
		// Update du total à droite
		this._calcTotalAvancementForRow(row, prefix);
		// Update la valeur de l'epic
		if (epicRow) {
			this._calcTotalAvancementForRow(epicRow, prefix);
		}
		// Update la valeur du lot
		if (lotRow) {
			this._calcTotalAvancementForRow(lotRow, prefix);
		}
		// Update la valeur du footer
		this._calcTotalAvancementForRow(this.tableData.footer, prefix);
	}

	/**
	 * Calcul du total de l’avancement pour la ligne
	 * @param row
	 * @param prefix
	 * @private
	 */
	private _calcTotalAvancementForRow(row: SPRow, prefix: string): void {
		const avProduitCell: SPRowCell = row.values.find(value => value.type === prefix + '-produit');
		const avancementCell: SPRowCell = row.values.find(value => value.type === prefix + '-avancement');
		if (avancementCell) {
			avancementCell.value = avProduitCell.value / row.budget * 100;
		}
	}

	/**
	 * Update valeur de l'epic, du lot, de la ligne total en bas
	 * @param epicRow
	 * @param lotRow
	 * @param totProduitColIndex
	 * @private
	 */
	private _calcForParentAndTotalRow(epicRow: SPRow, lotRow: SPRow, totProduitColIndex: number): void {
		// Update la valeur de l'epic
		if (epicRow) {
			this._calcTotalForCellOfRow(epicRow, totProduitColIndex);
		}
		// Update la valeur du lot
		if (lotRow) {
			this._calcTotalForCellOfRow(lotRow, totProduitColIndex);
		}

		// Update la valeur du footer
		// Update valeur de la ligne total en bas
		this._calcTotalForCol(totProduitColIndex);
	}

	private _calcTotalForCol(index: number): void {
		this.tableData.footer.values[index].value = this.tableData.body
			.filter(value => value.type === 'story' && (value.values[index].displayed
				&& ((value.lotParent && value.lotParent.visible) || !value.lotParent)))
			.map(value => value.values[index].value)
			.reduce(UtilsService.sumValues(), 0);
	}

	private _calcTotalProduitForRow(row: SPRow, prefix: string): number {
		const colIndex: number = row.values.findIndex(value => value.type === prefix + '-produit');
		if (prefix === 'total-period') {
			// Pour le total période, il faut faire le total des valeurs affichées et non le budget - RAE
			row.values[colIndex].value = row.values.filter(value => value.type === 'produit')
				.map(value => value.value)
				.reduce(UtilsService.sumValues());
		} else {
			row.values[colIndex].value = row.budget - row.values[colIndex - 1].value;
		}
		return colIndex;
	}

	private _calcTotalForCellOfRow(row: SPRow, index: number): void {
		row.values[index].value = row.children
			.map(value => value.values[index].value)
			.reduce(UtilsService.sumValues(), 0);
	}

	// endregion

	private _fillRaeExtNextPeriod(period: SPHeaderPeriod): void {
		// Si pas de période suivante, on annule
		if (!period.headerPeriodAfter) {
			return;
		}
		// Si période suivante, on reporte les chiffres RAE EXT de la période à la période suivante
		const raeExtIndex: number = period.users.find(value => value.type === 'raeext').index;
		const raeExtNextIndex: number = period.headerPeriodAfter.users.find(value => value.type === 'raeext').index;
		const consoNextIndex: number = period.headerPeriodAfter.users.find(value => value.type === 'conso').index;
		for (const row of this.tableData.body) {
			const actualRaeExt: SPRowCell = row.values[raeExtIndex];
			const nextRaeExt: SPRowCell = row.values[raeExtNextIndex];
			// Si row = story
			if (row.type === 'story') {
				if (nextRaeExt.report && (nextRaeExt.report as IStoryReportDto).remainingEXT) {
					// S’il y a un report, on utilise sa valeur en priorité
					nextRaeExt.value = (nextRaeExt.report as IStoryReportDto).remainingEXT;
					nextRaeExt.originalValue = (nextRaeExt.report as IStoryReportDto).remainingEXT;
					nextRaeExt.fillRAEEXT = true;
				} else {
					// Si pas de conso dans le next!
					if (!row.values[consoNextIndex].value) {
						// On vérifie si, dans le rae ext de l'actuel qu'on valide la valeur est 0
						if (!actualRaeExt.value) {
							nextRaeExt.fillRAEEXT = true;
							nextRaeExt.value = 0;
						} else {
							// Si pas de conso mais du RAE, le next raeext = rae ext actuel
							nextRaeExt.value = actualRaeExt.value;
						}
					} else {
						// S'il y a de la conso dans le next, on met à vide
						nextRaeExt.value = null;
						nextRaeExt.originalValue = null;
						nextRaeExt.fillRAEEXT = false;
					}
				}
				this.onInputChange(nextRaeExt.value, nextRaeExt);
			}
		}
	}

	// region table generation
	private _initTable(): void {
		if (!this.periodReports || !this.periodReports.length) {
			return;
		}
		let lotsVisibles: SPRow[] = this.lotsSelector.filter(row => row.visible).slice();
		this.lotsSelector = [];

		this.tableData = this._generateTable();
		this.floatingTableData = this._generateFloatingTable();

		// Filtre total produit sur la période pas = 0
		this.defaultVisibleLots = this.lotsSelector
			.filter(value => value.values.find(cell => cell.type === 'produit' && cell.value > 0)
				|| value.values.find(cell => cell.type === 'conso' && cell.value > 0));
		if (this.selectionAutomatiqueLotsVisibles || !lotsVisibles.length) {
			lotsVisibles = this.defaultVisibleLots.slice();
		}

		this._applyLotVisibilityChange(lotsVisibles);
		this._changeDetectorRef.detectChanges();
	}

	private _generateFloatingTable(): SPTable {
		const body: SPRow[] = this._generateFloatingBody();
		const footer: SPRow = this._generateFloatingFooter();

		return <SPTable>{
			header: null,
			body,
			footer,
		};
	}

	private _generateFloatingBody(): SPRow[] {
		const floatingRows: SPRow[] = [];
		for (const row of this.tableData.body) {
			if (row.visible === false || (row.lotParent && !row.lotParent.visible)) {
				continue;
			}
			floatingRows.push(this._calcFloatingRowValues(row));
		}

		return floatingRows;
	}

	private _calcFloatingRowValues(row: SPRow): SPRow {
		const floatingRow: SPRow = Object.assign({}, row);
		floatingRow.values = Object.assign([], floatingRow.values.slice(floatingRow.values.length - 5));
		floatingRow.values = floatingRow.values.map(value => Object.assign({}, value));


		const consoCell: SPRowCell = floatingRow.values.find(value => value.type === 'total-conso');
		const notValidatedConsoCells: SPRowCell[] = row.values.filter(value =>
			value.period
			&& value.period.periodReport
			&& !value.period.periodReport.validatedCP
			&& value.type === 'conso');
		for (const notValidatedConsoCell of notValidatedConsoCells) {
			consoCell.value -= notValidatedConsoCell.value;
		}
		if (consoCell?.value < 0) {
			consoCell.value = 0;
		}

		const raeExtCell: SPRowCell = floatingRow.values.find(value => value.type === 'total-raeext');
		const lastValidatedRaeExtCell: SPRowCell = row.values.find(value =>
			value.period
			&& value.period.lastValidatedCP
			&& value.type === 'raeext');
		if (lastValidatedRaeExtCell) {
			raeExtCell.value = lastValidatedRaeExtCell.value;
		}

		// Calcul produit
		const produitCell: SPRowCell = floatingRow.values.find(value => value.type === 'total-produit');
		if (produitCell && raeExtCell) {
			produitCell.value = floatingRow.budget - raeExtCell.value;
		}
		// Calcul diff
		const diffCell: SPRowCell = floatingRow.values.find(value => value.type === 'total-diff');
		if (diffCell && produitCell && consoCell) {
			diffCell.value = produitCell.value - consoCell.value;
		}
		// Calcul budget
		const budgetCell: SPRowCell = floatingRow.values.find(value => value.type === 'total-avancement');
		if (budgetCell) {
			budgetCell.value = row.budget;
			budgetCell.type = 'budget';
			// place budget on first place on floatingRow
			floatingRow.values.splice(floatingRow.values.indexOf(budgetCell), 1);
			floatingRow.values.splice(0, 0, budgetCell);
		}

		return floatingRow;
	}

	private _generateFloatingFooter(): SPRow {
		return this._calcFloatingRowValues(this.tableData.footer);
	}

	private _generateTable(): SPTable {
		const header: SPHeader = this._generateHeader();
		const body: SPRow[] = this._generateBody(header);
		const footer: SPRow = this._generateFooter(header, body);
		const projFooter: SPRow = this._generateProjFooter(footer);

		return <SPTable>{
			header,
			body,
			footer,
			projFooter,
		};
	}

	private _generateProjFooter(footer: SPRow): SPRow {
		const totalRow: SPRow = <SPRow>{
			budget: 0,
			values: [],
		};

		totalRow.budget = this.project.budget;
		// Pour chaque colonne, on va génerer une case vide
		for (let index: number = 0; index < footer.values.length; index++) {
			// Si la value n'existe pas, on la créé
			if (!totalRow.values[index]) {
				totalRow.values[index] = Object.assign({}, footer.values[index]);
			}
			totalRow.values[index].row = totalRow;
			const produit: number = this.project.budget - this.project.remainingEXTSum;
			switch (totalRow.values[index].type) {
				case 'total-conso':
					totalRow.values[index].value = this.project.spentSum;
					totalRow.values[index].type = 'total-proj-conso';
					break;
				case 'total-raeext':
					totalRow.values[index].value = this.project.remainingEXTSum;
					totalRow.values[index].type = 'total-proj-raeext';
					break;
				case 'total-produit':
					totalRow.values[index].value = produit;
					totalRow.values[index].type = 'total-proj-produit';
					break;
				case 'total-diff':
					// Budget - Conso
					totalRow.values[index].value = produit - this.project.spentSum;
					totalRow.values[index].type = 'total-proj-diff';
					break;
				case 'total-avancement':
					// Produit/Budget*100
					totalRow.values[index].value = (produit / this.project.budget) * 100;
					totalRow.values[index].type = 'total-proj-avancement';
					break;
			}
		}

		return totalRow;
	}

	private _generateFooter(header: SPHeader, body: SPRow[]): SPRow {
		const totalRow: SPRow = <SPRow>{
			budget: 0,
			values: [],
		};
		// Pour chaque ligne
		for (const row of body) {
			// Si c'est pas un lot, ou si lot pas visible, on passe
			if (row.type !== 'lot' || !row.visible) {
				continue;
			}
			// Le total fait la somme du budget des lots
			totalRow.budget += row.budget;
			// Pour chaque colonne
			for (let index: number = 0; index < row.values.length; index++) {
				// Si la value n'existe pas, on la créé
				if (!totalRow.values[index]) {
					totalRow.values[index] = <SPRowCell>{
						value: 0,
						type: row.values[index].type,
						period: row.values[index].period,
						header: header.users[index] || null,
						row: totalRow,
						index,
						displayed: row.values[index].period ? row.values[index].period.displayed : true, // affichage période
					};
				}
				if (row.values[index].type.startsWith('total-period-')) {
					totalRow.values[index].displayed = this.displayValuesFlat.indexOf('total-period') !== -1;
				}
				const totalConsoIndex: number = row.values.findIndex(value => value.type === 'total-conso');
				const totalProduitIndex: number = totalRow.values.findIndex(value => value.type === 'total-produit');
				switch (row.values[index].type) {
					case 'total-diff':
						totalRow.values[index].displayed = this.displayValuesFlat.indexOf('diffTotal') !== -1;
						// Prod - Conso
						totalRow.values[index].value = totalRow.values[totalProduitIndex].value - totalRow.values[totalConsoIndex].value;
						break;
					case 'total-avancement':
						totalRow.values[index].value = totalRow.values[totalProduitIndex].value / totalRow.budget * 100;
						break;
					case 'total-period-diff':
						totalRow.values[index].displayed =
							this.displayValuesFlat.indexOf('diffTotal') !== -1 && this.displayValuesFlat.indexOf('total-period') !== -1;
						break;
					case 'conso':
						totalRow.values[index].value += row.values[index].value;
						break;
					case 'produit':
						totalRow.values[index].value += row.values[index].value;
						break;
					case 'user':
					default:
						// Somme des valeurs de la colonne
						// Pour tous les cas qui sont pas écrit au dessus
						totalRow.values[index].value += row.values[index].value;
						break;
				}
			}
		}
		return totalRow;
	}

	private _generateBody(header: SPHeader): SPRow[] {
		// Génération des row definition (les 2 premières colonnes jira + budget)
		const rows: SPRow[] = this._generateRowDefinitions(header);

		// Deuxième passage, remplissage des SPRow avec les SPRowCell
		this._fillStoryAndSubtaskRow(rows, header);

		// Troisième passage pour calculer les valeurs pour les epics et les lots
		this._fillEpicAndLotRow(rows, header);

		// Quatrième passage pour remplir les colonnes totales périodes
		this._fillTotalPeriodColumns(rows);

		// Cinquième et dernier passage pour remplir les colonnes totales
		this._fillTotalColumns(rows);

		return rows;
	}

	private _fillTotalColumns(rows: SPRow[]): void {
		for (const row of rows) {
			const totalConsoCell: SPRowCell = <SPRowCell>{
				type: 'total-conso',
				value: 0,
				row,
				displayed: true,
			};
			const totalRAEExtCell: SPRowCell = <SPRowCell>{
				type: 'total-raeext',
				value: 0,
				row,
				displayed: true,
			};
			const totalProduitCell: SPRowCell = <SPRowCell>{
				type: 'total-produit',
				value: 0,
				row,
				displayed: true,
			};
			const totalDiffCell: SPRowCell = <SPRowCell>{
				type: 'total-diff',
				value: 0,
				row,
				displayed: this.displayValuesFlat.indexOf('diffTotal') !== -1,
			};
			const totalAvancementCell: SPRowCell = <SPRowCell>{
				type: 'total-avancement',
				value: 0,
				row,
				displayed: true,
			};

			switch (row.type) {
				case 'lot':
					totalConsoCell.value = (<ILotDto>row.rowDefinition).spentSum;
					totalRAEExtCell.value = (<ILotDto>row.rowDefinition).remainingEXTSum;
					if (row.budget) {
						totalProduitCell.value = row.budget - row.children
							.map(value => isNaN((<IStoryDto>value.rowDefinition).remainingEXT) ? value.budget : (<IStoryDto>value.rowDefinition).remainingEXT)
							.reduce(UtilsService.sumValues(), 0);
					}
					break;
				case 'epic':
					// Somme des stories enfant
					totalConsoCell.value = row.children
						.map(value => (<IStoryDto>value.rowDefinition).spent)
						.reduce(UtilsService.sumValues(), 0);
					totalRAEExtCell.value = row.children
						.map(value => isNaN((<IStoryDto>value.rowDefinition).remainingEXT) ? value.budget : (<IStoryDto>value.rowDefinition).remainingEXT)
						.reduce(UtilsService.sumValues(), 0);
					totalProduitCell.value = row.budget - totalRAEExtCell.value;
					break;
				case 'story':
					totalConsoCell.value = (<IStoryDto>row.rowDefinition).spent;
					totalRAEExtCell.value = isNaN((<IStoryDto>row.rowDefinition).remainingEXT) ? row.budget : (<IStoryDto>row.rowDefinition).remainingEXT;
					totalProduitCell.value = row.budget - totalRAEExtCell.value;
					break;
				case 'subtask':
					totalConsoCell.value = (<ISubtaskDto>row.rowDefinition).spent;
					break;
			}

			// Produit - conso
			totalDiffCell.value = totalProduitCell.value - totalConsoCell.value;
			// Avancement = produit / budget * 100
			totalAvancementCell.value = totalProduitCell.value / row.budget * 100;

			row.values.push(totalConsoCell, totalRAEExtCell, totalProduitCell, totalDiffCell, totalAvancementCell);
		}
	}

	private _fillTotalPeriodColumns(rows: SPRow[]): void {
		for (const row of rows) {
			const totalConsoCell: SPRowCell = <SPRowCell>{
				type: 'total-period-conso',
				value: 0,
				row,
				displayed: this.displayValuesFlat.indexOf('total-period') !== -1,
			};
			const totalRAEExtCell: SPRowCell = <SPRowCell>{
				type: 'total-period-raeext',
				value: 0,
				row,
				displayed: this.displayValuesFlat.indexOf('total-period') !== -1,
			};
			const totalProduitCell: SPRowCell = <SPRowCell>{
				type: 'total-period-produit',
				value: 0,
				row,
				displayed: this.displayValuesFlat.indexOf('total-period') !== -1,
			};
			const totalDiffCell: SPRowCell = <SPRowCell>{
				type: 'total-period-diff',
				value: 0,
				row,
				displayed: this.displayValuesFlat.indexOf('diffTotal') !== -1 && this.displayValuesFlat.indexOf('total-period') !== -1,
			};

			let lastValidated: SPRowCell;
			switch (row.type) {
				case 'lot':
					// Somme des conso
					totalConsoCell.value = row.values
						.filter(value => value.type === 'conso')
						.map(value => value.value)
						.reduce(UtilsService.sumValues(), 0);
					// Valeur du dernier RAE Ext de la row
					lastValidated = row.values.find(value => value.type === 'raeext' && value.period.lastValidatedCP);
					if (lastValidated) {
						totalRAEExtCell.value = lastValidated.value;
					}
					if (row.budget) {
						totalProduitCell.value = row.values.filter(value => value.type === 'produit')
							.map(value => value.value)
							.reduce(UtilsService.sumValues());
					}
					break;
				case 'epic':
					// Somme des conso
					totalConsoCell.value = row.values
						.filter(value => value.type === 'conso')
						.map(value => value.value)
						.reduce(UtilsService.sumValues(), 0);
					// Valeur du dernier RAE Ext de la row
					lastValidated = row.values.find(value => value.type === 'raeext' && value.period.lastValidatedCP);
					if (lastValidated) {
						totalRAEExtCell.value = lastValidated.value;
					}
					totalProduitCell.value = row.values.filter(value => value.type === 'produit')
						.map(value => value.value)
						.reduce(UtilsService.sumValues());
					break;
				case 'story':
					// Somme des conso
					totalConsoCell.value = row.values
						.filter(value => value.type === 'conso')
						.map(value => value.value)
						.reduce(UtilsService.sumValues(), 0);
					// Valeur du dernier RAE Ext de la row
					lastValidated = row.values.find(value => value.type === 'raeext' && value.period.lastValidatedCP);
					if (lastValidated) {
						totalRAEExtCell.value = lastValidated.value;
					}
					totalProduitCell.value = row.values.filter(value => value.type === 'produit')
						.map(value => value.value)
						.reduce(UtilsService.sumValues());
					break;
				case 'subtask':
					// Somme des conso
					totalConsoCell.value = row.values
						.filter(value => value.type === 'conso')
						.map(value => value.value)
						.reduce(UtilsService.sumValues(), 0);
					break;
			}

			// Produit - conso
			totalDiffCell.value = totalProduitCell.value - totalConsoCell.value;

			row.values.push(totalConsoCell, totalRAEExtCell, totalProduitCell, totalDiffCell);
		}
	}

	private _fillEpicAndLotRow(rows: SPRow[], header: SPHeader): void {
		// Pour chaque colonnes
		for (let i: number = 0; i < header.users.length; i++) {
			let epicValue: number = 0;
			let lotValue: number = 0;
			let epicBudget: number = 0;
			// Pour chaque ligne dans le sens inverse (en montant de bas en haut)
			for (let j: number = rows.length - 1; j >= 0; j--) {
				const row: SPRow = rows[j];
				switch (row.type) {
					case 'lot':
						row.values[i].value = lotValue;
						lotValue = 0;
						break;
					case 'epic':
						row.values[i].value = epicValue;
						lotValue += epicValue;
						epicValue = 0;
						row.budget = epicBudget;
						epicBudget = 0;
						break;
					case 'story':
						if (row.epicParent) {
							epicValue += row.values[i].value;
							epicBudget += row.budget;
						}
						break;
					case 'subtask':
						// Si c'est une subtask, on passe c'est pas intéressant
						break;
				}
			}
		}
	}

	private _fillStoryAndSubtaskRow(rows: SPRow[], header: SPHeader): void {
		// Pour chaque ligne
		for (const row of rows) {
			// pour chaque colonnes
			let conso: number = 0;
			let produit: number = 0;
			let raeext: number = 0;

			row.values = [];
			if (row.type === 'epic') {
				row.urlIssueJiraId = this.configurationUrlJira.value + (<IEpicDto>row.rowDefinition).jiraIntId;
			} else if (row.type === 'story') {
				row.urlIssueJiraId = this.configurationUrlJira.value + (<IStoryDto>row.rowDefinition).jiraIntId;
			} else if (row.type === 'subtask') {
				row.urlIssueJiraId = this.configurationUrlJira.value + (<ISubtaskDto>row.rowDefinition).jiraIntId;
			}

			let indexOfLastConso: number = null;
			let indexOfLastRAEExt: number = null;
			let indexOfLastProduit: number = null;
			let indexOfLastDiff: number = null;

			for (let index: number = 0; index < header.users.length; index++) {
				const headerUser: SPHeaderUser = header.users[index];
				// Chercher le story ou subtask report (pas le detail)
				let storyOrSubtaskReport: IStoryReportDto | ISubtaskReportDto;

				if (row.type === 'story') {
					storyOrSubtaskReport = headerUser.period.periodReport.storyReports.find(value => value.storyId === row.rowDefinition.id);
				} else if (row.type === 'subtask') {
					const subtaskReports: ISubtaskReportDto[] = [].concat(...headerUser.period.periodReport.storyReports
						.map(value => value.subtaskReports));
					storyOrSubtaskReport = subtaskReports.find(value => value.subtaskId === row.rowDefinition.id);
				}

				// On créé une cell du type de la colonne avec la valeur à zéro
				const cell: SPRowCell = <SPRowCell>{
					type: headerUser.type,
					value: 0,
					period: headerUser.period,
					header: headerUser,
					row,
					index,
					displayed: headerUser.period.displayed,
				};

				if (row.type === 'story') {
					cell.report = headerUser.period.periodReport.storyReports.find(value => value.storyId === row.rowDefinition.id);
				}

				switch (headerUser.type) {
					case 'user':
						// Récupère le report detail de la personne et on cumule les entrées sur la période
						if (row.type === 'story') {
							cell.value = headerUser.reports
								.filter(value => value.type === row.type && row.rowDefinition.id === (<IStoryReportDetail>value).storyReport.storyId)
								.map(value => (<IStoryReportDetail>value).spentSum)
								.reduce(UtilsService.sumValues(), 0);
							cell.report = headerUser.period.periodReport.storyReports.find(value => value.storyId === row.rowDefinition.id);
							cell.object = headerUser.reports
								.find(value => value.type === row.type && row.rowDefinition.id === (<IStoryReportDetail>value).storyReport.storyId);
						} else if (row.type === 'subtask') {
							cell.value = headerUser.reports
								.filter(value => value.type === row.type && row.rowDefinition.id === (<ISubtaskReportDetail>value).subtaskReport.subtaskId)
								.map(value => (<ISubtaskReportDetail>value).spent)
								.reduce(UtilsService.sumValues(), 0);
							const storyReport: IStoryReportDto = headerUser.period.periodReport.storyReports
								.find(value => value.storyId === (row.rowDefinition as ISubtaskDto).storyId);
							if (storyReport?.subtaskReports) {
								cell.report = storyReport.subtaskReports.find(value => value.subtaskId === row.rowDefinition.id);
							}
							cell.object = headerUser.reports
								.find(value => value.type === row.type && row.rowDefinition.id === (<ISubtaskReportDetail>value).subtaskReport.subtaskId);
						}
						conso += cell.value;
						break;
					case 'conso':
						// Conso = Somme conso utilisateur
						if (cell.report) {
							cell.value = cell.report.spentSum;
						} else {
							cell.value = conso;
						}
						// Set de l'index du même type
						cell.lastOfSameTypeIndex = indexOfLastConso;
						indexOfLastConso = index;
						break;
					case 'raeext':
						// Faut retrouver le story report
						if (row.type === 'story') {
							this._initRAEExtInputValue(storyOrSubtaskReport, cell, indexOfLastRAEExt, row, conso);
						} else if (!cell.fillRAEEXT && !cell.value) {
							cell.value = null;
						}
						raeext = cell.value;

						// Set de l'index du même type
						cell.lastOfSameTypeIndex = indexOfLastRAEExt;
						indexOfLastRAEExt = index;
						break;
					case 'produit':
						// Produit = Budget - RAEEXT - semaine precedente
						// Conso = Somme conso utilisateur
						if (cell.report) {
							cell.value = (<IStoryReportDto>cell.report).productionCalc;
						} else {
							cell.value = null;
						}
						produit = cell.value;

						// Set de l'index du même type
						cell.lastOfSameTypeIndex = indexOfLastProduit;
						indexOfLastProduit = index;
						break;
					case 'diff':
						// Diff = Produit - conso
						cell.value = produit - conso;
						conso = produit = raeext = 0;

						// Set de l'index du même type
						cell.lastOfSameTypeIndex = indexOfLastDiff;
						indexOfLastDiff = index;
						break;
				}
				row.values.push(cell);
			}
		}
	}

	private _initRAEExtInputValue(storyOrSubtaskReport: IStoryReportDto | ISubtaskReportDto,
								  cell: SPRowCell,
								  indexOfLastRAEExt: number,
								  row: SPRow,
								  conso: number): void {
		let value: number = null;
		let lastRAEValue: number = null;
		let lastCumuleValue: number = 0;

		// Récupération last cumulé + last rae
		if (indexOfLastRAEExt || this.periodReportToValidate.lastWeekPeriod) {
			if (indexOfLastRAEExt) {
				lastRAEValue = row.values[indexOfLastRAEExt].value;
				if (row.values[indexOfLastRAEExt].report) {
					lastCumuleValue = (row.values[indexOfLastRAEExt].report as IStoryReportDto).productionCumule;
				}
			} else {
				const storyReport: IStoryReportDto = this.periodReportToValidate.lastWeekPeriod.storyReports
					.find(sr => sr.storyId === row.rowDefinition.id);
				if (storyReport) {
					lastRAEValue = storyReport.remainingEXT;
					lastCumuleValue = storyReport.productionCumule;
				}
			}
		}
		if (storyOrSubtaskReport) {
			value = (<IStoryReportDto>storyOrSubtaskReport).remainingEXT;
			if (value === 0 || cell.period.periodReport.validatedCP) {
				cell.fillRAEEXT = true;
			}
			// SI valeur pas null, on va juste recalculer le value parce qu
			if (this.periodReportToValidate.toValidateCP === cell.period.periodReport.id && value != null) {
				// Value = budget - prodcumule
				value = row.budget - (<IStoryReportDto>storyOrSubtaskReport).productionCumule;
			}
		}

		// Diff de budget si budget actuel != dernier RAE + dernier produit cumulé
		if (this.periodReportToValidate.toValidateCP === cell.period.periodReport.id) {
			cell.diffBudget = cell.row.budget !== lastRAEValue + lastCumuleValue;
		}

		if (value === null) {
			// RAE = Budget - cumulé semaine précédente
			value = row.budget - lastCumuleValue || 0;
			cell.fillRAEEXT = true;

			// RAE T-1 = 0 --> RAE T = 0
			if (lastRAEValue === 0) {
				value = 0;
			}
			// Conso T = 0 --> RAE T = BUDGET si pas de semaine précédente
		} else if (!conso && !storyOrSubtaskReport && cell.header.period && cell.header.period.periodReport
			&& !cell.header.period.periodReport.validatedCP) {
			value = (row.rowDefinition as IStoryDto).budget || 0;
			cell.fillRAEEXT = true;
		}
		// Si on rempli pas la case et qu’il n’y a pas de value ou que value = 0, value = null
		if (!cell.fillRAEEXT && !value) {
			value = null;
		}

		// Si pas de report, valeur = null
		if (cell.header.period?.periodReport?.validatedCP && !cell.report) {
			value = null;
			cell.fillRAEEXT = false;
		}
		cell.value = value;
		cell.originalValue = cell.value;
	}

	private _generateRowDefinitions(header: SPHeader): SPRow[] {
		let rows: SPRow[] = [];

		// Premier passage, générer les rows avec leur définitions
		this._generateStoryAndSubtaskRowDefinitions(header, rows);

		// Maintenant qu'on à les story et subtasks, on génère les epics et les lots
		this._generateEpicsAndLotsRowDefinition(rows);

		// Et on ordonne le tout pour que ça rende comme on veut visuellement
		rows = this._orderEverythingInStoryAndEpicsAndLots(rows);
		return rows;
	}

	private _orderEverythingInStoryAndEpicsAndLots(rows: SPRow[]): SPRow[] {
		// Dé platifier tout pour en faire un objet (plus simple à ordonner derrière) puis replatifier
		// Génération des lots (la couche la plus haute)
		const rowObjects: SPRowObject[] = rows.map(value => <SPRowObject>{row: value, children: []});
		let lotRows: SPRowObject[] = rowObjects.filter(value => value.row.type === 'lot');
		const storyRows: SPRowObject[] = rowObjects.filter(value => value.row.type === 'story');
		const subtaskRows: SPRowObject[] = rowObjects.filter(value => value.row.type === 'subtask');

		// Pour chaque lot on va remplir avec les epics qui le concerne et les story sans epics qui le concerne aussi
		lotRows = lotRows.sort(UtilsService.dynamicMultiSort('row.rowDefinition.order'));
		for (const lotRow of lotRows) {
			const epicToAddToLot: SPRowObject[] = [];
			lotRow.children = storyRows.filter(value => (<IStoryDto>value.row.rowDefinition).lotId === (<ILotDto>lotRow.row.rowDefinition).id);

			// Les enfants du lots sont que des story
			for (const storyRow of lotRow.children) {
				const story: IStoryDto = <IStoryDto>storyRow.row.rowDefinition;
				// On place les subtask dans la story si la subtask a la story en ID et on les sort par order puis par nom si même order
				storyRow.children = subtaskRows
					.filter(value => story.id === (<ISubtaskDto>value.row.rowDefinition).storyId)
					.sort(UtilsService.dynamicMultiSort('row.rowDefinition.order', 'row.rowDefinition.jiraIntId'));

				// On vérifie l'epic de la story pour y préparer les epics et il faut marquer les storys comme à enlever du lot
				if (story.epic) {
					// Définie la row comme à enlever car elle sera dans l'epic
					storyRow.toRemove = true;
					// Recherche la row EPIC à mettre dans le lot
					const epicRowObject: SPRowObject = rowObjects
						.find(value => value.row.type === 'epic' && (<IEpicDto>value.row.rowDefinition).id === story.epicId);
					if (epicRowObject) {
						const epicRow: SPRow = epicRowObject.row;
						let epic: SPRowObject = epicToAddToLot.find(value => value.row.rowDefinition.id === epicRow.rowDefinition.id);
						// Si pas encore dans le lot, on la créé
						if (!epic) {
							const row: SPRow = Object.assign({}, epicRow);
							row.id = epicRow.rowDefinition.id + '-' + lotRow.row.rowDefinition.id;
							epic = {
								row,
								toRemove: false,
								children: [],
							};
						}

						// Ajoute la story à l'epic
						epic.children.push(storyRow);
						// Si epic pas encore dans l'array, on le rajoute
						if (!epicToAddToLot.find(value => value.row.rowDefinition.id === epic.row.rowDefinition.id)) {
							epicToAddToLot.push(epic);
						}
					}
				}
			}

			// Sort les epics par order puis par nom
			epicToAddToLot.sort(UtilsService.dynamicMultiSort('row.rowDefinition.order', 'row.rowDefinition.jiraIntId'));
			// Sort les story dans les epics par order puis par nom
			epicToAddToLot.forEach(value => value.children
				.sort(UtilsService.dynamicMultiSort('row.rowDefinition.order')));

			// Met les epics en place
			lotRow.children.push(...epicToAddToLot);

			// Enlève les story to remove
			let childrenCounter: number = lotRow.children.length;
			while (childrenCounter--) {
				if (lotRow.children[childrenCounter].toRemove) {
					lotRow.children.splice(childrenCounter, 1);
				}
			}

			// Pour finir, on sort les enfants du lot !
			lotRow.children.sort(UtilsService.dynamicMultiSort('row.rowDefinition.order', 'row.rowDefinition.jiraIntId'));
		}

		// Enlever les lot vide
		let lotCounter: number = lotRows.length;
		while (lotCounter--) {
			if (!lotRows[lotCounter].children.length) {
				lotRows.splice(lotCounter, 1);
			}
		}

		// Maintenant, on va platifier l'objet et le retourner
		const ordonnedRows: SPRow[] = this._flattenObject(lotRows).map(value => value.row);
		// On va faire les liaison story > epic, lot pour que la story sache sur qui sont ses papas
		// et que l'epic et lot sachent qui sont leurs enfants
		this._createLinks(ordonnedRows);
		return ordonnedRows;
	}

	private _createLinks(rows: SPRow[]): void {
		let epicRow: SPRow;
		let lotRow: SPRow;
		let storyRow: SPRow;
		// On peut se permettre de faire comme ça parce que l'objet est bien ordonné
		for (const row of rows) {
			switch (row.type) {
				case 'lot':
					// Set le lot
					lotRow = row;
					lotRow.children = [];
					// Comme le lot est supérieur à l'epic et à la story, un nouveau lot implique un nouvel epic et une nouvelle story
					epicRow = null;
					storyRow = null;

					if (lotRow.visible) {
						this.lotsSelector.push(lotRow);
					}
					break;
				case 'epic':
					// Set l'epic
					epicRow = row;
					epicRow.children = [];
					// Set la valeur parent
					epicRow.lotParent = lotRow;
					// Comme l'epic est supérieur à la story, un nouvel epic implique une nouvelle story
					storyRow = null;
					break;
				case 'story':
					// Set la storyRow
					storyRow = row;
					storyRow.children = [];
					// Set les valeurs parents
					storyRow.lotParent = lotRow;
					storyRow.epicParent = epicRow;
					// Ajoute la story au lot + epic
					lotRow.children.push(storyRow);
					if (epicRow) {
						epicRow.children.push(storyRow);
					}
					break;
				case 'subtask':
					// Set le parent
					row.lotParent = lotRow;
					row.epicParent = epicRow;
					row.storyParent = storyRow;
					// Ajoute la subatask a la story
					storyRow.children.push(row);
					break;
			}
		}
	}

	private _flattenObject(rows: SPRowObject[]): SPRowObject[] {
		let flattenedRows: SPRowObject[] = [];
		for (const row of rows) {
			flattenedRows.push(row);
			if (row.children) {
				flattenedRows = flattenedRows.concat(this._flattenObject(row.children));
			}
		}
		return flattenedRows;
	}

	private _generateEpicsAndLotsRowDefinition(rows: SPRow[]): void {
		if (this.project.lots) {
			for (const lot of this.project.lots) {
				rows.push(<SPRow>{
					type: 'lot',
					rowDefinition: lot,
					budget: lot.budget || 0,
					values: [],
					expanded: lot.isExpend,
					// Pour qu’un lot soit affiché, il ne doit pas être en draft ni être le lot "temporaire" (backlog)
					visible: lot.contract !== ContractStatusEnum.DRAFT && !lot.backlog,
					id: 'lot-' + lot.id,
				});
			}
		}
		if (this.project.epics) {
			for (const epic of this.project.epics) {
				rows.push(<SPRow>{
					type: 'epic',
					rowDefinition: epic,
					budget: 0,
					values: [],
					id: 'epic-' + epic.id,
				});
			}
		}
	}

	private _generateStoryAndSubtaskRowDefinitions(header: SPHeader, rows: SPRow[]): void {
		// Si on affiche les rows vide, on mets toutes les story et subtasks
		if (this.hasValue('emptyRows')) {
			for (const story of Array.from(this.storiesMap.values())) {
				const reportRow: SPRow = <SPRow>{
					type: 'story',
					rowDefinition: story,
					budget: story.budget || 0,
					values: [],
					id: 'story-' + story.id,
				};

				rows.push(reportRow);
			}

			if (this.hasValue('subtaskRows')) {
				for (const subtask of Array.from(this.subtaskMap.values())) {
					const reportRow: SPRow = <SPRow>{
						type: 'subtask',
						rowDefinition: subtask,
						budget: subtask.estimated || 0,
						values: [],
						id: 'subtask-' + subtask.id,
					};

					rows.push(reportRow);
				}
			}
		} else {
			// Sinon, on ne mets que celles concerné par les reports de la periode
			for (const headerUser of header.users) {
				if (headerUser.type !== 'user') {
					continue;
				}

				for (const report of headerUser.reports) {
					let reportRow: SPRow;

					let storyOrSubtask: IStoryDto | ISubtaskDto;
					// chercher la row sur laquel on va travailler en fonction de si c'est un subtaskreportdetail ou storyreportdetail
					if (report.type === 'subtask') {
						reportRow = rows.find(value => value.type === report.type
							&& value.rowDefinition.id === (<ISubtaskReportDetail>report).subtaskReport.subtaskId);
						storyOrSubtask = this.subtaskMap.get((<ISubtaskReportDetail>report).subtaskReport.subtaskId);
					} else if (report.type === 'story') {
						reportRow = rows.find(value => value.type === report.type
							&& value.rowDefinition.id === (<IStoryReportDetail>report).storyReport.storyId);
						storyOrSubtask = this.storiesMap.get((<IStoryReportDetail>report).storyReport.storyId);
					}

					// Si on a trouvé un report row, on continue
					if (reportRow) {
						continue;
					}

					// Si on a rien trouvé, on va créé la report row
					if (!reportRow && storyOrSubtask) {
						if ('budget' in storyOrSubtask) {
							reportRow = <SPRow>{
								type: report.type,
								rowDefinition: storyOrSubtask,
								budget: storyOrSubtask.budget || 0,
								values: [],
							};
						} else {
							reportRow = <SPRow>{
								type: report.type,
								rowDefinition: storyOrSubtask,
								budget: storyOrSubtask.estimated || 0,
								values: [],
							};
						}

						rows.push(reportRow);
					} else if (!storyOrSubtask) {
						this.warnings.push('Pas de ' + report.type + ' trouvé pour le report ' + report.id);
					}
				}
			}
		}
	}

	private _generateHeader(): SPHeader {
		const headerInfos: SPHeaderMonth[] = [];
		let periodBefore: SPHeaderPeriod = null;
		let index: number = 0;

		for (const periodReport of this.periodReports) {
			// if (!this.hasValue('emptyMonth') && (!periodReport.storyReports || !periodReport.storyReports.length)) {
			// 	continue;
			// }
			// Génération du SPHeaderMonth par rapport au mois et à l'année de la période
			const dateBeginMoment: moment.Moment = moment(periodReport.period.dateBegin);
			const mois: number = dateBeginMoment.get('month') + 1;
			const annee: number = dateBeginMoment.get('year');
			// Cherche dans l'array en cache
			let headerMonth: SPHeaderMonth = headerInfos.find(value => value.annee === annee && value.mois === mois);
			// Si on ne trouve rien, on créé une nouvelle entrée
			if (!headerMonth) {
				headerMonth = <SPHeaderMonth>{
					mois,
					annee,
					date: dateBeginMoment.toDate(),
					periods: [],
					colspan: 0,
				};
				headerInfos.push(headerMonth);
			}
			// Ajout de la période au mois
			const period: SPHeaderPeriod = <SPHeaderPeriod>{
				index,
				period: periodReport.period,
				periodReport: periodReport,
				dateBegin: moment(periodReport.period.dateBegin).toDate(),
				users: [],
				headerPeriodBefore: periodBefore,
				// Période pas validé admin, on affiche
				// Période validé admin sans afficher les validé, on affiche pas
				// Période validé admin et afficher les validé, on affiche
				displayed: (this.displayValuesFlat.indexOf('validatedAdminCols') !== -1 && periodReport.validatedAdmin) || !periodReport.validatedAdmin,
			};

			// Période pas affiché, dernière periode validé cp, afficher la dernière semaine
			if (!period.displayed
				&& this.periodReportToValidate.toDevalidateCP === periodReport.id
				&& this.displayValuesFlat.indexOf('keepOneValidatedWeek') !== -1) {
				period.displayed = true;
			}

			index++;

			periodBefore = period;
			// Ajout de la Conso si besoin
			period.users.push(<SPHeaderUser>{
				type: 'conso',
				period: period,
				order: 9996,
				displayed: this.hasValue('conso') && period.displayed,
			});
			// Ajout du RAE Ext si besoin
			period.users.push(<SPHeaderUser>{
				type: 'raeext',
				period: period,
				order: 9998,
				displayed: this.hasValue('raeext') && period.displayed,
			});
			// Ajout du Produit si besoin
			period.users.push(<SPHeaderUser>{
				type: 'produit',
				period: period,
				order: 9999,
				displayed: this.hasValue('produit') && period.displayed,
			});
			// Ajout de la Diff si besoin
			period.users.push(<SPHeaderUser>{
				type: 'diff',
				period: period,
				order: 10000,
				displayed: this.hasValue('diff') && period.displayed,
			});
			// Ajout de la période au mois
			headerMonth.periods.push(period);

			if (!periodReport.storyReports) {
				continue;
			}
			for (const storyReport of periodReport.storyReports) {
				if (storyReport.storyReportDetails) {
					const story: IStoryDto = this.storiesMap.get(storyReport.storyId);
					if (story) {
						for (const storyReportDetail of storyReport.storyReportDetails) {
							storyReportDetail.type = 'story';
							// Générer une colonne pour l'utilisateur qui est dans le report detail
							this._generateColForUserInReportDetail(period, storyReportDetail.jiraUserId, storyReportDetail, storyReport);
						}
					}
				}

				if (storyReport.subtaskReports && this.hasValue('subtaskRows')) {
					for (const substaskReport of storyReport.subtaskReports) {
						const subtask: ISubtaskDto = this.subtaskMap.get(substaskReport.subtaskId);

						if (!subtask || !substaskReport.subtaskReportDetails) {
							continue;
						}
						for (const subtaskReportDetail of substaskReport.subtaskReportDetails) {
							subtaskReportDetail.type = 'subtask';
							// Générer une colonne pour l'utilisateur qui est dans le report detail
							this._generateColForUserInReportDetail(period, subtaskReportDetail.jiraUserId, subtaskReportDetail, substaskReport);
						}
					}
				}
			}
		}

		// Créé et sort les arrays pour qu'elles soit niquels en sortie
		const periods: SPHeaderPeriod[] = [].concat(...headerInfos.map(value => value.periods));
		periods.forEach(value => value.users.sort(UtilsService.dynamicMultiSort('period.dateBegin', 'order')));
		const periodUser: SPHeaderUser[] = [].concat(...periods.map(value => value.users));

		// Calcul des colspan
		periods.forEach(value => value.colspan = value.users.filter(value1 => value1.displayed).length || 1);
		headerInfos.forEach(value => value.colspan = value.periods
			.filter(period => period.displayed)
			.map(value1 => value1.colspan)
			.reduce((acc, sizeColspan) => acc + sizeColspan, 0));

		// Set le first et last de chaque periode pour chaque mois
		headerInfos.forEach(value => {
			if (value.periods?.length > 0) {
				// Récupère et set le premier qui est displayed
				for (let i: number = 0; i < value.periods.length; i++) {
					if (value.periods[i].displayed) {
						value.periods[i].first = true;
						break;
					}
				}

				// Récupère et set le dernier qui est displayed
				for (let i: number = value.periods.length - 1; i >= 0; i--) {
					if (value.periods[i].displayed) {
						value.periods[i].last = true;
						break;
					}
				}
			}
		});

		// Set la dernière période comme la dernière de la table
		headerInfos[headerInfos.length - 1].periods[headerInfos[headerInfos.length - 1].periods.length - 1].lastOfTable = true;

		// Set la dernière période validé CP a dernière validé CP de la table
		const countOfValidatedCP: number = periods.filter(value => value.periodReport.validatedCP).length - 1;
		if (countOfValidatedCP !== -1) {
			periods[countOfValidatedCP].lastValidatedCP = true;
		}

		// Set le first et last des user pour chaque periode
		periods.forEach(value => {
			if (value.users?.length) {
				// Récupère et set le first qui est displayed
				for (let i: number = 0; i < value.users.length; i++) {
					if (value.users[i].displayed) {
						value.users[i].first = true;
						break;
					}
				}

				// Récupère et set le dernier qui est displayed
				for (let i: number = value.users.length - 1; i >= 0; i--) {
					if (value.users[i].displayed) {
						value.users[i].last = true;
						break;
					}
				}
			}
		});

		// Faire une passe décroissante pour remplir le next
		for (let i: number = periods.length - 1; i >= 0; i--) {
			const period: SPHeaderPeriod = periods[i];
			if (i + 1 > periods.length - 1) {
				continue;
			}
			period.headerPeriodAfter = periods[i + 1];
		}

		// Rempli l'index pour chaque header user
		periodUser.forEach((value, i) => value.index = i);

		// Retourne un objet dans lequel
		return <SPHeader>{
			months: headerInfos,
			periods: periods,
			users: periodUser,
		};
	}

	// endregion

	private _generateColForUserInReportDetail(period: SPHeaderPeriod,
											  jiraUserId: string,
											  reportDetail: IReportDetail,
											  parent: IStoryReportDto | ISubtaskReportDto): void {
		// Chercher le user dans la liste des users de la sp period
		let userInDetail: SPHeaderUser;
		period.users.forEach(value => {
			if (value.type === 'user' && value.user === undefined) {
				this._snackBar.open(this._translator.instant('PROJECT.DETAIL.SYNC_JIRA_USER'), '', {
					panelClass: 'error',
					duration: 10000,
				});
				return;
			}
			if (value.type === 'user' && value.user.jiraId === jiraUserId) {
				userInDetail = value;
				return;
			}
		});

		// Si pas présent, on le rajoute à la liste des user dans la période du mois en cours
		if (!userInDetail) {
			userInDetail = <SPHeaderUser>{
				type: 'user',
				user: this.users.find(value => value.jiraId === jiraUserId),
				order: period.users.length,
				period: period,
				reports: [],
				displayed: this.hasValue('individualImputations') && period.displayed,
			};
			period.users.push(userInDetail);
		}
		if (reportDetail.type === 'subtask') {
			(<ISubtaskReportDetail>reportDetail).subtaskReport = <ISubtaskReportDto>parent;
		} else if (reportDetail.type === 'story') {
			(<IStoryReportDetail>reportDetail).storyReport = <IStoryReportDto>parent;
		}
		userInDetail.reports.push(reportDetail);
	}

	private _getDateDifferenceInSeconds(date1: Date, date2: Date): number {
		const diffInMs: number = Math.abs(date2.getTime() - date1.getTime());
		return diffInMs / 1000;
	}
}
