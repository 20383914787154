<mat-form-field *ngIf="formField && !simpleInput">
	<mat-label>{{placeholder}}</mat-label>
	<input matInput [type]="type"
		   [(ngModel)]="displayedValue"
		   [ngModelOptions]="{standalone: true}"
		   (change)="formatValue()"
		   (input)="formatValue()"
		   (focus)="formatValue()"
		   [required]="required"
		   [disabled]="readonly"
		   appNumberInputMin [customMin]="customMin"
		   #numberInput>
	<mat-error>{{'FORM.ERROR.REQUIRED' | translate}}</mat-error>
</mat-form-field>

<input *ngIf="formField && simpleInput" [type]="type"
	   [placeholder]="placeholder"
	   [(ngModel)]="displayedValue"
	   [ngModelOptions]="{standalone: true}"
	   (change)="formatValue()"
	   (input)="formatValue()"
	   [required]="required" matInput
	   appNumberInputMin [customMin]="customMin"
	   #numberInput
	   [disabled]="readonly">

<div *ngIf="!formField">{{displayedValue}}</div>
