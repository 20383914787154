import {Pipe, PipeTransform} from '@angular/core';
import {PermissionEnum} from '../../core/business/service/user-base/user-base.dto';

@Pipe({
  name: 'role',
  standalone: true,
})
export class RolePipe implements PipeTransform {
  transform(role: PermissionEnum, roles: (keyof typeof PermissionEnum | PermissionEnum)[], permissions: string[] = [], details: string[] = []): boolean {
	  if (permissions && permissions.length) {
		  return (roles.includes(role) && permissions.some(p => details.includes(p))) || role == "ADMIN";
	  } else {
		  return roles.includes(role);
	  }
  }
}

@Pipe({
  name: 'admin',
  standalone: true,
})
export class AdminRolePipe implements PipeTransform {
  transform(role: PermissionEnum, permissions: string[] = [], details: string[] = []): boolean {
    return new RolePipe().transform(role, [PermissionEnum.ADMIN], permissions, details);
  }
}

@Pipe({
  name: 'client',
  standalone: true,
})
export class ClientRolePipe implements PipeTransform {
  transform(role: PermissionEnum, permissions: string[] = [], details: string[] = []): boolean {
    return new RolePipe().transform(role, [PermissionEnum.CLIENT], permissions, details);
  }
}
@Pipe({
  name: 'user',
  standalone: true,
})
export class UserRolePipe implements PipeTransform {
  transform(role: PermissionEnum, permissions: string[] = [], details: string[] = []): boolean {
    return new RolePipe().transform(role, [PermissionEnum.USER], permissions, details);
  }
}
