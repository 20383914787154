import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {FormsModule} from '@angular/forms';
import {HeaderService} from './header.service';
import {MatInputModule} from '@angular/material/input';

@NgModule({
	declarations: [HeaderComponent],
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule
	],
	providers: [HeaderService],
	exports: [HeaderComponent]
})
export class HeaderModule {
}
