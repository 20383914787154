import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {UtilsService} from '../../../utils/service/utils.service';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {IClientDto} from '../../../core/business/service/client/client.dto';

@Component({
	selector: 'app-client-select',
	templateUrl: './client-select.component.html',
	styleUrls: ['./client-select.component.scss'],
})
export class ClientSelectComponent implements AfterViewInit {

	public selectClientController: FormControl = new FormControl();
	public clientfiltered: IClientDto[] = [];

	private _sortClients: IClientDto[] = [];

	@Input() client: IClientDto;
	@Output() clientChange: EventEmitter<IClientDto> = new EventEmitter<IClientDto>();

	@Input() set clients(clients: IClientDto[]) {
		if (clients) {
			this._sortClients = clients.sort(UtilsService.dynamicMultiSort('name'));
			this.clientfiltered = this._sortClients.slice();
			if (this.client) {
				this.selectClientController.patchValue(this.client);
			}
		}
	}

	constructor() {
	}

	ngAfterViewInit(): void {
		this.selectClientController.valueChanges
			.pipe(
				debounceTime(250),
				distinctUntilChanged())
			.subscribe((client: any) => {
				if (typeof client === 'string') { // on est en mode recherche
					if (client) {
						this.clientfiltered = this.clientFilter(client);
					} else {
						// le filtre de recherche est vide,
						this.client = null;
						this.clientChange.emit(null);
						this.clientfiltered = this._sortClients.slice();
					}
				} else { // on a sélectionné un client
					this.client = client;
					this.clientChange.emit(client);
					this.clientfiltered = this._sortClients.slice();
				}
			});
	}

	clearFilter(): void {
		this.client = null;
		this.clientChange.emit(null);
		this.selectClientController.patchValue(null);
	}

	clientFilter(value: string): IClientDto[] {
		const filterValue: string = value.toLowerCase();
		return this._sortClients.filter(client => client.name.toLowerCase().startsWith(filterValue));
	}

	displaySelectedClient(client: IClientDto): string {
		return client ? client.name : '';
	}
}
