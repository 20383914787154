import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {filter, firstValueFrom, forkJoin, merge, mergeMap, Observable, takeWhile} from 'rxjs';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {UserBaseService} from '../core/business/service/user-base/user-base.service';
import {tap} from 'rxjs/operators';
import {UserBaseDto} from '../core/business/service/user-base/user-base.dto';

@Injectable()
export class AppInitializer {

	constructor(private _translator: TranslateService, private keycloak: KeycloakService, private userService: UserBaseService) {
	}

	async init(): Promise<any> {
		console.info('Console de développement OGDP.');
		this._translator.setDefaultLang('fr');

		const getUserObs: Observable<UserBaseDto> = this.keycloak.keycloakEvents$.pipe(
			takeWhile(v => v.type !== KeycloakEventType.OnReady, true),
			filter(v => v.type === KeycloakEventType.OnReady),
			mergeMap(() => this.userService.getUserFromEmail()),
		);

		return firstValueFrom(forkJoin([this._translator.use('fr'), getUserObs]));
	}
}
