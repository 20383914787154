import {IAvantVenteDto} from '../avant-vente.dto';
import {ICategorieDto} from '../categorie/categorie.dto';
import {IRepartitionDto} from '../repartition/repartition.dto';
import {IChargeAnnexeDto} from '../charge-annexe/charge-annexe.dto';

export interface IChiffrageDto {
	id: number;
	numero: number;
	tjm: number;
	pourcentageGestionProjet: number;
	pourcentageITTest: number;
	chargeTotal: number;
	type: ChiffrageTypeEnum;
	dateCreation: Date;
	dateSoumission: Date;
	avantVenteId: number;
	avantVente: IAvantVenteDto;
	categories: ICategorieDto[];
	repartitions: IRepartitionDto[];
	chargeAnnexes: IChargeAnnexeDto[];
}

export enum ChiffrageTypeEnum {
	REEL = 'REEL',
	PERCENT = 'PERCENT',
	MINMAX = 'MINMAX'
}
