import {IUserBaseDto} from '../user-base/user-base.dto';
import {IClientDto} from '../client/client.dto';
import {ILotDto} from '../lot/lot.dto';
import {IEpicDto} from '../epic/epic.dto';
import {IPeriodReportDto} from '../report/period-report/period-report.dto';
import {IProjectAlertDto} from '../project-alert/project-alert.dto';
import {ISprintDto} from '../sprint/sprint.dto';
import {IReportDto} from '../v1-report/v1-report.dto';
import {ICompanyDto} from '../company/company.dto';

export class GestionDispParams {
	displayParams: string[];
	statutLot: string[];
}

export interface IProjectDto {
	id: number;
	name: string;
	jiraIntId: string;
	jiraExtId: string;
	budget: number;
	description: string;
	spentSum: number;
	remainingEXTSum: number;
	status: ProjectStatusEnum;
	creationDate: Date;
	bitbucketRepositories: string[];
	lastJIRASyncDate: Date;
	lastTempoSyncDate: Date;
	lastExportDate: Date;
	followUpReminder: boolean;
	displayParameters: string[];
	gestionParameters: GestionDispParams;
	boardId: number;
	sprints: ISprintDto[];
	lots: ILotDto[];
	epics: IEpicDto[];
	reports: IReportDto[];
	period_report: IPeriodReportDto[];
	usersCp: IUserBaseDto[];
	clientId: number;
	client: IClientDto;
	companyId: number;
	company: ICompanyDto;
	open: boolean;
	projectAlert: IProjectAlertDto[];
	time: number;
	v1Report: boolean;
	// view values only
	periodSpentSum?: number;
	periodProdSum?: number;
}

export class ProjectDto implements IProjectDto {
	id: number;
	name: string;
	jiraIntId: string;
	jiraExtId: string;
	budget: number;
	description: string;
	spentSum: number;
	remainingEXTSum: number;
	status: ProjectStatusEnum;
	creationDate: Date;
	bitbucketRepositories: string[];
	lastJIRASyncDate: Date;
	lastTempoSyncDate: Date;
	lastExportDate: Date;
	followUpReminder: boolean;
	displayParameters: string[];
	gestionParameters: GestionDispParams;
	boardId: number;
	sprints: ISprintDto[];
	lots: ILotDto[];
	epics: IEpicDto[];
	reports: IReportDto[];
	period_report: IPeriodReportDto[];
	usersCp: IUserBaseDto[];
	clientId: number;
	client: IClientDto;
	companyId: number;
	company: ICompanyDto;
	open: boolean;
	projectAlert: IProjectAlertDto[];
	time: number;
	v1Report: boolean;
}

/********************* Project tatus ENUM *******************/

export enum ProjectStatusEnum {
	OPEN = 'OPEN',
	ARCHIVE = 'ARCHIVE'
}
