<app-header [displayBreadCrumb]="('SUIVI.HEADER' | translate) + (project ? ' → ' + project.name : '')">
	<ng-container #headerElementsContainer>
		<div class="center-list">
			<a [routerLink]="['/suivi/' + (project && project.jiraIntId?  project.jiraIntId : '')]"
			   class="nav-proj active">{{'NAVIGATION.SUIVI' | translate}}</a>
			<a [routerLink]="['/gestion/' + (project && project.id ? project.id : '')]"
			   class="nav-proj">{{'NAVIGATION.GESTION' | translate}}</a>
			<a [routerLink]="['/v1-report/' +  (project && project.id ? project.id : '')]"
			   *ngIf="role | role: ['ADMIN', 'USER']: permissions: ['v1-report', 'raf']"
			   class="nav-proj">{{'NAVIGATION.RAPPORT' | translate}}</a>
		</div>

		<div class="position-relative ms-4" style="height: 66px">
			<mat-label class="floating-label">{{'SUIVI.FILTER.PERIOD' | translate}}</mat-label>

			<div class="d-flex align-items-center">
				<button [disabled]="periodStart.isSame(limitStart, 'month')" (click)="onMonthButtonClick(true, true)"
						class="month-arrow-btn" mat-icon-button>
					<mat-icon>keyboard_double_arrow_left</mat-icon>
				</button>
				<button [disabled]="periodStart.isSame(limitStart, 'month')" (click)="onMonthButtonClick(true, false)"
						class="month-arrow-btn" mat-icon-button>
					<mat-icon>keyboard_arrow_left</mat-icon>
				</button>
				<mat-form-field class="filter-calendar">
					<input [(ngModel)]="periodStart" [matDatepicker]="periodStartDP"
						   [max]="periodEnd || limitEnd" [min]="limitStart" matInput>
					<mat-datepicker-toggle [for]="periodStartDP" matSuffix></mat-datepicker-toggle>
					<mat-datepicker (monthSelected)="onMonthSelected($event, periodStartDP, 'start')" #periodStartDP
									startView="year"></mat-datepicker>
				</mat-form-field>
				<span class="mx-2">-</span>
				<mat-form-field class="filter-calendar">
					<input [(ngModel)]="periodEnd" [matDatepicker]="periodEndDP" [max]="limitEnd"
						   [min]="periodStart" matInput>
					<mat-datepicker-toggle [for]="periodEndDP" matSuffix></mat-datepicker-toggle>
					<mat-datepicker (monthSelected)="onMonthSelected($event, periodEndDP, 'end')" #periodEndDP
									startView="year">
					</mat-datepicker>
				</mat-form-field>
				<button [disabled]="periodEnd.isSame(limitEnd, 'month')" (click)="onMonthButtonClick(false, false)"
						class="month-arrow-btn" mat-icon-button>
					<mat-icon>keyboard_arrow_right</mat-icon>
				</button>
				<button [disabled]="periodEnd.isSame(limitEnd, 'month')" (click)="onMonthButtonClick(false, true)"
						class="month-arrow-btn" mat-icon-button>
					<mat-icon>keyboard_double_arrow_right</mat-icon>
				</button>
			</div>
		</div>

		<mat-form-field *ngIf="role | role: ['ADMIN', 'USER']">
			<mat-label>{{'SUIVI.FILTER.DISPLAY_MATRIX.TITLE' | translate}}</mat-label>
			<mat-select [hideSingleSelectionIndicator]="true" [value]="displayMatrixValue"
						(selectionChange)="changeViewMatrix($event)">
				<mat-option [title]="'SUIVI.FILTER.DISPLAY_MATRIX.' + display.name.toUpperCase() | translate"
							[value]="display" *ngFor="let display of displayMatrix">
					{{'SUIVI.FILTER.DISPLAY_MATRIX.' + display.name.toUpperCase() | translate}}
				</mat-option>

				<mat-option [title]="'SUIVI.FILTER.DISPLAY_MATRIX.CUSTOM' | translate"
							*ngIf="displayMatrixValue === 'custom'" value="custom">
					{{'SUIVI.FILTER.DISPLAY_MATRIX.CUSTOM' | translate}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field *ngIf="role | role: ['ADMIN', 'USER']" class="large-select">
			<mat-label>{{'SUIVI.FILTER.DISPLAY' | translate}}</mat-label>
			<mat-select [value]="displayValuesFlat" (selectionChange)="changeViewSelection($event)" multiple>
				<mat-optgroup [label]="'SUIVI.FILTER.SELECT.GROUP.' + group.toUpperCase() | translate"
							  *ngFor="let group of displayValues.keys()">
					<mat-option [title]="'SUIVI.FILTER.SELECT.VALUE.' + viewValue.toUpperCase() | translate"
								[value]="viewValue" *ngFor="let viewValue of displayValues.get(group).values()">
						{{'SUIVI.FILTER.SELECT.VALUE.' + viewValue.toUpperCase() | translate}}
					</mat-option>
				</mat-optgroup>
			</mat-select>
		</mat-form-field>

		<div class="text-center">
			<mat-checkbox [checked]="showFloatingTable" (change)="showHideFloatingTable($event.checked)"
						  class="d-block">{{'SUIVI.FILTER.DISPLAY_VALIDATED_TOTAL' | translate}}</mat-checkbox>
			<button (click)="openLotSelectorDialog()" class="its-button" mat-flat-button>
				{{'SUIVI.FILTER.LOTS.SELECTION' | translate}}
			</button>
		</div>

		<div *ngIf="userIsAdminOrCP" class="actions">
			<button (click)="exportSuivi()" class="its-button" mat-flat-button>
				{{'APP.EXPORT' | translate}}
			</button>
			<button (click)="save(true)" class="its-button" mat-flat-button>
				{{'APP.SAVE' | translate}}
			</button>
			<button (click)="sync()" class="its-button pe-0" mat-flat-button>
				{{'APP.SYNC' | translate}}

				<button [matMenuTriggerFor]="syncMenu" (click)="$event.stopPropagation()" class="menu-arrow"
						mat-flat-button matSuffix>
					<div class="d-flex h-100 align-items-center">
						<span class="material-icons">keyboard_arrow_down</span>
					</div>
				</button>

				<mat-menu #syncMenu="matMenu" class="itsf-override">
					<button disabled mat-menu-item>{{'SUIVI.SYNC_ALL' | translate}}</button>
				</mat-menu>
			</button>
			<div *ngIf="project?.lastTempoSyncDate !== null" class="synchro-info">
				{{'PROJECT.SYNCHRO' | translate}} {{project.lastTempoSyncDate | date: 'dd/MM/yyyy HH:mm:ss'}}
			</div>
		</div>
	</ng-container>
</app-header>

<div class="position-relative">
	<div *ngIf="warnings.length > 0" class="alert alert-warning" role="alert">
		<div class="text-uppercase fw-bold">{{'APP.WARNING' | translate}}</div>
		<div *ngFor="let warning of warnings">{{warning}}</div>
	</div>

	<div *ngIf="!tableData" class="fw-bold d-flex justify-content-center p-3">
		{{'APP.NO_DATA' | translate}}
	</div>

	<table *ngIf="project && tableData">
		<thead>
		<tr>
			<th [style.top.px]="headerElementsContainer | stickTopHeight"
				class="sticky sticky-left-first-col sticky-over-other border-b-blue-2 project-info-wrapper"
				id="info-col" rowspan="3">
				<div class="full-cell d-flex">
					<div class="project-logo">
						<div>
							<img [alt]="'APP.LOGO' | translate" [src]="project.client.logo | safizer"
								 *ngIf="project.client?.logo" class="logo">
						</div>
					</div>
					<div class="project-info d-flex flex-column justify-content-between">
						<div class="position-relative">
							<div class="project-name d-flex">
								{{project.name}}
								<div (click)="openDialogProjectAlert()" class="d-flex flex-row">
									<app-project-messages [numberAlerts]="projectAlert"></app-project-messages>
								</div>
							</div>
							<div>
								<a [href]="configurationUrlJira.value + project.jiraIntId" class="table-grey"
								   target="_blank">{{project.jiraIntId}}</a>
								<span class="table-grey"> | </span>
								<a [href]="configurationUrlJira.value + project.jiraExtId" class="table-grey"
								   target="_blank">{{project.jiraExtId}}</a>
							</div>
						</div>
						<div class="d-flex align-items-end justify-content-between project-stats">
							<div>
								<div>Entreprise</div>
								<div class="color-grey">{{project.company?.name || ''}}</div>
							</div>
							<div>
								<div>{{'PROJECT.BUDGET' | translate}}</div>
								<div class="color-grey">{{project.budget || 0 | number:'1.0-3'}}</div>
							</div>
							<div>
								<div>{{'PROJECT.RAE' | translate}}</div>
								<div class="color-grey">{{project.remainingEXTSum || 0}}</div>
							</div>
							<div>
								<div>{{'PROJECT.STATUS.TITLE' | translate}}</div>
								<div class="color-grey">{{'PROJECT.STATUS.' + project.status | translate}}</div>
							</div>
						</div>
					</div>
					<div class="project-btn">
						<button [matMenuTriggerFor]="menu" class="more-button" color="warn" mat-icon-button>
							<mat-icon>more_horiz</mat-icon>
						</button>
						<mat-menu #menu="matMenu" class="itsf-override">
							<button mat-menu-item routerLink="/gestion/{{project.id}}">
								<span class="sp-edit gradient"></span>
								<span class="ms-2">{{'SUIVI.TABLE.HEADER.PROJECT' | translate}}</span>
							</button>
						</mat-menu>
					</div>
				</div>
			</th>
			<th [style.top.px]="headerElementsContainer | stickTopHeight"
				class="sticky sticky-left-second-col align-bottom sticky-over-other budget-column border-b-blue-2"
				id="budget-col" rowspan="3">
				<span class="text-vertical color-blue-navy-2 text-uppercase fw-bold">
					{{'SUIVI.TABLE.BUDGET' | translate}}
				</span>
			</th>
			<th [colSpan]="mois.colspan" [style.top.px]="headerElementsContainer | stickTopHeight"
				(click)="getCellInfo(mois)"
				*ngFor="let mois of tableData.header.months"
				class="sticky ps-3 bg-blue-navy-2 color-white text-uppercase border-r-blue-4">
				<span class="sticky-left-third-col">{{mois.date | date: 'MMMM yyyy'}}</span>
			</th>
			<th [colSpan]="displayValuesFlat.indexOf('diffTotal') === -1 ? 3 : 4"
				[style.top.px]="headerElementsContainer | stickTopHeight"
				*ngIf="displayValuesFlat.indexOf('total-period') !== -1"
				class="color-blue-navy-2 text-uppercase fw-bold sticky text-center total-header">
				<span>{{'SUIVI.TABLE.TOTAL_PERIOD' | translate}}</span>
			</th>
			<th [colSpan]="displayValuesFlat.indexOf('diffTotal') === -1 ? 4 : 5"
				[style.top.px]="headerElementsContainer | stickTopHeight"
				class="color-blue-navy-2 text-uppercase fw-bold sticky text-center total-header">
				<span>{{'SUIVI.TABLE.TOTAL_PROJECT' | translate}}</span>
			</th>
		</tr>
		<tr>
			<th [appCellClass]="period" [bgGreen]="period.periodReport.validatedAdmin"
				[borderRightLight]="!period.last" [borderRight]="period.last"
				[class.color-blue-navy-2-65]="!(period.periodReport.validatedAdmin || period.periodReport.validatedCP)"
				[class.validated-cp]="period.periodReport.validatedCP"
				[colSpan]="period.colspan"
				[id]="'period-' + period.index" [style.top.px]="headerElementsContainer | stickTopHeight: 1"
				(click)="getCellInfo(period)"
				*ngFor="let period of tableData.header.periods"
				class="sticky text-center color-white-65 border-b-grey-1 period">
				<span *ngIf="period.periodReport.hasAlert" class="sp-iconmonstr-triangle-1 period-has-alert"></span>

				<span [class.color-white]="period.periodReport.validatedAdmin || period.periodReport.validatedCP"
					  class="color-blue-navy-2 fw-bold">
					{{'SUIVI.TABLE.WEEK_LETTER' | translate}}{{period.period.week}}
				</span>
				<span *ngIf="period.period.index !== 0">
					({{period.period.index}})
				</span>
				<span>
					{{period.period.dateBegin | date: 'dd'}}
				</span>
				<mat-icon class="header-arrow-icon">arrow_right_alt</mat-icon>
				<span>
					{{period.period.dateEnd | date: 'dd'}}
				</span>

				<!-- Affiché si période à valider CP ou période déjà validé CP -->
				<button [matMenuTriggerFor]="menu"
						*ngIf="period.periodReport.id === periodReportToValidate?.toValidateCP
						|| (period.periodReport.id === periodReportToValidate?.toDevalidateCP && !period.periodReport.validatedAdmin)
						|| (period.periodReport.id === periodReportToValidate?.toValidateAdmin && period.periodReport.validatedCP)
						|| period.periodReport.id === periodReportToValidate?.toDevalidateAdmin
						|| period.periodReport.hasAlert && period.periodReport.validatedAdmin
						|| !(period.periodReport.validatedAdmin) && (role | admin)
						|| !(period.periodReport.validatedCP)" class="caret-down-btn"
						mat-icon-button>
					<mat-icon [class.color-icon]="
					(!(period.periodReport.validatedAdmin) && (role | admin) && periodReportToValidate.toValidateAdmin !== period.periodReport.id)
					&& (!(period.periodReport.validatedCP) && periodReportToValidate.toValidateCP !== period.periodReport.id)"
					>arrow_drop_down
					</mat-icon>
				</button>
				<mat-menu #menu="matMenu" class="itsf-override">
					<button (click)="validatePeriodCp(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toValidateCP"
							mat-menu-item>
						<span [matTooltipDisabled]="!checkIfProjectHasErrors()"
							  [matTooltip]="'ALERT.PROJECT' | translate">
							{{'SUIVI.TABLE.BUTTON.VALIDATE_PERIOD_CP' | translate}}
						</span>
					</button>
					<button (click)="validatePeriodCp(period, true)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toValidateCP
							&& period.periodReport.id === periodReportToValidate?.toValidateAdmin
							&& (role | admin)"
							mat-menu-item>
						<span [matTooltipDisabled]="!checkIfProjectHasErrors()"
							  [matTooltip]="'ALERT.PROJECT' | translate">
							{{'SUIVI.TABLE.BUTTON.VALIDATE_PERIOD_CP_ADMIN' | translate}}
						</span>
					</button>
					<button (click)="reinitRAE(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toValidateCP
							 && period.headerPeriodBefore"
							mat-menu-item>
						<span>{{'SUIVI.TABLE.BUTTON.REINIT_RAE' | translate}}</span>
					</button>
					<button (click)="unvalidatePeriodCP(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toDevalidateCP
							&& period.periodReport.id !== periodReportToValidate?.toValidateCP
							 && !period.periodReport.validatedAdmin"
							mat-menu-item>
						<span>{{'SUIVI.TABLE.BUTTON.UNVALIDATE_PERIOD_CP' | translate}}</span>
					</button>
					<button (click)="validatePeriodAdmin(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toValidateAdmin
							&& period.periodReport.validatedCP
							&& (role | admin)"
							mat-menu-item>
						<span [matTooltipDisabled]="!checkIfProjectHasErrors()"
							  [matTooltip]="'ALERT.PROJECT' | translate">
							{{'SUIVI.TABLE.BUTTON.VALIDATE_PERIOD_ADMIN' | translate}}
						</span>
					</button>
					<button (click)="unvalidatePeriodAdmin(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toDevalidateAdmin
							&& period.periodReport.id !== periodReportToValidate?.toValidateAdmin
							&& (role | admin)"
							mat-menu-item>
						<span>{{'SUIVI.TABLE.BUTTON.UNVALIDATE_PERIOD_ADMIN' | translate}}</span>
					</button>
					<button (click)="unvalidatePeriodCP(period, true)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toDevalidateAdmin
							&& period.periodReport.id !== periodReportToValidate?.toValidateAdmin
							&& (!period.headerPeriodAfter || !period.headerPeriodAfter.periodReport.validatedCP)
							&& (role | admin)"
							mat-menu-item>
						<span>{{'SUIVI.TABLE.BUTTON.UNVALIDATE_PERIOD_CP_ADMIN' | translate}}</span>
					</button>
					<button (click)="ignorePeriodErrors(period)"
							*ngIf="period.periodReport.hasAlert && period.periodReport.validatedAdmin"
							mat-menu-item>
						<span [matTooltip]="'ALERT.IGNORE' | translate">
							{{'SUIVI.TABLE.BUTTON.IGNORE_ERROR' | translate}}
						</span>
					</button>
					<button (click)="reportConsoToRaeExtForAll(period)"
							*ngIf="period.periodReport.id === periodReportToValidate?.toValidateCP"
							mat-menu-item>
						<span>
							{{'SUIVI.TABLE.BUTTON.PRODUIT_CONSO' | translate}}
						</span>
					</button>
					<button (click)="validateAllPeriodBefore(period)"
							*ngIf="!(period.periodReport.validatedCP)
							&& periodReportToValidate.toValidateCP !== period.periodReport.id"
							mat-menu-item>
						<span class="sp-iconmonstr-triangle-1 warn-icon"></span>
						<span [matTooltipDisabled]="!checkIfProjectHasErrors()"
							  [matTooltip]="'ALERT.PROJECT' | translate">
							  {{'SUIVI.TABLE.BUTTON.VALIDATE_PERIOD_BEFORE_CP' | translate}}
						</span>
					</button>
					<button (click)="validateAllPeriodBefore(period, false)"
							*ngIf="!(period.periodReport.validatedAdmin) && (role | admin)
								&& periodReportToValidate.toValidateAdmin !== period.periodReport.id"
							mat-menu-item>
						<span class="sp-iconmonstr-triangle-1 warn-icon"></span>
						<span [matTooltipDisabled]="!checkIfProjectHasErrors()"
							  [matTooltip]="'ALERT.PROJECT' | translate">
								{{'SUIVI.TABLE.BUTTON.VALIDATE_PERIOD_BEFORE_ADMIN' | translate}}
						</span>
					</button>
				</mat-menu>
			</th>
			<!-- Total période -->
			<ng-container *ngIf="displayValuesFlat | includes: 'total-period'">
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.CONSO' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom border-l-blue-2 border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.CONSO' | translate}}
				</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.RAEEXT' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.RAE' | translate}}
				</span>
				</th>
				<th [class.border-r-blue-4]="!(displayValuesFlat | includes: 'diffTotal')"
					[matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.PRODUIT' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.PRODUIT' | translate}}
				</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.DIFFERENCE' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					*ngIf="displayValuesFlat.indexOf('diffTotal') !== -1"
					class="sticky align-bottom total-subheader border-b-blue-2 border-r-blue-4" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.DIFFERENCE' | translate}}
					</span>
				</th>
			</ng-container>

			<!-- Total projet -->
			<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.CONSO' | translate"
				[style.top.px]="headerElementsContainer | stickTopHeight: 1"
				class="sticky align-bottom border-l-blue-2 border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.CONSO' | translate}}
				</span>
			</th>
			<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.RAEEXT' | translate"
				[style.top.px]="headerElementsContainer | stickTopHeight: 1"
				class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.RAE' | translate}}
				</span>
			</th>
			<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.PRODUIT' | translate"
				[style.top.px]="headerElementsContainer | stickTopHeight: 1"
				class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.PRODUIT' | translate}}
				</span>
			</th>
			<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.DIFFERENCE' | translate"
				[style.top.px]="headerElementsContainer | stickTopHeight: 1"
				*ngIf="displayValuesFlat.indexOf('diffTotal') !== -1"
				class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.DIFFERENCE' | translate}}
				</span>
			</th>
			<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.AVANCEMENT' | translate"
				[style.top.px]="headerElementsContainer | stickTopHeight: 1"
				class="sticky align-bottom total-subheader border-r-blue-4 border-b-blue-2" rowspan="2">
				<span class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.AVANCEMENT' | translate}}
				</span>
			</th>
		</tr>
		<tr>
			<th [appCellClass]="user"
				[bgBlueLight]="user.type !== 'user' || (user.type === 'raeext' && user.period.periodReport.validatedAdmin)"
				[borderRightLight]="user.last && !user.period.last" [borderRight]="user.last && user.period.last"
				[style.top.px]="headerElementsContainer | stickTopHeight: 2"
				(click)="getCellInfo(user)"
				*ngFor="let user of tableData.header.users"
				class="align-bottom sticky border-r-grey-1 border-b-blue-2">
				<span [appCellClass]
					  [colorGreen]="user.period.periodReport.validatedAdmin"
					  [jiraSubtask]="user.period.periodReport.validatedCP"
					  *ngIf="user.type === 'user'"
					  class="text-vertical color-blue-navy-2 fw-bold">
					{{user.user?.trigramme}}
				</span>
				<span [appCellClass]
					  [colorGreen]="user.period.periodReport.validatedAdmin"
					  [jiraSubtask]="user.period.periodReport.validatedCP"
					  *ngIf="user.type === 'conso'"
					  class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.CONSO' | translate}}
				</span>
				<span [appCellClass]
					  [colorGreen]="user.period.periodReport.validatedAdmin"
					  [jiraSubtask]="user.period.periodReport.validatedCP"
					  *ngIf="user.type === 'raeext'"
					  class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.RAE' | translate}}
				</span>
				<span [appCellClass]
					  [colorGreen]="user.period.periodReport.validatedAdmin"
					  [jiraSubtask]="user.period.periodReport.validatedCP"
					  *ngIf="user.type === 'produit'"
					  class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.PRODUIT' | translate}}
				</span>
				<span [appCellClass]
					  [colorGreen]="user.period.periodReport.validatedAdmin"
					  [jiraSubtask]="user.period.periodReport.validatedCP"
					  *ngIf="user.type === 'diff'"
					  class="text-vertical color-blue-navy-2">
					{{'SUIVI.TABLE.DIFF' | translate}}
				</span>
			</th>
		</tr>
		</thead>
		<tbody>
		<tr [appRowClass]="row.type"
			[hidden]="(row.lotParent && !row.lotParent.expanded) || (row.type === 'lot' && !row.visible) || (row.lotParent && !row.lotParent.visible)"
			*ngFor="let row of tableData.body; let rowIndex = index">
			<th class="sticky-left-first-col border-b-grey-1">
				<div class="d-flex align-items-center first-col">
					<!-- Picto epic, story, subtask -->
					<div [ngSwitch]="row.type" *ngIf="row.type !== 'lot'">
						<div *ngSwitchCase="'story'">
							<div
								[ngClass]="'sp-' + row.type.toUpperCase() + '-' + row.rowDefinition.type.toUpperCase()">
								<i class="path1"></i>
								<i class="path2"></i>
							</div>
						</div>
						<div *ngSwitchDefault>
							<div [ngClass]="'sp-' + row.type.toUpperCase()">
								<i class="path1"></i>
								<i class="path2"></i>
							</div>
						</div>
					</div>
					<!-- Fin picto -->

					<div [ngSwitch]="row.type" *ngIf="row.type !== 'lot'" class="row-definition-jira">
						<div *ngSwitchCase="'story'">
							<a [href]="row.urlIssueJiraId" tabindex="-1"
							   target="_blank">{{row.rowDefinition.jiraIntId}}</a>
						</div>
						<div *ngSwitchCase="'subtask'">
							<a [href]="row.urlIssueJiraId" tabindex="-1"
							   target="_blank">{{row.rowDefinition.jiraIntId}}</a>
						</div>
						<div *ngSwitchCase="'epic'">
							<a [href]="row.urlIssueJiraId" tabindex="-1"
							   target="_blank">{{row.rowDefinition.jiraIntId}}</a>
						</div>
						<div *ngSwitchDefault>

						</div>
					</div>
					<div [matTooltipDisabled]="element.offsetWidth === element.scrollWidth"
						 [matTooltip]="row.rowDefinition.name" #element
						 class="row-definition-name d-inline-block">
						<div
							[class.rotate-180]="!row.expanded"
							(click)="$event.stopPropagation(); changeIsExpendState(row);"
							*ngIf="row.type === 'lot'"
							class="sp-up-arrow up-arrow p-2 d-inline-block cursor-pointer">
						</div>

						<div *ngIf="row.type === 'lot'; then lotTitle else otherTitle"></div>
						<ng-template #lotTitle>
							<span>{{row.rowDefinition.name}}</span>
							<span class="color-grey-light-2 fst-italic">
								- {{row.children.length}} {{'PROJECT.STORIES' | translate}}
							</span>

							<span [ngClass]="'suivi-lot-status sp-' + row.rowDefinition.contract"
								  [title]="'PROJECT.CONTRACT.STATUS.' + row.rowDefinition.contract | translate">

							</span>
						</ng-template>
						<ng-template #otherTitle>
							<span>{{row.rowDefinition.name}}</span>
						</ng-template>

					</div>
					<div *ngIf="row.type === 'story' && row.rowDefinition.type !== 'CONTINGENCY'"
						 class="ms-auto tag-{{row.rowDefinition.status}}">
						{{'JIRA.STATUS.' + row.rowDefinition.status | translate}}
					</div>
				</div>
			</th>
			<th [class.budget-column-lot]="row.type == 'lot'"
				class="sticky-left-second-col text-center budget-column border-b-grey-1">
				<span
					*ngIf="(displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && row.budget !== 0)">
					{{(row.budget | number: '1.0-3') || 0}}
				</span>
			</th>
			<td [appCellClass]="cell"
				[bgBlueLight]="row.type !== 'lot' && row.type !== 'epic' && ((cell.type === 'raeext' && (!cell.period.periodReport.validatedAdmin && !cell.period.periodReport.validatedCP)) || (cell.type !== 'user' && cell.type !== 'raeext' && !cell.type.startsWith('total')))"
				[bgGreen]="row.type === 'lot' && (cell.type | includes: 'diff') && cell.value > 0"
				[bgRed]="row.type === 'lot' && (cell.type | includes: 'diff') && cell.value < 0"
				[bgWhite]="row.type !== 'lot' && row.type !== 'epic' && cell.type === 'raeext' && (cell.period.periodReport.validatedAdmin || cell.period.periodReport.validatedCP)"
				[borderRightLight]="cell.header?.last && !cell.header?.period.last"
				[borderRight]="cell.type === 'total-avancement' || cell.type === 'total-period-diff' || (cell.type === 'total-period-produit' && !(displayValuesFlat | includes: 'diffTotal')) || (cell.header?.last && cell.period?.last)"
				[class.border-r-white-2]="row.type == 'lot' && (cell.header?.last && !cell.header?.period.last)"
				[class.budget-column-lot]="row.type == 'lot' && (cell.type === 'total-period-diff' || (cell.type === 'total-period-produit' && !(displayValuesFlat | includes: 'diffTotal')) || (cell.header?.last && cell.period?.last))"
				[class.has-alert]="cell.type === 'user' && cell.object && (cell.object.type === 'story' || cell.object.type === 'subtask') && cell.object.hasAlert"
				[class.p-0]="cell.type ==='raeext' && row.type === 'story'"
				[colorBlueNavy]="row.type !== 'lot'"
				[hidden]="cell.header && !cell.header.displayed"
				(click)="getCellInfo(cell)"
				*ngFor="let cell of row.values"
				class="text-center border-rb-grey-1">

				<span
					[appCellClass]
					[colorGreen]="row.type !== 'lot' && cell.period?.periodReport.validatedAdmin"
					[jiraSubtask]="row.type !== 'lot' && cell.period?.periodReport.validatedCP"
					*ngIf="cell.type === 'user' && ((displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && cell.value))"
					class="conso-user">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span [appCellClass]
					  [class.pointer]="row.type === 'story' && cell.period.periodReport.id === periodReportToValidate?.toValidateCP"
					  [colorGreen]="row.type !== 'lot' && cell.period?.periodReport.validatedAdmin"
					  [jiraSubtask]="row.type !== 'lot' && cell.period?.periodReport.validatedCP"
					  (click)="reportConsoToRaeExt(row, cell.index)"
					  *ngIf="cell.type === 'conso' && ((displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && cell.value))">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<ng-container *ngIf="cell.type === 'raeext' && row.type !== 'subtask'">
					<span
						[appCellClass]
						[colorGreen]="row.type !== 'lot' && cell.period?.periodReport.validatedAdmin"
						[jiraSubtask]="row.type !== 'lot' && cell.period?.periodReport.validatedCP"
						*ngIf="row.type !== 'story'
						|| (row.type === 'story' && cell.period.periodReport.id !== periodReportToValidate?.toValidateCP)">
						<div *ngIf="row.rowDefinition.type === 'CONTINGENCY' && !cell.period?.periodReport.validatedCP">
							<span>{{row.budget}}</span>
						</div>
						<div
							*ngIf="(row.rowDefinition.type !== 'CONTINGENCY' || cell.period?.periodReport.validatedCP) && cell.fillRAEEXT && row.type === 'story'">
							<span>{{(cell.value | number: '1.0-3') || 0}}</span>
						</div>
						<div *ngIf="row.type !== 'story'">
							<span>{{(cell.value | number: '1.0-3') || 0}}</span>
						</div>

					</span>
					<span
						*ngIf="row.type === 'story' && cell.period.periodReport.id === periodReportToValidate?.toValidateCP">
						<div *ngIf="row.rowDefinition.type === 'CONTINGENCY'">
							<span>{{row.budget}}</span>
						</div>
						<div
							*ngIf="row.rowDefinition.type !== 'CONTINGENCY' && row.rowDefinition.lot.contract !== 'FINISH'">
							<input [bigStep]="1"
								   [class.diff-budget]="cell.diffBudget"
								   [class.production]="raeNumberInput.elementRef.nativeElement === document.activeElement && productionMode"
								   [class.validated]="cell.period.periodReport.validatedAdmin"
								   [disabled]="cell.period.periodReport.validatedAdmin"
								   [id]="'raeext-' + rowIndex + '-'+ cell.index"
								   [matTooltipDisabled]="!cell.diffBudget"
								   [matTooltip]="'SUIVI.TABLE.TOOLTIP.BUDGET' | translate: {oldBudget: calcOldBudget(cell), newBudget: cell.row.budget}"
								   [smallStep]="0.125"
								   [value]="cell.value ? '' + cell.value : cell.fillRAEEXT ? '0' : ''"
								   (beforeNumberChange)="onInputKeyDown($event, raeNumberInput)"
								   (blur)="onInputBlur(cell, raeNumberInput)"
								   (numberChange)="onInputChange($event, cell)"
								   #raeNumberInput="appNumberInput"
								   appNumberInput
								   class="raeext-input w-100" formatType="number" matInput type="text">
						</div>
						<div
							*ngIf="row.rowDefinition.type !== 'CONTINGENCY' && row.rowDefinition.lot.contract === 'FINISH'">
							{{ cell.value ? '' + cell.value : cell.fillRAEEXT ? '0' : '' }}
						</div>
					</span>
				</ng-container>
				<span
					[appCellClass]
					[colorGreen]="row.type !== 'lot' && cell.period?.periodReport.validatedAdmin"
					[jiraSubtask]="row.type !== 'lot' && cell.period?.periodReport.validatedCP"
					*ngIf="cell.type === 'produit' && row.type !== 'subtask' && ((displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && cell.value))">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					[appCellClass]
					[colorGreen]="cell?.value > 0 && row.type !== 'lot'"
					[colorRed]="cell?.value < 0 && row.type !== 'lot'"
					*ngIf="cell.type === 'diff' && row.type !== 'subtask' && ((displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && cell.value))">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="cell.type === 'total-conso' || cell.type === 'total-period-conso'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="(cell.type === 'total-raeext' || cell.type === 'total-period-raeext') && row.type !== 'subtask'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="(cell.type === 'total-produit' || cell.type === 'total-period-produit') && row.type !== 'subtask'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					[appCellClass]
					[colorGreen]="cell?.value > 0 && row.type !== 'lot'"
					[colorRed]="cell?.value < 0 && row.type !== 'lot'"
					*ngIf="(cell.type === 'total-diff' || cell.type === 'total-period-diff') && row.type !== 'subtask'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="(cell.type === 'total-avancement') && row.type !== 'subtask'">
					{{(cell.value | number: '1.0-2') || 0}} %
				</span>
			</td>
		</tr>
		</tbody>
		<tfoot>
		<tr class="total-row">
			<th class="sticky-before-bottom-line sticky-left-first-col text-end total-row-definition sticky-over-other">
				{{'SUIVI.TABLE.TOTAL_SHOWN' | translate}}
			</th>
			<th class="sticky-before-bottom-line sticky-left-second-col text-center sticky-over-other budget-column">
				<span
					*ngIf="(displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && tableData.footer.budget > 0)">
					{{(tableData.footer.budget | number: '1.0-3') || 0}}
				</span>
			</th>
			<td [appCellClass]="cell"
				[bgBlueLight]="(cell.type === 'raeext' && cell.period.periodReport.validatedAdmin) || (cell.type !== 'user' && cell.type !== 'raeext' && !(cell.type | startsWith: 'total'))"
				[borderRightLight]="cell.header?.last && !cell.header?.period.last"
				[borderRight]="cell.type === 'total-avancement' || cell.type === 'total-period-diff' || (cell.type === 'total-period-produit' && !(displayValuesFlat | includes: 'diffTotal')) || (cell.header?.last && cell.period?.last)"
				[hidden]="cell.header && !cell.header.displayed"
				(click)="getCellInfo(cell)"
				*ngFor="let cell of tableData.footer.values; let last = last"
				class="sticky-before-bottom-line text-center border-rb-grey-1 color-blue-navy-2 fw-bold">

				<span
					[appCellClass]
					[colorGreen]="cell.period?.periodReport.validatedAdmin"
					[jiraSubtask]="cell.period?.periodReport.validatedCP"
					*ngIf="cell.type === 'user' || cell.type === 'conso' || cell.type === 'raeext' || cell.type === 'produit'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span [appCellClass]="cell"
					  [colorGreen]="cell?.value > 0" [colorRed]="cell?.value < 0"
					  *ngIf="cell.type === 'diff' || cell.type === 'total-diff' || cell.type === 'total-period-diff'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span *ngIf="cell.type === 'total-conso' || cell.type === 'total-period-conso'
					 || cell.type === 'total-raeext' || cell.type === 'total-period-raeext'
					 || cell.type === 'total-produit' || cell.type === 'total-period-produit'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span *ngIf="cell.type === 'total-avancement'">
					{{(cell.value | number: '1.0-2') || 0}} %
				</span>
			</td>
		</tr>
		<tr class="total-row total-project-row">
			<th class="sticky-bottom-line sticky-left-first-col text-end total-row-definition sticky-over-other">
				{{'SUIVI.TABLE.TOTAL_PROJECT' | translate}}
			</th>
			<th class="sticky-bottom-line sticky-left-second-col text-center sticky-over-other budget-column">
				<span
					*ngIf="(displayValuesFlat | includes: 'zeros') || (!(displayValuesFlat | includes: 'zeros') && tableData.projFooter.budget > 0)">
					{{(tableData.projFooter.budget | number: '1.0-3') || 0}}
				</span>
			</th>
			<td [appCellClass]="cell"
				[borderRight]="cell.type === 'total-proj-avancement' || cell.type === 'total-period-diff' || (cell.type === 'total-period-produit' && !(displayValuesFlat | includes: 'diffTotal')) || (cell.header?.last && cell.period.lastOfTable)"
				[class.border-rb-grey-1]="cell.type | startsWith: 'total-proj'"
				[hidden]="cell.header && !cell.header.displayed"
				(click)="getCellInfo(cell)"
				*ngFor="let cell of tableData.projFooter.values; let last = last"
				class="sticky-bottom-line text-center color-blue-navy-2 fw-bold">

				<span *ngIf="!((cell.type | startsWith: 'total-proj') || cell.type == 'raeext')">
				</span>
				<span *ngIf="cell.type == 'raeext' && (cell.header.period.periodReport.validatedCP || cell.header.period.periodReport.validatedAdmin)">
					<button class="popup-week-report-btn" [matMenuTriggerFor]="weekReportMenu">
						<span class="sp-iconmonstr-triangle-1 warn-icon" *ngIf="appPopupWeekReport.budgetWarning"></span>
						<span>{{'SUIVI.TABLE.BUTTON.WEEK_REPORT' | translate}}</span>
						<mat-icon>
							arrow_drop_down
						</mat-icon>
					</button>
					<mat-menu #weekReportMenu="matMenu" xPosition="before"
							class="menu-size-limit-override"
							[hasBackdrop]="true"
							[overlapTrigger]="false">
						<app-popup-week-report
							#appPopupWeekReport
							[currentPeriod]="cell.header.period.periodReport"
							[previousPeriod]="cell.header.period.headerPeriodBefore?.periodReport"/>
					</mat-menu>
				</span>
				<span
					*ngIf="cell.type === 'total-proj-conso' || cell.type === 'total-proj-period-conso'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="cell.type === 'total-proj-raeext' || cell.type === 'total-proj-period-raeext'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="cell.type === 'total-proj-produit' || cell.type === 'total-proj-period-produit'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					[appCellClass]="cell"
					[colorGreen]="cell?.value > 0" [colorRed]="cell?.value < 0"
					*ngIf="cell.type === 'total-proj-diff' || cell.type === 'total-proj-period-diff'">
					{{(cell.value | number: '1.0-3') || 0}}
				</span>
				<span
					*ngIf="cell.type === 'total-proj-avancement'">
					{{(cell.value | number: '1.0-2') || 0}} %
				</span>
			</td>
		</tr>
		</tfoot>
	</table>

	<div #floatingTableBG class="total-validated total-validated-bg">
	</div>
	<div #floatingTable class="total-validated sticky-over-other">
		<table *ngIf="floatingTableData">
			<thead>
			<tr>
				<th [colSpan]="(displayValuesFlat | includes: 'diffTotal') ? 5 : 4"
					[style.top.px]="headerElementsContainer | stickTopHeight"
					class="sticky color-blue-navy-2 text-uppercase fw-bold text-center total-header border-l-blue-4">
					<span>{{'SUIVI.TABLE.TOTAL_PERIOD_VALIDATED' | translate}}</span>
				</th>
			</tr>
			<tr>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.BUDGET' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom border-l-blue-4 border-b-blue-2" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.BUDGET' | translate}}
					</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.CONSO' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.CONSO' | translate}}
					</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.RAEEXT' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.RAE' | translate}}
					</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.PRODUIT' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					*ngIf="displayValuesFlat.indexOf('diffTotal') !== -1"
					class="sticky align-bottom total-subheader border-b-blue-2" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.PRODUIT' | translate}}
					</span>
				</th>
				<th [matTooltip]="'SUIVI.TABLE.TOOLTIP.TOTAL.DIFFERENCE' | translate"
					[style.top.px]="headerElementsContainer | stickTopHeight: 1"
					class="sticky align-bottom total-subheader border-r-blue-4 border-b-blue-2" rowspan="2">
					<span class="text-vertical color-blue-navy-2">
						{{'SUIVI.TABLE.DIFFERENCE' | translate}}
					</span>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr [appRowClass]="row.type"
				[hidden]="(row.lotParent && !row.lotParent.expanded) || (row.type === 'lot' && !row.visible) || (row.lotParent && !row.lotParent.visible)"
				*ngFor="let row of floatingTableData.body; let rowIndex = index">

				<td [appCellClass]="cell"
					[bgGreen]="cell?.value > 0 && row.type === 'lot' && (cell.type | includes: 'diff')"
					[bgRed]="cell?.value < 0 && row.type === 'lot' && (cell.type | includes: 'diff')"
					[bgWhite]="cell.type === 'total-raeext' && row.type !== 'lot' && row.type !== 'epic'"
					[borderLeft]="firstCell"
					[borderRight]="lastCell" [colorBlueNavy]="row.type !== 'lot'"
					(click)="getCellInfo(cell)"
					*ngFor="let cell of row.values; let firstCell = first; let lastCell = last"
					class="text-center border-rb-grey-1">
					<span *ngIf="cell.type === 'total-conso'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span *ngIf="cell.type === 'total-raeext' && row.type !== 'subtask'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span *ngIf="cell.type === 'total-produit' && row.type !== 'subtask'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span [appCellClass]="cell"
						  [colorGreen]="cell?.value > 0 && row.type !== 'lot'"
						  [colorRed]="cell?.value < 0 && row.type !== 'lot'"
						  *ngIf="cell.type === 'total-diff' && row.type !== 'subtask'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span *ngIf="cell.type === 'budget' && row.type !== 'subtask'">
						{{(cell.value | number: '1.0-2') || 0}}
					</span>
				</td>
			</tr>
			</tbody>
			<tfoot>
			<tr class="total-row">
				<td [appCellClass]="cell"
					[borderLeft]="firstTotalCell" [borderRight]="lastTotalCell"
					*ngFor="let cell of floatingTableData.footer.values; let firstTotalCell = first; let lastTotalCell = last"
					class="sticky-before-bottom-line text-center border-rb-grey-1 color-blue-navy-2 fw-bold">
					<span
						*ngIf="cell.type === 'total-conso'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span
						*ngIf="cell.type === 'total-raeext'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span
						*ngIf="cell.type === 'total-produit'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span
						[appCellClass]="cell"
						[colorGreen]="cell?.value > 0" [colorRed]="cell?.value < 0" *ngIf="cell.type === 'total-diff'">
						{{(cell.value | number: '1.0-3') || 0}}
					</span>
					<span
						*ngIf="cell.type === 'budget'">
						{{(cell.value | number: '1.0-2') || 0}}
					</span>
				</td>
			</tr>
			<tr class="total-row total-project-row">
				<td [appCellClass]="cell"
					[borderLeft]="firstTotalCell" [borderRight]="lastTotalCell"
					*ngFor="let cell of floatingTableData.footer.values; let firstTotalCell = first; let lastTotalCell = last"
					class="sticky-bottom-line text-center color-blue-navy-2 fw-bold">
				</td>
			</tr>
			</tfoot>
		</table>
	</div>
</div>
