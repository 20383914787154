import {AbstractPaginateDatasource} from '../../../core/pagination/abstract-paginate.datasource';
import {SpinnerService} from '../../../core/service/spinner.service';
import {AvantVenteService} from '../../../core/business/service/avant-vente/avant-vente.service';
import {Pagination} from '../../../core/pagination/dto/pagination.dto';
import {PaginationOption} from '../../../core/pagination/dto/pagination-option.dto';
import {AvantVenteFilterDto, IAvantVenteDto} from '../../../core/business/service/avant-vente/avant-vente.dto';

export class AvantVenteDataSource extends AbstractPaginateDatasource<IAvantVenteDto> {

	constructor(private avantVenteService: AvantVenteService, public spinnerService: SpinnerService) {
		super(spinnerService);
	}

	findByFiltersPaging(filter: AvantVenteFilterDto, paginationOption: PaginationOption): void {
		this.notifyPagingData(this.avantVenteService.findByFiltersPaging(filter, paginationOption));
	}

	transformResults(results: Pagination<IAvantVenteDto>): IAvantVenteDto[] {
		return results.results;
	}
}
