import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../service/api.service';
import {Observable} from 'rxjs';
import {PaginationOption} from '../../../pagination/dto/pagination-option.dto';
import {Pagination} from '../../../pagination/dto/pagination.dto';
import {IClientDto} from './client.dto';

@Injectable({
	providedIn: 'root'
})
export class ClientService extends ApiService {

	private route: string = '/api/client';

	constructor(public http: HttpClient) {
		super(http);
	}

	update(clientDto: IClientDto): Observable<IClientDto> {
		return this.put(this.route + '/' + clientDto.id, clientDto);
	}

	create(clientDto: IClientDto): Observable<IClientDto> {
		return this.post(this.route, clientDto);
	}

	remove(id: number): Observable<void> {
		return this.delete(this.route + '/' + id);
	}

	getAllClient(): Observable<IClientDto[]> {
		return this.get(this.route);
	}

	syncClients(): Observable<void> {
		return this.get(this.route + '/sync');
	}

	findAllPaging(paginationOption: PaginationOption): Observable<Pagination<IClientDto>> {
		return this.post(this.route + '/paging/all', {paginationOption: paginationOption});
	}

	findFilterByNamePaging(filter: string, actif: boolean, paginationOption: PaginationOption): Observable<Pagination<IClientDto>> {
		return this.post(this.route + '/paging/findFilterByNamePaging', {
			filter: filter,
			actif: actif,
			paginationOption: paginationOption
		});
	}
}
