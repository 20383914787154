import {ApiService} from '../../../../service/api.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IAxisDto} from '../v1-report.dto';

@Injectable({
	providedIn: 'root'
})
export class AxisService extends ApiService {

	private route: string = '/api/v1-report/axis/';

	constructor(public http: HttpClient) {
		super(http);
	}

	findAll(): Observable<IAxisDto[]> {
		return this.http.get<IAxisDto[]>(this.route);
	}

	create(axis: IAxisDto): Observable<IAxisDto> {
		return this.http.post<IAxisDto>(this.route, {axis});
	}

	find(id: number): Observable<IAxisDto> {
		return this.http.get<IAxisDto>(this.route + id);
	}

}
