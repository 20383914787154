import {Component, Input, OnInit} from '@angular/core';
import {
	IPeriodReportDto,
	IPeriodReportDtos
} from '../../../../core/business/service/report/period-report/period-report.dto';
import {ActivatedRoute} from '@angular/router';
import {ReportService} from '../../../../core/business/service/report/report.service';

@Component({
	selector: 'app-popup-week-report',
	styleUrls: ['./week-report.component.scss'],
	templateUrl: './week-report.component.html'
})

export class WeekReportComponent implements OnInit {
	jiraKey: string;

	@Input()
	currentPeriod: IPeriodReportDto;

	@Input()
	previousPeriod: IPeriodReportDto;

	budgetWarning: boolean = false;

	constructor(
		private _activeRoute: ActivatedRoute,
		private _reportService: ReportService
	) {
		this.jiraKey = this._activeRoute.snapshot.paramMap.get('jirakey');
	}

	ngOnInit(): void {
		this.loadLastPeriod();
	}

	setBudgetWarning(): void {
		this.budgetWarning =
			this.previousPeriod !== undefined &&
			this.currentPeriod.globalBudget !== this.previousPeriod.globalBudget;
	}

	async loadLastPeriod(): Promise<void> {
		// Requête async pour récupérer la dernière period_report si elle est manquante
		if (this.previousPeriod == undefined) {
			const endDate: Date = new Date(this.currentPeriod.period.dateBegin);
			const startDate: Date = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000); // - 7 jours

			const periodResult: IPeriodReportDtos = await this._reportService.findPeriodForProject(
				this.jiraKey,
				startDate.toISOString(),
				endDate.toISOString()
			);
			if (periodResult.reports.length > 0) {
				this.previousPeriod = periodResult.reports[periodResult.reports.length - 1];
			}
		}
		this.setBudgetWarning();
	}

	periods(): any[] {
		return [
			{ ...this.previousPeriod, current: false, isUndefined: this.previousPeriod == undefined },
			{ ...this.currentPeriod, current: true, isUndefined: this.currentPeriod == undefined }
		];
	}
}
