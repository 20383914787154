import {NgModule} from '@angular/core';
import {ConfigurationComponent} from './configuration.component';
import {MatIconModule} from '@angular/material/icon';
import {HeaderModule} from '../template/header/header.module';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {PermissionEnum} from '../core/business/service/user-base/user-base.dto';
import {RouterModule, Routes} from '@angular/router';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';

const routes: Routes = [
	{path: '', component: ConfigurationComponent, data: {roles: [PermissionEnum.ADMIN]}},
];

@NgModule({
	declarations: [
		ConfigurationComponent
	],
	imports: [
		MatSelectModule,
		HeaderModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		FormsModule,
		MatTableModule,
		CommonModule,
		TranslateModule,
		RouterModule.forChild(routes)
	],
	providers: [
		Title
	]
})

export class ConfigurationModule {
}
