import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../core/pagination/dto/pagination-option.dto';
import {merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {ProjectAlertDatasource} from './datasource/project-alert.datasource';
import {ProjectAlertService} from '../core/business/service/project-alert/project-alert.service';
import {IMatrix} from '../core/business/common/common.dto';
import {
	AlertTypeByCauseMatrix,
	MessageAlertCauseEnum,
	ProjectAlertTypeEnum
} from '../core/business/service/project-alert/project-alert.dto';
import {ProjectAlertFilter} from './dto/project-alert-filter.dto';
import {SpinnerService} from '../core/service/spinner.service';
import {OgdpPaginatorComponent} from '../utils/components/paginator/ogdp-paginator.component';
import {SearchbarComponent} from '../utils/components/searchbar/searchbar.component';
import {Title} from '@angular/platform-browser';
import {ProjectService} from '../core/business/service/project/project.service';
import {environment} from '../../environments/environment';
import {ThemeEnum} from '../theme/themes';
import {Router} from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import {UserInfo} from '../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {AdminRolePipe} from '../security/pipe/role.pipe';

@Component({
	selector: 'app-project-alert',
	templateUrl: './project-alert.component.html',
	styleUrls: ['./project-alert.component.scss']
})

export class ProjectAlertComponent extends UserInfo implements AfterViewInit, OnInit {

	dataSourcePaginate: ProjectAlertDatasource;
	displayedColumns: string[] = ['panneau', 'project', 'creationDate', 'type', 'message'];

	projectAlertTypeEnumKeys: string[] = Object.keys(ProjectAlertTypeEnum);
	messageAlertCauseEnumKeys: string[] = Object.keys(MessageAlertCauseEnum);
	alertTypeMatrix: IMatrix = AlertTypeByCauseMatrix;

	projectAlertFilter: ProjectAlertFilter = new ProjectAlertFilter();

	paginationOption: PaginationOption;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchInputProject: SearchbarComponent;
	@ViewChild('filterAlertType') filterAlertType: MatSelect;
	@ViewChild('filterAlertMessageType') filterAlertMessageType: MatSelect;

	constructor(private projectAlertService: ProjectAlertService,
				private projectService: ProjectService,
				private spinnerService: SpinnerService,
				public override keycloak: KeycloakService,
				public dialog: MatDialog,
				private _titleService: Title,
				private router: Router) {
		super(keycloak);

		this.paginationOption = new PaginationOption(20, 0);
		this.paginationOption.sortBy = new PaginationSortBy('creationDate', PaginationSort.ASC);
	}

	ngOnInit(): void {
		this._titleService.setTitle(
			ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Alertes Projet'
		);
		this.dataSourcePaginate = new ProjectAlertDatasource(this.projectAlertService, this.spinnerService);
		this.loadProjectAlert();
	}

	ngAfterViewInit(): void {
		merge(this.filterAlertType.selectionChange, this.filterAlertMessageType.selectionChange,
			this.searchInputProject.valueChange, this.searchInputProject.clearInput)
			.pipe(
				debounceTime(500),
				distinctUntilChanged()).pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadProjectAlert();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadProjectAlert();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	sortData(event: any): void {
		if (event.direction) {
			this.paginationOption.sortBy = new PaginationSortBy(event.active, event.direction.toUpperCase());
		} else {
			this.paginationOption.sortBy = null;
		}
		this.loadProjectAlert();
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.paginationOption.page = event.pageSize;
		this.loadProjectAlert();
	}

	clearFilterField(): void {
		this.projectAlertFilter.projectName = '';

	}

	goToProject(id: number): void  {
		this.router.navigate(['/gestion/' + id]);
	}

	private loadProjectAlert(): void {
		if (this.projectAlertFilter) {
			this.dataSourcePaginate.findFilterPaging(this.projectAlertFilter, this.paginationOption);
		} else {
			this.dataSourcePaginate.findAllPaging(this.paginationOption);
		}
	}
}
