import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import NumberFormatOptions = Intl.NumberFormatOptions;

@Directive({
	selector: '[appNumberInput]',
	exportAs: 'appNumberInput'
})
export class NumberInputDirective implements OnInit, OnChanges {

	@Input() formatType: 'currency' | 'number' = 'currency';
	@Input() currencyCode: string = 'EUR';
	@Input() allowNegative: boolean = false;
	@Input() smallStep: number;
	@Input() bigStep: number;
	@Input() blurStep: number;
	@Input() doBlurEvent: boolean = true;
	@Output() ngModelChange: EventEmitter<number> = new EventEmitter<number>();
	@Output() beforeNumberChange: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
	@Output() numberChange: EventEmitter<number> = new EventEmitter<number>();

	displayedValue: string;
	numberFormatOptions: NumberFormatOptions;
	@Input() value: number;

	constructor(public elementRef: ElementRef) {
	}

	@HostListener('keyup', ['$event'])
	onChange(event: KeyboardEvent): void {
		if (event.altKey || event.ctrlKey || event.shiftKey) {
			return;
		}
		this.displayedValue = this.elementRef.nativeElement.value;
		if (this.smallStep && this.bigStep && (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'PageUp' || event.key === 'PageDown')) {
			let value: number = this._parseValue() || 0;
			switch (event.key) {
				case 'ArrowUp':
					value += this.smallStep;
					break;
				case 'ArrowDown':
					value -= this.smallStep;
					break;
				case 'PageUp':
					value += this.bigStep;
					break;
				case 'PageDown':
					value -= this.bigStep;
					break;
			}
			if (value <= 0 && !this.allowNegative) {
				value = 0;
			}
			this.displayedValue = '' + value;
		}
		this.formatValue(false);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'PageUp' || event.key === 'PageDown') {
			event.preventDefault();
		}
		this.beforeNumberChange.emit(event);
	}

	@HostListener('blur', ['$event'])
	onBlur(event: any): void {
		if (!this.doBlurEvent) {
			return;
		}
		this.displayedValue = this.elementRef.nativeElement.value;
		this.formatValue(true);

		if (this.value === null || this.value === undefined) {
			this.elementRef.nativeElement.classList.add('error');
		} else {
			this.elementRef.nativeElement.classList.remove('error');
		}
		if (this.value % 0.125 !== 0) {
			this.elementRef.nativeElement.classList.add('error');
		} else {
			this.elementRef.nativeElement.classList.remove('error');
		}
	}

	ngOnInit(): void {
		switch (this.formatType) {
			case 'currency':
				this.numberFormatOptions = <NumberFormatOptions>{
					style: 'currency',
					currency: this.currencyCode,
					maximumFractionDigits: 2,
					useGrouping: true
				};
				break;
			case 'number':
				this.numberFormatOptions = <NumberFormatOptions>{
					style: 'decimal',
					maximumFractionDigits: 3,
					useGrouping: true
				};
				break;
		}

		if (!this.value) {
			this.value = null;
			this.displayedValue = '';
		} else {
			this.displayedValue = this.value.toLocaleString('fr', this.numberFormatOptions);
		}
	}

	formatValue(blur: boolean): void {
		this.value = this._parseValue();

		if ((blur && (!this.displayedValue || this.displayedValue === '')) || isNaN(this.value)) {
			// Si on défocus le champ et que la displayed value est null ou vide, on met value à null
			// OU Si value est pas un nombre (Not a Number)
			this.displayedValue = '';
			this.value = null;
			if (blur) {
				this.elementRef.nativeElement.value = this.displayedValue;
			}
			this.ngModelChange.emit(null);
			this.numberChange.emit(null);
			return;
		} else if (!blur && (this.displayedValue.endsWith('.') || this.displayedValue.endsWith(','))) {
			// Si la valeur fini par . ou , et qu'on ne defocus pas, value et display value ne change pas
			// On return
			return;
		}
		if (blur && this.blurStep) {
			// Arrondire au step
			this.value = Math.ceil(this.value / this.blurStep) * this.blurStep;
		}

		// Si value est inférieur à 0 et qu'on interdit les valeurs négative
		if (this.value < 0 && !this.allowNegative) {
			// value = 0
			this.value = 0;
		}
		// Display value = value formaté en string
		this.displayedValue = this.value.toLocaleString('fr', this.numberFormatOptions);

		// On assigne la valeur affiché à l'élément
		this.elementRef.nativeElement.value = this.displayedValue;
		// On emit le tout
		this.ngModelChange.emit(this.value);
		this.numberChange.emit(this.value);
	}

	private _parseValue(): number {
		let regex: RegExp = /[^\d.,]/g;
		if (this.allowNegative) {
			regex = /(?!^-?)[^\d.,]/g;
		}
		// Retirer les espaces et le symbol euro
		return parseFloat(('' + this.displayedValue).replace(',', '.').replace(regex, ''));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value?.currentValue) {
			this.onBlur(null);
		}
	}
}
