import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], field: string, value: any): any[] {
  	if (!array || !Array.isArray(array)) {
  		return undefined;
	}
    return array.filter(item => item[field] === value);
  }

}
