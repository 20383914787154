import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FocusOnDirective} from './focus-on-input';

@NgModule({
	declarations: [FocusOnDirective],
	exports: [FocusOnDirective],
	imports: [
		CommonModule,
	]
})
export class FocusOnInputModule {
}
