import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ChiffrageItemFlatNode, StateItemNodeEnum} from '../../chiffrage.component';
import {ISousCategorieDto} from '../../../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';
import {IRepartitionDto} from '../../../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {AvantVenteBusiness} from '../../../../../business/avant-vente.business';
import {ISousRepartitionDto} from '../../../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.dto';
import {TranslateService} from '@ngx-translate/core';
import {
	ChiffrageTypeEnum,
	IChiffrageDto
} from '../../../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
	selector: 'app-chiffrage-sous-categorie',
	templateUrl: './chiffrage-sous-categorie.component.html',
	styleUrls: ['./chiffrage-sous-categorie.component.scss'],
})
export class ChiffrageSousCategorieComponent implements OnInit {

	@Input() sousCategorie: ISousCategorieDto;
	@Input() node: ChiffrageItemFlatNode;
	@Input() stateItemNode: StateItemNodeEnum;
	@Input() repartitions: IRepartitionDto[];
	@Input() chiffrage: IChiffrageDto;
	@Input() lastChiffrage: IChiffrageDto;

	@Output() saveNewSousCategorieForm: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();
	@Output() clearNewSousCategorieForm: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();
	@Output() removeSousCategorie: EventEmitter<ChiffrageItemFlatNode> = new EventEmitter<ChiffrageItemFlatNode>();

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (this.node.isKeybordSelected && event.ctrlKey && event.key === 's') {
			event.preventDefault();

			this.changeStateItemNode();
		}
	}

	constructor(private _snackbar: MatSnackBar, private _translator: TranslateService) {
	}

	ngOnInit(): void {
		if (!this.sousCategorie) { // Création
			this.sousCategorie = {} as ISousCategorieDto;
			this.sousCategorie.optionel = false;
			this.sousCategorie.charge = 0;
			if (this.stateItemNode === StateItemNodeEnum.EDIT) {
				this.node.isBeingEdited = true;
				this.sousCategorie.sousRepartitions = [];
				for (const repartition of this.repartitions) {
					this.sousCategorie.sousRepartitions.push({
						repartitionId: repartition.id
					} as ISousRepartitionDto);
				}
			}
		}

		this.updateAfterChange();
	}

	saveFormSousCategorie(): void {
		if (this.sousCategorie.nom?.length > 0) {
			if (this.chiffrage.type === ChiffrageTypeEnum.REEL) {
				this.sousCategorie.charge = this.sousCategorie.sousRepartitions.reduce((acc, sr) => sr.charge + acc, 0);
			}
			if (this.sousCategorie.charge != null) {
				if (typeof this.sousCategorie.charge === 'string') {
					this.sousCategorie.charge = +this.sousCategorie.charge.replace(',', '.');
				}

				if (isNaN(this.sousCategorie.charge)) {
					this.sousCategorie.charge = this.sousCategorie.oldCharge;
					this._snackbar.open(this._translator.instant('AVANT_VENTE.ERROR.CHARGE_MUST_BE_A_NUMBER'), '', {
						panelClass: 'error'
					});
				}
			} else {
				this.sousCategorie.charge = 0;
			}

			if (!isNaN(this.sousCategorie.charge)) {
				this.node.item = this.sousCategorie;

				if (this.chiffrage.type === ChiffrageTypeEnum.PERCENT) {
					this.sousCategorie.sousRepartitions.forEach((sousRepartition) => {
						sousRepartition.charge = Math.round(+this.sousCategorie.charge * sousRepartition.chargePercentage / 12.5) / 8;
					});
				}

				if (this.sousCategorie.charge * 8 % 1 === 0) {
					let i: number = 0;
					let chargeSousRepartition: number = this.sousCategorie.sousRepartitions.reduce((acc, sr) => acc + sr.charge, 0);
					const totalPercent: number = this.sousCategorie.sousRepartitions.reduce((acc, sr) => acc + sr.chargePercentage, 0);

					while (chargeSousRepartition !== this.sousCategorie.charge
						&& totalPercent === 100 && this.chiffrage.type === ChiffrageTypeEnum.PERCENT) {

						const total: number = Object.values(this.sousCategorie.sousRepartitions).reduce((acc, {charge}) => acc + charge, 0);
						const sousRepartitionLength: number = this.sousCategorie.sousRepartitions.length;
						if (total > this.sousCategorie.charge) {
							this.sousCategorie.sousRepartitions[(sousRepartitionLength - 1) - i % sousRepartitionLength].charge -= 0.125;
							chargeSousRepartition -= 0.125;
						} else {
							this.sousCategorie.sousRepartitions[(sousRepartitionLength - 1) - i % sousRepartitionLength].charge += 0.125;
							chargeSousRepartition += 0.125;
						}
						i++;
					}
				}
				this.saveNewSousCategorieForm.emit(this.node);
				this.changeStateItemNode();
			}
		} else {
		}
	}

	clearFormSousCategorie(): void {
		if (this.sousCategorie.id) { // update
			this.changeStateItemNode();
		} else {
			this.clearNewSousCategorieForm.emit(this.node);
		}
	}

	changeStateItemNode(): void {
		let newStateItemNode: StateItemNodeEnum = null;
		this.sousCategorie.oldCharge = +this.sousCategorie.charge;
		if (this.stateItemNode) {
			if (this.stateItemNode === StateItemNodeEnum.EDIT) {
				this.node.isBeingEdited = false;
				newStateItemNode = StateItemNodeEnum.READ;
			} else if (this.stateItemNode === StateItemNodeEnum.READ) {
				this.node.isBeingEdited = true;
				newStateItemNode = StateItemNodeEnum.EDIT;
			}
		} else {
			this.node.isBeingEdited = true;
			newStateItemNode = StateItemNodeEnum.EDIT;
		}

		// Lance l'update du component
		this.stateItemNode = newStateItemNode;
	}

	editNode(): void {
		if (this.stateItemNode !== StateItemNodeEnum.EDIT && this.lastChiffrage.id === this.chiffrage.id) {
			this.node.isBeingEdited = true;
			this.stateItemNode = StateItemNodeEnum.EDIT;
		}
	}

	deleteSousCategorie(): void {
		this.removeSousCategorie.emit(this.node);
	}

	isNameTruncated(element: HTMLSpanElement): boolean {
		return element.scrollWidth > element.clientWidth;
	}

	private updateAfterChange(): void {
		if (this.sousCategorie) {
			if (this.sousCategorie.sousRepartitions) {
				this.sousCategorie.sousRepartitions.sort(AvantVenteBusiness.sortSousRepartitionOrder);
			}
		}
	}
}
