import {AfterViewInit, ApplicationRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MyAccountComponent} from './my-account/my-account.component';
import packageJson from '../../../../package.json';
import {HeaderService} from '../../template/header/header.service';
import {UserBaseDto} from '../../core/business/service/user-base/user-base.dto';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {UserBaseService} from '../../core/business/service/user-base/user-base.service';
import {MatDialog} from '@angular/material/dialog';
import {UserInfo} from '../../security/util/user-info';
import {KeycloakService} from 'keycloak-angular';
import {takeWhile} from 'rxjs';

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent extends UserInfo implements OnInit, AfterViewInit {

	environment: {production: boolean, theme: string} = environment;
	theme: ThemeEnum;
	version: string = packageJson.version;
	user: UserBaseDto;

	categories: {hasChild: boolean, childrenNames: string[]}[] = [
		{ hasChild: false, childrenNames: ['Gestion de projet', 'Suivi projet', 'Rapport V1', 'Alertes projet'] },
		{ hasChild: false, childrenNames: ['Demande BDC', 'Avant vente'] },
		{ hasChild: false, childrenNames: ['Tempo JIRA', 'Export tempo'] },
		{ hasChild: false, childrenNames: ['Utilisateurs', 'Utilisateurs JIRA', 'Gestion client', 'Configuration'] }
	];

	constructor(public override keycloak: KeycloakService,
				public headerService: HeaderService,
				private _userService: UserBaseService,
				private _router: Router,
				private _appRef: ApplicationRef,
				private _headerService: HeaderService,
				private _dialog: MatDialog) {
		super(keycloak);
	}

	ngOnInit(): void {
		this.theme = ThemeEnum[environment.theme.toUpperCase()];
		this.user = this.currentUser;
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this._appRef.tick();
		}, 300);
	}

	checkFurtherChild(indexToStart: number): boolean {
		for (let i: number = 1; i !== this.categories.length; i++) {
			if (this.categories[indexToStart + i] && this.categories[indexToStart + i].hasChild) {
				return true;
			}
		}
		return false;
	}

	logout(): void {
		this.keycloak.logout(window.location.origin + this._router.url);
	}

	openClose(): void {
		this.headerService.menuOpen = !this.headerService.menuOpen;
		setTimeout(() => {
			this._appRef.tick();
		}, 300);
	}

	openProfileModal(): void {
		this._dialog.open(MyAccountComponent).afterClosed().subscribe(result => {
			if (result) {
				this._router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
					this._router.navigate(['/gestion'])
				);
			}
		});
	}
}
