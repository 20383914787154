import {ContractStatusEnum, ILotDto} from '../../../core/business/service/lot/lot.dto';
import {IStoryDto} from '../../../core/business/service/story/story.dto';

export interface GestionParameter {
	name: string;
	value: boolean;
}

export enum IssueDragDropMessageEnum {
	IMPOSSIBLE = 'IMPOSSIBLE',
	SAME_POSITION = 'SAME_POSITION',
	ORDER_CHANGED = 'ORDER_CHANGED',
	PARENT_CHANGED = 'PARENT_CHANGED'
}

export enum IssueTypeEnum {
	EPIC = 'EPIC',
	STORY = 'STORY',
	SUBTASK = 'SUBTASK',
}

// Objet intermédiaire: Permet d'avoir une grappe: Lot > EpicDto > StoryDto > SubTask
// (car en BDD, la hiérarchie est Lot > StoryDto > SubTask)
export class DetailedLot {
	id: number;
	epics: EpicWithStory[];
	name: string;
	budget: number;
	order: number;
	estimated: number;
	updateDate: Date;
	RAE: number;
	contract: ContractStatusEnum;
	backlog: boolean;
	isExpend: boolean;
	orphanStories: IStoryDto[] = [];
	lotObject: ILotDto;
	storyLength: number;
}

// Objet intermédiaire: La notion d'epic ajoutée à la grappe
export class EpicWithStory {
	id: number;
	name: string;
	jiraIntId: string;
	order: number;
	updateDate: Date;
	stories: IStoryDto[];
	RAE: number;
	budget: number;
	estimated: number;
	expanded: boolean = true;
}
