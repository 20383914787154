import {
	AfterViewInit,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges, OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	ChiffrageDatasource,
	DatasourceItemNode
} from './datasource/chiffrage.datasource';
import {FlatTreeControl} from '@angular/cdk/tree';
import {CategorieService} from '../../../../core/business/service/avant-vente/categorie/categorie.service';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ISousCategorieDto} from '../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.dto';
import {SousCategorieService} from '../../../../core/business/service/avant-vente/sous-categorie/sous-categorie.service';
import {IChiffrageDto} from '../../../../core/business/service/avant-vente/chiffrage/chiffrage.dto';
import {firstValueFrom, Observable} from 'rxjs';
import {
	IRepartitionDto
} from '../../../../core/business/service/avant-vente/repartition/repartition.dto';
import {SousRepartitionService} from '../../../../core/business/service/avant-vente/sous-repartition/sous-repartition.service';
import {ICategorieDto} from '../../../../core/business/service/avant-vente/categorie/categorie.dto';
import {SpinnerService} from '../../../../core/service/spinner.service';
import {ChiffrageSousCategorieComponent} from './component/chiffrage-sous-categorie/chiffrage-sous-categorie.component';
import {ChiffrageCategorieComponent} from './component/chiffrage-categorie/chiffrage-categorie.component';
import { MatDialog } from '@angular/material/dialog';

export enum StateItemNodeEnum {
	READ = 'READ',
	EDIT = 'EDIT'
}

export enum TypeItemNode {
	CATEGORIE = 'CATEGORIE',
	SOUS_CATEGORIE = 'SOUS_CATEGORIE'
}

export class ChiffrageItemFlatNode {
	displayName: string;
	level: number;
	expandable: boolean;
	item: any;
	order: number;
	isKeybordSelected: boolean = false;
	isBeingEdited: boolean = false;
}

@Component({
	selector: 'app-chiffrage',
	templateUrl: './chiffrage.component.html',
	styleUrls: ['./chiffrage.component.scss'],
})
export class ChiffrageComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {

	@Input() chiffrage: IChiffrageDto;
	@Input() lastChiffrage: IChiffrageDto;
	@Input() repartitions: IRepartitionDto[];
	@Input() eventNewCategorie: Observable<TypeItemNode> = new Observable<TypeItemNode>();
	@Input() eventNewRepartition: Observable<IRepartitionDto> = new Observable<IRepartitionDto>();
	@Input() eventDeleteRepartition: Observable<IRepartitionDto> = new Observable<IRepartitionDto>();

	@Output() needToUpdateRepartitions: EventEmitter<IChiffrageDto> = new EventEmitter<IChiffrageDto>();
	@Output() changedCategoriesValue: EventEmitter<ICategorieDto[]> = new EventEmitter<ICategorieDto[]>();

	treeControl: FlatTreeControl<ChiffrageItemFlatNode>;
	treeFlattener: MatTreeFlattener<DatasourceItemNode, ChiffrageItemFlatNode>;
	dataSource: MatTreeFlatDataSource<DatasourceItemNode, ChiffrageItemFlatNode>;
	businessDatasource: ChiffrageDatasource;

	flatNodeList: ChiffrageItemFlatNode[] = [];
	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap: Map<ChiffrageItemFlatNode, DatasourceItemNode> = new Map<ChiffrageItemFlatNode, DatasourceItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap: Map<DatasourceItemNode, ChiffrageItemFlatNode> = new Map<DatasourceItemNode, ChiffrageItemFlatNode>();

	indexKeybordSelectedNode: number = null;

	expandTimeout: any;
	expandDelay: number = 1000;

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {

		const currentNode: ChiffrageItemFlatNode = this.treeControl.dataNodes[this.indexKeybordSelectedNode];
		let previousCategoryNode: ChiffrageItemFlatNode;

		if (currentNode) {
			const previousCategoryNodes: ChiffrageItemFlatNode[] = this.treeControl.dataNodes.filter((node) =>
				node.level === 0 && node.order < currentNode.order
			);
			if (previousCategoryNodes.length > 0) {
				previousCategoryNode = previousCategoryNodes[previousCategoryNodes.length - 1];
			}
		}

		if (this.indexKeybordSelectedNode === null || this.indexKeybordSelectedNode === undefined) {
			this.indexKeybordSelectedNode = -1;
		}

		if (event.ctrlKey && event.key === 'd') {
			event.preventDefault();

			if (this.treeControl.dataNodes[this.indexKeybordSelectedNode].expandable) {
				this.addFormNewCategorie();
			} else if (!this.treeControl.dataNodes[this.indexKeybordSelectedNode].expandable) {
				this.addFormNewSousCategorie(this.getParentNode(this.treeControl.dataNodes[this.indexKeybordSelectedNode]));
			}

			this.selectedKeyboardSelectedNode(Array.from(this.flatNodeMap.keys()).pop());
		}

		if (event.key === 'ArrowUp') {
			this.disableKeybordSelectedNode(this.indexKeybordSelectedNode);

			if ((this.indexKeybordSelectedNode - 1) < 0) {
				this.indexKeybordSelectedNode = (this.treeControl.dataNodes.length - 1);
			} else {
				if (currentNode.level === 0 && !this.treeControl.isExpanded(previousCategoryNode)) {
					this.indexKeybordSelectedNode -= previousCategoryNode.item.sousCategories.length + 1;
				} else {
					this.indexKeybordSelectedNode -= 1;
				}
			}

			this.enableKeyboardSelectedNode(this.indexKeybordSelectedNode);
		}

		if (event.key === 'ArrowDown') {
			this.disableKeybordSelectedNode(this.indexKeybordSelectedNode);

			if ((this.indexKeybordSelectedNode + 1) > (this.treeControl.dataNodes.length - 1)) {
				this.indexKeybordSelectedNode = 0;
			} else {
				if (currentNode.level === 0 && !this.treeControl.isExpanded(currentNode)) {
					this.indexKeybordSelectedNode += currentNode.item.sousCategories.length + 1;
				} else {
					this.indexKeybordSelectedNode += 1;
				}
			}

			this.enableKeyboardSelectedNode(this.indexKeybordSelectedNode);
		}

		if (event.key === 'ArrowRight') {
			if (currentNode.level === 0) {
				this.treeControl.expand(currentNode);
				currentNode.item.isExpanded = true;
				this.categorieService.update(currentNode.item).subscribe();
			}
		}

		if (event.key === 'ArrowLeft') {
			if (currentNode.level === 0) {
				this.treeControl.collapse(currentNode);
				currentNode.item.isExpanded = false;
				this.categorieService.update(currentNode.item).subscribe();
			}
		}
	}

	constructor(public dialog: MatDialog,
				private categorieService: CategorieService,
				private sousCategorieService: SousCategorieService,
				private sousRepartitionService: SousRepartitionService,
				public spinnerService: SpinnerService) {

		this.businessDatasource = new ChiffrageDatasource(this.categorieService, this.sousCategorieService, this.sousRepartitionService, this.spinnerService);

		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<ChiffrageItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	}

	ngOnInit(): void {

		this.businessDatasource.dataChange.subscribe((datas: DatasourceItemNode[]) => {
			// Ces deux lignes sont obligatoire, bug du framework. Sinon il ne voit pas que les données on changées
			// et les indexes ne sont plus bon dans l'event : CdkDragDrop
			this.dataSource.data = [];
			this.dataSource.data = datas;
			datas.forEach((data, k) => {
				if (data.item?.isExpanded) {
					this.treeControl.expand(this.treeControl.dataNodes.filter(node => node.level === 0)[k]);
				}
			});
			this.changedCategoriesValue.emit(this.businessDatasource.categories);
		});

		this.eventNewCategorie.subscribe((typeItemNode: TypeItemNode) => {
			if (typeItemNode === TypeItemNode.CATEGORIE) {
				this.addFormNewCategorie();
			}
		});

		this.eventNewRepartition.subscribe((repartitionDto: IRepartitionDto) => {
			this.reloadSousRepartition(repartitionDto);
		});

		this.eventDeleteRepartition.subscribe((repartitionDto: IRepartitionDto) => {
			this.reloadSousRepartition(repartitionDto);
		});
	}

	ngAfterViewInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.chiffrage?.currentValue) {
			this.businessDatasource.findByChiffrageWithSousCategorieAndSousRepartition(changes.chiffrage.currentValue.id);
		}
	}

	ngOnDestroy(): void {
		this.treeControl.dataNodes.filter(node => node.level === 0).forEach(async (node) => {
			node.item.isExpanded = this.treeControl.isExpanded(node);
			await firstValueFrom(this.categorieService.update(node.item));
		});
	}

	getLevel = (node: ChiffrageItemFlatNode) => node.level;
	isExpandable = (node: ChiffrageItemFlatNode) => node.expandable;
	getChildren = (node: DatasourceItemNode): DatasourceItemNode[] => node.children;
	hasChild = (_: number, _nodeData: ChiffrageItemFlatNode) => _nodeData.expandable;
	hasNoContent = (_: number, _nodeData: ChiffrageItemFlatNode) => _nodeData.item === null || _nodeData.item === undefined;

	transformer = (node: DatasourceItemNode, level: number) => {
		const existingNode: ChiffrageItemFlatNode = this.nestedNodeMap.get(node);
		const flatNode: ChiffrageItemFlatNode = existingNode && existingNode.displayName === node.displayName
			? existingNode
			: new ChiffrageItemFlatNode();

		flatNode.displayName = node.displayName;
		flatNode.level = level;
		flatNode.expandable = !!node.children;
		flatNode.item = node.item;
		flatNode.order = node.order;

		if (!this.flatNodeList.includes(flatNode)) {
			this.flatNodeList.push(flatNode);
		}
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	}

	/*
	dragStart(nodeToDrag: ChiffrageItemFlatNode): void {
		this.dragNode = nodeToDrag;
	}

	dragEnd(): void {
		this.dragNode = null;
	}
*/

	/*
	dragHover(node: DatasourceItemNode): void {
		if (this.dragging) {
			clearTimeout(this.expandTimeout);
			this.expandTimeout = setTimeout(() => {
				this.treeControl.expand(node);
			}, this.expandDelay);
		}
	}

	dragHoverEnd(): void {
		if (this.dragging) {
			clearTimeout(this.expandTimeout);
		}
	}
	*/

	drop(event: CdkDragDrop<ChiffrageItemFlatNode, any>): void {
		// ignore drops outside of the tree
		if (!event.isPointerOverContainer) {
			return;
		}

		// /!\ ATTENTION
		// event.previousIndex : aucune idée de ce que veux dire cette information
		// event.currentIndex : La position est basé sur les noeuds visible à l'écran
		if (event.previousContainer === event.container) {
			const nestedNode: DatasourceItemNode = this.flatNodeMap.get(event.item.data);
			const parentNestedNode: ChiffrageItemFlatNode = this.getParentNode(event.item.data);
			if (parentNestedNode) {
				// on déplace une sous catégorie
				const nbOfPreviousVisibleNodes: number = this.numberOfPreviousVisibleNodes(event.item.data);
				const newPosOnThisLot: number = (event.currentIndex - nbOfPreviousVisibleNodes) - 1; // -1 pour supprimer la position du noeud lot

				this.businessDatasource.moveSousCategorie(this.flatNodeMap.get(parentNestedNode), nestedNode, event.item.data.order, newPosOnThisLot);
			} else {
				// on déplace une catégorie
				this.businessDatasource.moveCategorie(nestedNode, event.previousIndex, event.currentIndex);
			}
		}
	}

	changeStateItemNode(component: ChiffrageCategorieComponent | ChiffrageSousCategorieComponent): void {
		component.changeStateItemNode();
	}

	getHasEditedNode(): boolean {
		return Array.from(this.flatNodeMap).some(([key]) => key.isBeingEdited);
	}

	addFormNewSousCategorie(node: ChiffrageItemFlatNode): void {
		const hasEditedNode: boolean = this.getHasEditedNode();

		if (!hasEditedNode) {
			const parentNode: DatasourceItemNode = this.flatNodeMap.get(node);
			this.businessDatasource.insertSousCategorieForm(parentNode, '');
			this.treeControl.expand(node);
		}
	}

	saveFormSousCategorie(node: ChiffrageItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		const parentNestedNode: ChiffrageItemFlatNode = this.getParentNode(node);
		const parentNode: DatasourceItemNode = this.flatNodeMap.get(parentNestedNode);

		let updatedSousCategorie: Observable<ISousCategorieDto>;
		if (node.item.id) {
			updatedSousCategorie = this.businessDatasource.updateSousCategorie(parentNode, nestedNode, node.item);
		} else {
			updatedSousCategorie = this.businessDatasource.createSousCategorie(parentNode, nestedNode, node.item, this.chiffrage);
		}

		updatedSousCategorie.subscribe(() => {
			this.needToUpdateRepartitions.emit(this.chiffrage);
			this.changedCategoriesValue.emit(this.businessDatasource.categories);
		});
	}

	clearFormSousCategorie(node: ChiffrageItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		const parentNode: DatasourceItemNode = this.flatNodeMap.get(this.getParentNode(node));
		this.flatNodeMap.delete(node);
		this.businessDatasource.clearSousCategorieForm(parentNode, nestedNode);
	}

	addFormNewCategorie(): void {
		const hasEditedNode: boolean = this.getHasEditedNode();

		if (!hasEditedNode) {
			this.businessDatasource.insertCategorieForm('');
		}
	}

	saveFormCategorie(node: ChiffrageItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);

		let updatedCategorie: Observable<ICategorieDto>;
		if (node.item.id) { // update
			updatedCategorie = this.businessDatasource.updateCategorie(nestedNode, node.item);
		} else {
			updatedCategorie = this.businessDatasource.createCategorie(this.chiffrage, nestedNode, node.item);
		}

		updatedCategorie.subscribe(() => {
			this.changedCategoriesValue.emit(this.businessDatasource.categories);
		});
	}

	clearFormCategorie(node: ChiffrageItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		this.flatNodeMap.delete(node);
		this.businessDatasource.clearCategorieForm(nestedNode);
	}

	deleteCategorie(node: ChiffrageItemFlatNode): void {
		const confirmDelete: boolean = confirm('Etez-vous sûr de vouloir supprimer la catégorie ?');
		if (confirmDelete) {
			const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
			this.businessDatasource.deleteCategorie(nestedNode, node.item).subscribe(() => {
				this.needToUpdateRepartitions.emit(this.chiffrage);
				this.changedCategoriesValue.emit(this.businessDatasource.categories);
			});
		}
	}

	deleteSousCategorie(node: ChiffrageItemFlatNode): void {
		const nestedNode: DatasourceItemNode = this.flatNodeMap.get(node);
		const parentNestedNode: ChiffrageItemFlatNode = this.getParentNode(node);
		const parentNode: DatasourceItemNode = this.flatNodeMap.get(parentNestedNode);
		this.businessDatasource.deleteSousCategorie(parentNode, nestedNode, node.item).subscribe(() => {
			this.needToUpdateRepartitions.emit(this.chiffrage);
			this.changedCategoriesValue.emit(this.businessDatasource.categories);
		});
	}

	reloadSousRepartition(repartitionDto: IRepartitionDto): void {
		this.businessDatasource.reloadSousRepartition(repartitionDto).subscribe(() => {
			this.needToUpdateRepartitions.emit(this.chiffrage);
		});
	}

	selectedKeyboardSelectedNode(node: ChiffrageItemFlatNode): void {
		if (node.isBeingEdited && node.isKeybordSelected) {
			return;
		}

		const indexKeybordSelectedNode: number = this.treeControl.dataNodes.indexOf(node);

		if (this.indexKeybordSelectedNode === indexKeybordSelectedNode) {
			if (node.isKeybordSelected) {
				this.disableKeybordSelectedNode(this.indexKeybordSelectedNode);
			} else {
				this.enableKeyboardSelectedNode(this.indexKeybordSelectedNode);
			}
		} else {
			this.disableKeybordSelectedNode(this.indexKeybordSelectedNode);
			this.enableKeyboardSelectedNode(indexKeybordSelectedNode);
		}

		this.indexKeybordSelectedNode = indexKeybordSelectedNode;
	}

	getParentNode(node: ChiffrageItemFlatNode): ChiffrageItemFlatNode | null {
		const currentLevel: number = node.level;
		if (currentLevel < 1) {
			return null;
		}

		const startIndex: number = this.treeControl.dataNodes.indexOf(node) - 1;
		for (let i: number = startIndex; i >= 0; i--) {
			const currentNode: ChiffrageItemFlatNode = this.treeControl.dataNodes[i];
			if (currentNode.level < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	private numberOfPreviousVisibleNodes(node: ChiffrageItemFlatNode): number {
		let startNode: ChiffrageItemFlatNode;
		if (!this.treeControl.isExpandable(node)) {
			startNode = this.getParentNode(node);
		} else {
			startNode = node;
		}
		let notVisibleNodes: number = 0;

		const startIndex: number = this.treeControl.dataNodes.indexOf(startNode) - 1;
		for (let i: number = startIndex; i >= 0; i--) {
			const currentNode: ChiffrageItemFlatNode = this.treeControl.dataNodes[i];
			if (this.treeControl.isExpandable(currentNode)) {
				if (this.treeControl.isExpanded(currentNode)) {
					notVisibleNodes = notVisibleNodes + this.treeControl.getDescendants(currentNode).length + 1;
				} else {
					notVisibleNodes = notVisibleNodes + 1;
				}
			}
		}

		return notVisibleNodes;
	}

	private enableKeyboardSelectedNode(indexKeybordSelectedNode: number): void {
		if (indexKeybordSelectedNode != null) { // symbol != (1 equals instead of two) check null and undefined at same time
			if (indexKeybordSelectedNode >= 0 && indexKeybordSelectedNode < this.treeControl.dataNodes.length) {
				this.treeControl.dataNodes[indexKeybordSelectedNode].isKeybordSelected = true;
			}
		}
	}

	private disableKeybordSelectedNode(indexKeybordSelectedNode: number): void {
		if (indexKeybordSelectedNode != null) {
			if (indexKeybordSelectedNode >= 0 && indexKeybordSelectedNode < this.treeControl.dataNodes.length) {
				this.treeControl.dataNodes[indexKeybordSelectedNode].isKeybordSelected = false;
			}
		}
	}
}
