import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ExportLot, ExportLotDatasource} from './dialog-export-lot.datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import ExcelService from '../../../../core/service/excel/excel.service';
import {IProjectDto} from '../../../../core/business/service/project/project.dto';
import {ContractStatusEnum} from '../../../../core/business/service/lot/lot.dto';
import {SpinnerService} from '../../../../core/service/spinner.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-export-lot',
	styleUrls: ['./dialog-export-lot.component.scss'],
	templateUrl: './dialog-export-lot.component.html'
})
export class DialogExportLotComponent implements OnInit, AfterViewInit {

	public project: IProjectDto;

	public dataSource: ExportLotDatasource;
	public displayedColumns: string[];
	public selection: SelectionModel<ExportLot>;
	public allLots: boolean = true;
	public statusKeys: string[] = Object.keys(ContractStatusEnum);
	public filter: string;

	@ViewChild('chkAllLots') chkAllLots: MatCheckbox;
	@ViewChild('selectStatusLot') selectStatusLot: MatSelect;

	excelService: ExcelService;

	constructor(public dialogRef: MatDialogRef<DialogExportLotComponent>,
				private spinnerService: SpinnerService,
				@Inject(MAT_DIALOG_DATA) public data: { project: IProjectDto }) {
		this.project = data.project;
		this.dataSource = new ExportLotDatasource(this.spinnerService);
		this.selection = new SelectionModel<ExportLot>(true, []);
		this.excelService = new ExcelService();
	}

	ngOnInit(): void {
		this.displayedColumns = ['id', 'name', 'statut'];
		this.loadLots();
	}

	ngAfterViewInit(): void {
		merge(this.chkAllLots.change, this.selectStatusLot.selectionChange).pipe(tap(() => {
			this.loadLots();
		})).subscribe();
	}

	actionBtnExporter(): void {
		console.log(this.selection);
	}

	private loadLots(): void {
		this.dataSource.loadLots(this.project, this.filter);
	}

	masterToggle(): void {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource.datas().forEach(row => this.selection.select(row));
	}

	isAllSelected(): boolean {
		const numSelected: number = this.selection.selected.length;
		const numRows: number = this.dataSource.length();
		return numSelected === numRows;
	}
}
