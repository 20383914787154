<app-header [displayBreadCrumb]="'PROJECT.HEADER' | translate">
	<div class="ms-5">
		<app-searchbar [(value)]="gestionProjectFilter.projectName" (clearInput)="clearFilterField()"></app-searchbar>
	</div>

	<mat-form-field class="status-filter">
		<mat-label>{{'PROJECT.STATUS.TITLE' | translate}}</mat-label>
		<mat-select [(ngModel)]="gestionProjectFilter.projectStatus" #filterStatus multiple>
			<mat-option [value]="projectStatusEnumKey" *ngFor="let projectStatusEnumKey of projectStatusEnumKeys">
				<span>{{'PROJECT.STATUS.' + projectStatusEnumKey| translate}}</span>
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field>
		<mat-label>{{'PROJECT.SORT.TITLE' | translate}}</mat-label>
		<mat-select [(ngModel)]="paginationOption.sortBy" [hideSingleSelectionIndicator]="true" #sortBy>
			<mat-option [title]="'PROJECT.SORT.CREATION_ASC' | translate" [value]="sortByOption['creationDateAsc']">
				{{'PROJECT.SORT.CREATION_ASC' | translate}}
			</mat-option>
			<mat-option [title]="'PROJECT.SORT.CREATION_DESC' | translate" [value]="sortByOption['creationDateDesc']">
				{{'PROJECT.SORT.CREATION_DESC' | translate}}
			</mat-option>
			<mat-option [title]="'PROJECT.SORT.ALPHA' | translate" [value]="sortByOption['alphabetiqueOrderAsc']">
				{{'PROJECT.SORT.ALPHA' | translate}}
			</mat-option>
			<mat-option [title]="'PROJECT.SORT.BUDGET' | translate" [value]="sortByOption['budgetDesc']">
				{{'PROJECT.SORT.BUDGET' | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<app-client-select [clients]="clients" [(client)]="gestionProjectFilter.client"></app-client-select>

	<mat-form-field>
		<mat-label>Entreprises</mat-label>
		<mat-select #selectCompanies [(ngModel)]="gestionProjectFilter.companies" multiple>
			<mat-option *ngFor="let company of companies" [value]="company">{{ company.name }}</mat-option>
		</mat-select>
	</mat-form-field>

	<form>
		<mat-form-field *ngIf="role | role: ['ADMIN']">
			<mat-label>CP / CPA</mat-label>
			<input [formControl]="selectCpController" [matAutocomplete]="auto" matInput>
			<mat-autocomplete [displayWith]="displaySelectedCp" [hideSingleSelectionIndicator]="true"
							  #auto="matAutocomplete" autoActiveFirstOption>
				<mat-option [value]="it" *ngFor="let it of cpFiltered">
					<div *ngIf="it.avatar; then avatar else noAvatar"></div>
					<ng-template #avatar>
						<img [alt]="it.firstName" [src]="it.avatar | safizer" class="avatar">
					</ng-template>
					<ng-template #noAvatar>
						<span class="avatar"></span>
					</ng-template>
					<span>{{it.firstName}} {{it.lastName}}</span>
				</mat-option>
			</mat-autocomplete>
			<button (click)="clearCpFilter()" *ngIf="cp" class="clear material-icons" matSuffix>clear</button>
		</mat-form-field>
	</form>

	<div class="actions">
		<button mat-flat-button *ngIf="isDevelopmentMode && role | admin" (click)="obfuscateEveryProjectDialog()" class="its-button">
			{{'APP.OBFUSCATE.TITLE' | translate}}
		</button>
		<button mat-flat-button *ngIf="role | admin" (click)="syncWithMdm()" class="its-button">
			{{'APP.SYNC' | translate}}
		</button>
	</div>
</app-header>

<div class="content">
	<table [dataSource]="dataSourcePaginate" class="no-padding" id="project-table" mat-table multiTemplateDataRows>
		<!-- Informations Column -->
		<ng-container matColumnDef="informations">
			<td *matCellDef="let element" mat-cell style="width: 573px;">
				<div class="d-flex align-items-center">
					<div [class.rotate-180]="expandedElement.indexOf(element) === -1"
						 (click)="$event.stopPropagation(); addOrDelete(element)"
						 class="sp-up-arrow up-arrow pt-3 pb-3 display-lot">
					</div>
					<div [routerLink]="['/gestion/', element.id]" *ngIf="element.logo"
						 class="project-logo cursor-pointer">
						<img [alt]="'APP.LOGO' | translate" [src]="element.logo | safizer" class="logo">
					</div>
					<div class="d-flex flex-column ms-2">
						<div [routerLink]="['/gestion/', element.id]" class="table-blue cursor-pointer">
							{{element.name}}
						</div>
						<div>
							<a [href]="element.urlJiraIntId" class="table-grey"
							   target="_blank">{{element.jiraIntId}}</a>
							<span class="table-grey"> | </span>
							<a [href]="element.urlJiraExtId" class="table-grey"
							   target="_blank">{{element.jiraExtId}}</a>
						</div>
					</div>

					<div class="ms-auto d-flex flex-row">
						<span class="company">{{ element.company ? element.company.name : '' }}</span>
						<div class="me-2">
							<div class="margin-state">
								<span class="color-grey">{{'PROJECT.STATUS.' + element.status | translate}}</span>
							</div>
							<div (click)="openDialogProjectAlert(element.id)" class="d-flex justify-content-end">
								<app-project-messages [numberAlerts]="element.numberAlerts"></app-project-messages>
							</div>
						</div>
						<div [routerLink]="['/gestion/', element.id]" class="d-flex arrow-background">
							<div class="sp-right-arrow white-arrow align-self-center">

							</div>
						</div>
					</div>
				</div>
			</td>
		</ng-container>

		<!-- estimated Column -->
		<ng-container matColumnDef="estimated">
			<td [class.d-none]="!(role | role: ['ADMIN', 'USER'])" *matCellDef="let element"
				class="fw-bold table-blue border-dark-grey text-center" mat-cell
				style="width: 118px">{{element.budget}}</td>
		</ng-container>

		<!-- percentage Column -->
		<ng-container matColumnDef="percentage">
			<td *matCellDef="let element" class="fw-bold table-blue border-dark-grey text-center" mat-cell
				style="width: 118px">{{element.avancement | number:'1.0-0'}} %
			</td>
		</ng-container>

		<!-- void Column -->
		<ng-container matColumnDef="void">
			<td *matCellDef="let element" mat-cell>
				<span *ngIf="role | admin" class="icon">
					<div class="delete-button-padding position-right">
						<button [matMenuTriggerFor]="opt" class="sp-delete delete-button-style"></button>
						<mat-menu #opt="matMenu" class="itsf-override">
							<button (click)="deleteProject(element.id)" mat-menu-item>
								<span class="sp-speech-bubble gradient"></span>
								<span class="ms-2">{{'APP.DELETE' | translate}}</span>
							</button>
						</mat-menu>
					</div>
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="expandedDetail">
			<td [colSpan]="displayedColumns.length" *matCellDef="let element" mat-cell>
				<div [@detailExpand]="expandedElement.indexOf(element) !== -1 ? 'expanded' : 'collapsed'"
					 class="element-detail">
					<table id="table-lot">
						<tr *ngFor="let lot of element.lots">
							<td style="width: 573px;">
								<span [ngClass]="'ms-2 sp-' + lot.contract"></span>
								<span class="ms-2">{{lot.name}}</span>
							</td>
							<td *ngIf="role | role: ['ADMIN', 'USER']" class="border-dark-grey text-center"
								style="width: 118px">
								{{lot.budget}} {{'DATE.DAY_SMALL' | translate}}
							</td>
							<td class="border-dark-grey text-center" style="width: 118px">
								{{lot.budget == 0 ? 0 : ((1 - (lot.remainingEXTSum / lot.budget)) * 100)  | number:'1.0-0'}}
								%
							</td>
							<td>
							</td>
						</tr>
					</table>
				</div>
			</td>
		</ng-container>
		<tr *matRowDef="let row; columns: displayedColumns" class="projects-table" mat-row></tr>
		<tr *matRowDef="let row; columns: ['expandedDetail']" class="detail-row" mat-row>
	</table>
	<mat-paginator [length]="dataSourcePaginate.length()" [pageIndex]="paginationOption.page"
				   [pageSizeOptions]="paginationOption.pageSizeOptions"
				   [pageSize]="paginationOption.limit"></mat-paginator>

	<app-ogdp-paginator [length]="dataSourcePaginate.length()" [linkMatPaginator]="paginator"
						[pageIndex]="paginationOption.page" [pageSizeOptions]="paginationOption.pageSizeOptions"
						[pageSize]="paginationOption.limit" (pageClick)="goToPage($event)">
	</app-ogdp-paginator>
</div>

