import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DialogClientEditComponent} from './component/client-edit/dialog-client-edit.component';
import {ClientDatasource} from './datasource/client-datasource';
import {PaginationOption, PaginationSort, PaginationSortBy} from '../core/pagination/dto/pagination-option.dto';
import {firstValueFrom, merge, throwError} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {DialogClientDeleteComponent} from './component/client-delete/dialog-client-delete.component';
import {SpinnerService} from '../core/service/spinner.service';
import {ClientService} from '../core/business/service/client/client.service';
import {ClientDto, IClientDto} from '../core/business/service/client/client.dto';
import {OgdpPaginatorComponent} from '../utils/components/paginator/ogdp-paginator.component';
import {ProjectService} from '../core/business/service/project/project.service';
import {SearchbarComponent} from '../utils/components/searchbar/searchbar.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {ThemeEnum} from '../theme/themes';
import {MatSelect} from '@angular/material/select';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {UserInfo} from '../security/util/user-info';
import {KeycloakService} from "keycloak-angular";

@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss']
})

export class ClientComponent extends UserInfo implements AfterViewInit, OnInit {

	dataSourcePaginate: ClientDatasource;
	displayedColumns: string[] = ['logo', 'name', 'statut'];

	filterClientName: string;
	filterActif: boolean;

	paginationOption: PaginationOption;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(OgdpPaginatorComponent) ogdpPaginator: OgdpPaginatorComponent;
	@ViewChild(SearchbarComponent) searchInputClient: SearchbarComponent;
	@ViewChild('selectActif') selectActif: MatSelect;

	constructor(public override keycloak: KeycloakService,
				private clientService: ClientService,
				private spinnerService: SpinnerService,
				private _projectService: ProjectService,
				public snackBar: MatSnackBar,
				public dialog: MatDialog,
				private _titleService: Title) {
		super(keycloak);
		this.paginationOption = new PaginationOption(20, 0);
		this.paginationOption.sortBy = new PaginationSortBy('name', PaginationSort.ASC);
	}

	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Gestion client');

		this.dataSourcePaginate = new ClientDatasource(this.clientService, this.spinnerService);
		this.filterActif = true;
		this.loadClient();
	}

	ngAfterViewInit(): void {
		merge(this.searchInputClient.valueChange, this.searchInputClient.clearInput)
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.paginationOption.page = this.paginator.pageIndex;
					this.loadClient();
				})
			)
			.subscribe();

		this.selectActif.selectionChange.pipe(tap(() => {
			this.paginator.pageIndex = 0;
			this.paginationOption.page = this.paginator.pageIndex;
			this.loadClient();
		})).subscribe();

		// Rechargement des données quand on intéragit avec la pagination
		merge(this.paginator.page).pipe(tap(() => {
			this.paginationOption.page = this.paginator.pageIndex;
			this.paginationOption.limit = this.paginator.pageSize;
			this.loadClient();
			this.paginator.length = this.dataSourcePaginate.length();
		})).subscribe();
	}

	syncWithMdm(): void {
		this.spinnerService.enableLoading();
		this.clientService.syncClients().pipe(
			catchError(() => {
				this.spinnerService.disableLoading();
				return throwError(() => new Error('Erreur lors de la synchronisation des Clients'));
			})
		).subscribe(() => {
			this.loadClient();
			this.spinnerService.disableLoading();
			this.snackBar.open('Synchronisation réussie', '', {
				panelClass: 'success',
				duration: 2000,
			});
		});
	}

	sortData(event: any): void {
		if (event.direction) {
			this.paginationOption.sortBy = new PaginationSortBy(event.active, event.direction.toUpperCase());
		} else {
			this.paginationOption.sortBy = null;
		}
		this.loadClient();
	}

	goToPage(event: PageEvent): void {
		this.paginationOption.page = event.pageIndex;
		this.loadClient();
	}

	private loadClient(): void {
		if (this.filterClientName || this.filterActif != null) {
			this.dataSourcePaginate.findFilterByNamePaging(this.filterClientName, this.filterActif, this.paginationOption);
		} else {
			this.dataSourcePaginate.findAllPaging(this.paginationOption);
		}
	}

	openDialogCreateUpdateClient(client?: IClientDto): void {
		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '550px';
		if (client) {
			dialogConfig.data = {client};
		} else {
			dialogConfig.data = {client: new ClientDto()};
		}

		const dialogRef: MatDialogRef<DialogClientEditComponent> = this.dialog.open(DialogClientEditComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((result: IClientDto) => {
			this._titleService.setTitle('ITS OGDP > Gestion client');

			if (result) {
				this.clientService.create(result).subscribe(() => {
					this.snackBar.open('Création du client : ' + result.name);
					this.loadClient();
				});
			}
		});
	}

	async openDialogDeleteClient(clientToDelete: ClientDto): Promise<void> {
		this.spinnerService.enableLoading();
		clientToDelete.projects = await firstValueFrom(this._projectService.findByClient(clientToDelete.id));

		const dialogConfig: MatDialogConfig = new MatDialogConfig();
		dialogConfig.width = '550px';
		dialogConfig.data = {clientToDelete: clientToDelete};

		this.spinnerService.disableLoading();
		const dialogRefUpdate: MatDialogRef<DialogClientDeleteComponent> = this.dialog.open(DialogClientDeleteComponent, dialogConfig);
		dialogRefUpdate.afterClosed().subscribe(result => {
			this._titleService.setTitle('ITS OGDP > Gestion client');

			if (result) {
				this.clientService.remove(result.id).subscribe(() => {
					this.loadClient();
				});
			}
		});
	}

	clearFilterField(): void {
		this.filterClientName = '';
	}
}
