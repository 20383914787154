import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {SpinnerService} from '../service/spinner.service';

export interface Filterable {
	name: string;
	jiraIntId?: string;
	matchesFilter: boolean;
	/**
	 * Array of 3 strings : the middle one will be highlighted
	 */
	highlightedName: string[];
}

export abstract class AbstractDatasource<T> implements DataSource<T> {

	protected dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	protected _data: T[];
	protected _filterFunction: any;

	protected spinnerService: SpinnerService;

	protected constructor(spinnerService: SpinnerService) {
		this.spinnerService = spinnerService;
	}

	connect(collectionViewer: CollectionViewer): Observable<T[]> {
		return this.dataSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.dataSubject.complete();
	}

	data(): T[] {
		return this.dataSubject.value;
	}

	length(): number {
		return this.dataSubject.value.length;
	}

	abstract transformResults(data: any): T[];

	protected notifyData(result: Observable<any>): void {
		this.spinnerService.enableLoading();

		result.pipe(
			catchError(() => of({})),
			finalize(() => {
				this.spinnerService.disableLoading();
			})
		).subscribe((data: any) => {
			this._data = this.transformResults(data);
			this.dataSubject.next(this._data);
		});
	}

	protected applyFilter(filterFunction?: any): void {
		this._filterFunction = filterFunction;
		if (this._filterFunction && this._data) {
			this._data.forEach(this._filterFunction);
		}
	}
}

