<app-dialog-popup [popupTitle]="('CLIENT.' + (isCreation() ? 'NEW': 'UPDATE')) | translate">
	<div class="dialogContent" dialog-popup-content>
		<div class="main-div">
			<form [formGroup]="clientForm">
				<mat-form-field class="d-flex flex-column justify-content-between">
					<mat-label>{{'CLIENT.EDIT_FORM.NAME' |translate}}</mat-label>
					<input formControlName="name" matInput type="text">
				</mat-form-field>

				<div class="d-flex flex-column">
					<img [src]="base64CroppedImg | safizer" *ngIf="base64CroppedImg" alt="logo"
						 class="align-self-center cropperResult"/>
				</div>

				<div class="d-flex flex-column div-copper">
					<input #selectFile accept='image/*' type="file"/>
					<div class="d-flex justify-content-start displayImg">
						<image-cropper [aspectRatio]="1" [imageChangedEvent]="imageChangedEvent" [resizeToWidth]="128"
									   #imgCropper class="cropperImg" format="png"
						></image-cropper>
					</div>
				</div>
			</form>
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="saveClient()" class="its-button"
				mat-flat-button>{{'APP.' + (isCreation() ? 'CREATE' : 'UPDATE') | translate}}</button>
	</div>
</app-dialog-popup>
