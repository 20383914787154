import {Alignment, Borders, BorderStyle, Cell, CellValue, Fill, Font, Row, Worksheet} from 'exceljs';

export class CellRowBuilder {
	private cells: Cell[];

	constructor() {
		this.cells = [];
	}

	addEmptyCell(count: number): CellRowBuilder {
		this.cells = this.cells.concat(new Array(count).fill(''));
		return this;
	}

	addCell(cell: Cell): CellRowBuilder {
		this.cells.push(cell);
		return this;
	}

	build(): Cell[] {
		return this.cells;
	}

	values(): CellValue[] {
		return this.cells.map(value => value ? value.value : null);
	}

	mapCells(row: Row): void {
		row.eachCell((cell, colNumber) => {
			const builderCell: Cell = this.cells[colNumber - 1];
			cell.fill = builderCell.fill;
			cell.border = builderCell.border;
			cell.font = builderCell.font;
			cell.alignment = builderCell.alignment;
		});
	}

	addToWorksheet(worksheet: Worksheet): Row {
		const row: Row = worksheet.addRow(this.values());
		this.mapCells(row);
		return row;
	}

	getCells(): Cell[] {
		return this.cells;
	}
}

export class CellBuilder {
	private cell: Cell;

	constructor(value?: CellValue) {
		this.new(value);
	}

	new(value?: CellValue): CellBuilder {
		this.cell = {} as Cell;
		this.cell.value = value;
		return this;
	}

	value(value: CellValue): CellBuilder {
		this.cell.value = value;
		return this;
	}

	clone(): CellBuilder {
		const cellbuilder: CellBuilder = new CellBuilder();
		cellbuilder.cell = Object.assign({}, this.cell);
		for (const key of Object.keys(this.cell)) {
			cellbuilder.cell[key] = Object.assign({}, this.cell[key]);
		}
		return cellbuilder;
	}

	build(): Cell {
		return Object.assign({}, this.cell);
	}

	fill(fill: Fill): CellBuilder {
		this.cell.fill = fill;
		return this;
	}

	pattern(color: string): CellBuilder {
		this.cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: color},
			bgColor: {argb: color}
		};
		return this;
	}

	font(font: Font): CellBuilder {
		this.cell.font = font;
		return this;
	}

	fontFamily(family: string): CellBuilder {
		if (!this.cell.font) {
			this.cell.font = {};
		}
		this.cell.font.name = family;
		return this;
	}

	fontSize(size: number): CellBuilder {
		if (!this.cell.font) {
			this.cell.font = {};
		}
		this.cell.font.size = size;
		return this;
	}

	fontColor(color: string): CellBuilder {
		if (!this.cell.font) {
			this.cell.font = {};
		}
		this.cell.font.color = {argb: color};
		return this;
	}

	bold(): CellBuilder {
		if (!this.cell.font) {
			this.cell.font = {};
		}
		this.cell.font.bold = true;
		return this;
	}

	alignment(aligment: Alignment): CellBuilder {
		this.cell.alignment = aligment;
		return this;
	}

	alignMiddle(): CellBuilder {
		this.cell.alignment = {
			vertical: 'middle',
			horizontal: 'center',
			wrapText: true,
		};
		return this;
	}

	verticalAlignment(type: 'top' | 'middle' | 'bottom' | 'distributed' | 'justify'): CellBuilder {
		if (!this.cell.alignment) {
			this.cell.alignment = {};
		}
		this.cell.alignment.vertical = type;
		return this;
	}

	horizontalAlignment(type: 'left' | 'center' | 'right' | 'fill' | 'justify' | 'centerContinuous' | 'distributed'): CellBuilder {
		if (!this.cell.alignment) {
			this.cell.alignment = {};
		}
		this.cell.alignment.horizontal = type;
		return this;
	}

	wrapText(): CellBuilder {
		if (!this.cell.alignment) {
			this.cell.alignment = {};
		}
		this.cell.alignment.wrapText = true;
		return this;
	}

	border(border: Borders): CellBuilder {
		this.cell.border = border;
		return this;
	}

	borderTop(type: BorderStyle, color?: string): CellBuilder {
		if (!this.cell.border) {
			this.cell.border = {};
		}
		this.cell.border.top = {style: type};
		if (color) {
			this.cell.border.top.color = {argb: color};
		}
		return this;
	}

	borderLeft(type: BorderStyle, color?: string): CellBuilder {
		if (!this.cell.border) {
			this.cell.border = {};
		}
		this.cell.border.left = {style: type};
		if (color) {
			this.cell.border.left.color = {argb: color};
		}
		return this;
	}

	borderBottom(type: BorderStyle, color?: string): CellBuilder {
		if (!this.cell.border) {
			this.cell.border = {};
		}
		this.cell.border.bottom = {style: type};
		if (color) {
			this.cell.border.bottom.color = {argb: color};
		}
		return this;
	}

	borderRight(type: BorderStyle, color?: string): CellBuilder {
		if (!this.cell.border) {
			this.cell.border = {};
		}
		this.cell.border.right = {style: type};
		if (color) {
			this.cell.border.right.color = {argb: color};
		}
		return this;
	}
}
