<app-dialog-popup [popupTitle]="'STORY.BUDGET_REDISTRIBUTION_JIRA.TITLE' | translate">
	<div dialog-popup-content>
		<div class="header">
			<span>
				{{'STORY.BUDGET_REDISTRIBUTION_JIRA.HEADER' | translate}}
			</span>
		</div>

		<div class="body">
			<div [innerHTML]="'STORY.BUDGET_REDISTRIBUTION_JIRA.MESSAGE' | translate" class="mb-3 budget-jira-message">
			</div>
			<div *ngFor="let item of issuesByLots" class="lot">
				<mat-expansion-panel class="lot mb-3">
					<mat-expansion-panel-header class="lot">
						{{item.lot.object.name}} ({{item.issues.length}})
					</mat-expansion-panel-header>
					<table mat-table [dataSource]="item.issues" class="issue-table">
						<ng-container matColumnDef="issueId">
							<th mat-header-cell *matHeaderCellDef>{{'BUG.TABLE.JIRA'| translate }}</th>
							<td mat-cell *matCellDef="let element" [matTooltip]="element.name">
								<a class="jira-id" href="https://itsfuture-jira.atlassian.net/browse/{{element.jiraId}}"
								   target="_blank">{{element.jiraId}}</a>
							</td>
						</ng-container>
						<ng-container matColumnDef="oldBudget">
							<th mat-header-cell *matHeaderCellDef>{{'STORY.BUDGET_REDISTRIBUTION_JIRA.OLD_BUDGET' | translate}}</th>
							<td mat-cell *matCellDef="let element"> {{element.oldBudget}} </td>
						</ng-container>
						<ng-container matColumnDef="newBudget">
							<th mat-header-cell *matHeaderCellDef>{{'STORY.BUDGET_REDISTRIBUTION_JIRA.NEW_BUDGET' | translate}}</th>
							<td mat-cell *matCellDef="let element" class="fw-bolder"> {{element.newBudget}} </td>
						</ng-container>
						<ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef>Action</th>
							<td mat-cell *matCellDef="let element">
								<span class="txt-green" *ngIf="element.restored">Rétabli</span>
								<button *ngIf="!element.restored" (click)="restoreBudgetInJira(element)" mat-flat-button class="its-button mat-flat-button">
									Rétablir Jira
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
					</table>
				</mat-expansion-panel>
			</div>
		</div>
	</div>

	<div dialog-popup-footer>
		<button (click)="valider()" mat-flat-button class="its-button mat-flat-button">
			{{'APP.CLOSE' | translate}}
		</button>
	</div>
</app-dialog-popup>
