<div>
	<div class="lot-title">
		{{item.lot.object.name}} ({{'PROJECT.CONTRACT.STATUS.' + item.lot.object.contract | translate}})
	</div>
	<div class="d-flex justify-content-between mb-2">
		<div>
			<span>{{'STORY.BUDGET_REDISTRIBUTION_JIRA.DELTA' | translate}} : </span>
			<span class="fw-bold">
				{{item.deltaBudget | number: '.0-3'}} {{'DATE.DAY_SMALL' | translate}}
			</span>
		</div>
		<div>
			<mat-checkbox [(ngModel)]="item.isForceModifBudget" #forceModifBudget>
				{{'STORY.BUDGET_REDISTRIBUTION.FORCE' | translate}}
			</mat-checkbox>
		</div>
	</div>
	<div [hidden]="item.isForceModifBudget">
		<form>
			<mat-form-field class="w-100">
				<mat-label>{{getPlaceholder(item)}}</mat-label>
				<input [formControl]="item.storyFormControl" [matAutocomplete]="autocomplete" matInput>
				<mat-autocomplete [displayWith]="displaySelectedStory" #autocomplete="matAutocomplete"
								  autoActiveFirstOption>
					<mat-option [value]="story" *ngFor="let story of item.selectStoryFiltered">
							<span>{{story.jiraIntId}} - {{story.name}} - ({{'PROJECT.RAE' | translate}}
								: {{story.remainingEXT}} {{'DATE.DAY_SMALL' | translate}})</span>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</form>
		<div *ngIf="item.errorMessage" class="alert alert-danger">{{item.errorMessage}}</div>
	</div>
</div>
